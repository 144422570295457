'use strict';

import { isBoolean } from 'lodash';
var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImportTypes = require('../../constants/ImportTypes');

var ImportProgressStore = require('../../stores/ImportProgressStore');

var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var StickyPopover = require('../common/StickyPopover.react');

/** @module AudioUrlImport
 * @requires lodash
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires ImportTypes
 * @requires ImportProgressStore
 * @requires ImportActionsCreator
 * @requires StickyPopover
 */
var AudioUrlImport = ComponentFactory.Create(ImportProgressStore, {
	displayName: "AudioUrlImport",
	/**
	 * @method getState
	 */
	getState: function() {
		var state = {
			importProgress: ImportProgressStore.getState(ImportTypes.SOUNDCLOUD),
			url: this.route().query.url,
			popupIsOpen: false
		};
		state.loading = state.importProgress && state.importProgress.progress;
		return state;
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this.setState({element: ReactDOM.findDOMNode(this)});
	},

	/**
	 * @method _togglePopup
	 */
	_togglePopup: function(newState) {
		var isOpen = isBoolean(newState) ? newState : !this.state.popupIsOpen;
		var width = ReactDOM.findDOMNode(this).offsetWidth;

		this.setState({
			popupIsOpen: isOpen,
			rel: {
				width: width
			}
		});
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(e) {
		this._startImport();
	},

	/**
	 * @method _handleKeyDown
	 */
	_handleKeyDown: function(e) {
		if(e.key === "Enter") {
			this._startImport();
		}
	},

	/**
	 * @method _startImport
	 */
	_startImport: function() {
		var url = this.state.url;
		if(url && !this.state.loading) {
			ImportActionsCreator.startImport(ImportTypes.SOUNDCLOUD, {'page_url': url});
		}
	},

	/**
	 * @method _close
	 */
	_close: function() {
		this._togglePopup(false);
	},

	/**
	 * @method _onChangeTextField
	 */
	_onChangeTextField: function (ev) {
		this.setState({ url: ev.target.value });
	},

	/**
	 * @method _onMouseEnter
	 */
	_onMouseEnter: function() {
		this.setState({mouseOver: true});
	},

	/**
	 * @method _onMouseLeave
	 */
	_onMouseLeave: function() {
		this.setState({mouseOver: false});
	},

	/**
	 * @method render
	 */
	render: function () {
		var loading = this.state.loading;
		var popupForm = <div className="import-website">
			<div>
				<p className="import-website-description">Enter any URL to your audio track below to begin importing</p>
				<div className="import-website-body">
					<input
						className="website-url small-input"
						onKeyDown={this._handleKeyDown}
						onChange={this._onChangeTextField}
						value={this.state.url}
						type="text"
						placeholder="https://soundcloud.com/"
						autoFocus />
				</div>
				<div className="row">
					<div className="large-6 columns">
						<p className="import-website-disclaimer">Audio &amp; content may be subject to copyright.</p>
					</div>
					<div className="large-6 columns">
						<div
							className="button medium radius success"
							onClick={this._onClick}
							disabled={!this.state.url}>
							<p style={{color: "#fff"}}>Import</p>
						</div>
					</div>
				</div>
			</div>
		</div>;

		var popup = this.state.popupIsOpen ?
			<StickyPopover className={this.css("closable-popup", "import-popup", "page")}
				onClose={this._close}
				target={this.state.element}>
				<div className="title-bar row">
					<div className="large-12 columns">
						<p className="title">Import an audio track</p>
					</div>
				</div>
				{popupForm}
			</StickyPopover> :
			<div></div>;

		var iconCss = ['import-icon-img website', { 'pulse': loading }];
		var css = 'import-header-column import-header-column-button bg-overlay website';
		return (
			<div className={this.css(css)} >
				<div className="button-inner" onClick={this._togglePopup}>
					<div className="show-for-large-up import-website-label" >
						<p >{this.props.title}</p>
						<h3 >{this.props.tagline}</h3>
					</div>
					<div className="import-icon-holder" >
						<div className={this.css(iconCss)} >
						</div>
					</div>
				</div>
				{popup}
			</div>
		);
	}
});

module.exports = AudioUrlImport;
