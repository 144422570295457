'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var EmailLink = ComponentFactory.Create( {
	displayName: "EmailLink",
	propTypes: {
		handleChange: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return {
			curr_url: this.props.currentLinkURL.replace(/^mailto:/, '') || '',
			prefix: 'mailto'
		};
	},

	_handleInputChange: function (e) {
		var value = e.target.value;
		var url = this.state.prefix + ':' + value;
		this.setState({
			curr_url: value
		})
		this.props.handleChange(url);
	},


	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="email-input">
				<span className="link-prefix">{this.state.prefix}://</span>
				<input key="URL" type="text" placeholder={this.state.curr_url? null:'hello@example.com'} value={this.state.curr_url} onChange={this._handleInputChange}></input>
			</div>
		)
	}
});

module.exports = EmailLink;
