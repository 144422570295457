'use strict';

var _ = require('lodash');
var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');
var Mosaic = require('../content-management/content-lists/Mosaic.react.js');

var ComponentContextStore = require('../../stores/ComponentContextStore');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var PropertyTypes = require('../../constants/PropertyTypes');
var ContentTypes = require('../../constants/ContentTypes');
var ContentStore = require('../../stores/ContentStore');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');
var MediaServicesUtils = require('../../utils/MediaServicesUtils');
var ImportTypes = require('../../constants/ImportTypes');
var ImportProgressStore = require('../../stores/ImportProgressStore');
var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var TrackingImportStore = require('../../stores/TrackingImportStore');


/** @module AudioPicker
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires Mosaic.js
 * @requires ComponentContextStore
 * @requires ComponentActionsCreator
 * @requires PropertyTypes
 * @requires ContentTypes
 * @requires ContentStore
 * @requires ContentActionsCreator	 * @requires MediaServicesUtils
 * @requires ImportTypes
 * @requires ImportProgressStore
 * @requires ImportActionsCreator
 * @requires TrackingImportStore
 */
var AudioPicker = ComponentFactory.Create(ComponentContextStore, ContentStore, TrackingImportStore, {
	displayName: "AudioPicker",
	propTypes: {
		propertyName: React.PropTypes.string.isRequired,
		propertyType: React.PropTypes.oneOf([PropertyTypes.audio]).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var value = ComponentContextStore.getPropertyValue(this.props.propertyName);

		var audios = _(ContentTypes.SOUNDCLOUD)
			.thru(ContentStore.getState)
			.sortBy('id')
			.reverse()
			.value();
		var importTrackingId = _.get(this.state, "importTrackingId");
		var importedData = importTrackingId && TrackingImportStore.getState(importTrackingId);
		var trackIdInValue = value && MediaServicesUtils.getSoundcloudTrackIdFromApiUrl(value.url);

		var selectedItem = (importedData && _.first(importedData.content))
				|| (trackIdInValue && _.find(audios, {"track_id": trackIdInValue}));
		return {
			value: value,
			selectedItem: selectedItem,
			audios: audios
		};
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function(){
		ContentActionsCreator.loadContent(ContentTypes.SOUNDCLOUD);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		if(!_.isUndefined(prevState) && !_.isEqual(this.state.value, prevState.value)) {
			var component = ComponentContextStore.getState('active');
			if(!_.isEqual(_.get(component.context, this.props.propertyName), this.state.value)) {
				_.set(component.context, this.props.propertyName, this.state.value);
				ComponentActionsCreator.updateComponentInstanceContext(component);
			}
		}
	},

	/**
	 * @method _onApply
	 */
	_onApply: function(){
		var url = this.state.selectedItem
			&& MediaServicesUtils.getSoundcloudEmbeddedUrlById(_.get(this.state.selectedItem, "track_id"));
		if (url){
			this.setState({ value: { url: url }});
			setTimeout(function() {
				ComponentActionsCreator.stopAudioPicker();
			}, 250);
		}
	},

	/**
	 * @method _onImportedItemPick
	 */
	_onImportedItemPick: function(contentItem){
		this.setState({selectedItem: contentItem, importTrackingId: null});
	},

	/**
	 * @method _onImportStarted
	 */
	_onImportStarted: function(trackingId) {
		this.setState({ importTrackingId: trackingId });
	},

	/**
	 * @method render
	 */
	render: function() {
		var selectedItem = _.get(this.state.selectedItem, "id");
		var picked = _.isUndefined(selectedItem) ? [] : [selectedItem];
		var hasAudios = !_.isEmpty(this.state.audios);
		return (
			<div className="audio-picker">
				<span className="audio-picker-title">Select Audio:</span>
				<div className="apply">
					<div className="button medium radius success" onClick={this._onApply}>Apply</div>
				</div>

				<div>
					<UrlAudioPicker onImportStarted={this._onImportStarted}/>
					{hasAudios && (
						<div>
							<p>Select your imported Soundcloud audio and click Apply:</p>
							<Mosaic content={this.state.audios} onPick={this._onImportedItemPick} picked={picked}/>
						</div>
					)}
				</div>
			</div>
		);
	}
});


var UrlAudioPicker = ComponentFactory.Create(ImportProgressStore, {
	displayName: "UrlAudioPicker",
	propTypes: {
		url: React.PropTypes.string,
		onImportStarted: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return {
			url: this.props.url
		};
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var importProgress = ImportProgressStore.getState(ImportTypes.SOUNDCLOUD);
		var state = {
			loading: importProgress && importProgress.progress
		};
		return state;
	},

	/**
	 * @method _onTextChange
	 */
	_onTextChange: function(event) {
		this.setState({url: event.target.value});
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(e) {
		this._startImport();
	},

	/**
	 * @method _startImport
	 */
	_startImport: function() {
		var url = this.state.url;
		if(url && !this.state.loading) {
			var trackingId = _.uniqueId(ImportTypes.SOUNDCLOUD);
			ImportActionsCreator.startImport(ImportTypes.SOUNDCLOUD, {'page_url': url, trackingId: trackingId});
			if (this.props.onImportStarted) {
				this.props.onImportStarted(trackingId);
			}
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		var importButtonCss = ['button medium radius',
			{ 'pulse': this.state.loading }];
		return (
			<div className="url-audio-picker">
				<p>Type an url to a Soundcloud audio page:</p>
				<input type="text" value={this.state.url} onChange={this._onTextChange} />
				<div className={this.css(importButtonCss)} onClick={this._onClick}>Import</div>
			</div>
		);
	}
});

module.exports = AudioPicker;
