import React from 'react';
import classnames from 'classnames';

import { get } from 'lodash'

import Form from './common/Form.react';

export const TAB = {
	SEO: 'seo',
	DOMAIN: 'domain',
	EMAIL: 'email',
	DELETE: 'delete'
};

export default class WebsiteSettingsForm extends Form {
	static propTypes = {
		model: React.PropTypes.object.isRequired,
		onSave: React.PropTypes.func,
		onValidate: React.PropTypes.func,
		onFilter: React.PropTypes.func,
		availableDomainList: React.PropTypes.object.isRequired,
		onRefresh: React.PropTypes.func.isRequired,
		tab: React.PropTypes.string.isRequired,
		onTab: React.PropTypes.func.isRequired,
		onDelete: React.PropTypes.func.isRequired,
		accountStatus: React.PropTypes.object.isRequired
	};
	css = classnames;
	refreshThenReset() {
		this.props.onRefresh();
		this.reset();
	}
	render() {
		var warning = {};
		let domain = this.props.availableDomainList.find((d) => this.state.model.domain == d.domain);
		if (domain && domain.domain != this.props.model.domain) {
			switch (domain.errorCode) {
				case 1: //custom dns records found
					warning = {
						heading: "Warning: " + domain.domain + " has custom DNS records",
						details: <p className="form-warning-details">We cannot publish to a domain with custom DNS records. You will need to remove those custom records using the <a target='_blank' href={'https://panel.dreamhost.com/index.cgi?tree=domain.manage&current_step=Index&next_step=ShowZone&domain=' + domain.domain}>Manage Domains section of the DreamHost Panel</a> first. After making that change press the Refresh button and reselect the domain again.</p>,
						confirm: <input className="btn-primary" type="button" value="Refresh" onClick={() => this.refreshThenReset()} />};
					break;
				case 2: //dreamhosters domain
					warning = {
						heading: "Warning: " + domain.domain + " is a dreamhosters.com sub-domain",
						details: <p className="form-warning-details">We cannot publish to dreamhosters.com sub-domains. You will need to select a different domain or use the default DreamHost Cloud option.</p>,
						confirm: false};
					break;
				case 3: //http service found
					warning = {
						heading: "Warning: " + domain.domain + " already has hosting",
						details: <p className="form-warning-details">You've elected to publish this website to a domain which is already hosted on a DreamHost server. Publishing <em>will not remove any files</em> from the DreamHost server. This <em>will replace the hosting settings and website</em> for <a target='_blank' href={'http://' + domain.domain}>http://{domain.domain}</a>. Email settings and sub-domains will not change. You may reactivate the previous hosting settings through the DreamHost panel. To confirm please check the box below and Publish settings.</p>,
						confirm: <p>Confirm <input type="checkbox" name="confirm" key={domain.domain} onClick={() => this.save(true)}/></p>};
					break;
				case 4: //remixer domain
					break;
				case 5: //dreampress domain
					warning = {
						heading: "Warning: " + domain.domain + " is a DreamPress domain",
						details: <p className="form-warning-details">We cannot publish to DreamPress domains. You will need to remove the DreamPress server for this domain through the <a target='_blank' href='https://panel.dreamhost.com/index.cgi?tree=dreampress.dashboard&'>DreamPress section of the DreamHost Panel</a> before we can publish to the domain.</p>,
						confirm: <input className="btn-primary" type="button" value="Refresh" onClick={() => this.refreshThenReset()} />};
					break;
				case 6: //unique IP
					warning = {
						heading: "Warning: " + domain.domain + " has a unique IP address",
						details: <p className="form-warning-details">The domain you've selected is currently using a unique IP address. You will need to remove that unique IP through the <a target='_blank' href='https://panel.dreamhost.com/index.cgi?tree=domain.manage&'>Manage Domains section of the DreamHost Panel</a> before we can publish to the domain.</p>,
						confirm: <input className="btn-primary" type="button" value="Refresh" onClick={() => this.refreshThenReset()} />};
					break;
				case 7: //trial account
					warning = {
						heading: "Warning: Add-on plan required",
						details: <p className="form-warning-details">In order to publish to a custom domain on Remixer, you will need to purchase a Remixer add-on plan or a DreamHost shared hosting plan. Once purchased, your eligible domains will be available for publishing.</p>,
						confirm: <a href="https://panel.dreamhost.com/index.cgi?tree=remixer.dashboard&"><input className="btn-primary" type="submit" value="Upgrade Now!" /></a>};
					break;
				default:
					break;
			}
		}
		return (
			<form className="form-container">
				<ul className="form-control-bar">
					<li className={this.css({'active': this.props.tab === TAB.SEO})}><a onClick={() => this.props.onTab(TAB.SEO)}>SEO</a></li>
					<li className={this.css({'active': this.props.tab === TAB.DOMAIN})}><a onClick={() => this.props.onTab(TAB.DOMAIN)}>Domains</a></li>
					<li className={this.css({'active': this.props.tab === TAB.EMAIL})}><a onClick={() => this.props.onTab(TAB.EMAIL)}>Email</a></li>
					<li className={this.css({'active': this.props.tab === TAB.DELETE})}><a onClick={() => this.props.onTab(TAB.DELETE)}>Delete this website</a></li>
				</ul>
				<div className={this.css('form-padding', {'hidden': this.props.tab !== TAB.SEO})}>
					<section className="form-row">
						<label className="form-label">Website name</label>
						<div className="form-input-controls">
							<input className="form-input js-seo-title-input" data-target=".js-seo-title-target" type="text" name="name" value={this.state.model.name} onChange={this.handleInputChange} />
						</div>
					</section>
					<section className="form-row">
						<label className="form-label">Appear in Search Engines</label>
						<div className="form-input-controls">
							<select className="form-input js-seo-toggle" name="allowRobots" value={this.state.model.allowRobots} onChange={this.handleInputChange}>
								<option value="true">Yes</option>
								<option value="false">No</option>
							</select>
							<label className="form-sublabel">Search Result Preview</label>
							<div className="seo-preview">
								<p className="seo-title js-seo-title-target">{this.state.model.name}</p>
								<p className="seo-url">{this.state.model.domain ? this.state.model.domain : this.state.model.publicUrl ? this.state.model.publicUrl : "http://11111111-2222-3333-4444-555555555555-6.remixer.website/"}</p>
							</div>
						</div>
					</section>
					<section className="form-row">
						<label className="form-label">Google Analytics</label>
						<p className="form-description">Enter your Google Analytics ID</p>
						<div className="form-input-controls">
							<input className="form-input" type="text" placeholder="UA-xxxxxxxxx" name="ga" value={this.state.model.ga || ''} onChange={this.handleInputChange} />
						</div>
					</section>
					<section className="form-row form-buttons">
						<input className="btn-primary" type="button" value="Save settings" onClick={() => this.save()} />
					</section>
				</div>
				<div className={this.css('form-padding', {'hidden': this.props.tab !== TAB.DOMAIN})}>
					<section className="form-row clearfix">
						<label className="form-label">Assign an existing domain to this website</label>
						<div className={this.css('form-warning', {'hidden': !this.state.errors.domain})}>
							<a className="form-warning-close" onClick={() => this.reset()}>
								<i className="mdi mdi-close-circle-outline"></i>
							</a>
							<h3 className="form-warning-heading"><i className="mdi mdi-information-outline"></i>{warning.heading}</h3>
							{warning.details}
							<section className={this.css('form-row form-buttons form-warning-confirm', {'hidden': !warning.confirm})}>
								{warning.confirm}
							</section>
						</div>
						<div className={this.css('form-input-controls', {'hidden': this.state.errors.domain})}>
							<select name="domain" value={this.state.model.domain + ""} className="form-input form-select" onChange={this.handleInputChange}>
								<optgroup label="Default domain">
									<option value="">DreamHost Cloud</option>
								</optgroup>
								<optgroup label="Eligible domains">
									{this.props.availableDomainList.filter((d) => d.isEligible).sortBy((d) => d.domain).map((d) => <option key={d.domain} value={d.domain}>{d.domain}</option>)}
								</optgroup>
								<optgroup label="Noneligible domains">
									{this.props.availableDomainList.filter((d) => !d.isEligible).sortBy((d) => d.domain).map((d) => <option key={d.domain} value={d.domain}>{d.domain}</option>)}
								</optgroup>
							</select>
							<div className="form-refresh-domains" title="Refresh domains"><i onClick={() => this.props.onRefresh()} className="mdi mdi-rotate-3d"></i></div>
							<div className="row">
								{get(this.props.accountStatus, 'activeAccount') ?
									<div className="small-12 columns">
										<a className="form-input-button" href="https://panel.dreamhost.com/index.cgi?tree=domain.registration" target="_blank">Click to register or transfer a new domain</a>
										<div className="form-tool-tip">
											<i className="mdi icon mdi-help-circle-outline" />
											<span className="form-tool-tip-text" >Domains must be purchased from the DreamHost control panel. Please click the link to continue.</span>
										</div>
									</div>
									:
									<div className="small-12 columns">
										<a className="form-input-button" href="https://panel.dreamhost.com/index.cgi?tree=remixer.dashboard&" target="_blank">Click to Upgrade and unlock custom domain publishing</a>
										<div className="form-tool-tip">
											<i className="mdi icon mdi-help-circle-outline" />
											<span className="form-tool-tip-text" >In order to publish to a custom domain on Remixer, you will need to purchase a Remixer add-on plan or a DreamHost shared hosting plan. Please click the link to continue.</span>
										</div>
									</div>
								}
							</div>
						</div>
					</section>
					<section className="form-row form-buttons">
						<input className="btn-primary" type="button" value="Save settings" onClick={() => this.save()} />
					</section>
				</div>
				<div className={this.css('form-padding', {'hidden': this.props.tab !== TAB.EMAIL})}>
					<section className="form-row">
						{get(this.props.accountStatus, 'mailboxAccess') ?
							<div className="form-input-controls">
								<label className="form-label">It looks like email is included with your Remixer plan.</label>
								<a className="form-input-button" href="https://panel.dreamhost.com/index.cgi?tree=mail.addresses&" target="_blank">Click here to access and manage your email.</a>
								<div className="form-tool-tip">
									<i className="mdi icon mdi-help-circle-outline" />
									<span className="form-tool-tip-text" >Mailboxes are managed from the DreamHost control panel. Please click the link to continue.</span>
								</div>
							</div>
							:
							<div className="form-input-controls">
								<label className="form-label">Looking to add custom email to your domains?</label>
								<a className="form-input-button" href="https://panel.dreamhost.com/index.cgi?tree=remixer.dashboard&" target="_blank">Click here to upgrade to our Happy Hosting plan</a>
								<div className="form-tool-tip">
									<i className="mdi icon mdi-help-circle-outline" />
									<span className="form-tool-tip-text" >Our Happy Hosting plan allows you to manage and create as many email addresses as you'd like with unlimited auto-responders and more!</span>
								</div>
							</div>}
					</section>
				</div>
				<div className={this.css('form-padding', {'hidden': this.props.tab !== TAB.DELETE})}>
					<section className="form-row form-buttons">
						<label className="form-label">Your website will be deleted by clicking the button below</label>
					</section>
					<section className="form-row form-buttons">
						<input className="btn-primary delete" type="button" value="I understand - please delete my website" onClick={() => this.props.onDelete()} />
					</section>
				</div>
			</form>
		);
	}
}
