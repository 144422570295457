'use strict';

var _ = require('lodash');

var React = require('react');
var css = require('classnames');

var ComponentPreview = require('./ComponentPreview.react');
var ComponentPreviewModel = require('../models/ComponentPreview');

var filterCategories = {
	'All': '',
	'About': 'about',
	'Audio': 'audio',
	'Contact': 'contact',
	'Footer': 'footer',
	'Gallery': 'gallery',
	'Grid': 'grid',
	'Header': 'header',
	'Hero': 'hero',
	'Video': 'video'
};

/** @module ComponentsPreviewBase
	 * @requires lodash
	 * @requires react
	 * @requires ComponentFactory
	 * @requires ComponentPreview
	 * @requires ComponentPreview
	 */
var ComponentsPreviewBase = React.createClass({
	displayName: "ComponentsPreviewBase",
	propTypes: {
		previews: React.PropTypes.arrayOf(ComponentPreviewModel),
		onComponentClick: React.PropTypes.func,
		componentCategory: React.PropTypes.string,
		searchText: React.PropTypes.string,
		onCategorySelected: React.PropTypes.func,
		onSearchApplied: React.PropTypes.func,
		onPrevPage: React.PropTypes.func,
		onNextPage: React.PropTypes.func,
		loading: React.PropTypes.bool,
		disableNext: React.PropTypes.bool,
		disablePrev: React.PropTypes.bool
	},

	getInitialState: function() {
		return { searchText: this.props.searchText || ''};
	},

	componentWillReceiveProps(nextProps) {
		if (nextProps.searchText !== this.state.searchText) {
			this.setState({ searchText: nextProps.searchText });
		}
	},

	_handleKeyDown: function(e) {
		var ENTER = 13;
		if((e.keyCode === ENTER || e.which === 13) && this.props.onSearchApplied) {
			this.props.onSearchApplied(this.state.searchText);
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		var components = _.map(this.props.previews, (preview) => {
			return (
				<li key={preview.componentInstanceId}>
					<ComponentPreview preview={preview} onClick={this.props.onComponentClick}/>
				</li>);
		});

		var categories = _(filterCategories)
			.keys()
			.map((key) => {
				var title = key;
				var categoryValue = filterCategories[key];
				var className = css({
					selected: categoryValue === this.props.componentCategory && !this.props.searchText
				});
				return (
					<li key={categoryValue} className={className}>
						<span onClick={_.partial(this.props.onCategorySelected, categoryValue)}>{title}</span>
					</li>
				);
			})
			.value();

		var status = null;
		if (this.props.loading){
			status = (
				<div className="status loading">Loading...</div>
			);
		} else if (_.isEmpty(components)) {
			status = (
				<div className="status no-results">No Results</div>
			);
		}

		let style = { height: "150px" };

		return (
			<div className="components-preview">
				<ul className="categories">
					{categories}
					<li key="search" className={css({selected: !!this.state.searchText, 'search-box': true})}>
						<i className="mdi mdi-magnify"></i>
						<input type="text" placeholder="search..." value={this.state.searchText}
							onKeyDown={this._handleKeyDown} onChange={e => this.setState({searchText: e.target.value})}
						/>
					</li>
				</ul>
				{status}
				<div className="components" style={ style }>
					<span className={css({'prev': true, 'disable': this.props.disablePrev})}
						onClick={this.props.onPrevPage} title="Previous page"></span>
					<ul>{components}</ul>
					<span className={css({'next': true, 'disable': this.props.disableNext})}
						onClick={this.props.onNextPage} title="Next page"></span>
				</div>
			</div>
		);
	}
});

module.exports = ComponentsPreviewBase;
