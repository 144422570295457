'use strict';

module.exports = [
	'None',
	'Bronson',
	'Chipper',
	'Clothesline',
	'Cronkite',
	'Deck',
	'Frado',
	'Jerry',
	'Juicer',
	'Devo',
	'Trilly',
	'On Fleek',
	'Jug',
	'Kale',
	'Grill',
	'Midtown',
	'Salty',
	'Latch On',
	'Piece',
	'Tassel',
	'Barrelhouse',
	'Buddy Ghee',
	'Cogs',
	'Fine Dinner',
	'Jelly',
	'Kopasetic',
	'Main Kick',
	'Signify',
	'Wren',
	'Awks',
	'Povo',
	'YOLO',
	'Hundo P',
	'Trill',
	'Respek',
	'Low Key',
	'Savage'
]