'use strict';

import { identity, bind, forEach, create, invokeMap } from 'lodash';

var Preprocessors = require('./handlebars/Preprocessors');
var Helpers = require('./handlebars/Helpers');

var TextPreprocessor = Preprocessors.TextPreprocessor;
var EditablecontentTextPreprocessor = Preprocessors.EditablecontentTextPreprocessor;
var SrcImgPreprocessor = Preprocessors.SrcImgPreprocessor;
var BackgroundUrlImgPreprocessor = Preprocessors.BackgroundUrlImgPreprocessor;
var IframePreprocessor = Preprocessors.IframePreprocessor;
var LinkPreprocessor = Preprocessors.LinkPreprocessor;

var PropertyHelper = Helpers.PropertyHelper;
var EachHelper = Helpers.EachHelper;

/** @module TemplateCompilerFactory
 * @requires lodash
 * @requires Preprocessors
 * @requires Helpers
 */
function TemplateCompiler(handlebars) {
	this.GetPreprocessors = function() { return [new TextPreprocessor()]; };
	this.GetCompileOptions = identity;

	this.compile = bind(function(template) {
		forEach(this.GetPreprocessors(), function(preprocessor) {
			template = preprocessor.process(template);
		});

		return handlebars.compile.call(handlebars, template, this.GetCompileOptions());
	}, this);
}

function EditorTemplateCompiler(handlebars) {
	TemplateCompiler.call(this, handlebars);

	this.GetPreprocessors = function() {
		return [
			new EditablecontentTextPreprocessor(),
			new SrcImgPreprocessor(),
			new BackgroundUrlImgPreprocessor(),
			new IframePreprocessor(),
			new LinkPreprocessor()
		];
	};

	this.GetCompileOptions = function() {
		return { trackIds: true };
	};

	//register custom helpers for editor
	invokeMap([
		new PropertyHelper(handlebars),
		new EachHelper(handlebars)
	], "register");
}

EditorTemplateCompiler.prototype = create(TemplateCompiler.prototype, {
	'constructor': EditorTemplateCompiler
});

module.exports = {
	/**
	 * @method Create
	 */
	Create: function(handlebars, editorMode) {
		return editorMode ?
			new EditorTemplateCompiler(handlebars) :
			new TemplateCompiler(handlebars);
	}
};
