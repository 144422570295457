'use strict';
var RemixerConstants = require('../constants/RemixerConstants');
var AppDispatcher = require('../dispatcher/AppDispatcher');


/** @module PublishServerActionsCreator
 * @requires RemixerConstants
 * @requires AppDispatcher
 */
var PublishServerActionsCreator = {
	/**
	 * @method receivePublished
	 */
	receivePublished: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_WEBSITE_PUBLISHED,
			website: response.data
		});
	},
	/**
	 * @method receivePublishFailed
	 */
	receivePublishFailed: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_WEBSITE_PUBLISH_FAILED,
			website: response.data
		});
	}
};

module.exports = PublishServerActionsCreator;
