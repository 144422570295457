'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var WebAPI = require('../utils/WebAPI');

var ContentStore = require('../stores/ContentStore');


/** @module ContentActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 * @requires ContentStore
 */
var ContentActionsCreator = {
	/**
	 * @method loadContent
	 */
	loadContent: function(contentType) {
		if(!ContentStore.needs(contentType)) return;
		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_CONTENT,
			contentType: contentType
		},
		WebAPI.loadContent, [contentType]);
	},
	/**
	 * @method deleteContent
	 */
	deleteContent: function(contentId, contentType) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.DELETE_CONTENT,
			contentId: contentId,
			contentType: contentType
		},
		WebAPI.deleteContent, [contentId, contentType]);
	},
	/**
	 * @method postContent
	 */
	postContent: function(contentType, content) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.POST_CONTENT,
			contentType: contentType,
			content: content
		},
		WebAPI.postContent, [contentType, content]);
	},
	/**
	 * @method putContent
	 */
	putContent: function(contentId, contentType, content) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.PUT_CONTENT,
			contentId: contentId,
			contentType: contentType,
			content: content
		},
		WebAPI.putContent, [contentId, contentType, content]);
	},
	/**
	 * @method updateContentTags
	 */
	updateContentTags: function(contentId, contentType, tags) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.UPDATE_CONTENT_TAGS,
			contentId: contentId,
			contentType: contentType,
			tags: tags
		},
		WebAPI.updateContentTags, [contentId, contentType, tags]);
	}
};

module.exports = ContentActionsCreator;
