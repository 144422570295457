'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module ConfigStore
 * @requires StoreFactory
 * @requires RemixerConstants
 */
var ConfigStore = StoreFactory.Create({
	/**
	 * @method getConfig
	 */
	getConfig: function() {
		return this.getState();
	},

	/**
	 * @method _setConfig
	 */
	_setConfig: function(action) {
		this._set(action.config);
	},

	/**
	 * @method _setFailed
	 */
	_setFailed: function(action) {
		this._failed();
	},

	/**
	 * @method _setFilling
	 */
	_setFilling: function(action) {
		this._filling();
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		handlers[RemixerConstants.RECEIVE_CONFIG] = this._setConfig;
		handlers[RemixerConstants.RECEIVE_CONFIG_FAIL] = this._setFailed;
		handlers[RemixerConstants.LOAD_CONFIG] = this._setFilling;

		return handlers;
	}
});

module.exports = ConfigStore;
