import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';
var Mapper = require('../utils/Mapper');

const TrialStatusState = Immutable.Record({
	inTrial: false,
	daysRemaining: 0,
	activeAccount: false,
	mailboxAccess: false,
	disabled: false,
	hasHosting: false
});

class TrialStatusStore extends ReduceStore {
	getInitialState() {
		return new TrialStatusState();
	}

	reduce(state, action) {
		switch (action.actionType) {
			case RemixerConstants.RECEIVE_TRIAL_STATUS:
				return new TrialStatusState(Mapper.toCamelCase(action.trial));
			case RemixerConstants.RECEIVE_TRIAL_STATUS_FAILS:
				return state;
			default:
				return state;
		}
	}
}

export default new TrialStatusStore(AppDispatcher);
