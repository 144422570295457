'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var ContentTypes = require('../constants/ContentTypes');
var ImportTypes = require('../constants/ImportTypes');
import { assign } from 'lodash';

/** @module ContentServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires ContentTypes
 * @requires ImportTypes
 * @requires lodash
 */
var ContentServerActionsCreator = {
	/**
	 * @method receiveContentUpdate
	 */
	receiveContentUpdate: function(contentType, content) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONTENT_UPDATE,
			contentType: contentType,
			content: content
		});
	},
	/**
	 * @method receiveContent
	 */
	receiveContent: function(contentType, response) {
		var data = ImportTypes.transformToEnum(ContentTypes.transformToEnum(response.data, 'content_type'), 'import_type');
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONTENT,
			contentType: contentType,
			content: data
		});
	},
	/**
	 * @method receiveContentFails
	 */
	receiveContentFails: function(contentType) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONTENT_FAILS,
			contentType: contentType,
			message: 'We could not get content'
		});
	},
	/**
	 * @method receiveContentDelete
	 */
	receiveContentDelete: function(contentId, contentType, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONTENT_DELETE,
			contentId: contentId,
			contentType: contentType,
			content: response.data
		});
	},
	/**
	 * @method receiveContentDeleteFails
	 */
	receiveContentDeleteFails: function(contentId, contentType) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONTENT_DELETE_FAILS,
			contentId: contentId,
			contentType: contentType,
			message: 'We could not delete content'
		});
	},

	/**
	 * @method receiveUpdateContentTags
	 */
	receiveUpdateContentTags: function(id, contentType, tags, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_CONTENT_TAGS,
			contentId: id,
			contentType: contentType,
			tags: response.data
		});
	},
	/**
	 * @method receiveUpdateContentTagsFails
	 */
	receiveUpdateContentTagsFails: function(id, contentType, tags) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_CONTENT_TAGS_FAILS,
			contentId: id,
			contentType: contentType,
			message: 'We could not update content tags'
		});
	},

	/**
	 * @method receiveContentPost
	 */
	receiveContentPost: function(contentType, content, response) {
		var altContent = assign({ id: response.id }, content);
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONTENT_POST,
			contentType: contentType,
			content: altContent
		});
	},
	/**
	 * @method receiveContentPostFails
	 */
	receiveContentPostFails: function(contentType) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONTENT_POST_FAILS,
			contentType: contentType,
			message: 'We could not add content'
		});
	},
	/**
	 * @method receiveContentPut
	 */
	receiveContentPut: function(id, contentType, content) {
		var altContent = assign({ id: id }, content);
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONTENT_PUT,
			contentType: contentType,
			content: altContent
		});
	},
	/**
	 * @method receiveContentPutFails
	 */
	receiveContentPutFails: function(id, contentType) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONTENT_PUT_FAILS,
			id: id,
			contentType: contentType,
			message: 'We could not update content'
		});
	}
};

module.exports = ContentServerActionsCreator;
