import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

const YoutubeSearchState = Immutable.Record({
	searching: false,
	total: 0,
	per_page: undefined,
	next_page: undefined,
	prev_page: undefined
});

class YoutubeSearchStateStore extends ReduceStore<YoutubeSearchState> {
	getInitialState(): YoutubeSearchState {
		return new YoutubeSearchState();
	}

	reduce(state: YoutubeSearchState, action: Object): YoutubeSearchState {
		switch (action.actionType) {
			case RemixerConstants.SEARCH_YOUTUBE: return state.merge({searching: true});
			case RemixerConstants.RECEIVE_SEARCH_YOUTUBE: return new YoutubeSearchState({searching: false, ...action.response.data});
			case RemixerConstants.RECEIVE_SEARCH_YOUTUBE_FAILS: return state.merge({searching: false});
			default: return state;
		}
	}
}

export default new YoutubeSearchStateStore(AppDispatcher);
