"use strict";

import { partial, assign } from 'lodash';

var Validator = require('./Validator');
var ShapeUtils = require('./ShapeUtils');

/** @module Shapes
 * @requires lodash
 * @requires Validator
 * @requires ShapeUtils
 */

/**
 * @method Size
 */
function Size(options) {
	if (options.size) options = options.size;
	Validator.isTypeOf(options, 'number', ['width', 'height']);

	this.width = options.width;
	this.height = options.height;
}

/**
 * @method Position
 */
function Position(options) {
	if (options.position) options = options.position;
	Validator.isTypeOf(options, 'number', ['top', 'left']);

	this.top = options.top;
	this.left = options.left;
}

/**
 * @method Rectangle
 */
function Rectangle(options) {
	this.position = new Position(options);
	this.size = new Size(options);
	this.zIndex = options.zIndex;

	this.toObject = partial(assign, {}, this.position, this.size, {zIndex: this.zIndex});
	this.right = function() {
		return this.position.left + this.size.width;
	};
	this.bottom = function() {
		return this.position.top + this.size.height;
	};
	this.changeRectangle = function (delta) {
		return new Rectangle(ShapeUtils.ChangeRect(this.toObject(), delta));
	};
	this.intersection = function(rectangle) {
		var rect = ShapeUtils.IntersectionRect(this.toObject(), rectangle.toObject());
		return rect && new Rectangle(rect);
	};
	this.xAxisMiddle = function() {
		return this.position.left + this.size.width / 2;
	};
	this.translate = function(dx, dy) {
		return new Rectangle(ShapeUtils.TranslateRect(this.toObject(), dx, dy));
	};
	this.moveYAxis = function(y) {
		return new Rectangle(ShapeUtils.MoveRect(this.toObject(), this.position.left, y));
	};
	this.moveXAxis = function(x) {
		return new Rectangle(ShapeUtils.MoveRect(this.toObject(), x, this.position.top));
	};
	this.clone = function() {
		return new Rectangle(this.toObject());
	};
}

module.exports = {
	Size: Size,
	Position: Position,
	Rectangle: Rectangle
};
