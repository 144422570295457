'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

import { keys, uniqueId } from 'lodash';

/** @module FlashMessageStore
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires lodash
 */
var SignUpErrorHandlerStore = StoreFactory.Create({
	/**
	 * @method _addError
	 */
	_addError: function(action) {
		this._remove(keys(this._get()));
		this._set(uniqueId(), action);
	},

	/**
	 * @method _globalHandler
	 */
	_globalHandler: function (action) {
		var error = null;
		switch (action.actionType) {
			case RemixerConstants.LOGIN_TO_ACCOUNT:
				error = { chainIsFailed: false, type: action.actionType };
				break;
			case RemixerConstants.REQUEST_NEW_ACCOUNT:
				error = { chainIsFailed: false, type: action.actionType };
				break;
			case RemixerConstants.RECEIVE_NEW_ACCOUNT_FAILS:
				error = { chainIsFailed: true, type: action.actionType };
				break;
			case RemixerConstants.RECEIVE_CREATE_WEBSITE_FAIL:
				error = { chainIsFailed: true, type: action.actionType };
				break;
			case RemixerConstants.RECEIVE_USER_INFO_FAIL:
				error = { chainIsFailed: true, type: action.actionType };
				break;
			case RemixerConstants.RECEIVE_TRIAL_STATUS_FAILS:
				error = { chainIsFailed: true, type: action.actionType };
				break;
		}
		if (error) {
			this._addError(error);
		}
	}
});

module.exports = SignUpErrorHandlerStore;
