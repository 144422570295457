function JavascriptRenderer(editMode) {
	this.render = function (template, context) {
		if (!template) return;
		if (editMode) {
			return 'window.EDIT_MODE = true; ' + template;
		} else {
			return template;
		}
	}
}

module.exports = JavascriptRenderer;
