'use strict';

import { isEmpty, toArray, sortBy, isEqual } from 'lodash';
var $ = require('jquery');

var ComponentUtils = require('./ComponentUtils');

var queue = [];
var loading = false;

/** @module DomManipulation
 * @requires lodash
 * @requires jquery
 * @requires ComponentUtils
 */
function DomManipulation(root) {
	var _root = root;

	var $_find = function(componentId) {
		return $(ComponentUtils.GetComponentSelector(componentId));
	};

	var _injectElement = function (parent, componentId, element) {
		var $element = $(element);
		var old = $_find(componentId).filter($element.prop('tagName'));
		if(old.is('*')) {
			old.replaceWith($element);
			if(ComponentUtils.IsActiveComponent(old)) ComponentUtils.ActivateComponentBy(componentId, parent);
		} else {
			$(parent).append($element);
		}
	};

	var _injectHtml = function(parent, html, parameters) {
		var element = ComponentUtils.CreateComponent(parameters.componentId, html);
		if (!element) return;

		_injectElement(parent, parameters.componentId, element);
	};

	var _handleQueue = function() {
		if (isEmpty(queue) || loading) return;

		var parameters = queue.shift();
		var onload = function() {
			loading = false;
			_handleQueue();
		};

		var element = ComponentUtils.CreateComponent(parameters.componentId, parameters.html);
		if (!element) return;

		var $element = $(element);
		if ($element.prop('src')) {
			loading = true;
			$.getScript($element.prop('src')).always(onload);
		} else {
			_injectElement(parameters.parent, parameters.componentId, $element);
		}

		_handleQueue();
	};

	var _injectChord = function(html, parameters) {
		_injectHtml(_root, html, parameters);
	};

	var _injectScript = function(html, parameters) {
		if (!html) return;

		queue.push({
			parent: document.head,
			componentId: parameters.componentId,
			html: html
		});
		_handleQueue();
	};

	var _injectStyle = function(html, parameters) {
		_injectHtml(document.head, html, parameters);
	};

	var _orderComponent = function() {
		var components = toArray($(ComponentUtils.GetComponentSelector(), _root));
		var sorted = sortBy(components, ComponentUtils.GetComponentPosition);

		if (!isEqual(sorted, components)) $(_root).append(sorted);
	};

	this.remove = function (parameters) {
		$_find(parameters.componentId).remove();
	};

	this.inject = function (parameters) {
		_injectChord(parameters.component.html, parameters);
		_injectScript(parameters.component.javascript, parameters);
		_injectStyle(parameters.component.stylesheet, parameters);

		_orderComponent();
	};
}

module.exports = DomManipulation;
