'use strict';

import { isFunction, first, forOwn, get, isUndefined, bind, assign, chain } from 'lodash';

var TextManipulationUtils = require('./TextManipulationUtils');

var PropertyUtils = require('../rendering/PropertyUtils');
var ComponentUtils = require('../rendering/ComponentUtils');

/** @module TextManipulation
 * @requires lodash
 * @requires TextManipulationUtils
 * @requires PropertyUtils
 * @requires ComponentUtils
 */
function TextManipulation() {

	this.execute = function(message) {
		// IE11 doesn't support styleWithCSS
		if (document.queryCommandSupported("styleWithCSS") && !document.queryCommandState("styleWithCSS")) {
			document.execCommand('styleWithCSS', null, true);
		}
		var method = this[message.command];
		if(!isFunction(method)) throw "Command '" + message.command + "' not is supported yet!";

		let methodWithArgs = method.bind(this, message.parameters || null);

		try {
			methodWithArgs();
		} catch (err){
			if (err.name === 'NS_ERROR_UNEXPECTED') { // fix issue https://bugzilla.mozilla.org/show_bug.cgi?id=889940
				TextManipulationUtils.FixExecuteCommandInFireFox(methodWithArgs);
			}
			else throw err;
		}
	};

	this.removeSelection = function() {
		TextManipulationUtils.RemoveSelection();
	};

	this.selectAll = function(parameters) {
		if (!parameters) return;

		var component = ComponentUtils.GetComponentElementBy(document.body, parameters.componentId);
		var property = first(PropertyUtils.GetAllProperties(component, parameters.path));

		//NOTE: fix in order to avoid autoscroll to focused element
		var x = window.scrollX, y = window.scrollY;
		property.focus();
		window.scrollTo(x, y);

		TextManipulationUtils.SelectAll(property);
	};

	this.fontsize = function(param) {
		var size = +param;
		if (isNaN(size)) return;
		var { fontsize } = TextManipulationUtils.GetBaseComponentFontSize()
		var remSize = (size / fontsize) + 'rem';
		var selection = TextManipulationUtils.GetSelection();
		var selectionSettings = TextManipulationUtils.GetSelectedTextState();
		if (!document.queryCommandSupported("insertHTML")) {
			// IE11 doesn't support insertHTML
			TextManipulationUtils.PasteHTML("<font style='font-size: " + remSize + "; background-color: initial;'>" + document.getSelection() + "</font>", true)
			TextManipulationUtils.SetSelection(selection);
		} else {
			document.execCommand("insertHTML", false, "<font style='font-size: " + remSize + "; background-color: initial;'>" + document.getSelection() + "</font>");
			TextManipulationUtils.SetSelection(selection);
		}
		var that = this;
		forOwn(selectionSettings, function(value, key) {
			if(key !== "fontsize" && key !== "link" && key !== "range" && value !== false){
				that.execute({"command": key, "parameters": value});
			}
		});
	};

	this.textShadow = function() {
		var element = TextManipulationUtils.GetSelectedElement();
		if (!element) return;
		var selectionSettings = TextManipulationUtils.GetSelectedTextState();
		if(isUndefined(selectionSettings['textShadow']) || !get(selectionSettings, 'textShadow')) {
			if(element.contentEditable) {
				// we need to edit the element inside of the contenteditable element. to do this we will execute a browser native
				// forecolor command that will create a new element around the selection which we will then style with a text shadow
				// then we will set the forecolor again back to the original style
				var addColor = true;
				this.execute({"command": "forecolor", "parameters": (selectionSettings['forecolor'] === "#000" ? "#fff" : "#000")});
			}
			element = TextManipulationUtils.GetSelectedElement();
			element.style.textShadow = "rgba(0, 0, 0, 0.5) 0px 2px 6px";
			if(addColor) {
				this.execute({"command": "forecolor", "parameters": selectionSettings['forecolor']});
			}
		}
		else {
			element.style.textShadow = null;
			TextManipulationUtils.TriggerInputEvent();
		}
	};

	this.removeFormatting = function() {
		TextManipulationUtils.RemoveLinks();
	};

	this.backupRange = function() {
		var selection = window.getSelection();
		if(selection.rangeCount){
			var range = selection.getRangeAt(0);
			this.currentSelection = {"startContainer": range.startContainer, "startOffset":range.startOffset,"endContainer":range.endContainer, "endOffset":range.endOffset};
		}
	}

	this.restoreRange = function() {
		if(this.currentSelection){
			var selection = window.getSelection();
			selection.removeAllRanges();
			var range = document.createRange();
			range.setStart(this.currentSelection.startContainer, this.currentSelection.startOffset);
			range.setEnd(this.currentSelection.endContainer, this.currentSelection.endOffset);
			selection.addRange(range);
		}
	}

	this.forecolor = function(parameters) {
		document.execCommand('foreColor', false, TextManipulationUtils.ParseStyleColor(parameters))
	}

}

//extend propotype with proxy that gonna just call related execCommand
var proxyFunctions = chain(["bold", "italic", "justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "fontname", "backColor", "CreateLink", "insertOrderedList", "insertUnorderedList"])
	.mapKeys()
	.mapValues(function(command) {return bind(document.execCommand, document, command, false); })
	.value();

assign(TextManipulation.prototype, proxyFunctions);

module.exports = TextManipulation;
