'use strict';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImportTypes = require('../../constants/ImportTypes');

var UserStore = require('../../stores/UserStore');
var ImportProgressStore = require('../../stores/ImportProgressStore');
var ImportHistoryStore = require('../../stores/ImportHistoryStore');
var ContentStore = require('../../stores/ContentStore');

var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var SoundcloudActionsCreator = require('../../actions/SoundcloudActionsCreator');
var UserInfoActionsCreator = require('../../actions/UserInfoActionsCreator');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');

var ContentTypes = require('../../constants/ContentTypes');

var _ = require('lodash');

/** @module SoundcloudImport
 * @requires react
 * @requires ComponentFactory
 * @requires ImportTypes
 * @requires UserStore
 * @requires ImportProgressStore
 * @requires ImportHistoryStore
 * @requires ContentStore
 * @requires ImportActionsCreator
 * @requires SoundcloudActionsCreator
 * @requires UserInfoActionsCreator
 * @requires ComponentActionsCreator
 * @requires ContentActionsCreator
 * @requires ContentTypes
 * @requires lodash
 */
var SoundcloudImport = ComponentFactory.Create(UserStore, ImportProgressStore, ImportHistoryStore, ContentStore, {
	displayName: "SoundcloudImport",
	/**
	 * @method getState
	 */
	getState: function () {
		var state = {
			returnTo: this.route().path,
			isUserAuthenticated: !!UserStore.getState('soundcloud_connected'),
			importProgress: ImportProgressStore.getState(ImportTypes.SOUNDCLOUD),
			ready: UserStore.getMetaState().filled && ImportProgressStore.getMetaState(ImportTypes.SOUNDCLOUD).filled,
			importedFileCount: _(ContentStore.getState())
				.values()
				.flatten()
				.filter((x) => _.isEqual(x.import_type, ImportTypes.SOUNDCLOUD))
				.filter((x) => _.isEqual(x.content_type, ContentTypes.SOUNDCLOUD))
				.size()
		};

		state.loading = !state.ready || state.importProgress.progress;
		return state;
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		UserInfoActionsCreator.loadUserInfo();
		ComponentActionsCreator.loadImportHistory(ImportTypes.SOUNDCLOUD);
		ContentActionsCreator.loadContent(ContentTypes.SOUNDCLOUD);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function() {
		this._importAutoStart();
	},

	/**
	 * @method _startImport
	 */
	_startImport: function() {
		if(this.state.isUserAuthenticated) {
			ImportActionsCreator.startImport(ImportTypes.SOUNDCLOUD);
		}
	},

	/**
	 * @method _importAutoStart
	 */
	_importAutoStart: function() {
		if (this.state.ready && !_(this.state.importProgress).values().compact().some()) {
			this._startImport();
		}
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(e) {
		if (!this.state.ready) {
			return;
		}
		if(this.state.isUserAuthenticated) {
			this._startImport();
		} else {
			SoundcloudActionsCreator.requestSoundcloudAuthorizationUrl(this.state.returnTo);
		}
	},

	/**
	 * @method render
	 */
	render: function () {
		var css = {
			'soundcloud': true,
			'active': this.state.isUserAuthenticated
		};
		var iconClassName = "mdi mdi-soundcloud" + (this.state.loading ? " pulse" : "");
		var wrapperClassName = 'media-source';
		return (
			<div className={wrapperClassName}>
				<div>
					<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import Soundcloud">
						<i className={iconClassName}></i>
					</div>
					<div className="import-icon-bubble-holder" >
						<div className={"bubbleNumber " + this.css(css)} >
							<div className="bubbleLeft"></div><div className="bubbleRight"></div>
							<div className="bubbleText"><p>{this.state.importedFileCount}</p></div>
						</div>
					</div>
				</div>
			</div>);
	}
});

module.exports = SoundcloudImport;
