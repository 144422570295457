import qwest from 'qwest'

export default class API2Requester {
	constructor(config) {
		this._headers = config.headers || {}
		this._endpoint = config.api_url || '/rpc'

		this._httpClient = qwest
		this._requestsMade = 0
	}

	request({ method, params, headers }) {
		if (typeof method !== 'string') return Promise.reject('Invalid method!')
		return this._httpClient
			.post(
				this._endpoint,
				{
					method,
					params,
					id: ++this._requestsMade,
					jsonrpc: '2.0'
				},
				{
					dataType: 'json',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'NDN-AUTH-CSRF': this.getCookie('csrf'),
						...this._headers
					}
				}
			)
			.then((xhr, response) => {
				if (xhr.status === 200) {
					if (response.error) return Promise.reject(response.error)
					if (response.result && response.result.error) return Promise.reject(response.result.error)

					return Promise.resolve(response.result)
				} else {
					return Promise.reject(xhr.statusText)
				}
			})
			.catch(err => {
				return Promise.reject(err)
			})
	}

	getCookie(name) {
		var value = "; " + document.cookie;
		var parts = value.split("; " + name + "=");
		if (parts.length == 2) return parts.pop().split(";").shift();
	}
}

