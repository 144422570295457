'use strict';

var Mapper = require('../utils/Mapper');

/** @module EditorCommands
 * @requires Mapper
 */
module.exports = Mapper.mirrorKey({
	textPropertyChange: null,
	domState: null
});
