'use strict';

import { without, isEqual, get, set, has, partial, isUndefined, forEach, some } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentContextStore = require('../../stores/ComponentContextStore');
var ContentStore = require('../../stores/ContentStore');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');

var PropertyMetadata = require('../../models/PropertyMetadata');

var ContentTypes = require('../../constants/ContentTypes');

var BlogsView = require('../common/BlogsView.react');
var BlogItem = require('../common/BlogItem.react');

/** @module BlogsInput
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires ContentStore
 * @requires ComponentActionsCreator
 * @requires ContentActionsCreator
 * @requires PropertyMetadata
 * @requires ContentTypes
 * @requires BlogsView
 * @requires BlogItem
 */
var BlogsInput = ComponentFactory.Create(ComponentContextStore, ContentStore, {
	displayName: "BlogsInput",
	propTypes: {
		property: React.PropTypes.instanceOf(PropertyMetadata).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			value: ComponentContextStore.getPropertyValue(this.props.property.name),
			blogs: ContentStore.getState(ContentTypes.BLOGPOST),
			images: ContentStore.getState(ContentTypes.IMAGE)
		};
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		if(!isUndefined(prevState) && !isEqual(this.state.value, prevState.value)) {
			var component = ComponentContextStore.getState('active');
			if(!isEqual(get(component.context, this.props.property.name), this.state.value)) {
				set(component.context, this.props.property.name, this.state.value);

				ComponentActionsCreator.updateComponentInstanceContext(component);
			}
		}

		ContentActionsCreator.loadContent(ContentTypes.BLOGPOST);
		ContentActionsCreator.loadContent(ContentTypes.IMAGE);
	},

	/**
	 * @method _compressBlog
	 */
	_compressBlog: function(e) {
		var url = "";
		var content = get(e, 'data', {});
		var images = get(this, ['state', 'images']);

		if (has(content, ['img', '0', 'url'])) {
			url = content.img[0].url;
		}else if(has(content, ['img', '0']) && images){
			forEach(images, function(item, i){
				if(content.img[0] === item.id) url = item.url;
			});
		}

		var blogContent = '';
		if ( has(content, 'parsed_content') ){
			for( var paragraphNum = 0; paragraphNum < content.parsed_content.length; paragraphNum++ ){
				if (content.parsed_content[paragraphNum].content.match(/\w/gi)) {
					blogContent = blogContent + content.parsed_content[paragraphNum].content;
				}
			}
		}

		return {
			title: e.data.title,
			url: e.data.url,
			"published_date": e.data.published_date,
			"comments_url": e.data.comments_url,
			summary: e.data.summary,
			tags: e.data.tags,
			"img": [{"url": url}],
			"parsed_content": [{"content": blogContent}]
		};
	},

	/**
	 * @method _onClickExpand
	 */
	_onClickExpand: function(e) {
		this.setState({ expanded: !this.state.expanded });
	},

	/**
	 * @method _singlePickerClick
	 */
	_singlePickerClick: function(e) {
		this.setState({ value: this._compressBlog(e) });
	},

	/**
	 * @method _multiPickerClick
	 */
	_multiPickerClick: function(e) {
		if(!some(this.state.value, partial(isEqual, this._compressBlog(e)))) {
			this.setState({ value: this.state.value.concat(this._compressBlog(e)) });
		}
	},

	/**
	 * @method _onPickedClick
	 */
	_onPickedClick: function(e) {
		this.setState({ value: without(this.state.value, this._compressBlog(e)) });
	},

	/**
	 * @method _onLibraryClick
	 */
	_onLibraryClick: function(e) {
		if(this.props.isSingleBlog) {
			this._singlePickerClick(e);
		} else {
			this._multiPickerClick(e);
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		var view;
		if (this.props.isSingleBlog) {

			view = (<BlogItem onClick={this._onPickedClick} data={this.state.value} images={this.state.images}/>);

		} else {
			view = (<div className="row">
				<span className="small-heading">picked: <span className="hint">click on blog to remove</span></span>
				<BlogsView onClick={this._onPickedClick} blogs={this.state.value} images={this.state.images}/>
			</div>);
		}

		var picker;
		if (this.state.expanded) {
			picker = (<div className="image-picker">
				{view}
				<div className="row">
					<span className="small-heading">library: <span className="hint">click on blog to pick</span></span>
					<BlogsView onClick={this._onLibraryClick} blogs={this.state.blogs} images={this.state.images}/>
				</div>
			</div>);
		}

		return (
			<div className={this.css('images-input', 'row', { expanded: this.state.expanded })}>
				<div className="columns input-label">
					<label className="left inline">
						<span className="small-heading">{this.props.property.descriprion}</span>
					</label>
				</div>
				<div className="columns input">
					<div className="row">
						<div className="columns input-label">
							<a className="button radius tiny" onClick={this._onClickExpand}>
								<i className={this.css('mdi', {
									"mdi-comment": !this.state.expanded,
									"mdi-close": this.state.expanded })}>
								</i>
							</a>
						</div>
					</div>
				</div>
				<div className="collapsible-content">{picker}</div>
			</div>);
	}
});

module.exports = BlogsInput;
