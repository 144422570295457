import Immutable from 'immutable';

const Record = Immutable.Record({
	loaded: false,
	loading: false,
	failed: false
});

class LoadingStatusRecord extends Record {
	needs() {
		return !(this.loading || this.loaded);
	}
}


export default LoadingStatusRecord;
