import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

const VimeoSearchState = Immutable.Record({
	searching: false,
	total: 0,
	per_page: undefined,
	next_page: undefined,
	prev_page: undefined
});

class VimeoSearchStateStore extends ReduceStore<VimeoSearchState> {
	getInitialState(): Object {
		return new VimeoSearchState();
	}

	reduce(state: VimeoSearchState, action: Object): VimeoSearchState {
		switch (action.actionType) {
			case RemixerConstants.SEARCH_VIMEO: return state.merge({searching: true});
			case RemixerConstants.RECEIVE_SEARCH_VIMEO: return new VimeoSearchState({searching: false, ...action.response.data});
			case RemixerConstants.RECEIVE_SEARCH_VIMEO_FAILS: return state.merge({searching: false});
			default: return state;
		}
	}
}

export default new VimeoSearchStateStore(AppDispatcher);
