import React from 'react';
import {Container} from 'flux/utils';

import VimeoSearchForm from './VimeoSearchForm.react';
import VimeoSearchResultList from './VimeoSearchResultList.react';

import VimeoSearchActionsCreator from '../../actions/VimeoSearchActionsCreator';
import ImportActionsCreator from '../../actions/ImportActionsCreator';

import VimeoSearchParamsStore from '../../stores/VimeoSearchParamsStore';
import VimeoSearchResultStore from '../../stores/VimeoSearchResultStore';
import VimeoSearchStateStore from '../../stores/VimeoSearchStateStore';

import ImportTypes from '../../constants/ImportTypes';

import i18n from '../../utils/i18n';

class VimeoSearch extends React.Component {
	static propTypes = {
		onPick: React.PropTypes.func,
		minimized: React.PropTypes.bool
	};
	static getStores() {
		return [VimeoSearchParamsStore, VimeoSearchResultStore, VimeoSearchStateStore];
	}
	static calculateState(prevState) {
		return {
			searchParams: VimeoSearchParamsStore.getState(),
			searchResult: VimeoSearchResultStore.getState(),
			searchState: VimeoSearchStateStore.getState()
		};
	}
	_search(query, page=1) {
		VimeoSearchActionsCreator.search(query, page);
	}
	_onChangeQuery = (query = "") => {
		query && this._search(query);
	};
	_onChangePage = (nextPage) => {
		const {query} = this.state.searchParams;
		this._search(query, nextPage);
	};
	_onPick = (searchResult) => {
		if (this.props.onPick) this.props.onPick(searchResult);
		else ImportActionsCreator.startImport(ImportTypes.VIMEO, searchResult.toJS());
	};
	render() {
		const {query} = this.state.searchParams;
		const {searching, next_page} = this.state.searchState;
		const isInitialState = !query || (!this.state.searchResult.size && searching);

		return (
			<div>
				<div className="title-bar row">
					{!this.props.minimized ?
						<div className="small-8 columns">
							<p className="title">
								{searching &&
									<i className="mdi mdi-reload rotate" />
								}
								{i18n.t(isInitialState ? "Search Vimeo" : "Click a video to add to your library")}
							</p>
						</div>
						:
						null
					}
					{isInitialState ?
						null
						:
						<div className="large-4 toolbar-search columns">
							<VimeoSearchForm onSearch={this._onChangeQuery} query={query} minimized={true} />
						</div>
					}
				</div>
				{isInitialState ?
					<div className="row vimeo-search-group">
						<VimeoSearchForm onSearch={this._onChangeQuery} query={query} minimized={false} />
					</div>
					:
					<div className="row vimeo-search-group">
						{this.state.searchResult.size ?
							<div>
								<VimeoSearchResultList searchResultList={this.state.searchResult} onPick={this._onPick} />
								{next_page ?
									<div className="collection-column more-column" onClick={() => this._onChangePage(next_page)}>
										<div className="more">
											<p className="more-text">More</p>
											<i className="mdi mdi-arrow-right-bold-circle-outline more-icon"></i>
										</div>
									</div>
									:
									null
								}
							</div>
							:
							<div className="row pixabay-search-group">
								<div className="large-12 columns pixabay-search-out search-form">
									<div className="row">
										<div className="large-12 columns text-center">No video found</div>
									</div>
								</div>
							</div>
						}
					</div>
				}
			</div>
		);
	}
}

export default Container.create(VimeoSearch);
