"use strict";

var PropertyTypes = require('../constants/PropertyTypes');
var Validator = require('../utils/Validator');
import { mapValues } from 'lodash';

/** @module PropertyMetadata
 * @requires PropertyTypes
 * @requires Validator
 */
var PropertyMetadata = function PropertyMetadata(dto) {
	Validator.isTypeOf(dto, 'string', ["name", "description"]);
	Validator.isNullableTypeOf(dto, "boolean", ["isSetting"]);

	if (dto.propertyType === 'array') {
		this.childSpec = mapValues(dto.childSpec, (v, k, o) => {
			v['name'] = k;
			return new PropertyMetadata(v);
		});
	} else {
		Validator.inRange(dto, PropertyTypes, ["propertyType"]);
	}
	if (dto.propertyType === 'options') {
		this.options = dto.options;
	}

	this.name = dto.name;
	this.propertyType = dto.propertyType;
	this.description = dto.description;
	this.isSetting = dto.isSetting;
};

module.exports = PropertyMetadata;
