import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

const PixabaySearchState = Immutable.Record({
	searching: false,
	total: 0,
	per_page: undefined
});

class PixabaySearchStateStore extends ReduceStore<Object> {
	getInitialState(): Object {
		return new PixabaySearchState();
	}

	reduce(state: Object, action: Object): boolean {
		switch (action.actionType) {
			case RemixerConstants.SEARCH_PIXABAY: return state.merge({searching: true});
			case RemixerConstants.RECEIVE_SEARCH_PIXABAY: return state.merge({searching: false, total: +action.response.data.total, per_page: +action.response.data.per_page});
			case RemixerConstants.RECEIVE_SEARCH_PIXABAY_FAILS: return state.merge({searching: false});
			default: return state;
		}
	}
}

export default new PixabaySearchStateStore(AppDispatcher);
