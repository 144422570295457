import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';
import CookieFetcher from '../utils/CookieFetcher';
import TrialActionsCreator from './TrialActionsCreator'
import WebsiteActionsCreator from './WebsiteActionsCreator';

/** @module SignupServerActionCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
export default {

	receiveNewAccount(pageId, pageName, response) {
		const { owner, goop, account_id } = response.data;
		const username = owner;
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_NEW_ACCOUNT,
			username,
			pageId,
			pageName
		},
		() => CookieFetcher.fetchCookie(username, goop,
			() => {
				TrialActionsCreator.startTrial(account_id, { pageId, pageName, redirect: 'websites' });
			}
		));
	},
	receiveExistedAccount(pageId, pageName, response) {
		const { owner, goop } = response.data;
		const username = owner
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_NEW_ACCOUNT,
			username,
			pageId,
			pageName
		},
		() => CookieFetcher.fetchCookie(username, goop,
			() => WebsiteActionsCreator.createWebsiteWithRedirect(
				pageId,
				pageName,
				'loged_in_editor'
			)
		))
	},

	receiveNewAccountFails(message) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_NEW_ACCOUNT_FAILS,
			message
		});
	}

};
