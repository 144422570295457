'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var WebAPI = require('../utils/WebAPI');

/** @module ThemeLikesActionCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var ThemeLikesActionCreator = {
	/**
	 * @method setThemeLike
	 */
	setThemeLike: function(themeId) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.ADD_THEME_LIKE,
			themeId: themeId
		},
		WebAPI.addThemeLike, [themeId]);
	},
	/**
	 * @method removeThemeLike
	 */
	removeThemeLike: function(themeId){
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REMOVE_THEME_LIKE
		},
		WebAPI.removeThemeLike, [themeId]);
	}
};

module.exports = ThemeLikesActionCreator;
