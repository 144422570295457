'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');


/** @module OverlaySoundcloud
 * @requires react
 * @requires ComponentFactory
 */
var OverlaySoundcloud = ComponentFactory.Create({
	displayName: "OverlaySoundcloud",
	propTypes: {
		content: React.PropTypes.array,
		contentItem: React.PropTypes.object.isRequired,
		picked: React.PropTypes.array,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	/**
	 * @method render
	 */
	render: function() {
		var src = "https://w.soundcloud.com/player/?url=https://api.soundcloud.com/tracks/"
				+ this.props.contentItem.track_id
				+ "&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&visual=true";
		return (
			<iframe
				className="content-item video-content-item"
				src={src}
				frameBorder="0"
				webkitallowFullScreen mozallowFullScreen allowFullScreen>
			</iframe>
		);
	}
});

module.exports = OverlaySoundcloud;
