"use strict";

var _ = require("lodash");

var StoreFactory = require("../infrastructure/StoreFactory");

var RemixerConstants = require('../constants/RemixerConstants');

var ComponentMetadata = require("../models/ComponentMetadata");

/** @module ComponentMetadataStore
 * @requires RemixerConstants
 */
var ComponentMetadataStore = StoreFactory.Create({
	/**
	 * @method getComponents
	 */
	getComponents: function() {
		return _(this.getState())
			.values()
			.flatten()
			.value();
	},

	/**
	 * @method getComponentMetadata
	 */
	getComponentMetadata: function(reference) {
		if (_.isUndefined(reference)) return;

		return _(this.getComponents())
			.filter(x => x.reference.name == reference.name)
			.filter(x => x.reference.version == reference.version)
			.first();
	},

	/**
	 * @method _setState
	 */
	_setState: function(action) {
		var components = _.groupBy(_.map(action.componentsMetadata, ComponentMetadata.Factory.Create), 'componentCategory');

		this._set(components);
	},

	/**
	 * @method _setFilling
	 */
	_setFilling: function(action) {
		this._filling();
	},

	/**
	 * @method _setFailed
	 */
	_setFailed: function(action) {
		this._failed();
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		handlers[RemixerConstants.RECEIVE_COMPONENTS_METADATA] = this._setState;
		handlers[RemixerConstants.RECEIVE_COMPONENTS_METADATA_FAIL] = this._setFailed;
		handlers[RemixerConstants.LOAD_COMPONENTS_METADATA] = this._setFilling;

		return handlers;
	}
});

module.exports = ComponentMetadataStore;
