import React from 'react';

import PreloadedImg from './PreloadedImg.react';

export default class VimeoSearchResult extends React.Component {
	static propTypes = {
		searchResult: React.PropTypes.object.isRequired,
		onPick: React.PropTypes.func.isRequired
	};

	render() {
		const {searchResult, onPick} = this.props;

		return (
			<div className="collection-column" onClick={onPick}>
				<div className="collection-out" >
					<PreloadedImg
						className="primary"
						imageUrl={searchResult.url}
						showLoadingPlaceholder={false}
						component="div"
					>
					</PreloadedImg>
					<div className="collection-name" ><p><i className="mdi mdi-vimeo" /> {searchResult.title}</p></div>
				</div>
			</div>
		);
	}
}
