'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImportTypes = require('../../constants/ImportTypes');

var ConfigStore = require('../../stores/ConfigStore');
var UserStore = require('../../stores/UserStore');
var ImportProgressStore = require('../../stores/ImportProgressStore');
var ImportHistoryStore = require('../../stores/ImportHistoryStore');

var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var UserInfoActionsCreator = require('../../actions/UserInfoActionsCreator');
var ConfigActionsCreator = require('../../actions/ConfigActionsCreator');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

var ThirdPartyWebAPI = require('../../utils/ThirdPartyWebAPI');
var UrlUtils = require('../../utils/UrlUtils');

var ContentStore = require('../../stores/ContentStore');
var ContentTypes = require('../../constants/ContentTypes');

/** @module InstagramImport
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ImportTypes
 * @requires ConfigStore
 * @requires UserStore
 * @requires ImportProgressStore
 * @requires ImportHistoryStore
 * @requires ImportActionsCreator
 * @requires UserInfoActionsCreator
 * @requires ConfigActionsCreator
 * @requires ComponentActionsCreator
 * @requires ThirdPartyWebAPI
 * @requires UrlUtils
 * @requires ContentStore
 * @requires ContentTypes
 */
var InstagramImport = ComponentFactory.Create(UserStore, ConfigStore, ImportProgressStore, ImportHistoryStore, ContentStore, {
	displayName: "InstagramImport",
	/**
	 * @method getState
	 */
	getState: function() {
		var state = {
			clientId: ConfigStore.getState('instagramClientId'),
			redirectUrl: ConfigStore.getState('instagramRedirectUri'),
			returnTo: this.route().path,
			isUserAuthenticated: UserStore.getMetaState().filled && !!UserStore.getState('instagram_connected'),
			importProgress: ImportProgressStore.getState(ImportTypes.INSTAGRAM),
			ready: UserStore.getMetaState().filled && ConfigStore.getMetaState().filled && ImportProgressStore.getMetaState(ImportTypes.INSTAGRAM).filled,

			importedFileCount: _(ContentStore.getState())
				.values()
				.flatten()
				.filter((x) => _.isEqual(x.import_type, ImportTypes.INSTAGRAM))
				.filter((x) => _.isEqual(x.content_type, ContentTypes.IMAGE))
				.size()
		};
		state.loading = !state.ready || state.importProgress.progress;
		return state;
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function() {
		UserInfoActionsCreator.loadUserInfo();
		ConfigActionsCreator.loadConfig();
		ComponentActionsCreator.loadImportHistory(ImportTypes.INSTAGRAM);

		this._importAutoStart();
	},

	/**
	 * @method _startImport
	 */
	_startImport: function() {
		if(this.state.isUserAuthenticated && !this.state.importProgress.progress) {
			ImportActionsCreator.startImport(ImportTypes.INSTAGRAM);
		}
	},

	/**
	 * @method _importAutoStart
	 */
	_importAutoStart: function() {
		if (this.state.ready && !_(this.state.importProgress).values().compact().some()) {
			this._startImport();
		}
	},

	/**
	 * @method _onClick
	 */
	_onClick: function() {
		if (!this.state.ready) {
			return;
		}
		if(this.state.isUserAuthenticated) {
			this._startImport();
		} else {
			var instagramAuthUrl = ThirdPartyWebAPI.buildInstagramAuthUrl(
				this.state.clientId, this.state.redirectUrl, this.state.returnTo);

			UrlUtils.navigateTo(instagramAuthUrl);
		}
	},

	/**
	 * @method _onMouseEnter
	 */
	_onMouseEnter: function() {
		this.setState({mouseOver: true});
	},

	/**
	 * @method _onMouseLeave
	 */
	_onMouseLeave: function() {
		this.setState({mouseOver: false});
	},

	/**
	 * @method render
	 */
	render: function () {
		var css = {
			'instagram': true,
			'active': this.state.isUserAuthenticated
		};

		var iconClassName = "mdi mdi-instagram" + (this.state.loading ? " pulse" : "");

		var wrapperClassName;
		var output;

		if(_.get(this.props, "button", false)){
			output = (<div className="import-header-column import-header-column-button bg-overlay instagram" onClick={this._onClick}>
				<div>
					<div className="show-for-large-up import-label" >
						<p >{this.props.title}</p>
						<h3 >{this.props.tagline}</h3>
					</div>
					<div className="import-icon-holder" >
						<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import Instagram">
							<i className={iconClassName}></i>
						</div>
						<div className="import-icon-bubble-holder" >
							<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{this.state.importedFileCount}</p></div></div>
						</div>
					</div>
				</div>
			</div>);
		} else {
			wrapperClassName = 'media-source';
			output = (<div>
				<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import Instagram">
					<i className={iconClassName}></i>
				</div>
				<div className="import-icon-bubble-holder" >
					<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{this.state.importedFileCount}</p></div></div>
				</div>
			</div>);
		}
		return (<div className={wrapperClassName}>{output}</div>);
	}
});

module.exports = InstagramImport;
