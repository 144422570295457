'use strict';

import { map } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImageLink = ComponentFactory.Create( {
	displayName: "ImageLink",
	propTypes: {
		types: React.PropTypes.array,
		currentSelection: React.PropTypes.string,
		selectionChange: React.PropTypes.func,
		onLinkSave: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return null;
	},

	_handleSelectionChange: function(event) {
		var value = event.target.value;
		this.props.selectionChange(value);
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div>
				<button onClick={this.props.onLinkSave}>Save</button>
				<select value={this.props.currentSelection} onChange={this._handleSelectionChange} >
					{map(this.props.types, function(item) {
						return (<option key={item.key} value={item.key}>{item.name}</option>);
					})}
				</select>
				{this.props.children}
			</div>
		)
	}
});

module.exports = ImageLink;
