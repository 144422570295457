import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

/** @module PixabayServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
export default {
	receiveSearchPixabay(query, category, page, compact, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SEARCH_PIXABAY,
			query,
			category,
			page,
			compact,
			response
		});
	},
	receiveSearchPixabayFails(query, category, page, compact, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SEARCH_PIXABAY_FAILS,
			query,
			category,
			page,
			compact,
			response
		});
	}
};
