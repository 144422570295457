'use strict';

var Enum = require('../utils/Enum');

var ContentTypes = new Enum();

/** @module ContentTypes
 * @requires Enum
 */
function createContentTypeEnumEntry(name, resourceName) {
	return ContentTypes.createEnumEntry(name, {resourceName: resourceName});
}

ContentTypes.IMAGE = createContentTypeEnumEntry('image', 'image');
ContentTypes.PAGE = createContentTypeEnumEntry('page', 'page');
ContentTypes.BLOGPOST = createContentTypeEnumEntry('blogpost', 'blogpost');
ContentTypes.TWEET = createContentTypeEnumEntry('tweet', 'tweet');
ContentTypes.YOUTUBE = createContentTypeEnumEntry('youtube', 'youtube');
ContentTypes.TEXT = createContentTypeEnumEntry('text', 'text');
ContentTypes.MARKDOWN = createContentTypeEnumEntry('markdown', 'markdown');
ContentTypes.VIDEO = createContentTypeEnumEntry('video', 'video');
ContentTypes.VIMEO = createContentTypeEnumEntry('vimeo', 'vimeo');
ContentTypes.SOUNDCLOUD = createContentTypeEnumEntry('soundcloud', 'soundcloud');
ContentTypes.TAG = createContentTypeEnumEntry('tag', 'tag');

module.exports = ContentTypes;
