'use strict';

var sockjs = require('sockjs-client');
var Validator = require('../utils/Validator');
var WebSocketHandler = require('../utils/WebSocketHandler');
import { includes, bind, delay } from 'lodash'

var RECONNECT_DELAY = 2000;

/** @module WebSocketClient
 * @requires lodash
 * @requires sockjs-client
 * @requires Validator
 * @requires WebSocketHandler
 */
function WebSocketClient(uri, token, handler) {
	Validator.isTypeOf(uri, 'string');
	Validator.isTypeOf(token, 'string');
	Validator.isInstanceOf(handler, WebSocketHandler);

	var _uri = uri;
	var _token = token;
	var _handler = handler;

	var _connection;
	var _status = [];

	this.isReady = function() {
		return includes(_status, 'ready');
	};
	this.hasError = function() {
		return includes(_status, 'token_error');
	};

	this.retry = bind(function() {
		this.disconnect();
		this.connect();
	}, this);

	var retry = this.retry;
	this.connect = function() {
		//opened
		if(!_connection) {
			_connection = new sockjs(_uri, [
				'websocket',
				'xhr-streaming',
				'iframe-eventsource',
				'iframe-htmlfile',
				'xhr-polling',
				'iframe-xhr-polling',
				'jsonp-polling'
			]);

			_connection.onopen = function() {
				_status.push('connected');
				_connection.send(JSON.stringify({ token: _token }));
			};
			_connection.onmessage = function(msg) {
				if(!includes(_status, 'ready')) {
					try {
						if(msg.data.token === _token && msg.data.success) _status.push('ready');
					} catch (e) {
						if (!includes(_status, 'token_error')) {
							_status.push('token_error');
						}
					}
				} else {
					_handler.process(msg);
				}
			};
			_connection.onclose = function() {
				delay(function() {
					if(_connection) { // this check prevents reconnection after disconnect()
						retry();
					}
				}, RECONNECT_DELAY);
			};
		}
	};
	this.disconnect = function() {
		if(!_connection) return;

		_connection.close();
		_connection = null;
		_status = [];
	};
}

module.exports = WebSocketClient;
