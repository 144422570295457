'use strict';

var Mapper = require('../utils/Mapper');

/** @module ComponentTypes
 * @requires Mapper
 */
module.exports = Mapper.mirrorKey({
	base: null,
	meta: null,
	content: null,
	hidden: null,
	script: null
});
