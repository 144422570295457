'use strict';

import { create, constant } from 'lodash';

var ComponentDomAttributes = require('../../constants/ComponentDomAttributes');

/** @module Preprocessors
 * @requires lodash
 * @requires ComponentDomAttributes
 */

function TemplatePreprocessor() {
	this.propertyAttr = ComponentDomAttributes.property;

	this.process = function(template) {
		var regex = this.getRegex(),
			match, templateLength, offset;

		regex.lastIndex = 0;
		while ((match = regex.exec(template)) !== null) {
			templateLength = template.length;
			template = this.inject(match, template);
			offset = template.length - templateLength;

			if (offset > 0 || match.index === regex.lastIndex) regex.lastIndex += offset;
		}

		return template;
	};
	this.remove = function(input, start, length) {
		return input.slice(0, start) + input.slice(start + length);
	};
	this.insert = function(input, target, index) {
		return input.slice(0, index) + target + input.slice(index);
	};
}

function TextPreprocessor() {
	TemplatePreprocessor.call(this);

	this.getRegex = constant(
		/(\{\{\{?([^\/#][^}\s@]+)\}\}\}?)(?![^<>]*>)/gmi
	);
	this.inject = function(match, template) {
		var targetWithBrackets = match[1];
		var target = match[2];

		//NOTE: we have to be sure that there is three brackets like: {{{target}}}
		template = this.remove(template, match.index, targetWithBrackets.length);
		template = this.insert(template, "{{{" + target + "}}}", match.index);

		return template;
	};
}

function EditablecontentTextPreprocessor() {
	TextPreprocessor.call(this);

	this.inject = function(match, template) {
		var targetWithBrackets = match[1];
		var target = match[2];

		//NOTE: we have to be sure that there is three brackets like: {{{target}}}
		template = this.remove(template, match.index, targetWithBrackets.length);
		template = this.insert(template,
			"<div " + this.propertyAttr + "='{{{property " + target + "}}}' contenteditable='true' style='display: inline'>{{{" +
				target +
			"}}}</div>", match.index);

		return template;
	};
}

function BackgroundUrlImgPreprocessor() {
	TemplatePreprocessor.call(this);

	this.getRegex = constant(
		/\bstyle\s*=\s*['"][^'"]*background(?:-image)?[^;]+url\(\s*['"]?\s*\{\{\{?([^\/#][^}\s@]+)\}\}\}?\s*['"]?\s*\)[^'"]*['"]/gmi
	);

	this.inject = function(match, template) {
		var whole = match[0];
		var property = match[1];

		template = this.insert(template, " " + this.propertyAttr + "='{{{property " + property + "}}}'", match.index + whole.length);

		return template;
	};
}

function SrcImgPreprocessor() {
	TemplatePreprocessor.call(this);

	this.getRegex = constant(
		/<img[^>]+\bsrc\s*=\s*['"]?\s*\{\{\{?([^\/#][^}\s@]+)\}\}\}?\s*['"]/gmi
	);

	this.inject = function(match, template) {
		var whole = match[0];
		var property = match[1];

		template = this.insert(template, " " + this.propertyAttr + "='{{{property " + property + "}}}'", match.index + whole.length);

		return template;
	};
}

function IframePreprocessor() {
	TemplatePreprocessor.call(this);

	this.getRegex = constant(
		/<iframe[^>]+\bsrc\s*=\s*['"]?\s*\{\{\{?([^\/#][^}\s@]+)\}\}\}?\s*['"]/gmi
	);

	this.inject = function(match, template) {
		var whole = match[0];
		var property = match[1];

		template = this.insert(template, " " + this.propertyAttr + "='{{{property " + property + "}}}'", match.index + whole.length);

		return template;
	};
}

function LinkPreprocessor() {
	TemplatePreprocessor.call(this);

	this.getRegex = constant(
		/<a[^>]+\bhref\s*=\s*['"]?\s*\{\{\{?([^\/#][^}\s@]+)\}\}\}?\s*['"]/gmi
	);

	this.inject = function(match, template) {
		var whole = match[0];
		var property = match[1];

		template = this.insert(template, " " + this.propertyAttr + "='{{{property " + property + "}}}'", match.index + whole.length);

		return template;
	};
}

TextPreprocessor.prototype = create(TemplatePreprocessor.prototype, {
	'constructor': TextPreprocessor
});
EditablecontentTextPreprocessor.prototype = create(TextPreprocessor.prototype, {
	'constructor': EditablecontentTextPreprocessor
});
SrcImgPreprocessor.prototype = create(TemplatePreprocessor.prototype, {
	'constructor': SrcImgPreprocessor
});
BackgroundUrlImgPreprocessor.prototype = create(TemplatePreprocessor.prototype, {
	'constructor': BackgroundUrlImgPreprocessor
});
IframePreprocessor.prototype = create(TemplatePreprocessor.prototype, {
	constructor: IframePreprocessor
});
LinkPreprocessor.prototype = create(TemplatePreprocessor.prototype, {
	constructor: LinkPreprocessor
});

module.exports = {
	TextPreprocessor: TextPreprocessor,
	EditablecontentTextPreprocessor: EditablecontentTextPreprocessor,
	SrcImgPreprocessor: SrcImgPreprocessor,
	BackgroundUrlImgPreprocessor: BackgroundUrlImgPreprocessor,
	IframePreprocessor: IframePreprocessor,
	LinkPreprocessor: LinkPreprocessor
};
