"use strict";

var _ = require("lodash");

/** @module Mapper
 * @requires lodash
 */
function _toCamelCase(object) {
	if (!_.isObject(object)) return object;
	if (_.isArray(object)) return _.map(object, _toCamelCase);
	return _(object)
		.mapKeys(function(v, k) { return _.camelCase(k); })
		.mapValues(_toCamelCase)
		.value();
}

function _toSnakeCase(object) {
	if (!_.isObject(object)) return object;
	if (_.isArray(object)) return _.map(object, _toSnakeCase);
	return _(object)
		.mapKeys(function(v, k) { return _.snakeCase(k); })
		.mapValues(_toSnakeCase)
		.value();
}

function _mirrorKey(object) {
	return _.mapValues(object, function(v, k) { return k; });
}

module.exports = {
	toCamelCase: _toCamelCase,
	toSnakeCase: _toSnakeCase,
	mirrorKey: _mirrorKey
};
