'use strict';

import { get } from 'lodash';
var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');
var ContentTypes = require('../../../constants/ContentTypes');

var NoResultsWrapper = require('../content-lists/NoResultsWrapper.react');
var FilterWrapper = require('../content-lists/FilterWrapper.react');
var Preview = require('../content-lists/Preview.react');
var Overlay = require('../content-details/Overlay.react');

var config = {
	'higher-order-components': [
		FilterWrapper,
		NoResultsWrapper
	]
};

/** @module MarkdownTexts
	 * @requires lodash
	 * @requires react
	 * @requires ComponentFactory
	 * @requires ContentTypes
	 * @requires NoResultsWrapper
	 * @requires FilterWrapper
	 * @requires Preview
	 * @requires Overlay
	 */
var MarkdownTexts = ComponentFactory.Config(config).Create({
	displayName: "MarkdownTexts",
	propTypes: {
		content: React.PropTypes.array,
		contentItem: React.PropTypes.object,
		picked: React.PropTypes.array,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func,
		onUpdate: React.PropTypes.func,
		action: React.PropTypes.string
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function(){
		return {contentItem: {"content_type": ContentTypes.MARKDOWN, tags: []},
			noDelete: false};
	},

	/**
	 * @method componentWillReceiveProps
	 */
	componentWillReceiveProps: function(nextProps){
		if (get(nextProps, 'action')) {
			this.setState({contentItem: {"content_type": ContentTypes.MARKDOWN, tags: []}});
			this.setState({noDelete: true});
		} else {
			this.setState({contentItem: nextProps.contentItem});
			this.setState({noDelete: false});
		}
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function(){
		if (get(this.props, 'action')) {
			this.setState({contentItem: {"content_type": ContentTypes.MARKDOWN, tags: []}});
			this.setState({noDelete: true});
		} else {
			this.setState({contentItem: this.props.contentItem});
			this.setState({noDelete: false});
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div>
				<Preview {...this.props} />
				<Overlay {...this.props} noArrow={true} noTags={true} contentItem={this.state.contentItem} noDelete={this.state.noDelete}/>
			</div>
		);
	}
});

module.exports = MarkdownTexts;
