'use strict';

import { map, matchesProperty, union } from 'lodash';

var ImportTypes = require('./ImportTypes');
var ContentTypes = require('./ContentTypes');
var ContentFilterTypes = require('./ContentFilterTypes');

/** @module ContentFilters
 * @requires lodash
 * @requires ImportTypes
 * @requires ContentTypes
 * @requires ContentFilterTypes
 */
function createContentFilter(name, type, matcher) {
	return {
		name: name,
		type: type,
		matcher: matcher
	};
}

var dateFilters = [
	createContentFilter("LAST_HOUR", ContentFilterTypes.DATE,
		function(contentItem, content) {
			var hour = 60 * 60 * 1000;
			var timeElapsed = Date.now() - Date.parse(contentItem.created + "Z");
			return timeElapsed < hour;
		}),
	createContentFilter("TODAY", ContentFilterTypes.DATE,
		function(contentItem, content) {
			var today = new Date();
			var date = new Date(Date.parse(contentItem.created + "Z"));
			return today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate();
		}),
	createContentFilter("YESTERDAY", ContentFilterTypes.DATE,
		function(contentItem, content) {
			var today = new Date();
			var yesterday = new Date(today);
			yesterday.setDate(today.getDate() - 1);
			var date = new Date(Date.parse(contentItem.created + "Z"));
			return yesterday.getFullYear() === date.getFullYear() && yesterday.getMonth() === date.getMonth() && yesterday.getDate() === date.getDate();
		}),
	createContentFilter("THIS_WEEK", ContentFilterTypes.DATE,
		function(contentItem, content) {
			var today = new Date();
			var firstday = new Date(today.setDate(today.getDate() - today.getDay()));
			var lastday = new Date(today.setDate(today.getDate() - today.getDay() + 6));
			var date = new Date(Date.parse(contentItem.created + "Z"));
			return lastday.getTime() > date.getTime() && date.getTime() > firstday.getTime();
		}),
	createContentFilter("THIS_MONTH", ContentFilterTypes.DATE,
		function(contentItem, content) {
			var today = new Date();
			var date = new Date(Date.parse(contentItem.created + "Z"));
			return today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth();
		}),
	createContentFilter("THIS_YEAR", ContentFilterTypes.DATE,
		function(contentItem, content) {
			var today = new Date();
			var date = new Date(Date.parse(contentItem.created + "Z"));
			return today.getFullYear() === date.getFullYear();
		})
];

var importTypeFilters = map(ImportTypes, function(importType) {
	return createContentFilter(importType.name, ContentFilterTypes.IMPORT_TYPE, matchesProperty('import_type', importType));
});

var contentTypeFilters = map(ContentTypes, function(contentType) {
	return createContentFilter(contentType.name, ContentFilterTypes.CONTENT_TYPE, matchesProperty('content_type', contentType));
});

var tagFilters = [];

module.exports.createContentFilter = createContentFilter;
module.exports.dateFilters = dateFilters;
module.exports.importTypeFilters = importTypeFilters;
module.exports.contentTypeFilters = contentTypeFilters;
module.exports.tagFilters = tagFilters;

module.exports.filters = union(dateFilters, importTypeFilters, contentTypeFilters, tagFilters);
