'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var WebAPI = require('../utils/WebAPI');

/** @module TwitterActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var TwitterActionsCreator = {
	/**
	 * @method requestTwitterAuthorizationUrl
	 */
	requestTwitterAuthorizationUrl: function(returnToUrl) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REQUEST_TWITTER_AUTHORIZATION_URL,
			returnTo: returnToUrl
		},
		WebAPI.getTwitterAuthorizationUrl, [returnToUrl]);
	}
};

module.exports = TwitterActionsCreator;
