import React from 'react';
import {Container} from 'flux/utils';

import {daysDifference, minutesDifference, dateToUTC} from '../../utils/DateUtils';

import PublishStatuses from '../../constants/PublishStatuses';

import WebsitesStore,  {WebsitesLoadingStatusStore} from '../../stores/WebsitesStore';
import PageProjectStore, {PageProjectsLoadingStatusStore} from '../../stores/PageProjectStore';
import SitePublisherStore from '../../stores/SitePublisherStore';

var RouteStore = require('../../stores/RouteStore');

var WebsiteActionsCreator = require('../../actions/WebsiteActionsCreator');
var PageProjectActionsCreator = require('../../actions/PageProjectActionsCreator');
var PublishActionsCreator = require('../../actions/PublishActionsCreator');
var ExportWordPressActionsCreator = require('../../actions/ExportWordPressActionsCreator');

import WebsitesButtons from '../common/WebsitesButtons.react';

class Websites extends React.Component {
	static getStores() {
		return [
			PageProjectStore,
			WebsitesStore,
			WebsitesLoadingStatusStore,
			PageProjectsLoadingStatusStore,
			SitePublisherStore
		];
	}

	static calculateState(prevState){
		return {
			publishing: SitePublisherStore.getState(),
			websiteList: WebsitesStore.getState(),
			pageProjectList: PageProjectStore.getState(),
			loading: WebsitesLoadingStatusStore.getState().loading
				|| PageProjectsLoadingStatusStore.getState().loading
		};
	}

	openWPAdmin(website) {
		let newWindow = window.open('', '_blank');
		ExportWordPressActionsCreator.requestLoginWordPressAdmin(website, newWindow);
	}

	componentDidMount() {
		WebsiteActionsCreator.loadWebsites(true);
		PageProjectActionsCreator.loadPageProjects(true);

	}

	statusIcon(status) {
		switch (status) {
			case PublishStatuses.PUBLISHED:
				return (<p className="published status">
					Published
				</p>)
			case PublishStatuses.WPEXPORTED:
				return (<p className="published status">
					Exported to WP
				</p>)
			case PublishStatuses.WPEXPORTING:
				return null
			default:
				return (<p className="unpublished status">
					Unpublished Changes
				</p>)
		}
	}

	republishModal(website) {
		if(this.state.showModal === website.id) {
			return(
				<div className="backdrop row">
					<div className="modal small-6 small-centered columns">
						<h2>Reset your original site back to Remixer</h2>
						<span style={{color: 'red'}}>Any changes made within the WordPress administration console will not be copied over.</span>
						<br />
						<span>Please check out our <a href="https://help.dreamhost.com/hc/en-us/articles/215089888-WordPress-backup-tools">instructions</a> on backing up WordPress or <a href="https://panel.dreamhost.com/index.cgi?tree=support.msg&">contact support</a> for backups within 30 days of reset.</span>
						<br />
						<div className="website-block-export exported">
							<div className="website-block-export-inner">
								<div className="website-block-export-buttons">
									<ul className="website-block-export-actions">
										<li className="website-block-export-actions-primary">
											<div className="website-block-actions-button" onClick={() => {PublishActionsCreator.startPublishSite(website.id); this.setState({showModal: false})}}>
												<p >Reset</p>
											</div>
										</li>
										<li className="website-block-export-actions-secondary">
											<div className="website-block-actions-button" onClick={() => this.setState({showModal: false})}>
												<p >Cancel</p>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return;
		}
	}


	buttons(status, website) {
		switch (status) {
			case PublishStatuses.WPEXPORTED: {
				const minutesSinceExport = minutesDifference(new Date(website.exported), dateToUTC(new Date()));
				return (<div className="website-block-export exported">
					<div className="website-block-export-inner">
						<div className="website-block-export-buttons">
							<ul className="website-block-export-actions">
								<li className="website-block-export-actions-secondary">
									<div className="website-block-actions-button" onClick={() => this.setState({showModal: website.id})}>
										<p >Reset to Remixer</p>
									</div>
								</li>
								{minutesSinceExport <= 30 ?
									<li className="website-block-export-actions-primary form-tool-tip">
										<a onClick={() => this.openWPAdmin(website)} className="website-block-actions-button">
											<p >Open WP-Admin<i className={"mdi mdi-alert"}></i></p>
										</a>
										<span className="form-tool-tip-text" >WordPress has been installed however the domain is pointing at a new server and may not yet be accessible. Please allow up to 4 hours for DNS to propagate.</span>
									</li>
									: <li className="website-block-export-actions-primary">
										<a onClick={() => this.openWPAdmin(website)} className="website-block-actions-button">
											<p >Open WP-Admin</p>
										</a>
									</li>}
							</ul>
						</div>
					</div>
				</div>)
			}
			case PublishStatuses.WPEXPORTING:
				return (<div className="website-block-export exporting">
					<div className="website-block-export-inner">
						<i className={"mdi mdi-wordpress"}></i>
						<div className="website-block-export-text">
							<p className="website-block-export-heading">Export in progress</p>
							<p className="website-block-export-subtext">It may take up to 30 minutes to complete</p>
						</div>
					</div>
				</div>)
			default:
				return (<WebsitesButtons websiteId={website.id}/>)
		}
	}

	render() {
		return (
			<div className="row websites-page">
				<div className="small-12 small-centered columns">
					<h1>My Sites</h1>
				</div>
				<div className="small-12 small-centered columns">
					<h1>
						<a className="button-major" href={RouteStore.getState().route.makeHref("sitetailor")}>
							<div className="left desc"><span>Create a new site</span></div>
							<div className="right icon"><i className="mdi mdi-plus"></i></div>
						</a>
						<a className="button-major browse" href={RouteStore.getState().route.makeHref("themes")}>
							<div className="left desc"><span>Browse Themes</span></div>
						</a>
					</h1>
				</div>
				<div className="small-8 small-centered columns all-websites">
					{this.state.loading ?
						<span className="loader"></span>
						: <ul className="small-block-grid-1 large-block-grid-2">
							{this.state.websiteList.map(website => {
								let pageProject = this.state.pageProjectList.get(website.mainPageProjectId);
								let status = (this.state.publishing && this.state.publishing.get(website.id)) || website.status
								return (pageProject &&
									<li key={website.id}>
										<div className="block website-block">
											<div className="website-block-top">
												<img src={pageProject.thumbnail || "https://placehold.it/440x165/ffffff/444444"}/>
											</div>
											<div className="website-block-bottom block-padding">
												<div className="status-icon">
													{this.statusIcon(status)}
												</div>
												<h2 className="website-block-title">{website.name}</h2>
												<p className="website-block-url">
													{website.published ?
														<a href={website.domain ? "http://" + website.domain : website.publicUrl}
															target="_blank"
															className="website-block-url">
															{website.domain || "DreamHost Cloud"}
														</a>
														:
														<a className="website-block-domain">
															{website.domain || "DreamHost Cloud"}
														</a>
													}
												</p>
												{(status !== PublishStatuses.WPEXPORTED) && (status !== PublishStatuses.WPEXPORTING) ? <p className="website-block-publish-info">Last
													edited {daysDifference(new Date(website.edited), new Date())}</p>: null}
											</div>
											{this.buttons(status, website)}
											{this.republishModal(website)}
										</div>
									</li>
								);
							})}
						</ul>
					}
				</div>
			</div>
		);
	}
}

export default Container.create(Websites);
