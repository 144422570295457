import React from 'react';
import { some } from 'lodash'

import Form from './common/Form.react';

export default class PageSettingsForm extends Form {
	constructor(props) {
		super(props);
		this.handelBlur = this.handelBlur.bind(this);
	}
	handelBlur() {
		let {errors} = this.state;
		if (some(errors)) {
			this.reset();
			return;
		}
		super.save();
	}
	render() {
		return (
			<div className="page-settings-form">
				<div>
					<div className="small-heading">Page Title</div>
					<input type="text" name="title" placeholder="Type Page Title here..." value={this.state.model.title} onChange={this.handleInputChange} onBlur={this.handelBlur} />
					{this.state.errors.title && <small className="error">Invalid entry</small>}
				</div>
				<div>
					<div className="small-heading">Page Description</div>
					<input type="text" name="description" placeholder="Type Page Description here..." value={this.state.model.description} onChange={this.handleInputChange} onBlur={this.handelBlur} />
					{this.state.errors.description && <small className="error">Invalid entry</small>}
				</div>
				{this.props.model.slug &&
				<div>
					<div className="small-heading">Page Slug (URL)</div>
					<input type="text" name="slug" placeholder="" value={this.state.model.slug} onChange={this.handleInputChange} onBlur={this.handelBlur} />
					{this.state.errors.slug && <small className="error">Invalid entry</small>}
				</div>
				}
			</div>
		);
	}
}
