'use strict';

import { isEqual, isUndefined } from 'lodash';

var Color = require('color');

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var DomElementUtils = require('../../utils/DomElementUtils');

var Draggable = require('./Draggable.react');

var DEFAULT_COLOR = '#FFFFFF';
var VALUE_SPECTRUM_SCALE = 100;
var HUE_SCALE = 360;

/** @module ColorPicker
 * @requires lodash
 * @requires color
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires DomElementUtils
 * @requires Draggable
 */
var ColorPicker = ComponentFactory.Create({
	displayName: "ColorPicker",
	propTypes: {
		onChange: React.PropTypes.func,
		color: React.PropTypes.string,
		allowAlpha: React.PropTypes.bool
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return this._parseColor(this.props.color || DEFAULT_COLOR);
	},

	/**
	 * @method _getHue
	 */
	_getHue: function(color) {
		return Color().hsv(color.hsv().h, VALUE_SPECTRUM_SCALE, VALUE_SPECTRUM_SCALE);
	},

	/**
	 * @method _getNormalized
	 */
	_getNormalized: function(color) {
		//TODO: need some smarter way to convert matrix into 2 vectors
		if(color.hsv().v > color.hsv().s) {
			return Color().hsv(color.hsv().h, color.hsv().s, VALUE_SPECTRUM_SCALE, color.alpha());
		} else {
			return Color().hsv(color.hsv().h, VALUE_SPECTRUM_SCALE, color.hsv().v, color.alpha());
		}
	},

	/**
	 * @method _createState
	 */
	_createState: function(color) {
		var hueColor = this._getHue(color);
		return {
			hsv: color.hsv(),
			color: color.hslString(),
			hueColor: hueColor.hexString(),
			alpha: color.alpha(),
			saturation: color.alpha(1).hslString()
		};
	},

	/**
	 * @method _parseColor
	 */
	_parseColor: function(colorString) {
		try {
			var color = this._getNormalized(Color(colorString))
			return this._createState(color)
		}
		catch(err) {
			return this._createState(this._getNormalized(Color(this.state.color)))
		}
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		if (this.props.color && this.props.color.toLowerCase() === this.state.color) return;

		var isPropsChange = !isEqual(props, this.props);
		if (isPropsChange) {
			this.setState(this._parseColor(this.props.color || DEFAULT_COLOR));
		}
	},

	/**
	 * @method _hueChange
	 */
	_hueChange: function(ev, position) {
		if(ev.target.className !== this.hue.props.className) return;
		var rect = DomElementUtils.GetElementRect(ev.target);

		var percent = (position.initialX - rect.left + position.offsetX) / rect.width;
		var color = Color().hsv(HUE_SCALE * percent, this.state.hsv.s, this.state.hsv.v, this.state.alpha);
		var state = this._createState(color);

		if(this.props.onChange) this.props.onChange(state.color);
		this.setState(state);
	},

	/**
	 * @method _saturationValueChange
	 */
	_saturationValueChange: function(ev, position) {
		if(ev.target.className !== this.sv.props.className) return;
		var rect = DomElementUtils.GetElementRect(ev.target);

		var percent = (position.initialX - rect.left + position.offsetX) / rect.width;

		// we use one slider for saturation and value
		// first half for saturation last for value
		var state;
		var sliderScale = 1 / 2;
		if (percent > sliderScale) {
			var v = (2 - percent / sliderScale) * VALUE_SPECTRUM_SCALE;
			state = this._createState(Color().hsv(this.state.hsv.h, VALUE_SPECTRUM_SCALE, v, this.state.alpha));
		} else {
			var s = (percent / sliderScale) * VALUE_SPECTRUM_SCALE;
			state = this._createState(Color().hsv(this.state.hsv.h, s, VALUE_SPECTRUM_SCALE, this.state.alpha));
		}

		if(this.props.onChange) this.props.onChange(state.color);
		this.setState(state);
	},

	/**
	 * @method _getSaturationValuePosition
	 */
	_getSaturationValuePosition: function() {
		var sliderScale = 1 / 2;
		return (VALUE_SPECTRUM_SCALE + this.state.hsv.s - this.state.hsv.v) * sliderScale;
	},

	/**
	 * @method _alphaValueChange
	 */
	_alphaValueChange: function(ev, position) {
		if(ev.target.className !== this.alpha.props.children.props.className) return;
		var rect = DomElementUtils.GetElementRect(ev.target);

		var percent = (position.initialX - rect.left + position.offsetX) / rect.width;

		var state;
		state = this._createState(Color(this.state.color).alpha(percent.toFixed(2)));

		if(this.props.onChange) this.props.onChange(state.color);
		this.setState(state);
	},

	/**
	 * @method render
	 */
	render: function () {
		var valueSpectrumIndicatorCss = {
			left: this._getSaturationValuePosition() + '%',
			borderTopColor: this.state.hueColor,
			borderBottomColor: this.state.hueColor };
		var hueIndicatorCss = {
			left: (this.state.hsv.h / HUE_SCALE * 100) + '%',
			borderTopColor: this.state.hueColor,
			borderBottomColor: this.state.hueColor };
		var alphaIndicatorCss = {
			left: (this.state.alpha * 100) + '%',
			borderTopColor: this.state.hueColor,
			borderBottomColor: this.state.hueColor };
		var showAlpha = true;
		if (!isUndefined(this.props.allowAlpha)) showAlpha = this.props.allowAlpha
		return (
			<div className="color-picker">
				<div className="color-spectrums">
					<div className="hue-spectrum-container">
						<div className="spectrum-indicator" style={hueIndicatorCss}></div>
						<Draggable ref={node => this.hue = node} onDrag={this._hueChange} onDragStart={this._hueChange} className="hue-spectrum" />
					</div>
					<div className="saturation-value-spectrum-container">
						<div className="spectrum-indicator" style={valueSpectrumIndicatorCss}></div>
						<Draggable
							ref={node => this.sv = node}
							onDrag={this._saturationValueChange}
							onDragStart={this._saturationValueChange}
							className="saturation-value-spectrum"
							style={{
								backgroundColor: 'red',
								backgroundImage: 'linear-gradient(to right, white 0%, ' + this.state.hueColor + ' 50%, black 100%)'
							}}/>
					</div>
					{showAlpha ?
						<div className="alpha-value-spectrum-container">
							<div className="spectrum-indicator" style={alphaIndicatorCss}></div>
							<Draggable
								ref={node => this.alpha = node}
								onDrag={this._alphaValueChange}
								onDragStart={this._alphaValueChange}
								className="alpha-value-spectrum">
								<div className="alpha-value-spectrum-color" style={{background: 'linear-gradient(to right, rgba(255,0,0,0), ' + this.state.saturation + ')'}}/>
							</Draggable>
						</div> : null}
				</div>
				<div className="preview-color">
					<div className="color-example" style={{backgroundColor: this.state.color }}>
					</div>
				</div>
			</div>
		);
	}
});

module.exports = ColorPicker;
