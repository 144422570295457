'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var WebAPI = require('../utils/WebAPI');

/** @module PublishActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var PublishActionsCreator = {
	/**
	 * @method startPublishSite
	 */
	startPublishSite: function(websiteId) {
		var data = { websiteId: websiteId };
		AppDispatcher.dispatch({
			actionType: RemixerConstants.BEGIN_WEBSITE_PUBLISH,
			data: data
		},
		WebAPI.startPublish, [data]);
	}
};

module.exports = PublishActionsCreator;
