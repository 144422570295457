'use strict';

var Mapper = require('../utils/Mapper');

/** @module RemixerConstants
 * @requires Mapper
 */
module.exports = Mapper.mirrorKey({

	DEACTIVATE_PROPERTY: null,

	LOAD_THEME_PAGES: null,
	RECEIVE_THEME_PAGES: null,
	RECEIVE_THEME_PAGES_FAILS: null,

	OPEN_COMPONENT_SETTINGS: null,
	OPEN_NEW_COMPONENT_SETTINGS: null,

	START_IMAGE_MANIPULATION: null,
	END_IMAGE_MANIPULATION: null,

	START_IMAGE_PICKER: null,
	STOP_IMAGE_PICKER: null,

	START_VIDEO_PICKER: null,
	STOP_VIDEO_PICKER: null,

	START_AUDIO_PICKER: null,
	STOP_AUDIO_PICKER: null,

	START_MAP_PICKER: null,
	STOP_MAP_PICKER: null,

	START_LINK_PICKER: null,
	STOP_LINK_PICKER: null,

	START_COLOR_PICKER: null,
	STOP_COLOR_PICKER: null,

	START_MARKDOWN_PICKER: null,
	STOP_MARKDOWN_PICKER: null,

	START_MARKDOWN_MANIPULATION: null,
	STOP_MARKDOWN_MANIPULATION: null,

	RECEIVE_CONTENT_UPDATE: null,

	UPDATE_TEXT_PROPERTY: null,
	START_TEXT_MANIPULATION: null,
	END_TEXT_MANIPULATION: null,

	UPDATE_PAGE_DOM: null,

	CHANGE_ROUTE: null,

	MOVE_PAGE_REVISION: null,
	RECEIVE_MOVE_PAGE_REVISION: null,
	RECEIVE_MOVE_PAGE_REVISION_FAIL: null,

	SET_PREFERENCE: null,
	UNSET_PREFERENCE: null,
	FORGET_ALL_PREFERENCE: null,
	CHANGE_TEXT_COLOR: null,

	REMOVE_COMPONENT_INSTANCE: null,
	RECEIVE_REMOVE_COMPONENT_INSTANCE: null,
	RECEIVE_REMOVE_COMPONENT_INSTANCE_FAIL: null,

	LOAD_SIMILAR_COMPONENTS: null,
	RECEIVE_SIMILAR_COMPONENTS: null,
	RECEIVE_SIMILAR_COMPONENTS_FAIL: null,

	LOAD_PAGE: null,
	RECEIVE_PAGE: null,
	RECEIVE_PAGE_FAIL: null,

	LOAD_ALL_PAGES: null,
	RECEIVE_ALL_PAGES: null,
	RECEIVE_ALL_PAGES_FAIL: null,

	LOAD_AVAILABLE_DOMAINS: null,
	RECEIVE_AVAILABLE_DOMAINS: null,
	RECEIVE_AVAILABLE_DOMAINS_FAIL: null,

	UPDATE_PAGE_PROJECT: null,
	RECEIVE_UPDATE_PAGE_PROJECT: null,
	RECEIVE_UPDATE_PAGE_PROJECT_FAIL: null,
	RECEIVE_UPDATE_PAGE_PROJECT_PROPS: null,

	RECEIVE_UPDATE_PAGE_PROPS: null,

	DELETE_PAGE_PROJECT: null,
	RECEIVE_DELETE_PAGE_PROJECT: null,
	RECEIVE_DELETE_PAGE_PROJECT_FAIL: null,

	UPDATE_COMPONENT_INSTANCE_CONTEXT: null,
	RECEIVE_UPDATE_COMPONENT_INSTANCE_CONTEXT: null,
	RECEIVE_UPDATE_COMPONENT_INSTANCE_CONTEXT_FAIL: null,

	UPDATE_COMPONENT_INSTANCE: null,
	RECEIVE_UPDATE_COMPONENT_INSTANCE: null,
	RECEIVE_UPDATE_COMPONENT_INSTANCE_FAIL: null,

	LOAD_ALL_PAGE_PROJECTS: null,
	RECEIVE_ALL_PAGE_PROJECTS: null,
	RECEIVE_ALL_PAGE_PROJECTS_FAIL: null,

	SELECT_PAGE_PROJECT: null,
	UNSELECT_PAGE_PROJECT: null,

	RECEIVE_CREATE_WEBSITE: null,
	RECEIVE_CREATE_WEBSITE_FAIL: null,
	CREATE_WEBSITE: null,
	LOAD_ALL_WEBSITES: null,
	RECEIVE_ALL_WEBSITES: null,
	RECEIVE_ALL_WEBSITES_FAIL: null,
	SELECT_WEBSITE: null,
	UNSELECT_WEBSITE: null,
	DELETE_WEBSITE: null,
	RECEIVE_DELETE_WEBSITE: null,
	RECEIVE_DELETE_WEBSITE_FAIL: null,
	UPDATE_WEBSITE: null,
	RECEIVE_UPDATE_WEBSITE: null,
	RECEIVE_UPDATE_WEBSITE_FAIL: null,


	INHERIT_SKIN: null,
	RECEIVE_INHERIT_SKIN: null,
	RECEIVE_INHERIT_SKIN_FAIL: null,

	RECEIVE_CREATE_PAGE_PROJECT: null,
	RECEIVE_CREATE_PAGE_PROJECT_FAIL: null,

	CREATE_PAGE_PROJECT: null,

	RECEIVE_CLONE_PAGE_PROJECT: null,
	RECEIVE_CLONE_PAGE_PROJECT_FAIL: null,

	CLONE_PAGE_PROJECT: null,

	LOAD_RELEVANT_COMPONENTS: null,
	RECEIVE_LOAD_RELEVANT_COMPONENTS: null,
	RECEIVE_LOAD_RELEVANT_COMPONENTS_FAIL: null,

	LOAD_COMPONENTS_METADATA: null,
	RECEIVE_COMPONENTS_METADATA: null,
	RECEIVE_COMPONENTS_METADATA_FAIL: null,

	COMPONENT_CONTEXT_REQUEST: null,
	RECEIVE_COMPONENT_CONTEXT: null,
	CANCEL_COMPONENT_CONTEXT_REQUEST: null,

	START_DRAGGING_COMPONENT: null,
	END_DRAGGING_COMPONENT: null,

	REPLACE_COMPONENT_INSTANCE: null,
	RECEIVE_REPLACE_COMPONENT_INSTANCE: null,
	RECEIVE_REPLACE_COMPONENT_INSTANCE_FAIL: null,

	CREATE_COMPONENT_INSTANCE: null,
	RECEIVE_CREATE_COMPONENT_INSTANCE: null,
	RECEIVE_CREATE_COMPONENT_INSTANCE_FAIL: null,

	RESET_ACTIVE_COMPONENT: null,

	LOAD_THEMES: null,
	RECEIVE_THEMES: null,
	RECEIVE_THEMES_FAILS: null,

	LOAD_THEME: null,
	RECEIVE_THEME: null,
	RECEIVE_THEME_FAIL: null,

	ADD_THEME_LIKE: null,
	REMOVE_THEME_LIKE: null,
	RECEIVE_THEME_LIKE: null,
	RECEIVE_THEME_LIKE_FAIL: null,

	// used in import
	IMPORT_STARTING: null,
	RECEIVE_IMPORT_STARTED: null,
	IMPORT_FAILS: null,
	RECEIVE_IMPORT_FINISHED: null,
	LOAD_IMPORT_HISTORY: null,
	RECEIVE_IMPORT_HISTORY: null,
	RECEIVE_IMPORT_HISTORY_FAILS: null,
	RECEIVE_IMPORT_AUTH_EXPIRED: null,

	LOAD_CONTENT: null,
	DELETE_CONTENT: null,
	POST_CONTENT: null,
	PUT_CONTENT: null,

	RECEIVE_CONTENT: null,
	RECEIVE_CONTENT_FAILS: null,
	RECEIVE_CONTENT_DELETE: null,
	RECEIVE_CONTENT_DELETE_FAILS: null,
	RECEIVE_CONTENT_POST: null,
	RECEIVE_CONTENT_POST_FAILS: null,
	RECEIVE_CONTENT_PUT: null,
	RECEIVE_CONTENT_PUT_FAILS: null,

	UPDATE_CONTENT_TAGS: null,
	RECEIVE_UPDATE_CONTENT_TAGS: null,
	RECEIVE_UPDATE_CONTENT_TAGS_FAILS: null,

	RECEIVE_IFRAME_MESSAGE: null,
	IFRAME_RELEASE: null,

	LOAD_CONFIG: null,
	RECEIVE_CONFIG: null,
	RECEIVE_CONFIG_FAILS: null,

	REQUEST_FACEBOOK_AUTHORIZATION_URL: null,
	RECEIVE_FACEBOOK_AUTHORIZATION_URL: null,
	RECEIVE_FACEBOOK_AUTHORIZATION_URL_FAILS: null,

	REQUEST_TWITTER_AUTHORIZATION_URL: null,
	RECEIVE_TWITTER_AUTHORIZATION_URL: null,
	RECEIVE_TWITTER_AUTHORIZATION_URL_FAILS: null,

	REQUEST_FLICKR_AUTHORIZATION_URL: null,
	RECEIVE_FLICKR_AUTHORIZATION_URL: null,
	RECEIVE_FLICKR_AUTHORIZATION_URL_FAILS: null,

	REQUEST_YOUTUBE_AUTHORIZATION_URL: null,
	RECEIVE_YOUTUBE_AUTHORIZATION_URL: null,
	RECEIVE_YOUTUBE_AUTHORIZATION_URL_FAILS: null,

	REQUEST_VIMEO_AUTHORIZATION_URL: null,
	RECEIVE_VIMEO_AUTHORIZATION_URL: null,
	RECEIVE_VIMEO_AUTHORIZATION_URL_FAILS: null,

	REQUEST_SOUNDCLOUD_AUTHORIZATION_URL: null,
	RECEIVE_SOUNDCLOUD_AUTHORIZATION_URL: null,
	RECEIVE_SOUNDCLOUD_AUTHORIZATION_URL_FAILS: null,

	LOAD_USER_INFO: null,
	RECEIVE_USER_INFO: null,
	RECEIVE_USER_INFO_FAIL: null,

	// publish website
	RECEIVE_WEBSITE_PUBLISHED: null,
	RECEIVE_WEBSITE_PUBLISH_FAILED: null,
	BEGIN_WEBSITE_PUBLISH: null,

	ADD_FLASH_MESSAGE: null,
	REMOVE_FLASH_MESSAGE: null,

	SET_ACTIVE_CONTENT_FILTER: null,

	//web api requests
	REQUEST_FAILS: null,

	//youtube search
	SEARCH_YOUTUBE: null,
	RECEIVE_SEARCH_YOUTUBE: null,
	RECEIVE_SEARCH_YOUTUBE_FAILS: null,

	//vimeo search
	SEARCH_VIMEO: null,
	RECEIVE_SEARCH_VIMEO: null,
	RECEIVE_SEARCH_VIMEO_FAILS: null,

	//persons
	REQUEST_PERSON: null,
	RECEIVE_PERSON: null,
	RECEIVE_PERSON_FAILS: null,
	REQUEST_PERSONS: null,
	RECEIVE_PERSONS: null,
	RECEIVE_PERSONS_FAILS: null,
	REQUEST_ADD_PERSON: null,
	RECEIVE_ADD_PERSON: null,
	RECEIVE_ADD_PERSON_FAILS: null,
	REQUEST_UPDATE_PERSON: null,
	RECEIVE_UPDATE_PERSON: null,
	RECEIVE_UPDATE_PERSON_FAILS: null,
	REQUEST_DELETE_PERSON: null,
	RECEIVE_DELETE_PERSON: null,
	RECEIVE_DELETE_PERSON_FAILS: null,

	// signed uploads
	REQUEST_SIGNED_UPLOAD: null,
	RECEIVE_SIGNED_UPLOAD: null,
	RECEIVE_SIGNED_UPLOAD_FAILS: null,

	// Popup
	POPUP_BLOCKED: null,

	SEARCH_PIXABAY: null,
	RECEIVE_SEARCH_PIXABAY: null,
	RECEIVE_SEARCH_PIXABAY_FAILS: null,

	// Trial Publish
	HIDE_TRIAL_PUBLISH_NOTIFICATION: null,
	LOAD_TRIAL_STATUS: null,
	RECEIVE_TRIAL_STATUS: null,
	RECEIVE_TRIAL_STATUS_FAILS: null,

	// Signup
	REQUEST_NEW_ACCOUNT: null,
	RECEIVE_NEW_ACCOUNT: null,
	RECEIVE_NEW_ACCOUNT_FAILS: null,
	START_TRIAL: null,
	RESTORE_THEME_FOR_CREATION: null,
	RESET_THEME_FOR_CREATION: null,

	SET_SITETAILOR_LOAD_STATUS: null,
	SET_SITETAILOR_STEP_NAME: null,
	GET_SITETAILOR_LOAD_STATUS: null,

	LOGIN_TO_ACCOUNT: null,

	// wordpress export
	BEGIN_WORDPRESS_EXPORT: null,
	RECEIVE_WORDPRESS_EXPORT: null,
	RECEIVE_WORDPRESS_EXPORT_FAILED: null,

	CHECK_DREAMHOSTERS_DOMAIN: null,
	RECEIVE_DREAMHOSTERS_DOMAIN: null,
	RECEIVE_DREAMHOSTERS_DOMAIN_FAILED: null,

	REQUEST_LOGIN_WORDPRESS_ADMIN: null,
	RECEIVE_LOGIN_WORDPRESS_ADMIN: null,
	RECEIVE_LOGIN_WORDPRESS_ADMIN_FAILED: null
});
