'use strict';

import { map } from 'lodash';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var PropertyTypes = require('../constants/PropertyTypes');

var TextInput = require('./inputs/TextInput.react');
var LinkInput = require('./inputs/LinkInput.react');
var BlogsInput = require('./inputs/BlogsInput.react');
var VideoUrlInput = require('./inputs/VideoUrlInput.react');
var CheckboxInput = require('./inputs/CheckboxInput.react');
var RadioInput = require('./inputs/RadioInput.react');

/** @module ComponentContextSettings
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires PropertyTypes
 * @requires TextInput
 * @requires LinkInput
 * @requires BlogsInput
 * @requires VideoUrlInput
 * @requires CheckboxInput
 */
var ComponentContextSettings = ComponentFactory.Create({
	displayName: "ComponentContextSettings",
	propTypes: {
		component: React.PropTypes.any.isRequired,
		settings: React.PropTypes.any.isRequired
	},

	/**
	 * @method _buildInput
	 */
	_buildInput: function(property, index) {
		var component;
		switch (property.propertyType) {
			case PropertyTypes.link:
				component = (<li key={index}><LinkInput property={property} component={this.props.component} key={index} /></li>);
				break;
			case PropertyTypes.arrayOfBlogpost:
				component = (<li className="full-row" key={index}><BlogsInput property={property} component={this.props.component} key={index} /></li>);
				break;
			case PropertyTypes.blogpost:
				component = (<li className="full-row" key={index}><BlogsInput property={property} component={this.props.component} isSingleBlog={true} key={index} /></li>);
				break;
			case PropertyTypes.videoUrl:
				component = (<li className="full-row" key={index}><VideoUrlInput property={property} component={this.props.component} key={index} /></li>);
				break;
			case PropertyTypes.options:
				component = (<li key={index}><RadioInput property={property} component={this.props.component} key={index} /></li>);
				break;
			case PropertyTypes.boolean:
				component = (<li key={index}><CheckboxInput property={property} component={this.props.component} key={index} /></li>);
				break;
			default:
				component = (<li key={index}><TextInput property={property} component={this.props.component} key={index} /></li>);
		}

		return component;
	},

	/**
	 * @method render
	 */
	render: function() {
		var properties = map(this.props.settings, this._buildInput);
		return <div ref="container" className={this.props.className}>
			<p className="hint">alter component specific configurations</p>
			<ul className="inputs block-grid small-block-grid-1 medium-block-grid-3 large-block-grid-6">
				{properties}
			</ul>
		</div>;
	}
});

module.exports = ComponentContextSettings;
