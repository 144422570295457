'use strict';

var React = require('react');

var ComponentFactory = require('../infrastructure/ComponentFactory');
var ComponentMetadataStore = require('../stores/ComponentMetadataStore');
var ComponentActionsCreator = require('../actions/ComponentActionsCreator');
var PageRenderer = require('../rendering/PageRenderer');
var PageInstance = require('../models/PageInstance');
var IframeTools = require('./common/iframetools/IframeTools');

import { isEqual, isEmpty, get } from 'lodash'

/** @module PageView
 * @requires lodash
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires ComponentMetadataStore
 * @requires ComponentActionsCreator
 * @requires PageRenderer
 * @requires PageInstance
 * @requires IframeTools
 */
var PageView = ComponentFactory.Create(ComponentMetadataStore, {
	/* eslint-disable react/display-name */
	displayName: "PageView",
	propTypes: {
		page: React.PropTypes.instanceOf(PageInstance).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			metadata: ComponentMetadataStore.getComponents()
		};
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		ComponentActionsCreator.loadComponentsMetadata();

		var isChanged = !isEqual(get(props, 'page'), this.props.page) ||
			!isEqual(get(state, 'metadata'), this.state.metadata);

		if (isChanged) {
			var html = '';
			if (!isEmpty(this.state.metadata)) {
				var renderer = new PageRenderer(this.props.page, this.state.metadata);
				html = renderer.render();
			}
			this.setState({ html: html });
			IframeTools.RenderFrameContents(html, this.refs.iframe);
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		return (<iframe ref="iframe" sandbox="allow-same-origin allow-scripts allow-forms" src='./plain_iframe'/>);
	}
});

module.exports = PageView;
