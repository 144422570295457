'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var UrlUtils = require('../utils/UrlUtils');

/** @module FlickrServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires UrlUtils
 */
var FlickrServerActionsCreator = {
	/**
	 * @method receiveFlickrAuthorizationUrl
	 */
	receiveFlickrAuthorizationUrl: function(result) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_FLICKR_AUTHORIZATION_URL,
			result: result.data
		});
		UrlUtils.navigateTo(result.data.authorization_url);
	},
	/**
	 * @method receiveFlickrAuthorizationUrlFails
	 */
	receiveFlickrAuthorizationUrlFails: function(error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_FLICKR_AUTHORIZATION_URL_FAILS,
			message: 'could not get flickr authorization url'
		});
	}
};

module.exports = FlickrServerActionsCreator;
