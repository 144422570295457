'use strict';

import { clone } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

/** @module Draggable
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 */
var Draggable = ComponentFactory.Create({
	displayName: "Draggable",
	propTypes: {
		onDragStop: React.PropTypes.func,
		onDragStart: React.PropTypes.func,
		onDrag: React.PropTypes.func
	},

	/**
	 * @method _onDragStop
	 */
	_onDragStop: function(ev) {
		ev.stopPropagation();
		ev.preventDefault();

		document.removeEventListener('mousemove', this._onDrag);
		document.removeEventListener('mouseup', this._onDragStop);

		if(this.props.onDragStop) this.props.onDragStop.call(this, ev, clone(this.state));
	},

	/**
	 * @method _onDrag
	 */
	_onDrag: function(ev) {
		ev.stopPropagation();
		ev.preventDefault();

		this.setState({
			offsetX: ev.pageX - this.state.initialX,
			offsetY: ev.pageY - this.state.initialY
		});

		if(this.props.onDrag) this.props.onDrag.call(this, ev, clone(this.state));
	},

	/**
	 * @method _onDragStart
	 */
	_onDragStart: function(ev) {
		ev.stopPropagation();
		ev.preventDefault();

		var state = {
			initialX: ev.pageX,
			initialY: ev.pageY,
			offsetX: 0,
			offsetY: 0
		};
		document.addEventListener('mousemove', this._onDrag);
		document.addEventListener('mouseup', this._onDragStop);

		this.setState(state);
		if(this.props.onDragStart) this.props.onDragStart.call(this, ev, clone(state));
	},

	/**
	 * @method render
	 */
	render: function() {
		return (<div {...this.props} onMouseDown={this._onDragStart}>
			{this.props.children}
		</div>);
	}
});

module.exports = Draggable;
