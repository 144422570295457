'use strict';

import { get } from 'lodash';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');
var ComponentsPreviewStore = require('../stores/ComponentsPreviewStore');
var ComponentsPreviewBase = require('./ComponentsPreviewBase.react');

/** @module AddNewComponentsPreview
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentActionsCreator
 * @requires ComponentsPreviewStore
 * @requires ComponentsPreviewBase
 */
var AddNewComponentsPreview = ComponentFactory.Create(ComponentsPreviewStore, {
	displayName: "AddNewComponentsPreview",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		itemsPerPage: React.PropTypes.number
	},

	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			itemsPerPage: 5
		};
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var pIndex = get(this.state, 'pIndex', 0);
		var componentCategory = get(this.state, 'componentCategory', '');
		var searchText = get(this.state, 'searchText', '');

		return this._createState(componentCategory, searchText, pIndex);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		if(get(state, 'componentCategory') !== this.state.componentCategory
			|| get(state, 'searchText') !== this.state.searchText
			|| get(state, 'pIndex') !== this.state.pIndex){
			var pageId = this.props.pageId,
				componentId = null,
				pIndex = this.state.pIndex;

			ComponentActionsCreator.loadRelevantComponents(pageId, componentId, this.state.componentCategory,
				this.state.searchText, pIndex, this.props.itemsPerPage);
		}
	},

	/**
	 * @method _createState
	 */
	_createState: function(componentCategory, searchText, pIndex) {
		var pageId = this.props.pageId,
			componentId = null;
		const search='relevant';

		let loading = !!ComponentsPreviewStore.filling(search, pageId, componentId, componentCategory, searchText, pIndex);
		let filled = isPageFilled(pIndex);
		let previews = getPagePreviews(pIndex);
		if (!loading && filled && previews.length === 0) {
			pIndex = 0;
			previews = getPagePreviews(pIndex);
		}
		let hasOnePageOnly = !loading && pIndex === 0 && (
			(filled && previews.length < this.props.itemsPerPage )
				|| (isPageFilled(pIndex + 1) && getPagePreviews(pIndex + 1).length === 0)
		);

		return {
			pIndex: pIndex,
			componentCategory: componentCategory,
			searchText: searchText,
			previews: previews,
			loading: loading,
			hasOnePageOnly: hasOnePageOnly
		};

		function isPageFilled(pageIndex) {
			return ComponentsPreviewStore.getMetaState(search, pageId, componentId, componentCategory, searchText, pageIndex).filled;
		}

		function getPagePreviews(pageIndex) {
			return ComponentsPreviewStore.getState(search, pageId, componentId, componentCategory, searchText, pageIndex);
		}
	},

	/**
	 * @method _nextPage
	 */
	_nextPage: function() {
		var pIndex = get(this.state, ["previews", "length"], 0) >= this.props.itemsPerPage ? this.state.pIndex + 1 : 0;
		this.setState(this._createState(this.state.componentCategory, this.state.searchText, pIndex));
	},

	/**
	 * @method _prevPage
	 */
	_prevPage: function() {
		var pIndex = this.state.pIndex > 0 ? this.state.pIndex - 1 : 0;
		this.setState(this._createState(this.state.componentCategory, this.state.searchText, pIndex));
	},

	/**
	 * @method _createComponent
	 */
	_createComponent: function(reference) {
		ComponentActionsCreator.createComponentInstance(this.props.pageId, reference, -1);
	},

	/**
	 * @method _categorySelected
	 */
	_categorySelected: function(category){
		this.setState(this._createState(category, '', 0));
	},

	_searchApplied: function(text){
		this.setState(this._createState('', text, 0));
	},

	/**
	 * @method render
	 */
	render: function() {
		return <ComponentsPreviewBase previews={this.state.previews} onComponentClick={this._createComponent}
			onPrevPage={this._prevPage} onNextPage={this._nextPage}
			componentCategory={this.state.componentCategory} onCategorySelected={this._categorySelected}
			searchText={this.state.searchText}
			onSearchApplied={this._searchApplied}
			loading={this.state.loading}
			disableNext={this.state.hasOnePageOnly || this.state.loading }
			disablePrev={this.state.pIndex === 0 || this.state.loading }
		/>;
	}
});

module.exports = AddNewComponentsPreview;
