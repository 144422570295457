'use strict';

var Mapper = require('../utils/Mapper');

/** @module PublishStatuses
 * @requires Mapper
 */
module.exports = Mapper.mirrorKey({
	PUBLISHED: null,
	PUBLISHING: null,
	FAILED: null,
	WPEXPORTED: null,
	WPEXPORTING: null,
	WPEXPORTFAILED: null,
	MODIFIED_SINCE_PUBLISH: null,
	NOT_PUBLISHED: null
});
