'use strict';

var Mapper = require('../utils/Mapper');

/** TODO: some more types needed: image orientation (landscape, portrait), colors, faces, etc
 * @module ContentFilterTypes
 * @requires Mapper
 */
module.exports = Mapper.mirrorKey({
	DATE: null,
	IMPORT_TYPE: null,
	CONTENT_TYPE: null,
	TAG: null
});
