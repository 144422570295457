'use strict';

var React = require('react');

var ComponentFactory = require('../infrastructure/ComponentFactory');

var WebSocketClient = require("../utils/WebSocketClient");
var WebSocketHandler = require("../utils/WebSocketHandler");
var Analytics = require("../utils/Analytics");

var Router = require('../routing/Router');

var RouteStore = require('../stores/RouteStore');
var UserStore = require('../stores/UserStore');
var ConfigStore = require('../stores/ConfigStore');
import TrialStatusStore from '../stores/TrialStatusStore';
import { isEmpty, defer, kebabCase } from 'lodash'

var SimpleLayout = require('./layouts/SimpleLayout.react');
var CookieFetcher = require('./CookieFetcher.react');

var ConfigActionsCreator = require('../actions/ConfigActionsCreator');
var UserInfoActionsCreator = require('../actions/UserInfoActionsCreator');
import TrialActionsCreator from '../actions/TrialActionsCreator';
var FlashMessageActionsCreator = require('../actions/FlashMessageActionsCreator')

/** @module RemixerApp
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires Router
 * @requires RouteStore
 * @requires UserStore
 * @requires ConfigStore
 * @requires SimpleLayout.react
 * @requires ConfigActionsCreator
 * @requires UserInfoActionsCreator
 */
var RemixerApp = ComponentFactory.Create({
	displayName: "RemixerApp",
	/**
	 * @method getInitialState
	 */
	getInitialState: function () {
		return this.getState();
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		RouteStore.addChangeListener(this._onChange);
		UserStore.addChangeListener(this._onChange);
		ConfigStore.addChangeListener(this._onChange);
		this.trialStatusStore = TrialStatusStore.addListener(this._onChange);

		this.router = new Router();
		this.router.init();

		this.componentDidUpdate();
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function (pprops, pstate) {
		ConfigActionsCreator.loadConfig();
		UserInfoActionsCreator.loadUserInfo();
		TrialActionsCreator.getTrialStatus();
		if (!isEmpty(this.state.userinfo) && !isEmpty(this.state.socketUri) && !this.notification) {
			this.notification = new WebSocketClient(
				this.state.socketUri, this.state.userinfo.token, new WebSocketHandler());
			this.notification.connect();
		}
		if (!isEmpty(this.state.analyticsUri) && !this.analytics) {
			this.analytics = new Analytics(this.state.analyticsUri, !isEmpty(this.state.userinfo) ? this.state.userinfo.token : null);
		}
		if (!isEmpty(this.state.remixerNotifications) && pstate.remixerNotifications !== this.state.remixerNotifications) {
			this.state.remixerNotifications.forEach(message => {FlashMessageActionsCreator.addFlashMessage({text: message})})
		}
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function() {
		RouteStore.removeChangeListener(this._onChange);
		UserStore.removeChangeListener(this._onChange);
		ConfigStore.removeChangeListener(this._onChange);
		this.trialStatusStore.remove();
		this.analytics.destroy();

		WebSocketClient.disconnect();
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var route = RouteStore.getState('route');
		return {
			route: route,
			routeParams: route && route.params,
			userinfo: UserStore.getState(),
			socketUri: ConfigStore.getState('socketEndpointUri'),
			analyticsUri: ConfigStore.getState('analyticsEndpointUri'),
			trialStatus: TrialStatusStore.getState(),
			remixerNotifications: ConfigStore.getState('remixerNotifications')
		};
	},

	/**
	 * @method _updateState
	 */
	_updateState: function() {

		this.setState(this.getState());
	},

	/**
	 * @method _onChange
	 */
	_onChange: function() {
		//HACK: defer() is a quick fix for error: Invariant Violation: Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch.
		defer(this._updateState, this);
	},

	/**
	 * @method render
	 */
	render: function() {
		// <script/> for things like <CSSTransitionGroup/> that don't like null
		if (!this.state.route) return <script/>;
		return (<div className={kebabCase(this.state.route.name + "Page")}>
			<SimpleLayout user={this.state.userinfo} trial={this.state.trialStatus}>
				<this.state.route.handler {...this.state.routeParams} />
			</SimpleLayout>
			{!isEmpty(this.state.userinfo) && <CookieFetcher timeout={ 1000 * 60 } url={"https://panel.dreamhost.com"} />}
		</div>);
	}
});

module.exports = RemixerApp;
