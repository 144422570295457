'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');


var RouteStore = require('../stores/RouteStore');

/** @module PageProjectServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires RouteStore
 */
var PageProjectServerActionsCreator = {
	/**
	 * @method receiveCreatePageProject
	 */
	receiveCreatePageProject: function(website, title, description, slug, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CREATE_PAGE_PROJECT,
			website, title, description, slug,
			project: response.data
		});
		RouteStore.getState('route').transitionTo('layout', { websiteId: response.data.website_id, pageProjectId: response.data.id });
	},
	/**
	 * @method receiveCreatePageProjectFail
	 */
	receiveCreatePageProjectFail: function(website, title, description, slug, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CREATE_PAGE_PROJECT_FAIL,
			website, title, description, slug,
			message: 'We could not create project'
		});
	},

	receiveClonePageProject: function(website, pageProject, title, description, slug, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CLONE_PAGE_PROJECT,
			website, pageProject, title, description, slug,
			project: response.data
		});
	},
	/**
	 * @method receiveCreatePageProjectFail
	 */
	receiveClonePageProjectFail: function(website, pageProject, title, description, slug, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CLONE_PAGE_PROJECT_FAIL,
			website, pageProject, title, description, slug,
			message: 'We could not create project'
		});
	},

	/**
	 * @method receiveUpdatePage
	 */
	receiveUpdatePageProject: function(pageProject, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_PAGE_PROJECT,
			pageProject: response.data
		});
	},

	/**
	 * @method receiveUpdatePageFail
	 */
	receiveUpdatePageProjectFail: function(pageProject) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_PAGE_PROJECT_FAIL,
			pageProject: pageProject
		});
	},

	/**
	 * @method receiveAllPageProjects
	 */
	receiveAllPageProjects: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_ALL_PAGE_PROJECTS,
			projects: response.data
		});
	},
	/**
	 * @method receiveAllPageProjectsFail
	 */
	receiveAllPageProjectsFail: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_ALL_PAGE_PROJECTS_FAIL,
			message: 'We could not get projects'
		});
	},
	receiveDeletePageProject: function(pageProject, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_DELETE_PAGE_PROJECT,
			pageProject
		});
	},

	/**
	 * @method receiveDeletePageFail
	 */
	receiveDeletePageProjectFail: function(pageProject, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_DELETE_PAGE_PROJECT_FAIL,
			pageProject
		});
	},

	/**
	 * @method receiveUpdatePageProps
	 */
	receiveUpdatePageProjectProps: function(partialPageProject) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_PAGE_PROJECT_PROPS,
			pageProject: partialPageProject
		});
	}
};

module.exports = PageProjectServerActionsCreator;
