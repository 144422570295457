'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var UrlUtils = require('../utils/UrlUtils');

/** @module YoutubeServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires UrlUtils
 */
var YoutubeServerActionsCreator = {
	/**
	 * @method receiveYoutubeAuthorizationUrl
	 */
	receiveYoutubeAuthorizationUrl: function(result) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_YOUTUBE_AUTHORIZATION_URL,
			result: result.data
		});

		UrlUtils.navigateTo(result.data.authorization_url);
	},
	/**
	 * @method receiveYoutubeAuthorizationUrlFails
	 */
	receiveYoutubeAuthorizationUrlFails: function(error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_YOUTUBE_AUTHORIZATION_URL_FAILS,
			message: 'could not get youtube authorization url'
		});
	}
};

module.exports = YoutubeServerActionsCreator;
