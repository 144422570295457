'use strict';

import {map, values, compact } from 'lodash';
var handlebars = require('handlebars');

var PageInstance = require('../models/PageInstance');
var ComponentMetadata = require('../models/ComponentMetadata');

var ComponentBuilder = require('./ComponentBuilder');

var Validator = require('../utils/Validator');

var pageTemplate = handlebars
	.compile('<!DOCTYPE html>'
				+ '<html lang="en">'
				+ '<head>'
				+ '<title>{{title}}</title>'
				+ '<meta name="description" content="{{description}}">'
				+ '<meta name="robots" content="{{robots}}">'
				+ '<meta content="width=device-width, initial-scale=1" name="viewport">'
				+ '{{#each stylesheets}}{{{this}}}{{/each}}'
				+ '</head>'
				+ '<body>'
				+ '{{#each htmls}}{{{this}}}{{/each}}'
				+ '{{#each javascripts}}{{{this}}}{{/each}}'
				+ '{{#if ga}}'
				+ '<script>(function(i,s,o,g,r,a,m){i["GoogleAnalyticsObject"]=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,"script","//www.google-analytics.com/analytics.js","ga");ga("create", "{{ga}}", "auto");ga("send", "pageview");</script>'
				+ '{{/if}}' + '</body>' + '</html>');

/** @module PageRenderer
 * @requires lodash
 * @requires handlebars
 * @requires PageInstance
 * @requires ComponentMetadata
 * @requires ComponentBuilder
 * @requires Validator
 */
function PageRenderer(page, metadata) {
	var args = {
		page: page,
		metadata: metadata
	};
	Validator.isInstanceOf(args, Array, [ "metadata" ]);
	Validator.isInstanceOf(args, Object, [ "page" ]);

	var _page = args.page;
	if (!(_page instanceof PageInstance)) {
		_page = new PageInstance(_page);
	}

	var _metadata = map(args.metadata, ComponentMetadata.Factory.Create);

	/**
	 * @method _pick
	 */
	var _pick = function(components, property) {
		return compact(map(components, property));
	};

	/**
	 * @method render
	 */
	this.render = function() {
		var components = ComponentBuilder.buildAll(values(_page.components), _metadata, false, true);
		components = map(components, 'component');

		var context = {
			title: _page.title,
			description: _page.description,
			robots: _page.allowRobots ? 'INDEX, FOLLOW' : 'NOINDEX, NOFOLLOW',
			ga: _page.ga,
			htmls: _pick(components, 'html'),
			stylesheets: _pick(components, 'stylesheet'),
			javascripts: _pick(components, 'javascript')
		};

		return pageTemplate(context);
	};

	/**
	 * @method renderTemplate
	 * Template's context can include html, css, js
	 * Exported as v2 on renderer service
	 */
	this.renderTemplate = function(tmpl) {
		var components = ComponentBuilder.buildAll(values(_page.components), _metadata, false, false);
		components = map(components, 'component');
		var context = {
			html: _pick(components, 'html'),
			css: _pick(components, 'stylesheet'),
			js: _pick(components, 'javascript')
		};

		return handlebars.compile(tmpl)(context);
	};

	/**
	 * @method renderParts
	 * Returns an object containing distinct html/css/js
	 * Exported as v3 on renderer service
	 */
	this.renderParts = function(tmpl) {
		var components = ComponentBuilder.buildAll(values(_page.components), _metadata, false, false);
		components = map(components, 'component');
		var context = {
			html: _pick(components, 'html'),
			css: _pick(components, 'stylesheet'),
			js: _pick(components, 'javascript'),
			head: _pick(components, 'head')
		};
		return {
			'html': handlebars.compile('{{#each html}}{{{this}}}{{/each}}')(context),
			'css': handlebars.compile('{{#each css}}{{{this}}}{{/each}}')(context),
			'js': handlebars.compile('{{#each js}}{{{this}}}{{/each}}')(context),
			'head': handlebars.compile('{{#each head}}{{{this}}}{{/each}}')(context)
		};
	};

}

module.exports = PageRenderer;
