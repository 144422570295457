'use strict';

import { get, initial, split, last, set, clone, endsWith } from 'lodash';

var React = require('react');

var ComponentFactory = require('../../infrastructure/ComponentFactory');

var PageStore = require('../../stores/PageStore');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

var PropertyInfo = require('../../models/PropertyInfo');
var ComponentInstance = require('../../models/ComponentInstance');

var Rectangle = require('../common/Rectangle.react');

var PropertyTypes = require('../../constants/PropertyTypes');

/** @module PropertySystemLayer
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires PageStore
 * @requires ComponentActionsCreator
 * @requires PropertyInfo
 * @requires ComponentInstance
 * @requires Rectangle
 * @requires PropertyTypes
 */
var PropertySystemLayer = ComponentFactory.Create(PageStore, {
	displayName: "PropertySystemLayer",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		propertyInfo: React.PropTypes.instanceOf(PropertyInfo).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var instance = PageStore.getState(this.props.pageId, "components", this.props.propertyInfo.componentId);
		return {
			instance: instance,
			list: instance ? get(instance.context, initial(split(this.props.propertyInfo.path, '.'))) : [],
			index: +(last(split(this.props.propertyInfo.path, '.')))
		};
	},

	/**
	 * @method _remove
	 */
	_remove: function() {
		if (this.state.list.length <= 1) return;

		var instance = new ComponentInstance(this.state.instance);
		var list = this.state.list.slice(0); //clone array;
		list.splice(this.state.index, 1); //it will remove item form array;

		set(instance.context, initial(split(this.props.propertyInfo.path, '.')), list);
		ComponentActionsCreator.updateComponentInstanceContext(instance);
	},

	/**
	 * @method _add
	 */
	_add: function() {
		var instance = new ComponentInstance(this.state.instance);
		var list = this.state.list.slice(0); //clone array;
		var selected = clone(list[this.state.index]);
		list.splice(this.state.index + 1, 0, selected); //it will add the selected item into array;
		set(instance.context, initial(split(this.props.propertyInfo.path, '.')), list);
		ComponentActionsCreator.updateComponentInstanceContext(instance);
	},

	/**
	 * @method render
	 */
	render: function() {
		var addItemButtons = true;
		var marker = true;
		if (PropertyTypes.isImagePropertyType(PropertyTypes.fromArrayItem(this.props.propertyInfo.type))) return null;
		if (PropertyTypes.isLinkPropertyType(PropertyTypes.fromArrayItem(this.props.propertyInfo.type))) {
			addItemButtons = false;
			//mark only the first entry in array
			if (!endsWith(this.props.propertyInfo.path, '0')) marker = false;
		}

		var remove;
		if (this.state.list.length > 1) {
			remove = (<div onClick={this._remove} className="system-array-remove-item"></div>);
		}

		return (<Rectangle
			className="system-array-item-tools"
			rectangle={this.props.propertyInfo.relativeRectangle}>
			<div className="system-array-indicator"></div>
			<div className="system-array-tooltip">List Item</div>
			{addItemButtons ?
				<div className="system-array-buttons">
					{remove}
					<div onClick={this._add} className="system-array-add-item"></div>
				</div>
				: null }
			{marker ?
				<span className="marker"/>
				:
				null
			}
		</Rectangle>);
	}
});

module.exports = PropertySystemLayer;
