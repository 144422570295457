import React from 'react';


export default class VideoPickerPreview extends React.Component {
	static propTypes = {
		onBack: React.PropTypes.func.isRequired,
		onSelect: React.PropTypes.func.isRequired,
		url: React.PropTypes.string.isRequired,
		className: React.PropTypes.string
	};


	render() {
		const videoClass = this.props.className;

		return (
			<div className={'toolbar ' + videoClass + '-search'}>
				<div className={'toolbar-inside ' + videoClass + '-search'}>
					<div className="caption-bar">
						<div className="row">
							<div className="columns">
								<p className="toolbar-caption">
									<a className="back" onClick={this.props.onBack}>Back to Search</a>
									<a className="select right" onClick={this.props.onSelect}>Select Video</a>
								</p>
							</div>
						</div>
					</div>
					<div className={'toolbar-inside-content ' + videoClass + '-search'}>
						<div className={'row ' + videoClass + '-search-group'}>
							<div className="small-12 columns">
								<div className="flex-video widescreen">
									<iframe allowFullScreen frameBorder="0" src={this.props.url}></iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
