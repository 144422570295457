'use strict';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

/** @module ComponentContextMarkup
 * @requires react
 * @requires ComponentFactory
 */
var ComponentContextMarkup = ComponentFactory.Create({
	displayName: "ComponentContextMarkup",
	propTypes: {
		markup: React.PropTypes.string.isRequired
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className={this.props.className + " row"}>
				<textarea value={this.props.markup} readOnly/>
			</div>
		);
	}
});

module.exports = ComponentContextMarkup;
