import React from 'react';

import Paginator from './simple/Paginator.react';

export default class PixabayPaginator extends Paginator {
	render() {
		const {total, items} = this.props;
		const nextPage = this._nextPage();

		return (
			<div>
				Showing {items} of {total} results. {nextPage && <a onClick={() => this._handlePageChange(nextPage)}>Show more</a>}
			</div>
		);
	}
}
