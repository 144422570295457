'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var PreloadedImg = require('../../common/PreloadedImg.react');

/** @module OverlayImage
 * @requires react
 * @requires ComponentFactory
 * @requires PreloadedImg
 */
var OverlayImage = ComponentFactory.Create({
	displayName: "OverlayImage",
	propTypes: {
		content: React.PropTypes.array,
		contentItem: React.PropTypes.object.isRequired,
		picked: React.PropTypes.array,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<PreloadedImg imageUrl={this.props.contentItem.url} className="content-item image-content-item" />
		);
	}
});

module.exports = OverlayImage;
