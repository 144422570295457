'use strict';

var _ = require('lodash');

var ComponentTypes = require('../constants/ComponentTypes');
var ComponentDomAttributes = require('../constants/ComponentDomAttributes');

var ComponentInstance = require('../models/ComponentInstance');
var ComponentMetadata = require('../models/ComponentMetadata');

var ComponentRenderer = require('./ComponentRenderer');
var CSSRenderer = require('./CSSRenderer');
var JavascriptRenderer = require('./JavascriptRenderer');

var Validator = require('../utils/Validator');

/** @module ComponentBuilder
 * @requires lodash
 * @requires ComponentTypes
 * @requires ComponentDomAttributes
 * @requires ComponentInstance
 * @requires ComponentMetadata
 * @requires ComponentRenderer
 * @requires Validator
 */
function ComponentBuilder(options) {
	Validator.isInstanceOf(options, ComponentInstance, ["instance"]);
	Validator.isInstanceOf(options, ComponentMetadata.ComponentMetadata, ["metadata"]);

	this.metadata = options.metadata;
	this.instance = options.instance;
	this.wrapInHtml = options.wrapInHtml;

	this.htmlRenderer = new ComponentRenderer(options.editMode);
	this.scriptRenderer = new JavascriptRenderer(options.editMode);
	this.cssRenderer = new CSSRenderer(options.editMode);

	this.html = this.htmlRenderer.render(this.metadata.html, this.instance.context, this.metadata.properties);
	this.javascript = this.scriptRenderer.render(this.metadata.javascript, this.instance.context);
	this.stylesheet = this.cssRenderer.render(this.metadata.stylesheet, this.instance.context);
}

/**
 * @method ContentComponentBuilder
 */
function ContentComponentBuilder(options) {
	ComponentBuilder.call(this, options);

	this.build = function() {
		var output = {};

		output.html = _.template('<section ${attributes.chordId}="${id}" ${attributes.id}="${id}" ${attributes.name}="${name}" ${attributes.position}="${position}">${html}</section>')({
			attributes: ComponentDomAttributes,
			id: this.instance.id,
			name: _.kebabCase(this.instance.reference.name),
			position: this.instance.position,
			html: this.html
		});



		if (this.wrapInHtml){
			if(!_.isEmpty(this.stylesheet)) {
				output.stylesheet = '<style>' + this.stylesheet + '</style>';
			}
			if(!_.isEmpty(this.javascript)) {
				output.javascript = '<script type="text/javascript">(function() { ' + this.javascript + ' }) ();</script>';
			}

		} else {
			if(!_.isEmpty(this.stylesheet)) {
				output.stylesheet = this.stylesheet;
			}
			if(!_.isEmpty(this.javascript)) {
				output.javascript = '(function() { ' + this.javascript + ' }) ();';
			}

		}
		return output;
	};
}

/**
 * @method StyleComponentBuilder
 */
function StyleComponentBuilder(options) {
	ComponentBuilder.call(this, options);

	this.build = function() {
		var output = {};
		if (this.wrapInHtml){
			output.stylesheet = !_.isEmpty(this.stylesheet) ?
				'<style>' + this.stylesheet + '</style>' :
				this.html;
		} else {
			output.stylesheet = this.stylesheet;
			output.head = this.html;
		}
		return output;
	};
}

/**
 * @method ScriptComponentBuilder
 */
function ScriptComponentBuilder(options) {
	ComponentBuilder.call(this, options);

	this.build = function() {
		var output = {};
		if (this.wrapInHtml){
			output.javascript = !_.isEmpty(this.javascript) ?
				'<script type="text/javascript">(function() { ' + this.javascript + ' }) ();</script>' :
				this.html;
		} else {
			output.javascript = !_.isEmpty(this.javascript) ? '(function() { ' + this.javascript + ' }) ();' : '';
			output.head = this.html;
		}
		return output;
	};
}

ContentComponentBuilder.prototype = _.create(ComponentBuilder.prototype, {
	'constructor': ContentComponentBuilder
});
StyleComponentBuilder.prototype = _.create(ComponentBuilder.prototype, {
	'constructor': StyleComponentBuilder
});
ScriptComponentBuilder.prototype = _.create(ComponentBuilder.prototype, {
	'constructor': ScriptComponentBuilder
});

/**
 * @method _build
 */
function _build(instance, metadata, editMode, wrapInHtml) {
	var builder = false;
	var options = { instance: instance, metadata: metadata, editMode: !!editMode, wrapInHtml: !!wrapInHtml };
	if (metadata.componentType === ComponentTypes.base) {
		builder = new ContentComponentBuilder(options);
	}
	if(metadata.componentType === ComponentTypes.content) {
		builder = new ContentComponentBuilder(options);
	}

	if(metadata.componentType === ComponentTypes.hidden) {
		builder = new ContentComponentBuilder(options);
	}

	if(metadata.componentType === ComponentTypes.meta) {
		builder = new StyleComponentBuilder(options);
	}

	if(metadata.componentType === ComponentTypes.script) {
		builder = new ScriptComponentBuilder(options);
	}

	if (!builder) {
		throw "Attempt to create an unsupported componentType";
	} else {
		return builder.build();
	}
}

var _typeToOrder = _.zipObject([ComponentTypes.base, ComponentTypes.script, ComponentTypes.meta, ComponentTypes.content, ComponentTypes.hidden], [0, 1, 2, 3, 3]);

/**
 * @method _group
 */
var _group = function(components) {
	return _(components)
		.groupBy('reference.name')
		.mapValues(function (group) { return _.keyBy(group, 'reference.version'); })
		.value();
};

/**
 * @method ComponentsBuilder
 */
function ComponentsBuilder(options) {
	Validator.isArrayOfInstances(options, ComponentInstance, ["instances"]);
	Validator.isArrayOfInstances(options, ComponentMetadata.ComponentMetadata, ["metadata"]);

	var _editMode = options.editMode;

	var _instances = options.instances;
	var _metadataGroups = _group(options.metadata);

	var _wrapInHtml = options.wrapInHtml;

	/**
	 * @method _joinMetadata
	 */
	var _joinMetadata = function(instance) {
		return {
			metadata: _.get(_metadataGroups, [instance.reference.name, instance.reference.version]),
			instance: instance
		};
	};

	/**
	 * @method _sortByTypes
	 */
	var _sortByTypes = function(component) {
		return _typeToOrder[component.metadata.componentType];
	};

	/**
	 * @method _buildComponent
	 */
	var _buildComponent = function(component) {
		var output = _build(component.instance, component.metadata, _editMode, _wrapInHtml);

		return {
			component: output,
			componentId: component.instance.id
		};
	};

	/**
	 * @method _validation
	 */
	var _validation = function(component) {
		if(_.isEmpty(component.metadata)) {
			var reference = component.instance.reference;
			throw 'Validation error: metadata has no: ' + reference.name + '(' + reference.version + ')';
		}

		return component;
	};

	/**
	 * @method build
	 */
	this.build = function() {
		return _(_instances)
			.sortBy('position')
			.map(_joinMetadata)
			.map(_validation)
			.sortBy(_sortByTypes)
			.map(_buildComponent)
			.value();
	};
}


/**
 * @method _buildAll
 */
function _buildAll(instances, metadata, editMode, wrapInHtml) {
	var builder = new ComponentsBuilder({ instances: instances, metadata: metadata, editMode: !!editMode, wrapInHtml: !!wrapInHtml});
	return builder.build();
}

module.exports = { buildAll: _buildAll };
