'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var CreateTagForm = require('../../common/CreateTagForm.react');
var TagsDropDown = require('../../common/TagsDropDown.react');

var ContentStore = require('../../../stores/ContentStore');

var ContentTypes = require('../../../constants/ContentTypes');

var ContentActionsCreator = require('../../../actions/ContentActionsCreator');

var CREATE = "CREATE";
var SELECT = "SELECT";

function wrap(Component, contentType) {
	/** @module TagWrapper
	 * @requires lodash
	 * @requires react
	 * @requires ComponentFactory
	 * @requires CreateTagForm
	 * @requires TagsDropDown
	 * @requires ContentStore
	 * @requires ContentTypes
	 * @requires ContentActionsCreator
	 */
	var TagWrapper = ComponentFactory.Create(ContentStore, {
		displayName: "TagWrapper",
		propTypes: {
			content: React.PropTypes.array,
			contentItem: React.PropTypes.object,
			picked: React.PropTypes.array,
			onPick: React.PropTypes.func,
			onDelete: React.PropTypes.func
		},

		getInitialState: function() {
			var state = {
				action: SELECT,
				selected: null,
				tags: ContentStore.getState(ContentTypes.TAG)
			};
			return state;
		},

		/**
	 	* @method getState
	 	*/
		getState: function() {
			return {
				tags: ContentStore.getState(ContentTypes.TAG)
			};
		},

		/**
		 * @method componentDidUpdate
	 	*/
		componentDidUpdate: function() {
			ContentActionsCreator.loadContent(ContentTypes.TAG);
		},

		/**
		 * @method _getPicked
		 */
		_getPicked: function() {
			var selected = this.state.selected;
			var x = selected ?
				_(this.props.content)
					.filter(function(contentItem) { return _.some(contentItem.relative_content_items, {relative_content_id: selected.id}); })
					.map('id')
					.value()
				:
				null
				;
			return x;
		},

		/**
		 * @method _onPick
		 */
		_onPick: function(contentItem) {
			if (this.state.selected) {
				var content = _.cloneDeep(contentItem);
				if (_.some(content.relative_content_items, {relative_content_id: this.state.selected.id})) {
					_.remove(content.relative_content_items, {relative_content_id: this.state.selected.id});
				} else {
					content.relative_content_items.push({relative_content_id: this.state.selected.id});
				}
				ContentActionsCreator.putContent(contentItem.id, contentItem.content_type, content);
			} else {
				_.isFunction(this.props.onPick) && this.props.onPick(contentItem);
			}
		},

		/**
		 * @method _onSelectTag
	 	*/
		_onSelectTag: function(tag) {
			this.setState({
				action: SELECT,
				selected: tag
			});
		},

		_onCreateTag: function() {
			this.setState({
				action: CREATE,
				selected: null
			});
		},

		_onSaveTag: function(value) {
			ContentActionsCreator.postContent(ContentTypes.TAG, {name: value});
			this.setState({
				action: SELECT,
				selected: null
			});
		},

		_onCancelCreateTag: function() {
			this.setState({
				action: SELECT,
				selected: null
			});
		},

		/**
		 * @method _onFinish
		 */
		_onFinish: function() {
			this.setState({
				action: SELECT,
				selected: null
			});
		},

		/**
		 * @method render
		 */
		render: function() {
			var props = _.defaults({ picked: this._getPicked(), onPick: this._onPick }, this.props);

			return (
				<div>
					<div className="left tag-wrapper">
						{this.state.action === CREATE && <CreateTagForm onCancel={this._onCancelCreateTag} onCreate={this._onSaveTag}/>}
						{this.state.action === SELECT &&
							<div>
								<TagsDropDown tags={this.state.tags} selected={this.state.selected} onChange={this._onSelectTag} onCreate={this._onCreateTag} />
								{this.state.selected ?
									<div className="left hint">
										<small>click item to change tag </small>
										<button className="alert radius label" onClick={this._onFinish}>done</button>
									</div>
									:
									<div className="left hint">
										<small>to group content</small>
									</div>
								}
							</div>
						}
					</div>
					<Component {...props} />
				</div>
			);
		}
	});
	return TagWrapper;
}

module.exports = wrap;
