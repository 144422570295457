'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var WebAPI = require('../utils/WebAPI');

var ThemePagesStore = require('../stores/ThemePagesStore');
var ThemeStore = require('../stores/ThemeStore');

/** @module ThemesActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 * @requires ThemePagesStore
 * @requires ThemeStore
 */
var ThemesActionsCreator = {
	/**
	 * @method loadThemePages
	 */
	loadThemePages: function(themeId) {
		if (!ThemePagesStore.needs(themeId)) return;

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_THEME_PAGES,
			themeId: themeId
		},
		WebAPI.loadThemePages, [themeId]);
	},
	/**
	 * @method loadThemes
	 */
	loadThemes: function() {
		if (!ThemeStore.needs()) return;

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_THEMES
		},
		WebAPI.loadThemes);
	},
	/**
	 * @method loadTheme
	 */
	loadTheme: function(themeId) {
		if (!ThemeStore.needs(themeId)) return;

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_THEME,
			themeId: themeId
		},
		WebAPI.loadTheme, [themeId]);
	}

};

module.exports = ThemesActionsCreator;
