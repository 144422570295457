'use strict';

import { map, isEmpty } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ThemeStore = require('../../stores/ThemeStore');
var UserStore = require('../../stores/UserStore');
var Header = require('../layouts/partials/Header.react');
var ThemesActionsCreator = require('../../actions/ThemesActionsCreator');
var ThemeLikesActionCreator = require('../../actions/ThemeLikesActionCreator');
var WebsiteActionsCreator = require('../../actions/WebsiteActionsCreator');
var FlashMessenger = require('../layouts/partials/FlashMessenger.react');

import SignUpForm from '../SignUpForm.react';
import LoginForm from '../LoginForm.react';

import SignUpPreview from '../SignUpPreview.react';

var ThemeItem = require('../ThemeItem.react');

/** @module ThemesList
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ThemeStore
 * @requires ThemesActionsCreator
 * @requires ThemeLikesActionCreator
 * @requires PageProjectActionsCreator
 * @requires ThemeItem
 */
var ThemesList = ComponentFactory.Create(ThemeStore, {
	displayName: "ThemesList",
	/**
	 * @method getState
	 */
	getState: function() {
		return {
			themes: ThemeStore.getOrderedThemes(),
			userinfo: UserStore.getState(),
			loading: ThemeStore.getMetaState().filling,
			isSignUpFormVisible: false,
			themeForPreview: null,
			formToggle: false
		};
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		ThemesActionsCreator.loadThemes();
	},

	/**
	 * @method _onThemeSelect
	 */
	_onThemeSelect: function(theme) {
		isEmpty(this.state.userinfo)
			? this._showSignUpForm(theme)
			: WebsiteActionsCreator.createWebsite(theme.pageId, theme.name)
	},

	/**
	 * @method _showSignUpForm
	 */
	_showSignUpForm: function(theme) {
		this.setState({
			isSignUpFormVisible: true,
			themeForPreview: theme
		});
	},

	/**
	 * @method _hideSignUpForm
	 */
	_hideSignUpForm: function(theme) {
		this.setState({
			isSignUpFormVisible: false,
			themeForPreview: null
		});
	},

	/**
	 * @method _onThemePreview
	 */
	_onThemePreview: function(theme) {
		this.route().transitionTo('themePreview', {themeId: theme.themeId});
	},
	/**
	* @method _onThemeLikeClick
	*/
	_onThemeLikeClick: function(theme){
		var actionMethod = (theme.likes.self) ? 'removeThemeLike' : 'setThemeLike';
		ThemeLikesActionCreator[ actionMethod ]( theme.themeId );
	},
	/**
	 * @method getPageContent
	 */
	getPageContent: function() {
		return this.state.loading ?
			<span className="loader"></span>
			:
			<div>
				<div className="text-center">
					<h1>Select a theme to start</h1>
				</div>
				<div className="text-center">
					<h2>You can change the look and feel of your sections next</h2>
				</div>
				<div>
					<ul className="themes-list">
						{map(this.state.themes, (item) => {
							return (
								<li key={item.pageId}>
									<ThemeItem theme={item}
										onSelect={this._onThemeSelect}
										onPreview={this._onThemePreview}
										onLikeClick={this._onThemeLikeClick} />
								</li>
							);
						})}
					</ul>
				</div>
			</div>
	},
	/**
	 * @method changeForm
	 */
	changeForm: function(value) {
		this.setState({
			formToggle: value
		});
	},
	/**
	 * @method render
	 */
	render: function() {
		var formToggle = this.state.formToggle;
		return (
			<div>
				{this.state.isSignUpFormVisible &&
			<div onClick={this._hideSignUpForm} className="form">
				<Header user={this.state.userinfo} />
				<FlashMessenger floating={true} />
				<div onClick={(e) => e.stopPropagation()}>
					<div className="signup-title">
						{'This theme looks like a great start!'}
					</div>
					<div className="signup-content">
						<SignUpPreview theme={this.state.themeForPreview} />
						{formToggle ? <LoginForm saveTheme={this.state.themeForPreview} onChange={this.changeForm} formToggle={this.state.formToggle}/> :
							<SignUpForm saveTheme={this.state.themeForPreview} onChange={this.changeForm} formToggle={this.state.formToggle}/>}
					</div>
				</div>
			</div>
				}
				<div className={this.css('row themes-list-page', { 'blur': this.state.isSignUpFormVisible })}>
					{this.getPageContent()}
				</div>
			</div>
		);
	}
});

module.exports = ThemesList;
