import { map } from 'lodash';

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';
import PageProjectRecord from './records/PageProjectRecord';
import LoadingStatusStoreBase from './LoadingStatusStoreBase';

class PageProjectStore extends ReduceStore {
	getInitialState() {
		return Immutable.OrderedMap();
	}

	reduce(state, action) {
		switch (action.actionType) {
			case RemixerConstants.RECEIVE_ALL_PAGE_PROJECTS:
				return Immutable.OrderedMap(map(action.projects, (item) => [item.id, PageProjectRecord.fromDTO(item)]));
			case RemixerConstants.RECEIVE_UPDATE_PAGE_PROJECT_PROPS:
				return state.mergeIn([action.pageProject.id], action.pageProject)
			case RemixerConstants.RECEIVE_UPDATE_PAGE_PROJECT:
				return state.set(action.pageProject.id, PageProjectRecord.fromDTO(action.pageProject));
			case RemixerConstants.UPDATE_PAGE_PROJECT:
				return state.set(action.pageProject.id, action.pageProject);
			case RemixerConstants.RECEIVE_CREATE_PAGE_PROJECT:
				return state.set(action.project.id, PageProjectRecord.fromDTO(action.project));
			case RemixerConstants.RECEIVE_CLONE_PAGE_PROJECT:
				return state.set(action.project.id, PageProjectRecord.fromDTO(action.project));
			case RemixerConstants.RECEIVE_DELETE_PAGE_PROJECT:
				return state.delete(action.pageProject.id);
			case RemixerConstants.DELETE_PAGE_PROJECT:
				return state.delete(action.pageProject.id);
			default:
				return state;
		}
	}
}

export default new PageProjectStore(AppDispatcher);

export const PageProjectsLoadingStatusStore = new LoadingStatusStoreBase({
	load: RemixerConstants.LOAD_ALL_PAGE_PROJECTS,
	receive:  RemixerConstants.RECEIVE_ALL_PAGE_PROJECTS,
	fail: RemixerConstants.RECEIVE_ALL_PAGE_PROJECTS_FAIL
}, AppDispatcher);

export const PageProjectCreatingStatusStore= new LoadingStatusStoreBase({
	load: RemixerConstants.CREATE_PAGE_PROJECT,
	receive:  RemixerConstants.RECEIVE_CREATE_PAGE_PROJECT,
	fail: RemixerConstants.RECEIVE_CREATE_PAGE_PROJECT_FAIL
}, AppDispatcher);

