"use strict";

var Validator = require('../utils/Validator');
var Mapper = require('../utils/Mapper');

var ComponentReference = require('./ComponentReference');
var PageInstance = require('./PageInstance');

/** @module ComponentPreview
 * @requires Validator
 * @requires Mapper
 * @requires ComponentReference
 * @requires PageInstance
 */
function ComponentPreview(dto) {
	dto = Mapper.toCamelCase(dto);
	Validator.isTypeOf(dto, 'number', ["pageId"]);

	this.componentInstanceId = dto.componentContextId || dto.componentInstanceId;
	this.pageId = dto.pageId;
	this.page = new PageInstance(dto.page);
	this.reference = new ComponentReference(dto.componentReference);
}

module.exports = ComponentPreview;
