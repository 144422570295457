'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var AvailableDomainServerActionsCreator = {
	receiveAvailableDomains: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_AVAILABLE_DOMAINS,
			availableDomains: response.data
		});
	},
	receiveAvailableDomainsFail: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_AVAILABLE_DOMAINS_FAIL
		});
	}
};

module.exports = AvailableDomainServerActionsCreator;
