import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

import WebAPI from '../utils/WebAPI';

/** @module PixabayActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires PixabayStore
 * @requires WebAPI
 */
export default {
	search(query, category, page, compact) {
		WebAPI.searchPixabay(query, category, page, compact);
		AppDispatcher.dispatch({
			actionType: RemixerConstants.SEARCH_PIXABAY,
			query,
			category,
			page,
			compact
		});
	}
};
