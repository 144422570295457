import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

export default class SignUpPreview extends React.PureComponent {
	static propTypes = {
		theme: PropTypes.object
	}

	render() {
		return (
			<div className="signup-preview">
				<img src={ get(this.props, 'theme.thumbnails.desktop') }/>
			</div>
		)
	}
}
