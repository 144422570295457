import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';


const PixabaySearchParams = Immutable.Record({
	query: "",
	category: "",
	page: 1
});


class PixabaySearchStore extends ReduceStore<boolean> {
	getInitialState() {
		return new PixabaySearchParams();
	}

	reduce(state: boolean, action: Object): boolean {
		const {actionType, query, category, page} = action;
		switch (actionType) {
			case RemixerConstants.SEARCH_PIXABAY: return state.merge({query, category, page});
			default: return state;
		}
	}
}

export default new PixabaySearchStore(AppDispatcher);
