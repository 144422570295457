"use strict";

var i18next = require("i18next");

var en = require("../locales/en");

/** @module i18n
 * @requires i18next
 */
i18next.init({
	"lng": "en",
	"fallbackLng": false,
	"nsSeparator": false,
	"keySeparator": false,
	"resources": {
		"en": {
			"translation": en
		}
	}
});

module.exports = i18next;
