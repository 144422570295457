'use strict';

import { values, size, forEach, map } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var SimpleTabPane = require('../common/SimpleTabPane.react');

var ContentStore = require('../../stores/ContentStore');

var ContentActionsCreator = require('../../actions/ContentActionsCreator');

var ContentTypes = require('../../constants/ContentTypes');
var ContentManagementTabs = require('../../constants/ContentManagementTabs');

/** @module ContentMananementTabPane
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires SimpleTabPane
 * @requires ContentStore
 * @requires ContentActionsCreator
 * @requires ContentTypes
 * @requires ContentManagementTabs
 */
var ContentMananementTabPane = ComponentFactory.Config({pure: true}).Create(ContentStore, {
	displayName: "ContentMananementTabPane",
	propTypes: {
		active: React.PropTypes.oneOf(values(ContentManagementTabs)).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var state = {};
		state[ContentManagementTabs.IMAGES] = size(ContentStore.getState(ContentTypes.IMAGE));
		state[ContentManagementTabs.VIDEOS] = size(ContentStore.getState(ContentTypes.YOUTUBE));
		state[ContentManagementTabs.AUDIOS] = size(ContentStore.getState(ContentTypes.SOUNDCLOUD));
		state[ContentManagementTabs.MARKDOWN_TEXTS] = size(ContentStore.getState(ContentTypes.MARKDOWN));
		return state;
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function() {
		forEach([ContentTypes.IMAGE, ContentTypes.YOUTUBE, ContentTypes.SOUNDCLOUD, ContentTypes.MARKDOWN], function(contentType) {
			ContentActionsCreator.loadContent(contentType);
		});
	},

	/**
	 * @method _onSelect
	 */
	_onSelect: function(tab) {
		this.route().transitionTo('content', {tab: tab});
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="row content-type">
				<div className="small-12 columns content-type-tabs text-center">
					<SimpleTabPane activeKey={this.props.active} onSelect={this._onSelect}>
						{map(this.state, (total, tab) => {
							return (
								<a key={tab}>
									<div className="tab">
										<h2>{this._(tab)}</h2>
										<div className="bubbleNumber active" >
											<div className="bubbleLeft"></div>
											<div className="bubbleRight"></div>
											<div className="bubbleText"><p>{total}</p></div>
										</div>
									</div>
								</a>
							);
						})}
						<a key={ContentManagementTabs.GROUPS}>
							<div className="tab">
								<h2>{this._(ContentManagementTabs.GROUPS)}</h2>
							</div>
						</a>
					</SimpleTabPane>
				</div>
			</div>
		);
	}
});

module.exports = ContentMananementTabPane;
