"use strict";


import { map, isUndefined, isEqual, get, pick } from 'lodash';
var React = require("react");

var ComponentFactory = require("../../infrastructure/ComponentFactory");

var Shapes = require('../../utils/Shapes');

/** @module SystemLayerBreach
 * @requires Shapes
 */
var SystemLayerBreach = ComponentFactory.Create({
	displayName: "SystemLayerBreach",
	propTypes: {
		rectangle: React.PropTypes.instanceOf(Shapes.Rectangle).isRequired,
		onFocusChange: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return { focus: this.props.focus || false };
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this.componentDidUpdate();
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		var isFocusChangedFromInside = !isUndefined(state) &&
			this.state.focus !== state.focus &&
			this.state.focus !== this.props.focus;
		if (isFocusChangedFromInside && this.props.onFocusChange) {
			this.props.onFocusChange(this.state.focus);
		}

		var isFocusChangedFromOutside = !isUndefined(state) &&
			this.props.focus !== props.focus;
		if (isFocusChangedFromOutside) {
			this.setState({ focus: this.props.focus });
		}

		if (!isEqual(this.props.rectangle, get(props, 'rectangle'))) {
			var target = this.props.rectangle.toObject();
			this.setState({
				target: target,
				overlay: this._calculateOverlay(target)
			});
		}
	},

	/**
	 * @method _calculateOverlay
	 */
	_calculateOverlay: function(target) {
		return {
			top: {
				top: 0,
				right: 0,
				left: 0,

				height: target.top
			},
			right: {
				top: target.top,
				right: 0,
				left: target.left + target.width,

				height: target.height
			},
			bottom: {
				top: target.top + target.height,
				right: 0,
				bottom: 0,
				left: 0
			},
			left: {
				top: target.top,
				left: 0,

				width: target.left,
				height: target.height
			}
		};
	},

	/**
	 * @method _onHover
	 */
	_onHover: function() {
		this.setState({ hover: true });
	},

	/**
	 * @method _offHover
	 */
	_offHover: function() {
		this.setState({ hover: false });
	},

	/**
	 * @method _onFocus
	 */
	_onFocus: function() {
		this.setState({ focus: true });
	},

	/**
	 * @method _offFocus
	 */
	_offFocus: function() {
		this.setState({ focus: false });
	},

	/**
	 * @method render
	 */
	render: function() {
		var triggerStyle = get(this.state.target, "height", 0) ?
			this.state.target :
			{top: get(this.state.target, "top", 0), left: get(this.state.target, "left", 0)}
		return (<div className={this.css(this.props.className, pick(this.state, 'hover', 'focus'))}>
			{map(this.state.overlay, (rectangle, key) => {
				return (<div key={key}
					onMouseOver={this._offHover}
					onClick={this._offFocus}
					className="breach-overlay"
					style={rectangle} />);
			})}
			<div
				onMouseOver={this._onHover}
				onMouseLeave={this._offHover}
				onClick={this._onFocus}
				className="breach-trigger"
				style={triggerStyle}>
				{this.props.children}
			</div>
		</div>);
	}
});

module.exports = SystemLayerBreach;
