'use strict';

var _ = require('lodash');
var $ = require('jquery');
var React = require('react');
var ReactDOM = require('react-dom');
var DragSortableList = require('../../utils/DragDropSort').default;

var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentContextStore = require('../../stores/ComponentContextStore');
var ContentFilterStore = require('../../stores/ContentFilterStore');
var ContentStore = require('../../stores/ContentStore');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');

var ContentTypes = require('../../constants/ContentTypes');
var PropertyTypes = require('../../constants/PropertyTypes');


/** @module ImageArray
 * @requires lodash
 * @requires jquery
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires ContentFilterStore
 * @requires ContentStore
 * @requires ComponentActionsCreator
 * @requires ContentActionsCreator
 * @requires ContentTypes
 * @requires ImportTypes
 * @requires PropertyTypes
 * @requires DropDown
 * @requires ImagesUploader
 * @requires ContentFilterTypes
 */
var ImageArray = ComponentFactory.Create(ComponentContextStore, ContentFilterStore, ContentStore, {
	displayName: "ImageArray",
	propTypes: {
		propertyName: React.PropTypes.string.isRequired,
		propertyType: React.PropTypes.oneOf([PropertyTypes.image, PropertyTypes.arrayOfImage]).isRequired,
		activateArrayImage: React.PropTypes.func
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			value: ComponentContextStore.getPropertyValue(this.props.propertyName),
			content: ContentStore.getState(ContentTypes.IMAGE)
		};
	},

	componentDidMount: function() {
		ContentActionsCreator.loadContent(ContentTypes.IMAGE);
	},


	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		if(!_.isUndefined(prevState) && !_.isEqual(this.state.value, prevState.value)) {
			var component = ComponentContextStore.getState('active');
			if(!_.isEqual(_.get(component.context, this.props.propertyName), this.state.value)) {
				_.set(component.context, this.props.propertyName, this.state.value);

				ComponentActionsCreator.updateComponentInstanceContext(component);
				var isSingleImage = this.props.propertyType === PropertyTypes.image;
				if (isSingleImage && this.props.closeContentOverlay) this.props.closeContentOverlay();
			}
		}


	},

	/**
	 * @method _onDeleteClick
	 */
	_onDeleteClick: function(image) {
		var value = this.state.value;
		//at least one element should be in a list
		if (_.isArray(value) && value.length === 1) return;

		this.setState({ value: _.without(value, image) });
	},

	/**
	 * @method _isImagePicked
	 */
	_isImagePicked: function(image) {
		return _.some(this.state.value, _.partial(_.isEqual, image)) || _.isEqual(this.state.value, image);
	},

	/**
	 * @method _onUploadStatusChanged
	 */
	_onUploadStatusChanged: function(status) {
		this.setState({ uploadStatus: status });
	},

	/**
	 * @method _scroll
	 */
	_scroll: function(ev, delta) {
		var el = ReactDOM.findDOMNode(this.refs.picked);

		if (ev.type === "mousedown") {
			$(el).animate({ scrollLeft: el.scrollLeft + 60 * delta }, 300);
			this.interval = setInterval(function() {
				$(el).animate({ scrollLeft: el.scrollLeft + 60 * delta }, 300);
			}, 330);
		} else {
			clearInterval(this.interval);
		}
	},

	/**
	 * @method _scrollLeft
	 */
	_scrollLeft: function(ev) {
		this._scroll(ev, -5);
	},

	/**
	 * @method _scrollRight
	 */
	_scrollRight: function(ev) {
		this._scroll(ev, +5);
	},

	/**
	 * @method _calculateTotalContent
	 */
	_calculateTotalContent: function(filter) {
		return _(this.state.content)
			.filter(filter.matcher)
			.size();
	},

	_handleDragSort: function(sortedList, dropEvent){
		var value = this.state.value;
		if (_.isArray(value) && value.length === 1) return;
		var newvalue = new Array(this.state.value.length);
		var neworder = _.map(sortedList,function(o) { return parseInt(o.content.key) });
		neworder.forEach(function(newidx,curridx){
			newvalue[curridx]=value[newidx]
		});
		this.setState({value:newvalue});
	},

	_handleScroll: function(ev){
		var scroll_amt = 0;
		var container = document.getElementsByClassName('List')[0]
		var container_pos = container.getBoundingClientRect();
		var container_len = container_pos.right-container_pos.left;
		var left_scroll_threshold = 0.2*container_len;
		var right_scroll_threshold = 0.8*container_len;
		if(ev.pageX > right_scroll_threshold){
			scroll_amt = ev.pageX-right_scroll_threshold;
		}
		if(ev.pageX < left_scroll_threshold){
			scroll_amt = -(left_scroll_threshold - ev.pageX);
		}
		container.scrollLeft += scroll_amt;

	},

	_onImageSelect: function(image){
		this.props.activateArrayImage(_.indexOf(this.state.value, image))
	},

	/**
	 * @method render
	 */
	render: function() {
		var isSingleImage = this.props.propertyType === PropertyTypes.image;

		if (!isSingleImage) {
			var picked = (_.map(this.state.value, (image, idx) => {
				return {'content':(<div key={idx}
					className="image-array-thumbnail"
					onClick={(ev) => {ev.stopPropagation(); _.partial(this._onImageSelect, image)()}}
					style={{ backgroundImage: "url('" + (image.thumbnail_url || image.url) + "')", border: '1px solid #9c9c9c', width: 40, height: 40, borderRadius: 5, margin: 2, float: 'left', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', top: 16 }}>
					<div onClick={(ev) => {ev.stopPropagation(); _.partial(this._onDeleteClick, image)()}} className="remove-multi-img" style={{ cursor: 'pointer', border: '1px solid #fff', width: 20, height:20, borderRadius: '50%', background: '#b74e4e', position: 'absolute', left: 9, bottom: -10, textAlign: 'center', paddingTop: 2 }}>
						<p style={{ pointerEvents: 'none', color: '#fff', fontSize: 12, fontWeight: 'bold', padding: 0, margin: 0, lineHeight: '12px' }}>x</p>
					</div>
				</div>)
				};
			}));
		}

		if(picked) {
			return (
				<div className="image-array">
					<span className="help">Drag images to rearrange</span>
					<DragSortableList items={picked} onSort={this._handleDragSort} onDragMove={this._handleScroll} type="horizontal"/>
				</div>
			)
		} else {
			return null
		}

	}
});

module.exports = ImageArray;
