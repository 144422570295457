'use strict';

import { isFunction, create } from 'lodash';

var Communication = require('./Communication');
var Channel = Communication.Channel;
var Proxies = require('./Proxies');
var Receivers = require('./Receivers');

/** @module ChannelFactory
 * @requires lodash
 * @requires Communication
 * @requires Proxies
 * @requires Receivers
 */

function StraightMessagingChannel(options) {
	Channel.call(this, options);

	this.request = function(method, parameters) {
		if (!isFunction(this.window.__receive)) return;
		this.window.__receive({
			method: method,
			parameters: parameters
		});
	};

	var _setup = this.setup;
	this.setup = function() {
		_setup.apply(this, arguments);

		window.__receive = this._receive;
	};

	var _destroy = this.destroy;
	this.destroy = function() {
		_destroy.apply(this, arguments);

		delete window.__receive;
	};
}

StraightMessagingChannel.prototype = create(Channel.prototype, {
	'constructor': StraightMessagingChannel
});

function PostMassagingChannel(options) {
	Channel.call(this, options);

	this.__receive = this._receive;
	this._receive = function(e) {
		this.__receive(JSON.parse(e.data));
	}.bind(this);

	this.request = function(method, parameters) {
		var data = {
			method: method,
			parameters: parameters
		};
		this.window.postMessage(JSON.stringify(data), '*');
	};

	var _setup = this.setup;
	this.setup = function() {
		_setup.apply(this, arguments);

		window.addEventListener('message', this._receive, false);
	};

	var _destroy = this.destroy;
	this.destroy = function() {
		_destroy.apply(this, arguments);

		window.removeEventListener('message', this._receive);
	};
}

PostMassagingChannel.prototype = create(Channel.prototype, {
	'constructor': PostMassagingChannel
});

module.exports = {
	/**
	 * @method CreateParentChannel
	 */
	CreateParentChannel: function(target, strategy) {
		var channel = new StraightMessagingChannel({
			window: target,
			local: new Receivers.EventsHandling(strategy),
			remote: new Proxies.CommandsExecutorProxy()});

		return channel;
	},
	/**
	 * @method CreateChildChannel
	 */
	CreateChildChannel: function(target, root) {
		var channel = new StraightMessagingChannel({
			window: target,
			local: new Receivers.CommandsExecutor(root),
			remote: new Proxies.EventHandlingProxy()});

		return channel;
	}
};
