"use strict";

var UrlUtils = require("./UrlUtils");

import _, { has, isUndefined, isNull, isArray, forEach, curry, last, initial } from 'lodash';
/** @module Validator
 * @requires lodash
 * @requires UrlUtils
 */

/**
 * @method _exists
 */
function _exists(object, property) {
	if (!has(object, property)) {
		throw new Error("'" + property + "' is not exists");
	}
}

/**
 * @method _getProperty
 */
function _getProperty(object, property) {
	if (isUndefined(property)) return object;

	_exists(object, property);
	return object[property];
}

/**
 * @method _inRange
 */
function _inRange(value, range) {
	_exists(range, value);
}

/**
 * @method _isMatch
 */
function _isMatch(value, pattern) {
	var regexp = new RegExp(pattern);

	if(!regexp.test(value)) {
		throw new Error("object or its property is not match");
	}
}

/**
 * @method _isVersion
 */
function _isVersion(value) {
	_isMatch(value, "^[0-9]+\.[0-9]+\.[0-9]+$");
}

/**
 * @method _isInstanceOf
 */
function _isInstanceOf(value, type) {
	if(!(value instanceof type)) {
		//console.log(type);
		// console.log(value);
		//console.log("value is not an instance of expected type");
		throw new Error("value is not an instance of expected type");
	}
}

/**
 * @method _isTypeOf
 */
function _isTypeOf(value, typeName) {
	if(typeof value !== typeName) {
		throw new Error("value is not expected type");
	}
}

/**
 * @method _isNullableTypeOf
 */
function _isNullableTypeOf(value, typeName) {
	if(!isNull(value)) {
		_isTypeOf(value, typeName);
	}
}

/**
 * @method _isUri
 */
function _isUri(value) {
	if(!UrlUtils.isValid(value)) {
		throw new Error("value is not an valid uri");
	}
}

/**
 * @method _isArrayOfInstances
 */
function _isArrayOfInstances(value, type) {
	if(!isArray(value)) {
		throw new Error("array value is required to perform array of check");
	}
	forEach(value, curry(_isInstanceOf)(_, type));
}

/**
 * @method _isArrayOfType
 */
function _isArrayOfType(value, typeName) {
	if(!isArray(value)) {
		throw new Error("array value is required to perform array of check");
	}
	forEach(value, curry(_isTypeOf)(_, typeName));
}

/**
 * @method _create
 */
function _create(validator) {
	return function() {
		var properties = last(arguments);

		if(isArray(properties)) {
			var [target, ...params] = initial(arguments);
			forEach(properties, function(property) {
				var value = _getProperty(target, property);

				validator.apply(validator, [value].concat(params));
			});
		} else {
			validator.apply(validator, arguments);
		}
	};
}

module.exports = {
	inRange: _create(_inRange),
	isVersion: _create(_isVersion),
	isMatch: _create(_isMatch),
	isInstanceOf: _create(_isInstanceOf),
	isTypeOf: _create(_isTypeOf),
	isNullableTypeOf: _create(_isNullableTypeOf),
	isUri: _create(_isUri),
	isArrayOfInstances: _create(_isArrayOfInstances),
	isArrayOfType: _create(_isArrayOfType)
};
