'use strict';

import { clone, defaults, assign, pick } from 'lodash';

/** @module ShapeUtils
 * @requires lodash
 */

/**
 * @method changeRect
 */
function changeRect(rect, delta) {
	if(!delta) return rect;

	var deltaX = +delta || delta.deltaX || 0;
	var deltaY = +delta || delta.deltaY || 0;

	return {
		top: rect.top - deltaY / 2,
		left: rect.left - deltaX / 2,
		width: rect.width + deltaX,
		height: rect.height + deltaY
	};
}

/**
 * @method scaleRect
 */
function scaleRect(rect, scale) {
	if(!scale) return rect;
	var scaleX = +scale || scale.scaleX || 1;
	var scaleY = +scale || scale.scaleY || 1;

	return changeRect(rect, {
		deltaX: -rect.width * (1 - scaleX),
		deltaY: -rect.height * (1 - scaleY)
	});
}

/**
 * @method isCoordinatesInRect
 */
function isCoordinatesInRect(absoluteX, absoluteY, rect, rescale) {
	rect = scaleRect(rect, rescale);
	return (
		rect.left <= absoluteX &&
		absoluteX <= (rect.left + rect.width) &&
		rect.top <= absoluteY &&
		absoluteY <= (rect.top + rect.height)
	);
}

/**
 * @method resize
 */
function resize(initialSize, tagterSize) {
	var size = clone(initialSize);

	var aspectRatio = size.width / size.height;
	size.width = tagterSize.width || (tagterSize.height || size.height) * aspectRatio;
	size.height = tagterSize.height || (tagterSize.width || size.width) / aspectRatio;

	return size;
}

/**
 * @method maxSize
 */
function maxSize(size, tagterSize) {
	size = clone(size);

	if (size.width > tagterSize.width) size = resize(size, pick(tagterSize, 'width'));
	if (size.height > tagterSize.height) size = resize(size, pick(tagterSize, 'height'));

	return size;
}

/**
 * @method minSize
 */
function minSize(size, tagterSize) {
	size = clone(size);

	if (size.width < tagterSize.width) size = resize(size, pick(tagterSize, 'width'));
	if (size.height < tagterSize.height) size = resize(size, pick(tagterSize, 'height'));

	return size;
}

/**
 * @method boxSize
 */
function boxSize(size, max, min) {
	return minSize(maxSize(size, max), min);
}

/**
 * @method intersectionRect
 */
function intersectionRect(rect1, rect2) {
	var left = Math.max(rect1.left, rect2.left);
	var right = Math.min(rect1.left + rect1.width, rect2.left + rect2.width);
	var top = Math.max(rect1.top, rect2.top);
	var bottom = Math.min(rect1.top + rect1.height, rect2.top + rect2.height);

	if (right >= left && bottom >= top) {
		return {
			left: left,
			top: top,
			width: right - left,
			height: bottom - top
		};
	}
}

/**
 * @method translateRect
 */
function translateRect(rect, dx, dy) {
	var target = clone(rect);
	target.left += dx;
	target.top += dy;
	return target;
}

/**
 * @method moveRect
 */
function moveRect(rect, x, y) {
	var target = clone(rect);
	target.left = x;
	target.top = y;
	return target;
}

/**
 * @method isRectInclude
 */
function isRectInclude(outerRect, innerRect) {
	return (
		outerRect.top <= innerRect.top &&
		outerRect.left <= innerRect.left &&
		outerRect.width >= innerRect.width + innerRect.left &&
		outerRect.height >= innerRect.height + innerRect.top
	);
}

/**
 * @method centerRect
 */
function centerRect(outerRect, innerRect) {
	var target = defaults(pick(outerRect, 'top', 'left'), innerRect);
	if(!isRectInclude(outerRect, target)) return;

	target.top = (outerRect.height - innerRect.height) / 2;
	target.left = (outerRect.width - innerRect.width) / 2;

	return target;
}

/**
 * @method closestInRect
 */
function closestInRect(outerRect, innerRect) {
	var target = defaults(pick(outerRect, 'top', 'left'), innerRect);
	if(!isRectInclude(outerRect, target)) return;

	target.top = Math.min(Math.max(innerRect.top, outerRect.top), outerRect.height - innerRect.height);
	target.left = Math.min(Math.max(innerRect.left, outerRect.left), outerRect.width - innerRect.width);

	return target;
}

/**
 * @method relativeRect
 */
function relativeRect(outerRect, innerRect) {
	return assign({}, innerRect, { top: innerRect.top - outerRect.top, left: innerRect.left - outerRect.left });
}

/**
 * @method emptyRect
 */
function emptyRect() {
	return { left: 0, top: 0, width: 0, height: 0 };
}

module.exports = {
	IsCoordinatesInRect: isCoordinatesInRect,
	ScaleRect: scaleRect,
	ChangeRect: changeRect,
	IntersectionRect: intersectionRect,
	TranslateRect: translateRect,
	MoveRect: moveRect,
	IsRectInclude: isRectInclude,
	СenterRect: centerRect,
	ClosestInRect: closestInRect,
	RelativeRect: relativeRect,
	EmptyRect: emptyRect,

	Resize: resize,
	BoxSize: boxSize
};
