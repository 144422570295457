'use strict';

import { isEqual, trim, get } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var UrlUtils = require('../../../utils/UrlUtils');

/** @module UriAnchorTools
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires UrlUtils
 */
var UriAnchorTools = ComponentFactory.Create({
	displayName: "UriAnchorTools",
	propTypes: {
		onChange: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return this._createState();
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		if (isEqual(this.props.href, this.state.href)) return;

		var isPropsChange = !isEqual(props, this.props);
		if (isPropsChange) {
			this.setState(this._createState());
		}
	},

	/**
	 * @method _createState
	 */
	_createState: function() {
		var uri = trim(this.props.href, '/');
		// set the scheme eg  (http or https).
		// If the scheme is already set in state then don't change it.
		// Next try parseUrl but ignore any mailto: results.
		// If all else failse then just set to "http:".
		var scheme = get(this.state, "scheme") || (UrlUtils.parseUrl(uri).protocol && UrlUtils.parseUrl(uri).protocol !== "mailto:") ? UrlUtils.parseUrl(uri).protocol : false || "http:";
		return {
			scheme: scheme,
			text: UrlUtils.removeUriScheme(uri),
			link: UrlUtils.addUriScheme(uri, scheme),
			invalid: this._isUriInvalid(uri)
		};
	},

	/**
	 * @method _onChangeLink
	 */
	_onChangeLink: function(ev) {
		var uri = UrlUtils.addUriScheme(ev.target.value, this.state.scheme);

		this.setState({
			text: ev.target.value,
			link: uri,
			invalid: this._isUriInvalid(uri)
		});
	},

	/**
	 * @method _saveLink
	 */
	_saveLink: function(ev) {
		ev.preventDefault();

		if (this.state.invalid) return;

		var href = this.state.link && UrlUtils.normalizeUrl(this.state.link);
		if(this.props.onChange) this.props.onChange(href);
	},

	/**
	 * @method _isUriInvalid
	 */
	_isUriInvalid: function(uri) {
		return uri && !UrlUtils.isValid(uri);
	},

	/**
	 * @method _changeUrlScheme
	 */
	_changeUrlScheme: function() {
		if(this.state.scheme === "http:") {
			var scheme = "https:"
		}
		else {
			scheme = "http:"
		}
		var uri = UrlUtils.addUriScheme(UrlUtils.removeUriScheme(this.state.link), scheme)
		this.setState({
			scheme: scheme,
			link: uri,
			invalid: this._isUriInvalid(uri)
		});
	},

	/**
	 * @method render
	 */
	render: function () {
		return (<div className="tabs-content-link">
			<p className="hint">Enter a complete URL for this text to link to another website</p>
			<div className="url-input">
				<span className="url-input-prefix" onClick={this._changeUrlScheme}>{this.state.scheme + "//"}</span>
				<input className={this.css({ invalid: this.state.invalid })}
					type="text"
					value={this.state.text}
					onChange={this._onChangeLink}
					placeholder="Enter your URL..." />
				<span onClick={this._saveLink} className={this.css("save", { disable: this.state.invalid })}>Save</span>
			</div>
		</div>);
	}
});

module.exports = UriAnchorTools;
