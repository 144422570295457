'use strict';

import { has, get, forEach, map } from 'lodash';
var $ = require('jquery');
var UrlUtils = require('../../utils/UrlUtils');

var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

/** @module BlogItem
 * @requires lodash
 * @requires jquery
 * @requires UrlUtils
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 */
var BlogItem = ComponentFactory.Create({
	displayName: "BlogItem",
	/**
	 * @method close
	 */
	close: function(ev) {
		ev.stopPropagation();
		var $itemNode = $(ReactDOM.findDOMNode(this.refs.item));
		$itemNode.removeClass('selected');
	},

	/**
	 * @method showMore
	 */
	showMore: function(ev) {
		var $itemNode = $(ReactDOM.findDOMNode(this.refs.item));
		if ($itemNode.hasClass('selected')) {
			return;
		}
		$('body').find('.blog-list-view').not($itemNode).removeClass('selected');
		$itemNode.addClass('selected');
	},

	/**
	 * @method onClick
	 */
	onClick: function() {
		if (has(this, ['props', 'onClick'])) {
			this.props.onClick(this.props);
		}
	},

	/**
	 * @method render
	 */
	render: function() {

		var url = UrlUtils.staticUrl("img/wordpress.png");
		var content = get(this, ['props', 'data'], {});
		var images = get(this, ['props', 'images']);

		if (has(content, ['img', '0', 'url'])) {
			url = content.img[0].url;
		} else if(has(content, ['img', '0']) && images){
			forEach(images, function(item, i){
				if(content.img[0] === item.id) url = item.url;
			});
		}
		var blogContent = [];
		if ( has(content, 'parsed_content') ){
			for( var paragraphNum = 0; paragraphNum < content.parsed_content.length; paragraphNum++ ){
				if (content.parsed_content[paragraphNum].content.match(/\w/gi)) {
					blogContent.push(<p>{content.parsed_content[paragraphNum].content}</p>);
				}
			}
		}
		var title = get(content, 'title', '');

		return (
			<div ref="item" className="blog-item" onClick={this.onClick}>
				<div className="blog-item-thumbnail" style={{ backgroundImage: 'url("' + url + '")' }}></div>
				<div className="blog-item-content">
					<div className="blog-item-title">{title}</div>
					<div className="blog-item-description">{blogContent}</div>
					<div className="blog-item-tags">
						{map(this.props.data.tags, (tag, i) => {
							return <span className="blog-tag">{tag.term}</span>;
						})}
					</div>
				</div>
			</div>
		);
	}
});

module.exports = BlogItem;
