'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var ContentFilterTypes = require('../../../constants/ContentFilterTypes');

var ContentFilterStore = require('../../../stores/ContentFilterStore');

var NoResultsWrapper = require('../content-lists/NoResultsWrapper.react');
var Mosaic = require('../content-lists/Mosaic.react');

var Overlay = require('../content-details/Overlay.react');

var DropDownSingleSelectableGroup = require('../../common/simple/DropDownSingleSelectableGroup.react');
var SelectableItem = require('../../common/simple/SelectableItem.react');

var config = {
	pure: true,
	'higher-order-components': [
		NoResultsWrapper
	]
};

var ITEM_WIDTH = 200;
var ITEM_HEIGHT = 200;

/** @module Groups
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ContentFilterTypes
 * @requires ContentFilterStore
 * @requires NoResultsWrapper
 * @requires Mosaic
 * @requires Overlay
 * @requires DropDownSingleSelectableGroup
 * @requires SelectableItem
 */
var Groups = ComponentFactory.Config(config).Create(ContentFilterStore, {
	propTypes: {
		content: React.PropTypes.array,
		contentItem: React.PropTypes.object,
		picked: React.PropTypes.array,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	statics: {
		itemWidth: ITEM_WIDTH,
		itemHeight: ITEM_HEIGHT
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			filters: ContentFilterStore.getState(ContentFilterTypes.TAG)
		};
	},

	/**
	 * @method _onFilterChange
	 */
	_onFilterChange: function(filter) {
		this.setState({
			selectedFilter: filter
		});
	},

	/**
	 * @method _clearFilters
	 */
	_clearFilters: function() {
		this.setState({
			selectedFilter: undefined
		});
	},

	/**
	 * @method _calculateTotalContent
	 */
	_calculateTotalContent: function(filter) {
		return _(this.props.content)
			.filter(filter.matcher)
			.size();
	},

	/**
	 * @method render
	 */
	render: function() {
		var selectedFilter = this.state.selectedFilter;
		if (!selectedFilter) {
			selectedFilter = _.first(this.state.filters);
		}
		var content = [];
		if (selectedFilter) {
			content = _.filter(this.props.content, selectedFilter.matcher);
		}

		var icon = function(filter) {
			return {
				"mdi-timetable": ContentFilterTypes.DATE === filter.type,
				"mdi-web": ContentFilterTypes.IMPORT_TYPE === filter.type,
				"mdi-book-multiple": ContentFilterTypes.CONTENT_TYPE === filter.type,
				"mdi-tag": ContentFilterTypes.TAG === filter.type
			};
		};

		var props = _.defaults({content: content}, this.props);

		return (
			<div>
				<div className="clearfix">
					<DropDownSingleSelectableGroup selected={selectedFilter} onChange={this._onFilterChange} reqiured={true} className="right">
						{_.map(this.state.filters, (filter) => {
							var total = this._calculateTotalContent(filter);
							return (
								<SelectableItem key={filter.name + "_" + filter.type} value={filter}>
									<span className={this.css("mdi", icon(filter))} /> {this._(filter.name)} ({total})
								</SelectableItem>
							);
						})}
					</DropDownSingleSelectableGroup>
					<div className="right">Display: &nbsp;</div>
				</div>
				<Mosaic {...props} className="groups" useInfinite={true} itemWidth={ITEM_WIDTH} itemHeight={ITEM_HEIGHT} />
				<Overlay {...props} />
			</div>
		);
	}
});

module.exports = Groups;
