import React from 'react';

export default class VimeoSearchForm extends React.Component {
	static propTypes = {
		onSearch: React.PropTypes.func.isRequired,
		query: React.PropTypes.string,
		minimized: React.PropTypes.bool,
		className: React.PropTypes.string
	};
	static defaultProps = {
		query: "",
		minimized: false
	};
	constructor(props) {
		super(props);
		this.state = {query: props.query};
	}

	componentDidMount() {
		this._input.focus();
	}

	_handleSubmit = (event) => {
		event.preventDefault();
		this.props.onSearch(this.state.query);
	}

	render() {
		return (
			<form onSubmit={this._handleSubmit} action="" className={this.props.className}>
				{this.props.minimized ?
					<input type="text" value={this.state.query} onChange={(event) => this.setState({query: event.target.value})} ref={(input) => this._input = input} className="search rounded" />
					:
					<div className="columns small-12 vimeo-search-out">
						<div className="vimeo-search-logo" ></div>
						<div className="vimeo-search-inner row">
							<div className="columns small-10">
								<input
									type="text"
									placeholder="search..."
									value={this.state.query}
									onChange={(event) => this.setState({query: event.target.value})}
									ref={(input) => this._input = input}
									style={{padding: "0 0 0 10px"}}/>
							</div>
							<div className="columns small-2">
								<button
									className="vimeo-search-button"
									disabled={!this.state.query}
									type="submit">
									<i className="mdi mdi-magnify"></i>
								</button>
							</div>
						</div>
						<div className="vimeo-search-disclaimer row">
							<p className="small-12 columns">videos may be subject to copyright.</p>
						</div>
					</div>
				}
			</form>
		);
	}
}
