'use strict';

var $ = require('jquery');
var _ = require('lodash');

var CommandParameterUtils = require('./CommandParameterUtils');

/** @module CommandUtils
 * @requires jquery
 * @requires lodash
 * @requires CommandParameterUtils
 */

function getCommandSelector(event) {
	return '[data-events~=' + event + ']';
}

function _buildCommands(eventType, data, element) {
	var commandNames = $(element).data(eventType);
	var commands = _.zipObject(commandNames, _.map(commandNames,
		function(commandName) {
			return CommandParameterUtils.GetParameters(element, commandName, data);
		}));
	return commands;
}

function getCommands(element, eventType, data) {
	var elements = $(element).closest(getCommandSelector(eventType));
	var commands = _.assign.apply(_, _.map(elements, _.partial(_buildCommands, eventType, data)));

	return commands;
}

function setCommand(element, eventType, commandName) {
	element = $(element);
	if(!element.length) return;

	var events = _.words(element.attr('data-events') || '');
	element.attr('data-events', _.union(events, [eventType]).join(' '));

	//NOTE: some js plugins on the page could clean 'dataset' of DomElements
	//(foundation-clearing do)
	//so we have to store system data in attribute
	//until system components interaction will be moved outside of the iframe completely
	//(text is still there).
	element.attr('data-' + _.kebabCase(eventType),
		JSON.stringify(_.union(element.data(eventType), [commandName])));
}

module.exports = {
	GetCommandSelector: getCommandSelector,
	GetCommands: getCommands,
	SetCommand: setCommand
};
