'use strict';

import { get, map, replace } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImageFilters = require('../../constants/ImageFilters');

/** @module ImageFilter
 * @requires lodash
 * @requires jquery
 * @requires react
 * @requires ComponentFactory
 * @requires ImageFilters
 */
var ImageFilter = ComponentFactory.Create( {
	displayName: "ImageFilter",
	propTypes: {
		imageUrl: React.PropTypes.string,
		onFilterChange: React.PropTypes.func,
		imageStyle: React.PropTypes.object
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return {currentFilter: "imgFilterNone"};
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this._getCurrentFilter(this.props.imageStyle)
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		if(prevProps.imageStyle !== this.props.imageStyle) {
			this._getCurrentFilter(this.props.imageStyle)
		}
	},

	_getCurrentFilter: function(imageStyle) {
		this.setState({ currentFilter: get(imageStyle, 'filter', 'None') })
	},

	_onClick: function(filter) {
		this.props.onFilterChange(filter)
	},

	/**
	 * @method render
	 */
	render: function() {
		return <div>
			{map(ImageFilters, (filter) => {
				var selected = (get(this.state, 'currentFilter') === 'imgFilter' + replace(filter, ' ', '')) ? "selected" : ""
				return <div key={filter} className="apply-filter" onClick={() => this._onClick('imgFilter' + replace(filter, ' ', ''))} style={{position: 'relative', width: 76, height: 76, margin: 2, marginBottom: 18, float: 'left'}} >
					<div className={"filter-img " + 'imgFilter' + replace(filter, ' ', '')} style={{position: 'relative', borderRadius: 5, width: 76, height: 76, backgroundImage: 'url(' + this.props.imageUrl + ')', backgroundSize: 'cover', backgroundPosition: 'center center'}} />
					<p className="tiny-heading" style={{fontSize: 10, width: '100%', lineHeight: '10px', padding: 0, margin: 0, position: 'absolute', top: 80, left: 0, textAlign: 'center', fontFamily: 'Nexa-web', fontWeight: 300, color: '#3e3e3e'}}>{filter}</p>
					<div className={"img-2-filter " + selected} style={{position: 'absolute', top: 0, left: 0, width: 76, height: 76, borderRadius: 5}} />
				</div>})}
		</div>
	}
});

module.exports = ImageFilter;
