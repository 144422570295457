export default {
	fetchCookie (username, goop, callback) {
		const apiurl = window.LOGIN_URL;
		const url = apiurl + `&Nscmd=Nautologin&username=${username}&goop=${goop}`;
		var xhr = new XMLHttpRequest();
		xhr.onloadend = () => callback()
		xhr.open('GET', url, true);
		xhr.withCredentials = true;
		xhr.send(null);
	}
}

