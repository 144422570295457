'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var WebAPI = require('../utils/WebAPI');

/** @module ImportActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var ImportActionsCreator = {
	/**
	 * @method startImport
	 */
	startImport: function(importType, data) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.IMPORT_STARTING,
			importType: importType,
			data: data
		},
		WebAPI.startImport, [importType, data]);
	}
};

module.exports = ImportActionsCreator;
