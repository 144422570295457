'use strict';

var _ = require('lodash');
var RemixerConstants = require('../constants/RemixerConstants');
var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var FluxStoreState = require('./FluxStoreState');
var Validator = require('../utils/Validator');
var CHANGE_EVENT = 'change';

/** @module FluxStore
 * @requires lodash
 * @requires RemixerConstants
 * @requires AppDispatcher
 * @requires events
 * @requires FluxStoreState
 * @requires Validator
 */
function FluxStore(state) {
	var that = this;

	EventEmitter.call(that);
	that.setMaxListeners(200);

	Validator.isInstanceOf(state, FluxStoreState);
	var _state = state;

	/**
	 * @method emitChange
	 */
	that.emitChange = function() {
		that.emit(CHANGE_EVENT);
	};

	/**
	 * @method addChangeListener
	 */
	that.addChangeListener = function(callback) {
		that.on(CHANGE_EVENT, callback);
	};

	/**
	 * @method removeChangeListener
	 */
	that.removeChangeListener = function(callback) {
		that.removeListener(CHANGE_EVENT, callback);
	};

	/**
	 * @method getState
	 */
	that.getState = function() {
		return _state._get.apply(_state, arguments);
	};

	/**
	 * @method getMetaState
	 */
	that.getMetaState = function() {
		return _state._getMetadata.apply(_state, arguments);
	};

	/**
	 * @method needs
	 */
	that.needs = function() {
		return !_(_state._getMetadata.apply(_state, arguments))
			.values()
			.compact()
			.some();
	};

	/**
	 * @method filled
	 */
	that.filled = function() {
		return that.getMetaState.apply(that, arguments).filled;
	};

	/**
	 * @method filling
	 */
	that.filling = function() {
		return that.getMetaState.apply(that, arguments).filling;
	};

	/**
	 * @method failed
	 */
	that.failed = function() {
		return that.getMetaState.apply(that, arguments).failed;
	};

	/*
		Below enables traditional Flux subscribe model Stores to UI
		in a compact way with preprocessor optional prior to emit
		along with ensuring only store-specific events are allowed.

		This is very useful in creating content for post/put scenarios
		when data in the store is not existing and changes to existing
		along with being a basic staple of event-based programming.
	*/

	var _registeredRemixerEvents = {};
	var _registeredRemixerEventProprocessors = {};

	/** adds store-approved event listeners
	 * @method addRemixerListener
	 */
	that.addRemixerListener = function(evt, callback) {
		if (!_registeredRemixerEvents[evt]) {
			throw evt + ' not a supported event for the store.';
		}
		that.addListener(evt, callback);
	};

	/** removes store-approved event listeners
	 * @method removeRemixerListener
	 */
	that.removeRemixerListener = function(evt, callback) {
		if (!_registeredRemixerEvents[evt]) {
			throw evt + ' not a supported event for the store.';
		}
		that.removeListener(evt, callback);
	};

	/** adds dispatcher routing and store-specific events if defined
	 * @method registerRemixerEvents
	 */
	that.registerRemixerEvents = function() {
		if (!state._getRemixerEvents) return;

		_.forEach(state._getRemixerEvents(), function(evt) {
			if (!RemixerConstants[evt]) {
				throw evt + ' not a supported remixer event.';
			}
			_registeredRemixerEvents[evt] = true;
		});

		if (state._getRemixerEventPreprocessors) {
			_registeredRemixerEventProprocessors = state._getRemixerEventPreprocessors();
		}

		// simple routing of dispatcher via hash check with optional preprocessor before emit occurs
		AppDispatcher.register(function(action) {
			if (_registeredRemixerEvents[action.actionType]) {
				if (_registeredRemixerEventProprocessors[action.actionType]) {
					_registeredRemixerEventProprocessors[action.actionType](action, function next(options) {
						that.emit(action.actionType, action, options);
					});
				} else {
					that.emit(action.actionType, action);
				}
			}
		});
	};
}

FluxStore.prototype = _.create(EventEmitter.prototype, {
	'constructor': FluxStore
});

module.exports = FluxStore;
