'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var ImportIconBar = require('../../common/ImportIconBar.react');
var FileImport = require('../../common/FileImport.react');
var CollectionImport = require('../../common/CollectionImport.react');
var PageImport = require('../../common/PageImport.react');

/** @module ImageImporterToolBar
 * @requires react
 * @requires ComponentFactory
 * @requires ImportIconBar
 * @requires FileImport
 * @requires CollectionImport
 * @requires PageImport
 */
var ImageImporterToolBar = ComponentFactory.Create({
	displayName: "ImageImporterToolBar",
	// eslint-disable-next-line react/display-name
	render: function() {
		var icons = ["Instagram", "Twitter", "Flickr", "Facebook"];
		return (
			<div className="row import-header">
				<div className="medium-4 large-2 columns">
					<FileImport title="Upload from computer" tagline="click or drag & drop files here"/>
				</div>
				<div className="medium-4 large-6 columns">
					<ImportIconBar title="Import social media" tagline="select source or expand for more" icons={icons} />
				</div>
				<div className="medium-4 large-2 columns">
					<CollectionImport title="Search pixabay" tagline= "royalty free images"/>
				</div>
				<div className="medium-4 large-2 columns">
					<PageImport title="Import a website" tagline="enter site url"/>
				</div>
			</div>
		);
	}
});

module.exports = ImageImporterToolBar;
