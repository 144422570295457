'use strict';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');
var ContentStore = require('../../stores/ContentStore');
var PageStore = require('../../stores/PageStore');
var ComponentContextStore = require('../../stores/ComponentContextStore');
var PageDomStateStore = require('../../stores/PageDomStateStore');
var Tabs = require('../common/Tabs.react');
var ImageArray = require('./ImageArray.react');
var ImageLibrary= require('./ImageLibrary.react');
var ImageFilter= require('./ImageFilter.react');
var ImageLink= require('./ImageLink.react');
var HttpLink = require('./Link/http.react');
var EmailLink = require('./Link/email.react');
var AnchorLink = require('./Link/anchor.react');
var PageLink = require('./Link/page.react');


var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

import CommonWords from '../../constants/CommonWords'
import ImageStockPhotoLibrary from './ImageStockPhotoLibrary.react'
import ImagePixabayImporter from './ImagePixabayImporter.react'
import ImageMover from './ImageMover.react'
import ImageImporter from './ImageImporter.react'

import { get, set, forEach, clone, isEqual } from 'lodash';
var $ = require('jquery');

/** @module ImageEditor
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires ContentStore
 * @requires ComponentContextStore
 * @requires ImageTools
 * @requires ImagePicker
 * @requires lodash
 */
var ImageEditor = ComponentFactory.Create(ContentStore, ComponentContextStore, PageDomStateStore, PageStore, {
	displayName: "ImageEditor",

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return {
			component: {url: ''},
			imageImporterModal: false,
			currentLinkSelection: null,
			currentLinkURL: null
		};
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var component = ComponentContextStore.getState('active')
		var activeImagePicker = ComponentContextStore.getState('activeImagePicker')
		var pageDom = PageDomStateStore.getState(get(component, 'pageId'))
		// if the path doens't exist then we must have deleted the selected image
		// first try to find another image if the property is an array
		// if it's not an array then just stop the image picker
		if(component && activeImagePicker && pageDom && !get(component.context, activeImagePicker.path)) {
			if(Array.isArray(get(component.context, activeImagePicker.name))) {
				// property is array so pick first object
				setTimeout(() => {
					this._activateArrayImage(0)
				}, 1);
			}
		}

		// find pixabay query terms based on the text in the page
		// search the context of all web sections, filter out garbage words
		// then sort the remainder by frequency (highest frequency first)
		var text = ""
		var pageComponents = PageStore.getState(get(component, 'pageId'), 'components')
		forEach(pageComponents, (pageComponent) => {
			forEach(get(pageComponent, 'context', []), (value) => {
				text = text + " " + $('<div/>').html(value).text()
			})
		})
		var textArray = text.toLowerCase().replace(/[^a-z]+/g, " ").replace(/\s[a-z]{2}\s/g, " ").split(/\s/g)
		var filtered=textArray.filter(function(e){return this.indexOf(e)<0;},["", "hsl", "hsla", "rgb", "rgba", ...CommonWords]);
		var queryTerms = this.sortByFrequency(filtered)

		return {
			queryTerms: queryTerms,
			activeImagePicker: activeImagePicker,
			component: component,
			pageDom: pageDom,
			activeRectangle: PageDomStateStore.getPropertyRectangle(get(component, 'pageId'), get(activeImagePicker, 'componentId'), get(activeImagePicker, 'path'))
		}
	},

	sortByFrequency: function(array) {
		var frequency = {};

		array.forEach(function(value) { frequency[value] = 0; });

		var uniques = array.filter(function(value) {
			return ++frequency[value] == 1;
		});

		return uniques.sort(function(a, b) {
			return frequency[b] - frequency[a];
		});
	},

	_close: function() {
		ComponentActionsCreator.stopImagePicker()
	},

	_openImageImporterModal() {
		this.setState({imageImporterModal: 'image'})
	},

	_openPixabayImporterModal() {
		this.setState({imageImporterModal: 'pixabay'})
	},

	_closeImageImporterModal() {
		this.setState({imageImporterModal: false})
	},


	_activateArrayImage: function(imageNum) {
		if(isNaN(imageNum)) return;
		ComponentActionsCreator.startImagePicker( PageDomStateStore.getState("phash_" + get(this.state.component, 'pageId'), get(this.state.activeImagePicker, 'componentId'), this.state.activeImagePicker.name + '.' + imageNum + '.url') );
	},

	/**
	 * @method _onOpacityChange
	 */
	_onOpacityChange: function(opacity) {
		if(!this.state.activeImagePicker) return;

		var component = clone(this.state.component);
		var path = this.state.activeImagePicker.path;
		var stylePath = path.replace(new RegExp('url$'), 'style');
		var style = get(component.context, stylePath, {});

		set(style, 'opacity', opacity)
		set(component.context, stylePath, style)
		ComponentActionsCreator.updateComponentInstanceContext(component);

	},

	/**
	 * @method _onImageScale
	 */
	_onImageScale: function(scale) {
		if(!this.state.activeImagePicker) return;

		var component = clone(this.state.component);
		var path = this.state.activeImagePicker.path;
		var stylePath = path.replace(new RegExp('url$'), 'style');
		var style = get(component.context, stylePath, {});

		set(style, 'background-size', scale)
		set(component.context, stylePath, style)
		ComponentActionsCreator.updateComponentInstanceContext(component);

	},

	/**
	 * @method _onImageMove
	 */
	_onImageMove: function(position) {
		if(!this.state.activeImagePicker) return;

		var component = clone(this.state.component);
		var path = this.state.activeImagePicker.path;
		var stylePath = path.replace(new RegExp('url$'), 'style');
		var style = get(component.context, stylePath, {});

		set(style, 'background-position', get(position, 'x', 0) + ' ' + get(position, 'y', 0))
		set(component.context, stylePath, style)
		ComponentActionsCreator.updateComponentInstanceContext(component);

	},

	/**
	 * @method _onImageMove
	 */
	_onFilterChange: function(filter) {
		if(!this.state.activeImagePicker) return;

		var component = clone(this.state.component);
		var path = this.state.activeImagePicker.path;
		var stylePath = path.replace(new RegExp('url$'), 'style');
		var style = get(component.context, stylePath, {});

		set(style, 'filter', filter)
		set(component.context, stylePath, style)
		ComponentActionsCreator.updateComponentInstanceContext(component);

	},

	/**
	 * @method _onImageChange
	 */
	_onImageChange: function(newUrl) {
		if(!this.state.activeImagePicker) return;

		var component = clone(this.state.component);
		var path = this.state.activeImagePicker.path;
		var url = get(component.context, path);
		if(!isEqual(newUrl, url)) {
			set(component.context, path, newUrl);
			var img = new Image();
			img.onload = function() {
				ComponentActionsCreator.updateComponentInstanceContext(component);
			};
			img.src = newUrl;
		}
	},

	_onLinkApply: function() {
		if(!this.state.activeImagePicker) return;
		if(!this.state.currentLinkURL) return;
		var component = clone(this.state.component);
		var path = this.state.activeImagePicker.path;
		var imageUrl = get(component.context, path);
		if (!imageUrl) return;
		var linkPath = path.replace(new RegExp('url$'), 'link');
		var link = get(component.context, linkPath, {});

		set(link, 'url', this.state.currentLinkURL)
		set(link, 'type', this.state.currentLinkSelection || 'url')
		set(component.context, linkPath, link)
		ComponentActionsCreator.updateComponentInstanceContext(component);
		this.setState({currentLinkSelection: null})
	},

	_onLinkSelectionChange: function(key) {
		this.setState({currentLinkSelection: key});
	},

	_onURLChange: function(url) {
		this.setState({currentLinkURL: url});
	},

	_getLinkTarget: function(type) {
		var target;
		var selection = type;
		switch (selection) {
			case 'url':
				target = HttpLink;
				break;
			case 'email':
				target = EmailLink;
				break;
			case 'anchor':
				target = AnchorLink;
				break;
			case 'page':
				target = PageLink;
				break;
		}
		return target;
	},

	/**
	 * @method render
	 */
	render: function() {
		var ineligibleLinkChords = [
			'Hero Cover',
			'Hero Full',
			'Hero Parallax',
			'Hero Presentation',
			'Hero Color'
		]
		var linkOptions = [
			{
				'key':'url',
				'name':'URL'
			},
			{
				'key':'anchor',
				'name':'Anchor'
			},
			{
				'key':'email',
				'name':'Email'
			},
			{
				'key':'page',
				'name':'Page'
			}
		];
		if(this.state.activeImagePicker) {
			var url = get(this.state.component.context, this.state.activeImagePicker.path)
			var stylePath = this.state.activeImagePicker.path.replace(new RegExp('url$'), 'style');
			var style = get(this.state.component.context, stylePath, {});
			var linkPath = this.state.activeImagePicker.path.replace(new RegExp('url$'), 'link');
			var linkType = get(this.state.component.context, linkPath + '.type', 'url');
			var linkUrl = get(this.state.component.context, linkPath + '.url', '');
			var Target = this._getLinkTarget(this.state.currentLinkSelection || linkType);
			var ImgLink =
					<ImageLink key="Link" types={linkOptions} currentSelection={this.state.currentLinkSelection || linkType} selectionChange={this._onLinkSelectionChange} onLinkSave={this._onLinkApply}>
						<Target pageId={this.state.component.pageId} handleChange={this._onURLChange} currentLinkURL={linkUrl}/>
					</ImageLink>;
			if(this.state.activeImagePicker.type!='image' || ineligibleLinkChords.includes(this.state.component.reference.name)){
				ImgLink = <div key="Link"><span>This type of image cannot be linked</span></div>;
			}
		}
		return (this.state.activeImagePicker) ?
			<div className={"image-editor " + (this.state.imageImporterModal ? "no-scroll" : "")}>
				{this.state.imageImporterModal === 'image' ? <ImageImporter closeImageImporterModal={() => this._closeImageImporterModal()} /> : null}
				{this.state.imageImporterModal === 'pixabay' ? <ImagePixabayImporter closeImageImporterModal={() => this._closeImageImporterModal()} /> : null}
				<ImageArray propertyName={this.state.activeImagePicker.name}
					propertyType={this.state.activeImagePicker.type}
					activateArrayImage={this._activateArrayImage} />
				<ImageMover imageUrl={url}
					imageStyle={style}
					onImageMove={(position) => this._onImageMove(position)}
					onImageChange={(newUrl) => this._onImageChange(newUrl)}
					onImageScale={(scale) => this._onImageScale(scale)}
					onOpacityChange={(opacity) => this._onOpacityChange(opacity)}
					activeRectangle={this.state.activeRectangle} />
				<div style={{clear: "both"}}>
					<Tabs>
						<ImageLibrary key="Library"
							propertyPath={this.state.activeImagePicker.path}
							propertyType={this.state.activeImagePicker.type}
							openImageImporterModal={() => this._openImageImporterModal()} />
						<ImageStockPhotoLibrary key="Stock"
							queryTerms={this.state.queryTerms}
							openPixabayImporterModal={() => this._openPixabayImporterModal()} />
						<ImageFilter key="Filters"
							imageUrl={url}
							onFilterChange={(filter) => this._onFilterChange(filter)}
							imageStyle={style} />
						{ImgLink}
					</Tabs>
				</div>
				<div className="footer">
					<p className="tiny-heading">Changes are automatically applied.</p>
					<div className="close-button-wrapper" onClick={this._close}>
						<p className="close-button">Close</p>
					</div>
					<div className="grad-white-reverse" style={{width: '20.625rem', height: 10, position: 'absolute', top: '-12px', zIndex: 5, pointerEvents: 'none'}}></div>
				</div>
			</div>
			:
			null
	}
});

module.exports = ImageEditor;
