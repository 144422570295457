'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module RouteActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var RouteActionsCreator = {
	/**
	 * @method change
	 */
	change: function(route) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.CHANGE_ROUTE,
			route: route
		});
	}
};

module.exports = RouteActionsCreator;
