'use strict';

import { get, findKey } from 'lodash';
var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module TrackingImportStore
 * @requires lodash
 * @requires StoreFactory
 * @requires RemixerConstants
 */
var TrackingImportStore = StoreFactory.Create({

	/**
	 * @method _setImportStarted
	 */
	_setImportStarted: function(action) {
		var trackingId = get(action.payload, "trackingId");

		if (trackingId) {
			this._set(trackingId, action.data);
		}
	},

	/**
	 * @method _setImportFinished
	 */
	_setImportFinished: function(action) {
		var trackingId = get(action.payload, "trackingId");
		if (!trackingId) {
			var importId = get(action.data, "import_id");
			trackingId = findKey(this._get(), function (data) {
				return get(data, "import_id") === importId;
			});
		}

		if (trackingId) {
			this._set(trackingId, action.data);
		}
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};
		handlers[RemixerConstants.RECEIVE_IMPORT_STARTED] = this._setImportStarted;
		handlers[RemixerConstants.RECEIVE_IMPORT_FINISHED] = this._setImportFinished;
		handlers[RemixerConstants.IMPORT_FAILS] = this._setImportFinished;

		return handlers;
	}
});

module.exports = TrackingImportStore;
