'use strict';

import { defaults } from 'lodash';

var Validator = require('../utils/Validator');
var Shapes = require('../utils/Shapes');

/** @module PropertyInfo
 * @requires lodash
 * @requires Validator
 * @requires Shapes
 */
function PropertyInfo(dto) {
	Validator.isMatch(dto, '\\d+', ['componentId']);
	Validator.isTypeOf(dto, 'string', ['name', 'path', 'type']);

	this.componentId = +dto.componentId;
	if(dto.rectangle) this.rectangle = new Shapes.Rectangle(dto.rectangle);
	if(dto.relativeRectangle) this.relativeRectangle = new Shapes.Rectangle(dto.relativeRectangle);
	defaults(this, dto);
}

module.exports = PropertyInfo;
