export default [
	'Business',
	'Fashion',
	'Fitness',
	'Food',
	'Music',
	'Nature',
	'People',
	'Sports',
	'Technology'
];
