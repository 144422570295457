'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');
import { cloneDeep } from 'lodash';

/** @module UserStore
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires lodash
 */
var UserStore = StoreFactory.Create({

	/**
	 * @method getUser
	 */
	getUser: function() {
		return this.getState();
	},

	/**
	 * @method _setFilling
	 */
	_setFilling: function(action) {
		this._filling();
	},

	/**
	 * @method _setFailed
	 */
	_setFailed: function(action) {
		this._set(false);
	},

	/**
	 * @method _setUser
	 */
	_setUser: function(action) {
		action.userInfo.token = action.userInfo.token || '';
		this._set(action.userInfo);
	},
	/**
	 * @method _authExpired
	 */
	_authExpired: function (action) {
		var state = cloneDeep(this._get());
		state[action.importType.is_connected_property_name] = false;
		this._set(state);
	},
	/**
	 * @method _getHandlers
	 */
	_getHandlers: function () {
		var handlers = {};

		handlers[RemixerConstants.LOAD_USER_INFO] = this._setFilling;
		handlers[RemixerConstants.RECEIVE_USER_INFO] = this._setUser;
		handlers[RemixerConstants.RECEIVE_USER_INFO_FAIL] = this._setFailed;
		handlers[RemixerConstants.RECEIVE_IMPORT_AUTH_EXPIRED] = this._authExpired;

		return handlers;
	}
});

module.exports = UserStore;
