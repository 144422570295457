import { map } from 'lodash';

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';
import WebsiteRecord from './records/WebsiteRecord'
import LoadingStatusStoreBase from './LoadingStatusStoreBase';
import PageProjectStore from './PageProjectStore';

import PublishStatuses from '../constants/PublishStatuses';

class WebsitesStore extends ReduceStore {
	getInitialState() {
		return Immutable.OrderedMap();
	}

	reduce(state, action) {
		switch (action.actionType) {
			case RemixerConstants.RECEIVE_ALL_WEBSITES:
				return Immutable.OrderedMap(map(action.websites, (item) => [item.id, WebsiteRecord.fromDTO(item)]));
			case RemixerConstants.RECEIVE_CREATE_WEBSITE:
			case RemixerConstants.RECEIVE_UPDATE_WEBSITE:
			case RemixerConstants.RECEIVE_WEBSITE_PUBLISHED:
			case RemixerConstants.RECEIVE_WEBSITE_PUBLISH_FAILED:
			case RemixerConstants.RECEIVE_WORDPRESS_EXPORT:
			case RemixerConstants.RECEIVE_WORDPRESS_EXPORT_FAILED:
				return state.set(action.website.id, WebsiteRecord.fromDTO(action.website));
			case RemixerConstants.RECEIVE_DELETE_WEBSITE:
				return state.delete(action.website.id);

			case RemixerConstants.UPDATE_PAGE_PROJECT:
			case RemixerConstants.CREATE_PAGE_PROJECT:
			case RemixerConstants.CLONE_PAGE_PROJECT:
			case RemixerConstants.DELETE_PAGE_PROJECT: {
				let pageProject = action.pageProject || action.project;
				let websiteId = pageProject ? pageProject.websiteId : action.website.id;
				return WebsitesStore._resetEditedField(state, websiteId);
			}

			case RemixerConstants.CREATE_COMPONENT_INSTANCE: {
				let pageId = action.pageId;
				this.getDispatcher().waitFor([PageProjectStore.getDispatchToken()]);
				let websiteId = PageProjectStore.getState()
					.filter(project => project.pageId===pageId).first().websiteId;
				return WebsitesStore._resetEditedField(state, websiteId);
			}
			case RemixerConstants.UPDATE_COMPONENT_INSTANCE:
			case RemixerConstants.UPDATE_COMPONENT_INSTANCE_CONTEXT:
			case RemixerConstants.REPLACE_COMPONENT_INSTANCE:
			case RemixerConstants.REMOVE_COMPONENT_INSTANCE:{
				let pageId = action.componentInstance.pageId;
				this.getDispatcher().waitFor([PageProjectStore.getDispatchToken()]);
				let websiteId = PageProjectStore.getState().filter(project => (project.pageId===pageId)).first().websiteId;
				return WebsitesStore._resetEditedField(state, websiteId);
			}

			default:
				return state;
		}

	}

	static _resetEditedField(state, websiteId) {
		return state.setIn([websiteId, 'edited'], new Date()).setIn([websiteId, 'status'], PublishStatuses.NOT_PUBLISHED);
	}
}

export default new WebsitesStore(AppDispatcher);

export const WebsitesLoadingStatusStore = new LoadingStatusStoreBase({
	load: RemixerConstants.LOAD_ALL_WEBSITES,
	receive:  RemixerConstants.RECEIVE_ALL_WEBSITES,
	fail: RemixerConstants.RECEIVE_ALL_WEBSITES_FAIL
}, AppDispatcher);
