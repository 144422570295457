'use strict';

import { toArray, set, omitBy, initial, get, last, has, startsWith, omit, isArray, values } from 'lodash';

//{
//	data: {...},
//	filled: { 'key': bool },
//	filling: { 'key': bool },
//	failed: { 'key': bool }
//}

/** @module FluxStoreState
 * @requires lodash
 */
function FluxStoreState(initialState) {
	var _state = { data: initialState || {} };

	/**
	 * @method _prepend
	 */
	var _prepend = function (path, property) {
		return [property].concat(path);
	};

	/**
	 * @method _buildKey
	 */
	var _buildKey = function(path) {
		return '_' + path.join('_');
	};

	/**
	 * @method _key
	 */
	var _key = function(path, property) {
		return _prepend(_buildKey(path), property);
	};

	/**
	 * @method _getMetadata
	 */
	this._getMetadata = function() {
		var path = toArray(arguments);

		return {
			filled: get(_state, _key(path, 'filled'), false),
			filling: get(_state, _key(path, 'filling'), false),
			failed: get(_state, _key(path, 'failed'), false)
		};
	};

	/**
	 * @method _get
	 */
	this._get = function () {
		var path = _prepend(toArray(arguments), 'data');
		return get(_state, path);
	};

	/**
	 * @method _set
	 */
	this._set = function () {
		var args = toArray(arguments),
			value = last(args),
			path = initial(args);

		if (args.length === 1) path = [];

		set(_state, _prepend(path, 'data'), value);
		set(_state, _key(path, 'filled'), true);
		set(_state, _key(path, 'filling'), false);
		set(_state, _key(path, 'failed'), false);
	};

	/**
	 * @method _remove
	 */
	this._remove = function () {
		var path = _prepend(toArray(arguments), 'data');

		if(has(_state, path)) {
			var base = get(_state, initial(path));
			var obj = omit(base, last(path));
			var value = isArray(base) ? values(obj) : obj;

			set(_state, initial(path), value);
		}
	};

	/**
	 * @method _reset
	 */
	this._reset = function() {
		var property = _buildKey(toArray(arguments));

		_state.filled = omitBy(_state.filled, function(v, k) {
			return startsWith(k, property);
		});
		_state.filling = omitBy(_state.filling, function(v, k) {
			return startsWith(k, property);
		});
	};

	/**
	 * @method _filling
	 */
	this._filling = function () {
		var path = toArray(arguments);

		set(_state, _key(path, 'filling'), true);
	};

	/**
	 * @method _failed
	 */
	this._failed = function () {
		var path = toArray(arguments);

		set(_state, _key(path, 'failed'), true);
		set(_state, _key(path, 'filling'), false);
	};
}

module.exports = FluxStoreState;
