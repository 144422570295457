'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module UserPreferenceActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var UserPreferenceActionsCreator = {
	/**
	 * @method changeTextColor
	 */
	changeTextColor: function(value) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.CHANGE_TEXT_COLOR,
			value: value
		});
	},
	/**
	 * @method setPreference
	 */
	setPreference: function(name, value) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.SET_PREFERENCE,
			name: name,
			value: value
		});
	},
	/**
	 * @method unsetPreference
	 */
	unsetPreference: function(name) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.UNSET_PREFERENCE,
			name: name
		});
	},
	/**
	 * @method forgetAllPreference
	 */
	forgetAllPreference: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.FORGET_ALL_PREFERENCE
		});
	}
};

module.exports = UserPreferenceActionsCreator;
