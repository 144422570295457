import { map, some } from 'lodash';
import React from 'react';
import {Container} from 'flux/utils';

import PageProjectStore from '../../../stores/PageProjectStore';

var PageProjectActionsCreator = require('../../../actions/PageProjectActionsCreator');

class PageLinkSelector extends React.Component {
	static propTypes = {
		pageId: React.PropTypes.number.isRequired,
		href: React.PropTypes.string,
		onChange: React.PropTypes.func
	};

	static getStores() {
		return [PageProjectStore];
	}

	static calculateState(prevState){
		return {
			pageProjects: PageProjectStore.getState()
		};
	}

	componentDidMount() {
		PageProjectActionsCreator.loadPageProjects();
	}

	render (){
		const selectedValue = this.props.href  === null ? '__NULL__' : this.props.href;
		const list = this._getOptionItems(selectedValue);
		return (
			<select onChange={this.props.onChange} value={selectedValue}>
				{map(list, (item) => {
					return (<option key={item.key} value={item.key} disabled={item.key==='__NULL__'}>{item.name}</option>);
				})}
			</select>
		);
	}

	_getOptionItems(selectedValue){
		let pageProject = this.state.pageProjects.find((project) => project.pageId == this.props.pageId);
		let pageProjectList = pageProject
			? this.state.pageProjects.filter((project) => project.websiteId == pageProject.websiteId).toArray()
			: [];

		let list = pageProjectList.map((item) => {
			return {
				key: '/' + item.slug,
				name: item.title
			}
		});

		if(!some(list, { key: selectedValue })){
			let syntheticItem =  {
				key: selectedValue,
				name: selectedValue === '__NULL__'? '---' : selectedValue
			};

			list.unshift (syntheticItem);
		}
		return  list;
	}
}

module.exports = Container.create(PageLinkSelector);

