'use strict';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImportTypes = require('../../constants/ImportTypes');

var UserStore = require('../../stores/UserStore');
var ImportProgressStore = require('../../stores/ImportProgressStore');
var ImportHistoryStore = require('../../stores/ImportHistoryStore');
var ContentStore = require('../../stores/ContentStore');

var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var YoutubeActionsCreator = require('../../actions/YoutubeActionsCreator');
var UserInfoActionsCreator = require('../../actions/UserInfoActionsCreator');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');

var ContentTypes = require('../../constants/ContentTypes');

var _ = require('lodash');

/** @module YoutubeImport
 * @requires react
 * @requires ComponentFactory
 * @requires ImportTypes
 * @requires UserStore
 * @requires ImportProgressStore
 * @requires ImportHistoryStore
 * @requires ContentStore
 * @requires ImportActionsCreator
 * @requires YoutubeActionsCreator
 * @requires UserInfoActionsCreator
 * @requires ComponentActionsCreator
 * @requires ContentActionsCreator
 * @requires ContentTypes
 * @requires lodash
 */
var YoutubeImport = ComponentFactory.Create(UserStore, ImportProgressStore, ImportHistoryStore, ContentStore, {
	displayName: "YoutubeImport",
	/**
	 * @method getState
	 */
	getState: function () {
		var route = this.route();
		var state = {
			path: route.path,
			autoStartFromPath: route.params && route.params.action === 'startImport'
				&& route.params.actionParam === ImportTypes.YOUTUBE.toString(),
			isUserAuthenticated: !!UserStore.getState('google_connected'),
			importProgress: ImportProgressStore.getState(ImportTypes.YOUTUBE),
			ready: UserStore.getMetaState().filled && ImportProgressStore.getMetaState(ImportTypes.YOUTUBE).filled,
			importedFileCount: _(ContentStore.getState())
				.values()
				.flatten()
				.filter((x) => _.isEqual(x.import_type, ImportTypes.YOUTUBE))
				.filter((x) => _.isEqual(x.content_type, ContentTypes.YOUTUBE))
				.size()
		};

		state.loading = !state.ready || state.importProgress.progress;
		return state;
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		UserInfoActionsCreator.loadUserInfo();
		ComponentActionsCreator.loadImportHistory(ImportTypes.YOUTUBE);
		ContentActionsCreator.loadContent(ContentTypes.YOUTUBE);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function() {
		this._importAutoStart();
	},

	/**
	 * @method _startImport
	 */
	_startImport: function() {
		if(this.state.isUserAuthenticated) {
			ImportActionsCreator.startImport(ImportTypes.YOUTUBE);
		}
	},

	/**
	 * @method _importAutoStart
	 */
	_importAutoStart: function() {
		if (this.state.ready && this.state.autoStartFromPath &&
			!(this.state.importProgress.progress || this.state.importProgress.loading)) {
			this._startImport();
		}
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(e) {
		if (!this.state.ready) {
			return;
		}
		if(this.state.isUserAuthenticated) {
			this._startImport();
		} else {
			var path = this.state.path + (this.state.autoStartFromPath ? '' : startImportPath);
			YoutubeActionsCreator.requestYoutubeAuthorizationUrl(path);
		}
	},

	/**
	 * @method _onMouseEnter
	 */
	_onMouseEnter: function() {
		this.setState({mouseOver: true});
	},

	/**
	 * @method _onMouseLeave
	 */
	_onMouseLeave: function() {
		this.setState({mouseOver: false});
	},

	/**
	 * @method render
	 */
	render: function () {
		var css = {
			'youtube': true,
			'active': this.state.isUserAuthenticated
		};
		var iconClassName = "mdi mdi-youtube-play" + (this.state.loading ? " pulse" : "");

		var wrapperClassName;
		var output;

		if(_.get(this.props, "button", false)){
			output = (<div className="import-header-column import-header-column-button bg-overlay youtube" onClick={this._onClick}>
				<div>
					<div className="show-for-large-up import-label" >
						<p >{this.props.title}</p>
						<h3 >{this.props.tagline}</h3>
					</div>
					<div className="import-icon-holder" >
						<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import YouTube">
							<i className={iconClassName}></i>
						</div>
						<div className="import-icon-bubble-holder" >
							<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{this.state.importedFileCount}</p></div></div>
						</div>
					</div>
				</div>
			</div>);
		} else {
			wrapperClassName = 'media-source';
			output = (<div>
				<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import YouTube">
					<i className={iconClassName}></i>
				</div>
				<div className="import-icon-bubble-holder" >
					<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{this.state.importedFileCount}</p></div></div>
				</div>
			</div>);
		}
		return (<div className={wrapperClassName}>{output}</div>);
	}
});

var startImportPath = '/startImport/' + ImportTypes.YOUTUBE;

module.exports = YoutubeImport;
