"use strict";

var $ = require('jquery');
import { throttle, omit } from 'lodash'
var Validator = require('../utils/Validator');
var AppDispatcher = require('../dispatcher/AppDispatcher.js');

/** @module Analytics
 * @requires jquery
 * @requires lodash
 * @requires Validator
 * @requires AppDispatcher.js
 */
function Analytics(options, session) {

	var _urls = options.domains;

	var _getDate = function(){
		return new Date().toISOString().replace('T', ' ').replace('Z', '');
	};

	var data = [];
	var dataLayer = window.dataLayer || [];

	var _session = session;
	var _dispatch = AppDispatcher._dispatch;

	//trackEvent:
	//session (required,string)  The session variable that id's the user
	//action (require,string):	The action that was taken on that category
	//value (optional,string):	provides an additional dimension to the action data

	var _send = function (){
		var randUrl = _urls[Math.floor(Math.random() * _urls.length)];
		Validator.isUri(randUrl);

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: randUrl,
			contentType: 'application/json;charset=UTF-8',
			data: JSON.stringify(data),
			headers: {'x-access-token': _session}
		});
		data = [];
	};

	var _throttledSend = throttle(_send, 1000);

	AppDispatcher._dispatch = function(action){
		data.push({'stamp': _getDate(), 'session': _session, 'action': action.actionType, 'value': omit(action, 'actionType')});
		_throttledSend();
		_dispatch.apply(AppDispatcher, arguments);
		dataLayer.push({'stamp': _getDate(), 'session': _session, 'event': action.actionType, 'value': omit(action, 'actionType')});
	};

	this.destroy = function(){
		AppDispatcher._dispatch = _dispatch;
	};
}

module.exports = Analytics;
