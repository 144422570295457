'use strict';

var React = require('react');
import SiteTailorActionCreator from '../../../actions/SiteTailorActionCreator';
var ComponentFactory = require('../../../infrastructure/ComponentFactory');
import PublishButton from '../../common/PublishButton.react';
var DropDownSingleSelectableGroup = require('../../common/simple/DropDownSingleSelectableGroup.react');
var SelectableItem = require('../../common/simple/SelectableItem.react');
import { isEmpty } from 'lodash'

var RouteStore = require('../../../stores/RouteStore');
var SiteTailorStore = require('../../../stores/SiteTailorStore');

/** @module Header
 * @requires react
 * @requires lodash
 * @requires ComponentFactory
 * @requires PageStore
 * @requires FlashMessageActionsCreator
 * @requires PageActionsCreator
 * @requires PublishButton
 */
// function NavButton (props) {
// 	var navClass = "nav " + props.navClass;
// 	var navButtonsClass = "navigation-buttons " + ((!props.enabled) && 'disabled');
// 	return (
// 		<a href={props.link}>
// 			<div className={navButtonsClass}>
// 				<div className="menuLinkButton">
// 					<div className={navClass}></div>
// 					<p>{props.display}</p>
// 				</div>
// 			</div>
// 		</a>
// 	);
// }

var Header = ComponentFactory.Create(RouteStore, {
	displayName: "Header",

	getState: function() {
		return {
			route: RouteStore.getState('route'),
			tailorStore: SiteTailorStore.getLoadStatus()
		};
	},
	reloadTailor: function (e) {
		e.preventDefault()
		if (RouteStore.getState('route').path === 'sitetailor') {
			if (this.state.tailorStore.stepName === 'Publish') {
				SiteTailorActionCreator.setIsNeedToReload(true);
			}
		}
		RouteStore.getState('route').transitionTo('sitetailor')
	},

	render: function() {
		return (
			<div className="navigation-header">
				<div className="inner clearfix">
					<a className="dh-logo" href={this.route().makeHref('themes')}></a>
					{this.state.route.name == 'layout' &&
					<PublishButton websiteId={this.state.route.params.websiteId} />
					}
					<DropDownSingleSelectableGroup
						className={this.css('right')}
						hideSelected={true}>
						<SelectableItem value={null}>
							<div className="navigation-buttons">
								<div className="menuLinkButton">
									<div className="nav account"></div>
									<p>Account</p>
								</div>
							</div>
						</SelectableItem>
						<SelectableItem>
							<a href="https://panel.dreamhost.com/index.cgi?tree=support.msg&" target="_blank">Contact
								Support</a>
						</SelectableItem>
						<SelectableItem>
							<a href="https://help.dreamhost.com/hc/en-us/categories/204017167-Remixer" target="_blank">Knowledge
								Base</a>
						</SelectableItem>
						<SelectableItem>
							{
								isEmpty(this.props.user)
									? <a id='login-link' href={window.LOGIN_URL}>Login</a>
									: <a href="/logout">Logout</a>
							}
						</SelectableItem>
					</DropDownSingleSelectableGroup>
					<a href={this.route().makeHref("content")}>
						<div className={this.css('navigation-buttons', { 'disabled': isEmpty(this.props.user) })}>
							<div className="menuLinkButton">
								<div className="nav manage-content"></div>
								<p>Media</p>
							</div>
						</div>
					</a>
					<a href={this.route().makeHref('themes')}>
						<div className="navigation-buttons">
							<div className="menuLinkButton">
								<div className="nav themes"></div>
								<p>Themes</p>
							</div>
						</div>
					</a>
					<a onClick={(e) => this.reloadTailor(e)}>
						<div className="navigation-buttons">
							<div className="menuLinkButton">
								<div className="nav tailor"></div>
								<p>Create</p>
							</div>
						</div>
					</a>
					<a href={this.route().makeHref('websites')}>
						<div className={this.css('navigation-buttons', { 'disabled': isEmpty(this.props.user) })}>
							<div className="menuLinkButton">
								<div className="nav pages"></div>
								<p>My Sites</p>
							</div>
						</div>
					</a>
				</div>
			</div>
		);
	}
});

module.exports = Header;
