'use strict';

var _ = require('lodash');

var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ContentStore = require('../../stores/ContentStore');
var ImportProgressStore = require('../../stores/ImportProgressStore');
var ImportHistoryStore = require('../../stores/ImportHistoryStore');

var ContentTypes = require('../../constants/ContentTypes');
var ImportTypes = require('../../constants/ImportTypes');

var StickyPopover = require('../common/StickyPopover.react');
var PixabaySearch = require('./PixabaySearch.react').default;

/** @module CollectionImport
 * @requires lodash
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires ContentStore
 * @requires ContentTypes
 * @requires ImportTypes
 * @requires StickyPopover
 * @requires PixabayStore
 */
var CollectionImport = ComponentFactory.Create(ContentStore, ImportProgressStore, ImportHistoryStore, {
	displayName: "CollectionImport",
	/**
	 * @method getState
	 */
	getState: function() {

		var importedFileCount = _(ContentStore.getState())
			.values()
			.flatten()
			.filter((x) => _.isEqual(x.content_type, ContentTypes.IMAGE))
			.filter((x) => _.isEqual(x.import_type, ImportTypes.PIXABAY))
			.size();

		var importProgress = ImportProgressStore.getState(ImportTypes.PIXABAY);

		return {importedFileCount: importedFileCount, loading: importProgress && importProgress.progress};
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this.setState(_.assign(this.getState(), {element: ReactDOM.findDOMNode(this)}));
	},

	/**
	 * @method _showToolbar
	 */
	_showToolbar: function(e) {
		this.setState({popup: !this.state.popup});
	},

	/**
	 * @method _close
	 */
	_close: function() {
		this.setState({popup: false});
	},

	/**
	 * @method render
	 */
	render: function () {
		var that = this;
		var iconCss = ['import-icon-img pixabay',
			{ 'pulse': this.state.loading }];

		var popup = this.state.popup && <StickyPopover
			className="closable-popup import-popup pixabay-search"
			target={this.state.element}
			onClose={that._close}>
			<PixabaySearch />
		</StickyPopover>;

		return (
			<div className="import-header-column import-header-column-button bg-overlay collection">
				<div className="button-inner" onClick={this._showToolbar}>
					<div className="show-for-large-up import-collection-label" >
						<p >{this.props.title}</p>
						<h3 >{this.props.tagline}</h3>
					</div>
					<div className="import-icon-holder" title="Import Image">
						<div className={this.css(iconCss)} title="Import Image">
						</div>
						<div className="import-icon-bubble-holder" >
							<div className="bubbleNumber pixabay active" ><div className="bubbleLeft dh"></div><div className="bubbleRight dh"></div><div className="bubbleText dh"><p>{this.state.importedFileCount}</p></div></div>
						</div>
					</div>
				</div>
				{popup}
			</div>
		);
	}
});

module.exports = CollectionImport;
