'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module SignedUploadServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var SignedUploadServerActionsCreator = {
	/**
	 * @method receiveSignedUpload
	 */
	receiveSignedUpload: function(data) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SIGNED_UPLOAD,
			result: data
		});
	},
	/**
	 * @method receiveSignedUploadFails
	 */
	receiveSignedUploadFails: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SIGNED_UPLOAD_FAILS,
			message: 'Could not sign upload.'
		});
	}
};

module.exports = SignedUploadServerActionsCreator;
