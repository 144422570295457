'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var WebAPI = require('../utils/WebAPI');

/** @module SignedUploadActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var SignedUploadActionsCreator = {
	/**
	 * @method signUpload
	 */
	signUpload: function(query) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REQUEST_SIGNED_UPLOAD,
			query: query
		},
		WebAPI.signUpload, [query]);
	}
};

module.exports = SignedUploadActionsCreator;
