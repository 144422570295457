'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var Generic = require('./Generic.react');

/** @module SelectableItem
 * @requires react
 * @requires ComponentFactory
 * @requires Generic
 */
var SelectableItem = ComponentFactory.Create({
	displayName: "SelectableItem",
	propTypes: {
		value: React.PropTypes.any,
		selected: React.PropTypes.bool,
		onChange: React.PropTypes.func,
		tag: React.PropTypes.any
	},
	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			tag: 'li'
		};
	},
	/**
	 * @method render
	 */
	render: function() {
		var css = {
			"selectable-item": true,
			"selected": this.props.selected
		};
		return (
			<Generic {...this.props} className={this.css(this.props.className, css)} onClick={this.props.onChange} unselectable='on' />
		);
	}
});

module.exports = SelectableItem;
