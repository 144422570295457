'use strict';

import { filter, some, get, isNull, includes } from 'lodash';
var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');
var ComponentContextSettings = require('./ComponentContextSettings.react');
var ComponentContextColors = require('./ComponentContextColors.react');
var ComponentContextMarkup = require('./ComponentContextMarkup.react');
var ComponentContextStore = require('../stores/ComponentContextStore');
var ComponentMetadataStore = require('../stores/ComponentMetadataStore');
var PropertyTypes = require('../constants/PropertyTypes');
var Tabs = require('./common/Tabs.react');

/** @module ChordOptionsPanel
 * @requires react
 * @requires lodash
 * @requires ComponentFactory
 * @requires ComponentContextSettings
 * @requires ComponentContextColors
 * @requires ComponentContextMarkup
 * @requires ComponentContextStore
 * @requires ComponentMetadataStore
 * @requires PropertyTypes
 * @requires Tabs
 */
var ChordOptionsPanel = ComponentFactory.Create(ComponentContextStore, ComponentMetadataStore, {
	displayName: "ChordOptionsPanel",
	/**
	 * @method getState
	 */
	getState: function() {
		var state = {
			component: ComponentContextStore.getState('active')
		};
		if (!state.component) return state;

		state.metadata = ComponentMetadataStore.getComponentMetadata(state.component.reference);
		return state;
	},

	/**
	 * @method render
	 */
	render: function() {
		var state = this.state;

		var settingsByDefault = [PropertyTypes.videoUrl, PropertyTypes.boolean];
		var settings;
		var colors;
		if(state.metadata && state.component) {
			settings = filter(state.metadata.properties, function (property) {
				return property.isSetting ||
					(isNull(property.isSetting) && includes(settingsByDefault, property.propertyType));
			});

			colors = filter(state.metadata.properties, {propertyType: PropertyTypes.color});
		}

		var htmlText = get(this.state, ["metadata", "html"], "");

		var tabs = [];
		if (some(settings)) {
			tabs.push((
				<div key='Settings'>
					<ComponentContextSettings className="options-tab"
						component={this.state.component} settings={settings}/>
				</div>
			));
		}
		if (some(colors)) {
			tabs.push((
				<div key='Colors'>
					<ComponentContextColors className="colors-tab"
						component={this.state.component} colors={colors}/>
				</div>
			));
		}
		tabs.push((
			<div key='Markup'>
				<ComponentContextMarkup className="markup-tab" markup={htmlText}/>
			</div>
		));

		return (
			<Tabs>{tabs}</Tabs>
		);
	}
});

module.exports = ChordOptionsPanel;
