'use strict';

var _ = require('lodash');

var StoreFactory = require('../infrastructure/StoreFactory');

var ContentStore = require('./ContentStore');

var RemixerConstants = require('../constants/RemixerConstants');
var ContentTypes = require('../constants/ContentTypes');
var ContentFilters = require('../constants/ContentFilters');
var ContentFilterTypes = require('../constants/ContentFilterTypes');

/** @module ContentFilterStore
 * @requires lodash
 * @requires StoreFactory
 * @requires ContentStore
 * @requires RemixerConstants
 * @requires ContentTypes
 * @requires ContentFilters
 * @requires ContentFilterTypes
 */
var ContentFilterStore = StoreFactory.Create({
	/**
	 * @method _getInitialState
	 */
	_getInitialState: function() {
		return _.groupBy(ContentFilters.filters, 'type');
	},

	/**
	 * @method _updateTagContentFilters
	 */
	_updateTagContentFilters: function(action) {
		this.waitFor([ContentStore.token]);

		var tagFilters = _(ContentTypes.TAG)
			.thru(ContentStore.getState)
			.values()
			.flatten()
			.map((tag) => {
				return ContentFilters.createContentFilter(tag.name, ContentFilterTypes.TAG,
					function(contentItem, content) {
						return _.some(contentItem.relative_content_items, {relative_content_id: tag.id});
					});
			})
			.value();

		this._set(ContentFilterTypes.TAG, tagFilters);
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		// TODO: Review all those actions. Do all of them update content tags/groups?
		// TODO: Using verbs in action name looks pretty confusing... should be refactored
		// Tags
		handlers[RemixerConstants.LOAD_CONTENT] = this._updateTagContentFilters;
		handlers[RemixerConstants.POST_CONTENT] = this._updateTagContentFilters;
		handlers[RemixerConstants.PUT_CONTENT] = this._updateTagContentFilters;

		handlers[RemixerConstants.DELETE_CONTENT] = this._updateTagContentFilters;
		handlers[RemixerConstants.RECEIVE_CONTENT_DELETE] = this._updateTagContentFilters;

		handlers[RemixerConstants.RECEIVE_CONTENT_UPDATE] = this._updateTagContentFilters;
		handlers[RemixerConstants.RECEIVE_CONTENT_PUT] = this._updateTagContentFilters;
		handlers[RemixerConstants.RECEIVE_CONTENT_PUT_FAILS] = this._updateTagContentFilters;
		handlers[RemixerConstants.RECEIVE_CONTENT_POST] = this._updateTagContentFilters;
		handlers[RemixerConstants.RECEIVE_CONTENT_POST_FAILS] = this._updateTagContentFilters;

		handlers[RemixerConstants.RECEIVE_CONTENT] = this._updateTagContentFilters;
		handlers[RemixerConstants.RECEIVE_CONTENT_FAILS] = this._updateTagContentFilters;
		handlers[RemixerConstants.RECEIVE_IMPORT_FINISHED] = this._updateTagContentFilters;

		handlers[RemixerConstants.UPDATE_CONTENT_TAGS] = this._updateTagContentFilters;
		handlers[RemixerConstants.RECEIVE_UPDATE_CONTENT_TAGS] = this._updateTagContentFilters;
		handlers[RemixerConstants.RECEIVE_UPDATE_CONTENT_TAGS_FAILS] = this._updateTagContentFilters;

		return handlers;
	}
});

module.exports = ContentFilterStore;
