'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module ImportHistoryStore
 * @requires StoreFactory
 * @requires RemixerConstants
 */
var ImportHistoryStore = StoreFactory.Create({
	/**
	 * @method _setImportHistory
	 */
	_setImportHistory: function (action) {
		this._set(action.importType, action.data);
	},
	/**
	 * @method _setFailed
	 */
	_setFailed: function(action) {
		this._failed(action.importType);
	},
	/**
	 * @method _setFilling
	 */
	_setFilling: function(action) {
		this._filling(action.importType);
	},
	/**
	 * @method _resetHistory
	 */
	_resetHistory: function(action) {
		this._reset(action.importType);
	},
	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		handlers[RemixerConstants.LOAD_IMPORT_HISTORY] = this._setFilling;

		handlers[RemixerConstants.RECEIVE_IMPORT_HISTORY] = this._setImportHistory;
		handlers[RemixerConstants.RECEIVE_IMPORT_HISTORY_FAILS] = this._setFailed;

		handlers[RemixerConstants.RECEIVE_IMPORT_FINISHED] = this._resetHistory;

		return handlers;
	}
});

module.exports = ImportHistoryStore;
