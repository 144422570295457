'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var WebAPI = require('../utils/WebAPI');

import {PageProjectsLoadingStatusStore} from '../stores/PageProjectStore';

var PageProjectActionsCreator = {
	/**
	 * @method createPageProject
	 */
	createPageProject: function(website, title, description, slug) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.CREATE_PAGE_PROJECT,
			website,
			title,
			description,
			slug
		},
		WebAPI.createPageProject, [website, title, description, slug]);
	},
	clonePageProject: function(website, pageProject, title, description, slug) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.CLONE_PAGE_PROJECT,
			website,
			pageProject,
			title,
			description,
			slug
		},
		WebAPI.clonePageProject, [website, pageProject, title, description, slug]);
	},
	updatePageProject: function(pageProject) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.UPDATE_PAGE_PROJECT,
			pageProject
		},
		WebAPI.updatePageProject, [pageProject]);
	},
	deletePageProject: function(pageProject) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.DELETE_PAGE_PROJECT,
			pageProject
		},
		WebAPI.deletePageProject, [pageProject]);
	},
	/**
	 * @method loadPageProjects
	 */
	loadPageProjects: function(forceLoad) {
		if (!forceLoad && !PageProjectsLoadingStatusStore.getState().needs())return;
		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_ALL_PAGE_PROJECTS
		},
		WebAPI.loadAllPageProjects);
	}
};

module.exports = PageProjectActionsCreator;
