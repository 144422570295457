'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

import { keys, uniqueId, isString, has, forEach } from 'lodash';

/** @module FlashMessageStore
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires lodash
 */
var FlashMessageStore = StoreFactory.Create({
	/**
	 * @method _addFlashMessage
	 */
	_addFlashMessage: function(action) {
		this._remove(keys(this._get()));

		var message = action.message;
		message.id = uniqueId();
		message.level = message.level || 'info';

		this._set(message.id, message);
	},

	/**
	 * @method _removeFlashMessage
	 */
	_removeFlashMessage: function(action) {
		this._remove(action.message.id);
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		handlers[RemixerConstants.ADD_FLASH_MESSAGE] = this._addFlashMessage;
		handlers[RemixerConstants.REMOVE_FLASH_MESSAGE] = this._removeFlashMessage;

		return handlers;
	},

	/**
	 * @method _getErrorDescription
	 */
	_getErrorDescription: function (error) {
		return !isString(error) ? error.description : error;
	},

	/**
	 * @method _globalHandler
	 */
	_globalHandler: function(action) {
		var flashMessage = null;
		switch (action.actionType) {
			case 'IMPORT_STARTING':
				flashMessage = {message: {text: action.importType.getImportStartingMessage(action), level: 'info'}};
				break;
			case 'IMPORT_FAILS':
				flashMessage = {message: {text: action.importType.getImportFailedMessage(action), level: 'error'}};
				break;
			case 'RECEIVE_IMPORT_AUTH_EXPIRED':
				flashMessage = {message: {text: action.importType.getImportFaildAuthExpiredMessage(action), level: 'error'}};
				break;
			case 'RECEIVE_IMPORT_FINISHED':
				flashMessage = {message: {text: action.importType.getImportFinishedMessage(action), level: 'success'}};
				break;
			case RemixerConstants.RECEIVE_WEBSITE_PUBLISH_FAILED:
				flashMessage = {message: {text: "Publishing failed, please retry!", level: 'error'}};
				break;
			case RemixerConstants.POPUP_BLOCKED:
				flashMessage = {message: {text: "Popup window open failed, please open manually using the \"View\" button!", level: 'info'}};
				break;
			case RemixerConstants.RECEIVE_DELETE_PAGE_FAIL:
				flashMessage = {message: {text: "Failed to delete the page! Please try again later.", level: 'error'}};
				break;
			case RemixerConstants.RECEIVE_UPDATE_WEBSITE:
				flashMessage = {message: {text: "Website settings saved.", level: 'success'}};
				break;
			case RemixerConstants.RECEIVE_DELETE_WEBSITE_FAIL:
				flashMessage = {message: {text: "Failed to delete the website! Please try again later.", level: 'error'}};
				break;
			case RemixerConstants.RECEIVE_USER_INFO_FAIL:
				flashMessage = false;
				break;
			case RemixerConstants.RECEIVE_TRIAL_STATUS_FAILS:
				flashMessage = false;
				break;
			case RemixerConstants.RECEIVE_WORDPRESS_EXPORT:
				flashMessage = {message: {text: "Congratulations on exporting your Remixer website to WordPress! Your website’s move is currently in progress. An email has been sent to your email with next steps and instructions for logging into your WordPress dashboard.", level: 'success', messageDisappearTimeout: -1}};
				break;
		}
		if (flashMessage === null && /FAILS?$/.test(action.actionType)) {
			if (has(action, ['data', 'errors'])) {
				forEach(action.data.errors, (error) => {
					var errorMsg = this._getErrorDescription(error);
					flashMessage = {message: {text: errorMsg, level: 'error'}};
				});
			} else if (action.message) {
				flashMessage = {message: {text: action.message, level: 'error'}};
			} else {
				flashMessage = {message: {text: "Some error occurred", level: 'error'}};
			}
		}

		if (flashMessage) {
			this._addFlashMessage(flashMessage);
		}
	}
});

module.exports = FlashMessageStore;
