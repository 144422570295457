'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module RouteStore
 * @requires StoreFactory
 * @requires RemixerConstants
 */
var RouteStore = StoreFactory.Create({
	/**
	 * @method _setRoute
	 */
	_setRoute: function(action) {
		this._set('route', action.route);
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function () {
		var handlers = {};

		handlers[RemixerConstants.CHANGE_ROUTE] = this._setRoute;

		return handlers;
	}
});

module.exports = RouteStore;
