import React from 'react';

export default class PixabaySearchForm extends React.Component {
	static propTypes = {
		onSearch: React.PropTypes.func.isRequired,
		query: React.PropTypes.string,
		minified: React.PropTypes.bool,
		className: React.PropTypes.string
	};
	static defaultProps = {
		query: "",
		minified: false
	};
	constructor(props) {
		super(props);
		this.state = {query: props.query};
	}

	componentDidMount() {
		this._input.focus();
	}

	_handleSubmit = (event) => {
		event.preventDefault();
		this.props.onSearch(this.state.query);
	}

	render() {
		return (
			<form onSubmit={this._handleSubmit} action="" className={this.props.className}>
				{this.props.minified ?
					<input type="text" value={this.state.query} onChange={(event) => this.setState({query: event.target.value})} ref={(input) => this._input = input} className="search rounded" />
					:
					<div className="row">
						<div className="large-10 large-offset-1 columns">
							<div className="row collapse">
								<div className="small-10 columns">
									<input type="text" placeholder="Search images, vectors and art illustrations" value={this.state.query} onChange={(event) => this.setState({query: event.target.value})} ref={(input) => this._input = input} />
								</div>
								<div className="small-2 columns">
									<button type="submit" disabled={!this.state.query} className="button secondary postfix"><i className="mdi mdi-magnify" /></button>
								</div>
							</div>
						</div>
					</div>
				}
			</form>
		);
	}
}
