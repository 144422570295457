'use strict';

import { keyBy, mapValues } from 'lodash';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

var PageDomState = require('../models/PageDomState');

/** @module PageDomStateStore
 * @requires lodash
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires PageDomState
 */
var PageDomStateStore = StoreFactory.Create({
	/**
	 * @method getPageRectangle
	 */
	getPageRectangle: function(pageId) {
		return this.getState(pageId, 'page');
	},

	/**
	 * @method getComponentRectangle
	 */
	getComponentRectangle: function(pageId, componentId) {
		return this.getState("chash_" + pageId, componentId, 'rectangle');
	},

	/**
	 * @method getPropertyRectangle
	 */
	getPropertyRectangle: function(pageId, componentId, path) {
		return this.getState("phash_" + pageId, componentId, path, 'rectangle');
	},

	/**
	 * @method _setState
	 */
	_setState: function(action) {
		var domState = new PageDomState(action.domState);
		this._set(action.pageId, domState);
		var componentsHash = keyBy(domState.components, 'componentId');
		this._set("chash_" + action.pageId, componentsHash);
		this._set("phash_" + action.pageId, mapValues(componentsHash,
			function(component) { return keyBy(component.properties, 'path'); }));
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function () {
		var handlers = {};

		handlers[RemixerConstants.UPDATE_PAGE_DOM] = this._setState;

		return handlers;
	}
});

module.exports = PageDomStateStore;
