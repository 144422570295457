'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
import WebsiteActionsCreator from './WebsiteActionsCreator';

/** @module DreamHostersAvailableServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var DreamHostersAvailableServerActionsCreator = {
	/**
	 * @method receiveDreamHostersDomain
	 */
	receiveDreamHostersDomain: function(response, domain, website) {
		let newWebsite = website.set('domain', domain)
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_DREAMHOSTERS_DOMAIN,
			domain: domain
		},
		WebsiteActionsCreator.updateWebsite, [newWebsite]);
	},
	/**
	 * @method receiveDreamHostersDomainFailed
	 */
	receiveDreamHostersDomainFailed: function(response, domain) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_DREAMHOSTERS_DOMAIN_FAILED,
			domain: domain,
			error: response.message || response.error_key
		});
	}
};

module.exports = DreamHostersAvailableServerActionsCreator;
