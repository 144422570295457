'use strict';

import { identity, create, first, isObject, constant, isString, isFunction, get, clone, map, initial, isInteger } from 'lodash';

var ComponentDomAttributes = require('../../constants/ComponentDomAttributes');
var PropertyTypes = require('../../constants/PropertyTypes');

/** @module Helpers
 * @requires lodash
 * @requires ComponentDomAttributes
 * @requires PropertyTypes
 */

function Helper(handlebars) {
	this.getName = identity;
	this.getHelperFunction = identity;

	this.register = function() {
		var name = this.getName(),
			func = this.getHelperFunction();

		if (isString(name) && isFunction(func)) {
			handlebars.registerHelper.call(handlebars, name, func);
		}
	};
}

function PropertyHelper(handlebars) {
	Helper.call(this, handlebars);

	this.getName = constant("property");
	this.getHelperFunction = function() {
		return function (property, options) {
			var path = handlebars.Utils.appendContextPath(options.data.contextPath, options.ids[0]);
			var propertyInfo = { path: path.replace(/this\./g, '') };

			if(options.data.root.__metadata__) {
				path = propertyInfo.path.split('.');
				var type_path = map(path, (p) => {
					if (isInteger(parseInt(p, 10))) {
						return 'childSpec';
					} else {
						return p;
					}
				});
				for (var i = 0; i < type_path.length; i++) {
					var a = clone(type_path);
					a.push('propertyType');
					var v = get(options.data.root.__metadata__, a);
					if (v) {
						propertyInfo.type = v;
						break;
					}
					type_path = initial(type_path);
				}
				if (!v) {
					propertyInfo.type = v || get(options.data.root.__metadata__, [first(path), 'propertyType']);
				}
				propertyInfo.name = first(path);
			}

			return new handlebars.SafeString(JSON.stringify(propertyInfo));
		};
	};
}

function EachHelper(handlebars) {
	Helper.call(this, handlebars);

	this.getName = constant("each");
	//copied from: handlebars.js
	//and modified.
	this.getHelperFunction = function() {
		return function (context, options) {
			if (!options) {
				throw new handlebars.Exception('Must pass iterator to #each');
			}

			var fn = options.fn,
				inverse = options.inverse,
				i = 0,
				ret = '',
				data, contextPath;

			if (options.data && options.ids) {
				contextPath = handlebars.Utils.appendContextPath(options.data.contextPath, options.ids[0]) + '.';
			}
			else {
				contextPath = '';
			}

			if (handlebars.Utils.isFunction(context)) {
				context = context.call(this);
			}

			if (options.data) {
				data = handlebars.createFrame(options.data);
			}

			var propertyPath = contextPath.replace(/this\./g, '');
			var propertyName = first(propertyPath.split('.'));

			function execIteration(field, index, last) {
				var openMarker = "", closeMarker = "";
				if (data) {
					data.key = field;
					data.index = index;
					data.first = index === 0;
					data.last = !!last;

					if (contextPath) {
						data.contextPath = contextPath + field;
					}

					var propertyInfo = {};
					if(options.data.root.__metadata__) {
						var type = get(options.data.root.__metadata__, [propertyName, 'propertyType']);
						propertyInfo.name = propertyName;
						propertyInfo.type = PropertyTypes.toArrayItem(type);
						propertyInfo.path = propertyPath + field;

						var info = JSON.stringify(propertyInfo);
						openMarker = '<!--' + ComponentDomAttributes.property + '-->';
						closeMarker = '<!--/' + ComponentDomAttributes.property + '=' + info + '-->';
					}
				}

				ret = ret + openMarker + fn(context[field], {
					data: data,
					blockParams: handlebars.Utils.blockParams([context[field], field], [contextPath + field, null])
				}) + closeMarker;
			}

			if (context && isObject(context)) {
				if (handlebars.Utils.isArray(context)) {
					for (var j = context.length; i < j; i++) {
						execIteration(i, i, i === context.length - 1);
					}
				} else {
					var priorKey;

					for (var key in context) {
						if (context.hasOwnProperty(key)) {
							// We're running the iterations one step out of sync so we can detect
							// the last iteration without have to scan the object twice and create
							// an itermediate keys array.
							if (priorKey !== undefined) {
								execIteration(priorKey, i - 1);
							}
							priorKey = key;
							i++;
						}
					}
					if (priorKey !== undefined) {
						execIteration(priorKey, i - 1, true);
					}
				}
			}

			if (i === 0) {
				ret = inverse(this);
			}

			return ret;
		};
	};
}

PropertyHelper.prototype = create(Helper.prototype, {
	'constructor': PropertyHelper
});

EachHelper.prototype = create(Helper.prototype, {
	'constructor': EachHelper
});

module.exports = {
	PropertyHelper: PropertyHelper,
	EachHelper: EachHelper
};
