'use strict';

import { pickBy, endsWith, defaults, clone, mapValues, omit, partial } from 'lodash';

var crossroads = require('crossroads');
var hasher = require('hasher');

var Config = require('./Config');

var RouteActionsCreator = require('../actions/RouteActionsCreator');

var _normalize = function(request, values) {
	return pickBy(values, function(value, key) { return !endsWith(key, '_') && !/\d+/.test(key); });
};

/** @module Router
 * @requires lodash
 * @requires crossroads
 * @requires hasher
 * @requires Config
 * @requires RouteActionsCreator
 */
function Router(options) {
	var _options = defaults({}, options, { shouldTypecast: true, ignoreCase: true, normalize: _normalize });
	var _routes = {};

	var _parse = function (path) { crossroads.parse(path); };

	var _makePath = function(name, parameters) {
		var route = _routes[name];
		if (!route) throw "There is no route named '" + name + "'";

		return route.interpolate(parameters);
	};

	var _makeHref = function(name, parameters) {
		//TODO: make some type of configuration for hash or history navigation
		//let's use only hash for now
		return '#' + hasher.prependHash + _makePath(name, parameters);
	};

	var _redirectTo = function(name, parameters) {
		hasher.changed.active = false;
		hasher.setHash(_makePath(name, parameters));
		hasher.changed.active = true;
		window.location.reload();
	};

	var _transitionTo = function(name, parameters) {
		hasher.setHash(_makePath(name, parameters));
	};

	var _extend = function(route) {
		route = clone(route);

		route.makeHref = _makeHref;
		route.transitionTo = _transitionTo;
		route.redirectTo = _redirectTo;

		return route;
	};

	var _createConfig = function(config) {
		return mapValues(config, _extend);
	};

	var _onRouteChanged = function(route, name, values) {
		var params = omit(values, '?query'),
			query = values['?query'];

		route.name = name;
		route.params = params;
		route.query = query || {};
		route.path = _makePath(name, params);

		RouteActionsCreator.change(route);
	};

	var _registerRoute = function(route, name) {
		var registeredRoute = crossroads.addRoute(route.path + ':?query:', partial(_onRouteChanged, route, name));
		registeredRoute.rules = route.rules;
		return registeredRoute;
	};

	var _notFound = function(request) {
		console.error( "404/ Not found: " + request);
		_transitionTo('default');
	};

	this.init = function() {
		var config = _createConfig(Config);
		_routes = mapValues(config, _registerRoute);
		crossroads.shouldTypecast = _options.shouldTypecast;
		crossroads.ignoreCase = _options.ignoreCase;
		crossroads.normalizeFn = _options.normalize;
		crossroads.bypassed.add(_notFound);

		//TODO: it's only for hash navigation if we gonna use history
		//we need historyjs or something like that
		hasher.initialized.add(_parse);
		hasher.changed.add(_parse);
		hasher.init();
	};
}

module.exports = Router;
