import React from 'react';
import {Container} from 'flux/utils';

var classnames = require('classnames');

import WebsitesStore, {WebsitesLoadingStatusStore} from '../../stores/WebsitesStore';
import AvailableDomainsStore, {AvailableDomainsLoadingStatusStore} from '../../stores/AvailableDomainsStore';
import TrialStatusStore from '../../stores/TrialStatusStore';

import RouteStore from '../../stores/RouteStore';

import WebsiteActionsCreator from '../../actions/WebsiteActionsCreator';
import AvailableDomainActionsCreator from '../../actions/AvailableDomainActionsCreator';

import WebsiteSettingsForm, {TAB} from '../WebsiteSettingsForm.react';


class WebsiteSettings extends React.Component {
	static propTypes = {
		websiteId: React.PropTypes.number.isRequired
	};
	static getStores() {
		return [
			WebsitesStore,
			AvailableDomainsStore,
			WebsitesLoadingStatusStore,
			AvailableDomainsLoadingStatusStore,
			TrialStatusStore
		];
	}
	static _getState(props, prevState) {
		let website = WebsitesStore.getState().get(props.websiteId);
		let websiteList = WebsitesStore.getState().toList();
		let currentIndex = websiteList.indexOf(website);
		let nextWebsite = websiteList.get(currentIndex + 1);
		let prevWebsite = currentIndex != 0 && websiteList.get(currentIndex - 1);
		return {
			website,
			nextWebsite,
			prevWebsite,
			availableDomainList: AvailableDomainsStore.getState(),
			loading: WebsitesLoadingStatusStore.getState().loading,
			loadingAvailableDomains: AvailableDomainsLoadingStatusStore.getState().loading,
			tab: prevState.tab,
			accountStatus: TrialStatusStore.getState()
		};
	}
	static calculateState(prevState, props) {
		return WebsiteSettings._getState(props, prevState || {tab: TAB.SEO});
	}
	css = classnames;
	componentWillReceiveProps(nextProps) {
		this.setState(WebsiteSettings._getState(nextProps, {tab: TAB.SEO}));
	}
	componentDidMount() {
		WebsiteActionsCreator.loadWebsites();
		AvailableDomainActionsCreator.loadAvailableDomains();
	}
	handleChangeWebsite(website) {
		WebsiteActionsCreator.updateWebsite(website);
	}
	handleFilterWebsite(key, value) {
		if (key == "domain") {
			return value || null;
		} else if (key == "allowRobots") {
			return value == "true";
		}
		return value;
	}
	handleValidateWebsite(key, value) {
		if (key == "domain") {
			if (value) {
				return ([0, 4].indexOf(this.state.availableDomainList.find((domain) => domain.domain == value).errorCode) !== -1
					|| this.state.website.domain == value);
			}
		}
		return true;
	}
	handleAvailableDomainsRefresh() {
		AvailableDomainActionsCreator.loadAvailableDomains(true)
	}
	handleWebsiteDelete() {
		RouteStore.getState('route').transitionTo('websites');
		WebsiteActionsCreator.deleteWebsite(this.state.website);
	}
	handleTab(tab) {
		this.setState({tab});
	}
	render() {
		return (<div className="row page-settings-page">
			<div className="small-12 columns small-centered page-title">
				<div className="row">
					<div className="small-4 columns">
						{this.state.prevWebsite &&
								<a href={RouteStore.getState('route').makeHref("websiteSettings",  { websiteId: this.state.prevWebsite.id})} className="page-title-subhead left">&larr; Previous site</a>
						}&nbsp;
					</div>
					<div className="small-4 columns"><h1 className="page-title-header">Settings</h1></div>
					<div className="small-4 columns">
						{this.state.nextWebsite &&
								<a href={RouteStore.getState('route').makeHref("websiteSettings",  { websiteId: this.state.nextWebsite.id})} className="page-title-subhead right">Next site &rarr;</a>
						}
					</div>
				</div>
			</div>
			{(!this.state.loading && this.state.website) ?
				<WebsiteSettingsForm
					availableDomainList={this.state.availableDomainList}
					accountStatus={this.state.accountStatus}
					model={this.state.website}
					onSave={(website) => this.handleChangeWebsite(website)}
					onFilter={(key, value) => this.handleFilterWebsite(key, value)}
					onValidate={(key, value) => this.handleValidateWebsite(key, value)}
					onRefresh={() => this.handleAvailableDomainsRefresh()}
					tab={this.state.tab}
					onTab={(tab) => this.handleTab(tab)}
					onDelete={() => this.handleWebsiteDelete()} />
				:
				<div>Loading ...</div>
			}
		</div>
		);
	}
}

export default Container.create(WebsiteSettings, {withProps: true});
