'use strict';

import { get } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');
var ContentTypes = require('../../../constants/ContentTypes');

var SliderWrapper = require('./SliderWrapper.react');

var OverlayImage = require('./OverlayImage.react');
var OverlayYoutube = require('./OverlayYoutube.react');
var OverlayVimeo = require('./OverlayVimeo.react');
var OverlayMarkdown = require('./OverlayMarkdown.react');
var OverlaySoundcloud = require('./OverlaySoundcloud.react');

var contentTypes = {};
contentTypes[ContentTypes.IMAGE] = OverlayImage;
contentTypes[ContentTypes.YOUTUBE] = OverlayYoutube;
contentTypes[ContentTypes.VIMEO] = OverlayVimeo;
contentTypes[ContentTypes.MARKDOWN] = OverlayMarkdown;
contentTypes[ContentTypes.SOUNDCLOUD] = OverlaySoundcloud;


var config = {
	'higher-order-components': [
		SliderWrapper
	]
};

/** @module Overlay
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ContentTypes
 * @requires SliderWrapper
 * @requires OverlayImage
 * @requires OverlayYoutube
 * @requires OverlayMarkdown
 * @requires OverlaySoundcloud
 */
var Overlay = ComponentFactory.Config(config).Create({
	displayName: "Common.Overlay",
	propTypes: {
		content: React.PropTypes.array,
		contentItem: React.PropTypes.object,
		picked: React.PropTypes.array,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func,
		onUpdate: React.PropTypes.func,
		action: React.PropTypes.string
	},

	/**
	 * @method render
	 */
	render: function() {
		if (!this.props.contentItem) {
			return null;
		}
		var TypedDetails = contentTypes[get(this.props.contentItem, 'content_type')];
		return (
			<TypedDetails {...this.props}/>
		);
	}
});

module.exports = Overlay;
