'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var WebAPI = require('../utils/WebAPI');

var ConfigStore = require('../stores/ConfigStore');

/** @module ConfigActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 * @requires ConfigStore
 */
var ConfigActionsCreator = {
	/**
	 * @method loadConfig
	 */
	loadConfig: function() {
		if (!ConfigStore.needs()) return;

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_CONFIG
		},
		WebAPI.loadConfig);
	}
};

module.exports = ConfigActionsCreator;
