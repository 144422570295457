'use strict';



var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');
var ComponentsPreviewStore = require('../stores/ComponentsPreviewStore');
var ComponentInstance = require('../models/ComponentInstance');
var ComponentsPreviewBase = require('./ComponentsPreviewBase.react');

import { get, isEqual } from 'lodash'

/** @module SimilarComponentsPreview
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentActionsCreator
 * @requires ComponentsPreviewStore
 * @requires ComponentInstance
 * @requires ComponentsPreviewBase
 */
var SimilarComponentsPreview = ComponentFactory.Create(ComponentsPreviewStore, {
	displayName: "SimilarComponentsPreview",
	propTypes: {
		component: React.PropTypes.instanceOf(ComponentInstance).isRequired,
		search: React.PropTypes.oneOf(['similar', 'relevant']).isRequired,
		itemsPerPage: React.PropTypes.number
	},

	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			itemsPerPage: 5
		};
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var pIndex = get(this.state, 'pIndex', 0);
		var componentCategory = get(this.state, 'componentCategory', '');
		var searchText = get(this.state, 'searchText', '');

		return this._createState(componentCategory, searchText, pIndex);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		if(get(state, 'componentCategory') !== this.state.componentCategory
			|| get(state, 'searchText') !== this.state.searchText
			|| get(state, 'pIndex') !== this.state.pIndex
			|| !isEqual(this.props.component, props.component)
		) {
			var pageId = this.props.component.pageId,
				componentId = this.props.component.id,
				pIndex = this.state.pIndex;

			if (this.props.search === 'similar') {
				ComponentActionsCreator.loadSimilarComponents(pageId, componentId, this.state.componentCategory,
					this.state.searchText, pIndex, this.props.itemsPerPage);
			} else if (this.props.search === 'relevant') {
				ComponentActionsCreator.loadRelevantComponents(pageId, componentId, this.state.componentCategory,
					this.state.searchText, pIndex, this.props.itemsPerPage);
			}
		}
	},

	/**
	 * @method _createState
	 */
	_createState: function (componentCategory, searchText, pIndex) {
		var search = this.props.search,
			pageId = this.props.component.pageId,
			componentId = this.props.component.id;

		let loading = !!ComponentsPreviewStore.filling(search, pageId, componentId, componentCategory, searchText, pIndex);
		let filled = isPageFilled(pIndex);
		let previews = getPagePreviews(pIndex);
		if (!loading && filled && previews.length === 0) {
			pIndex = 0;
			previews = getPagePreviews(pIndex);
		}
		let hasOnePageOnly = !loading && pIndex === 0 && (
			(filled && previews.length < this.props.itemsPerPage )
				|| (isPageFilled(pIndex + 1) && getPagePreviews(pIndex + 1).length === 0)
		);

		return {
			pIndex: pIndex,
			componentCategory: componentCategory,
			searchText: searchText,
			previews: previews,
			loading: loading,
			hasOnePageOnly: hasOnePageOnly
		};

		function isPageFilled(pageIndex) {
			return ComponentsPreviewStore.getMetaState(search, pageId, componentId, componentCategory, searchText, pageIndex).filled;
		}

		function getPagePreviews(pageIndex) {
			return ComponentsPreviewStore.getState(search, pageId, componentId, componentCategory, searchText, pageIndex);
		}
	},

	/**
	 * @method _nextPage
	 */
	_nextPage: function() {
		var pIndex = get(this.state, ["previews", "length"], 0) >= this.props.itemsPerPage ? this.state.pIndex + 1 : 0;
		this.setState(this._createState(this.state.componentCategory, this.state.searchText, pIndex));
	},

	/**
	 * @method _prevPage
	 */
	_prevPage: function() {
		var pIndex = this.state.pIndex > 0 ? this.state.pIndex - 1 : 0;
		this.setState(this._createState(this.state.componentCategory, this.state.searchText, pIndex));
	},

	/**
	 * @method _createComponent
	 */
	_createComponent: function(reference) {
		if (this.props.search === 'similar') {
			ComponentActionsCreator.replaceComponentInstance(this.props.component, reference);
		} else {
			var position = (this.props.component.position || 0) + 1;
			ComponentActionsCreator.createComponentInstance(this.props.component.pageId, reference, position);
		}
	},

	/**
	 * @method _categorySelected
	 */
	_categorySelected: function(category){
		this.setState(this._createState(category, '', 0));
	},

	_searchApplied: function(text){
		this.setState(this._createState('', text, 0));
	},

	/**
	 * @method render
	 */
	render: function() {
		return <ComponentsPreviewBase previews={this.state.previews} onComponentClick={this._createComponent}
			onPrevPage={this._prevPage} onNextPage={this._nextPage}
			componentCategory={this.state.componentCategory} onCategorySelected={this._categorySelected}
			searchText={this.state.searchText}
			onSearchApplied={this._searchApplied}
			loading={this.state.loading}
			disableNext={this.state.hasOnePageOnly || this.state.loading }
			disablePrev={this.state.pIndex === 0 || this.state.loading }
		/>;
	}
});

module.exports = SimilarComponentsPreview;
