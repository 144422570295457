'use strict';


/** @module ComponentDomAttributes
 */
module.exports = {
	id: 'data-component-id',
	position: 'data-position',
	name: 'class',
	chordId: 'id',
	property: 'data-property',
	active: 'data-component-active',
	expanded: 'data-component-expanded'
};
