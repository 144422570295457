import React from 'react';
import classnames from 'classnames';

import _ from 'lodash';

import Form from './common/Form.react';

export const TAB = {
	EXISTING: 'existing',
	DREAMHOST: 'dreamhost'
};

export default class WordPressExportSettingsForm extends Form {
	static propTypes = {
		model: React.PropTypes.object.isRequired,
		onSave: React.PropTypes.func,
		onValidate: React.PropTypes.func,
		onFilter: React.PropTypes.func,
		availableDomainList: React.PropTypes.object.isRequired,
		onRefresh: React.PropTypes.func.isRequired,
		tab: React.PropTypes.string.isRequired,
		onTab: React.PropTypes.func.isRequired,
		onExport: React.PropTypes.func.isRequired,
		accountStatus: React.PropTypes.object.isRequired,
		onHandleDreamhostersChange: React.PropTypes.func.isRequired,
		dreamhostersDomain: React.PropTypes.string.isRequired
	};
	css = classnames;
	saveOnChange=true
	refreshThenReset() {
		this.props.onRefresh();
		this.reset();
	}
	render() {
		var warning = {};
		let domain = this.props.availableDomainList.find((d) => this.state.model.domain == d.domain);
		if (domain && domain.domain != this.props.model.domain) {
			switch (domain.errorCode) {
				case 1: //custom dns records found
					warning = {
						heading: "Warning: " + domain.domain + " has custom DNS records",
						details: <p className="form-warning-details">We cannot publish to a domain with custom DNS records. You will need to remove those custom records using the <a target='_blank' href={'https://panel.dreamhost.com/index.cgi?tree=domain.manage&current_step=Index&next_step=ShowZone&domain=' + domain.domain}>Manage Domains section of the DreamHost Panel</a> first. After making that change press the Refresh button and reselect the domain again.</p>,
						confirm: <input className="btn-primary" type="button" value="Refresh" onClick={() => this.refreshThenReset()} />};
					break;
				case 2: //dreamhosters domain
					break;
				case 3: //http service found
					warning = {
						heading: "Warning: " + domain.domain + " already has hosting",
						details: <p className="form-warning-details">Clicking confirm will install WordPress on this domain and export the current Remixer page into that WordPress installation.</p>,
						confirm: <p>Confirm <input type="checkbox" name="confirm" key={domain.domain} onClick={() => this.save(true)}/></p>};
					break;
				case 4: //remixer domain
					warning = {
						heading: "Warning: " + domain.domain + " is a Remixer domain",
						details: <p className="form-warning-details">Clicking confirm will install WordPress on this domain and export the current Remixer page into that WordPress installation.</p>,
						confirm: <p>Confirm <input type="checkbox" name="confirm" key={domain.domain} onClick={() => this.save(true)}/></p>};
					break;
				case 5: //dreampress domain
					warning = {
						heading: "Warning: " + domain.domain + " is a DreamPress domain",
						details: <p className="form-warning-details">We cannot publish to DreamPress domains. You will need to remove the DreamPress server for this domain through the <a target='_blank' href='https://panel.dreamhost.com/index.cgi?tree=dreampress.dashboard&'>DreamPress section of the DreamHost Panel</a> before we can publish to the domain.</p>,
						confirm: <input className="btn-primary" type="button" value="Refresh" onClick={() => this.refreshThenReset()} />};
					break;
				case 6: //unique IP
					warning = {
						heading: "Warning: " + domain.domain + " has a unique IP address",
						details: <p className="form-warning-details">The domain you've selected is currently using a unique IP address. You will need to remove that unique IP through the <a target='_blank' href='https://panel.dreamhost.com/index.cgi?tree=domain.manage&'>Manage Domains section of the DreamHost Panel</a> before we can publish to the domain.</p>,
						confirm: <input className="btn-primary" type="button" value="Refresh" onClick={() => this.refreshThenReset()} />};
					break;
				case 7: //trial account
					warning = {
						heading: "Warning: Add-on plan required",
						details: <p className="form-warning-details">In order to publish to a custom domain on Remixer, you will need to purchase a Remixer add-on plan or a DreamHost shared hosting plan. Once purchased, your eligible domains will be available for publishing.</p>,
						confirm: <a href="https://panel.dreamhost.com/index.cgi?tree=remixer.dashboard&"><input className="btn-primary" type="submit" value="Upgrade Now!" /></a>};
					break;
				default:
					break;
			}
		}
		return (
			<form className="form-container" onSubmit={e => { e.preventDefault(); }}>
				<ul className="form-control-bar row">
					<li className={this.css({'active': this.props.tab === TAB.EXISTING}, 'small-6 columns')}><a onClick={() => this.props.onTab(TAB.EXISTING)}>Use Existing Domain</a></li>
					<li className={this.css({'active': this.props.tab === TAB.DREAMHOST}, 'small-6 columns')}><a onClick={() => this.props.onTab(TAB.DREAMHOST)}>Use DreamHost Subdomain</a></li>
				</ul>
				<div className={this.css('form-padding', {'hidden': this.props.tab !== TAB.EXISTING})}>
					<section className="form-row clearfix">
						<label className="form-label">Assign an existing domain to this website</label>
						<div className={this.css('form-warning', {'hidden': !this.state.errors.domain})}>
							<a className="form-warning-close" onClick={() => this.reset()}>
								<i className="mdi mdi-close-circle-outline"></i>
							</a>
							<h3 className="form-warning-heading"><i className="mdi mdi-information-outline"></i>{warning.heading}</h3>
							{warning.details}
							<section className={this.css('form-row form-buttons form-warning-confirm', {'hidden': !warning.confirm})}>
								{warning.confirm}
							</section>
						</div>
						<div className={this.css('form-input-controls', {'hidden': this.state.errors.domain})}>
							<select name="domain" value={this.state.model.domain + ""} className="form-input form-select" onChange={this.handleInputChange}>
								<option value="">Select a domain</option>
								<optgroup label="Eligible domains">
									{this.props.availableDomainList.filter((d) => d.isEligible).sortBy((d) => d.domain).map((d) => <option key={d.domain} value={d.domain}>{d.domain}</option>)}
								</optgroup>
							</select>
							<section className="form-buttons">
								<input className="btn-primary" type="button" value="Select" onClick={() => this.props.onExport()} />
							</section>
							<div className="row">
								{_.get(this.props.accountStatus, 'activeAccount') ?
									<div className="small-12 columns">
										<a className="form-input-button" href="https://panel.dreamhost.com/index.cgi?tree=domain.registration" target="_blank">Click to register or transfer a new domain</a>
										<div className="form-tool-tip">
											<i className="mdi icon mdi-help-circle-outline" />
											<span className="form-tool-tip-text" >Domains must be purchased from the DreamHost control panel. Please click the link to continue.</span>
										</div>
									</div>
									:
									<div className="small-12 columns">
										<a className="form-input-button" href="https://panel.dreamhost.com/index.cgi?tree=remixer.dashboard&" target="_blank">Click to Upgrade and unlock custom domain publishing</a>
										<div className="form-tool-tip">
											<i className="mdi icon mdi-help-circle-outline" />
											<span className="form-tool-tip-text" >In order to publish to a custom domain on Remixer, you will need to purchase a Remixer add-on plan or a DreamHost shared hosting plan. Please click the link to continue.</span>
										</div>
									</div>
								}
							</div>
						</div>
					</section>
				</div>
				<div className={this.css('form-padding', {'hidden': this.props.tab !== TAB.DREAMHOST})}>
					<section className="form-row clearfix">
						<label className="form-label">Create a new DreamHosters subdomain</label>
						<div className={this.css('form-input-controls', {'hidden': this.state.errors.domain})}>
							<div className={this.css('form-input-inner-label', {"error": this.props.dreamhostersError})} htmlFor="domain">
								<label className="form-label-inner" htmlFor="domain">.DreamHosters.com</label>
								<input name="dreamhosterDomain" onKeyPress={(e)=>{ if(e.key == 'Enter') this.props.applyDreamHosters()}} value={this.props.dreamhostersDomain.replace(".dreamhosters.com", "")} className="form-input" id="domain" placeholder="Enter test domain you'd like to use here" onChange={(event) => this.props.onHandleDreamhostersChange(event.target.value + '.dreamhosters.com')}/>
							</div>
							<section className="form-buttons">
								<input className="btn-primary" type="button" value="Select" onClick={() => this.props.applyDreamHosters()} />
							</section>
						</div>
					</section>
				</div>
			</form>
		);
	}
}
