'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var PageStore = require('../stores/PageStore');
var ComponentMetadataStore = require('../stores/ComponentMetadataStore');

var PageContextComponent = require('./PageContextComponent.react');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');

var ComponentTypes = require('../constants/ComponentTypes');

/** @module PageContext
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires PageStore
 * @requires ComponentMetadataStore
 * @requires PageContextComponent
 * @requires ComponentActionsCreator
 * @requires ComponentTypes
 */
var PageContext = ComponentFactory.Create(PageStore, ComponentMetadataStore, {
	displayName: "PageContext",
	propTypes: {
		pageId: React.PropTypes.number.isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			page: PageStore.getState(this.props.pageId)
		};
	},

	componentWillReceiveProps: function(props) {
		this.setState({
			page: PageStore.getState(props.pageId)
		});
	},

	/**
	 * @method componentsDidUpdate
	 */
	componentsDidUpdate: function() {
		ComponentActionsCreator.loadPage(this.props.pageId);
		ComponentActionsCreator.loadComponentsMetadata();
	},

	/**
	 * @method _joinMetadata
	 */
	_joinMetadata: function(instance) {
		return {
			instance: instance,
			metadata: ComponentMetadataStore.getComponentMetadata(instance.reference)
		};
	},

	/**
	 * @method render
	 */
	render: function() {
		let  components = null;
		if (!_.isUndefined(this.state.page) && ComponentMetadataStore.filled()) {
			components = _(this.state.page.components)
				.map(this._joinMetadata)
				.filter((component) => { return _.includes(
					[ComponentTypes.content, ComponentTypes.hidden],
					component.metadata.componentType)
				})
				.sortBy("instance.position")
				.map(function (component, index) {
					return (<PageContextComponent key={component.instance.id} componentIndex={index} {...component} />);
				})
				.value();
		}


		return (<div className="page-context">
			<span className="small-heading">Page Layout</span>
			{components && <p className="tiny-heading">Click to edit a section or drag and drop to reorder:</p>}
			{components
				? <div>{components}</div>
				: <div>Loading ...</div>
			}
		</div>);
	}
});

module.exports = PageContext;
