import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

/** @module TrialServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
export default {
	receiveTrialStatus(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_TRIAL_STATUS,
			trial: response.data
		});
	},
	receiveTrialStatusFails() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_TRIAL_STATUS_FAILS
		});
	}
};