'use strict';

var _ = require('lodash');
var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImportTypes = require('../../constants/ImportTypes');

var UserStore = require('../../stores/UserStore');

var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var UserInfoActionsCreator = require('../../actions/UserInfoActionsCreator');
var FacebookActionsCreator = require('../../actions/FacebookActionsCreator');
var ImportProgressStore = require('../../stores/ImportProgressStore');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var ImportHistoryStore = require('../../stores/ImportHistoryStore');

var ContentStore = require('../../stores/ContentStore');
var ContentTypes = require('../../constants/ContentTypes');

/** @module FacebookImport
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ImportTypes
 * @requires UserStore
 * @requires ImportActionsCreator
 * @requires UserInfoActionsCreator
 * @requires FacebookActionsCreator
 * @requires ImportProgressStore
 * @requires ComponentActionsCreator
 * @requires ImportHistoryStore
 * @requires ContentStore
 * @requires ContentTypes
 */
var FacebookImport = ComponentFactory.Create(UserStore, ImportProgressStore, ImportHistoryStore, ContentStore, {
	displayName: "FacebookImport",
	/**
	 * @method getState
	 */
	getState: function () {
		var state = {
			returnTo: this.route().path,
			isUserAuthenticated: !!UserStore.getState('facebook_connected'),
			importProgress: ImportProgressStore.getState(ImportTypes.FACEBOOK),
			ready: UserStore.getMetaState().filled && ImportProgressStore.getMetaState(ImportTypes.FACEBOOK).filled,

			importedFileCount: _(ContentStore.getState())
				.values()
				.flatten()
				.filter((x) => _.isEqual(x.import_type, ImportTypes.FACEBOOK))
				.filter((x) => _.isEqual(x.content_type, ContentTypes.IMAGE))
				.size()

		};

		state.loading = !state.ready || state.importProgress.progress;
		return state;
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		UserInfoActionsCreator.loadUserInfo();
		ComponentActionsCreator.loadImportHistory(ImportTypes.FACEBOOK);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function() {
		this._importAutoStart();
	},

	/**
	 * @method _importAutoStart
	 */
	_importAutoStart: function() {
		if (this.state.ready && !_(this.state.importProgress).values().compact().some()) {
			this._startImport();
		}
	},


	/**
	 * @method _startImport
	 */
	_startImport: function() {
		if(this.state.isUserAuthenticated) {
			ImportActionsCreator.startImport(ImportTypes.FACEBOOK);
		}
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(e) {
		if (!this.state.ready) {
			return;
		}
		if(this.state.isUserAuthenticated) {
			this._startImport();
		} else {
			FacebookActionsCreator.requestFacebookAuthorizationUrl(this.state.returnTo);
		}
	},

	/**
	 * @method render
	 */
	render: function () {
		var css = {
			'facebook': true,
			'active': this.state.isUserAuthenticated
		};

		var iconClassName = "mdi mdi-facebook" + (this.state.loading ? " pulse" : "");

		var wrapperClassName;
		var output;

		if(_.get(this.props, "button", false)){
			output = (<div className="import-header-column import-header-column-button bg-overlay facebook" onClick={this._onClick}>
				<div>
					<div className="show-for-large-up import-label" >
						<p >{this.props.title}</p>
						<h3 >{this.props.tagline}</h3>
					</div>
					<div className="import-icon-holder" >
						<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import Facebook">
							<i className={iconClassName}></i>
						</div>
						<div className="import-icon-bubble-holder" >
							<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{this.state.importedFileCount}</p></div></div>
						</div>
					</div>
				</div>
			</div>);
		} else {
			wrapperClassName = 'media-source';
			output = (<div>
				<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import Facebook">
					<i className={iconClassName}></i>
				</div>
				<div className="import-icon-bubble-holder" >
					<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{this.state.importedFileCount}</p></div></div>
				</div>
			</div>);
		}
		return (<div className={wrapperClassName}>{output}</div>);

	}
});

module.exports = FacebookImport;
