'use strict';

import { map } from 'lodash';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');
var ColorInput = require('./inputs/ColorInput.react');

/** @module ComponentContextColors
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ColorInput
 */
var ComponentContextColors = ComponentFactory.Create({
	displayName: "ComponentContextColors",
	propTypes: {
		component: React.PropTypes.any.isRequired,
		colors: React.PropTypes.any.isRequired
	},

	/**
	 * @method _buildInput
	 */
	_buildInput: function(property) {
		return (<li key={property.name}><ColorInput property={property} component={this.props.component}/></li>);
	},

	/**
	 * @method render
	 */
	render: function() {
		var properties = map(this.props.colors, this._buildInput);
		return <div ref="container" className={this.props.className}>
			<p className="hint">select a color you'd like to change for this component</p>
			<ul className="inputs block-grid small-block-grid-1 medium-block-grid-2 large-block-grid-3 xlarge-block-grid-6">
				{properties}
			</ul>
		</div>;
	}
});

module.exports = ComponentContextColors;
