'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module FlashMessageActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var FlashMessageActionsCreator = {
	/**
	 * @method addFlashMessage
	 */
	addFlashMessage: function(message) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.ADD_FLASH_MESSAGE,
			message: message
		});
	},
	/**
	 * @method removeFlashMessage
	 */
	removeFlashMessage: function(message) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REMOVE_FLASH_MESSAGE,
			message: message
		});
	}
};

module.exports = FlashMessageActionsCreator;
