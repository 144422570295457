'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');
var Markdown = require('../../common/Markdown.react');

/** @module OverlayMarkdown
 * @requires react
 * @requires ComponentFactory
 * @requires Markdown
 */
var OverlayMarkdown = ComponentFactory.Create({
	displayName: "OverlayMarkdown",
	propTypes: {
		content: React.PropTypes.array,
		contentItem: React.PropTypes.object,
		picked: React.PropTypes.array,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func,
		onUpdate: React.PropTypes.func,
		action: React.PropTypes.string
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<Markdown {...this.props} />
		);
	}
});

module.exports = OverlayMarkdown;
