'use strict';

var _ = require('lodash');
var React = require('react');
var ImportProgressStore = require('../../stores/ImportProgressStore');
var ImportHistoryStore = require('../../stores/ImportHistoryStore');
var ComponentFactory = require('../../infrastructure/ComponentFactory');
var UserStore = require('../../stores/UserStore');
var ImportTypes = require('../../constants/ImportTypes');
var ContentStore = require('../../stores/ContentStore');
var ContentTypes = require('../../constants/ContentTypes');
var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var UserInfoActionsCreator = require('../../actions/UserInfoActionsCreator');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var FlickrActionsCreator = require('../../actions/FlickrActionsCreator');

/** @module FlickrImport
 * @requires lodash
 * @requires react
 * @requires ImportProgressStore
 * @requires ImportHistoryStore
 * @requires ComponentFactory
 * @requires UserStore
 * @requires ImportTypes
 * @requires ContentStore
 * @requires ContentTypes
 * @requires ImportActionsCreator
 * @requires UserInfoActionsCreator
 * @requires ComponentActionsCreator
 * @requires FlickrActionsCreator
 */
var FlickrImport = ComponentFactory.Create(UserStore, ImportProgressStore, ImportHistoryStore, ContentStore, {
	displayName: "FlickrImport",
	/**
	 * @method getState
	 */
	getState: function() {
		var state = {
			returnTo: this.route().path,
			isUserAuthenticated: UserStore.getMetaState().filled && !!UserStore.getState('flickr_connected'),
			importProgress: ImportProgressStore.getState(ImportTypes.FLICKR),
			ready: UserStore.getMetaState().filled && ImportProgressStore.getMetaState(ImportTypes.FLICKR).filled,

			importedFileCount: _(ContentStore.getState())
				.values()
				.flatten()
				.filter((x) => _.isEqual(x.import_type, ImportTypes.FLICKR))
				.filter((x) => _.isEqual(x.content_type, ContentTypes.IMAGE))
				.size()
		};

		state.loading = !state.ready || state.importProgress.progress;
		return state;
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function() {
		UserInfoActionsCreator.loadUserInfo();
		ComponentActionsCreator.loadImportHistory(ImportTypes.FLICKR);

		this._importAutoStart();
	},

	/**
	 * @method _startImport
	 */
	_startImport: function() {
		if(this.state.isUserAuthenticated && !this.state.importProgress.progress) {
			ImportActionsCreator.startImport(ImportTypes.FLICKR);
		}
	},

	/**
	 * @method _importAutoStart
	 */
	_importAutoStart: function() {
		if (this.state.ready && !_(this.state.importProgress).values().compact().some()) {
			this._startImport();
		}
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(e) {
		if (!this.state.ready) {
			return;
		}
		if(this.state.isUserAuthenticated) {
			this._startImport();
		} else {
			FlickrActionsCreator.requestFlickrAuthorizationUrl(this.state.returnTo);
		}
	},

	/**
	 * @method _onMouseEnter
	 */
	_onMouseEnter: function() {
		this.setState({mouseOver: true});
	},

	/**
	 * @method _onMouseLeave
	 */
	_onMouseLeave: function() {
		this.setState({mouseOver: false});
	},

	/**
	 * @method render
	 */
	render: function () {

		var css = {
			flickr: true,
			active: this.state.isUserAuthenticated
		};

		var iconClassNameLeft = "mdi mdi-checkbox-blank-circle left" + (this.state.loading ? " pulse" : "");
		var iconClassNameRight = "mdi mdi-checkbox-blank-circle right" + (this.state.loading ? " pulse" : "");

		var wrapperClassName;
		var output;

		if(_.get(this.props, "button", false)){
			output = (<div className="import-header-column import-header-column-button bg-overlay flickr" onClick={this._onClick}>
				<div>
					<div className="show-for-large-up import-label" >
						<p >{this.props.title}</p>
						<h3 >{this.props.tagline}</h3>
					</div>
					<div className="import-icon-holder" >
						<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import Flickr">
							<i className={iconClassNameLeft}></i>
							<i className={iconClassNameRight}></i>
						</div>
						<div className="import-icon-bubble-holder" >
							<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{this.state.importedFileCount}</p></div></div>
						</div>
					</div>
				</div>
			</div>);
		} else {
			wrapperClassName = 'media-source';
			output = (<div>
				<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import Flickr">
					<i className={iconClassNameLeft}></i>
					<i className={iconClassNameRight}></i>
				</div>
				<div className="import-icon-bubble-holder" >
					<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{this.state.importedFileCount}</p></div></div>
				</div>
			</div>);
		}
		return (<div className={wrapperClassName}>{output}</div>);

	}
});

module.exports = FlickrImport;
