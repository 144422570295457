'use strict';

var React = require('react');
var ReactDom = require('react-dom');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');
var LinkIcon = require('./LinkIcon.react');

/** @module LinkItem
 * @requires react-dom
 * @requires ComponentFactory
 * @requires LinkIcon
 * @requires react
 */
var LinkItem = ComponentFactory.Create({
	displayName: "LinkItem",
	propTypes: {
		link: React.PropTypes.object.isRequired,
		selected: React.PropTypes.bool.isRequired,
		onDragStart: React.PropTypes.func.isRequired,
		onDragEnd: React.PropTypes.func.isRequired,
		onSelect: React.PropTypes.func.isRequired
	},

	/**
	 * @method _getMouseY
	 */
	_getMouseY: function(ev) {
		var y = 0;
		if (!ev) ev = window.event;
		if (ev.pageY) 	{
			y = ev.pageY;
		}
		else if (ev.clientX || ev.clientY) 	{
			y = ev.clientY + document.body.scrollTop + document.documentElement.scrollTop;
		}
		return y;
	},

	/**
	 * @method _getRectangle
	 */
	_getRectangle: function() {
		var node = ReactDom.findDOMNode(this.refs.link);
		return node.getBoundingClientRect();
	},

	/**
	 * @method _onDrop
	 */
	_onDrop: function(ev) {
		this.props.onDragEnd({
			y: this._getMouseY(ev),
			link: this.props.link,
			rectangle: this._getRectangle()
		});
	},

	/**
	 * @method _onDragStart
	 */
	_onDragStart: function(ev) {
		this.props.onDragStart({
			link: this.props.link
		});
	},

	/**
	 * @method _onDragOver
	 */
	_onDragOver: function(ev) {
		ev.preventDefault();
	},

	/**
	 * @method _onSelect
	 */
	_onSelect: function() {
		this.props.onSelect(this.props.link);
	},

	/**
	 * @method render
	 */
	render: function() {
		var iconName = (this.props.link.icon) ? this.props.link.icon : 'none';
		var className = 'link-item' + (this.props.selected ? ' selected' : '');

		return (
			<div
				ref="link"
				className={className}
				draggable={true}
				onDragOver={this._onDragOver}
				onDrop={this._onDrop}
				onDragStart={this._onDragStart}
				onClick={this._onSelect}>
				<div className="icon-left"><LinkIcon name={iconName} /></div>
				<div className="label-wrapper"><p>{this.props.link.text}</p></div>
				<div className="drag-wrapper"><i className="mdi mdi-drag-horizontal"></i></div>
				<div className="remove-wrapper"><i className="mdi mdi-playlist-remove"></i></div>
				<div className="trim"></div>
			</div>
		);
	}
});

module.exports = LinkItem;
