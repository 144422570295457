const postcss = require('postcss');
const customProperties = require('postcss-custom-properties');

function CSSRenderer(editingMode) {
	this.render = function(template, context) {
		if (template) {
			return postcss()
				.use(customProperties({variables: context}))
				.process(template)
				.css;
		}
	};
	return this;
}
module.exports = CSSRenderer;


