'use strict';

import { noop, isEqual } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var SelectableGroup = require('./SelectableGroup.react');

/** @module SingleSelectableGroup
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires SelectableGroup
 */
var SingleSelectableGroup = ComponentFactory.Create({
	displayName: "SingleSelectableGroup",
	propTypes: {
		selected: React.PropTypes.any,
		onChange: React.PropTypes.func,
		required: React.PropTypes.bool,
		tag: React.PropTypes.any
	},
	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			onChange: noop
		};
	},
	/**
	 * @method _onChange
	 */
	_onChange: function(selectedItems, changedItem) {
		if (!isEqual(this.props.selected, changedItem)) {
			// if (!changedItem && this.props.required) {
			// 	return;
			// }
			this.props.onChange(changedItem);
		} else if (!this.props.required) {
			this.props.onChange();
		}
	},
	/**
	 * @method render
	 */
	render: function() {
		return (
			<SelectableGroup {...this.props} selected={[this.props.selected]} onChange={this._onChange} unselectable='on'/>
		);
	}
});

module.exports = SingleSelectableGroup;
