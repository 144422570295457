'use strict';

import { get, map } from 'lodash';

var React = require('react');

var ComponentFactory = require('../../infrastructure/ComponentFactory');

var PageDomStateStore = require('../../stores/PageDomStateStore');

var ComponentSystemLayer = require('./ComponentSystemLayer.react');
var InlinePropertyEmulator = require('../InlinePropertyEmulator.react');
var InlineSettingsEmulator = require('../InlineSettingsEmulator.react');

/** @module PageSystemLayer
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires PageDomStateStore
 * @requires ComponentSystemLayer
 * @requires InlinePropertyEmulator
 * @requires InlineSettingsEmulator
 */
var PageSystemLayer = ComponentFactory.Create(PageDomStateStore, {
	displayName: "PageSystemLayer",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		onTextCommand: React.PropTypes.func.isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			domPage: PageDomStateStore.getState(this.props.pageId)
		};
	},

	/**
	 * @method render
	 */
	render: function() {
		return (<div>
			{map(get(this.state.domPage, 'components'), (componentDom) => {
				return <ComponentSystemLayer
					key={componentDom.componentId}
					pageId={this.props.pageId}
					component={componentDom} />;
			})}
			<InlinePropertyEmulator pageId={this.props.pageId} onTextCommand={this.props.onTextCommand} />
			<InlineSettingsEmulator pageId={this.props.pageId} />
		</div>);
	}
});

module.exports = PageSystemLayer;
