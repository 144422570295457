'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var WebAPI = require('../utils/WebAPI');
var RemixerConstants = require('../constants/RemixerConstants');

var PageStore = require('../stores/PageStore');
var ComponentMetadataStore = require('../stores/ComponentMetadataStore');
var ImportHistoryStore = require('../stores/ImportHistoryStore');
var ComponentsPreviewStore = require('../stores/ComponentsPreviewStore');

/** @module ComponentActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 * @requires PageStore
 * @requires ComponentMetadataStore
 * @requires ImportHistoryStore
 * @requires ComponentsPreviewStore
 */
var ComponentActionsCreator = {
	/**
	 * @method openComponentSetting
	 */
	openComponentSetting: function(componentInstance, settingsType) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.OPEN_COMPONENT_SETTINGS,
			componentInstance: componentInstance,
			settingsType: settingsType
		});
	},

	/**
	 * @method openNewComponentSetting
	 */
	openNewComponentSetting: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.OPEN_NEW_COMPONENT_SETTINGS
		});
	},

	/**
	 * @method deactivateProperty
	 */
	deactivateProperty: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.DEACTIVATE_PROPERTY
		});
	},
	/**
	 * @method startImagePicker
	 */
	startImagePicker: function(propertyState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_IMAGE_PICKER,
			propertyState: propertyState
		});
	},
	/**
	 * @method stopImagePicker
	 */
	stopImagePicker: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.STOP_IMAGE_PICKER
		});
	},
	/**
	 * @method startVideoPicker
	 */
	startVideoPicker: function(propertyState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_VIDEO_PICKER,
			propertyState: propertyState
		});
	},
	/**
	 * @method stopVideoPicker
	 */
	stopVideoPicker: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.STOP_VIDEO_PICKER
		});
	},
	/**
	 * @method startAudioPicker
	 */
	startAudioPicker: function(propertyState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_AUDIO_PICKER,
			propertyState: propertyState
		});
	},
	/**
	 * @method stopAudioPicker
	 */
	stopAudioPicker: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.STOP_AUDIO_PICKER
		});
	},
	/**
	 * @method startLinkPicker
	 */
	startLinkPicker: function(propertyState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_LINK_PICKER,
			propertyState: propertyState
		});
	},
	/**
	 * @method stopLinkPicker
	 */
	stopLinkPicker: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.STOP_LINK_PICKER
		});
	},
	/**
	 * @method startColorPicker
	 */
	startColorPicker: function(propertyState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_COLOR_PICKER,
			propertyState: propertyState
		});
	},
	/**
	 * @method stopColorPicker
	 */
	stopColorPicker: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.STOP_COLOR_PICKER
		});
	},
	/**
	 * @method startMapPicker
	 */
	startMapPicker: function(propertyState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_MAP_PICKER,
			propertyState: propertyState
		});
	},
	/**
	 * @method stopMapPicker
	 */
	stopMapPicker: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.STOP_MAP_PICKER
		});
	},
	/**
	 * @method startMarkdownPicker
	 */
	startMarkdownPicker: function(propertyState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_MARKDOWN_PICKER,
			propertyState: propertyState
		});
	},
	/**
	 * @method stopMarkdownPicker
	 */
	stopMarkdownPicker: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.STOP_MARKDOWN_PICKER
		});
	},
	/**
	 * @method updateTextProperty
	 */
	updateTextProperty: function(propertyState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.UPDATE_TEXT_PROPERTY,
			propertyState: propertyState
		});
	},
	/**
	 * @method startTextManipulation
	 */
	startTextManipulation: function(propertyState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_TEXT_MANIPULATION,
			propertyState: propertyState
		});
	},
	/**
	 * @method endTextManipulation
	 */
	endTextManipulation: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.END_TEXT_MANIPULATION
		});
	},
	/**
	 * @method updatePageDomState
	 */
	updatePageDomState: function(pageId, domState) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.UPDATE_PAGE_DOM,
			pageId: pageId,
			domState: domState
		});
	},
	/**
	 * @method inheritSkin
	 */
	inheritSkin: function(pageId, basePageId) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.INHERIT_SKIN,
			pageId: pageId,
			basePageId: basePageId
		},
		WebAPI.inheritSkin, [pageId, basePageId]);
	},
	/**
	 * @method movePageRevision
	 */
	movePageRevision: function(pageId, delta) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.MOVE_PAGE_REVISION,
			pageId: pageId,
			delta: delta
		},
		WebAPI.movePageRevision, [pageId, delta]);
	},
	/**
	 * @method endDraggingComponent
	 */
	endDraggingComponent: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.END_DRAGGING_COMPONENT
		});
	},
	/**
	 * @method startDraggingComponent
	 */
	startDraggingComponent: function(data) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_DRAGGING_COMPONENT,
			data: data
		});
	},
	/**
	 * @method replaceComponentInstance
	 */
	replaceComponentInstance: function(componentInstance, componentReference) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REPLACE_COMPONENT_INSTANCE,
			componentInstance: componentInstance,
			componentReference: componentReference
		},
		WebAPI.replaceComponentInstance, [componentInstance, componentReference]);
	},
	/**
	 * @method createComponentInstance
	 */
	createComponentInstance: function(pageId, componentReference, position, options) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.CREATE_COMPONENT_INSTANCE,
			pageId: pageId,
			componentReference: componentReference,
			position: position
		},
		WebAPI.createComponentInstance, [pageId, componentReference, position], options);
	},
	/**
	 * @method updateComponentInstance
	 */
	updateComponentInstance: function(componentInstance) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.UPDATE_COMPONENT_INSTANCE,
			componentInstance: componentInstance
		},
		WebAPI.updateComponentInstance, [componentInstance]);
	},
	/**
	 * @method updateComponentInstanceContext
	 */
	updateComponentInstanceContext: function(componentInstance) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.UPDATE_COMPONENT_INSTANCE_CONTEXT,
			componentInstance: componentInstance
		},
		WebAPI.updateComponentInstanceContext, [componentInstance], { wait: 500, trailing: true, params: false });
	},
	/**
	 * @method removeComponentInstance
	 */
	removeComponentInstance: function(componentInstance) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REMOVE_COMPONENT_INSTANCE,
			componentInstance: componentInstance
		},
		WebAPI.removeComponentInstance, [componentInstance]);
	},
	/**
	 * @method activeComponent
	 */
	activeComponent: function(component) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.COMPONENT_CONTEXT_REQUEST,
			component: component
		});
	},
	/**
	 * @method resetActiveComponent
	 */
	resetActiveComponent: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RESET_ACTIVE_COMPONENT
		});
	},
	/**
	 * @method loadPage
	 */
	loadPage: function (pageId, force = false) {
		if (!force && !PageStore.needs(pageId)) return;

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_PAGE,
			pageId: pageId
		}, WebAPI.loadPage, [pageId]);
	},
	/**
	 * @method loadSimilarComponents
	 */
	loadSimilarComponents: function(pageId, componentInstanceId, componentCategory, searchText, pIndex, itemsPerPage) {
		if(!ComponentsPreviewStore.needs(
			'similar', pageId, componentInstanceId, componentCategory, searchText, pIndex)) {
			return;
		}

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_SIMILAR_COMPONENTS,
			pageId: pageId,
			componentInstanceId: componentInstanceId,
			componentCategory: componentCategory,
			searchText: searchText,
			pIndex: pIndex
		},
		WebAPI.loadSimilarComponents, [pageId, componentInstanceId, componentCategory, searchText, pIndex, itemsPerPage]);
	},
	/**
	 * @method loadRelevantComponents
	 */
	loadRelevantComponents: function(pageId, componentInstanceId, componentCategory, searchText, pIndex, itemsPerPage) {
		if(!ComponentsPreviewStore.needs(
			'relevant', pageId, componentInstanceId, componentCategory, searchText, pIndex)) {
			return;
		}

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_RELEVANT_COMPONENTS,
			pageId: pageId,
			componentInstanceId: componentInstanceId,
			componentCategory: componentCategory,
			searchText: searchText,
			pIndex: pIndex
		},
		WebAPI.loadRelevantComponents, [pageId, componentInstanceId, componentCategory, searchText, pIndex, itemsPerPage]);
	},
	/**
	 * @method loadComponentsMetadata
	 */
	loadComponentsMetadata: function() {
		if (!ComponentMetadataStore.needs()) return;

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_COMPONENTS_METADATA
		},
		WebAPI.loadComponentsMetadata);
	},
	/**
	 * @method loadImportHistory
	 */
	loadImportHistory: function(importType) {
		if (!ImportHistoryStore.needs(importType)) return;

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_IMPORT_HISTORY,
			importType: importType
		},
		WebAPI.loadImportHistory, [importType]);
	}
};

module.exports = ComponentActionsCreator;
