'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var FlashMessageStore = require('../../../stores/FlashMessageStore');

var FlashMessage = require('./FlashMessage.react');

/** @module FlashMessenger
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires FlashMessageStore
 * @requires FlashMessage
 */
var FlashMessenger = ComponentFactory.Create(FlashMessageStore, {
	displayName: "FlashMessenger",
	/**
	 * @method getState
	 */
	getState: function() {
		return {
			message: _(FlashMessageStore.getState()).values().last()
		};
	},

	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			messageDisappearTimeout: 10000,
			floating: true
		};
	},

	/**
	 * @method shouldComponentUpdate
	 */
	shouldComponentUpdate: function(nextProps, nextState) {
		return this.state.message !== nextState.message
			|| this.props.floating !== nextProps.floating;
	},

	/**
	 * @method render
	 */
	render: function() {
		if (!this.state.message) {
			return null;
		}
		return (
			<ul className={this.css('flash-messanger', { 'floating': this.props.floating })}>
				{[
					<FlashMessage
						key={this.state.message.id}
						message={this.state.message}
						messageDisappearTimeout={_.get(this.state, 'message.messageDisappearTimeout') || this.props.messageDisappearTimeout} />
				]}
			</ul>
		);
	}
});

module.exports = FlashMessenger;

