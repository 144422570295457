'use strict';

import { get, set, debounce, map, isEmpty } from 'lodash';
var RemixerConstants = require('../../constants/RemixerConstants');
var React = require('react');
var ReactDOMServer = require('react-dom/server');
var ComponentFactory = require('../../infrastructure/ComponentFactory');
var ComponentContextStore = require('../../stores/ComponentContextStore');
var ContentStore = require('../../stores/ContentStore');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');
var ContentTypes = require('../../constants/ContentTypes');
var Markdown = require('react-remarkable');
var PropertyTypes = require('../../constants/PropertyTypes');
var Tabs = require('../common/Tabs.react');

var tabsHeight = 80;


/** @module MarkdownInput
 * @requires lodash
 * @requires RemixerConstants
 * @requires react
 * @requires server
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires ContentStore
 * @requires ComponentActionsCreator
 * @requires ContentActionsCreator
 * @requires ContentTypes
 * @requires react-remarkable
 * @requires PropertyTypes
 * @requires Tabs
 */
var MarkdownInput = ComponentFactory.Create(ContentStore, ComponentContextStore, {
	displayName: "MarkdownInput",
	propTypes: {
		propertyName: React.PropTypes.string.isRequired,
		propertyType: React.PropTypes.oneOf([PropertyTypes.markdown, PropertyTypes.arrayOfMarkdown]).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var existingValue = ComponentContextStore.getPropertyValue(this.props.propertyName);
		var state = {
			value: get(this, ['state', 'value']) || existingValue,
			markdown: ContentStore.getState(ContentTypes.MARKDOWN) || {},
			preventNewSave: false,
			activeKey: 'edit'
		};
		return state;
	},

	/**
	 * @method setPageContent
	 */
	setPageContent: function(value) {
		this.timeout = setTimeout(() => {
			var component = ComponentContextStore.getState('active');
			set(component.context, this.props.propertyName, value);
			ComponentActionsCreator.updateComponentInstanceContext(component);
		}, 0);
	},

	/**
	 * @method receiveContent
	 */
	receiveContent: function(action) {
		var entryValue = this.refs.entry.value
		var value = {
			id: action.content.id,
			name: action.content.name,
			markdown: entryValue,
			content: ReactDOMServer.renderToStaticMarkup(this.markdown(entryValue))
		};
		this.setState({
			value: value,
			preventNewSave: false
		});
		this.setPageContent(value);
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		if (!this.debouncedSave) this.debouncedSave = debounce(this.save, 1000);
		if (!this.receiveContentBound) this.receiveContentBound = this.receiveContent.bind(this);
		ContentStore.addRemixerListener(RemixerConstants.RECEIVE_CONTENT_POST, this.receiveContentBound);
		ContentStore.addRemixerListener(RemixerConstants.RECEIVE_CONTENT_PUT, this.receiveContentBound);
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function() {
		clearTimeout(this.timeout);
		ContentStore.removeRemixerListener(RemixerConstants.RECEIVE_CONTENT_POST, this.receiveContentBound);
		ContentStore.removeRemixerListener(RemixerConstants.RECEIVE_CONTENT_PUT, this.receiveContentBound);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		ContentActionsCreator.loadContent(ContentTypes.MARKDOWN);
	},

	/**
	 * @method save
	 */
	save: function() {
		var value = this.state.value;
		var markdown = isEmpty(this.refs.entry) ? value.markdown : this.refs.entry.value
		var payload = {
			name: value.name,
			markdown: markdown,
			content: ReactDOMServer.renderToStaticMarkup(this.markdown())
		};
		if (value.id) {
			ContentActionsCreator.putContent(value.id, ContentTypes.MARKDOWN, payload);
		} else if (!this.state.preventNewSave) {
			ContentActionsCreator.postContent(ContentTypes.MARKDOWN, payload);
		}
	},

	/**
	 * @method nameChange
	 */
	nameChange: function(ev) {
		var value = this.state.value;
		value.name = ev.target.value;

		this.setState({ value: value });
		this.debouncedSave();
	},

	/**
	 * @method sourceChange
	 */
	sourceChange: function() {
		var value = this.state.value;
		value.markdown = this.refs.entry.value;

		this.setState({ value: value });
		this.debouncedSave();
	},

	/**
	 * @method pick
	 */
	pick: function(value) {
		this.setState({ value: value, preventNewSave: false });
		this.refs.tabs.setActive('Edit');
		this.setPageContent(value);
	},

	/**
	 * @method newMarkdown
	 */
	newMarkdown: function() {
		this.setState({
			value: {
				name: '',
				markdown: '# Your title',
				content: ''
			},
			preventNewSave: false
		});
	},

	/**
	 * @method markdown
	 */
	markdown: function (markdown = this.state.value.markdown) {
		return (
			<Markdown
				options={{ html: false, typographer: true, linkify: true }}
				container="div"
				source={markdown} />
		);
	},

	/**
	 * @method editUi
	 */
	editUi: function() {
		if (!this.state.value) {
			this.newMarkdown();
		}
		var textAreaStyle = {
			minHeight: this.props.componentRectangle.size.height - tabsHeight - 130
		};
		return (
			<div className="row">
				<div className="columns small-12">
					<h5>Document Title</h5>
					<input onChange={this.nameChange} ref="name"
						value={this.state.value.name} maxLength="255" type="text" placeholder="Enter Name of Text Document" />
				</div>
				<div className="columns small-12">
					<h5>Markdown</h5>
					<textarea
						style={textAreaStyle}
						ref="entry"
						onChange={this.sourceChange}
						className="markdown-entry entry"
						value={this.state.value.markdown}
					/>
				</div>
			</div>
		);
	},

	viewUi: function() {
		return this.markdown()
	},

	/**
	 * @method pickUi
	 */
	pickUi: function() {
		var scrollableStyle = {
			height: this.props.componentRectangle.size.height - tabsHeight - 60
		};
		var markdown = get(this, ['state', 'markdown'], []);
		var markdownUi;
		if (markdown.length) {
			markdownUi = map(this.state.markdown, (item) => {
				return (
					<a onClick={this.pick.bind(this, item)}>
						{item.name}
					</a>
				);
			});
		} else {
			return (
				<div className="no-results">No results</div>
			);
		}
		return (
			<div className="row markdown-section">
				<div className="columns small-12">
					<div style={scrollableStyle} className="markdown-scrollable">
						{markdownUi}
					</div>
				</div>
			</div>
		);
	},

	/**
	 * @method render
	 */
	render: function() {
		var componentStyle = {
			minHeight: this.props.componentRectangle.size.height - tabsHeight
		};
		var panelStyle = {
			minHeight: this.props.componentRectangle.size.height - tabsHeight
		};
		return (
			<div style={componentStyle} className="markdown-input markdown-page">
				<Tabs ref="tabs">
					<div style={panelStyle} className="edit" key="Edit">
						{this.editUi()}
					</div>
					<div className="view markdown" key="View" style={panelStyle}>
						<div className="markdown-inner">
							{this.viewUi()}
						</div>
					</div>
					<div style={panelStyle} className="pick" key="Pick Existing">
						{this.pickUi()}
					</div>
				</Tabs>
			</div>
		);
	}
});

module.exports = MarkdownInput;
