'use strict';

import { get, partial } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

/** @module SimpleTabPane
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 */
var SimpleTabPane = ComponentFactory.Create({
	displayName: "SimpleTabPane",
	propTypes: {
		activeKey: React.PropTypes.string,
		onSelect: React.PropTypes.func
	},

	/**
	 * @method render
	 */
	render: function () {
		return (
			<ul className="switches">
				{React.Children.map(this.props.children, (component) => {
					var key = get(component, 'key');
					return (
						<li key={key}
							onClick={partial(this.props.onSelect, key)}
							className={this.css("switch", {"active": this.props.activeKey === key})}
						>
							<div>{component}</div>
						</li>
					);
				})}
			</ul>
		);
	}
});

module.exports = SimpleTabPane;
