'use strict';

import { map, set, get, assign, isEqual, includes, cloneDeep, isArray, clone, forEach, find, isUndefined, filter, indexOf } from 'lodash';
var React = require('react');
var he = require('he');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var ComponentContextStore = require('../../../stores/ComponentContextStore');
var PageProjectStore = require('../../../stores/PageProjectStore').default;

var ComponentActionsCreator = require('../../../actions/ComponentActionsCreator');
var PropertyTypes = require('../../../constants/PropertyTypes');
var MdiIcons = require('../../../constants/MdiIcons');
var LinkItem = require('./LinkItem.react');
var LinkIcon = require('./LinkIcon.react');
var ChordAnchorTools = require('../../common/texttools/ChordAnchorTools.react');
var PageLinkSelector = require('../../common/texttools/PageLinkSelector.react');
var UrlUtils = require('../../../utils/UrlUtils');

var MAIL_PREFIX = 'mailto:';

// encode / decode input to prevent XSS attacks
function decodeLinkProps(linkProps) {
	linkProps = clone(linkProps);
	if (linkProps.text) {
		linkProps.text = he.decode(linkProps.text);
	}
	return linkProps;
}

function encodeLinkProps(linkProps) {
	linkProps = clone(linkProps);
	if (linkProps.text) {
		linkProps.text = he.encode(linkProps.text);
	}
	return linkProps;
}


/** @module LinkPicker
 * @requires react
 * @requires he
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires ComponentActionsCreator
 * @requires PropertyTypes
 * @requires MdiIcons
 * @requires LinkItem
 * @requires LinkIcon
 * @requires ChordAnchorTools
 * @requires lodash
 */
var LinkPicker = ComponentFactory.Create(ComponentContextStore, {
	displayName: "LinkPicker",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		propertyName: React.PropTypes.string.isRequired,
		propertyType: React.PropTypes.oneOf([PropertyTypes.link, PropertyTypes.arrayOfLink]).isRequired,
		selectedValuePath: React.PropTypes.string
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var value = ComponentContextStore.getPropertyValue(this.props.propertyName);
		var links, selectedLink;
		if (value) {
			var newValue = cloneDeep(value);
			links = this.props.propertyType === PropertyTypes.link ? [newValue] : newValue;
			forEach(links, function(link, index) {
				link.index = index;
			});

			if (this.props.propertyType === PropertyTypes.arrayOfLink && this.props.selectedValuePath){
				let match = /^(\w+).([0-9]+).*/g.exec(this.props.selectedValuePath);
				if (match && match[1] === this.props.propertyName){
					let selectedIndex = match[2];
					selectedLink = links[selectedIndex];
				}
			}
			if (!selectedLink) 	selectedLink = links[0];
		} else {
			links = [];
		}

		return {
			links: links,
			value: value,
			icons: MdiIcons,
			selectedLink: selectedLink,
			selectedType: selectedLink ? selectedLink.type : 'URL',
			scheme: get(UrlUtils.parseUrl(selectedLink.url), 'protocol', 'http:')
		};
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		if(!isUndefined(prevState) && !isEqual(this.state.value, prevState.value)) {
			var component = ComponentContextStore.getState('active');
			var oldContext = get(component.context, this.props.propertyName)
			oldContext = isArray(oldContext) ? map(oldContext, decodeLinkProps) : decodeLinkProps(oldContext)
			var newContext = this.state.value
			newContext = isArray(newContext) ? map(newContext, decodeLinkProps) : decodeLinkProps(newContext)
			if(!isEqual(oldContext, newContext)) {
				newContext = isArray(newContext) ? map(newContext, encodeLinkProps) : encodeLinkProps(newContext);
				set(component.context, this.props.propertyName, newContext);
				ComponentActionsCreator.updateComponentInstanceContext(component);
			}
		}
	},

	/**
	 * @method _onApply
	 */
	_onApply: function() {
		var newValue = this.props.propertyType === PropertyTypes.link ? this.state.links[0] : this.state.links;
		this.setState({value: newValue});
		setTimeout(function() {
			ComponentActionsCreator.stopLinkPicker();
		}, 250);
	},

	/**
	 * @method _createItem
	 */
	_createItem: function() {
		return { url: "#", text: "", icon: ""};
	},

	/**
	 * @method _onLinkChange
	 */
	_onLinkChange: function(link, changes) {
		var i = indexOf(this.state.links, link);
		if (i > -1)
		{
			var newItem = assign({}, this.state.links[i], changes);
			var links = this.state.links.slice();
			links.splice(i, 1, newItem);
			this.setState({links: links});
		}
	},

	/**
	 * @method _onLinkDelete
	 */
	_onLinkDelete: function(link) {
		var i = indexOf(this.state.links, link);
		if (i > -1) {
			var links = this.state.links.slice();
			if (this.state.links.length === 1) {
				links.splice(i, 1, this._createItem());
			} else {
				links.splice(i, 1);
			}
			this.setState({links: links});
		}
	},

	/**
	 * @method _onLinkAdd
	 */
	_onLinkAdd: function(link) {
		var i = indexOf(this.state.links, link);
		var newItem = this._createItem();
		var links = this.state.links.slice();
		links.splice(i + 1, 0, newItem);
		this.setState({links: links});
	},

	_onCreateMenu: function(link) {
		var websiteId = this.route().params.websiteId;
		var pageProjectList = PageProjectStore.getState().filter(pageProject => pageProject.websiteId == websiteId);
		var links = pageProjectList.map((pageProject, index) => {
			var newItem = this._createItem();
			newItem.url = "/" + pageProject.slug;
			newItem.text = pageProject.title;
			newItem.type = "Page";
			newItem.index = index;
			return newItem;
		});
		this.setState({links: links.toArray(), selectedLink: links.first(), selectedType: "Page"});
	},

	/**
	 * @method _onIconSearch
	 */
	_onIconSearch: function(ev) {
		var val = ev.target.value.toLowerCase();
		if (!val || val.trim() === '') {
			this.setState({
				icons: MdiIcons
			});
			return;
		}
		this.setState({ icons: filter(MdiIcons, function(icon) {
			return includes(icon, val);
		})});
	},

	/**
	 * @method _onDragStart
	 */
	_onDragStart: function(dragStartInfo) {
		this.setState({ dragStartInfo: dragStartInfo });
	},

	/**
	 * @method _onDragEnd
	 */
	_onDragEnd: function(dragEndInfo) {
		var start = this.state.dragStartInfo;
		var end = dragEndInfo;

		if (!start || !end) return;
		if (start.link.index === end.link.index) return;
		this.setState({ dragStartInfo: undefined });

		var topHalf = end.rectangle.top + end.rectangle.height / 2;
		var draggedAbove = (end.y < topHalf);
		var links = cloneDeep(this.state.links);
		var dragIndex, dropIndex;

		forEach(links, function(link, i) {
			if (link.index === start.link.index) dragIndex = i;
			else if (link.index === end.link.index) dropIndex = i - 1;
		});

		var dragLink = links[dragIndex];
		links.splice(dragIndex, 1);

		if (draggedAbove) {
			links.splice(dropIndex, 0, dragLink);
		} else {
			links.splice(dropIndex + 1, 0, dragLink)
		}

		this.setState({ links: links });
	},

	/**
	 * @method _onLinkSelect
	 */
	_onLinkSelect: function(link) {
		this.setState({ selectedLink: link, selectedType: link.type, scheme: get(UrlUtils.parseUrl(link.url), 'protocol', 'http:') });
	},

	/**
	 * @method _changeLink
	 */
	_changeLink: function(newProperties) {
		let selectedLink, links;
		if (this.state.selectedLink) {
			links = cloneDeep(this.state.links);
			selectedLink = find(links, (l) => l.index === this.state.selectedLink.index);
			newProperties.url = UrlUtils.removeUriScheme(isUndefined(newProperties.url) ? get(selectedLink, 'url', '') : newProperties.url);
			switch (newProperties.type || selectedLink.type || 'URL') {
				case 'URL':
					if(newProperties.url && !newProperties.url.match(new RegExp('^#'))) {
						newProperties.url = UrlUtils.addUriScheme(newProperties.url, this.state.scheme);
					}
					break;
				case 'Email':
					newProperties.url = UrlUtils.addMailtoScheme(newProperties.url);
					break;
				default:
					break;
			}
			assign(selectedLink, newProperties);
			this.setState({
				links: links,
				selectedLink: selectedLink,
				selectedType: selectedLink.type
			});
		}
	},

	/**
	 * @method _onLabelChange
	 */
	_onLabelChange: function(ev) {
		this._changeLink({ text: ev.target.value });
	},

	/**
	 * @method _onTypeChange
	 */
	_onTypeChange: function(ev) {
		if (!this.state.selectedLink) return;
		var newType = ev.target.options[ev.target.selectedIndex].text;
		if(newType === 'URL') {
			this.setState({ scheme: 'http:' }, () => this._changeLink({ type: newType }));
		} else {
			this._changeLink({ type: newType });
		}
	},

	/**
	 * @method _onTypeValueChange
	 */
	_onTypeValueChange: function(ev) {
		if (!this.state.selectedLink) return;
		this._changeLink({ url: ev.target.value });
	},

	/**
	 * @method _onAnchorChange
	 */
	_onAnchorChange: function(ev) {
		if (!this.state.selectedLink) return;
		this._changeLink({ url: "#" + ev.key });
	},

	_onPageLinkChange: function(ev) {
		if (!this.state.selectedLink) return;
		this._changeLink({ url: ev.target.value });
	},
	/**
	 * @method _onIconSelect
	 */
	_onIconSelect: function(icon) {
		if (icon === 'none') icon = undefined;
		this._changeLink({ icon: icon });
	},

	/**
	 * @method _onAddLink
	 */
	_onAddLink: function() {
		var links = cloneDeep(this.state.links);
		links.push({
			text: '[Enter Link Name]',
			type: 'URL'
		});
		forEach(links, function(link, index) {
			link.index = index;
		});
		this.setState({
			selectedLink: links[links.length - 1],
			links: links,
			selectedType: 'URL'
		});
	},

	/**
	 * @method _onRemoveLink
	 */
	_onRemoveLink: function() {
		if (!this.state.selectedLink) return;
		var links = cloneDeep(this.state.links);
		var selectedLink;
		for (var i = 0; i < links.length; i++) {
			if (links[i].index === this.state.selectedLink.index) {
				links.splice(i, 1);
			}
		}
		if (links.length) {
			selectedLink = links[0];
		}
		this.setState({
			selectedLink: selectedLink,
			links: links
		});
	},

	/**
	 * @method _changeUrlScheme
	 */
	_changeUrlScheme: function() {
		if(this.state.selectedType !== "URL" && !isUndefined(this.state.selectedType)) return
		if(this.state.scheme === "http:") {
			var scheme = "https:"
		}
		else {
			scheme = "http:"
		}
		var links = cloneDeep(this.state.links);
		var selectedLink = find(links, (l) => l.index === this.state.selectedLink.index);
		var url = UrlUtils.removeUriScheme(get(selectedLink, 'url'))
		if(url && !url.match(new RegExp('^#'))) {
			url = UrlUtils.addUriScheme(url, scheme);
		}
		assign(selectedLink, {url: url, type: "URL"});
		this.setState({
			links: links,
			scheme: scheme,
			selectedLink: selectedLink
		});
	},

	/**
	 * @method render
	 */
	render: function() {
		var that = this;
		var types = [
			{ name: 'URL', label: 'Link', placeholder: 'www.somesite.com', prefix: (get(this.state, 'scheme', '') + '//') },
			{ name: 'Anchor', label: 'Target', placeholder: '#link', prefix: '#' },
			{ name: 'Email', label: 'Email', placeholder: 'john@smith.com', prefix: MAIL_PREFIX },
			{ name: 'Page', label: 'Target', placeholder: '/', prefix: '' }
		];
		var selectedType = find(types, (t) => t.name === that.state.selectedType) || types[0];
		var selectedLink = this.state.selectedLink ? decodeLinkProps(this.state.selectedLink) : this.state.selectedLink;
		let linkEditor;
		switch (selectedType.name) {
			case 'Anchor':
				linkEditor = <ChordAnchorTools
					pageId={this.props.pageId}
					onChange={this._onAnchorChange}
					pulldownOnly={true}
					key={get(selectedLink, 'url', '').replace(selectedType.prefix, '')}
					href={get(selectedLink, 'url', '')}/>;
				break;
			case 'Page':
				linkEditor = <PageLinkSelector
					pageId={this.props.pageId}
					onChange={this._onPageLinkChange}
					href={get(selectedLink, 'url', '')}/>;
				break;
			default:
				linkEditor = <div className="url-input">
					<span className="url-input-prefix" onClick={this._changeUrlScheme}>{selectedType.prefix}</span>
					<input
						type="text"
						placeholder={selectedType.placeholder}
						value={get(selectedLink, 'url', '').replace(selectedType.prefix, '')}
						onChange={this._onTypeValueChange}
						key={selectedType.name}/>
				</div>
				break;
		}
		return (
			<div className="link-picker">
				<span className="link-picker-title">Links &amp; Navigation</span>
				<div className="row">
					<div className="large-6 columns">
						{this.props.propertyType === PropertyTypes.arrayOfLink && (
							<div ref="addButton" onClick={this._onCreateMenu} className="add-new-wrapper">
								<div className="add-new"><i className="mdi mdi-playlist-play"></i><p>Generate Site Menu</p></div>
							</div>)}
						{this.props.propertyType === PropertyTypes.arrayOfLink && (
							<div ref="addButton" onClick={this._onAddLink} className="add-new-wrapper">
								<div className="add-new"><i className="mdi mdi-playlist-plus"></i><p>Add New Item</p></div>
							</div>)}
						<div className="link-items">
							{map(this.state.links,
								(link, index) => {
									link = decodeLinkProps(link);
									var selected = false;
									if (selectedLink && selectedLink.index === link.index) {
										selected = true;
									}
									return (
										<LinkItem key={index}
											link={link}
											selected={selected}
											onSelect={this._onLinkSelect}
											onDragStart={this._onDragStart}
											onDragEnd={this._onDragEnd}
											onClick={this._onSelect}
											onChange={this._onLinkChange} onDelete={this._onLinkDelete} onAdd={this._onLinkAdd} />
									);
								})
							}
						</div>
					</div>
					<div className="large-6 columns">
						<div className="clearfix">
							{this.props.propertyType === PropertyTypes.arrayOfLink && (
								<div ref="removeButton" className="button small radius alert right" onClick={this._onRemoveLink}>Remove</div>
							)}
							<div ref="applyButton" className="button small radius success right" onClick={this._onApply}>Apply</div>
						</div>
						<div className="row">
							<div className="large-2 columns">
								Label:
							</div>
							<div className="large-10 columns">
								<input
									ref="labelInput"
									type="text"
									placeholder="My Link"
									value={get(selectedLink, 'text', '')}
									onChange={this._onLabelChange} />
							</div>
						</div>
						<div className="row">
							<div className="large-2 columns">
								Type:
							</div>
							<div className="large-10 columns">
								<select onChange={this._onTypeChange} value={selectedType.name}>
									{map(types, function(theType, index) {
										return (<option key={index} value={theType.name}>{theType.name}</option>);
									})}
								</select>
							</div>
						</div>
						<div className="row">
							<div className="large-2 columns">
								{selectedType.label}:
							</div>
							<div className="large-10 columns">
								{linkEditor}
							</div>
						</div>
						<div className="row">
							<div className="large-2 columns">
								Icon:
							</div>
							<div className="large-10 columns">
								<input type="text" placeholder="search..." onChange={this._onIconSearch} />
							</div>
						</div>
						<div className="row">
							<div className="large-12 columns">
								<div className="icon-list">
									<LinkIcon onSelect={that._onIconSelect} key={-1} name="none" />
									{map(this.state.icons, function(icon, index) {
										return (<LinkIcon onSelect={that._onIconSelect} key={index} name={icon} />);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
});

module.exports = LinkPicker;
