'use strict';

var _ = require('lodash');
var $ = require('jquery');
var React = require('react');

var RERENDERING_EVENTS = 'load resize scroll';
var INITIAL_RENDERED_ITEMS_COUNT = 50;
var RERENDERING_MAX_DELAY_MS = 1000;
var PRELOADING_AREA_WINDOWSIZE_FACTOR = 1.0;

var mapChildren = React.Children.map;

/** @module InfiniteFlexbox
 * @requires lodash
 * @requires jquery
 * @requires react
 */
var InfiniteFlexbox = React.createClass( {
	propTypes: {
		itemHeight: React.PropTypes.number,
		itemWidth: React.PropTypes.number,
		children: React.PropTypes.node
	},

	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function(){
		return {
			itemHeight: 200,
			itemWidth: 300
		};
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function () {
		var items = mapChildren(this.props.children, function (child){
			return  {visible: false};
		});
		return {items: items, renderedCount: Math.min(items.length, INITIAL_RENDERED_ITEMS_COUNT)};
	},

	/**
	 * @method componentWillReceiveProps
	 */
	componentWillReceiveProps: function(nextProps) {
		var items = mapChildren(nextProps.children, function (child, index){
			var visible = this.state.items && _.get(this.state.items[index], 'visible', false);
			return  {visible: visible};
		}, this);

		var renderedCount = Math.min(Math.max(this.state.renderedCount, INITIAL_RENDERED_ITEMS_COUNT), items.length);
		this.setState({items: items, renderedCount: renderedCount});
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function(){
		this._handlerDebounced =_.debounce(
			this._handler, 300,	{maxWait: RERENDERING_MAX_DELAY_MS, leading: false,  trailing: true });
		$(window).on(RERENDERING_EVENTS, this._handlerDebounced);
		this._handlerDebounced();
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function(){
		$(window).off(RERENDERING_EVENTS, this._handlerDebounced);
		this._handlerDebounced.cancel();
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		this._handlerDebounced();
	},

	/**
	 * @method render
	 */
	render: function() {
		var renderedCount = this.state.renderedCount;

		var defaultItemStyle = {
			height: this.props.itemHeight,
			width: this.props.itemWidth
		};
		return (
			<ul className="infinite-flexbox">
				{
					_(this.props.children)
						.thru(React.Children.toArray)
						.take(renderedCount)
						.map((child, index) => {
							var item = this.state.items[index];
							var className = 'infinite-flexbox-wrap-item '+ (item.visible ? 'visible' : 'hidden');
							var style = _.defaults({
								height: child.props.itemHeight,
								width: child.props.itemWidth
							}, defaultItemStyle);
							return (
								<li key={child.props.itemId} ref={refName(index)} className={className} style={style}>
									{item.visible && child}
								</li>
							)
						}).value()
				}
			</ul>
		);
	},

	/**
	 * @method _handler
	 */
	_handler: function() {
		/*
		var performance = (performance || Date);
		var timeStart = performance.now();
		*/
		var windowHeight = $(window).height();
		var windowWidth = $(window).width();

		var lastVisibleIndex = -1;
		var visibleCount = 0;
		var visibleChanged = false;
		var items = _.map(this.state.items, (item, index) => {
			var visible = false;
			if (index < this.state.renderedCount) {
				var element = this.refs[refName(index)];
				visible = isElementVisible(element, windowHeight, windowWidth);
			}
			visibleChanged = visibleChanged || (visible != item.visible);
			if (visible) {
				lastVisibleIndex = index;
				visibleCount++;
			}
			return  {visible: visible};
		});

		var lastVisibleCount = lastVisibleIndex + 1;
		var renderedAfterLastVisibleCount = this.state.renderedCount === lastVisibleCount ? //is user on the end of the page?
			(visibleCount * 2): visibleCount;
		var renderedCount = lastVisibleCount + renderedAfterLastVisibleCount;
		renderedCount = Math.max(this.state.renderedCount, renderedCount);
		renderedCount = Math.min(items.length, renderedCount);

		/*
		var handleTime = Math.round((performance.now() - timeStart));
		console.log("InfiniteFlexbox, "+ visibleCount+" / "+ renderedCount +" / "+ items.length+" items. Handle time "+handleTime+" ms.")
		*/
		if (visibleChanged || (renderedCount != this.state.renderedCount)) {
			this.setState({items: items, renderedCount: renderedCount});
		}
	}
});

function refName(index){
	return 'i' + index;
}

function isElementVisible(element, windowHeight, windowWidth) {
	if(!element) return false;
	var rect = element.getBoundingClientRect();
	var preloadingSpaceVertical = PRELOADING_AREA_WINDOWSIZE_FACTOR * windowHeight;
	var preloadingSpaceHorizontal = PRELOADING_AREA_WINDOWSIZE_FACTOR * windowWidth;
	return (
		rect.top >= -preloadingSpaceVertical &&
		rect.left >= -preloadingSpaceHorizontal &&
		rect.bottom <= (preloadingSpaceVertical + windowHeight) &&
		rect.right <= (preloadingSpaceHorizontal + windowWidth)
	);
}

module.exports = InfiniteFlexbox;
