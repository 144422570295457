'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var DropDownSingleSelectableGroup = require('./simple/DropDownSingleSelectableGroup.react');
var SelectableItem = require('./simple/SelectableItem.react');

var ContentStore = require('../../stores/ContentStore');

/** @module TagsDropDown
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires DropDownSingleSelectableGroup
 * @requires SelectableItem
 * @requires ContentStore
 */
var TagsDropDown = ComponentFactory.Create(ContentStore, {
	displayName: "TagsDropDown",
	propTypes: {
		tags: React.PropTypes.array,
		selected: React.PropTypes.object,
		onChange: React.PropTypes.func,
		onCreate: React.PropTypes.func
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			content: _(ContentStore.getState()).values().flatten().value()
		};
	},

	/**
	 * @method _getTotalContent
	 */
	_getTotalContent: function(tag) {
		return _(this.state.content)
			.filter(function(contentItem, content) {
				return _.some(contentItem.relative_content_items, {relative_content_id: tag.id});
			})
			.size();
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<DropDownSingleSelectableGroup selected={this.props.selected} onChange={this.props.onChange} required={true} hideSelected={!this.props.selected} className="left-border left">
				{!this.props.selected ?
					<SelectableItem value={null}>
						<span className={this.css("mdi", "mdi-tag-multiple")} /> Select Tag
					</SelectableItem>
					:
					null
				}
				{_.map(this.props.tags, (tag) => {
					return (<SelectableItem key={tag.id} value={tag}>
						<span className={this.css("mdi", "mdi-tag")} /> {this._(tag.name)} ({this._getTotalContent(tag)})
					</SelectableItem>);
				})}
				<SelectableItem onChange={this.props.onCreate}>
					<em><span className={this.css("mdi", "mdi-plus")} /> create new tag</em>
				</SelectableItem>
			</DropDownSingleSelectableGroup>
		);
	}
});

module.exports = TagsDropDown;
