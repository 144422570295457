'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');
var ContentTypes = require('../constants/ContentTypes');

import { pick, forEach, isArray, isString, findIndex, cloneDeep, some, assign, find } from 'lodash';

/** @module ContentStore
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires ContentTypes
 * @requires lodash
 */
var ContentStore = StoreFactory.Create({
	/**
	 * @method getContentFor
	 */
	getContentFor: function(contentType) {
		return pick(this.getState(), contentType);
	},

	/**
	 * @method _setFailed
	 */
	_setFailed: function (action) {
		this._failed(action.contentType);
	},

	/**
	 * @method _updateOrSetContent
	 */
	_updateOrSetContent: function(action) {
		if(!some(action.content)) return;

		var state = cloneDeep(this._get(action.contentType) || []);
		var content = isArray(action.content) ? action.content : [action.content]
		forEach(content, (contentItem) => {
			var unit = find(state, (item) => item.id === contentItem.id);
			if (unit) {
				assign(unit, contentItem);
			} else {
				state.push(contentItem);
			}
		});

		this._set(action.contentType, state);
	},

	/**
	 * @method _setContent
	 */
	_setContent: function (action) {
		//TODO: forEach need until we will figure out schema for each content type
		forEach(action.content, function(item){
			if(isString(item.content)) {
				try {
					item.content = JSON.parse(item.content);
				} catch(ex) {
					// noop
				}
			}
		});
		this._set(action.contentType, action.content);
	},

	/**
	 * @method _setFilling
	 */
	_setFilling: function(action) {
		this._filling(action.contentType);
	},

	/**
	 * @method _resetContent
	 */
	_resetContent: function(action) {
		if (action.contentId) {
			var idx = findIndex(this._get(action.contentType), (i) => i.id === action.contentId);
			this._reset(action.contentType, idx);
		} else {
			this._reset(action.contentType);
		}
	},

	/**
	 * @method _deleteContent
	 */
	_deleteContent: function(action) {
		var idx = findIndex(this._get(action.contentType), (i) => i.id === action.contentId);
		if (idx !== -1) this._remove(action.contentType, idx);
	},

	/**
	 * @method _updateContentTags
	 */
	_updateContentTags: function(action) {
		this._reset(action.contentType);
		this._reset(ContentTypes.TAG);
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function () {
		var handlers = {};

		handlers[RemixerConstants.LOAD_CONTENT] = this._setFilling;
		handlers[RemixerConstants.POST_CONTENT] = this._setFilling;
		handlers[RemixerConstants.PUT_CONTENT] = this._setFilling;

		handlers[RemixerConstants.DELETE_CONTENT] = this._deleteContent;
		handlers[RemixerConstants.RECEIVE_CONTENT_DELETE] = this._resetContent;

		handlers[RemixerConstants.RECEIVE_CONTENT_UPDATE] = this._updateOrSetContent;
		handlers[RemixerConstants.RECEIVE_CONTENT_PUT] = this._updateOrSetContent;
		handlers[RemixerConstants.RECEIVE_CONTENT_PUT_FAILS] = this._setFailed;
		handlers[RemixerConstants.RECEIVE_CONTENT_POST] = this._resetContent;
		handlers[RemixerConstants.RECEIVE_CONTENT_POST_FAILS] = this._setFailed;

		handlers[RemixerConstants.RECEIVE_CONTENT] = this._setContent;
		handlers[RemixerConstants.RECEIVE_CONTENT_FAILS] = this._setFailed;
		handlers[RemixerConstants.RECEIVE_IMPORT_FINISHED] = this._resetContent;

		handlers[RemixerConstants.RECEIVE_UPDATE_CONTENT_TAGS] = this._updateContentTags;
		handlers[RemixerConstants.RECEIVE_UPDATE_CONTENT_TAGS_FAILS] = this._setFailed;

		return handlers;
	},

	// allows UI to add/remove event listeners for events listed here only
	/**
	 * @method _getRemixerEvents
	 */
	_getRemixerEvents: function() {
		return [
			RemixerConstants.RECEIVE_CONTENT_PUT,
			RemixerConstants.RECEIVE_CONTENT_POST
		];
	}
});

module.exports = ContentStore;
