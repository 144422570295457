'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var RouteStore = require('../stores/RouteStore');

/** @module UserInfoServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var UserInfoServerActionsCreator = {
	/**
	 * @method receiveUserInfo
	 */
	receiveUserInfo: function(result) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_USER_INFO,
			userInfo: result.data
		});
	},
	/**
	 * @method receiveUserInfoWithRedirect
	 */
	receiveUserInfoWithRedirect: function (redirect, website, result, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_USER_INFO,
			userInfo: result.data
		});
		if (redirect === 'websites') {
			RouteStore.getState('route')
				.transitionTo('websites');
		}
		if (redirect === 'loged_in_editor') {
			RouteStore.getState('route')
				.transitionTo('layout', {
					websiteId: website.id || response.data.id,
					pageProjectId: website.main_page_project_id || response.data.main_page_project_id
				});
		}
		// if (redirect === 'wordpressExportSettings') {
		// 	RouteStore.getState('route')
		// 		.transitionTo(
		// 			'wordpressExportSettings',
		// 			{
		// 				websiteId: website.id
		// 			})
		// }
		if (redirect === 'layout') {
			RouteStore.getState('route')
				.transitionTo('layout',
					{
						websiteId: website.id,
						pageProjectId: website.main_page_project_id
					});
		}
	},
	/**
	 * @method receiveUserInfoFail
	 */
	receiveUserInfoFail: function(error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_USER_INFO_FAIL,
			message: "could not get userinfo"
		});
	}
};

module.exports = UserInfoServerActionsCreator;
