'use strict';

import { isArray, get, every, map, first, keyBy, find } from 'lodash';

var React = require('react');

var Switcher = require('./Switcher.react');

/** @module Tabs
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires Switcher
 */
var Tabs = React.createClass({
	displayName: "Tabs",
	propTypes: {
		onChange: React.PropTypes.func,
		titleComponent: React.PropTypes.object,
		activeKey: React.PropTypes.string,
		children: React.PropTypes.any,
		title: React.PropTypes.object
	},

	/**
	 * @method _childrenArr
	 */
	_childrenArr: function(){
		return isArray(this.props.children) ? this.props.children : [this.props.children];
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return {
			activeKey: this.props.activeKey
		};
	},

	componentWillReceiveProps: function(nextProps) {
		if (nextProps.activeKey != this.props.activeKey) {
			this.setState({activeKey: nextProps.activeKey});
		}
	},

	/**
	 * @method _validate
	 */
	_validate: function() {
		if(!every(map(this._childrenArr(), 'key'))) {
			throw "not all passed tabs has key";
		}
	},

	/**
	 * @method _createTabs
	 */
	_createTabs: function() {
		return map(this._childrenArr(), (child) => {
			return (<span key={child.key} className='value' unselectable='on'>{child.props.titleComponent || child.key}</span>);
		});
	},

	/**
	 * @method _createTitle
	 */
	_createTitle: function() {
		if(!get(this.props, 'title'))	return
		else {
			if (get(this.props, 'title.headline')) {
				var headline = <p className="headline">{this.props.title.headline}</p>
			}
			if (get(this.props, 'title.text')) {
				var text = <p className="text">{this.props.title.text}</p>
			}
		}
		return <div className="tabs-heading">
			{headline}{text}
		</div>
	},

	/**
	 * @method _onChange
	 */
	_onChange: function(component) {
		this.setState({ activeKey: component.key });
		if (this.props.onChange) this.props.onChange(component.key);
	},
	setActive: function(key) {
		if(key === this.state.activeKey) return;
		const childrenArr = this._childrenArr();
		const component = find(childrenArr, (child) => child.key === key)
		this._onChange(component)
	},

	/**
	 * @method render
	 */
	render: function () {
		this._validate();
		let tabs = this._createTabs();
		let childrenArr = this._childrenArr();
		let activeKey = this.state && this.state.activeKey || this.props.activeKey || get(first(childrenArr), 'key');
		let tabsContent = keyBy(childrenArr, 'key');
		let title = this._createTitle();
		return (<div>
			<div className="tabs">
				{title}
				<Switcher ref="switcher" active={find(tabs, {key: activeKey})} onChange={this._onChange}>
					{tabs}
				</Switcher>
			</div>
			<div className="tabs-content" unselectable='on'>
				{tabsContent[activeKey]}
			</div>
		</div>);
	}
});

module.exports = Tabs;
