'use strict';

import { constant, isObject, isString, get } from 'lodash';
var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var ReactTimerMixin = require('react-timer-mixin');

var FlashMessageActionsCreator = require('../../../actions/FlashMessageActionsCreator');

/** @module FlashMessage
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires react-timer-mixin
 * @requires FlashMessageActionsCreator
 */
var FlashMessage = ComponentFactory.Create({
	displayName: "FlashMessage",
	mixins: [ReactTimerMixin],

	propTypes: {
		messageDisappearTimeout: React.PropTypes.number,
		message: React.PropTypes.shape({
			text: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.object]),
			level: React.PropTypes.oneOf(['error', 'success', 'info'])
		}).isRequired
	},

	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			messageDisappearTimeout: 15000
		};
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		if(this.props.messageDisappearTimeout >= 0) {
			this.setTimeout(function() {
				FlashMessageActionsCreator.removeFlashMessage(this.props.message);
			}.bind(this), this.props.messageDisappearTimeout);
		}
	},

	shouldComponentUpdate: constant(false),

	/**
	 * @method _onClick
	 */
	_onClick: function() {
		FlashMessageActionsCreator.removeFlashMessage(this.props.message);
	},

	/**
	 * @method getErrorText
	 */
	getErrorText: function(errText) {
		if (isObject(errText)) {
			return get(errText, 'error_message') || get(errText, 'message')
		} else if(isString(errText)) {
			return errText
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		var message = this.props.message;
		var levelMessage, levelClassName, levelIconClassName;
		switch(message.level) {
			case 'success':
				levelMessage = 'Success.';
				levelClassName = 'success';
				levelIconClassName = 'mdi mdi-checkbox-marked-circle-outline';
				break;
			case 'error':
				levelMessage = 'Error!';
				levelClassName = 'error';
				levelIconClassName = 'mdi mdi-comment-alert-outline';
				break;
			case 'info':
			default:
				levelMessage = 'Info.';
				levelClassName = 'info';
				levelIconClassName = 'mdi mdi-information-outline';
				break;
		}
		return (
			<li className={levelClassName}>
				<div className="flash-messanger-container">
					<div className="flash-messanger-icon"><i className={levelIconClassName}></i></div>
					<div className="flash-messanger-content">
						<strong>{levelMessage}</strong> {this.getErrorText(message.text)}
					</div>
				</div>
				<a className="flash-messanger-close" onClick={this._onClick}>
					<i className="mdi mdi-close-circle-outline"></i>
				</a>
			</li>
		);
	}
});

module.exports = FlashMessage;
