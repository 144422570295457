'use strict';

var _ = require('lodash');

var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImportTypes = require('../../constants/ImportTypes');
var ContentTypes = require('../../constants/ContentTypes');

var UserStore = require('../../stores/UserStore');
var ImportProgressStore = require('../../stores/ImportProgressStore');
var ImportHistoryStore = require('../../stores/ImportHistoryStore');
var ContentStore = require('../../stores/ContentStore');

var UserInfoActionsCreator = require('../../actions/UserInfoActionsCreator');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

var StickyPopover = require('../common/StickyPopover.react');
var YoutubeSearch = require('../common/YoutubeSearch.react').default;


var YoutubeSearchToolBarItem = ComponentFactory.Create(UserStore, ImportProgressStore, ImportHistoryStore, ContentStore, {
	displayName: "YoutubeSearchToolBarItem",
	/**
	 * @method getState
	 */
	getState: function () {
		return {
			loading: !(UserStore.getMetaState().filled && ImportProgressStore.getMetaState(ImportTypes.YOUTUBE).filled) || ImportProgressStore.getState(ImportTypes.YOUTUBE).progress,
			importedFileCount: _(ContentStore.getState())
				.values()
				.flatten()
				.filter((x) => _.isEqual(x.import_type, ImportTypes.YOUTUBE))
				.filter((x) => _.isEqual(x.content_type, ContentTypes.YOUTUBE))
				.size()
		};
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		UserInfoActionsCreator.loadUserInfo();
		ComponentActionsCreator.loadImportHistory(ImportTypes.YOUTUBE);
	},

	_openPopover: function() {
		this.setState({element: ReactDOM.findDOMNode(this)});
	},

	_closePopover: function() {
		this.setState({element: null});
	},

	/**
	 * @method render
	 */
	render: function () {
		var css = {
			'youtube': true,
			'active': this.state.importedFileCount
		};

		return (
			<div className={this.css("import-header-column import-header-column-button bg-overlay ", css)}>
				<div className="button-inner" onClick={this._openPopover}>
					<div className="show-for-large-up import-youtube-label" >
						<p>Search YouTube</p>
						<h3>find that perfect clip</h3>
					</div>
					<div className="import-icon-holder" title="Search YouTube">
						<div className={this.css("circle-icon", css)} title="Search YouTube">
							<i className={this.css("mdi mdi-youtube-play", {pulse: this.state.loading})}></i>
						</div>
						<div className="import-icon-bubble-holder" >
							<div className={this.css("bubbleNumber", css)}>
								<div className="bubbleLeft"></div>
								<div className="bubbleRight"></div>
								<div className="bubbleText">
									<p>{this.state.importedFileCount}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					this.state.element ?
						<StickyPopover
							className="closable-popup import-popup youtube-search"
							onClose={this._closePopover}
							target={this.state.element}>
							<YoutubeSearch />
						</StickyPopover>
						:
						null
				}
			</div>
		);
	}
});

module.exports = YoutubeSearchToolBarItem;
