'use strict';

import { values, noop, keyBy, cloneDeep } from 'lodash';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module ThemeStore
 * @requires lodash
 * @requires StoreFactory
 * @requires RemixerConstants
 */
var ThemeStore = StoreFactory.Create({

	/**
	 * @method getOrderedThemes
	 * Assigns a specified order to the themes by their name, if the theme name isn't specified
	 * it will go at the bottom in any order
	 */
	getOrderedThemes: function() {
		return values(this.getState()).sort(function(a, b){
			var order = ['Small Business', 'Professional', 'Community', 'Traveler',
				'Event', 'Capture', 'Bistro', 'Startup', 'The Presenter',
				'Storyteller', 'Creative', 'Sound', 'Documentation'].reverse();
			return order.indexOf(b.name) - order.indexOf(a.name);
		});
	},

	/**
	 * @method getThemes
	 */
	getThemes: function() {
		return values(this.getState());
	},

	/**
	 * @method getTheme
	 */
	getTheme: function(themeId) {
		return this.getState(themeId);
	},

	/**
	 * @method _setThemes
	 */
	_setThemes: function(action) {
		this._set(keyBy(action.themes, 'themeId'));
	},

	/**
	 * @method _setThemesLoading
	 */
	_setThemesLoading: function() {
		this._filling();
	},

	/**
	 * @method _setThemesFailed
	 */
	_setThemesFailed: function() {
		this._failed();
	},

	/**
	 * @method _setTheme
	 */
	_setTheme: function(action) {
		this._set(action.themeId, action.theme);
	},

	/**
	 * @method _setThemeLike
	 */
	_setThemeLike: function(action) {
		var newTheme = cloneDeep(this._get(action.themeId));
		newTheme.likes = action.like;
		this._set(action.themeId, newTheme);
	},

	/**
	 * @method _setThemeLoading
	 */
	_setThemeLoading: function(action) {
		this._filling(action.themeId);
	},

	/**
	 * @method _setThemeFailed
	 */
	_setThemeFailed: function(action) {
		this._failed(action.themeId);
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function () {
		var handlers = {};

		handlers[RemixerConstants.LOAD_THEMES] = this._setThemesLoading;
		handlers[RemixerConstants.RECEIVE_THEMES] = this._setThemes;
		handlers[RemixerConstants.RECEIVE_THEMES_FAIL] = this._setThemesFailed;

		handlers[RemixerConstants.LOAD_THEME] = this._setThemeLoading;
		handlers[RemixerConstants.RECEIVE_THEME] = this._setTheme;
		handlers[RemixerConstants.RECEIVE_THEME_FAIL] = this._setThemeFailed;

		handlers[RemixerConstants.RECEIVE_THEME_LIKE] = this._setThemeLike;
		handlers[RemixerConstants.RECEIVE_THEME_LIKE_FAIL] = noop;
		return handlers;
	}
});

module.exports = ThemeStore;
