import Immutable from 'immutable';

var Mapper = require('../../utils/Mapper');

const Record = Immutable.Record({
	id: null,
	websiteId: null,
	pageId: null,
	basePageId: null,
	thumbnail: "",
	description: "",
	title: "",
	slug: "",
	created: null,
	updated: null
});

class PageProjectRecord extends Record {
	static fromDTO(json) {
		return new PageProjectRecord(Mapper.toCamelCase(json));
	}
	toDTO() {
		return Mapper.toSnakeCase(this.toJS());
	}
}

export default PageProjectRecord;
