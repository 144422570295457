'use strict';

var $ = require('jquery');
var _ = require('lodash');

var ComponentDomAttributes = require('../constants/ComponentDomAttributes');
var PropertyTypes = require('../constants/PropertyTypes');

var DomElementUtils = require('../utils/DomElementUtils');
var ShapeUtils = require('../utils/ShapeUtils');

var ComponentUtils = require('./ComponentUtils');

var _filter = {
	acceptNode: function filter(node) {
		return _.startsWith(node.nodeValue, '/' + ComponentDomAttributes.property) ?
			NodeFilter.FILTER_ACCEPT :
			NodeFilter.FILTER_SKIP;
	}
};

/** @module PropertyUtils
 * @requires jquery
 * @requires lodash
 * @requires ComponentDomAttributes
 * @requires PropertyTypes
 * @requires DomElementUtils
 * @requires ShapeUtils
 * @requires ComponentUtils
 */
function getListItemProperties(component) {
	//hack for IE http://stackoverflow.com/questions/5982648/recommendations-for-working-around-ie9-treewalker-filter-bug
	var safeFilter = _filter.acceptNode;
	safeFilter.acceptNode = _filter.acceptNode;
	var treeWalker = document.createTreeWalker(component, NodeFilter.SHOW_COMMENT, safeFilter, false);
	var componentRect = DomElementUtils.GetElementRect(component);
	var componentId = ComponentUtils.GetComponentId(component);

	var listItemsProperties = [];
	while (treeWalker.nextNode()) {
		var node = treeWalker.currentNode;
		var propertyData = JSON.parse(node.nodeValue.substr(ComponentDomAttributes.property.length + 2)); // ('/' + '=').length === 2
		//previousElementSibling doesn't seem to work for #comment nodes in IE
		//instead we will use previousSibling twice (once for the empty text node)
		var propertyElement = node.previousElementSibling || node.previousSibling.previousSibling;

		//NOTE: if property invisible we want to interact with parent element
		if (!propertyElement) {
			break;
		}
		if (propertyElement.offsetParent === null) {
			propertyElement = propertyElement.parentElement;		 
		}

		propertyData.componentId = componentId;
		propertyData.rectangle = DomElementUtils.GetElementRect(propertyElement);
		propertyData.relativeRectangle = ShapeUtils.RelativeRect(componentRect, propertyData.rectangle);

		listItemsProperties.push(propertyData);
	}
	return listItemsProperties;
}

function getSelector(search) {
	search = search ? '*="' + search + '"' : '';
	return '[' + ComponentDomAttributes.property + search + ']';
}

function getAllProperties(root, search) {
	return _.toArray($(root || document).find(getSelector(search)));
}

function getPropertyElement(element) {
	if(!element) return;

	return $(element).closest(getSelector()).get(0);
}

function getPropertyData(element) {
	element = getPropertyElement(element);
	if(!element) return;

	var data = element.getAttribute(ComponentDomAttributes.property);
	if(!data) return;

	return JSON.parse(data);
}

function getAllPropertiesState(component) {
	var componentRect = DomElementUtils.GetElementRect(component);
	var componentId = ComponentUtils.GetComponentId(component);

	return _(component)
		.thru(getAllProperties)
		.reject({
			//NOTE: we don't need properties inside invisible parent
			parentElement: {offsetParent: null}
		})
		.map(function(property) {
			var propertyData = getPropertyData(property);

			//NOTE: if property invisible we want to interact with parent elemet
			//NOTE: if property is text we want to interact with parent elemet
			if (property.offsetParent === null ||
				PropertyTypes.isTextPropertyType(propertyData.type)) {
				property = property.parentElement;
			}

			propertyData.componentId = componentId;
			propertyData.rectangle = DomElementUtils.GetElementRect(property);
			propertyData.relativeRectangle = ShapeUtils.RelativeRect(componentRect, propertyData.rectangle);

			return propertyData;
		})
		.concat(getListItemProperties(component))
		.uniqBy('path') //Some properties may be used many times in component's DOM. So we consider first occurrence only.
		.value();
}

module.exports = {
	GetAllProperties: getAllProperties,
	GetPropertyElement: getPropertyElement,
	GetPropertyData: getPropertyData,
	GetAllPropertiesState: getAllPropertiesState
};
