'use strict';

import { values, isEmpty } from 'lodash';
var RouteStore = require('../stores/RouteStore');
var ThemesList = require('../components/pages/ThemesList.react');
var ThemePreviewPage = require('../components/pages/ThemePreviewPage.react');
var ContentManagement = require('../components/pages/ContentManagement.react');
var Editor = require('../components/pages/Editor.react').default;
var Websites = require('../components/pages/Websites.react.js').default;
var Tailor = require('../components/pages/Sitetailor.react.js').default;
var WebsiteSettings = require('../components/pages/WebsiteSettings.react.js').default;
var WordPressExportSettings = require('../components/pages/WordPressExportSettings.react.js').default;

var ContentManagementTabs = require('../constants/ContentManagementTabs');


var checkOnUser = function(user, redirectPath, params = {}) {
	if (isEmpty(user)) {
		RouteStore
			.getState('route')
			.transitionTo(redirectPath, params)
	}
}

/** @module Config
 * @requires lodash
 * @requires ThemesList
 * @requires ThemePreviewPage
 * @requires ContentManagement
 * @requires LayoutManagement
 * @requires Websites
 * @requires WebsiteSettings
 * @requires Documents
 * @requires AccountSettings
 * @requires DataStatistics
 * @requires ContentManagementTabs
 */
var Config = {
	default: {
		path: '',
		handler: ThemesList,
		checkOnPermission: function (user) {
			checkOnUser(user, this.name)
		}
	},
	themes: {
		path: 'themes',
		handler: ThemesList,
		checkOnPermission: function (user) {
			checkOnUser(user, this.name)
		}
	},
	themePreview: {
		path: 'demo/{themeId}',
		handler: ThemePreviewPage,
		rules: {
			themeId: /^[0-9]+$/
		},
		checkOnPermission: function (user) {
			checkOnUser(user, this.name, this.params)
		}
	},
	content: {
		path: 'content/:tab:/:contentId:',
		handler: ContentManagement,
		rules: {
			tab: values(ContentManagementTabs),
			contentId: /^\d+$/,
			/**
			 * @method normalize_
			 */
			normalize_: function (request, values) {
				return {
					tab: values.tab || ContentManagementTabs.IMAGES,
					contentId: values.contentId
				};
			}
		},
		checkOnPermission: function (user) {
			checkOnUser(user, 'themes')
		}
	},
	contentAction: {
		path: 'content/:tab:/:action:/:actionParam:',
		handler: ContentManagement,
		rules: {
			tab: values(ContentManagementTabs),
			action: /^\w+$/,
			actionParam: /^\w+$/,
			/**
			 * @method normalize_
			 */
			normalize_: function (request, values) {
				return {
					tab: values.tab || ContentManagementTabs.IMAGES,
					action: values.action,
					actionParam: values.actionParam
				};
			}
		},
		checkOnPermission: function (user) {
			checkOnUser(user, 'themes')
		}
	},
	layout: {
		path: 'editor/{websiteId}/:pageProjectId:',
		handler: Editor,
		rules: {
			websiteId: /^[0-9]+$/,
			pageProjectId: /^[0-9]+$/
		},
		checkOnPermission: function (user) {
			checkOnUser(user, 'themes')
		}
	},
	websites: {
		path: 'websites',
		handler: Websites,
		checkOnPermission: function (user) {
			checkOnUser(user, 'themes')
		}
	},
	sitetailor: {
		path: 'sitetailor',
		handler: Tailor,
		checkOnPermission: function (user) {
			checkOnUser(user, this.name)
		}
	},
	websiteSettings: {
		path: 'websites/{websiteId}',
		handler: WebsiteSettings,
		rules: {
			websiteId: /^[0-9]+$/
		},
		checkOnPermission: function (user) {
			checkOnUser(user, 'themes')
		}
	},
	wordpressExportSettings: {
		path: 'export/wordpress/{websiteId}',
		handler: WordPressExportSettings,
		rules: {
			websiteId: /^[0-9]+$/
		},
		checkOnPermission: function (user) {
			checkOnUser(user, 'themes')
		}
	}
};

module.exports = Config;
