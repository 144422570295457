'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../infrastructure/ComponentFactory');
var Chrome = require('./Chrome.react');
var ChromeItem = Chrome.ChromeItem;
var ChromeButtons = Chrome.ChromeButtons;
var hasTouch = require('has-touch');

/** @module ThemeItem
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires Chrome
 * @requires has-touch
 */
var ThemeItem = ComponentFactory.Create({
	displayName: "ThemeItem",

	propTypes: {
		theme: React.PropTypes.object.isRequired,
		onSelect: React.PropTypes.func.isRequired,
		onPreview: React.PropTypes.func.isRequired,
		onLikeClick: React.PropTypes.func.isRequired
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return {
			detailFontSize: "30px",
			clicked: false
		};
	},

	/**
	 * @method handleResize
	 */
	handleResize: function(e) {
		this.setState({detailFontSize: this._onBodyResize(1.5)});
	},

	/**
	 * @method _onBodyResize
	 */
	_onBodyResize: function(compressor) {
		var element = ReactDOM.findDOMNode(this);
		var width = element.offsetWidth;
		this.setState({elementWidth: width});
		return ( Math.max(
			Math.min( ( width / ( compressor * 10 ) ))) + 'px' );
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		window.addEventListener('resize', this.handleResize);
		this.resizeTimer = setInterval(this.handleResize, 500);
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function() {
		window.removeEventListener('resize', this.handleResize);
		clearInterval(this.resizeTimer);
	},

	/**
	 * @method _onSelectClick
	 */
	_onSelectClick: function(ev) {
		ev.stopPropagation();
		this.props.onSelect(this.props.theme);
	},

	/**
	 * @method _onPreviewClick
	 */
	_onPreviewClick: function(ev) {
		ev.stopPropagation();
		this.props.onPreview(this.props.theme);
	},

	/**
	 * @method _onLikeClick
	 */
	_onLikeClick: function(ev) {
		this.props.onLikeClick(this.props.theme);
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(ev) {
		if (!hasTouch) return;
		this.setState({ clicked: !this.state.clicked });
	},

	/**
	 * @method render
	 */
	render: function() {
		var theme = this.props.theme;
		var imageSrc = theme.thumbnails && theme.thumbnails.main ? theme.thumbnails.main : null;
		var name = theme.name;
		var className = 'theme-item';
		if (hasTouch) className += ' touch-enabled';
		if (hasTouch && this.state.clicked) className += ' clicked';
		return (
			<ChromeItem ref="self" onClick={this._onClick} className={className} name={name} liked={theme.likes.self} onLikeClick={this._onLikeClick}>
				<div className="thumbnail" style={{ backgroundImage: "url(" + imageSrc + ")"}}>
					<div className="details" style={{ fontSize: this.state.detailFontSize }}>
						<div >
							<h1 className="name">{name}</h1>
							<p className="description">{theme.description}</p>
						</div>
						<ChromeButtons
							leftText="Preview Theme"
							rightText="Select Theme"
							leftClick={this._onPreviewClick}
							rightClick={this._onSelectClick} />
					</div>
				</div>
			</ChromeItem>
		);
	}
});

module.exports = ThemeItem;
