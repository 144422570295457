'use strict';

import { get } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');
var ContentTypes = require('../../../constants/ContentTypes');

var DescriptionMarkdown = require('./DescriptionMarkdown.react');

var contentTypes = {};
contentTypes[ContentTypes.MARKDOWN] = DescriptionMarkdown;

/** @module Description
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ContentTypes
 * @requires DescriptionMarkdown
 */
var Description = ComponentFactory.Create({
	displayName: "Description",
	propTypes: {
		contentItem: React.PropTypes.object.isRequired,
		picked: React.PropTypes.bool,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	/**
	 * @method render
	 */
	render: function() {
		var TypedDescription = contentTypes[get(this.props.contentItem, 'content_type')];
		return (
			<TypedDescription {...this.props} />
		);
	}

});

module.exports = Description;
