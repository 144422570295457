'use strict';

import { get, set, isEqual, isUndefined } from 'lodash';
var he = require('he');

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentContextStore = require('../../stores/ComponentContextStore');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

var PropertyMetadata = require('../../models/PropertyMetadata');


/** @module LinkInput
 * @requires he
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires ComponentActionsCreator
 * @requires PropertyMetadata
 * @requires lodash
 */
var LinkInput = ComponentFactory.Create(ComponentContextStore, {
	displayName: "LinkInput",
	propTypes: {
		property: React.PropTypes.instanceOf(PropertyMetadata).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			value: ComponentContextStore.getPropertyValue(this.props.property.name)
		};
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		if(!isUndefined(prevState) && !isEqual(this.state.value, prevState.value)) {
			var component = ComponentContextStore.getState('active');
			if(!isEqual(get(component.context, this.props.property.name), this.state.value)) {
				var encodedText = he.encode(get(this.state, 'value', ''));
				set(component.context, this.props.property.name, encodedText);

				ComponentActionsCreator.updateComponentInstanceContext(component);
			}
		}
	},

	/**
	 * @method _onUrlChange
	 */
	_onUrlChange: function(e) {
		set(this.state, ['value', 'url'], e.target.value);
		this.setState(this.state);
	},

	/**
	 * @method _onTextChange
	 */
	_onTextChange: function(e) {
		set(this.state, ['value', 'text'], e.target.value);
		this.setState(this.state);
	},

	/**
	 * @method _onIconChange
	 */
	_onIconChange: function(e) {
		set(this.state, ['value', 'icon'], e.target.value);
		this.setState(this.state);
	},

	/**
	 * @method render
	 */
	render: function() {
		var decodedText = he.decode(get(this.state, 'value', '') + '');
		return (
			<div className="link-input row">
				<label><span className="small-heading">{this.props.property.description} : </span></label>
				<div className="row">
					<div className="columns input-label">
						<label className="left inline">
							<span className="small-heading">Link</span>
						</label>
					</div>
					<div className="columns input">
						<input
							className="small-input"
							type='text'
							defaultValue={decodedText}
							placeholder='url'
							onChange={this._onUrlChange} />
					</div>
				</div>
				<div className="row">
					<div className="small-4 columns input-label">
						<label className="left inline">
							<span className="small-heading">Text</span>
						</label>
					</div>
					<div className="small-8 columns input">
						<input
							className="small-input"
							type='text'
							defaultValue={get(this.state.value, 'text')}
							placeholder='inner text'
							onChange={this._onTextChange} />
					</div>
				</div>
				<div className="row">
					<div className="small-4 columns input-label">
						<label className="left inline">
							<span className="small-heading">Icon</span>
						</label>
					</div>
					<div className="small-8 columns input">
						<input
							className="small-input"
							type='text'
							defaultValue={get(this.state.value, 'icon')}
							placeholder='icon'
							onChange={this._onIconChange} />
					</div>
				</div>
			</div>);
	}
});

module.exports = LinkInput;
