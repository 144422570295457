'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var WebAPI = require('../utils/WebAPI');
import api2Client from '../utils/api2-client';

/** @module ExportWordPressActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var ExportWordPressActionsCreator = {
	/**
	 * @method startExportWordPress
	 */
	startExportWordPress: function(websiteId) {
		var data = { websiteId: websiteId };
		AppDispatcher.dispatch({
			actionType: RemixerConstants.BEGIN_WORDPRESS_EXPORT,
			data: data
		},
		WebAPI.startExportWordPress, [data]);
	},
	/**
	 * @method loginWordPressAdmin
	 */
	requestLoginWordPressAdmin: function(website, newWindow) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REQUEST_LOGIN_WORDPRESS_ADMIN,
			data: website
		});
		api2Client.getWordpressLogin(website, newWindow);
	}
};

module.exports = ExportWordPressActionsCreator;
