'use strict';

import { isBoolean } from 'lodash';
var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImportTypes = require('../../constants/ImportTypes');

var ImportProgressStore = require('../../stores/ImportProgressStore');

var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var StickyPopover = require('../common/StickyPopover.react');
var MediaServicesUtils = require('../../utils/MediaServicesUtils');

/** @module VideoUrlImport
 * @requires lodash
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires ImportTypes
 * @requires ImportProgressStore
 * @requires ImportActionsCreator
 * @requires StickyPopover
 * @requires MediaServicesUtils
 */
var VideoUrlImport = ComponentFactory.Create(ImportProgressStore, {
	displayName: "VideoUrlImport",
	/**
	 * @method getState
	 */
	getState: function() {
		var state = {
			importProgress: ImportProgressStore.getState(ImportTypes.YOUTUBE) || ImportProgressStore.getState(ImportTypes.VIMEO),
			url: this.route().query.url,
			popupIsOpen: false,
			error: true
		};
		state.loading = state.importProgress && state.importProgress.progress;
		return state;
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this.setState({element: ReactDOM.findDOMNode(this)});
	},

	/**
	 * @method _togglePopup
	 */
	_togglePopup: function(newState) {
		var isOpen = isBoolean(newState) ? newState : !this.state.popupIsOpen;
		var width = ReactDOM.findDOMNode(this).offsetWidth;

		this.setState({
			popupIsOpen: isOpen,
			rel: {
				width: width
			}
		});
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(e) {
		this._startImport();
	},

	/**
	 * @method _handleKeyDown
	 */
	_handleKeyDown: function(e) {
		if(e.key === "Enter") {
			this._startImport();
		}
	},

	/**
	 * @method _startImport
	 */
	_startImport: function() {
		var url = this.state.url;
		if(url && !(this.state.loading)) {
			var videoId = MediaServicesUtils.getYoutubeVideoId(url);
			if (videoId) {
				ImportActionsCreator.startImport(ImportTypes.YOUTUBE, {'video_id': videoId});
			}
			videoId = MediaServicesUtils.getVimeoVideoId(url);
			if (videoId) {
				ImportActionsCreator.startImport(ImportTypes.VIMEO, {'video_id': videoId});
			}
		}
	},

	/**
	 * @method _close
	 */
	_close: function() {
		this._togglePopup(false);
	},

	/**
	 * @method _onChangeTextField
	 */
	_onChangeTextField: function (ev) {
		var text = ev.target.value;
		this.setState({ error: !MediaServicesUtils.getYoutubeVideoId(text) && !MediaServicesUtils.getVimeoVideoId(text), url: text });
	},

	/**
	 * @method _onMouseEnter
	 */
	_onMouseEnter: function() {
		this.setState({mouseOver: true});
	},

	/**
	 * @method _onMouseLeave
	 */
	_onMouseLeave: function() {
		this.setState({mouseOver: false});
	},

	/**
	 * @method render
	 */
	render: function () {
		var loading = this.state.loading;
		var importIcon = null;
		if (MediaServicesUtils.getYoutubeVideoId(this.state.url)) {
			importIcon = <i className="mdi mdi-youtube-play"/>
		} else if (MediaServicesUtils.getVimeoVideoId(this.state.url)) {
			importIcon = <i className="mdi mdi-vimeo"/>
		}
		var popupForm = <div className="import-website">
			<div>
				<p className="import-website-description">Enter any URL to your video below to begin importing</p>
				<div className="import-website-body">
					<input
						className="website-url small-input"
						onKeyDown={this._handleKeyDown}
						onChange={this._onChangeTextField}
						value={this.state.url}
						type="text"
						placeholder="put URL here..."
						autoFocus />
				</div>
				<div className="row">
					<div className="large-6 columns">
						<p className="import-website-disclaimer">Video &amp; content may be subject to copyright.</p>
					</div>
					<div className="large-6 columns">
						<div className={this.css("button medium radius success", {disabled: loading || this.state.error})} onClick={this._startImport}>
							<p style={{color: "#fff"}}>{importIcon} Import</p>
						</div>
					</div>
				</div>
			</div>
		</div>;

		var popup = this.state.popupIsOpen ?
			<StickyPopover className={this.css("closable-popup", "import-popup", "page")}
				onClose={this._close}
				target={this.state.element}>
				<div className="title-bar row">
					<div className="large-6 columns">
						<p className="title">Import a video</p>
					</div>
				</div>
				{popupForm}
			</StickyPopover> :
			<div></div>;

		var iconCss = ['import-icon-img website', { 'pulse': loading }];
		var css = 'import-header-column import-header-column-button bg-overlay website';
		return (
			<div className={this.css(css)} >
				<div className="button-inner" onClick={this._togglePopup}>
					<div className="show-for-large-up import-website-label" >
						<p >{this.props.title}</p>
						<h3 >{this.props.tagline}</h3>
					</div>
					<div className="import-icon-holder" >
						<div className={this.css(iconCss)} >
						</div>
					</div>
				</div>
				{popup}
			</div>
		);
	}
});

module.exports = VideoUrlImport;
