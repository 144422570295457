'use strict';

var ImportServerActionsCreator = require('../actions/ImportServerActionsCreator');
var ThemesServerActionsCreator = require('../actions/ThemesServerActionsCreator');
var UserInfoServerActionsCreator = require('../actions/UserInfoServerActionsCreator');
var ConfigServerActionsCreator = require('../actions/ConfigServerActionsCreator');
var TwitterServerActionsCreator = require('../actions/TwitterServerActionsCreator');
var FlickrServerActionsCreator = require('../actions/FlickrServerActionsCreator');
var YoutubeServerActionsCreator = require('../actions/YoutubeServerActionsCreator');
var VimeoServerActionsCreator = require('../actions/VimeoServerActionsCreator');
var SoundcloudServerActionsCreator = require('../actions/SoundcloudServerActionsCreator');
var ContentServerActionsCreator = require('../actions/ContentServerActionsCreator');
var ComponentServerActionsCreator = require('../actions/ComponentServerActionsCreator');
var PageProjectServerActionsCreator = require('../actions/PageProjectServerActionsCreator');
var WebsiteServerActionsCreator = require('../actions/WebsiteServerActionsCreator');
var PageServerActionsCreator = require('../actions/PageServerActionsCreator');
var AvailableDomainServerActionsCreator = require('../actions/AvailableDomainServerActionsCreator');
var ThemeLikesServerActionsCreator = require('../actions/ThemeLikesServerActionsCreator');
var PublishServerActionsCreator = require('../actions/PublishServerActionsCreator');
var PixabayServerActionsCreator = require('../actions/PixabayServerActionsCreator').default;
var FacebookServerActionsCreator = require('../actions/FacebookServerActionsCreator');
var YoutubeSearchServerActionsCreator = require('../actions/YoutubeSearchServerActionsCreator').default;
var VimeoSearchServerActionsCreator = require('../actions/VimeoSearchServerActionsCreator').default;
var PersonServerActionsCreator = require('../actions/PersonServerActionsCreator');
var SignedUploadServerActionsCreator = require('../actions/SignedUploadServerActionsCreator');
var TrialServerActionsCreator = require('../actions/TrialServerActionsCreator').default;
var ExportWordPressServerActionsCreator = require('../actions/ExportWordPressServerActionsCreator');
var ContentTypes = require('../constants/ContentTypes');
import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

var $ = require('jquery');
import { bind, forEach, constant } from 'lodash'

/** @module WebAPI
 * @requires ImportServerActionsCreator
 * @requires ThemesServerActionsCreator
 * @requires UserInfoServerActionsCreator
 * @requires ConfigServerActionsCreator
 * @requires TwitterServerActionsCreator
 * @requires FlickrServerActionsCreator
 * @requires YoutubeServerActionsCreator
 * @requires VimeoServerActionsCreator
 * @requires SoundcloudServerActionsCreator
 * @requires ContentServerActionsCreator
 * @requires ComponentServerActionsCreator
 * @requires PageProjectServerActionsCreator
 * @requires PageServerActionsCreator
 * @requires ThemeLikesServerActionsCreator
 * @requires PublishServerActionsCreator
 * @requires PixabayServerActionsCreator
 * @requires FacebookServerActionsCreator
 * @requires YoutubeSearchServerActionsCreator
 * @requires VimeoSearchServerActionsCreator
 * @requires PersonServerActionsCreator
 * @requires SignedUploadServerActionsCreator
 * @requires ContentTypes
 * @requires Mapper
 * @requires jquery
 * @requires lodash
 */
var WebAPI = {
	/**
	 * @method inheritSkin
	 */
	inheritSkin: function(pageId, basePageId) {
		var actions = ComponentServerActionsCreator;

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: `/api/v1/skin_context/${basePageId}/${pageId}/`,
			context: actions
		})
			.done(bind(actions.receiveInheritSkin, actions, pageId, basePageId))
			.fail(bind(actions.receiveInheritSkinFail, actions, pageId, basePageId));
	},
	/**
	 * @method movePageRevision
	 */
	movePageRevision: function(pageId, delta) {
		var actions = ComponentServerActionsCreator;

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: `/api/v1/pages/history/${delta}/${pageId}/`,
			context: actions
		})
			.done(bind(actions.receiveMovePageRevision, actions, pageId, delta))
			.fail(bind(actions.receiveMovePageRevisionFail, actions, pageId, delta));
	},

	createWebsite: function(basePageId, name, redirect) {
		var actions = WebsiteServerActionsCreator;

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: '/api/v1/websites/',
			data: JSON.stringify({ "name": name, "base_page_id": basePageId}),
			contentType: "application/json",
			context: actions
		})
			.done(bind(actions.receiveCreateWebsite, actions, basePageId, name, redirect))
			.fail(bind(actions.receiveCreateWebsiteFail, actions, basePageId, name, redirect));
	},

	createWebsiteWithRedirect: function(basePageId, name, redirect) {
		var actions = WebsiteServerActionsCreator;

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: '/api/v1/websites/',
			data: JSON.stringify({ "name": name, "base_page_id": basePageId}),
			contentType: "application/json",
			context: actions
		})
			.done(bind(actions.receiveCreateWebsite, actions, basePageId, name, 'no_redirect'))
			.fail(bind(actions.receiveCreateWebsiteFail, actions, basePageId, name, 'no_redirect'))
			.always((response) => {
				AppDispatcher.dispatch({
					actionType: RemixerConstants.LOAD_USER_INFO,
					basePageId,
					name
				},
				WebAPI.loadUserInfoWithRedirect, [redirect, response.data]);
			});
	},

	loadAllWebsites: function() {
		var actions = WebsiteServerActionsCreator;

		$.ajax({
			method: 'GET',
			dataType: 'json',
			url: '/api/v1/websites/',
			context: actions
		})
			.done(actions.receiveAllWebsites)
			.fail(actions.receiveAllWebsitesFail);
	},

	updateWebsite: function(website) {
		var actions = WebsiteServerActionsCreator;

		$.ajax({
			method: 'PUT',
			dataType: 'json',
			url: `/api/v1/websites/${website.id}/`,
			data: JSON.stringify(website.toDTO()),
			contentType: "application/json",
			context: actions
		})
			.done(bind(actions.receiveUpdateWebsite, actions, website))
			.fail(bind(actions.receiveUpdateWebsiteFail, actions, website));
	},

	deleteWebsite: function(website) {
		var actions = WebsiteServerActionsCreator;

		$.ajax({
			method: 'DELETE',
			dataType: 'json',
			url: `/api/v1/websites/${website.id}/`,
			contentType: "application/json",
			context: actions
		})
			.done(bind(actions.receiveDeleteWebsite, actions, website))
			.fail(bind(actions.receiveDeleteWebsiteFail, actions, website));

	},

	/**
	 * @method createPageProject
	 */
	createPageProject: function(website, title, description, slug) {
		var actions = PageProjectServerActionsCreator;

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: '/api/v1/page_projects/',
			data: JSON.stringify({ "website_id": website.id, title, description, slug}),
			contentType: "application/json",
			context: actions
		})
			.done(bind(actions.receiveCreatePageProject, actions, website, title, description, slug))
			.fail(bind(actions.receiveCreatePageProjectFail, actions, website, title, description, slug));
	},
	clonePageProject: function(website, pageProject, title, description, slug) {
		var actions = PageProjectServerActionsCreator;

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: '/api/v1/page_projects/',
			data: JSON.stringify({ "website_id": website.id, base_page_id: pageProject.pageId, title, description, slug}),
			contentType: "application/json",
			context: actions
		})
			.done(bind(actions.receiveClonePageProject, actions, website, pageProject, title, description, slug))
			.fail(bind(actions.receiveClonePageProjectFail, actions, website, pageProject, title, description, slug));
	},

	/**
	 * @method loadAllPageProjects
	 */
	loadAllPageProjects: function() {
		var actions = PageProjectServerActionsCreator;

		$.ajax({
			method: 'GET',
			dataType: 'json',
			url: '/api/v1/page_projects/',
			context: actions
		})
			.done(actions.receiveAllPageProjects)
			.fail(actions.receiveAllPageProjectsFail);
	},

	/**
	 * @method loadAllPages
	 */
	loadAllPages: function() {
		var actions = PageServerActionsCreator;
		if (window.preloaded.pages) {
			actions.receiveAllPages(window.preloaded.pages);
			delete window.preloaded.pages;
			return;
		}
		$.ajax({
			method: 'GET',
			dataType: 'json',
			url: '/api/v1/pages/',
			context: actions
		})
			.done(actions.receiveAllPages)
			.fail(actions.receiveAllPagesFail);
	},

	/**
	 * @method removeComponentInstance
	 */
	removeComponentInstance: function(componentInstance) {
		var actions = ComponentServerActionsCreator;

		$.ajax({
			method: 'DELETE',
			dataType: 'json',
			url: `/api/v1/page_context/components/${componentInstance.pageId}/${componentInstance.id}/`,
			context: actions
		})
			.done(actions.receiveRemoveComponentInstance)
			.fail(actions.receiveRemoveComponentInstanceFail);
	},

	/**
	 * @method updateComponentInstance
	 */
	updateComponentInstance: function(componentInstance) {
		var actions = ComponentServerActionsCreator;

		//TODO: avoid duplication, this function called when component updates
		//position or other properties
		$.ajax({
			method: 'PUT',
			dataType: 'json',
			url: `/api/v1/page_context/components/${componentInstance.pageId}/${componentInstance.id}/`,
			contentType: "application/json",
			data: JSON.stringify(componentInstance)
		})
			.done(bind(actions.receiveUpdateComponentInstance, actions, componentInstance))
			.fail(bind(actions.receiveUpdateComponentInstanceFail, actions, componentInstance));
	},

	/**
	 * @method updateComponentInstanceContext
	 */
	updateComponentInstanceContext: function(componentInstance) {
		var actions = ComponentServerActionsCreator;

		//TODO: avoid duplication, this function called when component updates
		//only context, in order to avoid page rendering
		$.ajax({
			method: 'PUT',
			dataType: 'json',
			url: `/api/v1/page_context/components/${componentInstance.pageId}/${componentInstance.id}/`,
			contentType: "application/json",
			data: JSON.stringify(componentInstance)
		})
			.done(bind(actions.receiveUpdateComponentInstanceContext, actions, componentInstance))
			.fail(bind(actions.receiveUpdateComponentInstanceContextFail, actions, componentInstance));
	},

	/**
	 * @method replaceComponentInstance
	 */
	replaceComponentInstance: function(componentInstance, componentReference) {
		var actions = ComponentServerActionsCreator;

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: `/api/v1/page_context/replace/${componentInstance.pageId}/${componentInstance.id}/`,
			data: {
				"component_reference_name": componentReference.name,
				"component_reference_version": componentReference.version
			}
		})
			.done(bind(actions.receiveReplaceComponentInstance, actions, componentInstance.pageId))
			.fail(bind(actions.receiveReplaceComponentInstanceFail, actions, componentInstance.pageId));
	},

	/**
	 * @method createComponentInstance
	 */
	createComponentInstance: function(pageId, componentReference, position) {
		var actions = ComponentServerActionsCreator;

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: `/api/v1/page_context/components/${pageId}/`,
			data: {
				"component_reference_name": componentReference.name,
				"component_reference_version": componentReference.version,
				"component_position": position
			},
			context: actions
		})
			.done(actions.receiveCreateComponentInstances)
			.fail(actions.receiveCreateComponentInstancesFail);
	},
	/**
	 * @method loadSimilarComponents
	 */
	loadSimilarComponents: function(pageId, componentInstanceId, componentCategory, searchText, pIndex, itemsPerPage) {
		var actions = ComponentServerActionsCreator;

		$.ajax({
			method: 'GET',
			dataType: 'json',
			url: `/api/v1/page_context/similar/${pageId}/${componentInstanceId}/?component_category=${componentCategory ? componentCategory : ''}&q=${searchText ? searchText : ''}&max_results=${itemsPerPage}&start_index=${pIndex * itemsPerPage}`
		})
			.done(bind(actions.receiveLoadSimilarComponents, actions, pageId, componentInstanceId, componentCategory, searchText, pIndex))
			.fail(bind(actions.receiveLoadSimilarComponentsFail, actions, pageId, componentInstanceId, componentCategory, searchText, pIndex));
	},
	/**
	 * @method loadRelevantComponents
	 */
	loadRelevantComponents: function(pageId, componentInstanceId, componentCategory, searchText, pIndex, itemsPerPage) {
		var actions = ComponentServerActionsCreator;

		$.ajax({
			method: 'GET',
			dataType: 'json',
			url: `/api/v1/page_context/relevant/${pageId}/`,
			data: {
				component_context_id: componentInstanceId,
				component_category: componentCategory ? componentCategory : '',
				q: searchText ? searchText : '',
				max_results: itemsPerPage,
				start_index: pIndex * itemsPerPage,
				latest_version: true
			}
		})
			.done(bind(actions.receiveLoadRelevantComponents, actions, pageId, componentInstanceId, componentCategory, searchText, pIndex))
			.fail(bind(actions.receiveLoadRelevantComponentsFail, actions, pageId, componentInstanceId, componentCategory, searchText, pIndex));
	},
	/**
	 * @method loadComponentsMetadata
	 */
	loadComponentsMetadata: function(category) {
		var actions = ComponentServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: '/api/v1/components/',
			data: { category: category },
			context: actions
		})
			.done(actions.receiveComponentsMetadata)
			.fail(actions.receiveComponentsMetadataFail);
	},

	/**
	 * @method loadPage
	 */
	loadPage: function(id) {
		var actions = ComponentServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: `/api/v1/pages/${id}/`,
			context: actions
		})
			.done(actions.receivePage)
			.fail(actions.receivePageFail);
	},

	/**
	 * @method updatePage
	 */
	updatePageProject: function(pageProject) {
		var actions = PageProjectServerActionsCreator;

		$.ajax({
			method: 'PUT',
			dataType: 'json',
			url: `/api/v1/page_projects/${pageProject.id}/`,
			data: JSON.stringify(pageProject.toDTO()),
			contentType: "application/json",
			context: actions
		})
			.done(bind(actions.receiveUpdatePageProject, actions, pageProject))
			.fail(bind(actions.receiveUpdatePageProjectFail, actions, pageProject));
	},

	deletePageProject: function(pageProject) {
		var actions = PageProjectServerActionsCreator;

		$.ajax({
			method: 'DELETE',
			dataType: 'json',
			url: `/api/v1/page_projects/${pageProject.id}/`,
			contentType: "application/json",
			context: actions
		})
			.done(bind(actions.receiveDeletePageProject, actions, pageProject))
			.fail(bind(actions.receiveDeletePageProjectFail, actions, pageProject));

	},

	/**
	 * @method startImport
	 */
	startImport: function(importType, data) {
		const actions = ImportServerActionsCreator;

		let request;
		if (importType.requireFormData) {
			const formData = new FormData();
			forEach(data, (file) => {
				formData.append('images', file, file.name);
			});

			request = {
				method: 'POST',
				url: '/api/v1/import/file/',
				data: formData,
				processData: false,
				contentType: false
			};
		} else {
			request = {
				method: 'POST',
				dataType: 'json',
				url: `/api/v1/import/${importType.name}/`,
				data: data
			};
		}
		$(window).bind('beforeunload', constant(
			"Content importing in progress. If you leave now it could be not completed"
		));
		$.ajax(request)
			.done(bind(actions.receiveImportStarted, actions, importType, data))
			.fail(bind(actions.receiveImportFails, actions, importType, data))
			.always(function() {$(window).unbind('beforeunload'); });
	},

	/**
	 * @method loadImportHistory
	 */
	loadImportHistory: function(importType) {
		var actions = ImportServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: `/api/v1/import/${importType.name}/`
		})
			.done(bind(actions.receiveImportHistory, actions, importType))
			.fail(bind(actions.receiveImportHistoryFails, actions, importType));
	},

	/**
	 * @method loadContent
	 */
	loadContent: function(contentType) {
		var actions = ContentServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: `/api/v1/content/${contentType.resourceName}/`
		})
			.done(bind(actions.receiveContent, actions, contentType))
			.fail(bind(actions.receiveContentFails, actions, contentType));
	},

	/**
	 * @method deleteContent
	 */
	deleteContent: function(id, contentType) {
		var actions = ContentServerActionsCreator;

		$.ajax({
			method: 'DELETE',
			dataType: 'json',
			url: `/api/v1/content/${contentType.resourceName}/${id}`
		})
			.done(bind(actions.receiveContentDelete, actions, id, contentType))
			.fail(bind(actions.receiveContentDeleteFails, actions, id, contentType));
	},

	/**
	 * @method postContent
	 */
	postContent: function(contentType, content) {
		var actions = ContentServerActionsCreator;
		var data = ContentTypes.transformToName(content, 'content_type');
		data = ContentTypes.transformToName(data, 'import_type');

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: `/api/v1/content/${contentType.resourceName}/`,
			data: data
		})
			.done(bind(actions.receiveContentPost, actions, contentType, content))
			.fail(bind(actions.receiveContentPostFails, actions, contentType));
	},

	/**
	 * @method putContent
	 */
	putContent: function(contentId, contentType, content) {
		var actions = ContentServerActionsCreator;
		var data = ContentTypes.transformToName(content, 'content_type');
		data = ContentTypes.transformToName(data, 'import_type');

		$.ajax({
			method: 'PUT',
			dataType: 'json',
			contentType: 'application/json',
			url: `/api/v1/content/${contentType.resourceName}/${contentId}`,
			data: JSON.stringify(data)
		})
			.done(bind(actions.receiveContentPut, actions, contentId, contentType, content))
			.fail(bind(actions.receiveContentPutFails, actions, contentId, contentType));
	},

	/**
	 * @method updateContentTags
	 */
	updateContentTags: function(id, contentType, tags) {
		var actions = ContentServerActionsCreator;

		$.ajax({
			method: 'PUT',
			dataType: 'json',
			contentType: 'application/json',
			url: `/api/v1/content/${contentType.resourceName}/${id}/tags/`,
			data: JSON.stringify({data: tags})
		})
			.done(bind(actions.receiveUpdateContentTags, actions, id, contentType, tags))
			.fail(bind(actions.receiveUpdateContentTagsFails, actions, id, contentType, tags));
	},

	/**
	 * @method addThemeLike
	 */
	addThemeLike: function(id) {
		var actions = ThemeLikesServerActionsCreator;

		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: `/api/v1/themes/${id}/like`
		})
			.done(bind(actions.receiveThemeLike, actions, id))
			.fail(bind(actions.receiveThemeLikeFail, actions));
	},

	/**
	 * @method removeThemeLike
	 */
	removeThemeLike: function(id) {
		var actions = ThemeLikesServerActionsCreator;

		$.ajax({
			method: 'DELETE',
			dataType: 'json',
			url: `/api/v1/themes/${id}/like`
		})
			.done(bind(actions.receiveThemeLike, actions, id))
			.fail(bind(actions.receiveThemeLikeFail, actions));
	},

	/**
	 * @method loadThemes
	 */
	loadThemes: function() {
		if (window.preloaded.themes) {
			ThemesServerActionsCreator.receiveThemes(window.preloaded.themes);
			delete window.preloaded.themes;
			return;
		}
		$.ajax({
			dataType: 'json',
			url: '/api/v1/themes/',
			context: ThemesServerActionsCreator
		})
			.done(ThemesServerActionsCreator.receiveThemes)
			.fail(ThemesServerActionsCreator.receiveThemesFails);
	},

	/**
	 * @method loadTheme
	 */
	loadTheme: function(themeId) {
		var actions = ThemesServerActionsCreator;
		$.ajax({
			dataType: 'json',
			url: `/api/v1/themes/${themeId}`
		})
			.done(bind(actions.receiveTheme, actions, themeId))
			.fail(bind(actions.receiveThemeFails, actions, themeId));
	},

	/**
	 * @method loadThemePages
	 */
	loadThemePages: function(themeId) {
		var actions = ThemesServerActionsCreator;

		$.ajax({
			method: 'GET',
			dataType: 'json',
			url: `/api/v1/themes/${themeId}/pages/`
		})
			.done(bind(actions.receiveThemePages, actions, themeId))
			.fail(bind(actions.receiveThemePagesFails, actions, themeId));
	},

	/**
	 * @method loadUserInfo
	 */
	loadUserInfo: function() {
		if (window.preloaded.userinfo) {
			UserInfoServerActionsCreator.receiveUserInfo(window.preloaded.userinfo);
			delete window.preloaded.userinfo;
			return;
		}
		$.ajax({
			dataType: 'json',
			url: '/api/v1/userinfo/',
			context: UserInfoServerActionsCreator
		})
			.done(UserInfoServerActionsCreator.receiveUserInfo)
			.fail(UserInfoServerActionsCreator.receiveUserInfoFail);
	},
	/**
	 * @method loadUserInfoWithRedirect
	 */
	loadUserInfoWithRedirect: function(redirect, website) {
		var actions = UserInfoServerActionsCreator
		if (window.preloaded.userinfo) {
			actions.receiveUserInfo(window.preloaded.userinfo);
			delete window.preloaded.userinfo;
			return;
		}
		$.ajax({
			dataType: 'json',
			url: '/api/v1/userinfo/',
			context: UserInfoServerActionsCreator
		})
			.done(bind(actions.receiveUserInfoWithRedirect, actions, redirect, website))
			.fail(bind(actions.receiveUserInfoFail, actions));
	},
	/**
	 * @method loadConfig
	 */
	loadConfig: function() {
		$.ajax({
			dataType: 'json',
			url: '/api/v1/app_settings',
			context: ConfigServerActionsCreator
		})
			.done(ConfigServerActionsCreator.receiveConfig)
			.fail(ConfigServerActionsCreator.receiveConfigFail);
	},

	/**
	 * @method getFlickrAuthorizationUrl
	 */
	getFlickrAuthorizationUrl: function(returnToUrl) {
		var actions = FlickrServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: '/api/v1/flickr/',
			method: 'POST',
			data: JSON.stringify({ 'return_to': returnToUrl }),
			contentType: "application/json",
			context: actions
		})
			.done(actions.receiveFlickrAuthorizationUrl)
			.fail(actions.receiveFlickrAuthorizationUrlFails);
	},

	/**
	 * @method getTwitterAuthorizationUrl
	 */
	getTwitterAuthorizationUrl: function(returnToUrl) {
		var actions = TwitterServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: '/api/v1/twitter_auth/',
			method: 'POST',
			data: JSON.stringify({"return_to": returnToUrl}),
			contentType: "application/json",
			context: actions
		})
			.done(actions.receiveTwitterAuthorizationUrl)
			.fail(actions.receiveTwitterAuthorizationUrlFails);
	},

	/**
	 * @method getSoundcloudAuthorizationUrl
	 */
	getSoundcloudAuthorizationUrl: function(returnToUrl) {
		var actions = SoundcloudServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: '/api/v1/soundcloud_auth/',
			method: 'POST',
			data: JSON.stringify({"return_to": returnToUrl}),
			contentType: "application/json",
			context: actions
		})
			.done(actions.receiveSoundcloudAuthorizationUrl)
			.fail(actions.receiveSoundcloudAuthorizationUrlFails);
	},

	/**
	 * @method getYoutubeAuthorizationUrl
	 */
	getYoutubeAuthorizationUrl: function(returnToUrl) {
		var actions = YoutubeServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: '/api/v1/google_auth/',
			method: 'POST',
			data: JSON.stringify({"return_to": returnToUrl}),
			contentType: "application/json",
			context: actions
		})
			.done(actions.receiveYoutubeAuthorizationUrl)
			.fail(actions.receiveYoutubeAuthorizationUrlFails);
	},

	/**
	 * @method getVimeoAuthorizationUrl
	 */
	getVimeoAuthorizationUrl: function(returnToUrl) {
		var actions = VimeoServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: '/api/v1/vimeo_auth/',
			method: 'POST',
			data: JSON.stringify({"return_to": returnToUrl}),
			contentType: "application/json",
			context: actions
		})
			.done(actions.receiveVimeoAuthorizationUrl)
			.fail(actions.receiveVimeoAuthorizationUrlFails);
	},

	/**
	 * @method startPublish
	 */
	startPublish: function (data) {
		var id = data.websiteId;
		var actions = PublishServerActionsCreator;
		$.ajax({
			contentType: "application/json",
			url: '/api/v1/publish/' + id,
			method: "POST",
			dataType: 'json'
		})
			.fail(actions.receivePublishFailed);
	},

	searchPixabay: function(query, category, page, compact) {
		var actions = PixabayServerActionsCreator;
		$.ajax({
			type: 'get',
			url: '/api/v1/pixabay_search/',
			data: { query, category, page },
			dataType: 'json'
		})
			.done(bind(actions.receiveSearchPixabay, actions, query, category, page, compact))
			.fail(bind(actions.receiveSearchPixabayFails, actions, query, category, page, compact));
	},

	/**
	 * @method getFacebookAuthorizationUrl
	 */
	getFacebookAuthorizationUrl: function(returnToUrl) {
		var actions = FacebookServerActionsCreator;

		$.ajax({
			dataType: 'json',
			url: '/api/v1/facebook_auth/',
			method: 'POST',
			data: JSON.stringify({"return_to": returnToUrl}),
			contentType: "application/json",
			context: actions
		})
			.done(actions.receiveFacebookAuthorizationUrl)
			.fail(actions.receiveFacebookAuthorizationUrlFails);
	},

	searchYoutube: function(query, page=null) {
		var actions = YoutubeSearchServerActionsCreator;
		$.ajax({
			type: 'get',
			url: '/api/v1/youtube_search/',
			data: { query, page },
			dataType: 'json'
		})
			.done(bind(actions.receiveSearchYoutube, actions, query, page))
			.fail(bind(actions.receiveSearchYoutubeFails, actions, query, page));
	},

	/**
	 * @method getVimeoSearchVideos
	 */
	searchVimeo: function(query, page=1) {
		var actions = VimeoSearchServerActionsCreator;
		$.ajax({
			type: 'get',
			url: '/api/v1/vimeo_search/',
			data: { query, page },
			dataType: 'json'
		})
			.done(bind(actions.receiveSearchVimeo, actions, query, page))
			.fail(bind(actions.receiveSearchVimeoFails, actions, query, page));
	},

	/**
	 * @method getPerson
	 */
	getPerson: function(id) {
		var actions = PersonServerActionsCreator;
		$.ajax({
			url: `/api/v1/persons/${id}/`,
			dataType: 'json'
		})
			.done(bind(actions.getPerson, actions, id))
			.fail(bind(actions.getPersonFails, actions, id));
	},

	/**
	 * @method getPersons
	 */
	getPersons: function() {
		var actions = PersonServerActionsCreator;
		$.ajax({
			url: '/api/v1/persons/',
			dataType: 'json'
		})
			.done(bind(actions.getPersons, actions))
			.fail(bind(actions.getPersonsFails, actions));
	},

	/**
	 * @method addPerson
	 */
	addPerson: function(data) {
		var actions = PersonServerActionsCreator;
		$.ajax({
			url: '/api/v1/persons/',
			dataType: 'json',
			method: 'POST',
			data: data
		})
			.done(bind(actions.addPerson, actions))
			.fail(bind(actions.addPersonFails, actions));
	},

	/**
	 * @method updatePerson
	 */
	updatePerson: function(id, data) {
		var actions = PersonServerActionsCreator;
		$.ajax({
			url: `/api/v1/persons/${id}/`,
			dataType: 'json',
			method: 'PUT',
			data: data
		})
			.done(bind(actions.updatePerson, actions))
			.fail(bind(actions.updatePersonFails, actions));
	},

	/**
	 * @method deletePerson
	 */
	deletePerson: function(id) {
		var actions = PersonServerActionsCreator;
		$.ajax({
			url: `/api/v1/persons/${id}/`,
			dataType: 'json',
			method: 'DELETE'
		})
			.done(bind(actions.deletePerson, actions))
			.fail(bind(actions.deletePersonFails, actions));
	},

	/**
	 * @method signUpload
	 */
	signUpload: function() {
		var actions = SignedUploadServerActionsCreator;
		$.ajax({
			url: '/api/v1/sign_upload/',
			dataType: 'json'
		})
			.done(bind(actions.receiveSignedUpload, actions))
			.fail(bind(actions.receiveSignedUploadFails, actions));
	},

	/**
	 * @method loadAvailableDomains
	 */
	loadAvailableDomains: function() {
		var actions = AvailableDomainServerActionsCreator;
		$.ajax({
			url: '/api/v1/available_domains/',
			dataType: 'json'
		})
			.done(actions.receiveAvailableDomains)
			.fail(actions.receiveAvailableDomainsFail);
	},

	/**
	 * @method getTrialStatus
	 */
	getTrialStatus: function() {
		var actions = TrialServerActionsCreator;
		$.ajax({
			url: '/api/v1/trial_status/',
			dataType: 'json'
		})
			.done(actions.receiveTrialStatus)
			.fail(actions.receiveTrialStatusFails);
	},

	/**
	 * @method startTrial
	 */
	startTrial: function(data) {
		const basePageId = data.pageId
		const name = data.pageName
		const redirect = data.redirect
		const actions = TrialServerActionsCreator;

		$.ajax({
			url: '/api/v1/start_trial/',
			dataType: 'json'
		})
			.done(() => {
				AppDispatcher.dispatch({
					actionType: RemixerConstants.CREATE_WEBSITE,
					basePageId,
					name
				},
				WebAPI.createWebsiteWithRedirect, [basePageId, name, redirect]);
			})
			.fail(actions.receiveTrialStatusFails);
	},

	/**
	 * @method startExportWordPress
	 */
	startExportWordPress: function(data) {
		var websiteId = data.websiteId;
		var actions = ExportWordPressServerActionsCreator;
		$.ajax({
			contentType: "application/json",
			url: '/api/v1/export/wordpress/' + websiteId,
			method: "POST",
			dataType: 'json'
		})
			.fail(actions.receiveExportWordPressFailed);
	}

};

module.exports = WebAPI;
