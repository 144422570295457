'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module SignedUploadStore
 * @requires StoreFactory
 * @requires RemixerConstants
 */
var SignedUploadStore = StoreFactory.Create({

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		return {}; // the ukraine-framework requires this
	},

	/**
	 * @method _getRemixerEvents
	 */
	_getRemixerEvents: function() {
		return [
			RemixerConstants.RECEIVE_SIGNED_UPLOAD,
			RemixerConstants.RECEIVE_SIGNED_UPLOAD_FAILS
		];
	}

});

module.exports = SignedUploadStore;
