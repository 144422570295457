import { map } from 'lodash';

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

const YoutubeSearchResult = Immutable.Record({
	video_id: undefined,
	title: undefined,
	url: undefined
});

class YoutubeSearchResultStore extends ReduceStore<Immutable.List> {
	getInitialState(): Immutable.List {
		return new Immutable.List();
	}

	reduce(state: Immutable.List, action: Object): Immutable.List {
		switch (action.actionType) {
			case RemixerConstants.RECEIVE_SEARCH_YOUTUBE:
				return state.clear().push(...map(action.response.data.items, (entity) => new YoutubeSearchResult(entity)));
			default: return state;
		}
	}
}

export default new YoutubeSearchResultStore(AppDispatcher);
