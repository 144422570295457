'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var WebAPI = require('../utils/WebAPI');


var AvailableDomainActionsCreator = {
	loadAvailableDomains: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_AVAILABLE_DOMAINS
		},
		WebAPI.loadAvailableDomains);
	}
};

module.exports = AvailableDomainActionsCreator;
