'use strict';

var React = require('react');

var PageLinkSelector = require('./PageLinkSelector.react');

/** @module ChordAnchorTools
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires DropDown
 * @requires PageStore
 * @requires ComponentMetadataStore
 * @requires ComponentActionsCreator
 * @requires ComponentTypes
 */
class PageLinkTools extends React.Component {
	static propTypes= {
		pageId: React.PropTypes.number.isRequired,
		onChange: React.PropTypes.func,
		href: React.PropTypes.string
	};

	constructor(props) {
		super(props);
		this._onPageLinkChange = this._onPageLinkChange.bind(this);
		this._savePageLink = this._savePageLink.bind(this);

		this.state = {
			url: props.href
		};
	}

	componentWillReceiveProps(nextProps){
		if (nextProps.href != this.props.href){
			this.setState({
				url: nextProps.href
			});
		}
	}

	/**
	 * @method _onChangeAnchor
	 */
	_onPageLinkChange(ev) {
		this.setState({ url: ev.target.value });
	}

	/**
	 * @method _saveAnchor
	 */
	_savePageLink(ev) {
		ev.preventDefault();

		if(this.props.onChange) this.props.onChange(this.state.url);
	}

	/**
	 * @method render
	 */
	render() {
		return (<div className="tabs-content-link">
			<p className="hint">Select a page for this text to navigate to</p>
			<div className="url-input">
				<PageLinkSelector
					pageId={this.props.pageId}
					onChange={this._onPageLinkChange}
					href={this.state.url}/>
				<span onClick={this._savePageLink} className="save">Save</span>
			</div>
		</div>);
	}

}

module.exports = PageLinkTools;
