'use strict';

var React = require('react');
import { constant } from 'lodash';

var CookieFetcher = React.createClass({
	propTypes: {
		timeout: React.PropTypes.number.isRequired,
		url: React.PropTypes.string.isRequired
	},
	getDefaultProps: function () {
		return {
			timeout: 1000 * 60 * 60,
			url: 'https://panel.dreamhost.com'
		}
	},
	componentDidMount: function () {
		this.start();
	},
	shouldComponentUpdate: function (newProps) {
		return this.props.timeout !== newProps.timeout;
	},
	componentWillUnmount: function () {
		this.stop();
	},
	getCookies() {
		var xhr = new XMLHttpRequest();
		xhr.open('GET', this.props.url, true);
		xhr.withCredentials = true;
		xhr.send(null);
	},
	callback: function () {
		if (this.timer) {
			this.getCookies();
			this.start();
		}
	},
	start: function () {
		this.stop();
		this.timer = setTimeout(this.callback, this.props.timeout);
	},
	stop: function () {
		clearTimeout(this.timer);
		this.timer = null;
	},
	render: constant(false)
});

module.exports = CookieFetcher;
