'use strict';

import { map } from 'lodash';
var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var InstagramImport = require('../common/InstagramImport.react');
var TwitterImport = require('../common/TwitterImport.react');
var YoutubeImport = require('../common/YoutubeImport.react');
var FacebookImport = require('../common/FacebookImport.react');
var YelpImport = require('../common/YelpImport.react');
var FlickrImport = require('../common/FlickrImport.react');
var PicasaImport = require('../common/PicasaImport.react');
var BlogImport = require('../common/BlogImport.react');
var VimeoImport = require('../common/VimeoImport.react');
var VineImport = require('../common/VineImport.react');
var TumblrImport = require('../common/TumblrImport.react');
var SoundcloudImport = require('../common/SoundcloudImport.react');
var StickyPopover = require('../common/StickyPopover.react');

/** @module ImportIconBar
 * @requires lodash
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires InstagramImport
 * @requires TwitterImport
 * @requires YoutubeImport
 * @requires FacebookImport
 * @requires YelpImport
 * @requires FlickrImport
 * @requires PicasaImport
 * @requires BlogImport
 * @requires VimeoImport
 * @requires VineImport
 * @requires TumblrImport
 * @requires SoundcloudImport
 * @requires StickyPopover
 */
var ImportIconBar = ComponentFactory.Create({
	displayName: "ImportIconBar",
	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return ({popup: false});
	},

	/**
	 * @method showToolbar
	 */
	showToolbar: function(e) {
		this.setState({popup: !this.state.popup});
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this.setState({element: ReactDOM.findDOMNode(this.refs.more)});
	},

	/**
	 * @method _close
	 */
	_close: function() {
		this.setState({popup: false});
	},

	/**
	 * @method render
	 */
	render: function() {
		var title = this.props.title;
		var tagline = this.props.tagline;
		var icons = map(this.props.icons, (icon) => {
			switch (icon) {
				case "Instagram": return <InstagramImport key={icon}/>;
				case "Youtube": return <YoutubeImport key={icon}/>;
				case "Twitter": return <TwitterImport key={icon}/>;
				case "Facebook": return <FacebookImport key={icon}/>;
				case "Yelp": return <YelpImport key={icon}/>;
				case "Flickr": return <FlickrImport key={icon}/>;
				case "Picasa": return <PicasaImport key={icon}/>;
				case "Blog": return <BlogImport key={icon}/>;
				case "Vimeo": return <VimeoImport key={icon}/>;
				case "Vine": return <VineImport key={icon}/>;
				case "Tumblr": return <TumblrImport key={icon}/>;
				case "Soundcloud": return <SoundcloudImport key={icon}/>;
			}
		});
		var popup = this.state.popup ?
			(<StickyPopover className={this.css("closable-popup", "import-popup", "icons")}
				onClose={this._close}
				target={this.state.element}>
				<div className="title-bar row">
					<div className="large-6 columns">
						<p className="title">Import additional accounts</p>
					</div>
				</div>
				<div className="content">
					{icons}
				</div>
			</StickyPopover>) :
			<div></div>;
		return (
			<div className="import-icon-bar">
				<div className="import-button" >
					<div className="show-for-large-up import-social-media-label" >
						<p>{title}</p>
						<h3>{tagline}</h3>
					</div>
					<div className="media-source place-holder"></div>
					<div>{icons}</div>
				</div>
				{icons.length > 1 ?
					<div ref="more" onClick={this.showToolbar} className="media-source more">
						<div className="circle-icon more" title="more">
							<i className="mdi mdi-dots-horizontal more" ></i>
						</div>
						{popup}
					</div>
					: null }
			</div>
		);
	}
});

module.exports = ImportIconBar;
