'use strict';

var _ = require('lodash');

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');
var ImportStore = require('./ImportStore');
var UserStore = require('./UserStore');
var ImportHistoryStore = require('./ImportHistoryStore');

/** @module ImportProgressStore
 * @requires lodash
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires ImportStore
 * @requires UserStore
 * @requires ImportHistoryStore
 */
var ImportProgressStore = StoreFactory.Create({
	/**
	 * @method _updateImport
	 */
	_updateImport: function(action) {
		this.waitFor([ImportStore.token, ImportHistoryStore.token, UserStore.token]);

		var importMetaState = ImportStore.getMetaState(action.importType);
		var historyMetaState = ImportHistoryStore.getMetaState(action.importType);
		var history = ImportHistoryStore.getState(action.importType);
		var imported = _(importMetaState).values().compact().some();

		this._set(action.importType, {
			progress: importMetaState.filling,
			failed: importMetaState.failed || historyMetaState.failed,
			applied: historyMetaState.filled && _.some(history) || importMetaState.filled,
			loading: (!historyMetaState.filled || historyMetaState.filling) && !imported,
			imported: imported
		});
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		//ImportStore
		handlers[RemixerConstants.IMPORT_STARTING] = this._updateImport;
		handlers[RemixerConstants.RECEIVE_IMPORT_FINISHED] = this._updateImport;
		handlers[RemixerConstants.IMPORT_FAILS] = this._updateImport;
		handlers[RemixerConstants.RECEIVE_IMPORT_AUTH_EXPIRED] = this._updateImport;

		//ImportHistoryStore
		handlers[RemixerConstants.LOAD_IMPORT_HISTORY] = this._updateImport;
		handlers[RemixerConstants.RECEIVE_IMPORT_HISTORY] = this._updateImport;
		handlers[RemixerConstants.RECEIVE_IMPORT_HISTORY_FAILS] = this._updateImport;
		handlers[RemixerConstants.RECEIVE_IMPORT_FINISHED] = this._updateImport;

		return handlers;
	}
});

module.exports = ImportProgressStore;
