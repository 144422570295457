'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

import { omit } from 'lodash';

/** @module Generic
 * @requires react
 * @requires ComponentFactory
 */
var Generic = ComponentFactory.Create({
	displayName: "Generic",
	propTypes: {
		tag: React.PropTypes.any
	},
	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			tag: "div"
		};
	},
	/**
	 * @method render
	 */
	render: function() {
		var Tag = this.props.tag;
		return (
			<Tag {...omit(this.props, ['tag', 'hideSelected'])}>
				{this.props.children}
			</Tag>
		);
	}
});

module.exports = Generic;
