'use strict';

var React = require('react');

/** @module Chrome
 * @requires react
 */

/** @module StatisticsPanel
 * @requires react
 */
var StatisticsPanel = React.createClass({
	propTypes: {
		usageTooltip: React.PropTypes.string,
		likesTooltip: React.PropTypes.string,
		usageCount: React.PropTypes.number,
		likesCount: React.PropTypes.number
	},
	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="statistics-panel">
				<div className="counters">
					<StatCounter counterType="usages" tooltip={this.props.usageTooltip} count={this.props.usageCount} />
					<StatCounter counterType="likes" tooltip={this.props.likesTooltip} count={this.props.likesCount} />
				</div>
			</div>
		);
	}
});

/** @module StatCounter
 * @requires react
 */
var StatCounter = React.createClass({
	propTypes: {
		counterType: React.PropTypes.string,
		tooltip: React.PropTypes.string,
		count: React.PropTypes.number
	},
	/**
	 * @method render
	 */
	render: function() {
		var count = this.props.count || 0;
		return (
			<div className={"stat-counter " + this.props.counterType} title={this.props.tooltip}><i/>{count}</div>
		);
	}
});

/** @module ChromeButtons
 * @requires react
 */
var ChromeButtons = React.createClass({
	propTypes: {
		leftText: React.PropTypes.string,
		rightText: React.PropTypes.string,
		leftClick: React.PropTypes.func,
		rightClick: React.PropTypes.func
	},
	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="buttons" data-buttons-separator="OR">
				<button onClick={this.props.leftClick} className="left-button">{this.props.leftText}</button>
				<button onClick={this.props.rightClick} className="right-button">{this.props.rightText}</button>
			</div>
		);
	}
});

/** @module LikeButton
 * @requires react
 */
var LikeButton = React.createClass({
	propTypes: {
		onClick: React.PropTypes.func,
		liked: React.PropTypes.bool
	},
	/**
	 * @method onClick
	 */
	onClick: function(){
		if(this.props.onClick){
			this.props.onClick();
		}
	},
	/**
	 * @method render
	 */
	render: function () {
		var text = (this.props.liked) ? 'UnLike' : 'Like';
		var statusClassName = (this.props.liked) ? "on" : "off";
		return (
			<a className={"like-toogle " + statusClassName} onClick={this.onClick} title={text}>
				<i className='mdi mdi-heart' />
			</a>
		);
	}
});

/** @module ChromeItem
 * @requires react
 */
var ChromeItem = React.createClass({
	propTypes: {
		className: React.PropTypes.string,
		onClick: React.PropTypes.func,
		name: React.PropTypes.string,
		liked: React.PropTypes.bool,
		onLikeClick: React.PropTypes.func,
		children: React.PropTypes.any
	},
	/**
	 * @method render
	 */
	render: function() {
		var className = 'chrome-item ' + (this.props.className || '');
		return (
			<div className={className} onClick={this.props.onClick}>
				<div className="chrome-header">
					<div className="semaphor"><span/><span/><span/></div>
					{this.props.onLikeClick && <LikeButton liked={this.props.liked} onClick={this.props.onLikeClick} />}
				</div>
				{this.props.children}
			</div>
		);
	}
});

module.exports = {
	ChromeItem: ChromeItem,
	ChromeButtons: ChromeButtons,
	StatisticsPanel: StatisticsPanel
};
