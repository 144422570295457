'use strict';
var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var TagWrapper = require('../content-lists/TagWrapper.react');
var FilterWrapper = require('../content-lists/FilterWrapper.react');
var NoResultsWrapper = require('../content-lists/NoResultsWrapper.react');
var Mosaic = require('../content-lists/Mosaic.react');

var Overlay = require('../content-details/Overlay.react');

var config = {
	'higher-order-components': [
		TagWrapper,
		FilterWrapper,
		NoResultsWrapper
	]
};

var ITEM_WIDTH = 200;
var ITEM_HEIGHT = 200;

/** @module Audios
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires TagWrapper
 * @requires FilterWrapper
 * @requires NoResultsWrapper
 * @requires Mosaic
 * @requires ContentTypes
 * @requires Overlay
 */
var Audios = ComponentFactory.Config(config).Create({
	displayName: "Audios",
	propTypes: {
		content: React.PropTypes.array,
		contentItem: React.PropTypes.object,
		picked: React.PropTypes.array,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	statics: {
		itemWidth: ITEM_WIDTH,
		itemHeight: ITEM_HEIGHT
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div>
				<Mosaic {...this.props} useInfinite={true} itemWidth={ITEM_WIDTH} itemHeight={ITEM_HEIGHT} />
				<Overlay {...this.props} />
			</div>
		);
	}
});

module.exports = Audios;
