'use strict';

var Mapper = require('../utils/Mapper');
import { includes, assign, camelCase, startsWith, endsWith, mapKeys } from 'lodash';

var baseTypes = {
	link: null,
	icon: null,
	string: null,
	image: null,
	blogpost: null,
	text: null,
	address: null,
	video: null,
	videoUrl: null, // todo: merge the 'videoUrl' property type with 'video'
	audio: null,
	boolean: null,
	options: null,
	color: null,
	markdown: null,
	map: null,
	array: null
};

function extendWithArrays(types) {
	return assign({}, types, mapKeys(types, function(value, key) {
		return camelCase("array of " + key);
	}));
}

/** @module PropertyTypes
 * @requires Mapper
 * @requires lodash
 */
var PropertyTypes = Mapper.mirrorKey(extendWithArrays(baseTypes));
PropertyTypes.isTextPropertyType = function isTextPropertyType(type) {
	return includes([
		this.string,
		this.arrayOfString,
		//this.link,
		//this.arrayOfLink,
		this.text,
		this.arrayOfText
	], type);
};
PropertyTypes.isImagePropertyType = function isImagePropertyType(type) {
	return includes([
		this.image,
		this.arrayOfImage
	], type);
};
PropertyTypes.isVideoPropertyType = function isVideoPropertyType(type) {
	return includes([
		this.video,
		this.arrayOfVideo
	], type);
};
PropertyTypes.isAudioPropertyType = function isAudioPropertyType(type) {
	return includes([
		this.audio,
		this.arrayOfAudio
	], type);
};
PropertyTypes.isMapPropertyType = function isMapAddressPropertyType(type) {
	return includes([
		this.map,
		this.arrayOfMap
	], type);
};
PropertyTypes.isLinkPropertyType = function isLinkPropertyType(type) {
	return includes([
		this.link,
		this.arrayOfLink
	], type);
};
PropertyTypes.isMarkdownPropertyType = function isMarkdownPropertyType(type) {
	return includes([
		this.markdown,
		this.arrayOfMarkdown
	], type);
};


PropertyTypes.isArrayChild = function isArrayChild(type) {
	return startsWith(type, "arrayOf") && !this.isArrayItem(type);
};
var pattern = "[item]";
PropertyTypes.isArrayItem = function isArrayItem(type) {
	return endsWith(type, pattern);
};
PropertyTypes.toArrayItem = function toArrayItem(type) {
	return type + pattern;
};
PropertyTypes.fromArrayItem = function fromArrayItem(type) {
	return type.substr(0, type.length - pattern.length);
};

module.exports = PropertyTypes;
