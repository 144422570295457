'use strict';

import { pickBy, startsWith, result, assign, defaults } from 'lodash';

var FluxStore = require('./FluxStore');
var FluxStoreState = require('./FluxStoreState');
var FluxStoreRegister = require('./FluxStoreRegister');

var storesRegister = new FluxStoreRegister();

function publicOnly(context) {
	return pickBy(context, function(value, key) {
		return !startsWith(key, '_');
	});
}

/** @module StoreFactory
 * @requires lodash
 * @requires FluxStore
 * @requires FluxStoreState
 * @requires FluxStoreRegister
 */
var StoreFactory = {
	/**
	 * @method Create
	 */
	Create: function(context) {
		var state = new FluxStoreState(result(context, '_getInitialState', {}));
		var store = new FluxStore(state);

		assign(store, publicOnly(context));
		defaults(state, context);

		storesRegister.register(store, state);

		return store;
	}
};

module.exports = StoreFactory;
