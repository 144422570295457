'use strict';

import { assign, isEmpty, has } from 'lodash';
var handlebars = require('handlebars');
var MediaServicesUtils = require('../utils/MediaServicesUtils');

var TemplateCompilerFactory = require('./TemplateCompilerFactory');

//register global helpers
handlebars.registerHelper('videoServiceEmbeddedUrl', function(url, autoPlay, loop) {
	if (!url) return url;

	return MediaServicesUtils.getVideoServiceEmbeddedUrl(url, {autoPlay: autoPlay, loop: loop}) || url;
});

/** @module ComponentRenderer
 * @requires handlebars
 * @requires lodash
 * @requires MediaServicesUtils
 * @requires TemplateCompilerFactory
 */
var ComponentRenderer = function(editMode) {
	var _compiler = TemplateCompilerFactory.Create(handlebars, editMode);

	var _compiled = {};
	var _compile = function(template) {
		if(!has(_compiled, template)) {
			_compiled[template] = _compiler.compile(template);
		}

		return _compiled[template];
	};

	this.render = function(template, context, metadata) {
		if (isEmpty(template)) return;

		var render = _compile(template);

		if (metadata) context = assign({}, context, { __metadata__: metadata });
		return render(context);
	};
};

module.exports = ComponentRenderer;
