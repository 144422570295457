import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

/** @module PixabayServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
export default {
	receiveSearchVimeo(query, page, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SEARCH_VIMEO,
			query,
			page,
			response
		});
	},
	receiveSearchVimeoFails(query, page, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SEARCH_VIMEO_FAILS,
			query,
			page,
			response
		});
	}
};
