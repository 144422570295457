'use strict';

import { omit, get, pick } from 'lodash';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');
var Shapes = require('../utils/Shapes');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');

var ComponentInstance = require('../models/ComponentInstance');

var SimilarComponentsPreview = require('./SimilarComponentsPreview.react');
var ChordOptionsPanel = require('./ChordOptionsPanel.react');
var KeyPicker = require('./common/KeyPicker.react');

/** @module ChordSettingsBar
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires Shapes
 * @requires ComponentActionsCreator
 * @requires ComponentInstance
 * @requires SimilarComponentsPreview
 * @requires ChordOptionsPanel
 * @requires KeyPicker
 */
var ChordSettingsBar = ComponentFactory.Create({
	displayName: "ChordSettingsBar",
	propTypes: {
		component: React.PropTypes.instanceOf(ComponentInstance).isRequired,
		rectangle: React.PropTypes.instanceOf(Shapes.Rectangle).isRequired,
		expanded: React.PropTypes.bool,
		settingsType: React.PropTypes.string
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return {};
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		if(props.rectangle !== this.props.rectangle) {
			var css = this.props.rectangle.toObject();
			css.top += css.height;
			this.setState({ css: omit(css, 'height') });
		}
	},

	/**
	 * @method _add
	 */
	_add: function(id) {
		ComponentActionsCreator.openComponentSetting(this.props.component, 'add');
	},

	/**
	 * @method render
	 */
	render: function() {
		var header, content;
		if(get(this.props, "settingsType") && this.props.expanded) {
			content = (<KeyPicker content={get(this.props, "settingsType")}>
				<div key='replace' className="switch-chord-panel">
					<SimilarComponentsPreview component={this.props.component} search='similar'/>
				</div>
				<div key='add' className="switch-chord-panel">
					<SimilarComponentsPreview component={this.props.component} search='relevant'/>
				</div>
				<div key='configuration' className="config-chord-panel">
					<ChordOptionsPanel/>
				</div>
			</KeyPicker>);
			header = (<KeyPicker content={get(this.props, "settingsType")}>
				<span key='replace'>Remix Component</span>
				<span key='add'>Add New Component</span>
				<span key='configuration'>Component Options</span>
			</KeyPicker>);
		}
		return (
			<div className={this.css("chord-settings", pick(this.props, 'expanded'), this.props.expanded && get(this.props, "settingsType"))}
				style={this.state.css}>
				<div className="chord-settings-buttons">
					<div onClick={this._add} className="add-chord-button"><p>new</p></div>
				</div>
				<div className="chord-settings-container">
					<div className="chord-settings-header">
						{header}
					</div>
					<div className="chord-settings-content">
						{content}
					</div>
				</div>
			</div>);
	}
});

module.exports = ChordSettingsBar;
