import { map, get } from 'lodash';

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import LoadingStatusStoreBase from './LoadingStatusStoreBase'

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';
import AvailableDomainRecord from './records/AvailableDomainRecord'
import TrialStatusStore from './TrialStatusStore'

const trialStatusOverride = function(state, action) {
	if(!TrialStatusStore.getState().get('inTrial')){
		return Immutable.List(map(action.availableDomains, (item) => AvailableDomainRecord.fromDTO(item)));
	}
	return Immutable.List(map(action.availableDomains, (item) => {
		return AvailableDomainRecord.fromDTO({'domain': get(item, 'domain'), 'is_eligible': false, 'error_code': 7})}
	));
}

class AvailableDomainsStore extends ReduceStore {
	getInitialState() {
		return Immutable.List();
	}

	reduce(state, action) {
		switch (action.actionType) {
			case RemixerConstants.RECEIVE_AVAILABLE_DOMAINS:
				return trialStatusOverride(state, action);
			default:
				return state;
		}
	}
}

export default new AvailableDomainsStore(AppDispatcher);

export const AvailableDomainsLoadingStatusStore = new LoadingStatusStoreBase({
	load: RemixerConstants.LOAD_AVAILABLE_DOMAINS,
	receive:  RemixerConstants.RECEIVE_AVAILABLE_DOMAINS,
	fail: RemixerConstants.RECEIVE_AVAILABLE_DOMAINS_FAIL
}, AppDispatcher);
