'use strict';

import { create, bind, isFunction, forEach } from 'lodash';

var DomManipulation = require('./DomManipulation');
var TextManipulation = require('./TextManipulation');

var Communication = require('./Communication');
var Receiver = Communication.Receiver;

var ComponentUtils = require('./ComponentUtils');

/** @module Receivers
 * @requires lodash
 * @requires DomManipulation
 * @requires TextManipulation
 * @requires Communication
 * @requires ComponentUtils
 */

function EventsHandling(strategy) {
	Receiver.call(this);

	var _strategy = strategy;

	this.handle = function (parameters) {
		forEach(parameters, bind(this._call, this));
	};

	this._call = function (params, command) {
		var method = _strategy[command];
		if(isFunction(method)) method.call(_strategy, params);
	};
}

function CommandsExecutor(root) {
	Receiver.call(this);

	var _dom = new DomManipulation(root);
	var _text = new TextManipulation();

	this.remove = function (parameters) {
		_dom.remove(parameters);
	};

	this.inject = function (parameters) {
		_dom.inject(parameters);
	};

	this.text = function (parameters) {
		_text.execute(parameters);
	};

	this.scroll = function(parameters) {
		window.scrollTo(parameters.coordX, parameters.coordY);
	};

	this.activate = function (parameters) {
		ComponentUtils.ActivateComponentBy(parameters.componentId, root);
	};

	this.expand = function(parameters) {
		ComponentUtils.ExpandComponentSpaceBy(parameters.componentId, root);
	};
}

EventsHandling.prototype = create(Receiver.prototype, {
	'constructor': EventsHandling
});

CommandsExecutor.prototype = create(Receiver.prototype, {
	'constructor': CommandsExecutor
});

module.exports = {
	EventsHandling: EventsHandling,
	CommandsExecutor: CommandsExecutor
};
