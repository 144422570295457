'use strict';

import SignUpPreview from "../SignUpPreview.react";
import SignUpForm from '../SignUpForm.react';
import LoginForm from '../LoginForm.react';

import { indexOf, defer, first, isEmpty } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');
var Header = require('../layouts/partials/Header.react');
var ThemeStore = require('../../stores/ThemeStore');
var UserStore = require('../../stores/UserStore');

var ThemesActionsCreator = require('../../actions/ThemesActionsCreator');
var WebsiteActionsCreator = require('../../actions/WebsiteActionsCreator');

var ThemePreview = require('../ThemePreview.react');
var FlashMessenger = require('../layouts/partials/FlashMessenger.react');

function getNextItem (items, currentItem, step){
	var nextItemIndex = indexOf(items, currentItem);
	if (nextItemIndex < 0) return null;

	nextItemIndex += step;
	if (nextItemIndex >= items.length) {
		nextItemIndex = 0;
	}
	if (nextItemIndex < 0) {
		nextItemIndex = items.length - 1;
	}

	return items[nextItemIndex];
}

/** @module ThemePreviewPage
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ThemeStore
 * @requires UserStore
 * @requires ThemesActionsCreator
 * @requires ThemePreview
 */
var ThemePreviewPage = ComponentFactory.Create(ThemeStore, {
	displayName: "ThemePreviewPage",
	/**
	 * @method getState
	 */
	getState: function() {
		var themeId = this.route().params.themeId;
		var themes = ThemeStore.getOrderedThemes();
		var currentTheme = ThemeStore.getTheme(themeId) || first(themes);

		return {
			themes: themes,
			userinfo: UserStore.getState(),
			currentTheme: currentTheme,
			isSignUpFormVisible : false,
			formToggle: false
		};
	},
	/**
	* @method componentDidMount
	*/
	componentDidMount: function() {
		UserStore.addChangeListener(this._onChange);
	},
	/**
	* @method componentDidMount
	*/
	componentWillUnmount: function() {
		UserStore.removeChangeListener(this._onChange);
	},
	/**
	* @method _onChange
	*/
	/**
	* @method _updateState
	*/
	_updateState: function() {

		this.setState(this.getState());
	},
	_onChange: function() {
		//HACK: defer() is a quick fix for error: Invariant Violation: Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch.
		defer(this._updateState, this);
	},
	/**
	* @method componentDidUpdate
	*/
	componentDidUpdate: function() {
		if(this.route().params.themeId) {
			ThemesActionsCreator.loadTheme(this.route().params.themeId);
		}

		ThemesActionsCreator.loadThemes();
	},

	/**
	 * @method _onThemeSelect
	 */
	_onThemeSelect: function() {
		isEmpty(this.state.userinfo)
			? this._showSignUpForm()
			: WebsiteActionsCreator.createWebsite(this.state.currentTheme.pageId, this.state.currentTheme.name);
	},

	/**
	 * @method _showSignUpForm
	 */
	_showSignUpForm: function() {
		this.setState({	isSignUpFormVisible: true });
	},

	/**
	 * @method _hideSignUpForm
	 */
	_hideSignUpForm: function() {
		this.setState({
			isSignUpFormVisible: false
		});
	},

	/**
	 * @method _onCloseThemePreview
	 */
	_onCloseThemePreview: function () {
		this.route().transitionTo('themes');
	},

	/**
	 * @method _goToNextTheme
	 */
	_goToNextTheme: function(step){
		var nextTheme = getNextItem(this.state.themes, this.state.currentTheme, step);
		if (nextTheme && nextTheme.themeId !== this.state.currentTheme.themeId) {
			this.route().transitionTo('themePreview', {themeId: nextTheme.themeId});
			this.setState({currentTheme: nextTheme});
		}
	},

	/**
	 * @method _onPreviousThemeClick
	 */
	_onPreviousThemeClick: function(){
		this._goToNextTheme(-1);
	},

	/**
	 * @method _onNextThemeClick
	 */
	_onNextThemeClick: function(){
		this._goToNextTheme(1);
	},
	/**
	 * @method changeForm
	 */
	changeForm: function(value) {
		this.setState({
			formToggle: value
		});
	},

	/**
	 * @method render
	 */
	render: function() {
		if (!this.state.currentTheme) return <div />;

		var theme = this.state.currentTheme;
		var formToggle = this.state.formToggle;
		return (
			<div>
				{this.state.isSignUpFormVisible &&
			<div onClick={this._hideSignUpForm} className="form">
				<Header user={this.state.userinfo} />
				<FlashMessenger floating={true} />
				<div onClick={(e) => e.stopPropagation()}>
					<div className="signup-title">
						{'This theme looks like a great start!'}
					</div>
					<div className="signup-content">
						<SignUpPreview theme={theme} />
						{formToggle ? <LoginForm saveTheme={theme} onChange={this.changeForm} formToggle={this.state.formToggle}/> :
							<SignUpForm saveTheme={theme} onChange={this.changeForm} formToggle={this.state.formToggle}/>}
					</div>
				</div>
			</div>
				}
				<div className={this.css('theme-preview-page', { blur: this.state.isSignUpFormVisible })}>
					<a className="nav-button previous" title="Previous theme" onClick={this._onPreviousThemeClick}><span>{'<'}</span></a>
					<a className="nav-button next" title="Next theme" onClick={this._onNextThemeClick}><span>{'>'}</span></a>
					<ThemePreview
						themeTitle={theme.name}
						themeId={theme.themeId}
						selectButtonText={this.state.userinfo ? "Start Editing": "Select this theme"}
						onClose={this._onCloseThemePreview}
						onSelect={this._onThemeSelect}
						closeButtonText="Back to theme selection"
					/>
				</div>
			</div>
		);
	}
});


module.exports = ThemePreviewPage;
