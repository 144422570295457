import { get } from 'lodash';

import React from 'react';
import {Container} from 'flux/utils';

import WebsitesStore from '../../stores/WebsitesStore';
import SitePublisherStore from '../../stores/SitePublisherStore';
import TrialHideNoticeStore from '../../stores/TrialHideNoticeStore';

import PublishStatuses from '../../constants/PublishStatuses';

import TrialActionsCreator from '../../actions/TrialActionsCreator';
var classnames = require('classnames');


class TrialPublishOverlay extends React.Component {
	static propTypes = {
	}
	static getStores() {
		return [WebsitesStore, SitePublisherStore, TrialHideNoticeStore];
	}
	static calculateState(prevState, props){
		let hideNotice = TrialHideNoticeStore.getState()
		let sitePublisher = SitePublisherStore.getState()
		let publishingSiteId = null
		let status = null
		sitePublisher.forEach((value, key, map) => {
			if ((value === PublishStatuses.PUBLISHING || value === PublishStatuses.PUBLISHED) && !hideNotice.includes(key) ){
				publishingSiteId = key;
				status = value
			}
		})
		return {
			website: WebsitesStore.getState().get(publishingSiteId),
			publishingStatus: status
		};
	}

	_hide() {
		TrialActionsCreator.hide(get(this.state, "website.id"))
	}

	render() {
		if (!this.state.website) {
			return null;
		} else {
			let publishedURL = this.state.website.get("publicUrl", null)
			return (
				<div className="trial-publishing-overlay row">
					<div className = "trial-box large-4 small-centered columns">
						<a className="fixed-hide" onClick={() => this._hide()}>Hide</a>
						<div className = "upper row">
							<span className="large-12 columns">{this.state.publishingStatus === PublishStatuses.PUBLISHING ?  "Now publishing your site!" : "Site Publishing Complete!"}</span>
						</div>
						<div className = "middle row">
							{this.state.publishingStatus === PublishStatuses.PUBLISHING ?
								<span className={classnames("large-12", "columns", "url", "loading")}>{publishedURL ? publishedURL : "Generating page URL"}</span>
								: <a href={publishedURL} target="_blank" className={classnames("large-12", "columns", "url", "complete")}>{publishedURL}</a>
							}
							<span className="large-12 columns">Upgrade your account to publish to a custom domain.</span>
						</div>
						<a href="https://panel.dreamhost.com/index.cgi?tree=remixer.dashboard&">
							<div className = "lower row">
								<span>Click to Upgrade</span>
							</div>
						</a>
					</div>

				</div>
			)
		}
	}
}

export default Container.create(TrialPublishOverlay, {withProps: true});
