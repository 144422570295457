'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var ComponentContextStore = require('../stores/ComponentContextStore');
var PageDomStateStore = require('../stores/PageDomStateStore');
var PageStore = require('../stores/PageStore');

var ChordSettingsBar = require('./ChordSettingsBar.react');
var AddNewChordBar = require('./AddNewChordBar.react');

/** @module InlineSettingsEmulator
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires PageDomStateStore
 * @requires PageStore
 * @requires ChordSettingsBar
 * @requires AddNewChordBar
 */
var InlineSettingsEmulator = ComponentFactory.Create(PageDomStateStore, PageStore, ComponentContextStore, {
	displayName: "InlineSettingsEmulator",
	propTypes: {
		pageId: React.PropTypes.number.isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			active: ComponentContextStore.getState('active'),
			opened: ComponentContextStore.getState('settingsOpened'),
			settingsType: ComponentContextStore.getState('settingsType'),
			pageDom: PageDomStateStore.getState(this.props.pageId),
			page: PageStore.getState(this.props.pageId)
		};
	},

	/**
	 * @method hasStickyComponent
	 */
	hasStickyComponent: function() {
		var components = _.get(this.state, 'pageDom.components');
		return(_.find(components, {'rectangle': {'zIndex': 2}}) ? true : false)
	},

	/**
	 * @method firstRelativeComponentRectangle
	 */
	firstRelativeComponentRectangle: function() {
		var components = _.get(this.state, 'pageDom.components');
		return(_.get(_.find(components, {'rectangle': { 'zIndex': 1}}), 'rectangle'))
	},

	/**
	 * @method render
	 */
	render: function() {
		if (!this.state.pageDom || !this.state.page || !this.props.pageId) return null;
		return (<div>
			<AddNewChordBar pageId={this.props.pageId}
				expanded={this.state.opened === null}
				rectangle={this.firstRelativeComponentRectangle()}
				sticky={this.hasStickyComponent()}/>
			{_(this.state.pageDom.components)
				.map((componentInfo) => {
					var instance = this.state.page.components[componentInfo.componentId];
					if(!instance) return null;

					return (<ChordSettingsBar
						key={instance.id}
						component={instance}
						rectangle={componentInfo.rectangle}
						settingsType={this.state.settingsType}
						expanded={_.get(this.state.opened, 'id') === componentInfo.componentId} />);
				})
				.compact()
				.value()}
		</div>);
	}
});

module.exports = InlineSettingsEmulator;
