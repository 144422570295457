export const daysDifference = function daysDifference(d1, d2) {
	let t2 = d2.getTime();
	let t1 = d1.getTime();

	let difference = parseInt(( t2 - t1 ) / ( 24 * 3600 * 1000 ));
	if ( difference === 0 ){ return "today"; }
	if ( difference === 1 ) { return difference + " day ago"; }
	else { return difference + " days ago"; }
}

export const minutesDifference = function minutesDifference(d1, d2) {
	const t2 = d2.getTime();
	const t1 = d1.getTime();

	const difference = parseInt(( t2 - t1 ) / ( 60 * 1000 ));
	return difference;
}

export const dateToUTC = function dateToUTC(date) {
	// convert to msec
	// add local time zone offset
	// get UTC time in msec
	var utc = date.getTime() + (date.getTimezoneOffset() * 60000);
	return new Date(utc)
}