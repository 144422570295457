import React from 'react';

import ExistedVideoResult from './ExistedVideoResult.react';

export default class ExistedVideoResultList extends React.Component {
	static propTypes = {
		searchResultList: React.PropTypes.array.isRequired,
		onPick: React.PropTypes.func.isRequired
	};

	render() {
		const {searchResultList, onPick} = this.props;

		return (
			<div className="pixabay-search-result">
				{searchResultList.map((searchResult) => <ExistedVideoResult key={searchResult.video_id} searchResult={searchResult} onPick={() => onPick(searchResult)} />)}
			</div>
		);
	}
}
