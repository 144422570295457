'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var ImportIconBar = require('../../common/ImportIconBar.react');
var VideoUrlImport = require('../../common/VideoUrlImport.react');
var YoutubeSearchToolBarItem = require('../../common/YoutubeSearchToolBarItem.react');
var VimeoSearchToolBarItem = require('../../common/VimeoSearchToolBarItem.react');

/** @module VideoImporterToolBar
 * @requires react
 * @requires ComponentFactory
 * @requires ImportIconBar
 * @requires VideoUrlImport
 * @requires YoutubeImport
 * @requires YoutubeSearch
 */
var VideoImporterToolBar = ComponentFactory.Create({
	displayName: "VideoImporterToolBar",
	// eslint-disable-next-line react/display-name
	render: function() {
		var icons = ["Youtube", "Vimeo"];
		return (
			<div className="row import-header">
				<div className="medium-3 large-3 columns">
					<VideoUrlImport title="Import from an URL" tagline="supply direct link to YouTube or Vimeo video"/>
				</div>
				<div className="medium-3 large-3 columns">
					<ImportIconBar title="Import social media" tagline="select source or expand for more" icons={icons} />
				</div>
				<div className="medium-3 large-3 columns">
					<YoutubeSearchToolBarItem />
				</div>
				<div className="medium-3 large-3 columns">
					<VimeoSearchToolBarItem />
				</div>
			</div>
		);
	}
});

module.exports = VideoImporterToolBar;
