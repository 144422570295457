'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module SiteTailorActionCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var SiteTailorActionCreator = {
	/**
	 * @method changeTextColor
	 */
	setIsNeedToReload: function(value) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.SET_SITETAILOR_LOAD_STATUS,
			load: value
		});
	},
	setSitetailorStepNumber: function(value) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.SET_SITETAILOR_STEP_NAME,
			stepName: value
		});
	}
};

module.exports = SiteTailorActionCreator;
