'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var PageStore = require('../stores/PageStore');
var PageDomStateStore = require('../stores/PageDomStateStore');
var ComponentMetadataStore = require('../stores/ComponentMetadataStore');
var ComponentContextStore = require('../stores/ComponentContextStore');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');

var ComponentBuilder = require('../rendering/ComponentBuilder');
var ChannelFactory = require('../rendering/ChannelFactory');
var PageInstanceComparer = require('../rendering/PageInstanceComparer');

var PageSystemLayer = require('./editor/PageSystemLayer.react');
var he = require('he');

/** @module PageEditor
 * @requires lodash
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires PageStore
 * @requires PageDomStateStore
 * @requires ComponentMetadataStore
 * @requires ComponentContextStore
 * @requires ComponentActionsCreator
 * @requires ComponentBuilder
 * @requires ChannelFactory
 * @requires PageInstanceComparer
 * @requires PageSystemLayer
 */
var PageEditor = ComponentFactory.Create(PageStore, PageDomStateStore, ComponentMetadataStore, ComponentContextStore, {
	displayName: "PageEditor",
	propTypes: {
		pageId: React.PropTypes.number.isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var state = {
			activeComponentId: ComponentContextStore.getState('active', 'id'),
			openedComponentId: ComponentContextStore.getState('settingsOpened', 'id'),
			page: PageStore.getState(this.props.pageId),
			metadata: ComponentMetadataStore.getComponents(),
			pageDom: PageDomStateStore.getState(this.props.pageId),
			loading: PageStore.getMetaState(this.props.pageId).filling
				|| ComponentMetadataStore.getMetaState().filling
		};
		if(state.pageDom) state.iframeHeight = state.pageDom.page.size.height;

		return state;
	},

	componentWillReceiveProps: function(props) {
		var state = {
			activeComponentId: ComponentContextStore.getState('active', 'id'),
			openedComponentId: ComponentContextStore.getState('settingsOpened', 'id'),
			page: PageStore.getState(props.pageId),
			metadata: ComponentMetadataStore.getComponents(),
			pageDom: PageDomStateStore.getState(props.pageId),
			loading: PageStore.getMetaState(props.pageId).filling
				|| ComponentMetadataStore.getMetaState().filling
		};
		if(state.pageDom) state.iframeHeight = state.pageDom.page.size.height;

		this.setState(state);
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function(props, state) {
		this.channel = ChannelFactory.CreateParentChannel(
			this.iframe.contentWindow, {
				textPropertyChange: this._textPropertyChange,
				state: this._domState
			});
		this.channel.setup();
		this.comparer = new PageInstanceComparer();

		document.addEventListener("scroll", this._onScroll);
		document.addEventListener("wheel", _.identity);//need to force browsers scroll synchronously

		// Prevent the backspace key from navigating back.
		document.addEventListener('keydown', this._onBackspace);

		this.componentDidUpdate();
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function() {
		this.channel.destroy();

		document.removeEventListener("scroll", this._onScroll);
		document.removeEventListener("wheel", _.identity);
		document.removeEventListener('keydown', this._onBackspace);
	},

	/**
	 * @method _onBackspace
	 */
	_onBackspace: function(event) {
		var doPrevent = false;
		if (event.keyCode === 8) {
			var element = event.srcElement || event.target;
			if ((element.tagName.toUpperCase() === 'INPUT' && (
				element.type.toUpperCase() === 'TEXT' ||
				element.type.toUpperCase() === 'PASSWORD' ||
				element.type.toUpperCase() === 'FILE' ||
				element.type.toUpperCase() === 'SEARCH' ||
				element.type.toUpperCase() === 'EMAIL' ||
				element.type.toUpperCase() === 'NUMBER' ||
				element.type.toUpperCase() === 'DATE' )) ||
				element.tagName.toUpperCase() === 'TEXTAREA') {
				doPrevent = element.readOnly || element.disabled;
			}
			else {
				doPrevent = true;
			}
		}

		if (doPrevent) {
			event.preventDefault();
			ComponentActionsCreator.movePageRevision(this.props.pageId, -1);
		}
	},

	/**
	 * @method _onScroll
	 */
	_onScroll: function(ev) {
		this.channel.proxy.scroll(window.pageXOffset, window.pageYOffset);
	},

	//iframe commands handlers
	/**
	 * @method _textPropertyChange
	 */
	_textPropertyChange: function(parameters) {
		var id = parameters.componentId, path = ['context', parameters.path];
		var value = he.encode(parameters.value, {'allowUnsafeSymbols': true });
		if (this.comparer.isComponentDifferent(id, path, value)) {
			var component = this.comparer.componentUpdate(id, path, value);

			ComponentActionsCreator.updateComponentInstanceContext(component);
		}
	},

	/**
	 * @method _domState
	 */
	_domState: function(state) {
		ComponentActionsCreator.updatePageDomState(this.props.pageId, state);
	},
	//end iframe commands handlers

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		ComponentActionsCreator.loadPage(this.props.pageId);
		ComponentActionsCreator.loadComponentsMetadata();

		if(this.state.page && this.state.isIframeReady && !_.isEmpty(this.state.metadata)) {
			var diff = this.comparer.diff(this.state.page);

			if (_.some(diff.updated) || _.some(diff.removed)) {
				this._sendInject(diff.updated);
				this._sendRemove(diff.removed);

				this.comparer.update(this.state.page);
			}
		}

		if(!_.isEqual(_.get(prevState, 'activeComponentId'), this.state.activeComponentId)) {
			this.channel.proxy.activate({ componentId: this.state.activeComponentId });
		}

		if (!_.isEqual(_.get(prevState, 'openedComponentId'), this.state.openedComponentId)) {
			this.channel.proxy.expand({ componentId: this.state.openedComponentId });
		}
	},

	/**
	 * @method _sendInject
	 */
	_sendInject: function(instances) {
		var editMode = true;
		var components = ComponentBuilder.buildAll(instances, this.state.metadata, editMode, true);

		_.forEach(components, _.bind(this.channel.proxy.inject, this.channel.proxy));
	},

	/**
	 * @method _sendRemove
	 */
	_sendRemove: function(instances) {
		_(instances)
			.map(x => { return {componentId: x.id} })
			.forEach(_.bind(this.channel.proxy.remove, this.channel.proxy));
	},

	/**
	 * @method _onIframeLoad
	 */
	_onIframeLoad: function() {
		this.setState({ isIframeReady: true });
	},

	/**
	 * @method _onTextCommand
	 */
	_onTextCommand: function(command) {
		this.channel.proxy.text(command);
	},

	/**
	 * @method render
	 */
	render: function() {
		const css = [{ 'opacity-0': !this.state.isIframeReady }]

		return (<div className="theme" style={{ height: this.state.iframeHeight }}>
			<PageSystemLayer className={this.css(css)}
				onTextCommand={this._onTextCommand}
				pageId={this.props.pageId} />
			{!this.state.isIframeReady && <span className="loader"></span>}
			<iframe className={this.css(css)} ref={(iframe) => {this.iframe = iframe; }}
				name='page-editor-iframe' src='/iframe'
				onLoad={this._onIframeLoad}
				sandbox="allow-scripts allow-forms allow-same-origin"/>
		</div>);
	}
});

module.exports = PageEditor;
