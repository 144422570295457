import { map } from 'lodash';

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

const PixabaySearchResult = Immutable.Record({
	id: undefined,
	thumbnail_url: undefined,
	url: undefined
});

class PixabaySearchResultStore extends ReduceStore<Object> {
	getInitialState(): Object {
		return Immutable.List();
	}

	reduce(state: Object, action: Object): boolean {
		switch (action.actionType) {
			case RemixerConstants.RECEIVE_SEARCH_PIXABAY:
				if (!action.compact) {
					state = state.clear();
				}
				return state.push(...map(action.response.data.items, (entity) => new PixabaySearchResult(entity)));
			default: return state;
		}
	}
}

export default new PixabaySearchResultStore(AppDispatcher);
