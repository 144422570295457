'use strict';

import { defaults, forEachRight, get, last, initial } from 'lodash';

var React = require('react');

var FluxComponent = require('./FluxComponent');

var HigherOrderComponent = require('./HigherOrderComponent');

/** @module ComponentFactory
 * @requires lodash
 * @requires react
 * @requires FluxComponent
 * @requires HigherOrderComponent
 */
var ComponentFactory = {

	/**
	 * @method Config
	 */
	Config: function(options) {
		return defaults({}, options, this);
	},
	/**
	 * @method Create
	 */
	Create: function() {
		var component = last(arguments),
			stores = initial(arguments);

		var higherOrderComponents = get(this, 'higher-order-components', []);
		var pure = get(this, 'pure', false);

		var componentClass = React.createClass(new FluxComponent(component, stores, pure));

		forEachRight(higherOrderComponents, function(wrapper) {
			componentClass = HigherOrderComponent(wrapper, componentClass);
		});

		return componentClass;
	}
};



module.exports = ComponentFactory;
