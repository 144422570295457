'use strict';

var _ = require('lodash');

var ComponentInstance = require('../models/ComponentInstance');
var PageInstance = require('../models/PageInstance');
var Validator = require('../utils/Validator');

/** @module PageInstanceComparer
 * @requires lodash
 * @requires ComponentInstance
 * @requires PageInstance
 * @requires Validator
 */
function PageInstanceComparer() {
	var _empty = { components: {} },
		_page = _empty;

	var _clone = function(page) {
		var clone = _.clone(_.omit(page, 'components'));

		clone.components = _(page.components)
			.values()
			.map(function(component) {
				return new ComponentInstance(component); })
			.keyBy('id').value();

		return clone;
	};

	var _getExistanceDifference = function(first, second) {
		var ids = _.difference(_.keys(first.components), _.keys(second.components));
		var difference = _.values(_.pick(first.components, ids));

		return difference;
	};

	var _getRemoved = function(page) {
		return _getExistanceDifference(_page, page);
	};

	var _getUpdated = function(page) {
		var updated = _.filter(page.components, function(component, key) {
			return !_.isEqual(_page.components[key], component);
		});

		return updated;
	};

	var _equals = function(page) {
		return _.isEqual(_page.components, page.components);
	};

	this.empty = function empty() {
		return _page === _empty;
	};

	this.diff = function diff(page) {
		Validator.isInstanceOf(page, PageInstance);

		var updated = [],
			removed = [];

		if(!_equals(page)) {
			updated = _getUpdated(page);
			removed = _getRemoved(page);
		}

		return {
			updated: updated,
			removed: removed
		};
	};

	this.update = function update(page) {
		Validator.isInstanceOf(page, PageInstance);

		if(!_equals(page)) _page = _clone(page);
	};

	this.isComponentDifferent = function isComponentDifferent(id, path, value) {
		var currentValue = _.get(_page.components, _.flatten([id, path]));

		return !_.isEqual(currentValue, value);
	};

	this.componentUpdate = function componentUpdate(id, path, value) {
		_.set(_page.components, _.flatten([id, path]).join('.'), value);

		return _page.components[id];
	};
}

module.exports = PageInstanceComparer;
