'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var UserStore = require('../../stores/UserStore');
var ImportProgressStore = require('../../stores/ImportProgressStore');
var ImportHistoryStore = require('../../stores/ImportHistoryStore');
var ContentStore = require('../../stores/ContentStore');

var ContentTypes = require('../../constants/ContentTypes');
var ImportTypes = require('../../constants/ImportTypes');

var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var VimeoActionsCreator = require('../../actions/VimeoActionsCreator');
var UserInfoActionsCreator = require('../../actions/UserInfoActionsCreator');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');


/** @module VimeoImport
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ContentStore
 * @requires ContentTypes
 * @requires ImportTypes
 */
var VimeoImport = ComponentFactory.Create(UserStore, ImportProgressStore, ImportHistoryStore, ContentStore, {
	displayName: "VimeoImport",
	/**
	 * @method getState
	 */
	getState: function () {
		var route = this.route();
		var state = {
			path: route.path,
			autoStartFromPath: route.params && route.params.action === 'startImport'
				&& route.params.actionParam === ImportTypes.VIMEO.toString(),
			isUserAuthenticated: !!UserStore.getState('vimeo_connected'),
			importProgress: ImportProgressStore.getState(ImportTypes.VIMEO),
			ready: UserStore.getMetaState().filled && ImportProgressStore.getMetaState(ImportTypes.VIMEO).filled,
			importedFileCount: _(ContentStore.getState())
				.values()
				.flatten()
				.filter((x) => _.isEqual(x.import_type, ImportTypes.VIMEO))
				.filter((x) => _.isEqual(x.content_type, ContentTypes.VIMEO))
				.size()
		};

		state.loading = !state.ready || state.importProgress.progress;
		return state;
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		UserInfoActionsCreator.loadUserInfo();
		ComponentActionsCreator.loadImportHistory(ImportTypes.VIMEO);
		ContentActionsCreator.loadContent(ContentTypes.VIMEO);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function() {
		this._importAutoStart();
	},

	/**
	 * @method _startImport
	 */
	_startImport: function() {
		if(this.state.isUserAuthenticated) {
			ImportActionsCreator.startImport(ImportTypes.VIMEO);
		}
	},

	/**
	 * @method _importAutoStart
	 */
	_importAutoStart: function() {
		if (this.state.ready && this.state.autoStartFromPath &&
			!(this.state.importProgress.progress || this.state.importProgress.loading)) {
			this._startImport();
		}
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(e) {
		if (!this.state.ready) {
			return;
		}
		if(this.state.isUserAuthenticated) {
			this._startImport();
		} else {
			var path = this.state.path + (this.state.autoStartFromPath ? '' : startImportPath);
			VimeoActionsCreator.requestVimdoeAuthorizationUrl(path);
		}
	},

	/**
	 * @method _onMouseEnter
	 */
	_onMouseEnter: function() {
		this.setState({mouseOver: true});
	},

	/**
	 * @method _onMouseLeave
	 */
	_onMouseLeave: function() {
		this.setState({mouseOver: false});
	},

	/**
	 * @method render
	 */
	render: function () {
		var uploadedFileCount = this.state.importedFileCount;

		var css = {
			'vimeo': true,
			'active': this.state.isUserAuthenticated
		};

		var iconClassName = "mdi mdi-vimeo" + (this.state.loading ? " pulse" : "");
		var wrapperClassName;
		var output;

		if(_.get(this.props, "button", false)){
			output = (<div className="import-header-column import-header-column-button bg-overlay vimeo" onClick={this._onClick}>
				<div>
					<div className="show-for-large-up import-label" >
						<p >{this.props.title}</p>
						<h3 >{this.props.tagline}</h3>
					</div>
					<div className="import-icon-holder" >
						<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import Vimeo">
							<i className={iconClassName}></i>
						</div>
						<div className="import-icon-bubble-holder" >
							<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{uploadedFileCount}</p></div></div>
						</div>
					</div>
				</div>
			</div>);
		} else {
			wrapperClassName = 'media-source';
			output = (<div>
				<div className={"circle-icon " + this.css(css)} onClick={this._onClick} title="Import Vimeo">
					<i className={iconClassName}></i>
				</div>
				<div className="import-icon-bubble-holder" >
					<div className={"bubbleNumber " + this.css(css)} ><div className="bubbleLeft"></div><div className="bubbleRight"></div><div className="bubbleText"><p>{uploadedFileCount}</p></div></div>
				</div>
			</div>);
		}
		return (<div className={wrapperClassName}>{output}</div>);
	}
});

var startImportPath = '/startImport/' + ImportTypes.VIMEO;

module.exports = VimeoImport;
