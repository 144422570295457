import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

class TrialHideNoticeStore extends ReduceStore {
	getInitialState() {
		return Immutable.List();
	}

	reduce(state, action) {
		switch (action.actionType) {
			case RemixerConstants.HIDE_TRIAL_PUBLISH_NOTIFICATION:
				return Immutable.List.of(...state, action.websiteId);
			default:
				return state;
		}
	}
}

export default new TrialHideNoticeStore(AppDispatcher);
