'use strict';

import { map, keyBy, assign } from 'lodash';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

var ComponentInstance = require('../models/ComponentInstance');
var PageInstance = require('../models/PageInstance');
var Mapper = require('../utils/Mapper');

/** @module PageStore
 * @requires lodash
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires ComponentInstance
 * @requires PageInstance
 * @requires Mapper
 */
var PageStore = StoreFactory.Create({
	/**
	 * @method _resetPageByComponent
	 */
	_resetPageByComponent: function(action) {
		var instance = new ComponentInstance(action.component);
		this._reset(instance.pageId);
	},

	/**
	 * @method _updateComponentInstanceContext
	 */
	_updateComponentInstanceContext: function(action) {
		var instance = new ComponentInstance(action.componentInstance);
		this._set(instance.pageId, 'components', instance.id, instance);
	},

	/**
	 * @method _removeComponentInstance
	 */
	_removeComponentInstance: function(action) {
		var instance = action.componentInstance;
		this._remove(instance.pageId, 'components', instance.id);
	},

	/**
	 * @method _resetPageFromComponent
	 */
	_resetPageFromComponent: function(action) {
		this._reset(action.component.pageId);
	},

	/**
	 * @method _setPage
	 */
	_setPage: function (action) {
		this._set(action.page.id, new PageInstance(action.page));
	},

	/**
	 * @method _setPageLoading
	 */
	_setPageLoading: function(action) {
		this._filling(action.pageId);
	},

	/**
	 * @method _setPageFailed
	 */
	_setPageFailed: function(action) {
		this._failed(action.pageId);
	},

	/**
	 * @method _setComponentContextProperty
	 */
	_setComponentContextProperty: function(action) {
		if (!this._get('active')) return;

		this._set('active', 'context', action.propertyName, action.propertyValue);
	},

	/**
	 * @method _resetPage
	 */
	_resetPage: function(action) {
		this._reset(action.pageId);
	},

	/**
	 * @method _setFilling
	 */
	_setFilling: function(action) {
		this._filling(action.page ? action.page.id : void 0);
	},

	/**
	 * @method _setData
	 */
	_setData: function(action) {
		var pages = map(action.pages, function(page) {
			return new PageInstance(page);
		});

		this._set(keyBy(pages, 'id'));
	},

	/**
	 * @method _setPageProps
	 */
	_setPageProps: function(action) {
		action = Mapper.toCamelCase(action);
		var pageId = action.pageId;
		var page = assign(this._get(pageId), action.pageProperties);
		this._set(pageId, page);
	},

	/**
	 * @method _setFailed
	 */
	_setFailed: function(action) {
		this._failed(action.page ? action.page.id : void 0);
	},


	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		handlers[RemixerConstants.RECEIVE_CREATE_COMPONENT_INSTANCE] = this._resetPageByComponent;
		handlers[RemixerConstants.RECEIVE_UPDATE_COMPONENT_INSTANCE] = this._resetPageByComponent;
		handlers[RemixerConstants.UPDATE_COMPONENT_INSTANCE_CONTEXT] = this._updateComponentInstanceContext;

		handlers[RemixerConstants.RECEIVE_REMOVE_COMPONENT_INSTANCE] = this._resetPageFromComponent;
		handlers[RemixerConstants.REMOVE_COMPONENT_INSTANCE] = this._removeComponentInstance;

		handlers[RemixerConstants.LOAD_PAGE] = this._setPageLoading;
		handlers[RemixerConstants.RECEIVE_PAGE] = this._setPage;
		handlers[RemixerConstants.RECEIVE_PAGE_FAIL] = this._setPageFailed;

		handlers[RemixerConstants.SET_COMPONENT_CONTEXT_PROPERTY] = this._setComponentContextProperty;

		handlers[RemixerConstants.RECEIVE_MOVE_PAGE_REVISION] = this._resetPage;

		handlers[RemixerConstants.RECEIVE_INHERIT_SKIN] = this._resetPage;

		handlers[RemixerConstants.LOAD_ALL_PAGES] = this._setFilling;
		handlers[RemixerConstants.RECEIVE_ALL_PAGES] = this._setData;
		handlers[RemixerConstants.RECEIVE_ALL_PAGES_FAIL] = this._setFailed;

		handlers[RemixerConstants.UPDATE_PAGE] = this._setFilling;
		handlers[RemixerConstants.RECEIVE_UPDATE_PAGE] = this._setPage;
		handlers[RemixerConstants.RECEIVE_UPDATE_PAGE_PROPS] = this._setPageProps;
		handlers[RemixerConstants.RECEIVE_UPDATE_PAGE_FAIL] = this._setFailed;

		handlers[RemixerConstants.RECEIVE_REPLACE_COMPONENT_INSTANCE] = this._resetPage;

		return handlers;
	}
});

module.exports = PageStore;
