'use strict';

import { map } from 'lodash';

var Validator = require('../utils/Validator');
var Shapes = require('../utils/Shapes');

var PropertyInfo = require('./PropertyInfo');

function _convert(propertyInfo) {
	return new PropertyInfo(propertyInfo);
}

/** @module ComponentDomState
 * @requires lodash
 * @requires Validator
 * @requires Shapes
 * @requires PropertyInfo
 */
function ComponentDomState(dto) {
	Validator.isMatch(dto, '\\d+', ['componentId', 'position']);
	Validator.isTypeOf(dto, 'string', ['name']);
	Validator.isTypeOf(dto, 'object', ['rect']);
	Validator.isArrayOfType(dto, 'object', ['properties']);

	this.componentId = +dto.componentId;
	this.position = +dto.position;
	this.name = dto.name;
	this.rectangle = new Shapes.Rectangle(dto.rect);
	this.properties = map(dto.properties, _convert);
}

module.exports = ComponentDomState;
