'use strict';

import { partial, identity, isBoolean, isEqual, toArray, bind, defaults, get, isEmpty, invokeMap } from 'lodash';
var classnames = require('classnames');

var FluxStore = require('./FluxStore');

var RouteStore = require('../stores/RouteStore');

var Validator = require('../utils/Validator');
var i18n = require('../utils/i18n');

var _empty = partial(identity, undefined);

/**
 * @method _shouldComponentUpdate
 */
var _shouldComponentUpdate = function(nextProp, nextState) {
	var result = this._base('shouldComponentUpdate', toArray(arguments));
	if (isBoolean(result)) return result;

	return !isEqual(nextProp, this.props) || !isEqual(nextState, this.state);
};

/** @module FluxComponent
 * @requires lodash
 * @requires classnames
 * @requires FluxStore
 * @requires RouteStore
 * @requires Validator
 * @requires i18n
 */
function FluxComponent(component, stores, pure) {
	Validator.isTypeOf(component, 'object');
	var _component = component;

	Validator.isArrayOfInstances(stores, FluxStore);
	var _stores = stores;

	this.route = bind(RouteStore.getState, RouteStore, 'route');
	this.css = classnames;

	this._ = bind(i18n.t, i18n);

	/**
	 * @method _base
	 */
	this._base = function(method, params) {
		return get(_component, method, _empty).apply(this, params);
	};

	if (!isEmpty(_stores)) {
	/**
	 * @method getInitialState
	 */
		this.getInitialState = function() {
			return this._base('getInitialState') || this.getState();
		};

		/**
	 	 * @method componentDidMount
	 	 */
		this.componentDidMount = function() {
			this._base('componentDidMount');

			invokeMap(_stores, 'addChangeListener', this._onChange);

			this.componentDidUpdate();
		};

		/**
	 	 * @method componentWillUnmount
	 	 */
		this.componentWillUnmount = function() {
			this._base('componentWillUnmount');

			invokeMap(_stores, 'removeChangeListener', this._onChange);
		};

		/**
	 	 * @method componentDidUpdate
	 	 */
		this.componentDidUpdate = function() {
			this._base('componentDidUpdate', toArray(arguments));
		};

		/**
	 	 * @method getState
	 	 */
		this.getState = function() {
			return this._base('getState', toArray(arguments));
		};

		/**
	 	 * @method _onChange
	 	 */
		this._onChange = function() {
			if(this.isMounted()) {
				this._base('_onChange');
				var state = this.getState();
				if (isEmpty(state)){
					this.forceUpdate();
				} else {
					this.setState(state);
				}
			}
		};

		if (pure) {
			this.shouldComponentUpdate = _shouldComponentUpdate;
		}
	} else {
		this.shouldComponentUpdate = _shouldComponentUpdate;
	}

	//add if not exists;
	defaults(this, _component);
}

module.exports = FluxComponent;
