'use strict';

import { omit } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var Shapes = require('../../utils/Shapes');

/** @module Rectangle
 * @requires react
 * @requires ComponentFactory
 * @requires Shapes
 */
var Rectangle = ComponentFactory.Create({
	displayName: "Rectangle",
	propTypes: {
		rectangle: React.PropTypes.instanceOf(Shapes.Rectangle)
	},

	/**
	 * @method render
	 */
	render: function() {
		if(!this.props.rectangle) return null;

		return (<div
			{...omit(this.props, ["rectangle", "className", "style", "children"])}
			className={this.css("rectangle", this.props.className)}
			style={this.props.rectangle.toObject()}>
			{this.props.children}
		</div>);
	}
});

module.exports = Rectangle;
