"use strict";

import { map, keyBy } from 'lodash';

var ComponentInstance = require('./ComponentInstance');
var Validator = require('../utils/Validator');
var Mapper = require('../utils/Mapper');

/**
 * @method convert
 */
function convert(component) {
	return new ComponentInstance(component);
}

/** @module PageInstance
 * @requires lodash
 * @requires ComponentInstance
 * @requires Validator
 * @requires Mapper
 */
function PageInstance(dto) {
	var components = dto.components;
	dto = Mapper.toCamelCase(dto);
	Validator.isTypeOf(dto, "number", ["id"]);
	//	Validator.isTypeOf(dto, "string", ["name", "description", "title"]);
	dto.components = components || [];
	Validator.isInstanceOf(dto, Array, ["components"]);
	dto.version = dto.version || "0.0.0";
	Validator.isVersion(dto, ["version"]);

	this.id = dto.id;
	this.name = dto.name;
	this.version = dto.version;
	this.pageThumbnailUrl = dto.pageThumbnailUrl;
	this.updated = dto.updated;

	this.components = keyBy(map(dto.components, convert), 'id');
	/**
	 * @method getReferences
	 */
	this.getReferences = function() {
		return map(this.components, 'reference');
	};
}

module.exports = PageInstance;
