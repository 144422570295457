'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

/** @module MarkdownToolbar
 * @requires react
 * @requires ComponentFactory
 */
var MarkdownToolbar = ComponentFactory.Create({
	displayName: "MarkdownToolbar",
	/**
	 * @method newMarkdown
	 */
	newMarkdown: function() {
		this.route().transitionTo('contentAction', { tab: 'markdown', action: 'new' });
	},
	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="row import-header">
				<div className="small-3 small-centered columns">
					<div className="import-header-column import-header-column-button bg-overlay flickr" onClick={this.newMarkdown}>
						<div>
							<div className="show-for-large-up import-label" >
								<p>{this._("New Markdown")}</p>
								<h3>{this._("# your content")}</h3>
							</div>
							<div className="import-icon-holder" >
								<div className="circle-icon ">
									<i className="mdi mdi-markdown"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
});

module.exports = MarkdownToolbar;
