import React from 'react';

export default class Paginator extends React.Component {
	static propTypes = {
		page: React.PropTypes.number,
		perPage: React.PropTypes.number,
		total: React.PropTypes.number.isRequired,
		items: React.PropTypes.number.isRequired,
		onChange: React.PropTypes.func
	};
	static defaultProps = {
		page: 1,
		perPage: 20,
		onChange: React.PropTypes.func
	};
	_totalPages() {
		const {total, perPage} = this.props;
		return Math.ceil(total/perPage);
	}
	_nextPage() {
		const {page} = this.props;
		return page < this._totalPages() && page + 1;
	}
	_prevPage() {
		const {page} = this.props;
		return page > 1 && page - 1;
	}
	_handlePageChange = (page) => {
		this.props.onChange(page);
	};

	render() {
		const pages = [];
		for (let page = 1, totalPages = this._totalPages(); page <= totalPages; page++) {
			pages.push(<li key={page} className="page" onClick={() => this._handlePageChange(page)}>{page}</li>);
		}
		return (
			<ul className="paginator">
				{pages}
			</ul>
		);
	}
}
