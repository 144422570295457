"use strict";

var Validator = require('../utils/Validator');
var Mapper = require('../utils/Mapper');

/** @module ComponentReference
 * @requires Validator
 * @requires Mapper
 */
function ComponentReference(dto) {
	dto = Mapper.toCamelCase(dto);
	if (dto.reference) dto = dto.reference;

	Validator.isTypeOf(dto, "string", ["name"]);

	dto.version = dto.version || "0.0.0";
	Validator.isVersion(dto, ["version"]);

	this.name = dto.name;
	this.version = dto.version;
}

module.exports = ComponentReference;
