'use strict';

import { filter, some, chain, forEach, trim, pick, isElement } from 'lodash';

var DomElementUtils = require('../utils/DomElementUtils');

var bodyElement = document.querySelector('body');

/** @module TextManipulationUtils
 * @requires lodash
 * @requires DomElementUtils
 */

function rangeSelectsSingleNode(range) {
	var startNode = range.startContainer;
	return startNode === range.endContainer &&
		startNode.hasChildNodes() &&
		range.endOffset === range.startOffset + 1;
}
function getBaseComponentFontSize() {
	var bodyStyles = DomElementUtils.GetCompiledStyle(bodyElement, ['font-size']);
	if (bodyStyles) {
		return {
			fontsize: + trim(bodyStyles['font-size'], 'px')
		}
	}
}

function getRange() {
	var selection = document.getSelection(),
		range = selection.rangeCount && selection.getRangeAt(0);

	if (range) return range;
}

function isElementNode(node) {
	return node.nodeName !== "#text";
}

function isMixedSelection() {
	var range = getRange();
	if (!range) return;

	var elements = filter(range.commonAncestorContainer.childNodes, isElementNode);

	return some(elements);
}

function getSelectedElement() {
	if (isMixedSelection()) return;

	var selectedElement = null,
		range = getRange();

	if(!range) return selectedElement;

	if (rangeSelectsSingleNode(range)) {
		selectedElement = range.startContainer.childNodes[range.startOffset];
	} else {
		selectedElement = range.startContainer;
	}

	return selectedElement.nodeName === "#text" ?
		selectedElement.parentNode :
		selectedElement;
}

function toHex(integer) {
	var hex = (+integer).toString(16);
	return hex.length === 1 ? "0" + hex : hex;
}

function convertRgbToHex(r, g, b) {
	return "#" + toHex(r) + toHex(g) + toHex(b);
}

function convertHslToHex(hue, saturation, lightness) {
	// based on algorithm from http://en.wikipedia.org/wiki/HSL_and_HSV#Converting_to_RGB
	if( hue == undefined ){
		return [0, 0, 0];
	}

	saturation = parseInt(saturation) / 100
	lightness = parseInt(lightness) / 100
	var chroma = (1 - Math.abs((2 * lightness) - 1)) * saturation;
	var huePrime = hue / 60;
	var secondComponent = chroma * (1 - Math.abs((huePrime % 2) - 1));

	huePrime = Math.floor(huePrime);
	var red;
	var green;
	var blue;

	if( huePrime === 0 ){
		red = chroma;
		green = secondComponent;
		blue = 0;
	}else if( huePrime === 1 ){
		red = secondComponent;
		green = chroma;
		blue = 0;
	}else if( huePrime === 2 ){
		red = 0;
		green = chroma;
		blue = secondComponent;
	}else if( huePrime === 3 ){
		red = 0;
		green = secondComponent;
		blue = chroma;
	}else if( huePrime === 4 ){
		red = secondComponent;
		green = 0;
		blue = chroma;
	}else if( huePrime === 5 ){
		red = chroma;
		green = 0;
		blue = secondComponent;
	}

	var lightnessAdjustment = lightness - (chroma / 2);
	red += lightnessAdjustment;
	green += lightnessAdjustment;
	blue += lightnessAdjustment;

	return "#" + toHex(Math.round(red * 255)) + toHex(Math.round(green * 255)) + toHex(Math.round(blue * 255));
}

function parseStyleColor(color) {
	var colorParams = color.match(/\d+/g);
	if (colorParams && colorParams.length !== 3) return color
	if (color.match(/rgb/g)) {
		return convertRgbToHex.apply(null, colorParams);
	}
	if (color.match(/hsl/g)) {
		return convertHslToHex.apply(null, colorParams);
	}

}

function parseStyleFontFamily(family) {
	return chain(family)
		.words(/[^,]+/g)
		.first()
		.trim(" '")
		.value();
}

function getSelectedTextState() {
	var element = getSelectedElement();
	var styles = DomElementUtils.GetCompiledStyle(element, ['font-size', 'font-family', 'font-weight', 'font-style', 'color', 'text-shadow']);
	if(styles) {
		return {
			bold: styles['font-weight'] === 'bold' || +styles['font-weight'] >= 700,
			italic: styles['font-style'] === 'italic',
			fontsize: _getFontValueInPixels(styles),
			fontname: parseStyleFontFamily(styles['font-family']),
			forecolor: parseStyleColor(styles.color),
			link: element.getAttribute('href'),
			range: pick(getRange(), 'collapsed', 'endOffset', 'startOffset'),
			textShadow: !(styles['text-shadow'] === 'none')
		};
	}
}

function _getFontValueInPixels(styles) {
	var curentFontSize = styles['font-size']
	if(curentFontSize.indexOf('px')!==-1) {
		return trim(curentFontSize, 'px')
	}
	var { fontsize } = getBaseComponentFontSize()
	return trim(curentFontSize, 'rem') * fontsize
}


function replaceWithOwnChildren(el) {
	var parent = el.parentNode;
	while (el.hasChildNodes()) {
		parent.insertBefore(el.firstChild, el);
	}
	parent.removeChild(el);
}

function getSelectedContainer() {
	var range = getRange();
	if (!range) return;

	var node = range.commonAncestorContainer;
	while(node && !(isElementNode(node) && node.getAttribute('contenteditable'))) {
		node = node.parentNode;
	}
	return node;
}

function getSelection() {
	var range = getRange();
	if (!range) return;

	var container = getSelectedContainer();
	var preSelectionRange = range.cloneRange();
	preSelectionRange.selectNodeContents(container);
	preSelectionRange.setEnd(range.startContainer, range.startOffset);

	var start = preSelectionRange.toString().length;
	var end = start + range.toString().length;

	return {
		container: container,
		start: start,
		end: end
	};
}

function setSelection(selection) {
	var charIndex = 0,
		container = selection.container,
		range = document.createRange(),
		nodeStack = [container],
		node,
		foundStart = false,
		stop = false;

	range.setStart(container, 0);
	range.collapse(true);

	while (!stop && (node = nodeStack.pop())) {
		if (node.nodeType === 3) {
			var nextCharIndex = charIndex + node.length;
			if (!foundStart && selection.start >= charIndex && selection.start <= nextCharIndex) {
				range.setStart(node, selection.start - charIndex);
				foundStart = true;
			}
			if (foundStart && selection.end >= charIndex && selection.end <= nextCharIndex) {
				range.setEnd(node, selection.end - charIndex);
				stop = true;
			}
			charIndex = nextCharIndex;
		} else {
			var i = node.childNodes.length;
			while (i--) {
				nodeStack.push(node.childNodes[i]);
			}
		}
	}

	var restored = window.getSelection();
	restored.removeAllRanges();
	restored.addRange(range);
}

function removeSelectionFormatting() {
	var range = getRange();
	if (!range) return;

	var selection = getSelection();
	replaceWithOwnChildren(range.commonAncestorContainer);
	setSelection(selection);
}

function getLinksInSelection() {
	var selectedLinks = [];

	var range = getRange();
	if (!range) return;

	var linkRange = document.createRange();
	var container = range.commonAncestorContainer;
	if (container.nodeType !== 1) {
		container = container.parentNode;
	}
	if (container.nodeName.toLowerCase() === "a") {
		selectedLinks.push(container);
	} else {
		forEach(container.getElementsByTagName("a"), function(link) {
			linkRange.selectNodeContents(link);
			if (linkRange.compareBoundaryPoints(range.END_TO_START, range) < 1 && linkRange.compareBoundaryPoints(range.START_TO_END, range) > -1) {
				selectedLinks.push(link);
			}
		});
	}
	linkRange.detach();

	return selectedLinks;
}

function removeLinks() {
	var selection = getSelection();
	forEach(getLinksInSelection(), replaceWithOwnChildren);
	triggerInputEvent();
	setSelection(selection);
}

function triggerInputEvent() {
	var selection = getSelection();
	var event = new Event('input', {
		'bubbles': true,
		'cancelable': true
	});
	selection.container.dispatchEvent(event);
}


function removeSelection() {
	window.getSelection().removeAllRanges();
}

function selectAllTextNodes(element, selection) {
	if(element.nodeName === "#text") {
		let range = document.createRange();
		range.selectNodeContents(element);
		let count = selection.rangeCount;
		selection.addRange(range);

		// Chrome does not support multi-range selection and even does not raise any exception.
		// So we check the status using rangeCount
		if (count === selection.rangeCount) {
			throw 'Discontiguous selection is not supported.';
		}
	}

	forEach(element.childNodes, child => {
		selectAllTextNodes(child, selection);}
	);
}

function selectAll(element) {
	if(!isElement(element)) return;
	let sel = window.getSelection();
	try {
		sel.removeAllRanges();
		selectAllTextNodes(element, sel);
		if (sel.type === 'None') throw "no #text entry found need to create a new range"
	} catch (exc){
		sel.removeAllRanges();
		let range = document.createRange();
		range.selectNodeContents(element);
		sel.addRange(range);
	}
}

function pasteHtml(html, selectPastedContent) {
	var sel, range;
	if (window.getSelection) {
		// IE9 and non-IE
		sel = window.getSelection();
		if (sel.getRangeAt && sel.rangeCount) {
			range = sel.getRangeAt(0);
			range.deleteContents();

			// Range.createContextualFragment() would be useful here but is
			// only relatively recently standardized and is not supported in
			// some browsers (IE9, for one)
			var el = document.createElement("div");
			el.innerHTML = html;
			var frag = document.createDocumentFragment(), node, lastNode;
			while ( (node = el.firstChild) ) {
				lastNode = frag.appendChild(node);
			}
			var firstNode = frag.firstChild;
			range.insertNode(frag);

			// Preserve the selection
			if (lastNode) {
				range = range.cloneRange();
				range.setStartAfter(lastNode);
				if (selectPastedContent) {
					range.setStartBefore(firstNode);
				} else {
					range.collapse(true);
				}
				sel.removeAllRanges();
				sel.addRange(range);
			}
		}
	} else if ( (sel = document.selection) && sel.type != "Control") {
		// IE < 9
		var originalRange = sel.createRange();
		originalRange.collapse(true);
		sel.createRange().pasteHTML(html);
		if (selectPastedContent) {
			range = sel.createRange();
			range.setEndPoint("StartToStart", originalRange);
			range.select();
		}
	}
}

function fixExecuteCommandInFireFox(execCommandCallBack){
	// see https://bugzilla.mozilla.org/show_bug.cgi?id=889940
	let selection = window.getSelection();
	let range = selection.getRangeAt(0);
	let element = range.endContainer.parentNode;

	// add a dummy span element
	let span = document.createElement("span");
	element.appendChild(span);

	try {
		execCommandCallBack();
	} finally {
		element.removeChild(span);
	}
}

module.exports = {
	GetSelectedElement: getSelectedElement,
	GetSelectedTextState: getSelectedTextState,
	RemoveLinks: removeLinks,
	RemoveSelectionFormatting: removeSelectionFormatting,
	GetSelectedContainer: getSelectedContainer,
	GetBaseComponentFontSize: getBaseComponentFontSize,
	GetSelection: getSelection,
	SetSelection: setSelection,
	RemoveSelection: removeSelection,
	SelectAll: selectAll,
	PasteHTML: pasteHtml,
	FixExecuteCommandInFireFox: fixExecuteCommandInFireFox,
	TriggerInputEvent: triggerInputEvent,
	ParseStyleColor: parseStyleColor
};
