'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var HttpLink = ComponentFactory.Create( {
	displayName: "HttpLink",
	propTypes: {
		handleChange: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		let prefix = /^http[s]?/.exec(this.props.currentLinkURL)
		return {
			curr_url: this.props.currentLinkURL.replace(/^http[s]?:\/\//, '') || '',
			prefix: (Array.isArray(prefix) && prefix[0]) || 'https'
		};
	},
	_handlePrefixChange: function() {
		var newPrefix= this.state.prefix=='http'?'https':'http';
		this.setState({prefix:newPrefix} );
		var url = newPrefix + '://' + this.state.curr_url;
		this.props.handleChange(url);
	},

	_handleInputChange: function(e) {
		var value = e.target.value;
		var url = this.state.prefix+'://'+value;
		this.setState({curr_url: value})
		this.props.handleChange(url);
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="url-input">
				<span className="link-prefix" onClick={this._handlePrefixChange}>{this.state.prefix}://</span>
				<input key="URL" type="text" placeholder='www.somesite.com' value={this.state.curr_url} onChange={this._handleInputChange}></input>
			</div>
		)

	}
});

module.exports = HttpLink;
