import { get, isFunction, defer } from 'lodash';
var $ = require('jquery');
var ReactDOM = require('react-dom');

/**
 * @module IframeTools
 * @requires lodash
 * @requires jquery
 * @requires react-dom
 */

/**
 * @method RenderFrameContents
 */
function RenderFrameContents(html, iframe, callback) {
	if( !html || !iframe ) { return; }

	var doc = get(iframe, ['contentWindow','document'], undefined);
	if ( iframe === undefined || doc === undefined) {
		return;
	}
	// the Edge browser will not assign the proper proportions to the iframe
	// if the iframe container is hidden. to prevent this issue the line below
	// will test if the iframe is visible before trying to write content to it
	if(doc.readyState === 'complete' && $(ReactDOM.findDOMNode(iframe)).is(':visible')) {
		doc.open();
		doc.write(html);
		doc.close();
		if ( isFunction(callback) ) {
			callback();
		}
	} else {
		defer(RenderFrameContents, html, iframe, callback);
	}
}

module.exports = {
	RenderFrameContents: RenderFrameContents
};
