'use strict';

import { values } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ContentMananementTabPane = require('../content-management/ContentMananementTabPane.react');
var ContentToolbar = require('../content-management/ContentToolbar.react');
var ContentContainer = require('../content-management/ContentContainer.react');

var ContentManagementTabs = require('../../constants/ContentManagementTabs');

/** @module ContentManagement
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ContentMananementTabPane
 * @requires ContentToolbar
 * @requires ContentContainer
 * @requires ContentManagementTabs
 */
var ContentManagement = ComponentFactory.Create({
	displayName: "ContentManagement",
	propTypes: {
		tab: React.PropTypes.oneOf(values(ContentManagementTabs)).isRequired,
		contentId: React.PropTypes.number,
		action: React.PropTypes.string
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className={this.css("row content-management", (this.props.contentId || this.props.action ? "no-scroll" : ""))}>
				<div className="small-12 columns text-center">
					<h1>{this._("Manage Content")}</h1>
				</div>
				<div className="small-12 columns text-center">
					<h2>{this._("all your media in one place")}</h2>
				</div>
				<div className="small-12 columns">
					<ContentMananementTabPane active={this.props.tab} />
				</div>
				<div className="small-12 columns">
					<ContentToolbar strategy={this.props.tab} />
				</div>
				<div className="small-12 columns content">
					<ContentContainer
						strategy={this.props.tab}
						contentId={this.props.contentId}
						action={this.props.action} />
				</div>
			</div>
		);
	}
});

module.exports = ContentManagement;
