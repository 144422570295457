'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module ConfigServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var ConfigServerActionsCreator = {
	/**
	 * @method receiveConfig
	 */
	receiveConfig: function(result) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONFIG,
			config: result.data
		});
	},
	/**
	 * @method receiveConfigFail
	 */
	receiveConfigFail: function(error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CONFIG_FAILS,
			message: "could not get config"
		});
	}
};

module.exports = ConfigServerActionsCreator;
