'use strict';
var RemixerConstants = require('../constants/RemixerConstants');
var AppDispatcher = require('../dispatcher/AppDispatcher');


/** @module ExportWordPressServerActionsCreator
 * @requires RemixerConstants
 * @requires AppDispatcher
 */
var ExportWordPressServerActionsCreator = {
	/**
	 * @method receiveExportWordPress
	 */
	receiveExportWordPress: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_WORDPRESS_EXPORT,
			website: response.data
		});
	},
	/**
	 * @method receiveExportWordPressFailed
	 */
	receiveExportWordPressFailed: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_WORDPRESS_EXPORT_FAILED,
			website: response.data
		});
	},
	/**
	 * @method receiveLoginWordPressAdmin
	 */
	receiveLoginWordPressAdmin: function(response, newWindow) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_LOGIN_WORDPRESS_ADMIN,
			data: response.data
		});
		newWindow.location = response.data.url;
	},
	/**
	 * @method receiveLoginWordPressAdminFailed
	 */
	receiveLoginWordPressAdminFailed: function(response, url, newWindow) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_LOGIN_WORDPRESS_ADMIN_FAILED,
			data: response.data
		});
		newWindow.location = url;
	}
};

module.exports = ExportWordPressServerActionsCreator;
