'use strict';

import { partial } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var PreloadedImg = require('../../common/PreloadedImg.react');

/** @module ThumbnailImage
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires PreloadedImg
 */
var ThumbnailImage = ComponentFactory.Create({
	displayName: "ThumbnailImage",
	propTypes: {
		contentItem: React.PropTypes.object.isRequired,
		picked: React.PropTypes.bool,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	/**
	 * @method _isImporting
	 */
	_isImporting: function() {
		return !(this.props.contentItem.thumbnail_url || this.props.contentItem.url);
	},

	/**
	 * @method render
	 */
	render: function() {
		var importing = this._isImporting();
		var url = this.props.contentItem.thumbnail_url || this.props.contentItem.url;
		return (
			<PreloadedImg
				onClick={partial(this.props.onPick, this.props.contentItem)}
				className={this.css("image-library-thumbnail image-thumbnail", {"picked": this.props.picked, importing: importing})}
				imageUrl={url}
				showLoadingPlaceholder={false}
				component="div"
			>
			</PreloadedImg>
		);
	}
});

module.exports = ThumbnailImage;
