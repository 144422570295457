'use strict';

import { isEqual, find, has } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');
var Switcher = require('./Switcher.react');

var DEFAULT_VALUE = '---';

var defaultSelected = function(component) {
	return (component || DEFAULT_VALUE);
};
	/** @module DropDown
	 * @requires lodash
	 * @requires react
	 * @requires ComponentFactory
	 * @requires Switcher
	 */
var DropDown = ComponentFactory.Create({
	displayName: "DropDown",
	propTypes: {
		onChange: React.PropTypes.func,
		selected: React.PropTypes.func
	},
	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {selected: defaultSelected};
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return { active: this.props.active };
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		var isChildrenChange = !isEqual(props.children, this.props.children);
		if(isChildrenChange && this.state.active){
			var key = this.state.active.key;
			this.setState({ active: find(this.props.children, {'key': key})});
		}
		var isActiveChange = !isEqual(this.props.active, this.state.active);
		var isPropsChange = !isEqual(props.active, this.props.active);
		if (isPropsChange && isActiveChange) {
			this.setState({ active: this.props.active });
		}
	},

	/**
	 * @method _onSelectionChange
	 */
	_onSelectionChange: function(component) {
		this.setState({ active: component });
		if(this.props.onChange) this.props.onChange(component);
	},

	/**
	 * @method render
	 */
	render: function () {
		return (
			<div className="dropdown">
				<div className="selected">
					{this.props.selected(this.state.active)}
					<div className="arrow"></div>
				</div>
				<div className="options">
					<Switcher active={this.state.active} ignoreInitialChange={has(this.props, 'active')} onChange={this._onSelectionChange}>
						{this.props.children}
					</Switcher>
				</div>
			</div>
		);
	}
});

module.exports = DropDown;
