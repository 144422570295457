'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var WebAPI = require('../utils/WebAPI');

/** @module SoundcloudActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var SoundcloudActionsCreator = {
	/**
	 * @method requestSoundcloudAuthorizationUrl
	 */
	requestSoundcloudAuthorizationUrl: function(returnToUrl) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REQUEST_SOUNDCLOUD_AUTHORIZATION_URL,
			returnTo: returnToUrl
		},
		WebAPI.getSoundcloudAuthorizationUrl, [returnToUrl]);
	}
};

module.exports = SoundcloudActionsCreator;
