import Sitetailor from 'sitetailor-widget-ui'
import React from 'react';
import { split, defer } from 'lodash';
import SiteTailorActionCreator from '../../actions/SiteTailorActionCreator';
var UserInfoActionsCreator = require('../../actions/UserInfoActionsCreator');

var RouteStore = require('../../stores/RouteStore');
var FlashMessageActionsCreator = require('../../actions/FlashMessageActionsCreator');
var SiteTailorStore = require('../../stores/SiteTailorStore');

import 'sitetailor-widget-ui/dist/widget.css'
class Tailor extends React.Component {
	constructor(props) {
		super(props)
		this.tailor = new Sitetailor()
	}
	static getStores() {
		return [
			SiteTailorStore
		];
	}
	static _getState(props) {
		return {
			load: SiteTailorStore.getLoadStatus()
		};
	}

	static calculateState(prevState, props) {
		return Tailor._getState(props);
	}

	componentWillReceiveProps(nextProps) {
		this.setState(Tailor._getState(nextProps));
	}

	componentDidMount() {
		SiteTailorStore.addChangeListener(this._onChange);
		this.initSitetailor()
	}
	initSitetailor() {
		this.tailor.init(this.mountPoint, {
			api_url: '/api/v1',
			ndn_url: '/rpc',
			login_redirect_url: split(window.LOGIN_URL, '?')[0],
			remixer_login_url: window.LOGIN_URL,
			remixer_tos_url: 'https://www.dreamhost.com/legal/',
			on_user_created_fails: this.onUserCreatedFails,
			on_user_loged_in_fails: this.onUserLoginFails,
			on_sitetailor_navigation_change: this.onSiteTailoreLastStep,
			on_site_tailor_and_start_again: this.onSaveAndStartAgain,
			on_user_created: this.onUserCreated,
			on_user_loged_in: this.onLogedIn,
			on_site_tailor: this.onSiteTailored,
			on_sitetailor_last_step: this.onSiteTailoreLastStep,
			on_user_loged_in_and_export: this.goToExportPageAfterLoginOrSignUp,
			on_new_user_and_export: this.goToExportPageAfterLoginOrSignUp,
			on_something_fails: this.onFails,
			save_and_export: this.goToExportPage,
			analytics_url: '/analytics/sitetailor',
			google_analytics_url: '/g-analytics/signup/create%20account'
		})
	}

	destroySitetailor() {
		SiteTailorActionCreator.setIsNeedToReload(false);
		SiteTailorActionCreator.setSitetailorStepNumber('');
		this.tailor.destroy(this.mountPoint)
	}
	componentDidUpdate() {
		if (this.state.load.stepName === 'Publish' && this.state.load.load === true) {
			this.destroySitetailor();
			this.initSitetailor();
		}
	}
	componentWillUnmount() {
		SiteTailorStore.removeChangeListener(this._onChange);
		this.destroySitetailor();
	}
	_getState() {
		return {
			load: SiteTailorStore.getLoadStatus()
		};
	}
	_updateState = () => {
		this.setState(this._getState());
	}
	_onChange = () => {
		defer(this._updateState);
	}
	onSiteTailoreLastStep = (step) => {
		SiteTailorActionCreator.setSitetailorStepNumber(step);
	}
	onSiteTailored = (website) => {
		RouteStore.getState('route')
			.transitionTo(
				'layout',
				{
					websiteId: website.id,
					pageProjectId: website.main_page_project_id
				})
	}
	goToExportPage = (website) => {
		RouteStore.getState('route')
			.transitionTo(
				'wordpressExportSettings',
				{
					websiteId: website.id
				})
	}
	goToExportPageAfterLoginOrSignUp = (website) => {
		const redirect = 'wordpressExportSettings'
		UserInfoActionsCreator.loadUserInfoWithRedirect(redirect, website);

	}
	onLogedIn = (website) => {
		const redirect = 'layout'
		UserInfoActionsCreator.loadUserInfoWithRedirect(redirect, website);
	}

	onUserCreated = (data) => {
		const redirect = 'websites'
		UserInfoActionsCreator.loadUserInfoWithRedirect(redirect);
	}

	onSaveAndStartAgain = (data) => {
		FlashMessageActionsCreator.addFlashMessage({ text: 'Your site has been saved'})
	}

	onUserCreatedFails = (message) => {
		FlashMessageActionsCreator.addFlashMessage({ text: message, level: 'error' })
	}

	onUserLoginFails = (message) => {
		FlashMessageActionsCreator.addFlashMessage({ text: message.error_message, level: 'error' })
	}

	onFails = (message) => {
		FlashMessageActionsCreator.addFlashMessage({ text: message.error_message || message.message || message, level: 'error' })
	}

	render() {
		return <div>
			<div className="test" ref={(div) => {
				this.mountPoint = div
			}}></div>
		</div>
	}
}

export default Tailor
