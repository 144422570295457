'use strict';

require("babel-polyfill");

var React = require('react');
var ReactDOM = require('react-dom');
var RemixerApp = require('./components/RemixerApp.react');

var UrlUtils = require('./utils/UrlUtils');
import '../scss/app.scss';

UrlUtils.hardReturn();


ReactDOM.render(<RemixerApp />, document.getElementById('remixer-app'));
