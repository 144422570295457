'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var WebAPI = require('../utils/WebAPI');

/** @module FacebookActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var FacebookActionsCreator = {
	/**
	 * @method requestFacebookAuthorizationUrl
	 */
	requestFacebookAuthorizationUrl: function(returnToUrl) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REQUEST_FACEBOOK_AUTHORIZATION_URL,
			returnTo: returnToUrl
		},
		WebAPI.getFacebookAuthorizationUrl, [returnToUrl]);
	}
};

module.exports = FacebookActionsCreator;
