'use strict';


import { get, isEqual, uniqueId } from 'lodash';

var React = require('react');
var ReactDOM = require('react-dom');
var Events = require('events');

var ComponentFactory = require('../../infrastructure/ComponentFactory');

var Shapes = require('../../utils/Shapes');
var DomElementUtils = require('../../utils/DomElementUtils');

//NOTE: it's ok to have singletone event emitter to communicate
//but we have to create store in case we need to share more state.
var _singletone = new Events.EventEmitter();

var EVENT_NAME = "open";

/** @module Popover
 * @requires lodash
 * @requires react
 * @requires react-dom
 * @requires events
 * @requires ComponentFactory
 * @requires Shapes
 * @requires DomElementUtils
 */
var Popover = ComponentFactory.Create({
	displayName: "Popover",
	propTypes: {
		targetRectangle: React.PropTypes.instanceOf(Shapes.Rectangle).isRequired,
		boundaryRectangle: React.PropTypes.instanceOf(Shapes.Rectangle).isRequired,
		onClose: React.PropTypes.func,
		onOpen: React.PropTypes.func,
		updatePeriod: React.PropTypes.number,
		autoClose: React.PropTypes.bool
	},

	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			updatePeriod: 100,
			autoClose: true
		};
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return {
			closed: !this.props.targetRectangle
		};
	},

	/**
	 * @method applyDomChanges
	 */
	applyDomChanges: function() {
		if(!this.refs.panel) return;

		var containerRect = DomElementUtils.GetElementRect(ReactDOM.findDOMNode(this.refs.panel));
		if(!isEqual(this.state.containerRect, containerRect)) {
			this.setState({ containerRect: containerRect });
		}
	},

	/**
	 * @method autoClose
	 */
	autoClose: function(uid) {
		if(uid !== this.uid && this.props.autoClose) this._onClose();
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this.interval = setInterval(this.applyDomChanges, this.props.updatePeriod);
		this.componentDidUpdate();
		this.uid = uniqueId('popup');
		_singletone.on(EVENT_NAME, this.autoClose);
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function() {
		clearInterval(this.interval);
		_singletone.removeListener(EVENT_NAME, this.autoClose);
	},

	/**
	 * @method calculatePosition
	 */
	calculatePosition: function() {
		var onTop = false;
		var boundaryRectangle = this.props.boundaryRectangle;
		var containerRectangle = new Shapes.Rectangle(this.state.containerRect);
		var pointerHeight = 20;

		var visibleTargetRectangle = boundaryRectangle.intersection(this.props.targetRectangle);
		if(!visibleTargetRectangle) return {};

		boundaryRectangle = boundaryRectangle.changeRectangle(-50); //padding 50px
		containerRectangle = containerRectangle.moveYAxis(visibleTargetRectangle.bottom());

		if (containerRectangle.bottom() > boundaryRectangle.bottom()
		&& visibleTargetRectangle.position.top - containerRectangle.size.height >= boundaryRectangle.position.top) {
			containerRectangle = containerRectangle.moveYAxis(visibleTargetRectangle.position.top - containerRectangle.size.height - pointerHeight);
			onTop = true;
		}

		var pointerLeft = visibleTargetRectangle.xAxisMiddle();

		containerRectangle = containerRectangle.moveXAxis(pointerLeft - (containerRectangle.size.width / 2));

		if (containerRectangle.position.left < boundaryRectangle.position.left) {
			containerRectangle = containerRectangle.translate(boundaryRectangle.position.left - containerRectangle.position.left, 0);
		}
		if (containerRectangle.right() > boundaryRectangle.right()) {
			containerRectangle = containerRectangle.translate(boundaryRectangle.right() - containerRectangle.right(), 0);
		}

		return {
			onTop: onTop,
			pointerLeft: pointerLeft,
			pinner: containerRectangle.position
		};
	},

	/**
	 * @method _chanedAndExists
	 */
	_chanedAndExists: function(props, state) {
		return (this.props.targetRectangle && this.props.boundaryRectangle && this.state.containerRect) &&
			(!isEqual(this.props.targetRectangle, props.targetRectangle) ||
			!isEqual(this.props.boundaryRectangle, props.boundaryRectangle) ||
			!isEqual(this.state.containerRect, state.containerRect));
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		props = props || {};
		state = state || {};

		if (!isEqual(this.props.targetRectangle, props.targetRectangle) && this.state.closed) {
			this.setState({ closed: false });
		}

		if (this._chanedAndExists(props, state)) {
			this.setState({ position: this.calculatePosition() });
		}

		//position appears
		if (!state.position && this.state.position) this._onOpen();
	},

	/**
	 * @method _onOpen
	 */
	_onOpen: function() {
		_singletone.emit(EVENT_NAME, this.uid);
		if (this.props.onOpen) {
			this.props.onOpen(this.state.position.pinner.top, this.state.position.pinner.left);
		}
	},

	/**
	 * @method _onClose
	 */
	_onClose: function() {
		this.setState({ closed: true });
		if(this.props.onClose) this.props.onClose();
	},

	/**
	 * @method render
	 */
	render: function() {
		if(this.state.closed) {
			return null;
		}
		var pointerLeft = get(this.state.position, 'pointerLeft');
		var pinnerTop = get(this.state.position, ['pinner', 'top']);
		var pinnerLeft = get(this.state.position, ['pinner', 'left']);
		var onTop = get(this.state.position, 'onTop');
		var backEl = (<div key="backdrop" className="backdrop" onClick={this._onClose}></div>);
		var pointerEl = (<div key="pointer" className="pointer" style={{ left: pointerLeft }}></div>);
		var pinnerEl = (<div key="pinner" className="pinner" style={{ left: pinnerLeft }}>
			<div ref="panel" className="inner-panel">
				<div className="popup-content">
					{this.props.children}
				</div>
			</div>
		</div>);
		var popup = [pointerEl, pinnerEl, backEl];
		if (onTop) {
			popup.reverse();
		}
		return (<div className={this.css("popup", {top: onTop, bottom: !onTop, hidden: !this.state.position}, this.props.className)} style={{ top: pinnerTop }}>
			{ popup }
		</div>);
	}
});

module.exports = Popover;
