'use strict';

import { get, isUndefined, isEmpty, some, map, partial, first, isEqual } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

/** @module Switcher
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 */
var Switcher = ComponentFactory.Create({
	displayName: "Switcher",
	propTypes: {
		onChange: React.PropTypes.func,
		ignoreInitialChange: React.PropTypes.bool
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return { };
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this.componentDidUpdate();
	},

	/**
	 * @method _isInitialCall
	 */
	_isInitialCall: function(props, state) {
		return isUndefined(props) && isUndefined(state) || isEmpty(props.children) && !isEmpty(this.props.children);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		if (this._isInitialCall(props, state)) {
			var initial = this.props.active || first(this.props.children);
			if(this.props.ignoreInitialChange) {
				this.setState({ active: initial });
			} else {
				this._setActive(initial);
			}
		} else {
			var isPropsChange = !isEqual(props.active, this.props.active);
			if (isPropsChange) {
				this._setActive(this.props.active);
			}

			var isOptionsChange = !isEqual(props.children, this.props.children);
			if (isOptionsChange) {
				var key = get(this.state.active, 'key');
				var isNewChildrenHasActive = some(map(this.props.children, 'key'), partial(isEqual, key));
				if (!isNewChildrenHasActive) {
					this._setActive(undefined);
				}
			}
		}
	},

	/**
	 * @method _setActive
	 */
	_setActive: function(component) {
		if(!isEqual(this.state.active, component)) {
			this.setState({ active: component });
			if(!isEqual(this.props.active, component) && this.props.onChange) {
				this.props.onChange(component);
			}
		}
	},

	/**
	 * @method setActive
	 */
	setActive: function(key) {
		for (var i = 0; i < this.props.children.length; i++) {
			if(this.props.children[i].key === key) {
				this._setActive(this.props.children[i]);
				return;
			}
		}
	},

	/**
	 * @method render
	 */
	render: function () {
		return (
			<ul className="switches">
				{React.Children.map(this.props.children, (component, idx) => {
					return (
						<li key={idx} onClick={partial(this._setActive, component)}
							className={this.css("switch", {"active": isEqual(get(this, ['state', 'active', 'key']), get(component, 'key')) })} unselectable='on'>
							<div unselectable='on'>{component}</div>
						</li>);
				})}
			</ul>
		);
	}
});

module.exports = Switcher;
