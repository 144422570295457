import React from 'react'
import PropTypes from 'prop-types'
import { Form, Text } from 'react-form'
import classnames from 'classnames'
import { map, values, last } from 'lodash'
import SignUpErrorHandlerStore from '../stores/SignUpErrorHandlerStore'
import SignupActionCreator from '../actions/SignupActionCreator';

export default class LoginForm extends React.PureComponent {
	static propTypes = {
		changeNavigation: PropTypes.func,
		onChange: PropTypes.func,
		saveTheme: PropTypes.object.isRequired,
		formToggle: PropTypes.bool
	}
	constructor(props) {
		super(props)
		this.state = {
			isShowPassword: false,
			chainIsInProgress: false,
			failedAction: last(values(SignUpErrorHandlerStore.getState()))
		}
	}
	
	componentDidMount() {
		SignUpErrorHandlerStore.addChangeListener(this._onChange)
	}
	_onChange = () => {
		let failedAction = last(values(SignUpErrorHandlerStore.getState()))
		this.setState({
			chainIsInProgress: !failedAction.chainIsFailed
		})
	}
	
	componentWillUnmount() {
		SignUpErrorHandlerStore.removeChangeListener(this._onChange);
	}
	onShowPasswordClick = () => {
		this.setState({
			isShowPassword: !this.state.isShowPassword
		})
	}
	errorValidator = (values) => {
		const validateWebid = (webid) => {
			if (webid) {
				let emptyRegex = /(?=(^ *$))/
				return emptyRegex.test(webid) ? ['· username or email can not be blank'] : null
			}
			return !webid ? ['· username or email can not be blank'] : null
		}
		const validatePassword = (password) => {
			if (password) {
				let charactersRegex = /([^\x20-\x7e])/
				let lenghtRegex = /(?=^.{8,31}$)/
				let whiteSpaceRegex = /(?=(^\s+|\s+$))/
				return (password.match(charactersRegex) === null &&
					lenghtRegex.test(password) &&
					!whiteSpaceRegex.test(password)) ? null
					: ['· password can not be blank',
						'· must be between 8 and 31 characters long',
						'· cannot start or end with a space',
						'· ASCII printable English characters only']
			}
			return !password ? ['· password can not be blank'] : null
		}
		return {
			webid: validateWebid(values.webid),
			password: validatePassword(values.password)
		}
	}

	loginToAccount(values) {
		SignupActionCreator.loginToAccount(
			this.props.saveTheme.pageId,
			this.props.saveTheme.name,
			values.webid,
			values.password
		);
	}

	getNotification(formApi, field) {
		if (formApi.errors[field] && formApi.touched[field]) {
			return (<div className="notifications">
				{map(formApi.errors[field],(val, key) => {
					return <div className="notification-text" key={key}>{val}</div>
				})}
			</div>)
		}
	}

	changeForm = () => {
		this.props.onChange(!this.props.formToggle)
	}

	getForm() {
		return (
			<Form
				validateError={this.errorValidator}
				onSubmitFailure={formData => formData}
				onSubmit={values => {
					this.loginToAccount(values)
				}}>
				{formApi => (
					<form className="account-form" onSubmit={formApi.submitForm} id="form2">
						<div className="text-input-wrapper">
							<Text className={classnames(
								'text-input', formApi.errors.webid && formApi.touched.webid && 'error'
							)} placeholder="Your username or email address" field="webid" id="webid"/>
							{this.getNotification(formApi, 'webid')}
						</div>
						<div className="text-input-wrapper">
							<div className={classnames('password-wrapper',
								formApi.errors.password && formApi.touched.password && 'error')}>
								<Text type={this.state.isShowPassword ? 'text' : 'password'}
									className="text-input"
									placeholder="Password" field="password" id="password"/>
								<div className={classnames(
									'eye', this.state.isShowPassword && 'open'
								)} onClick={this.onShowPasswordClick}/>
							</div>
							{this.getNotification(formApi, 'password')}
						</div>
						<button disabled={this.state.chainIsInProgress} type="submit"
							className={classnames('account-submit-button', { 'spinner white': this.state.chainIsInProgress })}>
							{!this.state.chainIsInProgress && 'Login and edit site'}
						</button>
					</form>
				)}
			</Form>
		)
	}
	render() {
		return (
			<div className="account-container">
				<div className="account-text">
					{'Login to your account to add your own content and begin editing your site!'}
				</div>
				{this.getForm()}
				<div className="login-question">
					<span className="description">
						{'Don\'t have an account? '}
					</span>
					<div className="login-toggle" onClick={this.changeForm}>
						{'Create account'}
					</div>
				</div>
				<div className="terms-container">
					<span>
						{'By clicking \'Create Account\', you agree to our general '}
						<a href={'https://www.dreamhost.com/legal/'}>
							{'Terms of Service'}
						</a>
						{' policies.'}
					</span>
				</div>
			</div>
		)
	}
}
