'use strict';

import { get, find, map } from 'lodash';

var React = require('react');

var PropertyTypes = require('../../constants/PropertyTypes');

var ComponentFactory = require('../../infrastructure/ComponentFactory');

var PageStore = require('../../stores/PageStore');
var ComponentContextStore = require('../../stores/ComponentContextStore');
var ComponentMetadataStore = require('../../stores/ComponentMetadataStore');

var ComponentDomState = require('../../models/ComponentDomState');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

var DomElementUtils = require('../../utils/DomElementUtils');

var Rectangle = require('../common/Rectangle.react');

var PropertySystemLayer = require('./PropertySystemLayer.react');
var ArrayItemSystemLayer = require('./ArrayItemSystemLayer.react');

/** @module ComponentSystemLayer
 * @requires lodash
 * @requires react
 * @requires PropertyTypes
 * @requires ComponentFactory
 * @requires PageStore
 * @requires ComponentContextStore
 * @requires ComponentDomState
 * @requires ComponentInstance
 * @requires ComponentActionsCreator
 * @requires DomElementUtils
 * @requires Rectangle
 * @requires PropertySystemLayer
 * @requires ArrayItemSystemLayer
 */
var ComponentSystemLayer = ComponentFactory.Create(PageStore, ComponentContextStore, ComponentMetadataStore, {
	displayName: "ComponentSystemLayer",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		component: React.PropTypes.instanceOf(ComponentDomState).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var activeComponentId = ComponentContextStore.getState('active', 'id');
		var activeProperty =
			ComponentContextStore.getState('activeTextManipulation') ||
			ComponentContextStore.getState('activeImagePicker') ||
			ComponentContextStore.getState('activeMarkdownPicker') ||
			ComponentContextStore.getState('activeVideoPicker') ||
			ComponentContextStore.getState('activeAudioPicker') ||
			ComponentContextStore.getState('activeMapPicker');
		var active = activeComponentId === this.props.component.componentId;
		var page = PageStore.getState(this.props.pageId);
		var componentMetadata = {};
		if (get(page, ['components', this.props.component.componentId])) componentMetadata = ComponentMetadataStore.getComponentMetadata(page.components[this.props.component.componentId].reference);
		return {
			page: page,
			hold: activeComponentId && !active,
			active: active,
			focus: get(activeProperty, 'componentId') === this.props.component.componentId,
			componentMetadata: componentMetadata
		};
	},

	/**
	 * @method _activate
	 */
	_activate: function() {
		if (!this.state.page) return;

		if (!this.state.hold) {
			var instance = this.state.page.components[this.props.component.componentId];
			ComponentActionsCreator.activeComponent(instance);
		} else {
			ComponentActionsCreator.resetActiveComponent();
		}
	},

	/**
	 * @method _remove
	 */
	_remove: function(ev) {
		ev.stopPropagation();

		var instance = this.state.page.components[this.props.component.componentId];
		if (!instance) return;

		ComponentActionsCreator.removeComponentInstance(instance);
	},

	/**
	 * @method _configure
	 */
	_configure: function(ev) {
		ev.stopPropagation();

		var instance = this.state.page.components[this.props.component.componentId];
		if (!instance) return;
		ComponentActionsCreator.openComponentSetting(instance, 'configuration');

		var compRect =  this.props.component.rectangle
		if (compRect) {
			DomElementUtils.scrollDocumentTop(compRect.position.top + compRect.size.height - 200);
		}

	},

	/**
	 * @method _replace
	 */
	_replace: function(ev) {
		ev.stopPropagation();

		var instance = this.state.page.components[this.props.component.componentId];
		if (!instance) return;
		ComponentActionsCreator.openComponentSetting(instance, 'replace');

		var compRect =  this.props.component.rectangle
		if (compRect) {
			DomElementUtils.scrollDocumentTop(compRect.position.top + compRect.size.height - 200);
		}

	},

	/**
	 * @method _pickColor
	 */
	_pickColor: function(ev) {
		ev.stopPropagation();

		this._activate();

		var propertyMetadata = find(this.state.componentMetadata.properties, {"name": "backgroundColor"});
		if (!propertyMetadata) return;
		ComponentActionsCreator.startColorPicker(propertyMetadata);

		var compRect =  this.props.component.rectangle
		if (compRect) {
			DomElementUtils.scrollDocumentTop(compRect.position.top);
		}

	},

	/**
	 * @method _toggleDeleteConfirm
	 */
	_toggleDeleteConfirm: function() {
		this.setState({deleteConfirm: !get(this.state, 'deleteConfirm', false)})
	},

	/**
	 * @method _onFocusChange
	 */
	_onFocusChange: function(focus) {
		if (focus) this._activate();
	},

	/**
	 * @method render
	 */
	render: function() {
		var draggableAttrs = {
			key: this.state.key,
			onClick: this._activate
		};
		return (<Rectangle {...draggableAttrs}
			className={this.css("system-component", {
				'hold': this.state.hold,
				'focus': this.state.focus,
				'active': this.state.active
			})}
			rectangle={this.props.component.rectangle}>
			<div className="system-component-overlay"></div>
			<div className="chord-toolbar">
				<div className="toolbar-item label-tool">{this.props.component.name}</div>
				<div onClick={this._configure} className="toolbar-item settings-tool">
					<i className="mdi mdi-settings"></i>
					<span>Settings</span>
				</div>
				{find(this.state.componentMetadata.properties, {"name": "backgroundColor"}) ?
					<div onClick={this._pickColor} className="toolbar-item remix-tool">
						<i className="mdi mdi-palette"></i>
						<span>Color</span>
					</div>
					: null }
				<div onClick={this.state.deleteConfirm ? this._remove : this._toggleDeleteConfirm} onMouseLeave={this.state.deleteConfirm ? this._toggleDeleteConfirm : null} className={this.css("toolbar-item delete-tool", {active: this.state.deleteConfirm})}>
					<i className="mdi mdi-delete"></i>
					<span>Confirm</span>
				</div>
			</div>
			{map(this.props.component.properties, (propertyInfo) => {
				return PropertyTypes.isArrayItem(propertyInfo.type) ?
					(<ArrayItemSystemLayer
						key={propertyInfo.path}
						pageId={this.props.pageId}
						propertyInfo={propertyInfo} />) :
					(<PropertySystemLayer
						key={propertyInfo.path}
						onFocusChange={this._onFocusChange}
						propertyInfo={propertyInfo}
						marker={!PropertyTypes.isArrayChild(propertyInfo.type)}/>);
			})}
		</Rectangle>);
	}
});

module.exports = ComponentSystemLayer;
