'use strict';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

/** @module CreateTagForm
 * @requires react
 * @requires ComponentFactory
 */
var CreateTagForm = ComponentFactory.Create({
	displayName: "CreateTagForm",
	propTypes: {
		onCancel: React.PropTypes.func,
		onCreate: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return {value: ''};
	},

	/**
	 * @method _onValueChange
	 */
	_onValueChange: function(event) {
		this.setState({value: event.target.value});
	},

	/**
	 * @method _onCancel
	 */
	_onCancel: function() {
		this.setState({value: ''});
		this.props.onCancel();
	},

	/**
	 * @method _onCreate
	 */
	_onCreate: function() {
		this.state.value && this.props.onCreate(this.state.value);
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="row">
				<div className="small-4 columns">
					<label className="right inline"><span className={this.css("mdi", "mdi-plus")} /> Create new tag</label>
				</div>
				<div className="small-4 columns">
					<input type="text" value={this.state.value} className="radius" placeholder="type here..." onChange={this._onValueChange} />
				</div>
				<div className="small-4 columns">
					<label className="left inline">
						<button disabled={!this.state.value.trim()} className={this.css("radius label", {disabled: !this.state.value})} onClick={this._onCreate}>create</button>
						<button className="alert radius label" onClick={this._onCancel}>cancel</button>
					</label>
				</div>
			</div>
		);
	}
});

module.exports = CreateTagForm;
