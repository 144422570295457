'use strict';

import { get } from 'lodash';
var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');
var PageStore = require('../stores/PageStore');

var ComponentInstance = require('../models/ComponentInstance');
var PropertyInfo = require('../models/PropertyInfo');

/** @module ComponentContextStore
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires PageStore
 * @requires ComponentInstance
 * @requires PropertyInfo
 */
var ComponentContextStore = StoreFactory.Create({
	/**
	 * @method getPropertyValue
	 */
	getPropertyValue: function(propertyPath) {
		if (!this.getState('active')) return;
		return get(this.getState('active', 'context'), propertyPath);
	},

	/**
	 * @method _setActiveComponent
	 */
	_setActiveComponent: function(action) {
		this._set('active', new ComponentInstance(action.component));
		this._deactivate();
	},

	/**
	 * @method _unsetActiveComponent
	 */
	_unsetActiveComponent: function() {
		this._remove('active');
		this._deactivate();
	},

	/**
	 * @method _updateActiveComponent
	 */
	_updateActiveComponent: function(action) {
		this.waitFor([PageStore.token]);

		if (this._get('active', 'id') === action.componentInstance.id) {
			this._set('active', new ComponentInstance(action.componentInstance));
		}
	},

	/**
	 * @method _receivePage
	 */
	_receivePage: function(action) {
		this.waitFor([PageStore.token]);

		var active = this._get('active');
		if (!active) return;

		var updatedActive = action.page.components[active.id];
		if (updatedActive) {
			this._set('active', new ComponentInstance(updatedActive));
		} else {
			this._remove('active');
		}
		this._deactivate();
	},

	/**
	 * @method _deactivate
	 */
	_deactivate: function() {
		this._remove('activeTextManipulation');
		this._remove('activeImagePicker');
		this._remove('activeMarkdownPicker');
		this._remove('activeMarkdownManipulation');
		this._remove('activeVideoPicker');
		this._remove('activeAudioPicker');
		this._remove('activeMapPicker');
		this._remove('activeLinkPicker');
		this._remove('activeColorPicker');
		this._remove('settingsOpened');
		this._remove('settingsType');
	},

	/**
	 * @method _startPropertyAction
	 */
	_startPropertyAction: function(propertyAction, action) {
		this._deactivate();

		this._set(propertyAction, new PropertyInfo(action.propertyState));
	},

	/**
	 * @method _updateTextProperty
	 */
	_updateTextProperty: function(action) {
		this._startPropertyAction('activeTextManipulation', action);
	},

	/**
	 * @method _startTextManipulation
	 */
	_startTextManipulation: function(action) {
		this._startPropertyAction('activeTextManipulation', action);
	},

	/**
	 * @method _endTextManipulation
	 */
	_endTextManipulation: function() {
		this._remove('activeTextManipulation');
	},

	/**
	 * @method _startImagePicker
	 */
	_startImagePicker: function(action) {
		this._startPropertyAction('activeImagePicker', action);
	},

	/**
	 * @method _stopImagePicker
	 */
	_stopImagePicker: function(action) {
		this._remove('activeImagePicker');
	},

	/**
	 * @method _startMarkdownManipulation
	 */
	_startMarkdownManipulation: function(action) {
		this._startPropertyAction('activeMarkdownManipulation', action);
	},

	/**
	 * @method _stopMarkdownManipulation
	 */
	_stopMarkdownManipulation: function(action) {
		this._remove('activeMarkdownaManipulation');
	},

	/**
	 * @method _setActiveComponentSettings
	 */
	_setActiveComponentSettings: function(action) {
		this._deactivate();

		var instance = new ComponentInstance(action.componentInstance);
		this._set('active', instance);
		this._set('settingsOpened', instance);
		this._set('settingsType', action.settingsType);
	},

	/**
	 * @method _setActiveNewComponentSettings
	 */
	_setActiveNewComponentSettings: function(action) {
		this._deactivate();

		this._remove('active');
		this._set('settingsOpened', null);
	},

	/**
	 * @method _startMarkdownPicker
	 */
	_startMarkdownPicker: function(action) {
		this._startPropertyAction('activeMarkdownPicker', action);
	},

	/**
	 * @method _stopMarkdownPicker
	 */
	_stopMarkdownPicker: function(action) {
		this._remove('activeMarkdownPicker');
	},

	/**
	 * @method _startVideoPicker
	 */
	_startVideoPicker: function(action) {
		this._startPropertyAction('activeVideoPicker', action);
	},

	/**
	 * @method _stopVideoPicker
	 */
	_stopVideoPicker: function(action) {
		this._remove('activeVideoPicker');
	},

	/**
	 * @method _startAudioPicker
	 */
	_startAudioPicker: function(action) {
		this._startPropertyAction('activeAudioPicker', action);
	},

	/**
	 * @method _stopAudioPicker
	 */
	_stopAudioPicker: function(action) {
		this._remove('activeAudioPicker');
	},

	/**
	 * @method _startMapPicker
	 */
	_startMapPicker: function(action) {
		this._startPropertyAction('activeMapPicker', action);
	},

	/**
	 * @method _stopMapPicker
	 */
	_stopMapPicker: function(action) {
		this._remove('activeMapPicker');
	},

	/**
	 * @method _startLinkPicker
	 */
	_startLinkPicker: function(action) {
		this._startPropertyAction('activeLinkPicker', action);
	},

	/**
	 * @method _stopLinkPicker
	 */
	_stopLinkPicker: function(action) {
		this._remove('activeLinkPicker');
	},

	/**
	 * @method _startColorPicker
	 */
	_startColorPicker: function(action) {
		this._deactivate();
		this._set('activeColorPicker', action.propertyState);
	},

	/**
	 * @method _stopColorPicker
	 */
	_stopColorPicker: function(action) {
		this._remove('activeColorPicker');
	},

	/**
	 * @method _startDragging
	 */
	_startDragging: function(action) {
		this._set('dragging', action.data);
	},

	/**
	 * @method _endDragging
	 */
	_endDragging: function() {
		this._remove('dragging');
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		handlers[RemixerConstants.OPEN_COMPONENT_SETTINGS] = this._setActiveComponentSettings;
		handlers[RemixerConstants.OPEN_NEW_COMPONENT_SETTINGS] = this._setActiveNewComponentSettings;

		handlers[RemixerConstants.RECEIVE_CREATE_COMPONENT_INSTANCE] = this._setActiveComponent;
		handlers[RemixerConstants.COMPONENT_CONTEXT_REQUEST] = this._setActiveComponent;

		handlers[RemixerConstants.REMOVE_COMPONENT_INSTANCE] = this._unsetActiveComponent;
		handlers[RemixerConstants.RESET_ACTIVE_COMPONENT] = this._unsetActiveComponent;

		handlers[RemixerConstants.UPDATE_COMPONENT_INSTANCE_CONTEXT] = this._updateActiveComponent;

		handlers[RemixerConstants.RECEIVE_PAGE] = this._receivePage;

		handlers[RemixerConstants.UPDATE_TEXT_PROPERTY] = this._updateTextProperty;

		handlers[RemixerConstants.START_TEXT_MANIPULATION] = this._startTextManipulation;
		handlers[RemixerConstants.END_TEXT_MANIPULATION] = this._endTextManipulation;

		handlers[RemixerConstants.START_IMAGE_PICKER] = this._startImagePicker;
		handlers[RemixerConstants.STOP_IMAGE_PICKER] = this._stopImagePicker;

		handlers[RemixerConstants.START_MARKDOWN_PICKER] = this._startMarkdownPicker;
		handlers[RemixerConstants.STOP_MARKDOWN_PICKER] = this._stopMarkdownPicker;

		handlers[RemixerConstants.START_VIDEO_PICKER] = this._startVideoPicker;
		handlers[RemixerConstants.STOP_VIDEO_PICKER] = this._stopVideoPicker;

		handlers[RemixerConstants.START_AUDIO_PICKER] = this._startAudioPicker;
		handlers[RemixerConstants.STOP_AUDIO_PICKER] = this._stopAudioPicker;

		handlers[RemixerConstants.START_MAP_PICKER] = this._startMapPicker;
		handlers[RemixerConstants.STOP_MAP_PICKER] = this._stopMapPicker;

		handlers[RemixerConstants.START_LINK_PICKER] = this._startLinkPicker;
		handlers[RemixerConstants.STOP_LINK_PICKER] = this._stopLinkPicker;

		handlers[RemixerConstants.START_COLOR_PICKER] = this._startColorPicker;
		handlers[RemixerConstants.STOP_COLOR_PICKER] = this._stopColorPicker;

		handlers[RemixerConstants.DEACTIVATE_PROPERTY] = this._deactivate;

		handlers[RemixerConstants.START_DRAGGING_COMPONENT] = this._startDragging;
		handlers[RemixerConstants.END_DRAGGING_COMPONENT] = this._endDragging;

		handlers[RemixerConstants.CHANGE_ROUTE] = this._unsetActiveComponent;

		return handlers;
	}
});

module.exports = ComponentContextStore;
