'use strict';

import { partial } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

/** @module DescriptionMarkdown
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 */
var DescriptionMarkdown = ComponentFactory.Create({
	displayName: "DescriptionMarkdown",
	propTypes: {
		contentItem: React.PropTypes.object.isRequired,
		picked: React.PropTypes.bool,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<a onClick={partial(this.props.onPick, this.props.contentItem)}>
				{this.props.contentItem.name || (<em>No title</em>)}
				<span onClick={partial(this.props.onDelete, this.props.contentItem)} className="delete">
					<i className="mdi mdi-delete" />
				</span>
			</a>
		);
	}

});

module.exports = DescriptionMarkdown;
