'use strict';

import { isEqual, omit, debounce } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var Dropzone = require('react-dropzone');
var ImportTypes = require('../../constants/ImportTypes');
var ImportActionsCreator = require('../../actions/ImportActionsCreator');

var ImportStore = require('../../stores/ImportStore');

function diasableDropEffect(e) {
	e.dataTransfer.effectAllowed = 'none';
	e.dataTransfer.dropEffect = 'none';
}

/** @module ImagesUploader
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires react-dropzone
 * @requires ImportTypes
 * @requires ImportActionsCreator
 * @requires ImportStore
 */
var ImagesUploader = ComponentFactory.Create(ImportStore, {
	displayName: "ImagesUploader",
	propTypes: {
		onStatusChanged: React.PropTypes.func
	},

	/**
	 * @method getState
	 */
	getState: function () {
		return {
			loading: ImportStore.filling(ImportTypes.FILE)
		};
	},

	/**
	 * @method _handleDraggingStart
	 */
	_handleDraggingStart: function () {
		this._handleDraggingEndDebounced.cancel();
		this.setState({dragging: true});
	},

	/**
	 * @method _handleDraggingEnd
	 */
	_handleDraggingEnd: function () {
		this.setState({dragging: false});
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		if(!isEqual(omit(state, ['dragging', 'loading']), omit(this.state, ['dragging', 'loading']))) {
			if(this.props.onStatusChanged) this.props.onStatusChanged(this._getStatus());
		}
	},

	/**
	 * @method componentWillMount
	 */
	componentWillMount: function () {
		//hack: delays _handleDraggingEnd as it might occurs very frequently and prevents blinking
		this._handleDraggingEndDebounced = debounce(this._handleDraggingEnd, 200);
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function () {
		window.addEventListener('dragenter', this.onWindowDragEnter);
		window.addEventListener('dragover', this.onWindowDragOver);
		window.addEventListener('dragleave', this.onWindowDragLeave);
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function () {
		window.removeEventListener('dragenter', this.onWindowDragEnter);
		window.removeEventListener('dragover', this.onWindowDragOver);
		window.removeEventListener('dragleave', this.onWindowDragLeave);
	},

	/**
	 * @method onDrop
	 */
	onDrop: function (files) {
		if (!this.state.loading) {
			if (files.length) {
				ImportActionsCreator.startImport(ImportTypes.FILE, files);
			}
			this._handleDraggingEndDebounced();
		}
	},

	/**
	 * @method onWindowDragLeave
	 */
	onWindowDragLeave: function (e) {
		e.preventDefault();
		this._handleDraggingEndDebounced();
	},

	/**
	 * @method onWindowDragOver
	 */
	onWindowDragOver: function (e) {
		e.preventDefault();
		this._handleDraggingStart();
	},

	/**
	 * @method onWindowDragEnter
	 */
	onWindowDragEnter: function (e) {
		e.preventDefault();
		this._handleDraggingStart();
	},

	/**
	 * @method onDropzoneDragOver
	 */
	onDropzoneDragOver: function (e) {
		e.preventDefault();
		if (this.state.loading) {
			diasableDropEffect(e);
		}

		this._handleDraggingStart();
	},

	/**
	 * @method onDropzoneDragEnter
	 */
	onDropzoneDragEnter: function (e) {
		e.preventDefault();
		if (this.state.loading) {
			diasableDropEffect(e);
		} else {
			e.dataTransfer.dropEffect = 'copy';
		}
		this._handleDraggingStart();
	},

	/**
	 * @method _getStatus
	 */
	_getStatus: function() {
		return {
			loading: this.state.loading,
			incoming: this.state.dragging && !this.state.loading
		};
	},

	/**
	 * @method render
	 */
	render: function () {
		var status = this._getStatus();
		return (<Dropzone className={this.css("dropzone", status)} supportClick={!status.loading}
			onDrop={this.onDrop}
			onDragEnter={this.onDropzoneDragOver}
			onDragOver={this.onDropzoneDragEnter}
			style={{...this.props.style}}>
			{this.props.children}
		</Dropzone>);
	}
});

module.exports = ImagesUploader;
