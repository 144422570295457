import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

import WebAPI from '../utils/WebAPI';

/** @module TrialActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
export default {
	startTrial(account_id, data) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.START_TRIAL,
			account_id
		},
		WebAPI.startTrial, [data]);
	},
	hide(websiteId) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.HIDE_TRIAL_PUBLISH_NOTIFICATION,
			websiteId
		});
	},
	getTrialStatus() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_TRIAL_STATUS
		},
		WebAPI.getTrialStatus);
	}
};
