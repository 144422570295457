'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var UrlUtils = require('../utils/UrlUtils');

/** @module TwitterServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires UrlUtils
 */
var TwitterServerActionsCreator = {
	/**
	 * @method receiveTwitterAuthorizationUrl
	 */
	receiveTwitterAuthorizationUrl: function(result) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_TWITTER_AUTHORIZATION_URL,
			result: result.data
		});
		UrlUtils.navigateTo(result.data.authorization_url);
	},
	/**
	 * @method receiveTwitterAuthorizationUrlFails
	 */
	receiveTwitterAuthorizationUrlFails: function(error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_TWITTER_AUTHORIZATION_URL_FAILS,
			message: 'could not get twitter authorization url'
		});
	}
};

module.exports = TwitterServerActionsCreator;
