'use strict';

import { get } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');
var ContentTypes = require('../../../constants/ContentTypes');

var ThumbnailImage = require('./ThumbnailImage.react');
var ThumbnailYoutube = require('./ThumbnailYoutube.react');
var ThumbnailVimeo = require('./ThumbnailVimeo.react');
var ThumbnailSoundcloud = require('./ThumbnailSoundcloud.react');
var ThumbnailMarkdown = require('./ThumbnailMarkdown.react');

var contentTypes = {};
contentTypes[ContentTypes.IMAGE] = ThumbnailImage;
contentTypes[ContentTypes.YOUTUBE] = ThumbnailYoutube;
contentTypes[ContentTypes.VIMEO] = ThumbnailVimeo;
contentTypes[ContentTypes.SOUNDCLOUD] = ThumbnailSoundcloud;
contentTypes[ContentTypes.MARKDOWN] = ThumbnailMarkdown;

/** @module Thumbnail
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ContentTypes
 * @requires ThumbnailImage
 * @requires ThumbnailYoutube
 * @requires ThumbnailSoundcloud
 * @requires ThumbnailMarkdown
 */
var Thumbnail = ComponentFactory.Create({
	displayName: "Thumbnail",
	propTypes: {
		contentItem: React.PropTypes.object.isRequired,
		picked: React.PropTypes.bool,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	/**
	 * @method render
	 */
	render: function() {
		var TypedThumbnail = contentTypes[get(this.props.contentItem, 'content_type')];
		return (
			<TypedThumbnail {...this.props} />
		);
	}

});

module.exports = Thumbnail;
