import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';
import PublishStatuses from '../constants/PublishStatuses';

class SitePublisherStore extends ReduceStore {
	getInitialState() {
		return Immutable.Map();
	}

	reduce(state, action) {
		switch (action.actionType) {
			case RemixerConstants.BEGIN_WEBSITE_PUBLISH:
				//TODO: refactor inconsistent naming (websiteId)
				return state.set(action.data.websiteId, PublishStatuses.PUBLISHING);
			case RemixerConstants.RECEIVE_WEBSITE_PUBLISHED:
				return state.set(action.website.id, null);
			case RemixerConstants.BEGIN_WORDPRESS_EXPORT:
				return state.set(action.data.websiteId, PublishStatuses.WPEXPORTING);
			case RemixerConstants.RECEIVE_WORDPRESS_EXPORT:
				return state.set(action.website.id, null);
			case RemixerConstants.RECEIVE_WEBSITE_PUBLISH_FAILED:
				return state.set(action.website.id, PublishStatuses.FAILED);
			case RemixerConstants.RECEIVE_WORDPRESS_EXPORT_FAILED:
				return state.set(action.website.id, PublishStatuses.WPEXPORTFAILED);
			default:
				return state;
		}
	}
}

export default new SitePublisherStore(AppDispatcher);
