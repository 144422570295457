import React from 'react';
import classnames from 'classnames';
import UrlUtils from '../../../utils/UrlUtils';

export default class TrialBanner extends React.Component {
	static propTypes = {
		daysRemaining: React.PropTypes.number.isRequired
	};

	render() {
		return (
			<div className={classnames("trial-banner", "row")}>
				<div className="small-6 columns">
					<img className="trial-robot" src={UrlUtils.staticUrl("img/dh-robot-cellphone.svg")} />

					<span className="trial-headline" >Remixer trial account</span>
				</div>
				<div className="small-6 columns" >
					<a href="https://panel.dreamhost.com/index.cgi?tree=remixer.dashboard&"><button className="btn-upgrade right">Upgrade Now</button></a>
					{this.props.daysRemaining > 0 ?
						<div className="trial-countdown right">
							<span className="counter" >{this.props.daysRemaining} {this.props.daysRemaining === 1 ? "day" : "days"}</span>
							<span className="smalltext" >Remaining in trial</span>
						</div>
						: <div className="trial-countdown right">
							<span className="expired" >Upgrade your account to publish to a custom domain.</span>
						</div> }
				</div>
			</div>
		);
	}
}
