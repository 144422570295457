"use strict";

import { isString, cloneDeep } from 'lodash';

var Validator = require('../utils/Validator');
var ComponentReference = require('./ComponentReference');
var Mapper = require('../utils/Mapper');

function parse(context) {
	if(isString(context)) {
		return JSON.parse(context);
	}
	return context;
}

/** @module ComponentInstance
 * @requires lodash
 * @requires Validator
 * @requires ComponentReference
 * @requires Mapper
 */
function ComponentInstance(dto) {
	dto = cloneDeep(dto);
	var context = dto.context; //save before delete
	delete dto.context; //avoid unnesesery operations upon context

	dto = Mapper.toCamelCase(dto);
	Validator.isTypeOf(dto, 'number', ["id", "position"]);

	this.pageId = dto.pageId;
	this.id = dto.id;
	this.position = dto.position;
	this.context = parse(context);
	this.reference = new ComponentReference(dto);
}

module.exports = ComponentInstance;
