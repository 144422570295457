import SignupServerActionCreator from '../../actions/SignupServerActionCreator';
import DreamHostersAvailableServerActionsCreator from '../../actions/DreamHostersAvailableServerActionsCreator';
import ExportWordPressServerActionsCreator from '../../actions/ExportWordPressServerActionsCreator';
import API2Requester from './API2Requester'

const api2_remix_client = new API2Requester({
	api_url: "/rpc"
	// TODO: for some reason api2-remix server is not support CORS
	// requests for now, so we have to proxy them using remixer nginx
	// uncomment next line when server will be fixed
	// api_url: "https://api2-remix.dreamhost.com/rpc"
})

const createAccount = function(
	pageId,
	pageName,
	email,
	password,
	first_name,
	last_name,
	email_opt_in
) {
	const agreed_tos = 1;
	api2_remix_client.request({
		method: 'signup.create_account',
		params: {
			email,
			password,
			first_name,
			last_name,
			agreed_tos,
			email_opt_in
		}
	})
		.then(
			(response) => SignupServerActionCreator.receiveNewAccount(
				pageId,
				pageName,
				response
			),
			SignupServerActionCreator.receiveNewAccountFails
		);
}

const loginToAccount = function(
	pageId,
	pageName,
	webid,
	password
) {
	api2_remix_client.request({
		method: 'signup.login',
		params: {
			webid,
			password
		}
	})
		.then(
			(response) => SignupServerActionCreator.receiveExistedAccount(
				pageId,
				pageName,
				response
			),
			SignupServerActionCreator.receiveNewAccountFails
		);
}

const checkDreamhostersDomain = function(
	domain,
	website
) {
	api2_remix_client.request({
		method: 'remixer.check_dreamhosters_subdomain',
		params: {
			domain
		}
	})
		.then(
			(response) => DreamHostersAvailableServerActionsCreator.receiveDreamHostersDomain(
				response,
				domain,
				website
			),
			(response) => DreamHostersAvailableServerActionsCreator.receiveDreamHostersDomainFailed(
				response,
				domain
			)
		);
}

const whoami = function() {
	return api2_remix_client.request({
		method: 'id.whoami',
		params: {}
	})
}

const getWordpressLogin = function(website, newWindow) {
	whoami().then((response) => {
		let accountId = response.data.preferred_account;
		api2_remix_client.request({
			method: 'remixer.get_wordpress_login',
			params: {
				install_id: website.wpInstallId,
				account_id: accountId
			}
		}).then(
			(response) => ExportWordPressServerActionsCreator.receiveLoginWordPressAdmin(response, newWindow)
			,(response) => ExportWordPressServerActionsCreator.receiveLoginWordPressAdminFailed(
				response,
				(website.domain ? "http://" + website.domain : website.publicUrl) + "/wp-admin",
				newWindow)
		)
	},
	(response) => ExportWordPressServerActionsCreator.receiveLoginWordPressAdminFailed(
		response,
		(website.domain ? "http://" + website.domain : website.publicUrl) + "/wp-admin",
		newWindow))
}

export default {getWordpressLogin, checkDreamhostersDomain, loginToAccount, createAccount}