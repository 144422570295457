'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module ThemeLikesServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var ThemeLikesServerActionsCreator = {

	/**
	 * @method receiveThemeLike
	 */
	receiveThemeLike: function(id, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_THEME_LIKE,
			like: response.data,
			themeId: id
		});
	},

	/**
	 * @method receiveThemeLikeFail
	 */
	receiveThemeLikeFail: function(){
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_THEME_LIKE_FAIL,
			message: "could not get theme tags"
		});
	}
};

module.exports = ThemeLikesServerActionsCreator;
