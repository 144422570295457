import { get } from 'lodash';

import React from 'react';
import {Container} from 'flux/utils';

import PixabaySearchResultList from '../common/PixabaySearchResultList.react';

import PixabayActionsCreator from '../../actions/PixabayActionsCreator';
import ImportActionsCreator from '../../actions/ImportActionsCreator';

import PixabaySearchParamsStore from '../../stores/PixabaySearchParamsStore';
import PixabaySearchResultStore from '../../stores/PixabaySearchResultStore';
import PixabaySearchStateStore from '../../stores/PixabaySearchStateStore';

import ImportTypes from '../../constants/ImportTypes';

class ImageStockPhotoLibrary extends React.Component {
	static propTypes = {
		queryTerms: React.PropTypes.array,
		openPixabayImporterModal: React.PropTypes.func
	}
	static getStores() {
		return [PixabaySearchParamsStore, PixabaySearchResultStore, PixabaySearchStateStore];
	}
	static calculateState(prevState, prevProps) {
		return {
			searchParams: PixabaySearchParamsStore.getState(),
			searchResult: PixabaySearchResultStore.getState(),
			searchState: PixabaySearchStateStore.getState()
		};
	}

	componentWillMount() {
		if(!this.state.searchParams.query) {
			this._search(get(this.props.queryTerms, "[0]", "cats"), "all", 1)
		}
	}

	_search(query, category, page, compact) {
		PixabayActionsCreator.search(query, category, page, compact);
	}
	_onChangeQuery = (query = "", category="all") => {
		this._search(query, category, 1);
	};
	_onChangePage = (nextPage) => {
		const {query, category, page} = this.state.searchParams;
		this._search(query, category, nextPage, page + 1 == nextPage);
	};
	render() {
		// const {query, category} = this.state.searchParams;
		// const {searching, total, per_page} = this.state.searchState;
		// const isInitialState = !query && !category;

		return (
			<div className="inner-panel pixabay-search">
				<div className="row pixabay-search-group">
					<div className="large-12 columns pixabay-search-out">
						<div className="row pixabay-search-panel">
							<div className="large-12 columns">
								<PixabaySearchResultList searchResultList={this.state.searchResult.slice(0,12)} onPick={(searchResult) => ImportActionsCreator.startImport(ImportTypes.PIXABAY, { url: searchResult.url })} />
								<button className="view-more-pixa-images" onClick={this.props.openPixabayImporterModal} style={{fontSize: 10, padding: 10, paddingTop: 6, paddingBottom: 6, margin: 0, borderRadius: 5, width: 'calc(100% - 6px)', marginBottom: 10}}>View More Images</button>
								<div style={{width: '100%', height: 15, position: 'relative'}}>
									<p className="tiny-heading" style={{textShadow: '0px 1px rgba(255,255,255,0.5)', fontSize: 10, textAlign: 'left', lineHeight: '14px', padding: 0, margin: 0, fontWeight: 'bold', float: 'left', marginRight: 5, opacity: '0.5'}}>Images provided by</p>
									<a href="https://pixabay.com" target="_blank" style={{border: 'none'}}><div style={{width: 78, height: 15, backgroundImage: 'url(https://pixabay.com/static/img/logo.png)', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', float: 'left', marginRight: 5}} /></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Container.create(ImageStockPhotoLibrary, {withProps: true});
