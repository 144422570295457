'use strict';

import { get, pick } from 'lodash';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');
var AddNewComponentsPreview = require('./AddNewComponentsPreview.react');
var Shapes = require('../utils/Shapes');


/** @module AddNewChordBar
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentActionsCreator
 * @requires AddNewComponentsPreview
 */
var AddNewChordBar = ComponentFactory.Create({
	displayName: "AddNewChordBar",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		expanded: React.PropTypes.bool,
		rectangle: React.PropTypes.instanceOf(Shapes.Rectangle),
		sticky: React.PropTypes.bool
	},

	/**
	 * @method _add
	 */
	_add: function() {
		if (this.props.expanded) {
			ComponentActionsCreator.resetActiveComponent();
			return;
		}
		ComponentActionsCreator.openNewComponentSetting();
	},

	/**
	 * @method render
	 */
	render: function() {
		if ( this.props.sticky ) return null;
		var firstChordTop = {'top': get(this.props, 'rectangle.position.top', '25px')};
		return (
			<div className={this.css("chord-settings add-chord-only", pick(this.props, 'expanded'))} style={firstChordTop}>
				<div className="chord-settings-buttons">
					<div onClick={this._add} className="add-chord-button"><p>new</p></div>
				</div>
				<div className="chord-settings-container">
					<div className="chord-settings-header">
						<span key='add'>Add New Component</span>
					</div>
					<div className="chord-settings-content">
						<div key='add' className="switch-chord-panel">
							<AddNewComponentsPreview pageId={this.props.pageId}/>
						</div>
					</div>
				</div>
			</div>);
	}
});

module.exports = AddNewChordBar;
