'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var FontSizes = require('../../../constants/FontSizes');
var PredefinedFonts = require('../../../constants/PredefinedFonts');

var DropDown = require('../DropDown.react');
var Tabs = require('../Tabs.react');
var ColorPicker = require('../ColorPicker.react');

var UriAnchorTools = require('./UriAnchorTools.react');
var ChordAnchorTools = require('./ChordAnchorTools.react');
var EmailAnchorTools = require('./EmailAnchorTools.react');
var PageLinkTools = require('./PageLinkTools.react');

var UserPreferenceStore = require('../../../stores/UserPreferenceStore');
var PageStore = require('../../../stores/PageStore');
var ComponentMetadataStore = require('../../../stores/ComponentMetadataStore');

var UserPreferenceActionsCreator = require('../../../actions/UserPreferenceActionsCreator');
var ComponentActionsCreator = require('../../../actions/ComponentActionsCreator');

var DomElementUtils = require('../../../utils/DomElementUtils');

/** @module TextTools
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires FontSizes
 * @requires PredefinedFonts
 * @requires DropDown
 * @requires Tabs
 * @requires ColorPicker
 * @requires UriAnchorTools
 * @requires ChordAnchorTools
 * @requires EmailAnchorTools
 * @requires UserPreferenceStore
 * @requires PageStore
 * @requires ComponentMetadataStore
 * @requires UserPreferenceActionsCreator
 */
var TextTools = ComponentFactory.Config({pure: true}).Create(UserPreferenceStore, PageStore, ComponentMetadataStore, {
	displayName: "TextTools",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		propertyInfo: React.PropTypes.object.isRequired,
		onCommand: React.PropTypes.func.isRequired
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		var state = this.getState();
		state.selection = this.props.selection || {};
		return state;
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var page = PageStore.getState(this.props.pageId);
		var pageFonts =  _(page && page.getReferences())
			.map(function (componentRef) {
				return ComponentMetadataStore.getComponentMetadata(componentRef)
			})
			.filter({'componentCategory': 'font'})
			.map('reference.name')
			.value();
		var fontComponents = _(ComponentMetadataStore.getComponents())
			.filter({'componentCategory': 'font'})
			.value();
		var allFonts =  _(fontComponents)
			.map('reference.name')
			.value();
		return {
			previousColors: UserPreferenceStore.getState('text_colors'),
			pageFonts: pageFonts,
			allFonts: allFonts,
			fontComponents: fontComponents
		};
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this._onChangeColorDebounced = _.debounce(this._onChangeColor, 100);
		this._sendCommand('selectAll', { componentId: this.props.propertyInfo.componentId, path: this.props.propertyInfo.path });
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function() {
		this._sendCommand('removeSelection');
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props) {
		if(!_.isEqual(props, this.props)) {
			this.setState({ selection: this.props.selection || {} });
			//IE11 will not do a separate selection between iframe and editor
			//so we need to tell the iframe to save the current selection
			this._sendCommand('backupRange');
		}
	},

	/**
	 * @method _sendCommand
	 */
	_sendCommand: function(command, parameters) {
		this.props.onCommand({ command: command, parameters: parameters });
	},

	_createElements: function optionElements(options) {
		return _.map(options, (option) => {
			return (<span key={option} className='value' unselectable='on'>{option}</span>);
		});
	},

	/**
	 * @method _createOptions
	 */
	_createOptions: function(initial, selected) {
		var options = _(initial)
				.union([selected])
				.compact()
				.sortBy()
				.value(),
			elOptions = this._createElements(options);

		return {
			options: elOptions,
			selected: elOptions[_.indexOf(options, selected)]
		};
	},

	/**
	 * @method _saveLink
	 */
	_saveLink: function(href) {
		//IE11 will not do a separate selection between iframe and editor
		//so we need to tell the iframe to restore the previous selection
		this._sendCommand('restoreRange');
		if (href) {
			this._sendCommand('CreateLink', href);
		} else {
			this._sendCommand('removeFormatting');
		}
	},

	/**
	 * @method _expandLink
	 */
	_expandLink: function(e) {
		this.setState({ linkExpanded: !this.state.linkExpanded, colorExpanded: false });
	},

	/**
	 * @method _expandColor
	 */
	_expandColor: function() {
		this.setState({ colorExpanded: !this.state.colorExpanded, linkExpanded: false });
	},

	/**
	 * @method _onChangeSize
	 */
	_onChangeSize: function(element) {
		if (!element) return;
		this._sendCommand('fontsize', element.key);
	},

	/**
	 * @method _onSizeInputChange
	 */
	_onSizeInputChange: function(size) {
		this._sendCommand('fontsize', size);
	},

	/**
	 * @method _onChangeFont
	 */
	_onChangeFont: function(element) {
		if (!element) return;
		this._sendCommand('fontname', element.key);
		//add font chord if not already included on page
		if (_.union(PredefinedFonts, this.state.pageFonts).indexOf(element.key) === -1) {
			var reference = _.filter(this.state.fontComponents, {'reference': {'name': element.key}})[0].reference;
			// {wait: 2000, trailing: true} is a hack to keep from retrieving old page from server before we push the font
			// change there
			ComponentActionsCreator.createComponentInstance(this.props.pageId, reference, 0, { wait: 2000, trailing: true });
		}
	},

	/**
	 * @method _checkboxCommand
	 */
	_checkboxCommand: function(e) {
		e.preventDefault();
		e.stopPropagation();

		var command = e.currentTarget.getAttribute('data-command');
		this._sendCommand(command);
		this._inversCheckbox(command);
	},

	/**
	 * @method _inversCheckbox
	 */
	_inversCheckbox: function(command) {
		var selection = _.clone(this.state.selection);
		selection[command] = !selection[command];
		this.setState({ selection: selection });
	},

	/**
	 * @method _colorInput
	 */
	_onChangeColorInput: function(e){
		e.persist();
		this._onChangeColorDebounced(e.target.value);
	},

	/**
	 * @method _onChangeColor
	 */
	_onChangeColor: function(color) {
		UserPreferenceActionsCreator.changeTextColor(color);
		this._sendCommand('forecolor', color);
	},

	_getLinkDefaultTabKey:function (link){
		let linkDefaultTabKey = 'URL';
		if (link){
			if (link.match(/^#[0-9]+$/g)) linkDefaultTabKey = 'Anchor';
			else if (link.match(/^\/\w*$/g)) linkDefaultTabKey = 'Page';
			else if (link.startsWith('mailto:')) linkDefaultTabKey = 'Email';
		}
		return linkDefaultTabKey;
	},

	/**
	 * @method render
	 */
	render: function () {
		var selection = this.state.selection;
		var fontsizes = this._createOptions(FontSizes, +selection.fontsize);
		var fontnames = this._createOptions(_.union(PredefinedFonts, this.state.pageFonts, this.state.allFonts), selection.fontname);
		var themeColors = ['#2f323b', '#f4f4f4', '#9c9c9c', '#515151', '#3e95be'];
		// TODO: use some clever logic once theme colors are done
		var userColors = this.state.previousColors || themeColors;
		return (
			<div className='text-tools'>
				<div className="text-tools-main">
					<div onClick={this._checkboxCommand}
						data-command='justifyLeft'
						className='align-left-cmd'
						unselectable='on'></div>
					<div onClick={this._checkboxCommand}
						data-command='justifyCenter'
						className='align-center-cmd'
						unselectable='on'></div>
					<div onClick={this._checkboxCommand}
						data-command='justifyRight'
						className='align-right-cmd'
						unselectable='on'></div>
					<div onClick={this._checkboxCommand}
						data-command='justifyFull'
						className='seporated-right format-align-justify-cmd'
						unselectable='on'></div>

					<div onClick={this._checkboxCommand}
						data-command='bold'
						className={this.css('seporated-left', 'format-bold-cmd', { active: selection.bold })}
						unselectable='on'></div>
					<div onClick={this._checkboxCommand}
						data-command='italic'
						className={this.css('format-italic-cmd', { active: selection.italic })}
						unselectable='on'></div>
					<div onClick={this._checkboxCommand}
						data-command='textShadow'
						className={this.css('seporated-right', 'format-textshadow-cmd', { active: selection.textShadow })}
						unselectable='on'></div>

					<div className='fontsizes seporated-left tool-dropdown' unselectable='on'>
						<FontSizeInput initialValue={selection.fontsize} onChange={this._onSizeInputChange}/>
						<DropDown onChange={this._onChangeSize} active={fontsizes.selected} unselectable='on'>
							{fontsizes.options}
						</DropDown>
					</div>
					<div className='fontnames sepadated-right tool-dropdown' unselectable='on'>
						<DropDown onChange={this._onChangeFont} active={fontnames.selected} unselectable='on'>
							{fontnames.options}
						</DropDown>
					</div>

					<div onClick={this._checkboxCommand}
						data-command='insertUnorderedList'
						className={this.css('seporated-left', 'format-list-bulleted-cmd')}
						unselectable='on'></div>
					<div onClick={this._checkboxCommand}
						data-command='insertOrderedList'
						className={this.css('seporated-right', 'format-list-numbers-cmd')}
						unselectable='on'></div>

					<div onClick={this._expandLink}
						className={this.css('sepodated-left', 'link-variant-cmd', { active: selection.link || this.state.linkExpanded })}
						unselectable='on'></div>

					<div onClick={this._expandColor}
						className={this.css('colors-cmd', { active: this.state.colorExpanded })}
						style={{color: selection.forecolor}}
						unselectable='on'></div>

				</div>
				<div className={this.css("text-tools-collapsible", { expanded: this.state.colorExpanded })}>
					<Tabs>
						<div key='Custom Color' className="tabs-content-color" unselectable='on'>
							<div className="row">
								<p className="hint small-6 columns">Use the color slider or manually enter a color value</p>
								<input
									className="color-input small-6 columns"
									type="text"
									onChange={this._onChangeColorInput}
									placeholder={selection.forecolor ? selection.forecolor : "#ffffff"} />
							</div>
							<ColorPicker
								range={selection.range}
								color={selection.forecolor}
								onChange={this._onChangeColorDebounced}
								allowAlpha={!DomElementUtils.IsIE()} />
						</div>
						<div key='Previous Color' className="tabs-content-color" unselectable='on'>
							<p className="hint">Previous selected custom colors</p>
							{_.map(userColors, (color) => {
								return <div className="color-example"
									key={color}
									onClick={_.partial(this._onChangeColor, color)}
									style={{backgroundColor: color}}></div>;
							})}
						</div>
					</Tabs>
				</div>
				<div className={this.css("text-tools-collapsible", { expanded: this.state.linkExpanded })}>
					{_.get(selection, ['range', 'collapsed']) ? <p className="centered" >Please select text to link</p> :
						<Tabs activeKey={this._getLinkDefaultTabKey(selection.link)}>
							<div key='URL' unselectable='on'>
								<UriAnchorTools
									href={selection.link}
									onChange={this._saveLink} />
							</div>
							<div key='Anchor' unselectable='on'>
								<ChordAnchorTools
									href={selection.link}
									pageId={this.props.pageId}
									onChange={this._saveLink} />
							</div>
							<div key='Page' unselectable='on'>
								<PageLinkTools
									href={selection.link}
									pageId={this.props.pageId}
									onChange={this._saveLink} />
							</div>
							<div key='Email' unselectable='on'>
								<EmailAnchorTools
									href={selection.link}
									pageId={this.props.pageId}
									onChange={this._saveLink} />
							</div>
						</Tabs>
					}
				</div>
			</div>
		);
	}
});

var FontSizeInput = React.createClass({
	propTypes: {
		initialValue: React.PropTypes.string,
		onChange: React.PropTypes.func.isRequired
	},

	getInitialState:  function () {
		return { value: this.props.initialValue}
	},

	/**
	 * @method componentWillReceiveProps
	 */
	componentWillReceiveProps: function(nextProps) {
		this.setState({
			value: nextProps.initialValue
		});
	},

	/**
	 * @method _onKeyDown
	 */
	_onKeyDown: function(e) {
		var ENTER = 13;
		if((e.keyCode === ENTER || e.which === 13) && e.target.value > 0) {
			this.props.onChange(e.target.value)
		}
	},

	/**
	 * @method _onChange
	 */
	_onChange: function(e) {
		var value = e.target.value.replace(/\D/g, '');
		this.setState({value: value});
	},

	/**
	 * @method render
	 */
	render: function () {
		return (
			<input type='text' maxLength='3' placeholder='---'
				value={this.state.value} onChange={this._onChange} onKeyDown={this._onKeyDown} unselectable='on'/>
		);
	}
});

module.exports = TextTools;
