'use strict';

var Mapper = require('../utils/Mapper');

/** @module ComponentCategories
 * @requires Mapper
 */
module.exports = Mapper.mirrorKey({
	header: null,
	hero: null,
	about: null,
	gallery: null,
	contact: null,
	footer: null,
	system: null,
	skin: null,
	font: null,
	video: null,
	audio: null,
	grid: null,
	testing: null
});
