'use strict';

import { map } from 'lodash';

var Validator = require('../utils/Validator');
var Shapes = require('../utils/Shapes');

var ComponentDomState = require('./ComponentDomState');

function _convert(componentDomState) {
	return new ComponentDomState(componentDomState);
}

/** @module PageDomState
 * @requires lodash
 * @requires Validator
 * @requires Shapes
 * @requires ComponentDomState
 */
function PageDomState(dto) {
	Validator.isTypeOf(dto, 'object', ['page']);
	Validator.isArrayOfType(dto, 'object', ['components']);

	this.page = new Shapes.Rectangle(dto.page);
	this.components = map(dto.components, _convert);
	this.selection = dto.selection;
}

module.exports = PageDomState;
