import { isEmpty, kebabCase } from 'lodash';

import React from 'react';
import {Container} from 'flux/utils';

import WebsitesStore, {WebsitesLoadingStatusStore} from '../../stores/WebsitesStore';
import AvailableDomainsStore, {AvailableDomainsLoadingStatusStore} from '../../stores/AvailableDomainsStore';
import PageProjectStore, {PageProjectsLoadingStatusStore, PageProjectCreatingStatusStore} from '../../stores/PageProjectStore';
import TrialStatusStore from '../../stores/TrialStatusStore';

import RouteStore from '../../stores/RouteStore';

import WebsiteActionsCreator from '../../actions/WebsiteActionsCreator';
import AvailableDomainActionsCreator from '../../actions/AvailableDomainActionsCreator';
import PageProjectActionsCreator from '../../actions/PageProjectActionsCreator';

import ImageEditor from '../image-editor/ImageEditor.react';
import PageEditor from '../PageEditor.react';
import UndoRedo from '../UndoRedo.react';
import WebsitePageList from '../WebsitePageList.react';
import PageSettingsForm from '../PageSettingsForm.react';
import PageContext from '../PageContext.react';
import WebsiteAvailableDomainForm from '../WebsiteAvailableDomainForm.react';

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

class Editor extends React.Component {
	static propTypes = {
		websiteId: React.PropTypes.number.isRequired,
		pageProjectId: React.PropTypes.number
	};

	static getStores() {
		return [
			WebsitesStore,
			AvailableDomainsStore,
			PageProjectStore,
			WebsitesLoadingStatusStore,
			AvailableDomainsLoadingStatusStore,
			PageProjectsLoadingStatusStore,
			PageProjectCreatingStatusStore,
			TrialStatusStore
		];
	}

	static _getState(props) {
		let website = WebsitesStore.getState().get(props.websiteId);
		let pageProjectId = props.pageProjectId || (website && website.mainPageProjectId);
		return {
			website: website,
			pageProject: PageProjectStore.getState().get(pageProjectId),
			pageProjectList: PageProjectStore.getState().filter((project) => project.websiteId == props.websiteId),
			availableDomainList: AvailableDomainsStore.getState(),
			loading: WebsitesLoadingStatusStore.getState().loading || PageProjectsLoadingStatusStore.getState().loading,
			pageCreating: PageProjectCreatingStatusStore.getState().loading,
			trialStatus: TrialStatusStore.getState()
		};
	}

	static calculateState(prevState, props) {
		return Editor._getState(props);
	}
	componentWillReceiveProps(nextProps) {
		this.setState(Editor._getState(nextProps));
	}

	componentDidMount() {
		this.setState({
			loading: true
		});
		WebsiteActionsCreator.loadWebsites(true);
		!isEmpty(this.state.pageProject) && ComponentActionsCreator.loadPage(this.state.pageProject.pageId, true);
		AvailableDomainActionsCreator.loadAvailableDomains();
		PageProjectActionsCreator.loadPageProjects(true);
	}
	guessTitle() {
		const titles = [
			"About", "Contacts", ""
		];
		let title = titles[this.state.pageProjectList.size - 1];
		if (title) {
			return title;
		}
		let i = 2;
		do {
			let titleNumber = "My Page " + i;
			if (!this.state.pageProjectList.find((project) => project.title == titleNumber)) {
				return titleNumber;
			}
		} while (++i);
	}
	guessSlug(title) {
		let slug = kebabCase(title);
		let i = '';
		do {
			let slugNumber = slug + i;
			if (!this.state.pageProjectList.find((project) => project.slug == slugNumber)) {
				return slugNumber;
			}
		} while (++i);
	}
	handleClonePageProject = (pageProject) => {
		let title = pageProject.title + " (Clone)";
		let slug = this.guessSlug(title);
		PageProjectActionsCreator.clonePageProject(
			this.state.website,
			pageProject,
			title,
			pageProject.description,
			slug
		)
	}

	handleCreatePageProject = () => {
		if (this.state.pageCreating) return;

		let title = this.guessTitle();
		let slug = this.guessSlug(title);
		PageProjectActionsCreator.createPageProject(
			this.state.website,
			title,
			"",
			slug
		)
	}
	handleDeletePageProject = (pageProject) => {
		if (pageProject.id == this.state.pageProject.id) {
			RouteStore.getState('route').transitionTo("layout", {websiteId: this.state.website.id})
		}
		PageProjectActionsCreator.deletePageProject(pageProject);
	}
	handleChangePageProject = (pageProject) => {
		PageProjectActionsCreator.updatePageProject(pageProject);
	}
	handleValidatePageProject = (key, value) => {
		if (key == "slug") {
			if (!value ||
				value == "index" ||
				!value.match(/^[a-zA-Z0-9_-]{1,255}$/) ||
				this.state.pageProjectList.find((project) => project != this.state.pageProject && project.slug == value)) {
				return false;
			}
		}
		return true;
	}
	handleChangeWebsite = (website) => {
		WebsiteActionsCreator.updateWebsite(website);
	}
	handleFilterWebsite = (key, value) => {
		if (key == "domain") {
			return value || null;
		}
		return value;
	}
	handleValidateWebsite = (key, value) => {
		if (key == "domain") {
			if (value) {
				return ([0, 4].indexOf(this.state.availableDomainList.find((domain) => domain.domain == value).errorCode) !== -1
					|| this.state.website.domain == value);
			}
		}
		return true;
	}
	handleAvailableDomainsRefresh = () => {
		AvailableDomainActionsCreator.loadAvailableDomains(true)
	}
	render() {
		return (!this.state.loading && this.state.pageProject) ?
			<div className="layout-management-page">
				<div className="page-structure">
					<div className="website-directory">
						<div className="small-heading">Website Directory</div>
						<p className="tiny-heading">Assign a custom domain to this website:</p>

						<WebsiteAvailableDomainForm
							availableDomainList={this.state.availableDomainList}
							model={this.state.website}
							accountStatus={this.state.trialStatus}
							onSave={this.handleChangeWebsite}
							onFilter={this.handleFilterWebsite}
							onValidate={this.handleValidateWebsite}
							onRefresh={this.handleAvailableDomainsRefresh} />

						<WebsitePageList
							activePageProject={this.state.pageProject}
							pageProjectList={this.state.pageProjectList}
							website={this.state.website}
							onDelete={this.handleDeletePageProject}
							onClone={this.handleClonePageProject} />
						<div className="text-center">
							<button className={'button small' + (this.state.pageCreating ? ' disabled' : '')}
								onClick={this.handleCreatePageProject}>
								{this.state.pageCreating ? ' Creating New Page ...' : 'Create New Page'}
							</button>
						</div>
					</div>
					<PageSettingsForm
						model={this.state.pageProject}
						onValidate={this.handleValidatePageProject}
						onSave={this.handleChangePageProject} />
					<PageContext pageId={this.state.pageProject.pageId} />
				</div>
				<ImageEditor websiteId={this.props.websiteId} />
				<PageEditor pageId={this.state.pageProject.pageId} />
				<UndoRedo pageId={this.state.pageProject.pageId} />
			</div>
			:
			<div className="loader-wrapper">
				<span className="loader"></span>
			</div>
	}
}

export default Container.create(Editor, {withProps: true});
