'use strict';

/** @module param
 */
// created since jquery.param does not work in non-browser context (node)
module.exports = function param(source) {
	var array = [];
	for(var key in source) {
		array.push(encodeURIComponent(key) + '=' + encodeURIComponent(source[key]));
	}
	return array.join('&');
};
