'use strict';

import _, { filter, map } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');
var PageStore = require('../../../stores/PageStore');
var ComponentMetadataStore = require('../../../stores/ComponentMetadataStore');
var ComponentTypes = require('../../../constants/ComponentTypes');

var AnchorLink = ComponentFactory.Create( {
	displayName: "AnchorLink",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		handleChange: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		var components = PageStore.getState(this.props.pageId, 'components');
		var chords = _(components)
			.map(this._joinMetadata)
			.filter(x => x.metadata.componentType == ComponentTypes.content)
			.map('instance')
			.value()
		return {
			curr_id: this.props.currentLinkURL.replace(/^#/, '') || chords[0].id,
			curr_name: this._getChordNameById(chords,chords[0].id),
			prefix: '#',
			chords: chords
		};
	},

	_getChordNameById: function(chords,id) {
		var chord = filter(chords, function(o) { return o.id==id })[0];
		return chord.reference.name;
	},

	_joinMetadata: function(instance) {
		return {
			instance: instance,
			metadata: ComponentMetadataStore.getComponentMetadata(instance.reference)
		};
	},

	_handleInputChange: function(component) {
		var value = component.target.value;
		var url = this.state.prefix+value;
		this.setState({curr_id: value})
		this.setState({curr_name: this._getChordNameById(this.state.chords,value)})
		this.props.handleChange(url);
	},


	/**
	 * @method render
	 */
	render: function() {
		var options = map(this.state.chords, function(chord) {
			return (<option value={chord.id} >{chord.reference.name}</option>);
		});
		return (
			<div className="anchor-input">
				<p className="hint">Select a component for this to scroll/position to</p>
				<select onChange={this._handleInputChange} value={this.state.curr_id} >
					{options}
				</select>
			</div>
		)
	}
});

module.exports = AnchorLink;
