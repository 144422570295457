'use strict';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

/** @module ContentOverlay
 * @requires react
 * @requires ComponentFactory
 */
var ContentOverlay = ComponentFactory.Create({
	displayName: "ContentOverlay",
	/**
	 * @method render
	 */
	render: function() {
		if (!this.props.show) {
			return null;
		}
		var style = {
			left: 0,
			top: this.props.componentRectangle.position.top,
			height: this.props.componentRectangle.size.height
		};
		return (
			<div style={style} className="content-overlay">
				<div className="content-overlay-inner">
					{this.props.children}
				</div>
			</div>
		);
	}
});

module.exports = ContentOverlay;
