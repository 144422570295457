'use strict';

import { partial, identity, bind, get, isUndefined, isFunction, has } from 'lodash';

var AppDispatcher = require('../dispatcher/AppDispatcher');

var FluxStore = require('./FluxStore');
var FluxStoreState = require('./FluxStoreState');

var Validator = require('../utils/Validator');

var _empty = partial(identity, {});

/** @module FluxStoreRegister
 * @requires lodash
 * @requires AppDispatcher
 * @requires FluxStore
 * @requires FluxStoreState
 * @requires Validator
 */
function FluxStoreRegister() {
	var waitFor = bind(AppDispatcher.waitFor, AppDispatcher);

	this.register = function(store, state) {
		Validator.isInstanceOf(store, FluxStore);
		Validator.isInstanceOf(state, FluxStoreState);

		if(has(state, '_getHandlers')) {
			Validator.isTypeOf(state, 'function', ['_getHandlers']);
		} else {
			Validator.isTypeOf(state, 'function', ['_globalHandler']);
		}

		//expose AppDispatcher.waitFor
		state.waitFor = waitFor;

		var handlers = get(state, '_getHandlers', _empty).call(state);

		store.token = AppDispatcher.register(function(action) {
			if (isUndefined(action.actionType)) {
				throw "invalid action is performed";
			}

			var handler = handlers[action.actionType] || state._globalHandler;
			if (isFunction(handler)) {
				handler.apply(state, arguments);
				store.emitChange();
			}
		});

		// allows react UI to subscribe to store-specific events
		store.registerRemixerEvents();
	};
}

module.exports = FluxStoreRegister;
