'use strict';

import { isEqual, trim } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var UrlUtils = require('../../../utils/UrlUtils');

/** @module EmailAnchorTools
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires UrlUtils
 */
var EmailAnchorTools = ComponentFactory.Create({
	displayName: "EmailAnchorTools",
	propTypes: {
		onChange: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		return this._createState();
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		if (isEqual(this.props.href, this.state.href)) return;

		var isPropsChange = !isEqual(props, this.props);
		if (isPropsChange) {
			this.setState(this._createState());
		}
	},

	/**
	 * @method _createState
	 */
	_createState: function() {
		var uri = trim(this.props.href, '/');
		var text = UrlUtils.removeUriScheme(uri);
		var invalid = uri && !UrlUtils.isValidEmail(text);

		return {
			text: text,
			link: UrlUtils.addMailtoScheme(uri),
			invalid: invalid
		};
	},

	/**
	 * @method _onChangeLink
	 */
	_onChangeLink: function(ev) {
		var text = ev.target.value;
		var link = UrlUtils.addMailtoScheme(text)
		this.setState({
			text: text,
			link: link,
			invalid: link && !UrlUtils.isValidEmail(text)
		});
	},

	/**
	 * @method _saveLink
	 */
	_saveLink: function(ev) {
		ev.preventDefault();

		if (this.state.invalid) return;

		var href = this.state.link && UrlUtils.normalizeUrl(this.state.link);
		if(this.props.onChange) this.props.onChange(href);
	},

	/**
	 * @method render
	 */
	render: function () {
		return (<div className="tabs-content-link">
			<p className="hint">Enter an email address to which this text should link</p>
			<div className="url-input">
				<span className="url-input-prefix">{'mailto:'}</span>
				<input className={this.css({ invalid: this.state.invalid })}
					type="text"
					value={this.state.text}
					onChange={this._onChangeLink}
					placeholder="Enter your email address..." />
				<span onClick={this._saveLink} className={this.css("save", { disable: this.state.invalid })}>Save</span>
			</div>
		</div>);
	}
});

module.exports = EmailAnchorTools;
