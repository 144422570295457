'use strict';

import { values, get } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImageImporterToolBar = require('./content-toolbars/ImageImporterToolBar.react');
var VideoImporterToolBar = require('./content-toolbars/VideoImporterToolBar.react');
var AudioImporterToolBar = require('./content-toolbars/AudioImporterToolBar.react');
var MarkdownToolbar = require('./content-toolbars/MarkdownToolbar.react');

var ContentManagementTabs = require('../../constants/ContentManagementTabs');

var strategies = {};
strategies[ContentManagementTabs.IMAGES] = ImageImporterToolBar;
strategies[ContentManagementTabs.VIDEOS] = VideoImporterToolBar;
strategies[ContentManagementTabs.AUDIOS] = AudioImporterToolBar;
strategies[ContentManagementTabs.MARKDOWN_TEXTS] = MarkdownToolbar;

/** @module ContentToolbar
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ImageImporterToolBar
 * @requires VideoImporterToolBar
 * @requires AudioImporterToolBar
 * @requires MarkdownToolbar
 * @requires ContentManagementTabs
 */
var ContentToolbar = ComponentFactory.Create({
	displayName: "ContentToolbar",
	propTypes: {
		strategy: React.PropTypes.oneOf(values(ContentManagementTabs)).isRequired
	},

	/**
	 * @method render
	 */
	render: function() {
		var TypedToolbar = get(strategies, this.props.strategy);
		return (TypedToolbar ?
			<TypedToolbar />
			:
			null
		);
	}
});

module.exports = ContentToolbar;
