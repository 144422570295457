import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';


const YoutubeSearchParams = Immutable.Record({
	query: "",
	page: null
});


class YoutubeSearchParamsStore extends ReduceStore<YoutubeSearchParams> {
	getInitialState() {
		return new YoutubeSearchParams();
	}

	reduce(state: YoutubeSearchParams, action: Object): YoutubeSearchParams {
		const {actionType, query, page} = action;
		switch (actionType) {
			case RemixerConstants.SEARCH_YOUTUBE: return state.merge({query, page});
			default: return state;
		}
	}
}

export default new YoutubeSearchParamsStore(AppDispatcher);
