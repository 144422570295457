'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var UrlUtils = require('../utils/UrlUtils');

/** @module VimeoServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires UrlUtils
 */
var VimeoServerActionsCreator = {
	/**
	 * @method receiveVimeoAuthorizationUrl
	 */
	receiveVimeoAuthorizationUrl: function(result) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_VIMEO_AUTHORIZATION_URL,
			result: result.data
		});

		UrlUtils.navigateTo(result.data.authorization_url);
	},
	/**
	 * @method receiveVimeoAuthorizationUrlFails
	 */
	receiveVimeoAuthorizationUrlFails: function(error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_VIMEO_AUTHORIZATION_URL_FAILS,
			message: 'could not get vimeo authorization url'
		});
	}
};

module.exports = VimeoServerActionsCreator;
