'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var WebAPI = require('../utils/WebAPI');

/** @module FlickrActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var FlickrActionsCreator = {
	/**
	 * @method requestFlickrAuthorizationUrl
	 */
	requestFlickrAuthorizationUrl: function(returnToUrl) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REQUEST_FLICKR_AUTHORIZATION_URL,
			returnTo: returnToUrl
		},
		WebAPI.getFlickrAuthorizationUrl, [returnToUrl]);
	}
};

module.exports = FlickrActionsCreator;
