'use strict';

// polyfill for canvas.toBlob
require('blueimp-canvas-to-blob');
import { assign } from 'lodash';

/** @module canvas
 * @requires blueimp-canvas-to-blob
 * @requires lodash
 */

function drawImage(params) {
	params.context.drawImage(
		params.image,
		params.sx,
		params.sy,
		params.sWidth,
		params.sHeight,
		params.dx,
		params.dy,
		params.dWidth,
		params.dHeight
	);
}

function applyRotateToCanvas(canvas, rotation) {
	var context = canvas.getContext('2d');

	var tempCanvas = document.createElement('canvas');
	tempCanvas.width = canvas.width;
	tempCanvas.height = canvas.height;
	tempCanvas.getContext('2d').drawImage(canvas, 0, 0);

	var dWidth = canvas.width;
	var dHeight = canvas.height;

	canvas.width = ((dWidth)*Math.abs(Math.cos(rotation*Math.PI/180)))+((dHeight)*Math.abs(Math.sin(rotation*Math.PI/180)));
	canvas.height = ((dWidth)*Math.abs(Math.sin(rotation*Math.PI/180)))+((dHeight)*Math.abs(Math.cos(rotation*Math.PI/180)));
	var newwidth = ((dWidth)*Math.cos(rotation*Math.PI/180))+((dHeight)*Math.sin(rotation*Math.PI/180));

	context.save();
	context.translate(dWidth/2, dHeight/2);
	context.rotate(rotation*Math.PI/180);

	var offset = Math.sin(rotation*Math.PI/180) * (dWidth - Math.sin(rotation*Math.PI/180) * newwidth);
	context.translate(-((dWidth-offset)/2), -((dHeight-offset)/2));

	context.drawImage(tempCanvas, 0, 0);
	context.restore();
	return canvas;
}

function applyFlipToCanvas(canvas) {
	var context = canvas.getContext('2d');

	var tempCanvas = document.createElement('canvas');
	tempCanvas.width = canvas.width;
	tempCanvas.height = canvas.height;
	tempCanvas.getContext('2d').drawImage(canvas, 0, 0);

	context.save();
	context.clearRect(0,0, canvas.width, canvas.height);
	context.scale(-1, 1);
	context.translate(-canvas.width, 0);
	context.drawImage(tempCanvas, 0, 0);
	context.restore();
	return canvas;
}

function imageCropToCanvas(params) {
	var canvas = document.createElement('canvas');
	var context = canvas.getContext('2d');

	canvas.width = params.dWidth;
	canvas.height = params.dHeight;

	drawImage(assign({}, params, { context: context}));
	return canvas;
}

function upload(params) {
	// validate (should use npm jsonschema instead)
	var canvas = params.canvas;
	var signed = params.signed;
	var fileName = params.fileName;
	var contentType = params.contentType || 'image/jpeg';
	var xhr = params.previousXhr;
	var onReady = params.onReady;
	var onError = params.onError;

	if (xhr) xhr.abort();
	xhr = new XMLHttpRequest();

	var paramsOut = assign({}, params);

	paramsOut.key = signed.prefix + '/' + fileName;
	paramsOut.url = signed.baseUrl + '/' + fileName;

	canvas.toBlob(function uploadBlob(blob) {
		var formData = new FormData();

		formData.append('key', paramsOut.key);
		formData.append('AWSAccessKeyId', signed.awsAccessKey);
		formData.append('acl', 'public-read');
		formData.append('policy', signed.policy);
		formData.append('signature', signed.signature);
		formData.append('Content-Type', contentType);
		formData.append('file', blob);

		xhr.open('POST', signed.postUrl, true);

		if (params.onProgress) xhr.upload.addEventListener('progress', params.onProgress, false);

		xhr.onreadystatechange = function(ev) {
			if (xhr.readyState !== 4) return;
			if (xhr.status >= 200 && xhr.status < 300) onReady(paramsOut);
			else onError(ev, paramsOut);
		};

		xhr.send(formData);
	}, contentType);

	return xhr;
}

// KISS principle: https://en.wikipedia.org/wiki/KISS_principle
// basic singleton is only what is needed at this time
module.exports = {
	imageCropToCanvas: imageCropToCanvas,
	upload: upload,
	applyRotateToCanvas: applyRotateToCanvas,
	applyFlipToCanvas: applyFlipToCanvas
};
