import React from 'react';

import PreloadedImg from './PreloadedImg.react';

export default class PixabaySearchResultList extends React.Component {
	static propTypes = {
		searchResult: React.PropTypes.object.isRequired,
		onPick: React.PropTypes.func.isRequired
	};

	render() {
		const {searchResult, onPick} = this.props;

		return (
			<PreloadedImg
				onClick={onPick}
				className="pixabay-search-thumbnail"
				imageUrl={searchResult.thumbnail_url}
				showLoadingPlaceholder={false}
				component="div"
			>
			</PreloadedImg>
		);
	}
}
