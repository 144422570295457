'use strict'

import { debounce, uniqueId, map, zipObject, cloneDeep, set } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

var PropertyMetadata = require('../../models/PropertyMetadata');
var ComponentInstance = require('../../models/ComponentInstance');

const debouncedUpdateComponentInstanceContext = debounce((c) => ComponentActionsCreator.updateComponentInstanceContext(c));

var RadioInput = ComponentFactory.Create({
	displayName: "RadioInput",
	propTypes: {
		property: React.PropTypes.instanceOf(PropertyMetadata).isRequired,
		component: React.PropTypes.instanceOf(ComponentInstance).isRequired
	},
	componentDidMount: function (props) {
		this._name = uniqueId();
	},
	_onChange: function (e) {
		var component = cloneDeep(this.props.component);
		set(component, ['context', this.props.property.name], e.target.value);
		debouncedUpdateComponentInstanceContext(component);
	},
	render: function () {
		var options = this.props.property.options;
		var ids = zipObject(this.props.property.options, map(this.props.property.options, uniqueId));
		var name = this._name;
		var Options = map(
			options,
			(o) => {
				return (
					<div>
						<input type="radio" value={o} name={name} id={ids[o]} onChange={this._onChange} checked={this.props.component.context[this.props.property.name] === o} />
						<label htmlFor={ids[o]}>{o}</label>
					</div>
				)}
		);
		return (
			<div>
				<span>{this.props.property.description}</span>
				{Options}
			</div>
		)
	}
})

module.exports = RadioInput;
