'use strict';

var _ = require('lodash');
var $ = require('jquery');

var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentContextStore = require('../../stores/ComponentContextStore');
var ContentFilterStore = require('../../stores/ContentFilterStore');
var ContentStore = require('../../stores/ContentStore');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');

var ContentTypes = require('../../constants/ContentTypes');
var ImportTypes = require('../../constants/ImportTypes');
var PropertyTypes = require('../../constants/PropertyTypes');

var DropDown = require('../common/DropDown.react');

var ContentFilterTypes = require('../../constants/ContentFilterTypes');

var __t = {
	'LAST_HOUR': "Last hour",
	'TODAY': "Today",
	'YESTERDAY': "Yesterday",
	'THIS_WEEK': "This week",
	'THIS_MONTH': "This month",
	'THIS_YEAR': "This year"
};

_.forEach(ImportTypes, function (importType) {
	__t[importType.name] = importType.displayName;
});

var _t = function(text) {
	return __t[text] || text;
};


// TODO: fix FilteredContentStore
/** @module ImageLibrary
 * @requires lodash
 * @requires jquery
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires ContentFilterStore
 * @requires ContentStore
 * @requires ComponentActionsCreator
 * @requires ContentActionsCreator
 * @requires ContentTypes
 * @requires ImportTypes
 * @requires PropertyTypes
 * @requires DropDown
 * @requires ImagesUploader
 * @requires ContentFilterTypes
 */
var ImageLibrary = ComponentFactory.Create(ComponentContextStore, ContentFilterStore, ContentStore, {
	displayName: "ImageLibrary",
	propTypes: {
		propertyPath: React.PropTypes.string.isRequired,
		propertyType: React.PropTypes.oneOf([PropertyTypes.image, PropertyTypes.arrayOfImage]).isRequired,
		openImageImporterModal: React.PropTypes.func
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			value: ComponentContextStore.getPropertyValue(_.initial(_.split(this.props.propertyPath, '.'))),
			content: ContentStore.getState(ContentTypes.IMAGE),
			filters: _.union(
				ContentFilterStore.getState(ContentFilterTypes.DATE),
				ContentFilterStore.getState(ContentFilterTypes.IMPORT_TYPE),
				ContentFilterStore.getState(ContentFilterTypes.GROUP),
				ContentFilterStore.getState(ContentFilterTypes.TAG))
		};
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		if(!_.isUndefined(prevState) && !_.isEqual(this.state.value, prevState.value)) {
			var component = ComponentContextStore.getState('active');
			var path = _.initial(_.split(this.props.propertyPath, '.'));
			if(!_.isEqual(_.get(component.context, path), this.state.value)) {
				_.set(component.context, path, this.state.value);

				ComponentActionsCreator.updateComponentInstanceContext(component);
			}
		}

		ContentActionsCreator.loadContent(ContentTypes.IMAGE);
	},

	/**
	 * @method _singleSelectClick
	 */
	_singleSelectClick: function(image) {
		this.setState({ value: image });
	},

	/**
	 * @method _multiSelectClick
	 */
	_multiSelectClick: function(image) {
		this.setState({ value: _.union(this.state.value, [image]) });
	},

	/**
	 * @method _onDeleteClick
	 */
	_onDeleteClick: function(image) {
		var value = this.state.value;
		//at least one element should be in a list
		if (_.isArray(value) && value.length === 1) return;

		this.setState({ value: _.without(value, image) });
	},

	/**
	 * @method _onSelectClick
	 */
	_onSelectClick: function(image) {
		if (this.props.propertyType === PropertyTypes.image) {
			this._singleSelectClick(image);
		} else {
			this._multiSelectClick(image);
		}
	},

	/**
	 * @method _isImagePicked
	 */
	_isImagePicked: function(image) {
		return _.some(this.state.value, _.partial(_.isEqual, image)) || _.isEqual(this.state.value, image);
	},

	/**
	 * @method _onFilterChange
	 */
	_onFilterChange: function(component) {
		this.setState({ filter: _.get(component, ['props', 'value'], null) });
	},

	/**
	 * @method _onUploadStatusChanged
	 */
	_onUploadStatusChanged: function(status) {
		this.setState({ uploadStatus: status });
	},

	/**
	 * @method _scroll
	 */
	_scroll: function(ev, delta) {
		var el = ReactDOM.findDOMNode(this.refs.picked);

		if (ev.type === "mousedown") {
			$(el).animate({ scrollLeft: el.scrollLeft + 60 * delta }, 300);
			this.interval = setInterval(function() {
				$(el).animate({ scrollLeft: el.scrollLeft + 60 * delta }, 300);
			}, 330);
		} else {
			clearInterval(this.interval);
		}
	},

	/**
	 * @method _scrollLeft
	 */
	_scrollLeft: function(ev) {
		this._scroll(ev, -5);
	},

	/**
	 * @method _scrollRight
	 */
	_scrollRight: function(ev) {
		this._scroll(ev, +5);
	},

	/**
	 * @method _calculateTotalContent
	 */
	_calculateTotalContent: function(filter) {
		return _(this.state.content)
			.filter(filter.matcher)
			.size();
	},

	/**
	 * @method render
	 */
	render: function() {
		var images = this.state.content;
		if (this.state.filter) {
			images = _.filter(images, this.state.filter.matcher);
		}

		var library = _(images)
			.reject(function(img) { return img.url === img.original_url; }) // eslint-disable-line lodash/matches-prop-shorthand
			.map((image, idx) => {
				return (<div key={idx}
					className={this.css("image-library-thumbnail", { 'picked': this._isImagePicked(image) })}
					onClick={_.partial(this._onSelectClick, image)}
					style={{ backgroundImage: "url('" + (image.thumbnail_url || image.url) + "')" }}>
				</div>);
			})
			.reverse().value();

		library.unshift(<div className="image-library-thumbnail add-media" key="-1" onClick={this.props.openImageImporterModal}>
			<table style={{width: '100%', height: '100%', position: 'relative', border: 'none', background: 'transparent'}}>
				<tbody style={{width: '100%', height: '100%', position: 'relative', border: 'none', background: 'transparent'}}>
					<tr style={{width: '100%', height: '100%', position: 'relative', border: 'none', background: 'transparent'}}>
						<td style={{width: '100%', height: '100%', position: 'relative', border: 'none', background: 'transparent', verticalAlign: 'middle', textAlign: 'center', padding: 0}}>
							<div className='image' style={{width: 36, height: 36, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', display: 'inline-block'}} />
							<p className="tiny-heading" style={{margin: 0, fontSize: 10, fontWeight: 'bold', marginBottom: 0}}>Add Media</p>
							<button style={{display: 'none', fontSize: 10, padding: 10, paddingTop: 6, paddingBottom: 6, margin: 0, borderRadius: 5}}>Add</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>);

		var icon = function(filter) {
			return {
				"mdi-timetable": ContentFilterTypes.DATE === filter.type,
				"mdi-web": ContentFilterTypes.IMPORT_TYPE === filter.type,
				"mdi-book-multiple": ContentFilterTypes.CONTENT_TYPE === filter.type,
				"mdi-tag": ContentFilterTypes.TAG === filter.type || ContentFilterTypes.GROUP === filter.type
			};
		};

		return (
			<div className="image-library">
				<div className="filters">
					<DropDown onChange={this._onFilterChange}>
						<span key="NONE" className="name">All Images ({_.size(this.state.content)})</span>
						{_.compact(_.map(this.state.filters, (filter) => {
							var total = this._calculateTotalContent(filter);
							return (
								total
									?
									<span key={filter.type + "_" + filter.name} value={filter} className="name"><span className={this.css("mdi", icon(filter))} /> {_t(filter.name)} ({total})</span>
									:
									null
							);
						}))}
					</DropDown>
				</div>
				{library}
			</div>
		)
	}
});

module.exports = ImageLibrary;
