'use strict';

import { partial, map } from 'lodash';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

var ComponentPreview = require("../models/ComponentPreview");

var _convert = function(data) { return new ComponentPreview(data); };

/** @module ComponentsPreviewStore
 * @requires lodash
 * @requires StoreFactory
 * @requires RemixerConstants
 */
var ComponentsPreviewStore = StoreFactory.Create({
	/**
	 * @method _setState
	 */
	_setState: function(prefix, action) {
		this._set(prefix, action.pageId, action.componentContextId, action.componentCategory, action.searchText, action.pIndex, map(action.previews, _convert));
	},

	/**
	 * @method _setFailed
	 */
	_setFailed: function(prefix, action) {
		this._failed(prefix, action.pageId, action.componentInstanceId, action.componentCategory, action.searchText, action.pIndex);
	},

	/**
	 * @method _setFilling
	 */
	_setFilling: function(prefix, action) {
		this._filling(prefix, action.pageId, action.componentInstanceId, action.componentCategory, action.searchText, action.pIndex);
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		handlers[RemixerConstants.LOAD_SIMILAR_COMPONENTS] = partial(this._setFilling, 'similar');
		handlers[RemixerConstants.RECEIVE_SIMILAR_COMPONENTS] = partial(this._setState, 'similar');
		handlers[RemixerConstants.RECEIVE_SIMILAR_COMPONENTS_FAIL] = partial(this._setFailed, 'similar');

		handlers[RemixerConstants.LOAD_RELEVANT_COMPONENTS] = partial(this._setFilling, 'relevant');
		handlers[RemixerConstants.RECEIVE_LOAD_RELEVANT_COMPONENTS] = partial(this._setState, 'relevant');
		handlers[RemixerConstants.RECEIVE_LOAD_RELEVANT_COMPONENTS_FAIL] = partial(this._setFailed, 'relevant');

		return handlers;
	}
});

module.exports = ComponentsPreviewStore;
