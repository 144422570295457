import {ReduceStore} from 'flux/utils';
import LoadingStatusRecord from './records/LoadingStatusRecord'

class LoadingStatusStoreBase extends ReduceStore {
	constructor(loadingStatusActions, dispatcher) {
		super(dispatcher);
		this._loadingStatusActions = loadingStatusActions;
	}

	getInitialState():Object {
		return new LoadingStatusRecord();
	}

	reduce(state, action):boolean {
		switch (action.actionType) {
			case this._loadingStatusActions.load:
				return state.merge({loading: true});
			case this._loadingStatusActions.receive:
				return state.merge({loading: false, loaded: true, failed: false});
			case this._loadingStatusActions.fail:
				return state.merge({loading: false, loaded: true, failed: true});
			default:
				return state;
		}
	}
}

export default LoadingStatusStoreBase;
