'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var ContentFilterTypes = require('../../../constants/ContentFilterTypes');

var ContentFilterStore = require('../../../stores/ContentFilterStore');

var DropDownSingleSelectableGroup = require('../../common/simple/DropDownSingleSelectableGroup.react');
var SelectableItem = require('../../common/simple/SelectableItem.react');

function wrap(Component, contentType) {
	/** @module FilterWrapper
	 * @requires lodash
	 * @requires react
	 * @requires ComponentFactory
	 * @requires ContentFilterTypes
	 * @requires ContentFilterStore
	 * @requires DropDownSingleSelectableGroup
	 * @requires SelectableItem
	 */
	var FilterWrapper = ComponentFactory.Create(ContentFilterStore, {
		displayName: "FilterWrapper",
		propTypes: {
			content: React.PropTypes.array,
			contentItem: React.PropTypes.object,
			picked: React.PropTypes.array,
			onPick: React.PropTypes.func,
			onDelete: React.PropTypes.func
		},

		/**
		 * @method getState
		 */
		getState: function() {
			return {
				filters: _.union(
					ContentFilterStore.getState(ContentFilterTypes.DATE),
					ContentFilterStore.getState(ContentFilterTypes.IMPORT_TYPE),
					ContentFilterStore.getState(ContentFilterTypes.TAG))
			};
		},

		/**
		 * @method _onFilterChange
		 */
		_onFilterChange: function(filter) {
			this.setState({
				selectedFilter: filter
			});
		},

		/**
		 * @method _clearFilters
		 */
		_clearFilters: function() {
			this.setState({
				selectedFilter: undefined
			});
		},

		/**
		 * @method _calculateTotalContent
		 */
		_calculateTotalContent: function(filter) {
			return _(this.props.content)
				.filter(filter.matcher)
				.size();
		},

		/**
		 * @method render
		 */
		render: function() {
			var props = _.defaults({}, this.props);

			if (this.state.selectedFilter) {
				props.content = _.filter(props.content, this.state.selectedFilter.matcher);
			}

			var icon = function(filter) {
				return {
					"mdi-timetable": ContentFilterTypes.DATE === filter.type,
					"mdi-web": ContentFilterTypes.IMPORT_TYPE === filter.type,
					"mdi-book-multiple": ContentFilterTypes.CONTENT_TYPE === filter.type,
					"mdi-tag": ContentFilterTypes.TAG === filter.type
				};
			};

			var filters = this.state.filters;
			var filtersWithTotal = _(filters).map(function (filterItem) {
				return {key: filterItem.name + "_" + filterItem.type, filter: filterItem, total: 0}
			}).keyBy('key').value();

			_.forEach(this.props.content, function(item) {
				_.forEach(filters, function(filterItem) {
					if (filterItem.matcher(item)) {
						var key = filterItem.name + "_" + filterItem.type;
						var value = filtersWithTotal[key];
						value.total = value.total + 1;
					}
				});
			});
			return (
				<div>
					<div className="right">
						<div className="left">Display:&nbsp;</div>
						<DropDownSingleSelectableGroup selected={this.state.selectedFilter} onChange={this._onFilterChange} required={true} className="left">
							<SelectableItem key="_All_" onChange={this._clearFilters}>
								All
							</SelectableItem>
							{_(filtersWithTotal).filter('total').map((item) => {
								return (
									<SelectableItem key={item.key} value={item.filter}>
										<span className={this.css("mdi", icon(item.filter))} /> {this._(item.filter.name)} ({item.total})
									</SelectableItem>
								);
							}).value()}
						</DropDownSingleSelectableGroup>
					</div>
					<div className="clearfix"></div>
					<Component {...props} />
				</div>
			);
		}
	});
	return FilterWrapper;
}

module.exports = wrap;
