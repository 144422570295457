'use strict';

import { isEmpty, isString, attempt, includes, has } from 'lodash'
var urljoin = require('url-join');

var staticUrlBase = process.env.STATIC_URL_BASE || "http://localhost:6544/";

/** @module UrlUtils
 * @requires lodash
 * @requires url-join
 */

/**
 * @method location
 */
function location (url) {
	var a = document.createElement('a');
	a.href = url;
	return a;
}

// Map for redirect_to parameters 'Instagram imports fixes'
const redirectMap = new Map();
redirectMap.set('instagram', 'content/images');

/**
 * @method parseQueryString
 */
function parseQueryString(queryString) {
	let obj = {};
	if (isEmpty(queryString)) return obj;
	const pairs = window.location.search.substring(1).split("&");
	let pair, i;

	for (i in pairs) {
		if (pairs[i] === "") continue;
		pair = pairs[i].split("=");
		obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
	}
	return obj;
}

/**
 * @method buildStaticUrl
 */
function buildStaticUrl (path) {
	var base = location(staticUrlBase);
	var url = location(path);
	// MS Edge doesn't support location.origin
	if (!base.origin) {
		base.origin = base.protocol + "//" + base.hostname + (base.port ? ':' + base.port: '');
	}

	var combined = urljoin(base.origin, base.pathname, url.pathname, url.search, url.hash);

	// strip trailing slash
	if (combined.substr(-1) === '/') {
		return combined.substr(0, combined.length - 1);
	} else {
		return combined;
	}
}

/**
 * @method createUrl
 */
function createUrl(url) {
	var a;
	try {
		return new URL(url);
	} catch(ex) {
		a = document.createElement('a');
		a.href = url;
		return {
			hash: a.hash,
			host: a.host,
			hostname: a.hostname,
			href: a.href,
			origin: a.origin,
			pathname: a.pathname,
			port: a.port,
			protocol: a.protocol,
			search: a.search,
			searchParams: parseQueryString(a.search)
		};
	}
}

module.exports = {
	staticUrl: buildStaticUrl,
	/**
	 * @method createUrl
	 */
	createUrl: createUrl,
	/**
	 * @method createDataUrl
	 */
	createDataUrl: function(data, mimtype, isbase64, encoding) {
		//data uri scheme => data:[<MIME-type>][;charset=<encoding>][;base64],<data>

		var params = '';
		if (isbase64) {
			params = ';base64';
		} else {
			params = ';' + (encoding || 'utf-8');
		}

		return 'data:' + mimtype + params + ',' + encodeURIComponent(data);
	},
	/**
	 * @method normalizeUrl
	 */
	normalizeUrl: function(url) {
		var a = document.createElement('a');
		a.href = url;
		return a.cloneNode(false).href;
	},
	/**
	 * @method removeUriScheme
	 */
	removeUriScheme: function(url) {
		if(isEmpty(url) || !isString(url)) return;

		return url.replace(/^(\w+:\/\/)/, '').replace(/^(\w+:)/, '');
	},
	/**
	 * @method addUriScheme
	 */
	addUriScheme: function(url, scheme) {
		if(isEmpty(url) || !isString(url)) return;

		//NOTE: url already has a scheme
		if(/^\w+:\/\//.test(url)) return url;

		return ((scheme + '//') || 'http://') + url;
	},
	/**
	 * @method addMailtoScheme
	 */
	addMailtoScheme: function(url, scheme) {
		if(isEmpty(url) || !isString(url)) return;

		//NOTE: url already has a scheme
		if(/^\w+:/.test(url)) return url;

		return scheme || 'mailto:' + url;
	},
	/**
	 * @method parseUrl
	 */
	parseUrl: function (url) {
		if (!isString(url)) return;

		return createUrl(this.normalizeUrl(url));
	},
	/**
	 * @method isValidEmail
	 */
	isValidEmail: function(email){
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	},
	/**
	 * @method isValid
	 */
	isValid: function(url) {
		var urlResult = attempt(createUrl, url);
		return Boolean(urlResult.host);
	},
	/**
	 * @method hardReturn
	 */
	hardReturn: function() {
		if (!includes(window.location.href, 'return_to')){
			return;
		}

		const url = this.parseUrl(window.location.href);
		const querystring = this.parseQueryString(url.search);
		if (has(querystring, 'return_to')) {
			const returnTo = querystring.return_to;
			url.search = '';
			url.hash = redirectMap.has(returnTo) ? redirectMap.get(returnTo) : returnTo;
			window.location.href = url.toString();
		}
	},
	/**
	 * @method parseQueryString
	 */
	parseQueryString: parseQueryString,
	/**
	 * @method buildQueryString
	 */
	buildQueryString: function(obj) {
		var parts = [];
		for (var i in obj) {
			if (obj.hasOwnProperty(i)) {
				parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
			}
		}
		return parts.join("&");
	},
	/**
	 * @method navigateTo
	 */
	navigateTo: function (url) {
		if(this.isValid(url)){
			window.location.href = url;
		}
	},

	/**
	 * @method getQueryParam
	 */
	getQueryParam: function(url, name){
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		if (results === null) {
			return null;
		}
		else{
			return results[1] || 0;
		}
	}
};
