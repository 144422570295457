'use strict';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var ComponentContextStore = require('../stores/ComponentContextStore');
var PageDomStateStore = require('../stores/PageDomStateStore');
var PageStore = require('../stores/PageStore');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');

var ComponentInstance = require('../models/ComponentInstance');
var ComponentMetadataFull = require('../models/ComponentMetadata');
var ComponentMetadata = ComponentMetadataFull.ComponentMetadata;

var DomElementUtils = require('../utils/DomElementUtils');

var ComponentTypes = require('../constants/ComponentTypes');

import { get } from 'lodash'

/** @module PageContextComponent
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires PageStore
 * @requires ComponentActionsCreator
 * @requires ComponentInstance
 * @requires ComponentMetadata
 * @requires DomElementUtils
 * @requires ComponentTypes
 */
var PageContextComponent = ComponentFactory.Create(PageStore, ComponentContextStore, {
	displayName: "PageContextComponent",
	propTypes: {
		instance: React.PropTypes.instanceOf(ComponentInstance).isRequired,
		metadata: React.PropTypes.instanceOf(ComponentMetadata).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			activeComponentId: ComponentContextStore.getState('active', 'id'),
			page: PageStore.getState(this.props.instance.pageId),
			opened: ComponentContextStore.getState('settingsOpened')
		};
	},

	/**
	 * @method _onClick
	 */
	_onClick: function() {
		if (this.state.deleteConfirm) {
			this._toggleDeleteConfirm()
			return
		}
		ComponentActionsCreator.activeComponent(this.props.instance);
		var compRect =  PageDomStateStore.getComponentRectangle(this.props.instance.pageId, this.props.instance.id);
		if (compRect) {
			DomElementUtils.scrollDocumentTop(compRect.position.top - 50);
		}
	},

	/**
	 * @method _onDragStart
	 */
	_onDragStart: function(e) {
		ComponentActionsCreator.startDraggingComponent({ componentId: this.props.instance.id });
		e.dataTransfer.setData('text', JSON.stringify({componentId: this.props.instance.id}));
	},

	/**
	 * @method _onDragEnd
	 */
	_onDragEnd: function() {
		ComponentActionsCreator.endDraggingComponent();
	},

	//allow drop
	/**
	 * @method _onDragOver
	 */
	_onDragOver: function(e) {
		e.preventDefault();
		this.setState({ hoverBottom: true, hoverTop: false })
	},

	/**
	 * @method _onDragOverTop
	 */
	_onDragOverTop: function(e) {
		e.preventDefault();

		var delta = DomElementUtils.IsCoordinatesInBottom(e.pageX, e.pageY, e.currentTarget);
		if(delta) {
			this.setState({ hoverBottom: true, hoverTop: false })
		} else {
			this.setState({ hoverTop: true, hoverBottom: false })
		}
	},

	/**
	 * @method _onDragOverSeparator
	 */
	_onDragOverSeparator: function(e) {
		e.preventDefault();

		this.setState({ separatorActive: true })
	},

	/**
	 * @method _onDragOverSeparatorTop
	 */
	_onDragOverSeparatorTop: function(e) {
		e.preventDefault();

		this.setState({ separatorActiveTop: true })
	},

	/**
	 * @method _onDragLeave
	 */
	_onDragLeave: function(e) {
		e.preventDefault();
		this.setState({ hoverTop: false, hoverBottom: false, separatorActive: false, separatorActiveTop: false })
	},

	/**
	 * @method _onDrop
	 */
	_onDrop: function(e) {
		e.preventDefault();

		var compRect =  PageDomStateStore.getComponentRectangle(this.props.instance.pageId, this.props.instance.id);
		if (compRect) {
			DomElementUtils.scrollDocumentTop(compRect.position.top + compRect.size.height - 50);
		}

		var position = this.props.instance.position + 1;

		var droppedData = e.dataTransfer.getData('text');
		if(droppedData) {
			droppedData = JSON.parse(droppedData);
			if(droppedData.componentId){
				var instance = new ComponentInstance(this.state.page.components[droppedData.componentId]);

				instance.position = position;
				ComponentActionsCreator.updateComponentInstance(instance);
			} else if(droppedData.name && droppedData.version) {
				ComponentActionsCreator.createComponentInstance(this.state.page.id, droppedData, position);
			}
		}
		this.setState({ hoverTop: false, hoverBottom: false, separatorActive: false, separatorActiveTop: false })

	},

	/**
	 * @method _onDropTop
	 */
	_onDropTop: function(e) {
		e.preventDefault();

		var compRect =  PageDomStateStore.getComponentRectangle(this.props.instance.pageId, this.props.instance.id);
		if (compRect) {
			DomElementUtils.scrollDocumentTop(compRect.position.top);
		}

		var delta = DomElementUtils.IsCoordinatesInBottom(e.pageX, e.pageY, e.currentTarget);
		var position = this.props.instance.position + delta;

		var droppedData = e.dataTransfer.getData('text');
		if(droppedData) {
			droppedData = JSON.parse(droppedData);
			if(droppedData.componentId){
				var instance = new ComponentInstance(this.state.page.components[droppedData.componentId]);

				instance.position = position;
				ComponentActionsCreator.updateComponentInstance(instance);
			} else if(droppedData.name && droppedData.version) {
				ComponentActionsCreator.createComponentInstance(this.state.page.id, droppedData, position);
			}
		}
		this.setState({ hoverTop: false, hoverBottom: false, separatorActive: false, separatorActiveTop: false })

	},

	/**
	 * @method _onDropSeparator
	 */
	_onDropSeparatorTop: function(e) {
		e.preventDefault();

		var compRect =  PageDomStateStore.getComponentRectangle(this.props.instance.pageId, this.props.instance.id);
		if (compRect) {
			DomElementUtils.scrollDocumentTop(compRect.position.top);
		}

		var position = this.props.instance.position;

		var droppedData = e.dataTransfer.getData('text');
		if(droppedData) {
			droppedData = JSON.parse(droppedData);
			if(droppedData.componentId){
				var instance = new ComponentInstance(this.state.page.components[droppedData.componentId]);

				instance.position = position;
				ComponentActionsCreator.updateComponentInstance(instance);
			} else if(droppedData.name && droppedData.version) {
				ComponentActionsCreator.createComponentInstance(this.state.page.id, droppedData, position);
			}
		}
		this.setState({ hoverTop: false, hoverBottom: false, separatorActive: false, separatorActiveTop: false })

	},


	/**
	 * @method _isDraggable
	 */
	_isDraggable: function() {
		return this.props.metadata.componentType === ComponentTypes.content || this.props.metadata.componentType === ComponentTypes.hidden;
	},

	/**
	 * @method _applyDrop
	 */
	_applyDrop: function() {
		if (this.props.componentIndex) {
			return this._isDraggable() && { onDrop: this._onDrop, onDragOver: this._onDragOver, onDragLeave: this._onDragLeave };
		} else {
			return this._isDraggable() && { onDrop: this._onDropTop, onDragOver: this._onDragOverTop, onDragLeave: this._onDragLeave };
		}

	},

	/**
	 * @method _applyDropSeparator
	 */
	_applyDropSeparator: function() {
		return { onDrop: this._onDrop, onDragOver: this._onDragOverSeparator, onDragLeave: this._onDragLeave };
	},

	/**
	 * @method _applyDropSeparator
	 */
	_applyDropSeparatorTop: function() {
		return { onDrop: this._onDropSeparatorTop, onDragOver: this._onDragOverSeparatorTop, onDragLeave: this._onDragLeave };
	},

	/**
	 * @method _applyDrag
	 */
	_applyDrag: function() {
		return this._isDraggable() && { draggable: true, onDragStart: this._onDragStart, onDragEnd: this._onDragEnd, onClick: this._onClick };
	},

	/**
	 * @method _add
	 */
	_add: function() {
		ComponentActionsCreator.openComponentSetting(this.props.instance, 'add');
		var compRect =  PageDomStateStore.getComponentRectangle(this.props.instance.pageId, this.props.instance.id);
		if (compRect) {
			DomElementUtils.scrollDocumentTop(compRect.position.top + compRect.size.height - 200);
		}
	},

	/**
	 * @method _add
	 */
	_addTop: function() {
		ComponentActionsCreator.openNewComponentSetting();
		var compRect =  PageDomStateStore.getComponentRectangle(this.props.instance.pageId, this.props.instance.id);
		if (compRect) {
			DomElementUtils.scrollDocumentTop(compRect.position.top - 200);
		}
	},

	_copy: function() {
		ComponentActionsCreator.createComponentInstance(
			this.props.instance.pageId,
			this.props.instance.reference,
			(this.props.instance.position || 0) + 1
		);
	},

	/**
	 * @method _remove
	 */
	_remove: function(e) {
		e.stopPropagation();

		ComponentActionsCreator.removeComponentInstance(this.props.instance);
	},

	/**
	 * @method _toggleDeleteConfirm
	 */
	_toggleDeleteConfirm: function() {
		this.setState({deleteConfirm: !get(this.state, 'deleteConfirm', false)})
	},

	/**
	 * @method render
	 */
	render: function() {
		return (<div className={"button-wrapper"}>
			{!this.props.componentIndex && <div className={this.css("add-new-chord-separator", { active: this.state.opened === null || this.state.separatorActiveTop || get(this.state, 'hoverTop', false) })} onClick={this._addTop} {...this._applyDropSeparatorTop()}>
				<div className="line" />
				<div className="add-new-chord-holder"><i className="mdi mdi-plus" /><i className="mdi mdi-plus-circle" /></div>
			</div>}
			<div className={this.css("button-background")} {...this._applyDrop()}>
				<div className={this.css('button', this.props.metadata.componentCategory,
					{ active: this.state.activeComponentId === this.props.instance.id })} {...this._applyDrag()}>
					<div className="row">
						{this._isDraggable() && <div className="drag-trigger large-1 columns"><i className="mdi icon mdi-drag"></i></div>}
						<div className="component-name large-9 columns">
							<span>{this.props.instance.reference.name}</span>
						</div>
						<div className="action-icons large-1 columns">
							<span onClick={this._copy}>
								<i className="mdi mdi-content-copy icon"></i>
							</span>
						</div>
						<div className="action-icons large-1 columns">
							<span onClick={this._toggleDeleteConfirm}>
								<i className="mdi mdi-delete icon"></i>
							</span>
						</div>
						<div className={this.css("delete-chord-confirm", { show: this.state.deleteConfirm })} onClick={this._remove} onMouseLeave={this._toggleDeleteConfirm} >
							<div>
								<span><i className="mdi mdi-delete icon" />
									<p>confirm</p>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={this.css("add-new-chord-separator", { active: (this.state.activeComponentId === this.props.instance.id && this.state.opened) || this.state.separatorActive || get(this.state, 'hoverBottom', false) })} onClick={this._add} {...this._applyDropSeparator()}>
				<div className="line" />
				<div className="add-new-chord-holder"><i className="mdi mdi-plus" /><i className="mdi mdi-plus-circle" /></div>
			</div>
		</div>);
	}
});

module.exports = PageContextComponent;
