'use strict';

import { forEach } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');
//var SwipeList = require('./SwipeList.react');
//var BlogItem = require('./BlogItem.react');

/** @module BlogView
 * @requires react
 * @requires ComponentFactory
 * @requires SwipeList
 * @requires BlogItem
 */
var BlogView = ComponentFactory.Create({
	displayName: "BlogView",
	/**
	 * @method onClick
	 */
	/*onClick: function(data) {
		console.log(data);
	},*/

	/**
	 * @method render
	 */
	render: function() {
		var blogData = [];
		forEach(this.props.blogs, function(item){
			blogData = blogData.concat(item.content.posts);
		});

		/*var blogProps = {
			rows: 2,
			itemWidth: 250,
			itemHeight: 250,
			component: BlogItem,
			componentProps: {
				images: this.props.images,
				onClick: this.onClick,
				onDelete: this.onDelete
			},
			list: blogData
		};*/

		return (
			<div className="off-canvas-wrap">
				{/*<SwipeList
					rows={2}
					itemWidth={250}
					itemHeight={250}
					component={BlogItem}
					componentProps={{
						images: this.props.images,
						onClick: this.props.onClick,
						onDelete: this.onDelete
					}}
					list={blogProps.list}
				/>*/}
			</div>
		);
	}

});

module.exports = BlogView;
