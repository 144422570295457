'use strict';

var querystring = require('./param');

/** @module MediaservicesUtil
 * @requires param
 */
function getBoolParamForUrlAsNumber(param) {
	return param === true || param === 1 || param === '1' ? 1 : 0;
}

function getBoolParamForUrl(param) {
	return !!(param === true || param === 'true' || param === '1' || param === 1);
}

function getVimeoVideoId(videoUrl) {
	if (!videoUrl) return;

	var regExp = /^.*(?:vimeo.com)\/(?:channels\/|channels\/\w+\/|groups\/[^\/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
	var match = videoUrl.match(regExp);

	if (match) {
		return match[1];
	}
}

function getYoutubeVideoId(videoUrl) {
	if (!videoUrl) return;

	var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
	var match = videoUrl.match(regExp);

	if (match) {
		return match[1];
	}
}

function getSoundcloudTrackIdFromApiUrl(url) {
	if (!url) return;

	var regExp = /api\.soundcloud\.com\/tracks\/(\d+)/;
	var match = url.match(regExp);

	if (match) {
		return match[1];
	}
}


function getDailymotionVideoId(videoUrl) {
	if (!videoUrl) return;

	var regExp = /(?:dailymotion\.com(?:\/video|\/hub)|dai\.ly)\/([0-9a-z]+)(?:[\-_0-9a-zA-Z]+#video=([a-z0-9]+))?/;
	var match = videoUrl.match(regExp);

	if (match) {
		return match[1];
	}
}

function getVideoEmbeddedUrl(videoUrl, settings) {
	var id = getVimeoVideoId(videoUrl);
	if (id) {
		return 'https://player.vimeo.com/video/' + id + '?' + querystring({
			autoplay: getBoolParamForUrlAsNumber(settings.autoPlay),
			loop: getBoolParamForUrlAsNumber(settings.loop)
		});
	}
}

function getVimeoEmbeddedUrlById(id, settings) {
	settings = settings || {};
	return 'https://player.vimeo.com/video/' + id + '?' + querystring({
		badge: getBoolParamForUrlAsNumber(settings.badge),
		autopause: getBoolParamForUrlAsNumber(settings.autopause),
		player_id: getBoolParamForUrlAsNumber(settings.player_id)
	});
}

function getYoutubeEmbeddedUrlById(id, settings) {
	settings = settings || {};
	return 'https://www.youtube.com/embed/' + id + '?' + querystring({
		autoplay: getBoolParamForUrlAsNumber(settings.autoPlay),
		loop: getBoolParamForUrlAsNumber(settings.loop),
		playlist: id,
		rel: 0
	});
}

function getYoutubeEmbeddedUrl(videoUrl, settings) {
	var id = getYoutubeVideoId(videoUrl);
	return id && getYoutubeEmbeddedUrlById(id, settings);
}

function getDailymotionEmbeddedUrl(videoUrl, settings) {
	var id = getDailymotionVideoId(videoUrl);
	if (id) {
		return 'https://www.dailymotion.com/embed/video/' + id + '?' + querystring({
			autoPlay: getBoolParamForUrlAsNumber(settings.autoPlay)
		});
	}
}

function getYahooEmbeddedUrl(videoUrl) {
	if (videoUrl && videoUrl.indexOf('screen.yahoo.com/') > -1) {
		return videoUrl + '?format=embed';
	}
}

function getVideoServiceEmbeddedUrl(videoUrl, settings) {
	return getYoutubeEmbeddedUrl(videoUrl, settings) || getVideoEmbeddedUrl(videoUrl, settings)
		|| getYahooEmbeddedUrl(videoUrl, settings) || getDailymotionEmbeddedUrl(videoUrl, settings);
}

function getYoutubeUrlByVideoId(videoId) {
	return 'https://youtu.be/' + videoId;
}

function getSoundcloudEmbeddedUrlById(id, settings) {
	settings = settings || {};
	return 'https://w.soundcloud.com/player/?url=https://api.soundcloud.com/tracks/' + id
		+ '&hide_related=true&show_comments=false&show_user=false&show_reposts=false&visual=false&'
		+ querystring({
			'auto_play': getBoolParamForUrl(settings.autoplay)
		});
}

module.exports = {
	getVideoServiceEmbeddedUrl: getVideoServiceEmbeddedUrl,
	getYoutubeUrlByVideoId: getYoutubeUrlByVideoId,
	getYoutubeVideoId: getYoutubeVideoId,
	getVimeoVideoId: getVimeoVideoId,
	getYoutubeEmbeddedUrlById: getYoutubeEmbeddedUrlById,
	getVimeoEmbeddedUrlById: getVimeoEmbeddedUrlById,
	getSoundcloudEmbeddedUrlById: getSoundcloudEmbeddedUrlById,
	getSoundcloudTrackIdFromApiUrl: getSoundcloudTrackIdFromApiUrl
};
