'use strict';

import { template , isArray, isEmpty, assign, defaults } from 'lodash';
var Enum = require('../utils/Enum');

var DEFAULT_STARTING_MESSAGE_TEMPLATE = 'Import ${contentNamePlural} from ${importNameCapitalized} started...';
var DEFAULT_FINISHED_MESSAGE_TEMPLATE = 'Importing ${contentNamePlural} from ${importNameCapitalized} finished: ${statisticsMessage}';
var DEFAULT_FAILED_MESSAGE_TEMPLATE = 'Unable to import ${contentNamePlural} from ${importNameCapitalized}.';
var NO_ITEMS_ADDED = 'No new items added';
var UNKNOWN_ERROR = 'Unknown error';
var AUTH_EXPIRED_RUN_AGAIN = 'Authentication Expired. Please run import again.';

var defaultOptions = {
	requireFormData: false,
	startingMessageTemplate: DEFAULT_STARTING_MESSAGE_TEMPLATE,
	finishedMessageTemplate: DEFAULT_FINISHED_MESSAGE_TEMPLATE,
	failedMessageTemplate: DEFAULT_FAILED_MESSAGE_TEMPLATE,
	contentNamePlural: 'images',
	isConnectedPropertyName: 'token'
};

/**
 * @method getCapitalizedString
 */
function getCapitalizedString(value) {
	if (!value) {
		return 'Undefined';
	}

	return value[0].toUpperCase() + value.slice(1);
}

/**
 * @method appendStatisticsMessage
 */
function appendStatisticsMessage(message, type, count) {
	if (!count) {
		return message;
	}

	if (message) {
		message += ', ';
	}

	return message + count + ' ' + type + (count > 1 ? 's' : '') + ' added';
}

/**
 * @method getStatisticsMessage
 */
function getStatisticsMessage(statistics) {
	if (!statistics && !statistics.audio && !statistics.video && !statistics.image) {
		return NO_ITEMS_ADDED;
	}
	var statisticsMessage = '';

	statisticsMessage = appendStatisticsMessage(statisticsMessage, 'audio', statistics.audio);
	statisticsMessage = appendStatisticsMessage(statisticsMessage, 'video', statistics.video);
	statisticsMessage = appendStatisticsMessage(statisticsMessage, 'image', statistics.image);

	return statisticsMessage ? statisticsMessage : NO_ITEMS_ADDED;
}

/**
 * @method getErrorMessage
 */
function getErrorMessage(errors) {
	var errorMessage = isArray(errors) && !isEmpty(errors)
		? errors.join(', ')
		: UNKNOWN_ERROR;
	return errorMessage;
}

/** @module ImportTypes
 * @requires lodash
 * @requires Enum
 */
var ImportTypes = new Enum();
/**
 * @method ImportTypesEntry
 */
function ImportTypesEntry() {
}

ImportTypesEntry.prototype.getTemplateParameters = function (action) {
	var templateParameters = {
		contentNamePlural: this.contentNamePlural,
		importNameCapitalized: this.importNameCapitalized ? this.importNameCapitalized : getCapitalizedString(this.name)
	};
	templateParameters = assign(templateParameters, action);
	return templateParameters;
};

ImportTypesEntry.prototype.getImportStartingMessage = function (action) {
	var templateParameters = this.getTemplateParameters(action);
	return template(this.startingMessageTemplate)(templateParameters);
};

ImportTypesEntry.prototype.getImportFinishedMessage = function (action) {
	var templateParameters = this.getTemplateParameters(action);
	templateParameters.statisticsMessage = getStatisticsMessage(action.data.statistics);

	return template(this.finishedMessageTemplate)(templateParameters);
};

ImportTypesEntry.prototype.getImportFailedMessage = function (action) {
	var templateParameters = this.getTemplateParameters(action);
	templateParameters.errorMessage = getErrorMessage(action.data && action.data.errors);

	return template(this.failedMessageTemplate)(templateParameters);
};

ImportTypesEntry.prototype.getImportFaildAuthExpiredMessage = function (action) {
	var templateParameters = this.getTemplateParameters(action);
	templateParameters.errorMessage = AUTH_EXPIRED_RUN_AGAIN;

	return template(this.failedMessageTemplate)(templateParameters);
};

function createImportTypeEnumEntry(name, displayName, options) {
	var opts = defaults({displayName: displayName}, options, defaultOptions);

	return ImportTypes.createEnumEntry(name, opts, ImportTypesEntry);
}

ImportTypes.YOUTUBE = createImportTypeEnumEntry('youtube', 'Youtube', {
	contentNamePlural: 'videos',
	isConnectedPropertyName: 'google_connected'
});
ImportTypes.FACEBOOK = createImportTypeEnumEntry('facebook', 'Facebook', {isConnectedPropertyName: 'facebook_connected'});
ImportTypes.INSTAGRAM = createImportTypeEnumEntry('instagram', 'Instagram', {isConnectedPropertyName: 'instagram_connected'});
ImportTypes.TWITTER = createImportTypeEnumEntry('twitter', 'Twitter', {isConnectedPropertyName: 'twitter_connected'});
ImportTypes.PAGE = createImportTypeEnumEntry('page', 'URL', {
	startingMessageTemplate: 'Import images from ${data.url} started...'
});
ImportTypes.FILE = createImportTypeEnumEntry('file', 'Uploaded', {
	requireFormData: true,
	startingMessageTemplate: 'Uploading ${data.length > 1 ? data.length + " images": data[0].name + " image"} started...',
	importNameCapitalized: 'Computer'
});
ImportTypes.PIXABAY = createImportTypeEnumEntry('pixabay', 'Collections', {importNameCapitalized: 'Collections'});
ImportTypes.FLICKR = createImportTypeEnumEntry('flickr', 'Flickr', {isConnectedPropertyName: 'flickr_connected'});
ImportTypes.VIMEO = createImportTypeEnumEntry('vimeo', 'Vimeo', {
	contentNamePlural: 'videos',
	isConnectedPropertyName: 'vimeo_connected'
});
ImportTypes.SOUNDCLOUD = createImportTypeEnumEntry('soundcloud', 'Soundcloud', {
	contentNamePlural: 'audio tracks',
	isConnectedPropertyName: 'soundcloud_connected'
});
// DO WE NEED THESE 3????
ImportTypes.YOUTUBESEARCH = createImportTypeEnumEntry('youtubesearch', 'Search on Youtube');
ImportTypes.VIMEOSEARCH = createImportTypeEnumEntry('Search on Vimeo');
ImportTypes.MARKDOWN = createImportTypeEnumEntry('markdown', 'Markdown');

module.exports = ImportTypes;
