import React from 'react';
import {Container} from 'flux/utils';

var classnames = require('classnames');
var _ = require('lodash');

import {ChromeItem} from '../Chrome.react';
import WebsitesStore, {WebsitesLoadingStatusStore} from '../../stores/WebsitesStore';
import AvailableDomainsStore, {AvailableDomainsLoadingStatusStore} from '../../stores/AvailableDomainsStore';
import TrialStatusStore from '../../stores/TrialStatusStore';
import DreamHostersStore from '../../stores/DreamHostersStore';
import PageProjectStore, {PageProjectsLoadingStatusStore} from '../../stores/PageProjectStore';

import RouteStore from '../../stores/RouteStore';

import WebsiteActionsCreator from '../../actions/WebsiteActionsCreator';
import AvailableDomainActionsCreator from '../../actions/AvailableDomainActionsCreator';
import PageProjectActionsCreator from '../../actions/PageProjectActionsCreator';

import WordPressExportSettingsForm, {TAB} from '../WordPressExportSettingsForm.react';
import ExportWordPressActionsCreator from '../../actions/ExportWordPressActionsCreator';
import DreamHostersAvailableActionsCreator from '../../actions/DreamHostersAvailableActionsCreator';

class WordPressExportSettings extends React.Component {
	static propTypes = {
		websiteId: React.PropTypes.number.isRequired
	};
	static getStores() {
		return [
			WebsitesStore,
			AvailableDomainsStore,
			WebsitesLoadingStatusStore,
			AvailableDomainsLoadingStatusStore,
			TrialStatusStore,
			PageProjectStore,
			DreamHostersStore
		];
	}
	static _getState(props, prevState) {
		const website = WebsitesStore.getState().get(props.websiteId);
		const websiteList = WebsitesStore.getState().toList();
		const currentIndex = websiteList.indexOf(website);
		const nextWebsite = websiteList.get(currentIndex + 1);
		const prevWebsite = currentIndex !== 0 && websiteList.get(currentIndex - 1);
		const dreamhostersStatus = DreamHostersStore.getState();
		return {
			website,
			nextWebsite,
			prevWebsite,
			availableDomainList: AvailableDomainsStore.getState(),
			loading: WebsitesLoadingStatusStore.getState().loading
				|| PageProjectsLoadingStatusStore.getState().loading,
			loadingAvailableDomains: AvailableDomainsLoadingStatusStore.getState().loading,
			tab: prevState.tab,
			accountStatus: TrialStatusStore.getState(),
			pageProjectList: PageProjectStore.getState(),
			dreamhostersStatus,
			dreamhostersDomain: prevState.dreamhostersDomain || ''
		};
	}
	static calculateState(prevState, props) {
		return WordPressExportSettings._getState(props, prevState || {tab: TAB.EXISTING});
	}
	css = classnames;
	componentWillReceiveProps(nextProps) {
		this.setState(WordPressExportSettings._getState(nextProps, {tab: TAB.EXISTING}));
	}
	componentDidUpdate(prevProps, prevState) {
		if(((_.get(this.state, 'dreamhostersStatus.available')
			&& _.get(this.state, 'dreamhostersStatus.domain') === prevState.dreamhostersDomain)
			|| _.get(this.state, 'availableDomainList').filter((d) => {return d.domain === this.state.dreamhostersDomain}).size)
			&& _.get(this.state, 'website.domain') === prevState.dreamhostersDomain){
			_.defer(() => {this.handleExport()}, this);
			this.setState({dreamhostersDomain: ''})
		}
	}
	componentDidMount() {
		WebsiteActionsCreator.loadWebsites(true);
		AvailableDomainActionsCreator.loadAvailableDomains();
		PageProjectActionsCreator.loadPageProjects(true);
	}
	handleChangeWebsite = (website) => {
		WebsiteActionsCreator.updateWebsite(website);
	}
	handleFilterWebsite(key, value) {
		if (key == "domain") {
			return value || null;
		} else if (key == "allowRobots") {
			return value == "true";
		}
		return value;
	}
	handleValidateWebsite(key, value) {
		return true;
	}
	handleAvailableDomainsRefresh = () => {
		AvailableDomainActionsCreator.loadAvailableDomains(true)
	}
	handleExport = () => {
		if(_.get(this.state.website, "domain")){
			RouteStore.getState('route').transitionTo('websites');
			ExportWordPressActionsCreator.startExportWordPress(this.props.websiteId)
		}
	}
	handleTab = (tab) => {
		this.setState({tab});
	}
	applyDreamHosters = () => {
		if(this.state.availableDomainList.filter((d) => {return d.domain === this.state.dreamhostersDomain}).size) {
			WebsiteActionsCreator.updateWebsite(this.state.website.set('domain', this.state.dreamhostersDomain));
		} else {
			DreamHostersAvailableActionsCreator.checkDreamHostersAvailable(this.state.dreamhostersDomain, this.state.website)
		}
	}
	render() {
		const pageProject = !this.state.loading && this.state.pageProjectList.get(_.get(this.state.website, 'mainPageProjectId'));
		return (<div className="row page-settings-page export-settings-page">
			<div className="page-title-background">
				<div className="small-12 columns small-centered page-title">
					<div className="row">
						<div className="small-5 columns">
							<div className="website-block-top">
								<ChromeItem>
									{!pageProject ?
										<span className="loader"></span>:
										<img src={pageProject.thumbnail || "https://placehold.it/440x165/ffffff/444444"}/>}
								</ChromeItem>
							</div>
						</div>
						<div className="small-7 columns"><h1 className="page-title-header">Choose a domain to export to WordPress</h1></div>
					</div>
				</div>
			</div>
			{(!this.state.loading && this.state.website) ?
				<WordPressExportSettingsForm
					availableDomainList={this.state.availableDomainList}
					accountStatus={this.state.accountStatus}
					model={this.state.website}
					onSave={this.handleChangeWebsite}
					onFilter={(key, value) => this.handleFilterWebsite(key, value)}
					onValidate={(key, value) => this.handleValidateWebsite(key, value)}
					onRefresh={this.handleAvailableDomainsRefresh}
					tab={this.state.tab}
					onTab={this.handleTab}
					onExport={this.handleExport}
					dreamhostersDomain = {this.state.dreamhostersDomain}
					onHandleDreamhostersChange = {(value) => {this.setState({dreamhostersDomain: value})}}
					applyDreamHosters={this.applyDreamHosters}
					dreamhostersError = {this.state.dreamhostersStatus.error} />
				:
				<div>Loading ...</div>
			}
		</div>
		);
	}
}

export default Container.create(WordPressExportSettings, {withProps: true});
