'use strict';

import { functions, isEqual, omit } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var SingleSelectableGroup = require('./SingleSelectableGroup.react');
var SelectableItem = require('./SelectableItem.react');

/** @module DropDownSingleSelectableGroup
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires SingleSelectableGroup
 * @requires SelectableItem
 */
var DropDownSingleSelectableGroup = ComponentFactory.Create({
	displayName: "DropDownSingleSelectableGroup",
	propTypes: {
		selected: React.PropTypes.any,
		onChange: React.PropTypes.func,
		hideSelected: React.PropTypes.bool,
		tag: React.PropTypes.any
	},
	/**
	 * @method render
	 */
	render: function() {
		var active = null;
		var children = React.Children.map(this.props.children, (child) => {
			if (!active
				&& child
				&& child.type === SelectableItem
				&& isEqual(
					omit(child.props.value, functions(child.props.value)),
					omit(this.props.selected, functions(this.props.selected)))
			) {
				active = React.cloneElement(child, {tag: "div"});
				if (this.props.hideSelected) {
					return;
				}
			}
			return child;
		});
		var css = {
			"drop-down": true
		};
		return (
			<div className={this.css(this.props.className, css)}>
				<div className="active" unselectable='on'>
					{active}
					<div className="arrow"></div>
				</div>
				<SingleSelectableGroup {...this.props} selected={this.props.selected}>
					{children}
				</SingleSelectableGroup>
			</div>
		);
	}
});
module.exports = DropDownSingleSelectableGroup;
