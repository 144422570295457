'use strict';

/** @module ContentManagementTabs
 */
module.exports = {
	IMAGES: "images",
	VIDEOS: "videos",
	MARKDOWN_TEXTS: "markdown",
	GROUPS: "groups",
	AUDIOS: "audios"
};
