'use strict';

import { noop, set, isUndefined, isEqual, get } from 'lodash';
var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentContextStore = require('../../stores/ComponentContextStore');
var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var PropertyTypes = require('../../constants/PropertyTypes');
var UrlUtils = require('../../utils/UrlUtils');


/** @module MapPicker
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires ComponentActionsCreator
 * @requires PropertyTypes
 * @requires UrlUtils
 * @requires lodash
 */
var MapPicker = ComponentFactory.Create(ComponentContextStore, {
	displayName: "MapPicker",
	propTypes: {
		propertyName: React.PropTypes.string.isRequired,
		propertyType: React.PropTypes.oneOf([PropertyTypes.map]).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var value = ComponentContextStore.getPropertyValue(this.props.propertyName);
		return {
			address: value && UrlUtils.getQueryParam(value.url, 'q'),
			value: value
		};
	},

	componentDidMount: noop,

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		if(!isUndefined(prevState) && !isEqual(this.state.value, prevState.value)) {
			var component = ComponentContextStore.getState('active');
			if(!isEqual(get(component.context, this.props.propertyName), this.state.value)) {
				set(component.context, this.props.propertyName, this.state.value);
				ComponentActionsCreator.updateComponentInstanceContext(component);
			}
		}
	},

	/**
	 * @method _onTextChange
	 */
	_onTextChange: function(event) {
		this.setState({address: event.target.value});
	},

	/**
	 * @method _onApply
	 */
	_onApply: function(){
		var address = this.state.address;
		var url = address
			&& "https://www.google.com/maps/embed/v1/place?key=AIzaSyB7N9q7WsrEwY1WzJpLOEfdUMBkpTM_hGk&q=" + address;
		if (url){
			this.setState({ value: { url: url }});
			setTimeout(function() {
				ComponentActionsCreator.stopMapPicker();
			}, 250);
		}
	},

	/**
	 * @method _onKeyDown
	 */
	_onKeyDown: function(e) {
		var ENTER = 13;
		if(e.keyCode === ENTER || e.which === 13) {
			this._onApply();
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="map-picker">
				<span className="map-picker-title">Google Map:</span>
				<div className="apply">
					<div className="button medium radius success" onClick={this._onApply}>Apply</div>
				</div>

				<div className="map-address">
					<p>Type an address:</p>
					<input type="text" value={this.state.address} onChange={this._onTextChange}
						onKeyDown={this._onKeyDown} />
				</div>
			</div>
		);
	}
});


module.exports = MapPicker;
