import React from 'react'
import PropTypes from 'prop-types'
import { Form, Text, Checkbox } from 'react-form'
import classnames from 'classnames'
import { map, values, last } from 'lodash'
import SignupActionCreator from '../actions/SignupActionCreator';
import SignUpErrorHandlerStore from '../stores/SignUpErrorHandlerStore'

export default class CreateAccount extends React.PureComponent {
	static propTypes = {
		changeNavigation: PropTypes.func,
		onChange: PropTypes.func,
		saveTheme: PropTypes.object.isRequired,
		formToggle: PropTypes.bool
	}
	constructor(props) {
		super(props)
		this.state = {
			isShowPassword: false,
			chainIsInProgress: false,
			failedAction: last(values(SignUpErrorHandlerStore.getState()))
		}
	}
	componentDidMount() {
		SignUpErrorHandlerStore.addChangeListener(this._onChange)
	}
	_onChange = () => {
		let failedAction = last(values(SignUpErrorHandlerStore.getState()))
		this.setState({
			chainIsInProgress: !failedAction.chainIsFailed
		})
	}
	componentWillUnmount() {
		SignUpErrorHandlerStore.removeChangeListener(this._onChange);
	}
	onShowPasswordClick = () => {
		this.setState({
			isShowPassword: !this.state.isShowPassword
		})
	}
	errorValidator = (values) => {
		const validateFirstName = (firstName) => {
			if (firstName) {
				let emptyRegex = /(?=(^ *$))/
				return emptyRegex.test(firstName) ? ['· first name can not be blank'] : null
			}
			return !firstName ? ['· first name can not be blank'] : null
		}
		const validateLastName = (lastName) => {
			if (lastName) {
				let emptyRegex = /(?=(^ *$))/
				return emptyRegex.test(lastName) ? ['· last name can not be blank'] : null
			}
			return !lastName ? ['· last name can not be blank'] : null
		}
		const validateEmail = (email) => {
			if (email) {
				let emailRegex = /(^[-\w.+]+@([A-z0-9][-A-z0-9]+\.)+[A-z])/
				return emailRegex.test(email) ? null : ['· cannot be blank',
					'· must contain only one @',
					'· cannot end with a @']
			}
			return !email ? ['email is required.'] : null
		}
		const validatePassword = (password) => {
			if (password) {
				let charactersRegex = /([^\x20-\x7e])/
				let lenghtRegex = /(?=^.{8,31}$)/
				let whiteSpaceRegex = /(?=(^\s+|\s+$))/
				return (password.match(charactersRegex) === null &&
					lenghtRegex.test(password) &&
					!whiteSpaceRegex.test(password)) ? null
					: ['· password can not be blank',
						'· must be between 8 and 31 characters long',
						'· cannot start or end with a space',
						'· ASCII printable English characters only']
			}
			return !password ? ['· password can not be blank'] : null
		}
		return {
			firstName: validateFirstName(values.firstName),
			lastName: validateLastName(values.lastName),
			email: validateEmail(values.email),
			password: validatePassword(values.password)
		}
	}

	createAccount(values) {
		SignupActionCreator.createAccount(
			this.props.saveTheme.pageId,
			this.props.saveTheme.name,
			values.email,
			values.password,
			values.firstName,
			values.lastName,
			values.email_opt_in ? 1 : 0
		);
	}

	getNotification(formApi, field) {
		if (formApi.errors[field] && formApi.touched[field]) {
			return (<div className="notifications">
				{map(formApi.errors[field],(val, key) => {
					return <div className="notification-text" key={key}>{val}</div>
				})}
			</div>)
		}
	}

	changeForm = () => {
		this.props.onChange(!this.props.formToggle)
	}

	getForm() {
		return (
			<Form
				defaultValues={{ email_opt_in: false }}
				validateError={this.errorValidator}
				onSubmitFailure={formData => formData}
				onSubmit={values => this.createAccount(values)}>
				{formApi => (
					<form className="account-form" onSubmit={formApi.submitForm} id="form2">
						<div className="text-input-wrapper">
							<Text className={classnames(
								'text-input account-input', formApi.errors.firstName && formApi.touched.firstName && 'error'
							)} placeholder="Your first name" field="firstName" id="firstName"/>
							{this.getNotification(formApi, 'firstName')}
						</div>
						<div className="text-input-wrapper">
							<Text className={classnames(
								'text-input', formApi.errors.lastName && formApi.touched.lastName && 'error'
							)} placeholder="Your last name" field="lastName" id="lastName"/>
							{this.getNotification(formApi, 'lastName')}
						</div>
						<div className="text-input-wrapper">
							<Text className={classnames(
								'text-input', formApi.errors.email && formApi.touched.email && 'error'
							)} placeholder="Your email address" field="email" id="email"/>
							{this.getNotification(formApi, 'email')}
						</div>
						<div className="text-input-wrapper">
							<div className={classnames('password-wrapper',
								formApi.errors.password && formApi.touched.password && 'error')}>
								<Text type={this.state.isShowPassword ? 'text' : 'password'}
									className="text-input"
									placeholder="Create a password" field="password" id="password"/>
								<div className={classnames(
									'eye', this.state.isShowPassword && 'open'
								)} onClick={this.onShowPasswordClick}/>
							</div>
							{this.getNotification(formApi, 'password')}
						</div>
						<div className="checkbox-wrapper">
							<Checkbox field="email_opt_in" id="email_opt_in"/>
							<label htmlFor="email_opt_in">
								<div className="checkbox-text">{'Sign me up for offers, news and advice from DreamHost.'}</div>
							</label>
						</div>
						<button
							disabled={this.state.chainIsInProgress}
							type="submit"
							className={classnames('account-submit-button',
								{ 'spinner white': this.state.chainIsInProgress })}>
							{!this.state.chainIsInProgress && 'Create account and save site'}
						</button>
					</form>
				)}
			</Form>
		)
	}
	render() {
		return (
			<div className="account-container">
				<div className="account-text">
					{'Create an account to add your own content and begin editing your site!'}
				</div>
				{this.getForm()}
				<div className="login-question">
					<span className="description">
						{'Already have an account? '}
					</span>
					<div className="login-toggle" onClick={this.changeForm}>
						{'Log into Remixer'}
					</div>
				</div>
				<div className="terms-container">
					<span>
						{'By clicking \'Create Account\', you agree to our general '}
						<a href={'https://www.dreamhost.com/legal/'}>
							{'Terms of Service'}
						</a>
						{' policies.'}
					</span>
				</div>
			</div>
		)
	}
}
