import { isEqual } from 'lodash';

import React from 'react';
import {Container} from 'flux/utils';

import ImportActionsCreator from '../../actions/ImportActionsCreator';

import MediaServicesUtils from '../../utils/MediaServicesUtils';

import Tabs from '../common/Tabs.react';
import YoutubeSearch from './YoutubeSearch.react';
import VimeoSearch from './VimeoSearch.react';
import ExistedVideo from './ExistedVideo.react';
import VideoPickerPreview from './VideoPickerPreview.react';

import YoutubeSearchStateStore from '../../stores/YoutubeSearchStateStore';
import VimeoSearchStateStore from '../../stores/VimeoSearchStateStore';

import ContentTypes from '../../constants/ContentTypes';
import ImportTypes from '../../constants/ImportTypes';


class VideoPickerTabs extends React.Component {
	static propTypes = {
		onSelect: React.PropTypes.func.isRequired
	};
	static getStores() {
		return [YoutubeSearchStateStore, VimeoSearchStateStore];
	}
	static getVideoEmbedUrl(id, contentType) {
		if (isEqual(contentType, ContentTypes.YOUTUBE)) {
			return MediaServicesUtils.getYoutubeEmbeddedUrlById(id);
		} else if (isEqual(contentType, ContentTypes.VIMEO)) {
			return MediaServicesUtils.getVimeoEmbeddedUrlById(id);
		}
	}

	static calculateState(prevState) {
		return {
			youtubeSearchState: YoutubeSearchStateStore.getState(),
			vimeoSearchState: VimeoSearchStateStore.getState()
		};
	}

	onSelect(video, videoType) {
		switch (videoType) {
			case ContentTypes.YOUTUBE:
				ImportActionsCreator.startImport(ImportTypes.YOUTUBE, video.toJS());
				this.props.onSelect(VideoPickerTabs.getVideoEmbedUrl(video.video_id, ContentTypes.YOUTUBE));
				break;
			case ContentTypes.VIMEO:
				ImportActionsCreator.startImport(ImportTypes.VIMEO, video.toJS());
				this.props.onSelect(VideoPickerTabs.getVideoEmbedUrl(video.video_id, ContentTypes.VIMEO));
				break;
			default:
				this.props.onSelect(VideoPickerTabs.getVideoEmbedUrl(video.video_id, video.content_type));
		}
	}

	onPick(video, videoType) {
		switch (videoType) {
			case ContentTypes.YOUTUBE:
				this.setState({selectedYoutube: video});
				break;
			case ContentTypes.VIMEO:
				this.setState({selectedVimeo: video});
				break;
			default:
				this.setState({selectedExisted: video});
		}
	}

	render() {
		const {selectedYoutube, selectedVimeo, selectedExisted} = this.state;

		var youtubeSearchTitle = (
			<span>
				{
					this.state.youtubeSearchState.searching ?
						<i className="mdi mdi-reload rotate" />
						:
						<i className="mdi mdi-youtube-play" style={{backgroundColor: '#bb0000', color: '#fff'}} />
				} Search Youtube
			</span>
		);

		var vimeoSearchTitle = (
			<span>
				{
					this.state.vimeoSearchState.searching ?
						<i className="mdi mdi-reload rotate" />
						:
						<i className="mdi mdi-vimeo" style={{backgroundColor: '#17aee1', color: '#fff'}} />
				} Search Vimeo
			</span>
		);

		var existedVideoTitle = (
			<span><i className="mdi mdi-folder" style={{color: '#cece44'}} /> Pick Existing</span>
		);

		return (
			<Tabs>
				<div key="Pick Existing" titleComponent={existedVideoTitle}>
					{selectedExisted ?
						<VideoPickerPreview className={isEqual(selectedExisted.content_type, ContentTypes.YOUTUBE)?"youtube":"vimeo"} onBack={() => this.onPick(null)} onSelect={() => this.onSelect(selectedExisted)} url={VideoPickerTabs.getVideoEmbedUrl(selectedExisted.video_id, selectedExisted.content_type)} />
						:
						<ExistedVideo onPick={(existedVideo) => this.onPick(existedVideo)}/>
					}
				</div>
				<div key="Search YouTube" titleComponent={youtubeSearchTitle}>
					{selectedYoutube ?
						<VideoPickerPreview className="youtube" onBack={() => this.onPick(null, ContentTypes.YOUTUBE)} onSelect={() => this.onSelect(selectedYoutube, ContentTypes.YOUTUBE)} url={VideoPickerTabs.getVideoEmbedUrl(selectedYoutube.video_id, ContentTypes.YOUTUBE)} />
						:
						<div className="toolbar">
							<YoutubeSearch onPick={(youtubeVideo) => this.onPick(youtubeVideo, ContentTypes.YOUTUBE)} minimized={true} />
						</div>
					}
				</div>
				<div key="Search Vimeo" titleComponent={vimeoSearchTitle}>
					{selectedVimeo ?
						<VideoPickerPreview className="vimeo" onBack={() => this.onPick(null, ContentTypes.VIMEO)} onSelect={() => this.onSelect(selectedVimeo, ContentTypes.VIMEO)} url={VideoPickerTabs.getVideoEmbedUrl(selectedVimeo.video_id, ContentTypes.VIMEO)} />
						:
						<div className="toolbar">
							<VimeoSearch onPick={(vimeoVideo) => this.onPick(vimeoVideo, ContentTypes.VIMEO)} minimized={true} />
						</div>
					}
				</div>
			</Tabs>
		);
	}
}

export default Container.create(VideoPickerTabs, {withProps: true});
