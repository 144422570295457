import { map }  from 'lodash';

import React from 'react';
import {Container} from 'flux/utils';

import PixabayPaginator from './PixabayPaginator.react';
import PixabaySearchForm from './PixabaySearchForm.react';
import PixabaySearchResultList from './PixabaySearchResultList.react';

import PixabayActionsCreator from '../../actions/PixabayActionsCreator';
import ImportActionsCreator from '../../actions/ImportActionsCreator';

import PixabaySearchParamsStore from '../../stores/PixabaySearchParamsStore';
import PixabaySearchResultStore from '../../stores/PixabaySearchResultStore';
import PixabaySearchStateStore from '../../stores/PixabaySearchStateStore';

import PixabayCategories from '../../constants/PixabayCategories';
import ImportTypes from '../../constants/ImportTypes';

import i18n from '../../utils/i18n';

class PixabaySearch extends React.Component {
	static getStores() {
		return [PixabaySearchParamsStore, PixabaySearchResultStore, PixabaySearchStateStore];
	}
	static calculateState(prevState) {
		return {
			searchParams: PixabaySearchParamsStore.getState(),
			searchResult: PixabaySearchResultStore.getState(),
			searchState: PixabaySearchStateStore.getState()
		};
	}
	_search(query, category, page, compact) {
		PixabayActionsCreator.search(query, category, page, compact);
	}
	_onChangeQuery = (query = "", category="all") => {
		this._search(query, category, 1);
	};
	_onChangePage = (nextPage) => {
		const {query, category, page} = this.state.searchParams;
		this._search(query, category, nextPage, page + 1 == nextPage);
	};
	render() {
		const {query, category, page} = this.state.searchParams;
		const {searching, total, per_page} = this.state.searchState;
		const isInitialState = !query && !category;

		return (
			<div className="inner-panel pixabay-search">
				<div className="row title-bar">
					<div className="large-8 columns">
						<p className="title left">
							{searching &&
								<i className="mdi mdi-reload rotate" />
							}
							{isInitialState ?
								i18n.t("Search for images")
								:
								i18n.t("Click an image to add to your library")
							}
						</p>
					</div>
					{!isInitialState &&
					<div className="large-4 toolbar-search columns">
						<PixabaySearchForm onSearch={this._onChangeQuery} query={query} minified={true} />
					</div>
					}
				</div>
				{isInitialState ?
					<div className="row pixabay-search-group">
						<div className="large-12 columns pixabay-search-out search-form">
							<div className="row">
								<div className="large-12 columns text-center"></div>
							</div>
							<div className="row">
								<div className="large-12 columns text-center"><img src="https://pixabay.com/static/img/logo.png" width="180" /></div>
							</div>
							<div className="row">
								<div className="large-12 columns text-center">Over 670,000 free stock images, vectors &amp; art illustrations</div>
							</div>
							<PixabaySearchForm onSearch={this._onChangeQuery} query={query} />
							<div className="row">
								<div className="large-12 columns text-center">
									<a onClick={() => this._onChangeQuery("")} className="pixaCategory">{i18n.t("Popular")}</a>
									{map(PixabayCategories, (pixabayCategory) => <a key={pixabayCategory} onClick={() => this._onChangeQuery(pixabayCategory)} className="pixaCategory">{i18n.t(pixabayCategory)}</a>)}
								</div>
							</div>
						</div>
					</div>
					:
					<div className="row pixabay-search-group">
						<div className="large-12 columns pixabay-search-out">
							<div className="row">
								<div className="large-11 columns text-left">
									<PixabayPaginator total={total} page={page} perPage={per_page} items={this.state.searchResult.size} onChange={this._onChangePage}/>
								</div>
								<div className="large-1 columns text-right">
									<a onClick={() => this._onChangeQuery("", null)}>Back</a>
								</div>
							</div>
							<div className="pixabay-search-panel">
								<div className="large-12 columns">
									<PixabaySearchResultList searchResultList={this.state.searchResult} onPick={(searchResult) => ImportActionsCreator.startImport(ImportTypes.PIXABAY, { url: searchResult.url })} />
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default Container.create(PixabaySearch);
