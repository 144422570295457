'use strict';

import { find } from 'lodash';
var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

/** @module KeyPicker
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 */
var KeyPicker = ComponentFactory.Create({
	displayName: "KeyPicker",
	propTypes: {
		content: React.PropTypes.string
	},
	/**
	 * @method render
	 */
	render: function() {
		return find(this.props.children, {key: this.props.content}) || null;
	}
});

module.exports = KeyPicker;
