'use strict';

import { isFunction } from 'lodash';

var PropertyUtils = require('./PropertyUtils');
var ComponentUtils = require('./ComponentUtils');

var EditorCommands = require('../constants/EditorCommands');

/** @module CommandParameterUtils
 * @requires lodash
 * @requires PropertyUtils
 * @requires ComponentUtils
 * @requires EditorCommands
 */

var _strategy = {};
_strategy[EditorCommands.textPropertyChange] = function(element) {
	var property = PropertyUtils.GetPropertyElement(element);
	var propertyData = PropertyUtils.GetPropertyData(property);
	if (propertyData) {
		propertyData.value = property.innerHTML;
		propertyData.componentId = ComponentUtils.GetComponentId(element);
	}
	return propertyData;
};

function getParameters(element, commandName, ev) {
	var method = _strategy[commandName];
	if(isFunction(method)) {
		var parameters = method.call(_strategy, element, ev);
		return parameters || null; //it's importans to return null
	}
}

module.exports = {
	GetParameters: getParameters
};
