import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

const DreamHostersState = Immutable.Record({
	available: false,
	error: null,
	domain: null
});

class DreamHostersStore extends ReduceStore {
	getInitialState() {
		return new DreamHostersState();
	}

	reduce(state, action) {
		switch (action.actionType) {
			case RemixerConstants.RECEIVE_DREAMHOSTERS_DOMAIN:
				return new DreamHostersState({available: true, domain: action.domain});
			case RemixerConstants.RECEIVE_DREAMHOSTERS_DOMAIN_FAILED:
				return new DreamHostersState({available: false, domain: action.domain, error: action.error});
			default:
				return state;
		}
	}
}

export default new DreamHostersStore(AppDispatcher);
