"use strict";

import { create, map, filter, some, partial, values, isEqual, keyBy } from 'lodash';

var Validator = require('../utils/Validator');
var Mapper = require('../utils/Mapper');

var ComponentTypes = require('../constants/ComponentTypes');
var ComponentCategories = require('../constants/ComponentCategories');

var ComponentReference = require('./ComponentReference');

var PropertyMetadata = require("./PropertyMetadata");

/**
 * @method _createReference
 */
function _createReference(params) {
	return new ComponentReference(params);
}

/**
 * @method _createProperty
 */
function _createProperty(params) {
	return new PropertyMetadata(params);
}

/** @module ComponentMetadata
 * @requires Validator
 * @requires Mapper
 * @requires ComponentTypes
 * @requires ComponentCategories
 * @requires ComponentReference
 */
function ComponentMetadata(dto) {
	dto.thumbnailUrl = dto.thumbnailUrl || "https://placehold.it/150x100";

	Validator.isTypeOf(dto, "string", ["description", "thumbnailUrl"]);
	Validator.inRange(dto, ComponentTypes, ["componentType"]);
	Validator.inRange(dto, ComponentCategories, ["componentCategory"]);

	dto.dependencies = dto.dependencies || [];
	Validator.isInstanceOf(dto, Array, ["dependencies"]);

	this.description = dto.description;
	this.componentType = dto.componentType;
	this.componentCategory = dto.componentCategory;
	this.dependencies = map(dto.dependencies, _createReference);
	this.reference = _createReference(dto);
	this.thumbnailUrl = dto.thumbnailUrl;

	/**
	 * @method filterDependencies
	 */
	this.filterDependencies = function(exclude) {
		return filter(this.dependencies, function (dependency) {
			return !some(exclude, partial(isEqual, dependency));
		});
	};

	/**
	 * @method hasDependencies
	 */
	this.hasDependencies = function(reference) {
		return some(this.dependencies, partial(isEqual, reference));
	};
}

/**
 * @method TemplatedComponentMetadata
 */
function TemplatedComponentMetadata(dto) {
	ComponentMetadata.call(this, dto);

	dto.properties = values(dto.properties);
	Validator.isInstanceOf(dto, Array, ["properties"]);

	this.properties = keyBy(map(dto.properties, _createProperty), 'name');
}

/**
 * @method MetaComponentMetadata
 */
function MetaComponentMetadata(dto) {
	TemplatedComponentMetadata.call(this, dto);

	var template = dto.stylesheet || dto.html;
	Validator.isTypeOf(template, "string");

	if (dto.stylesheet) this.stylesheet = dto.stylesheet;
	else this.html = dto.html;
}

/**
 * @method ScriptComponentMetadata
 */
function ScriptComponentMetadata(dto) {
	TemplatedComponentMetadata.call(this, dto);

	var template = dto.javascript || dto.html;
	Validator.isTypeOf(template, "string");

	if (dto.javascript) this.javascript = dto.javascript;
	else this.html = dto.html;
}

/**
 * @method ContentComponentMetadata
 */
function ContentComponentMetadata(dto) {
	TemplatedComponentMetadata.call(this, dto);

	Validator.isTypeOf(dto, "string", ["html"]);

	dto.stylesheet = dto.stylesheet || "";
	dto.javascript = dto.javascript || "";
	Validator.isTypeOf(dto, "string", ["stylesheet", "javascript"]);

	this.html = dto.html;
	this.stylesheet = dto.stylesheet;
	this.javascript = dto.javascript;
}

TemplatedComponentMetadata.prototype = create(ComponentMetadata.prototype, {
	'constructor': TemplatedComponentMetadata
});
MetaComponentMetadata.prototype = create(TemplatedComponentMetadata.prototype, {
	'constructor': MetaComponentMetadata
});
ContentComponentMetadata.prototype = create(TemplatedComponentMetadata.prototype, {
	'constructor': ContentComponentMetadata
});
ScriptComponentMetadata.prototype = create(TemplatedComponentMetadata.prototype, {
	'constructor': ScriptComponentMetadata
});

/**
 * @method _create
 */
function _create(dto) {
	dto = Mapper.toCamelCase(dto);
	Validator.inRange(dto, ComponentTypes, ["componentType"]);

	if (dto.componentType === ComponentTypes.base) {
		return new ContentComponentMetadata(dto);
	}
	if (dto.componentType === ComponentTypes.content) {
		return new ContentComponentMetadata(dto);
	}

	if (dto.componentType === ComponentTypes.hidden) {
		return new ContentComponentMetadata(dto);
	}

	if (dto.componentType === ComponentTypes.meta) {
		return new MetaComponentMetadata(dto);
	}

	if (dto.componentType === ComponentTypes.script) {
		return new ScriptComponentMetadata(dto);
	}
}

module.exports = {
	Factory: { Create: _create },
	ComponentMetadata: ComponentMetadata
};
