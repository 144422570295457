'use strict';

var React = require('react');

var ComponentFactory = require('../../../infrastructure/ComponentFactory');
var ImportIconBar = require('../../common/ImportIconBar.react');
var AudioUrlImport = require('../../common/AudioUrlImport.react');

/** @module AudioImporterToolBar
 * @requires react
 * @requires ComponentFactory
 * @requires ImportIconBar
 * @requires AudioUrlImport
 */
var AudioImporterToolBar = ComponentFactory.Create({
	displayName: "AudioImporterToolBar",
	// eslint-disable-next-line react/display-name
	render: function () {
		var icons = ["Soundcloud"];
		return (
			<div className="row import-header">
				<div className="medium-6 large-3 columns">
					<AudioUrlImport title="Import a SoundCloud URL" tagline="supply direct link to audio"/>
				</div>
				<div className="medium-6 large-9 columns">
					<ImportIconBar title="Import social media" tagline="select source or expand for more" icons={icons} />
				</div>
			</div>
		);
	}
});

module.exports = AudioImporterToolBar;
