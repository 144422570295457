'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module PageServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var PageServerActionsCreator = {
	/**
	 * @method receiveAllPages
	 */
	receiveAllPages: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_ALL_PAGES,
			pages: response.data
		});
	},
	/**
	 * @method receiveAllPagesFail
	 */
	receiveAllPagesFail: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_ALL_PAGES_FAIL
		});
	},

	/**
	 * @method receiveUpdatePageProperties
	 */
	receiveUpdatePageProperties: function(pageId, pageProperties) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_PAGE_PROPS,
			pageId: pageId,
			pageProperties: pageProperties
		});
	}
};

module.exports = PageServerActionsCreator;
