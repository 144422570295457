import { isEqual } from 'lodash';

import React from 'react';
import classNames from 'classnames';

import ContentTypes from '../../constants/ContentTypes';

import PreloadedImg from './PreloadedImg.react';


export default class ExistedVideoResult extends React.Component {
	static propTypes = {
		searchResult: React.PropTypes.object.isRequired,
		onPick: React.PropTypes.func.isRequired
	};

	render() {
		const {searchResult, onPick} = this.props;
		const iconCss = {
			"mdi": true,
			"mdi-youtube-play": isEqual(searchResult.content_type, ContentTypes.YOUTUBE),
			"mdi-vimeo": isEqual(searchResult.content_type, ContentTypes.VIMEO)
		}

		return (
			<div className="collection-column" onClick={onPick}>
				<div className="collection-out" >
					<PreloadedImg
						className="primary"
						imageUrl={searchResult.url}
						showLoadingPlaceholder={false}
						component="div"
					>
					</PreloadedImg>
					<div className="collection-name" ><p><i className={classNames(iconCss)} /> {searchResult.title}</p></div>
				</div>
			</div>
		);
	}
}
