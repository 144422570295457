import React from 'react';
import { some } from 'lodash';

export default class Form extends React.Component {
	static propTypes = {
		model: React.PropTypes.object.isRequired,
		onSave: React.PropTypes.func,
		onValidate: React.PropTypes.func,
		onFilter: React.PropTypes.func
	};
	static defaultProps = {
		onSave: (model) => {},
		onValidate: (key, value) => true,
		onFilter: (key, value) => value
	};
	static _getState(props) {
		return {
			model: props.model,
			errors: {}
		};
	}
	saveOnChange = false;
	constructor(props) {
		super(props);
		this.state = Form._getState(props);

		this.handleInputChange = this.handleInputChange.bind(this);
	}
	componentWillReceiveProps(props) {
		if (!this.props.model.equals(props.model)) {
			this.setState(Form._getState(props));
		}
	}
	reset() {
		this.setState(Form._getState(this.props));
	}
	handleInputChange(event) {
		const target = event.target;
		const {value, name} = target;

		let errors = this.state.errors;
		errors[name] = !this.props.onValidate(name, value);

		let state = {
			model: this.state.model.set(name, this.props.onFilter(name, value)),
			errors: errors
		};

		if (this.saveOnChange) {
			this.setState(state, () => this.save());
		} else {
			this.setState(state);
		}
	}
	save(force=false) {
		let {errors, model} = this.state;

		if (!force && (some(errors) || this.props.model.equals(model))) {
			return;
		}

		this.props.onSave(model);
	}
}
