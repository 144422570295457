'use strict';

import { omit } from 'lodash';
var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var ImportTypes = require('../constants/ImportTypes');
var RouteStore = require('../stores/RouteStore');

/** @module ImportServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires ImportTypes
 */
var ImportServerActionsCreator = {
	/**
	 * @method receiveImportStarted
	 */
	receiveImportStarted: function(importType, payload, response) {
		var transformedData = ImportTypes.transformToEnum(response.data, 'import_type');
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_IMPORT_STARTED,
			importType: importType,
			data: transformedData,
			payload: payload
		});

		var route = RouteStore.getState('route');
		if (route.params && route.params.action === 'startImport') {
			var newParams = omit(route.params, ['action', 'actionParam']);
			route.transitionTo('content', newParams);
		}
	},
	/**
	 * @method receiveImportFinished
	 */
	receiveImportFinished: function(importType, payload, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_IMPORT_FINISHED,
			importType: importType,
			data: response.data,
			payload: payload
		});
	},
	/**
	 * @method receiveImportFails
	 */
	receiveImportFails: function(importType, payload, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.IMPORT_FAILS,
			importType: importType,
			data: response.responseJSON || response.data,
			payload: payload
		});
	},
	/**
	 * @method receiveImportHistory
	 */
	receiveImportHistory: function(importType, response) {
		var transformedData = ImportTypes.transformToEnum(response.data, 'import_type');
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_IMPORT_HISTORY,
			importType: importType,
			data: transformedData
		});
	},
	/**
	 * @method receiveImportHistoryFails
	 */
	receiveImportHistoryFails: function(importType) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_IMPORT_HISTORY_FAILS,
			importType: importType,
			message: 'We could not get imported content'
		});
	},
	/**
	 * @method receiveImportAuthExpired
	 */
	receiveImportAuthExpired: function(importType) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_IMPORT_AUTH_EXPIRED,
			importType: importType
		});
	}
};

module.exports = ImportServerActionsCreator;
