'use strict';

import { isElement, some, mapKeys, map, union, pick, kebabCase, camelCase, assign } from 'lodash';
var $ = require('jquery');

var ShapeUtils = require('./ShapeUtils');

/** @module DomElementUtils
 * @requires lodash
 * @requires ShapeUtils
 */

/**
 * @method getCompiledStyle
 */
function getCompiledStyle(element, properties) {
	if (!isElement(element)) return;

	//FF compiled styles is an object with camel case propertis
	//Chrome compiled styles is an object with kebab case propertis
	if(some(properties)) properties = union(properties, map(properties, camelCase));
	var style = pick(window.getComputedStyle(element), properties);

	return mapKeys(style, function(v, k) { return kebabCase(k); });
}

/**
 * @method getElementRect
 * find rectangle which surrounds element. if contents of the element has fixed position
 * then rect.height of the original element will be zero and we should instead
 * check the first child directly
 */
function getElementRect(element) {
	if (!isElement(element)) return;

	var rect = element.getBoundingClientRect();
	var fixed = false;
	if (rect.height === 0 && element.children.length && element.className !== 'base'){
		rect = element.children[0].getBoundingClientRect();
		fixed = true;
	}
	return {
		width: rect.width,
		height: rect.height,
		left: rect.left + window.pageXOffset,
		top: rect.top + window.pageYOffset,
		zIndex: fixed ? 2 : 1  //to make sure the fixed overlay is higher than other chords
	};
}

/**
 * @method getRelativeParent
 */
function getRelativeParent(element) {
	if (!isElement(element)) return;

	var relative;
	var current = element.parentElement;
	while(!relative) {
		var parentCss = getCompiledStyle(current, ['position']);
		if(parentCss.position === 'relative' || parentCss.position === 'absolute' || !current.parentElement) relative = current;
		current = current.parentElement;
	}

	return relative;
}

/** find position of element relative to 'relative'
 * @method getElementRelativeRect
 */
function getElementRelativeRect(element, relative) {
	if (!isElement(element) || !isElement(relative)) return;

	var relativeRect = getElementRect(relative);
	var targetRect = getElementRect(element);

	return ShapeUtils.RelativeRect(relativeRect, targetRect);
}

/**
 * @method getElementScrollRect
 */
function getElementScrollRect(element) {
	return assign(getElementRect(element), {
		width: element.scrollWidth,
		height: element.scrollHeight
	});
}

/**
 * @method isCoordinatesOutside
 */
function isCoordinatesOutside(absoluteX, absoluteY, element, rescale) {
	var size = getElementRect(element);

	return !ShapeUtils.IsCoordinatesInRect(absoluteX, absoluteY, size, rescale);
}

/**
 * @method isCoordinatesInBottom
 */
function isCoordinatesInBottom(absoluteX, absoluteY, element, rescale) {
	var size = getElementRect(element);

	if(!size) return;

	size.top += size.height / 2;
	size.height = size.height / 2;

	return ShapeUtils.IsCoordinatesInRect(absoluteX, absoluteY, size, rescale);
}

/**
 * @method isCoordinatesInView
 */
function isCoordinatesInView(absoluteX, absoluteY, element, rescale) {
	var rect = ShapeUtils.ScaleRect(getElementRect(element), rescale);

	var clientX = absoluteX - element.scrollLeft;
	var clientY = absoluteY - element.scrollTop;

	return rect.left <= clientX && clientX <= rect.width &&
		rect.top <= clientY && clientY <= rect.height;
}

function scrollDocumentTop(top){
	$("html, body").stop().animate({ scrollTop: top}, 300);
}

function isIE() {
	var ua = window.navigator.userAgent;

	// Test values; Uncomment to check result …

	// IE 10
	// ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

	// IE 11
	// ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

	// Edge 12 (Spartan)
	// ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

	// Edge 13
	// ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

	var msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	var trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	var edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}

	// other browser
	return false;
}


module.exports = {
	GetElementRect: getElementRect,
	GetElementScrollRect: getElementScrollRect,
	GetRelativeParent: getRelativeParent,
	GetElementRelativeRect: getElementRelativeRect,
	IsCoordinatesOutside: isCoordinatesOutside,
	IsCoordinatesInView: isCoordinatesInView,
	IsCoordinatesInBottom: isCoordinatesInBottom,
	GetCompiledStyle: getCompiledStyle,
	scrollDocumentTop: scrollDocumentTop,
	IsIE: isIE
};
