'use strict';

import { size } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

function wrap(Component) {
	/** @module NoResultsWrapper
	 * @requires lodash
	 * @requires react
	 * @requires ComponentFactory
	 */
	var NoResultsWrapper = ComponentFactory.Create({
		displayName: "NoResultsWrapper",
		propTypes: {
			content: React.PropTypes.array,
			contentItem: React.PropTypes.object,
			picked: React.PropTypes.array,
			onPick: React.PropTypes.func,
			onDelete: React.PropTypes.func,
			action: React.PropTypes.string,
			loading: React.PropTypes.bool
		},

		/**
		 * @method render
	 	 */
		render: function() {
			return (size(this.props.content) || this.props.action ?
				<Component {...this.props} />
				:
				<div className="row">
					<div className="small-12 columns">
						<div className="no-results">{this.props.loading ? 'Loading...' : 'No Results'}</div>
					</div>
				</div>
			);
		}
	});
	return NoResultsWrapper;
}

module.exports = wrap;
