'use strict';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var PageView = require('./PageView.react');
var Mapper = require('../utils/Mapper');

var ThemePagesStore = require('../stores/ThemePagesStore');
var ThemesActionsCreator = require('../actions/ThemesActionsCreator');

import { values, get, assign, isEqual, first } from 'lodash'

var Devices = Mapper.mirrorKey({
	desktop: null,
	tablet: null,
	phone: null
});

var Orientations = Mapper.mirrorKey({
	landscape: null,
	portrait: null
});

/** @module ThemePreview
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires PageView
 * @requires Mapper
 * @requires ThemePagesStore
 * @requires ThemesActionsCreator
 */
var ThemePreview = ComponentFactory.Create(ThemePagesStore, {
	displayName: "ThemePreview",
	propTypes: {
		themeId: React.PropTypes.number.isRequired,
		themeTitle: React.PropTypes.string.isRequired,
		initialDevice: React.PropTypes.oneOf(values(Devices)),
		selectButtonText: React.PropTypes.string.isRequired,
		closeButtonText: React.PropTypes.string,
		onClose: React.PropTypes.func,
		onSelect: React.PropTypes.func
	},

	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			initialDevice: Devices.desktop
		};
	},

	statics: {
		Devices: Devices
	},

	/**
	 * @method _deviceButtonCss
	 */
	_deviceButtonCss: function(device, orientation, currentlyActiveDevice) {
		return this.css('device-button', device, orientation, {active: device === currentlyActiveDevice});
	},

	/**
	 * @method _previewContentCss
	 */
	_previewContentCss: function(device, orientation) {
		return this.css('preview-content', device, orientation);
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function () {
		return assign(this.getState(), {
			device: this.props.initialDevice,
			orientation: Orientations.portrait
		});
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props) {
		ThemesActionsCreator.loadThemePages(this.props.themeId);

		if(!isEqual(get(props, 'themeId'), this.props.themeId)) {
			this.setState(this.getState());
		}
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			page: first(values(ThemePagesStore.getState(this.props.themeId)))
		};
	},

	/**
	 * @method _setDesktopMode
	 */
	_setDesktopMode: function () {
		this.setState({device: Devices.desktop});
	},

	/**
	 * @method _setGadgetMode
	 */
	_setGadgetMode: function (device) {
		if (this.state.device === device) {
			this._swapOrientation();
			return;
		}
		this.setState({device: device});
	},

	/**
	 * @method _setTabletMode
	 */
	_setTabletMode: function () {
		this._setGadgetMode(Devices.tablet);
	},

	/**
	 * @method _setPhoneMode
	 */
	_setPhoneMode: function () {
		this._setGadgetMode(Devices.phone);
	},

	/**
	 * @method _swapOrientation
	 */
	_swapOrientation: function () {
		this.setState(function(previousState, currentProps) {
			var newOrientation = previousState.orientation === Orientations.landscape ? Orientations.portrait : Orientations.landscape;
			return {orientation: newOrientation};
		});
	},

	/**
	 * @method render
	 */
	render: function() {
		var device = this.state.device;
		var orientation = this.state.orientation;

		var orientationToggleStyle = device === Devices.desktop ? {display: "none"} : null;
		return (
			<div className="theme-preview">
				<div className="preview-bar">
					<div className="theme-info">
						<span className="theme-title-label">Live demo for </span><span className="theme-title">{this.props.themeTitle}</span>
					</div>
					<div className="devices">
						<div className={this._deviceButtonCss(Devices.desktop, Orientations.landscape, device)} title="Preview for desktops">
							<a onClick={this._setDesktopMode}><i/></a>
						</div>
						<div className={this._deviceButtonCss(Devices.tablet, orientation, device)} title="Preview for tablets">
							<a onClick={this._setTabletMode}><i/></a>
						</div>
						<div className={this._deviceButtonCss(Devices.phone, orientation, device)} title="Preview for phones">
							<a onClick={this._setPhoneMode}><i/></a>
						</div>
						<div className="device-button orientation-toggle" style={orientationToggleStyle}
							title="Swap orientation">
							<a onClick={this._swapOrientation}><i/></a>
						</div>
					</div>
					<div className="buttons">
						<a className="back-button" onClick={this.props.onClose}><i/>{this.props.closeButtonText}</a>
						<a className="choose-button" onClick={this.props.onSelect}><i/>{this.props.selectButtonText}</a>
					</div>
				</div>
				<div className={this._previewContentCss(device, orientation)}>
					<div className="iframe-wrapper" >
						{this.state.page && <PageView page={this.state.page} />}
					</div>
				</div>
			</div>
		);
	}
});

module.exports = ThemePreview;
