'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
import api2Client from '../utils/api2-client';

/** @module DreamHostersAvailableActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var DreamHostersAvailableActionsCreator = {
	/**
	 * @method checkDreamHostersAvailable
	 */
	checkDreamHostersAvailable: function(domain, website) {
		var data = { domain: domain, website: website };
		AppDispatcher.dispatch({
			actionType: RemixerConstants.CHECK_DREAMHOSTERS_DOMAIN,
			data: data
		},
		api2Client.checkDreamhostersDomain, [domain, website]);
	}
};

module.exports = DreamHostersAvailableActionsCreator;
