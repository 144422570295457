'use strict';

var $ = require('jquery');
import { isEmpty, words, toArray, invokeMap } from 'lodash';

var ComponentDomAttributes = require('../constants/ComponentDomAttributes');

/** @module ComponentUtils
 * @requires jquery
 * @requires lodash
 * @requires ComponentDomAttributes
 */

function createComponent(id, html) {
	if(isEmpty(html)) return;

	var component = $(html);
	if (component.length !== 1) return;

	component.attr(ComponentDomAttributes.id, id);

	return component.get(0);
}

function getComponentSelector(id) {
	var search = id ? '=' + id : '';
	return '[' + ComponentDomAttributes.id + search + ']';
}

function getComponentElementBy(root, componentId) {
	return $(root).find(getComponentSelector(componentId)).get(0);
}

function getComponentElement(element) {
	return $(element).closest(getComponentSelector()).get(0);
}

function getComponentId(element) {
	var component = getComponentElement(element);
	if (!component) return;

	return $(component).attr(ComponentDomAttributes.id);
}

function getComponentPosition(element) {
	var component = getComponentElement(element);
	if (!component) return;

	return +$(component).attr(ComponentDomAttributes.position);
}

function getComponentName(element) {
	var component = getComponentElement(element);
	if (!component) return;

	return words(component.getAttribute('class')).join(' ');
}

function getAllComponents(root) {
	return toArray($(root || document).find(getComponentSelector()));
}

function getActiveSelector() {
	return '[' + ComponentDomAttributes.active + '=true]';
}

function isActiveComponent(element) {
	return !!$(element).closest(getActiveSelector()).get(0);
}

function getExpandSelector() {
	return '[' + ComponentDomAttributes.expanded + '=true]';
}

function collapseAllComponentsSpace(root) {
	invokeMap($(root || document).find(getExpandSelector()), 'removeAttribute', ComponentDomAttributes.expanded);
}

function expandComponentSpaceBy(componentId, root) {
	collapseAllComponentsSpace(root);
	if(!componentId) return;

	var component = getComponentElementBy(root, componentId);
	if(!isActiveComponent(component)) return;

	component.setAttribute(ComponentDomAttributes.expanded, true);
}

function deactivateAllComponents(root) {
	invokeMap($(root || document).find(getActiveSelector()), 'removeAttribute', ComponentDomAttributes.active);
}

function activateComponentBy(componentId, root) {
	deactivateAllComponents(root);
	collapseAllComponentsSpace(root);

	var component = getComponentElementBy(root, componentId);
	if(component) component.setAttribute(ComponentDomAttributes.active, true);
}

function getActiveComponent(root) {
	return $(root || document).find(getActiveSelector()).get(0);
}

function getComponentInfo(element) {
	var component = getComponentElement(element);
	if (!component) return;

	return {
		componentId: $(component).attr(ComponentDomAttributes.id),
		position: +$(component).attr(ComponentDomAttributes.position),
		name: words(component.getAttribute('class')).join(' ')
	};
}

module.exports = {
	GetComponentSelector: getComponentSelector,
	GetComponentElement: getComponentElement,
	GetComponentElementBy: getComponentElementBy,
	GetComponentId: getComponentId,
	GetComponentName: getComponentName,
	GetComponentInfo: getComponentInfo,
	CreateComponent: createComponent,
	GetComponentPosition: getComponentPosition,
	GetAllComponents: getAllComponents,
	ActivateComponentBy: activateComponentBy,
	GetActiveComponent: getActiveComponent,
	IsActiveComponent: isActiveComponent,
	ExpandComponentSpaceBy: expandComponentSpaceBy
};
