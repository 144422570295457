import React from 'react';

import PixabaySearchResult from './PixabaySearchResult.react';

export default class PixabaySearchResultList extends React.Component {
	static propTypes = {
		searchResultList: React.PropTypes.object.isRequired,
		onPick: React.PropTypes.func.isRequired
	};

	render() {
		const {searchResultList, onPick} = this.props;

		const items = [];
		for (let searchResult of searchResultList) {
			items.push(<PixabaySearchResult key={searchResult.id} searchResult={searchResult} onPick={() => onPick(searchResult)} />);
		}
		return (
			<div className="pixabay-search-result">
				{items}
			</div>
		);
	}
}
