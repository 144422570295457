'use strict';

import { partial } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

/** @module ThumbnailSoundcloud
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 */
var ThumbnailSoundcloud = ComponentFactory.Create({
	displayName: "ThumbnailSoundcloud",
	propTypes: {
		contentItem: React.PropTypes.object.isRequired,
		picked: React.PropTypes.bool,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	/**
	 * @method render
	 */
	render: function() {
		var url = this.props.contentItem.url;
		return (
			<div
				onClick={this.props.onPick && partial(this.props.onPick, this.props.contentItem)}
				className={this.css("image-library-thumbnail soundcloud-thumbnail", {"picked": this.props.picked})}
				style={{ backgroundImage: 'url("' + url + '")'}}
			>
				<div className="details">
					<div className="icon">
						<i className="mdi mdi-soundcloud" />
					</div>
					<div className="title">
						<p>{this.props.contentItem.title}</p>
					</div>
				</div>
			</div>
		);
	}
});

module.exports = ThumbnailSoundcloud;
