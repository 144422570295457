'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var WebAPI = require('../utils/WebAPI');

var UserStore = require('../stores/UserStore');

/** @module UserInfoActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 * @requires UserStore
 */
var UserInfoActionsCreator = {
	/**
	 * @method loadUserInfo
	 */
	loadUserInfo: function() {
		if (!UserStore.needs()) return;

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_USER_INFO
		},
		WebAPI.loadUserInfo);
	},
	/**
	 * @method loadUserInfoWithRedirect
	 */
	loadUserInfoWithRedirect: function (redirect = 'no_redirect', website = 'no_website') {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_USER_INFO
		},
		WebAPI.loadUserInfoWithRedirect, [redirect, website]);
	}
};

module.exports = UserInfoActionsCreator;
