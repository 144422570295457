'use strict';

var _ = require('lodash');
var React = require('react');

var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ContentTypes = require('../../constants/ContentTypes');

var ContentActionsCreator = require('../../actions/ContentActionsCreator');

var ContentStore = require('../../stores/ContentStore');

var ExistedVideoResultList = require('../common/ExistedVideoResultList.react').default;

var ExistedVideo = ComponentFactory.Create(ContentStore, {
	displayName: "VideoPicker",
	propTypes: {
		onPick: React.PropTypes.func
	},

	_calculateList: function(state) {
		var videos = state.videos;
		var page = state.page;
		var pages = _.chunk( videos, 8);
		var next_page = pages.length > (page + 1) ? page + 1 : null;
		if (next_page === null && page != 0) {
			next_page = 0;
		}
		var pageItems = pages[page];
		state.next_page = next_page;
		state.pageItems = pageItems;
		return state;
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var videos = _([ContentTypes.YOUTUBE, ContentTypes.VIMEO])
			.map(function(contentType) {
				return ContentStore.getState(contentType);
			})
			.compact()
			.flatten()
			.sortBy(["id"])
			.value();

		var state = {
			videos: videos,
			page: (this.state ? this.state.page : 0)
		};
		return this._calculateList(state);
	},

	componentDidUpdate: function(prevProps, prevState) {
		ContentActionsCreator.loadContent(ContentTypes.YOUTUBE);
		ContentActionsCreator.loadContent(ContentTypes.VIMEO);
	},

	_onChangePage: function(nextPage) {
		var state = {
			videos: this.state.videos,
			page: nextPage
		};
		this.setState(this._calculateList(state));
	},

	render: function() {
		var videos = _.get(this, 'state.videos');
		var pageItems = _.get(this, 'state.pageItems');
		var next_page = _.get(this, 'state.next_page');

		var importedVideosHtml;
		var pickHint;

		if (videos && videos.length) {
			pickHint = <span className="hint">Click on an imported video to pick:</span>;

			importedVideosHtml = (
				<div>
					<ExistedVideoResultList searchResultList={pageItems} onPick={this.props.onPick} />
					{next_page !== null ?
						<div className="collection-column more-column" onClick={() => this._onChangePage(next_page)}>
							<div className="more">
								<p className="more-text">More</p>
								<i className="mdi mdi-arrow-right-bold-circle-outline more-icon"></i>
							</div>
						</div>
						:
						null
					}
				</div>
			);

		} else {
			pickHint = <span className="hint">There are no imported videos:</span>;
			importedVideosHtml = (
				<div>
					<a href={this.route().makeHref('content', { tab: 'videos'})}> ;
						<h3 className="suggest">Import videos in the Content section.</h3>
					</a>
				</div>
			);
		}

		return (
			<div className="toolbar">
				<div className="title-bar row existed">
					<label className="left">
						{pickHint}
					</label>
				</div>
				<div className="row youtube-search-group">
					{importedVideosHtml}
				</div>
			</div>
		);
	}
});

module.exports = ExistedVideo;
