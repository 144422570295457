'use strict';

var ImportTypes = require('../constants/ImportTypes');
import { forEach } from 'lodash';

/** @module en
 * @requires ImportTypes
 * @requires lodash
 */
var en = {
	'images': "Images",
	'videos': "Videos",
	'audios': "Audio",
	'markdown': "Markdown",
	'groups': "Content Groups",
	'LAST_HOUR': "Last hour",
	'TODAY': "Today",
	'YESTERDAY': "Yesterday",
	'THIS_WEEK': "This week",
	'THIS_MONTH': "This month",
	'THIS_YEAR': "This year"
};

forEach(ImportTypes, function (importType) {
	en[importType.name] = importType.displayName;
});

module.exports = en;
