import { map } from 'lodash';

import React from 'react';
import {Container} from 'flux/utils';

import WebsitesStore from '../../stores/WebsitesStore';
import SitePublisherStore from '../../stores/SitePublisherStore';

import PublishStatuses from '../../constants/PublishStatuses';

var PublishActionsCreator = require('../../actions/PublishActionsCreator');
var FlashMessageActionsCreator = require('../../actions/FlashMessageActionsCreator');


class PublishButton extends React.Component {
	static propTypes = {
		websiteId: React.PropTypes.number.isRequired
	}
	static getStores() {
		return [WebsitesStore, SitePublisherStore];
	}
	static calculateState(prevState, props){
		return {
			website: WebsitesStore.getState().get(props.websiteId),
			sitePublisher: SitePublisherStore.getState().get(props.websiteId)
		};
	}

	componentDidMount() {
		this.publishingStageUpdater = setInterval(() => this.setState({publishingStage: this.state.publishingStage + 1}), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.publishingStageUpdater);
		clearTimeout(this.launchTimeout);
	}

	handleStartPublish(websiteId) {
		if (this.state.sitePublisher === PublishStatuses.PUBLISHING) {
			FlashMessageActionsCreator.addFlashMessage({text: "Publish action is already in progress"});
			return;
		}
		this.setState({launch: true});
		this.launchTimeout = setTimeout(()=> {
			this.setState({publishingStage: 0, launch: false});
			PublishActionsCreator.startPublishSite(websiteId);
		}, 800);
	}

	publishingText() {
		var publishingStages = [
			"Publishing Started",
			"Contacting Servers",
			"Exporting Images",
			"Joining Chords",
			"Bundling CSS",
			"Admiring Page",
			"Finishing Touches"
		];
		var i = this.state.publishingStage < publishingStages.length ? this.state.publishingStage : publishingStages.length - 1;
		var text = publishingStages[i].split(' ');
		var filler = [];
		if (i === 0) {
			filler.push(<i className="mdi mdi-cloud-upload" />);
		} else {
			filler.push(<div style={{height: '18px'}} />)
		}
		filler.push(map(text, function(n, key) {
			return(<p key={key}>{n}</p>);
		}))
		return (<div className="publishing-text">{filler}</div>);
	}

	render() {
		if (!this.state.website) {
			return null;
		}
		if (this.state.sitePublisher === PublishStatuses.PUBLISHING) {
			return (
				<div className="navigation-buttons">
					<div className="publishing-animation-holder">
						<div className="spinning-rocket-trail">
							<div className="trail-holder">
								<div className="trail-trail"/>
								<div className="trail-background" />
							</div>
							<div className="trail-rocket" />
						</div>
					</div>
					{this.publishingText()}
				</div>
			);
		} else if (this.state.website.status === PublishStatuses.PUBLISHED) {
			return (
				<a href={this.state.website.publicUrl} target="_blank">
					<div className="navigation-buttons">
						<div className="menuLinkButton">
							<div className="nav publish pulse">
								<div className={"inner-rocket"} />
							</div>
							<p>View!</p>
						</div>
					</div>
				</a>
			);
		} else {
			let rocketCss = this.state.launch ? "launch " : "";
			let text = this.state.launch ? "" : "Publish";
			return (
				<div className="navigation-buttons" onClick={() => this.handleStartPublish(this.state.website.id)}>
					<div className="menuLinkButton">
						<div className={rocketCss + "nav publish"}>
							<div className={rocketCss + "inner-rocket"} />
						</div>
						<p>{text}</p>
					</div>
				</div>
			);
		}
	}
}

export default Container.create(PublishButton, {withProps: true});
