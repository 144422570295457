'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module ThemesServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var ThemesServerActionsCreator = {
	/**
	 * @method receiveThemes
	 */
	receiveThemes: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_THEMES,
			themes: response.themes
		});
	},
	/**
	 * @method receiveThemesFails
	 */
	receiveThemesFails: function(){
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_THEMES_FAILS,
			message: "could not get templates"
		});
	},
	/**
	 * @method receiveTheme
	 */
	receiveTheme: function(themeId, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_THEME,
			themeId: themeId,
			theme: response
		});
	},
	/**
	 * @method receiveThemeFails
	 */
	receiveThemeFails: function(themeId){
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_THEME_FAIL,
			themeId: themeId,
			message: "could not get template"
		});
	},
	/**
	 * @method receiveThemePages
	 */
	receiveThemePages: function(themeId, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_THEME_PAGES,
			themeId: themeId,
			//imitate multipage theme
			pages: [response.data]
		});
	},
	/**
	 * @method receiveThemePagesFails
	 */
	receiveThemePagesFails: function(themeId) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_THEME_PAGES_FAILS,
			themeId: themeId,
			message: "could not get template"
		});
	}
};

module.exports = ThemesServerActionsCreator;
