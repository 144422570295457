'use strict';

import { includes, map } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var Description = require('../content-items/Description.react');

/** @module Preview
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires Description
 */
var Preview = ComponentFactory.Create({
	displayName: "Preview",
	propTypes: {
		content: React.PropTypes.array,
		contentItem: React.PropTypes.object,
		picked: React.PropTypes.array,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	/**
	 * @method _isPicked
	 */
	_isPicked: function(contentItem) {
		return includes(this.props.picked, contentItem.id);
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="row markdown-section">
				<div className="columns small-12">
					{map(this.props.content, (contentItem) => {
						return (
							<Description
								key={contentItem.id}
								contentItem={contentItem}
								picked={this._isPicked(contentItem)}
								onPick={this.props.onPick}
								onDelete={this.props.onDelete}
							/>
						);
					})}
				</div>
			</div>
		);
	}
});

module.exports = Preview;
