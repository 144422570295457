'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var WebAPI = require('../utils/WebAPI');

/** @module YoutubeActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires WebAPI
 */
var YoutubeActionsCreator = {
	/**
	 * @method requestYoutubeAuthorizationUrl
	 */
	requestYoutubeAuthorizationUrl: function(returnToUrl) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REQUEST_YOUTUBE_AUTHORIZATION_URL,
			returnTo: returnToUrl
		},
		WebAPI.getYoutubeAuthorizationUrl, [returnToUrl]);
	}
};

module.exports = YoutubeActionsCreator;
