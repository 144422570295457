'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module UserStore
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires lodash
 */
var SiteTailorStore = StoreFactory.Create({

	/**
	 * @method getLoadStatus
	 */
	getLoadStatus: function() {
		return this.getState();
	},

	/**
	 * @method setLoadStatus
	 */
	setLoadStatus: function (action) {
		this._set('load', action.load);
	},
	/**
	 * @method setStepNumber
	 */
	setStepName: function (action) {
		this._set('stepName', action.stepName);
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function () {
		var handlers = {};

		handlers[RemixerConstants.SET_SITETAILOR_LOAD_STATUS] = this.setLoadStatus;
		handlers[RemixerConstants.GET_SITETAILOR_LOAD_STATUS] = this.getLoadStatus;
		handlers[RemixerConstants.SET_SITETAILOR_STEP_NAME] = this.setStepName;

		return handlers;
	}
});

module.exports = SiteTailorStore;
