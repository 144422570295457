'use strict';

import { map, find, reduce } from 'lodash';
import WebsitesStore from '../../../stores/WebsitesStore';
var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var PageProjectStore = require('../../../stores/PageProjectStore').default;

var PageLink = ComponentFactory.Create( {
	displayName: "PageLink",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		handleChange: React.PropTypes.func
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		var websites = WebsitesStore.getState().toArray();
		var pages = PageProjectStore.getState().toArray();
		var currentPage = find(pages, (page) => {
			return(page.pageId === this.props.pageId)
		})
		pages = reduce(pages, (result, page) => {
			let website = find(websites, (site) => {
				return(site.id === page.websiteId)
			})
			if(website && website.id === currentPage.websiteId) {
				website = {name: 'Current Site', publicUrl: '/'}
			}
			if(!website || !website.publicUrl){return(result)}
			result.push({
				id: page.id,
				title: page.title,
				slug: page.slug,
				websiteName: website.name,
				websiteUrl: website.publicUrl.replace(/\/?$/, '/')
			})
			return(result)
		}, [])
		return {
			curr_name: this.props.currentLinkURL || "",
			pages: pages
		};
	},

	_handleInputChange: function(component) {
		var value = component.target.value;
		this.setState({curr_name: value})
		this.props.handleChange(value);
	},


	/**
	 * @method render
	 */
	render: function() {
		var options = map(this.state.pages, function(page) {
			return (<option value={page.websiteUrl+page.slug}>{page.websiteName + '-' + page.title}</option>);
		});
		return (
			<div className="page-input">
				<p className="hint">Select a page for this image to link to</p>
				<select onChange={this._handleInputChange} value={this.state.curr_name} >
					{options}
				</select>
			</div>
		)
	}
});

module.exports = PageLink;
