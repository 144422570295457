'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var Dropzone = require('react-dropzone');
var ImportTypes = require('../../constants/ImportTypes');
var ImportActionsCreator = require('../../actions/ImportActionsCreator');

var ImportStore = require('../../stores/ImportStore');
var ContentStore = require('../../stores/ContentStore');
var ContentTypes = require('../../constants/ContentTypes');

function diasableDropEffect(e) {
	e.dataTransfer.effectAllowed = 'none';
	e.dataTransfer.dropEffect = 'none';
}

/** @module FileImport
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires react-dropzone
 * @requires ImportTypes
 * @requires ImportActionsCreator
 * @requires ImportStore
 * @requires ContentStore
 * @requires ContentTypes
 */
var FileImport = ComponentFactory.Create(ImportStore, ContentStore, {
	displayName: "FileImport",
	/**
	 * @method getState
	 */
	getState: function () {
		var importMetaState = ImportStore.getMetaState(ImportTypes.FILE);

		return {
			loading: importMetaState.filling,
			importedFileCount: _(ContentStore.getState())
				.values()
				.flatten()
				.filter((x) => _.isEqual(x.import_type, ImportTypes.FILE))
				.filter((x) => _.isEqual(x.content_type, ContentTypes.IMAGE))
				.size()
		};
	},

	/**
	 * @method _handleDraggingStart
	 */
	_handleDraggingStart: function () {
		this._handleDraggingEndDebounced.cancel();
		this.setState({dragging: true});
	},

	/**
	 * @method _handleDraggingEnd
	 */
	_handleDraggingEnd: function () {
		this.setState({dragging: false});
	},

	/**
	 * @method componentWillMount
	 */
	componentWillMount: function () {
		//hack: delays _handleDraggingEnd as it might occurs very frequently and prevents blinking
		this._handleDraggingEndDebounced = _.debounce(this._handleDraggingEnd, 200);
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function () {
		window.addEventListener('dragenter', this.onWindowDragEnter);
		window.addEventListener('dragover', this.onWindowDragOver);
		window.addEventListener('dragleave', this.onWindowDragLeave);
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function () {
		window.removeEventListener('dragenter', this.onWindowDragEnter);
		window.removeEventListener('dragover', this.onWindowDragOver);
		window.removeEventListener('dragleave', this.onWindowDragLeave);
	},

	/**
	 * @method onDrop
	 */
	onDrop: function (files) {
		if (!this.state.loading) {
			if (files.length) {
				ImportActionsCreator.startImport(ImportTypes.FILE, files);
			}
			this._handleDraggingEndDebounced();
		}
	},

	/**
	 * @method onWindowDragLeave
	 */
	onWindowDragLeave: function (e) {
		e.preventDefault();
		this._handleDraggingEndDebounced();
	},

	/**
	 * @method onWindowDragOver
	 */
	onWindowDragOver: function (e) {
		e.preventDefault();
		this._handleDraggingStart();
	},

	/**
	 * @method onWindowDragEnter
	 */
	onWindowDragEnter: function (e) {
		e.preventDefault();
		this._handleDraggingStart();
	},

	/**
	 * @method onDropzoneDragOver
	 */
	onDropzoneDragOver: function (e) {
		e.preventDefault();
		if (this.state.loading) {
			diasableDropEffect(e);
		}

		this._handleDraggingStart();
	},

	/**
	 * @method onDropzoneDragEnter
	 */
	onDropzoneDragEnter: function (e) {
		e.preventDefault();
		if (this.state.loading) {
			diasableDropEffect(e);
		} else {
			e.dataTransfer.dropEffect = 'copy';
		}
		this._handleDraggingStart();
	},

	/**
	 * @method render
	 */
	render: function () {
		var loading = this.state.loading;
		var incoming = this.state.dragging && !loading;
		var fileuploadClasses = {
			"file-upload-zone": true,
			"incoming": incoming
		};

		var css = [
			'import-header-column',
			'import-header-column-button',
			'bg-overlay',
			'computer'];

		var iconCss = ['import-icon-img computer',
			{ 'pulse': loading }];

		return (
			<div className={this.css(css)} >

				<div className={this.css(fileuploadClasses)}>
					<Dropzone className="dropzone" supportClick={!loading} accept="image/*"
						onDrop={this.onDrop}
						onDragEnter={this.onDropzoneDragOver}
						onDragOver={this.onDropzoneDragEnter}>
						<div className="show-for-large-up import-computer-label" >
							<p >{this.props.title}</p>
							<h3 >{this.props.tagline}</h3>
						</div>

						<div className="import-icon-holder" >
							<div className={this.css(iconCss)}>
							</div>
							<div className="import-icon-bubble-holder" >
								<div className="bubbleNumber active" ><div className="bubbleLeft dh"></div><div className="bubbleRight dh"></div><div className="bubbleText dh"><p>{this.state.importedFileCount}</p></div></div>
							</div>
						</div>
					</Dropzone>
				</div>

			</div>
		);
	}
});

module.exports = FileImport;
