'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module PersonServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var PersonServerActionsCreator = {
	/**
	 * @method getPerson
	 */
	getPerson: function(data) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_PERSON,
			result: data
		});
	},
	/**
	 * @method getPersonFails
	 */
	getPersonFails: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_PERSON_FAILS,
			message: 'Could not get person.'
		});
	},
	/**
	 * @method getPersons
	 */
	getPersons: function(data) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_PERSONS,
			result: data
		});
	},
	/**
	 * @method getPersonsFails
	 */
	getPersonsFails: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_PERSONS_FAILS,
			message: 'Could not get persons.'
		});
	},
	/**
	 * @method addPerson
	 */
	addPerson: function(data) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_ADD_PERSON,
			data: data
		});
	},
	/**
	 * @method addPersonFails
	 */
	addPersonFails: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_ADD_PERSON_FAILS,
			message: 'Could not add person.'
		});
	},
	/**
	 * @method updatePerson
	 */
	updatePerson: function(data) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_PERSON,
			data: data
		});
	},
	/**
	 * @method updatePersonFails
	 */
	updatePersonFails: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_PERSON_FAILS,
			message: 'Could not update person.'
		});
	},
	/**
	 * @method deletePerson
	 */
	deletePerson: function(data) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_DELETE_PERSON,
			data: data
		});
	},
	/**
	 * @method deletePersonFails
	 */
	deletePersonFails: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_DELETE_PERSON_FAILS,
			message: 'Could not update person.'
		});
	}
};

module.exports = PersonServerActionsCreator;
