'use strict';

var _ = require('lodash');
var ContentTypes = require('../constants/ContentTypes');
var ImportTypes = require('../constants/ImportTypes');
var ContentServerActionsCreator = require('../actions/ContentServerActionsCreator');
var ImportServerActionsCreator = require('../actions/ImportServerActionsCreator');
var PageServerActionsCreator = require('../actions/PageServerActionsCreator');
var PublishServerActionsCreator = require('../actions/PublishServerActionsCreator');
var ExportWordPressServerActionsCreator = require('../actions/ExportWordPressServerActionsCreator');
var PageProjectServerActionsCreator = require('../actions/PageProjectServerActionsCreator');
/** @module WebSocketHandler
 * @requires lodash
 * @requires ContentTypes
 * @requires ImportTypes
 * @requires ContentServerActionsCreator
 * @requires ImportServerActionsCreator
 * @requires PageServerActionsCreator
 * @requires PublishServerActionsCreator
 */
function WebSocketHandler() {
	var updatedContentQueue = [];

	var receiveContentUpdate  = function() {
		_(updatedContentQueue).groupBy('content_type').forEach(function(contents, content_type) {
			ContentServerActionsCreator.receiveContentUpdate(content_type, _.flatten(contents));
		});
		updatedContentQueue = [];
	};

	var receiveContentUpdateDebounced  = _.debounce(receiveContentUpdate, 200, { 'maxWait': 1000, leading: false,  trailing: true } );

	this.process = function (message) {
		if (!message.data) return;

		var data = JSON.parse(message.data);

		if (data.type === 'page_screenshot' && data.status === 'success') {
			PageServerActionsCreator.receiveUpdatePageProperties(data.data.page_id, {'page_thumbnail_url': data.data.img_url});
			PageProjectServerActionsCreator.receiveUpdatePageProjectProps({id: data.data.page_project_id, thumbnail: data.data.img_url});
			return;
		}

		var transformedData = ImportTypes.transformToEnum(ContentTypes.transformToEnum(data, 'content_type'), 'import_type');

		switch (data.type) {
			case 'MetadataImported':
			case 'ContentItemImported':
				updatedContentQueue.push(transformedData.data.content);
				receiveContentUpdateDebounced();

				break;
			case 'ImportFailedAuthExpired':
				ImportServerActionsCreator.receiveImportAuthExpired(transformedData.data.import_type);
				break;
			case 'ImportFailed':
				ImportServerActionsCreator.receiveImportFails(transformedData.data.import_type, null, transformedData);
				break;
			case 'ImportMetadataFinished':
				ImportServerActionsCreator.receiveImportFinished(transformedData.data.import_type, null, transformedData);
				break;
			case 'Publish':
				PublishServerActionsCreator.receivePublished(data);
				break;
			case 'PublishFailed':
				PublishServerActionsCreator.receivePublishFailed(data);
				break;
			case 'WPExport':
				ExportWordPressServerActionsCreator.receiveExportWordPress(data);
				break;
			case 'WPExportFailed':
				ExportWordPressServerActionsCreator.receiveExportWordPressFailed(data);
				break;
			default:
				console.error('Unknown message type : ' + data.type);
		}

	};
}

module.exports = WebSocketHandler;
