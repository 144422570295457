'use strict';

var Dispatcher = require('flux').Dispatcher;

import { defaults, isArray, debounce, defer } from 'lodash'

/** @module AppDispatcher
 * @requires lodash
 * @requires flux
 */
var flux = new Dispatcher();

flux._dispatch = flux.dispatch;
var dispatches = {};

flux.dispatch = function(action, request, parameters, options) {
	if (request) {
		options = defaults(options || {}, { wait: 50, trailing: false, params: true });

		if(!isArray(parameters)) parameters = [];

		var debounceData = options.trailing ?
			{ leading: false, trailing: true } :
			{ leading: true, trailing: false };

		var key = action.actionType + (options.params && JSON.stringify(parameters));
		if(!dispatches[key]) {
			dispatches[key] = options.wait ? debounce(request, options.wait, debounceData) : request;
		}

		if(options.trailing) flux._dispatch.call(flux, action);
		defer(function() {
			if(!options.trailing) flux._dispatch.call(flux, action);
			dispatches[key].apply(null, parameters);
		});
	} else {
		flux._dispatch.call(flux, action);
	}
};

module.exports = flux;
