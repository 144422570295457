'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var WebAPI = require('../utils/WebAPI');

import {WebsitesLoadingStatusStore} from '../stores/WebsitesStore';

/** @module WebsiteStoreActionsCreator
	 * @requires AppDispatcher
	 * @requires RemixerConstants
	 * @requires WebAPI
	 * @requires WebsiteStore
	 */
var WebsiteActionsCreator = {
	/**
	 * @method createWebsite
	 */
	createWebsite: function(basePageId, name, redirect = 'editor') {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.CREATE_WEBSITE,
			basePageId,
			name
		},
		WebAPI.createWebsite, [basePageId, name, redirect]);
	},
	/**
	 * @method createWebsiteWithRedirect
	 */
	createWebsiteWithRedirect: function(basePageId, name, redirect = 'editor') {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.CREATE_WEBSITE,
			basePageId,
			name
		},
		WebAPI.createWebsiteWithRedirect, [basePageId, name, redirect]);
	},
	/**
	 * @method loadWebsites
	 */
	loadWebsites: function(forceLoad) {
		if (!forceLoad && !WebsitesLoadingStatusStore.getState().needs()) return;

		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOAD_ALL_WEBSITES
		}, WebAPI.loadAllWebsites);
	},

	updateWebsite: function(website) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.UPDATE_WEBSITE,
			website
		}, WebAPI.updateWebsite, [website]);
	},

	deleteWebsite: function(website) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.DELETE_WEBSITE,
			website
		}, WebAPI.deleteWebsite, [website]);
	}
};

module.exports = WebsiteActionsCreator;
