'use strict';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');
var RouteStore = require('../../stores/RouteStore');
var Header = require('./partials/Header.react');
var FlashMessenger = require('./partials/FlashMessenger.react');
var UrlUtils = require('../../utils/UrlUtils');
import TrialBanner from './partials/TrialBanner.react.js';
import TrialPublishOverlay from '../common/TrialPublishOverlay.react.js';

/** @module SimpleLayout
 * @requires react
 * @requires ComponentFactory
 * @requires Header.react
 * @requires FlashMessenger.react
 */
var SimpleLayout = ComponentFactory.Create({
	displayName: "SimpleLayout",

	componentWillUpdate: function(nextProps, nextState) {
		if (nextProps.trial.disabled) {
			UrlUtils.navigateTo("https://panel.dreamhost.com/");
		}
		RouteStore.getState('route').checkOnPermission(nextProps.user)
	},

	/**
	 * @method render
	 */
	render: function() {
		if(this.props.trial.disabled) return null
		return (
			<div className="remixer-container">
				<Header user={this.props.user} trialStatus={this.props.trial} />
				<FlashMessenger floating={true} />
				<div className={this.css("content", this.props.trial.inTrial ? "trial-padding" : '' )}>
					{this.props.children}
					{ this.props.trial.inTrial ? <div><TrialBanner daysRemaining={this.props.trial.daysRemaining}/><TrialPublishOverlay /></div> : null }
				</div>
			</div>
		);
	}
});

module.exports = SimpleLayout;
