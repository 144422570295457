'use strict';

var _ = require('lodash');

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module UserPreferenceStore
 * @requires lodash
 * @requires StoreFactory
 * @requires RemixerConstants
 */
var UserPreferenceStore = StoreFactory.Create({

	/**
	 * @method getState
	 */
	getState: function(path) {
		return _.get(JSON.parse(localStorage.getItem('preference')), path);
	},

	/**
	 * @method _setPreference
	 */
	_setPreference: function(action) {
		var preference = JSON.parse(localStorage.getItem('preference')) || {};
		preference[action.name] = action.value;

		localStorage.setItem('preference', JSON.stringify(preference));
	},

	/**
	 * @method _unsetPreference
	 */
	_unsetPreference: function(action) {
		var preference = JSON.parse(localStorage.getItem('preference')) || {};
		delete preference[action.name];

		localStorage.setItem('preference', JSON.stringify(preference));
	},

	/**
	 * @method _forgetAllPreference
	 */
	_forgetAllPreference: function() {
		localStorage.removeItem('preference');
	},

	/**
	 * @method _beginWebsitePublish
	 */
	_beginWebsitePublish: function() {
		localStorage.setItem('preference', JSON.stringify({'publishing': true}));
	},

	/**
	 * @method _changeTextColor
	 */
	_changeTextColor: function(action) {
		var preference = JSON.parse(localStorage.getItem('preference')) || {};
		var colors = _(_.get(preference, 'text_colors', []))
			.without(action.value)
			.unshift(action.value)
			.take(10)
			.value();
		_.set(preference, 'text_colors', colors);
		localStorage.setItem('preference', JSON.stringify(preference));
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function () {
		var handlers = {};

		handlers[RemixerConstants.SET_PREFERENCE] = this._setPreference;
		handlers[RemixerConstants.UNSET_PREFERENCE] = this._unsetPreference;
		handlers[RemixerConstants.FORGET_ALL_PREFERENCE] = this._forgetAllPreference;
		handlers[RemixerConstants.BEGIN_WEBSITE_PUBLISH] = this._beginWebsitePublish;
		handlers[RemixerConstants.CHANGE_TEXT_COLOR] = this._changeTextColor;

		return handlers;
	}
});

module.exports = UserPreferenceStore;
