import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

import WebAPI from '../utils/WebAPI';

export default {
	search(query, page=null) {
		WebAPI.searchYoutube(query, page);
		AppDispatcher.dispatch({
			actionType: RemixerConstants.SEARCH_YOUTUBE,
			query,
			page
		});
	}
};
