'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var UrlUtils = require('../utils/UrlUtils');

/** @module SoundcloudServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires UrlUtils
 */
var SoundcloudServerActionsCreator = {
	/**
	 * @method receiveSoundcloudAuthorizationUrl
	 */
	receiveSoundcloudAuthorizationUrl: function(result) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SOUNDCLOUD_AUTHORIZATION_URL,
			result: result.data
		});

		UrlUtils.navigateTo(result.data.authorization_url);
	},
	/**
	 * @method receiveSoundcloudAuthorizationUrlFails
	 */
	receiveSoundcloudAuthorizationUrlFails: function(error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SOUNDCLOUD_AUTHORIZATION_URL_FAILS,
			message: 'could not get Soundcloud authorization url'
		});
	}
};

module.exports = SoundcloudServerActionsCreator;
