'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var Images = require('./content-containers/Images.react');
var Videos = require('./content-containers/Videos.react');
var Audios = require('./content-containers/Audios.react');
var MarkdownTexts = require('./content-containers/MarkdownTexts.react');
var Groups = require('./content-containers/Groups.react');

var ContentStore = require('../../stores/ContentStore');

var ContentActionsCreator = require('../../actions/ContentActionsCreator');

var ContentTypes = require('../../constants/ContentTypes');
var ContentManagementTabs = require('../../constants/ContentManagementTabs');

var strategies = {};
strategies[ContentManagementTabs.IMAGES] = Images;
strategies[ContentManagementTabs.VIDEOS] = Videos;
strategies[ContentManagementTabs.AUDIOS] = Audios;
strategies[ContentManagementTabs.MARKDOWN_TEXTS] = MarkdownTexts;
strategies[ContentManagementTabs.GROUPS] = Groups;

/** @module ContentContainer
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires Images
 * @requires Videos
 * @requires Audios
 * @requires MarkdownTexts
 * @requires Groups
 * @requires ContentStore
 * @requires ContentActionsCreator
 * @requires ContentTypes
 * @requires ContentManagementTabs
 */
var ContentContainer = ComponentFactory.Create(ContentStore, {
	displayName: "ContentContainer",
	propTypes: {
		strategy: React.PropTypes.oneOf(_.values(ContentManagementTabs)).isRequired,
		contentId: React.PropTypes.number,
		action: React.PropTypes.string
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var content = this._getContent(this.props.strategy);
		return {
			content: content,
			contentItem: this._getContentItem(content, this.props.contentId),
			action: this.props.action,
			loading: this._getContentLoadingStatus(this.props.strategy)
		};
	},

	/**
	 * @method componentWillReceiveProps
	 */
	componentWillReceiveProps: function(nextProps) {
		var content = this.state.content;
		if (nextProps.strategy !== this.props.strategy) {
			content = this._getContent(nextProps.strategy);
			this.setState({
				content: content
			});
		}
		if (nextProps.contentId !== this.props.contentId) {
			this.setState({
				contentItem: this._getContentItem(content, nextProps.contentId)
			});
		}
		if (nextProps.action !== this.props.action) {
			this.setState({
				action: nextProps.action
			});
		}
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function() {
		_.forEach(this._getContentTypes(this.props.strategy), function(contentType) {
			ContentActionsCreator.loadContent(contentType);
		});
		ContentActionsCreator.loadContent(ContentTypes.TAG);
	},

	/**
	 * @method _getContentTypes
	 */
	_getContentTypes: function(strategy) {
		// TODO: CONTENT_TYPES_TO_CONTENT_MANAGEMENT_TABS
		// remove spagetti code
		var contentTypes = [];
		switch(strategy) {
			case ContentManagementTabs.IMAGES:
				contentTypes.push(ContentTypes.IMAGE);
				break;
			case ContentManagementTabs.VIDEOS:
				contentTypes.push(ContentTypes.YOUTUBE);
				contentTypes.push(ContentTypes.VIMEO);
				break;
			case ContentManagementTabs.AUDIOS:
				contentTypes.push(ContentTypes.SOUNDCLOUD);
				break;
			case ContentManagementTabs.MARKDOWN_TEXTS:
				contentTypes.push(ContentTypes.MARKDOWN);
				break;
			case ContentManagementTabs.GROUPS:
				contentTypes.push(ContentTypes.IMAGE);
				contentTypes.push(ContentTypes.YOUTUBE);
				contentTypes.push(ContentTypes.VIMEO);
				contentTypes.push(ContentTypes.SOUNDCLOUD);
				contentTypes.push(ContentTypes.MARKDOWN);
				break;
		}
		return contentTypes;
	},

	/**
	 * @method _getContent
	 */
	_getContent: function(strategy) {
		return _(strategy)
			.thru(this._getContentTypes)
			.map(function(contentType) {
				return ContentStore.getState(contentType);
			})
			.compact()
			.flatten()
			.orderBy("id", false).value();
	},

	_getContentLoadingStatus: function(strategy) {
		return _(strategy)
			.thru(this._getContentTypes)
			.map(function(contentType) {
				return ContentStore.getMetaState(contentType).filling;
			})
			.some(Boolean);
	},

	/**
	 * @method _getContentItem
	 */
	_getContentItem: function(content, id) {
		return id && content && _.find(content, (c) => c.id === id);
	},

	/**
	 * @method _onPick
	 */
	_onPick: function(contentItem) {
		this.route().transitionTo('content', { tab: this.props.strategy, contentId: contentItem && contentItem.id });
	},

	_onUpdate: _.noop, // TODO: not implemented yet

	/**
	 * @method _onDelete
	 */
	_onDelete: function(contentItem) {
		var contentType = _.get(contentItem, 'content_type');

		ContentActionsCreator.deleteContent(contentItem.id, contentType);
	},

	/**
	 * @method render
	 */
	render: function() {
		var TypedContentContainer = _.get(strategies, this.props.strategy);
		return (
			<TypedContentContainer
				content={this.state.content}
				contentItem={this.state.contentItem}
				onPick={this._onPick}
				onUpdate={this._onUpdate}
				onDelete={this._onDelete}
				action={this.state.action}
				loading={this.state.loading}
			/>
		);
	}
});

module.exports = ContentContainer;
