'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

var RouteStore = require('../stores/RouteStore');

var WebsiteServerActionsCreator = {
	/**
	 * @method receiveCreatePageProject
	 */
	receiveCreateWebsite: function(basePageId, name, redirect, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CREATE_WEBSITE,
			basePageId,
			name,
			website: response.data
		});
		if(redirect === 'editor') {
			RouteStore.getState('route')
				.transitionTo('layout', { websiteId: response.data.id });
		}
	},
	/**
	 * @method receiveCreateWebsiteFail
	 */
	receiveCreateWebsiteFail: function(basePageId, name) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CREATE_WEBSITE_FAIL,
			basePageId,
			name,
			message: 'We could not create project'
		});
	},
	/**
	 * @method receiveAllWebsites
	 */
	receiveAllWebsites: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_ALL_WEBSITES,
			websites: response.data
		});
	},
	/**
	 * @method receiveAllWebsitesFail
	 */
	receiveAllWebsitesFail: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_ALL_WEBSITES_FAIL,
			message: 'We could not get websites'
		});
	},

	receiveUpdateWebsite: function(website, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_WEBSITE,
			website: response.data
		});
	},

	receiveUpdateWebsiteFail: function(website) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_WEBSITE_FAIL,
			website: website
		});
	},

	receiveDeleteWebsite: function(website, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_DELETE_WEBSITE,
			website: website
		});
	},


	receiveDeleteWebsiteFail: function(website, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_DELETE_WEBSITE_FAIL,
			website: website
		});
	}
};

module.exports = WebsiteServerActionsCreator;
