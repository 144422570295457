'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var DropDown = require('../DropDown.react');

var PageStore = require('../../../stores/PageStore');
var ComponentMetadataStore = require('../../../stores/ComponentMetadataStore');

var ComponentActionsCreator = require('../../../actions/ComponentActionsCreator');

var ComponentTypes = require('../../../constants/ComponentTypes');

/** @module ChordAnchorTools
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires DropDown
 * @requires PageStore
 * @requires ComponentMetadataStore
 * @requires ComponentActionsCreator
 * @requires ComponentTypes
 */
var ChordAnchorTools = ComponentFactory.Create(PageStore, ComponentMetadataStore, {
	displayName: "ChordAnchorTools",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		onChange: React.PropTypes.func,
		pulldownOnly: React.PropTypes.bool,
		href: React.PropTypes.string
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var components = PageStore.getState(this.props.pageId, 'components');

		return {
			chords: _(components)
				.map(this._joinMetadata)
				.filter(x => _.includes(
					[
						ComponentTypes.content,
						ComponentTypes.hidden
					], x.metadata.componentType))
				.map('instance')
				.value()
		};
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		ComponentActionsCreator.loadPage(this.props.pageId);
		ComponentActionsCreator.loadComponentsMetadata();

		if (_.isEqual(this.props.href, this.state.href)) return;

		var isPropsChange = !_.isEqual(props, this.props);
		if (isPropsChange) {
			this.setState({ href: this.props.href });
		}
	},

	/**
	 * @method _joinMetadata
	 */
	_joinMetadata: function(instance) {
		return {
			instance: instance,
			metadata: ComponentMetadataStore.getComponentMetadata(instance.reference)
		};
	},

	/**
	 * @method _onChangeAnchor
	 */
	_onChangeAnchor: function(element) {
		this.setState({ chordAnchor: element.key && '#' + element.key });
	},

	/**
	 * @method _saveAnchor
	 */
	_saveAnchor: function(ev) {
		ev.preventDefault();

		if(this.props.onChange) this.props.onChange(this.state.chordAnchor);
		this.setState({ href: this.state.chordAnchor });
	},

	/**
	 * @method _changeSaveAnchor
	 */
	_changeSaveAnchor: function(ev) {
		if(this.props.onChange) this.props.onChange(ev);
		this.setState({ chordAnchor: ev.key && '#' + ev.key, href: '#' + ev.key });
	},

	/**
	 * @method _createElements
	 */
	_createElements: function() {
		var options = _.map(this.state.chords, function(chord) {
			return (<span key={chord.id} className='value' unselectable='on'>{chord.reference.name}</span>);
		});
		options.unshift(<span key='---' className='value' unselectable='on'>{'---'}</span>);
		return options;
	},

	/**
	 * @method _createOptions
	 */
	_createOptions: function() {
		var options = this._createElements();
		var selectedIdx = _.findIndex(options, (el) => ('#' + el.key) === this.state.href);
		return {
			options: options,
			selected: options[selectedIdx]
		};
	},

	/**
	 * @method render
	 */
	render: function () {
		var chords = this._createOptions();

		if (this.props.pulldownOnly) {
			return (<DropDown onChange={this._changeSaveAnchor} active={chords.selected} key={this.props.pageId}>
				{chords.options}
			</DropDown>
			);
		} else {

			return (<div className="tabs-content-link">
				<p className="hint">Select a component for this to scroll/position to</p>
				<div className="url-input">
					<DropDown onChange={this._onChangeAnchor} active={chords.selected}>
						{chords.options}
					</DropDown>
					<span onClick={this._saveAnchor} className="save">Save</span>
				</div>
			</div>);
		}
	}
});

module.exports = ChordAnchorTools;
