'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var MediaServicesUtils = require('../../../utils/MediaServicesUtils');

/** @module OverlayYoutube
 * @requires react
 * @requires ComponentFactory
 * @requires MediaServicesUtils
 */
var OverlayYoutube = ComponentFactory.Create({
	displayName: "OverlayYoutube",
	propTypes: {
		content: React.PropTypes.array,
		contentItem: React.PropTypes.object.isRequired,
		picked: React.PropTypes.array,
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<iframe
				className="content-item video-content-item"
				src={MediaServicesUtils.getYoutubeEmbeddedUrlById(this.props.contentItem.video_id)}
				frameBorder="0"
				webkitallowFullScreen mozallowFullScreen allowFullScreen>
			</iframe>
		);
	}
});

module.exports = OverlayYoutube;
