'use strict';

var _ = require('lodash');

var React = require('react');
var ReactCSSTransitionGroup = require('react-addons-css-transition-group');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var Overlay = require('../../common/Overlay.react');

//TODO: move update logic outside Slider
var ContentActionsCreator = require('../../../actions/ContentActionsCreator');
var FlashMessageActionsCreator = require('../../../actions/FlashMessageActionsCreator');

var ContentStore = require('../../../stores/ContentStore');
var ContentTypes = require('../../../constants/ContentTypes');

function wrap(Component) {
	/** @module SliderWrapper
	 * @requires lodash
	 * @requires react
	 * @requires react-addons-css-transition-group
	 * @requires ComponentFactory
	 * @requires Overlay
	 * @requires ContentActionsCreator
	 * @requires FlashMessageActionsCreator
	 * @requires ContentStore
	 * @requires ContentTypes
	 */
	var SliderWrapper = ComponentFactory.Create(ContentStore, {
		displayName: "SliderWrapper",
		propTypes: {
			content: React.PropTypes.array,
			contentItem: React.PropTypes.object,
			picked: React.PropTypes.array,
			onPick: React.PropTypes.func,
			onDelete: React.PropTypes.func,
			onUpdate: React.PropTypes.func,
			noArrow: React.PropTypes.bool,
			noDelete: React.PropTypes.bool,
			noTags: React.PropTypes.bool,
			action: React.PropTypes.string
		},

		/**
		 * @method getState
		 */
		getState: function() {
			return {
				tags: ContentStore.getState(ContentTypes.TAG)
			};
		},

		/**
		 * @method _onPick
		*/
		_onPick: function(contentItem) {
			this.props.onPick(contentItem);
		},

		/**
		 * @method _onClose
		*/
		_onClose: function() {
			this._onPick(null);
		},

		/**
		 * @method _findWithOffset
		 */
		_findWithOffset: function(offset) {
			var i = _.findIndex(this.props.content, (item) => item.id === this.props.contentItem.id) + offset;
			return _.first(_.at(this.props.content, i));
		},

		/**
		 * @method _slideWithOffset
		 */
		_slideWithOffset: function(offset) {
			this._onPick(this._findWithOffset(offset));
		},

		/**
		 * @method _onPrevious
		 */
		_onPrevious: function() {
			this._slideWithOffset(-1);
		},

		/**
		 * @method _onNext
		 */
		_onNext: function() {
			this._slideWithOffset(1);
		},

		/**
		 * @method _onPreviousCallback
		 */
		_onPreviousCallback: function() {
			if (this._findWithOffset(-1)) {
				return this._onPrevious;
			}
		},

		/**
		 * @method _onNextCallback
		 */
		_onNextCallback: function() {
			if (this._findWithOffset(1)) {
				return this._onNext;
			}
		},

		/**
		 * @method _onDelete
		 */
		_onDelete: function() {
			if (!this.props.noArrow) {
				this._onNext();
			}
			else { this._onClose(); }

			if (this.props.contentItem.id) this.props.onDelete(this.props.contentItem);
		},

		/**
		 * @method _onChangeTags
		 */
		_onChangeTags: function(tags) {
			//TODO: move update logic outside Slider
			//		this.props.contentItem["content_type"]
			var contentType = _.get(this.props.contentItem, 'content_type');
			if (_.get(this.props.contentItem, 'id')) {
				ContentActionsCreator.updateContentTags(this.props.contentItem.id, contentType, tags);
			}
			else {
				FlashMessageActionsCreator.addFlashMessage({ text: 'Please save before adding a tag.', level: 'error' });
			}
		},

		/**
		 * @method _getTags
		 */
		_getTags: function() {
			return _(this.props.contentItem.relative_content_items)
				.map('relative_content_id')
				.map((val) => {
					return _.find(this.state.tags, {id: val});
				})
				.map('name')
				.value();
		},

		/**
		 * @method render
		 */
		render: function() {
			return (
				<ReactCSSTransitionGroup transitionName="remixer-overlay"
					transitionEnterTimeout={500} transitionLeaveTimeout={500} >
					{(_.get(this.props.contentItem, 'id') || (this.props.action && _.get(this.props.contentItem, 'content_type'))) ?
						<Overlay
							key="overlay"
							onClose={this._onClose}
							onPrevious={this.props.noArrow ? null : this._onPreviousCallback()}
							onNext={this.props.noArrow ? null : this._onNextCallback()}
							onDelete={this.props.noDelete ? null : this._onDelete}
							onChangeTags={this.props.noTags ? null : this._onChangeTags}
							tags={this._getTags()}
						>
							<Component {...this.props} />
						</Overlay>
						:
						null
					}
				</ReactCSSTransitionGroup>
			);
		}
	});
	return SliderWrapper;
}

module.exports = wrap;
