import Immutable from 'immutable';

var Mapper = require('../../utils/Mapper');

const Record = Immutable.Record({
	domain: "",
	errorCode: 0,
	isEligible: false
});

class AvailableDomainRecord extends Record{
	static fromDTO(json) {
		return new AvailableDomainRecord(Mapper.toCamelCase(json));
	}
	toDTO() {
		return Mapper.toSnakeCase(this.toJS());
	}
}

export default AvailableDomainRecord;
