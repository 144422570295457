'use strict';

import { defer, bind, isEqual, get, isEmpty } from 'lodash';
var $ = require('jquery');

var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');

var ComponentPreview = require('../models/ComponentPreview');

var ComponentMetadataStore = require('../stores/ComponentMetadataStore');

var PageRenderer = require('../rendering/PageRenderer');
var IframeTools = require('./common/iframetools/IframeTools');

/** @module ComponentPreviewReact
 * @requires lodash
 * @requires jquery
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires ComponentActionsCreator
 * @requires ComponentPreview
 * @requires ComponentMetadataStore
 * @requires PageRenderer
 * @requires IframeTools
 */
var ComponentPreviewReact = ComponentFactory.Create(ComponentMetadataStore, {
	displayName: "ComponentPreviewReact",
	propTypes: {
		preview: React.PropTypes.instanceOf(ComponentPreview).isRequired,
		onClick: React.PropTypes.func
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			metadata: ComponentMetadataStore.getComponents()
		};
	},

	/**
	 * @method _equal
	 */
	_equal: function(first, second, path) {
		return isEqual(get(first, path), get(second, path));
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		ComponentActionsCreator.loadComponentsMetadata();
		//it's better to do render in defer to avoid slow down of the ui
		var isMetadataChanged = !this._equal(state, this.state, 'metadata');
		var isPageChanged = !this._equal(props, this.props, ['preview', 'page']);
		var isReady = !isEmpty(get(this.props, ['preview', 'page'])) && !isEmpty(get(this.state, 'metadata'));
		if(isReady && (isMetadataChanged || isPageChanged)) {
			var renderer = new PageRenderer(this.props.preview.page, this.state.metadata);
			defer(bind(function() {
				var html = renderer.render();
				this.setState({ html: html });
				IframeTools.RenderFrameContents(html, this.refs.iframe, this.scaleFrameContents);
			}, this));
		}
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(ev) {
		if (this.props.onClick) this.props.onClick(this.props.preview.reference, ev);
	},

	/**
	 * @method _onDragStart
	 */
	_onDragStart: function(e) {
		ComponentActionsCreator.startDraggingComponent({ reference: this.props.preview.reference });

		e.dataTransfer.setData('text', JSON.stringify(this.props.preview.reference));
	},

	/**
	 * @method _onDragEnd
	 */
	_onDragEnd: function() {
		ComponentActionsCreator.endDraggingComponent();
	},

	/**
	 * @method scaleFrameContents
	 */
	scaleFrameContents: function() {
		var $iframe = $(ReactDOM.findDOMNode(this.refs.iframe));
		var width = $iframe.contents().width() || $iframe.width();
		var height = $iframe.contents().height() || $iframe.height();
		$iframe.width(width);
		$iframe.height(height);

		var $thumb = $(ReactDOM.findDOMNode(this.refs.thumb));
		var containerWidth = $thumb.contents().width();
		var containerHeight = $thumb.contents().height();

		var widthRatio = containerWidth / width;
		var scale = widthRatio;
		$iframe.css({
			'-webkit-transform': 'scale(' + scale + ')',
			'-moz-transform': 'scale(' + scale + ')',
			'-ms-transform': 'scale(' + scale + ')',
			'-o-transform': 'scale(' + scale + ')',
			'transform': 'scale(' + scale + ')',
			'visibility': 'visible',
			'top': Math.max(0, (containerHeight - (height * scale)) / 2)
		});
	},

	/**
	 * @method render
	 */
	render: function() {
		return (<div draggable="true"
			onClick={this._onClick}
			onDragStart={this._onDragStart}
			onDragEnd={this._onDragEnd}
			className='inner'>
			<div className="inner-title">
				<span className="small-heading">{this.props.preview.reference.name}</span>
			</div>
			<div ref="thumb" className="inner-content thumb">
				<div key={this.props.preview.pageId} className="wrapper">
					<iframe
						ref="iframe"
						scrolling="no" seamless
						sandbox="allow-scripts allow-forms allow-same-origin"
						src='./plain_iframe'></iframe>
					<div className="overlay"></div>
				</div>
			</div>
		</div>);
	}
});

module.exports = ComponentPreviewReact;
