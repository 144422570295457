'use strict';

import { get, has, isElement, isEqual } from 'lodash';

var React = require('react');
var ReactDOM = require('react-dom');

var ComponentFactory = require('../../infrastructure/ComponentFactory');

var DomElementUtils = require('../../utils/DomElementUtils');
var Shapes = require('../../utils/Shapes');
var ShapeUtils = require('../../utils/ShapeUtils');

var Popover = require('./Popover.react');

/** @module StickyPopover
 * @requires lodash
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires DomElementUtils
 * @requires Shapes
 * @requires ShapeUtils
 * @requires Popover
 */
var StickyPopover = ComponentFactory.Create({
	displayName: "StickyPopover",
	propTypes: {
		onClose: React.PropTypes.func,
		onOpen: React.PropTypes.func,
		updatePeriod: React.PropTypes.number,
		//checks that:
		//"target" is required DOM element
		//"boundary" is not required DOM element
		/**
		 * @method customValidationProp
		 */
		customValidationProp: function(props) {
			if (!has(props, 'target')) {
				return new Error('"target" property is required!');
			} else if (!isElement(props.target)) {
				return new Error('"target" property should be DOM element!');
			}

			if (has(props, 'boundary') && !isElement(props.boundary)) {
				return new Error('"boundary" property should be DOM element!');
			}
		}
	},

	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			boundary: document.body,
			updatePeriod: 100
		};
	},

	/**
	 * @method applyDomChanges
	 */
	applyDomChanges: function() {
		var zeroElement = DomElementUtils.GetRelativeParent(ReactDOM.findDOMNode(this));

		var targetRect = DomElementUtils.GetElementRelativeRect(this.props.target, zeroElement);
		targetRect = targetRect || ShapeUtils.EmptyRect();
		if(!isEqual(get(this.state, 'targetRect'), targetRect)) {
			this.setState({ targetRect: targetRect });
		}

		var boundaryRect = DomElementUtils.GetElementRelativeRect(this.props.boundary, zeroElement);
		boundaryRect = boundaryRect || ShapeUtils.EmptyRect();
		if(!isEqual(get(this.state, 'boundaryRect'), boundaryRect)) {
			this.setState({ boundaryRect: boundaryRect });
		}
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this.applyDomChanges();
		this.interval = setInterval(this.applyDomChanges, this.props.updatePeriod);
		this.componentDidUpdate();
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function() {
		clearInterval(this.interval);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		if (!this.state) return;
		state = state || {};

		if(!isEqual(state.targetRect, this.state.targetRect)) {
			this.setState({ targetRectangle: new Shapes.Rectangle(this.state.targetRect) });
		}
		if(!isEqual(state.boundaryRect, this.state.boundaryRect)) {
			this.setState({ boundaryRectangle: new Shapes.Rectangle(this.state.boundaryRect) });
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		var targetRectangle = get(this.state, 'targetRectangle');
		var boundaryRectangle = get(this.state, 'boundaryRectangle');
		if (!targetRectangle || !boundaryRectangle) return null;
		return (<Popover className={this.props.className}
			targetRectangle={targetRectangle}
			boundaryRectangle={boundaryRectangle}
			onClose={this.props.onClose}
			onOpen={this.props.onOpen}
			updatePeriod={this.props.updatePeriod}>
			{this.props.children}
		</Popover>);
	}
});

module.exports = StickyPopover;
