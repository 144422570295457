'use strict';

import { map, debounce, get, set, isEqual, cloneDeep } from 'lodash';
var he = require('he');

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');

var PropertyMetadata = require('../../models/PropertyMetadata');
var ComponentInstance = require('../../models/ComponentInstance');

var ContentStore = require('../../stores/ContentStore');

var ContentTypes = require('../../constants/ContentTypes');
var MediaServicesUtils = require('../../utils/MediaServicesUtils');

var UrlUtils = require('../../utils/UrlUtils');


/** @module VideoUrlInput
 * @requires lodash
 * @requires he
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentActionsCreator
 * @requires PropertyMetadata
 * @requires ComponentInstance
 * @requires ContentStore
 * @requires ContentTypes
 * @requires MediaServicesUtils
 * @requires UrlUtils
 * @requires ContentActionsCreator
 */
var VideoUrlInput = ComponentFactory.Create(ContentStore, {
	displayName: "VideoUrlInput",
	propTypes: {
		property: React.PropTypes.instanceOf(PropertyMetadata).isRequired,
		component: React.PropTypes.instanceOf(ComponentInstance).isRequired
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			videos: ContentStore.getState(ContentTypes.YOUTUBE)
		};
	},
	/**
	 * @method componentDidMount
	 */
	componentDidMount: function(props) {
		this.componentDidUpdate();

		this._debouncedSave = debounce(this._save, 100, this);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		var name = this.props.property.name,
			path = ['component', 'context', name];

		var isStateChanged = !this._equal(this.state, prevState, 'value');
		var isPropertyChanged = !this._equal(this.props, prevProps, path);
		var isSynced = this._equal(this.state, this.props, 'value', path);
		if (isSynced) return;

		if (isPropertyChanged) {
			this.setState({ value: get(this.props, path) });
		} else if(isStateChanged) {
			this._debouncedSave();
		}

		ContentActionsCreator.loadContent(ContentTypes.YOUTUBE);
	},

	/**
	 * @method _save
	 */
	_save: function() {
		var component = cloneDeep(this.props.component);

		var encodedText = he.encode(get(this.state, 'value', ''));
		set(component, ['context', this.props.property.name], encodedText);

		ComponentActionsCreator.updateComponentInstanceContext(component);
	},

	/**
	 * @method _equal
	 */
	_equal: function(first, second, firstpath, secondpath) {
		secondpath = secondpath || firstpath;
		return isEqual(get(first, firstpath), get(second, secondpath));
	},

	/**
	 * @method _onTextChange
	 */
	_onTextChange: function(e) {
		this.setState({ value: e.target.value });
	},

	/**
	 * @method _onVideoSelect
	 */
	_onVideoSelect: function(video) {
		var videoUrl = MediaServicesUtils.getYoutubeUrlByVideoId(video.video_id);
		this.setState({ value: videoUrl });
	},

	/**
	 * @method render
	 */
	render: function() {
		var videos = get(this.state, 'videos', []);
		var decodedText = he.decode(get(this.state, 'value', '') + '');
		return (
			<div className="text-input row">
				<div className="medium-3 large-3 columns">
					<div className="columns input-label">
						<label className="left inline">
							<span className="small-heading">Type URL to your video:</span>
							<div className="video-icons">
								<div className="youtube"><img src={UrlUtils.staticUrl("img/YouTube-logo-dark.png")}/></div>
								<div className="vimeo"><img src={UrlUtils.staticUrl("img/vimeo_icon_dark.png")}/></div>
								<div className="yahoo"><img src={UrlUtils.staticUrl("img/yahoo1-64x64.png")}/></div>
								<div className="dailymotion"><img src={UrlUtils.staticUrl("img/Dailymotion_Dark_logo.png")}/></div>
							</div>
						</label>
					</div>
					<div className="columns input">
						<input
							className="small-input"
							type='text'
							value={decodedText}
							placeholder={this.props.property.description}
							onChange={this._onTextChange} />
					</div>
				</div>
				<div className="medium-9 large-9 columns">
					<div className="columns input-label">
						<label className="left inline">
							<span className="small-heading ">or click on an imported video to pick:</span>
						</label>
					</div>
					<div className="columns">
						<ul className="video-thumbnail-list">
							{map(videos, (video) => {
								return <ListItemWrapper key={video.id} onSelect={this._onVideoSelect} video={video}/>;
							})}
						</ul>
					</div>
				</div>
			</div>);
	}
});

var ListItemWrapper = ComponentFactory.Create({
	displayName: "ListItemWrapper",
	/**
	 * @method _onSelect
	 */
	_onSelect: function(){
		this.props.onSelect(this.props.video);
	},

	/**
	 * @method render
	 */
	render: function() {
		var video = this.props.video;
		var thumbUrl = video.thumbnail_url || video.url || "https://www.youtube.com/yt/brand/media/image/YouTube-logo-full_color.png";
		return (
			<li>
				<div className="video-thumbnail" onClick={this._onSelect}
					style={{backgroundImage: "url('" + thumbUrl + "')"}} title={video.title}>
				</div>
			</li>
		);
	}
});

module.exports = VideoUrlInput;
