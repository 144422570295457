'use strict';

/** @module Predefined Fonts supported by most browsers and OS.
 *  See http://web.mit.edu/jmorzins/www/fonts.html
 */
module.exports = [
	'Arial',
	'Courier New',
	'Times New Roman'
];
