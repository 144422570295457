import { get, set, bind, identity } from 'lodash';

import React from 'react';
var Draggable = require('../common/Draggable.react');
var canvasUtils = require('../../utils/canvas');
var SignedUploadActionsCreator = require('../../actions/SignedUploadActionsCreator');
var SignedUploadStore = require('../../stores/SignedUploadStore');
var RemixerConstants = require('../../constants/RemixerConstants');
var FlashMessageActionsCreator = require('../../actions/FlashMessageActionsCreator');
var DomElementUtils = require('../../utils/DomElementUtils');

export default class ImageMover extends React.Component {
	static propTypes = {
		activeRectangle: React.PropTypes.object.isRequired,
		imageUrl: React.PropTypes.string.isRequired,
		imageStyle: React.PropTypes.object,
		onImageChange: React.PropTypes.func,
		onImageMove: React.PropTypes.func,
		onImageScale: React.PropTypes.func,
		onOpacityChange: React.PropTypes.func,
		onUploadProgress: React.PropTypes.func
	}

	static defaultProps = {
		imageUrl: "",
		activeRectangle: {
			size: 0,
			height: 0,
			width: 0
		}
	}

	constructor(props) {
		super(props);

		this.maskMargin = 20
		this.maxMaskSize = 290

		// get the current position of the image, it should be in the form '10% 25%'
		var positions = get(props, 'imageStyle.background-position', '0 0').split(' ')

		// get the current size of the image, it should be in the form '100%'
		var scale = get(props, 'imageStyle.background-size', '100%')

		// get the current opacity of the image, it should be in the form '0.5'
		var opacity = get(props, 'imageStyle.opacity', 1)

		// build the mask based on the display rectangle of the image
		if(props.activeRectangle) {
			var activeRectangle = props.activeRectangle.size
			if(activeRectangle.width >= activeRectangle.height){
				//equate activeRectangle.width with 100% and figure out percent of height
				var maskSize = {percent: {width: "100%", height: Math.floor(activeRectangle.height / activeRectangle.width * 100) + "%"}}
			} else {
				//activeRectangle.height is bigger so find percentage of width
				maskSize = {percent: {width: Math.floor(activeRectangle.width / activeRectangle.height * 100) + "%", height: "100%"}}
			}
		} else {
			// just some default values
			maskSize = {percent: {width: "100%", height: "56%"}}
		}
		set(maskSize, 'pixels', {
			width: parseInt(maskSize.percent.width) / 100 * this.maxMaskSize,
			height: parseInt(maskSize.percent.height) / 100 * this.maxMaskSize
		})

		var maskLocation = this._getMaskLocation(maskSize)

		var diff = {x: parseInt(positions[0]), y: parseInt(positions[1])}
		var position = {
			imageX: maskLocation.x - (diff.x / 100) * maskSize.pixels.width,
			imageY: maskLocation.y - (diff.y / 100) * maskSize.pixels.height
		}
		this.state = {
			scale: parseInt(scale) - 100,
			opacity: opacity * 100,
			maskSize: maskSize,
			imageX: get(position, 'imageX', 20),
			imageY: get(position, 'imageY', 20)
		}
	}

	/**
	 * @method componentDidMount
	 */
	componentDidMount() {
		SignedUploadStore.addListener(RemixerConstants.RECEIVE_SIGNED_UPLOAD, this.signedUploadEventHandler);
		this._loadImage(this.props.imageUrl)
	}

	componentWillUnmount() {
		SignedUploadStore.removeListener(RemixerConstants.RECEIVE_SIGNED_UPLOAD, this.signedUploadEventHandler);
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.imageUrl !== nextProps.imageUrl) {
			this._loadImage(nextProps.imageUrl)
		}
	}

	_loadImage(url) {
		this.originalImage = new Image()
		this.originalImage.crossOrigin = 'anonymous'

		this.originalImage.onload = bind(function() {
			this.originalImage.onload = identity;

			if (this.originalImage) {
				var nativeSize = { width: this.originalImage.naturalWidth, height: this.originalImage.naturalHeight };
				// now that the image is loaded we can set the position of the thumbnail

				var scale = get(this.props, 'imageStyle.background-size', '100%')
				scale = parseInt(scale) - 100
				var scaleOffset = 0
				if(scale && (nativeSize.width / nativeSize.height) >= (this.state.maskSize.pixels.width / this.state.maskSize.pixels.height)) {
					scaleOffset = (nativeSize.width / nativeSize.height) / (this.state.maskSize.pixels.width / this.state.maskSize.pixels.height) * 100 - 100
					scaleOffset = Math.ceil(scaleOffset)
				}

				var maskLocation = this._getMaskLocation(this.state.maskSize)
				var positions = get(this.props, 'imageStyle.background-position', '50% 50%').split(' ')

				this.setState({
					scale: scale - scaleOffset
				}, () => {
					var imageSize = this._scaleImageToThumbnail(this.originalImage, nativeSize).split(" ")
					if(!imageSize[1]) {
						imageSize.push(imageSize[0])
					}


					var diff = {x: parseInt(positions[0]), y: parseInt(positions[1])}
					var position = {
						imageX: maskLocation.x - (diff.x / 100) * (parseInt(imageSize[0]) - this.state.maskSize.pixels.width),
						imageY: maskLocation.y - (diff.y / 100) * (parseInt(imageSize[1]) - this.state.maskSize.pixels.height)
					}
					this.setState({
						nativeSize: nativeSize,
						imageX: position.imageX,
						imageY: position.imageY
					}, () => {this._updateCanvas(this.originalImage)})
				})

			}
		}, this);
		// DO NOT REMOVE!!! Allows cors header to take on cleared cache
		var sep = (url.indexOf('?') > -1) ? '&' : '?';
		this.originalImage.src = url + sep + Date.now();
	}

	_getMaskLocation(maskSize) {
		var x = 20 + (this.maxMaskSize - maskSize.pixels.width) / 2
		var y = 20 + (this.maxMaskSize - maskSize.pixels.height) / 2
		return({x: x, y: y})
	}

	_convertPositionToPercent(position) {
		var maskLocation = this._getMaskLocation(this.state.maskSize)
		var diff = {x: maskLocation.x - position.x, y: maskLocation.y - position.y}
		var imageSize = this._scaleImageToThumbnail(this.originalImage).split(" ")

		//difference between top left of pic and top left of mask
		// divided by the scale of the image where 100% is when the bottom of the image is at the bottom of the mask
		// x should be 0% when left edge of image is touching left edge of mask
		// x should be 100% when right edge of image is touching right edge of mask
		// y should be 0% when top edge of image is touching top edge of mask
		// y should be 100% when bottom edge of image is touching bottom edge of mask
		var offsetRange = {
			x: parseInt(imageSize[0]) - this.state.maskSize.pixels.width,
			y: parseInt(imageSize[1]) - this.state.maskSize.pixels.height
		}

		if( offsetRange.x === 0 ) {
			var x = 0
		} else {
			x = (diff.x / offsetRange.x) * 100
		}
		if( offsetRange.y === 0 ) {
			var y = 0
		} else {
			y = (diff.y / offsetRange.y) * 100
		}
		return({x: x.toFixed(2) + "%", y: y.toFixed(2) + "%"})
	}

	_setImagePosition(position) {
		this.setState({
			imageX: position.x,
			imageY: position.y
		})
		this.props.onImageMove(this._convertPositionToPercent(position))
	}

	_centerImage() {

		var scale = 0
		if((this.state.nativeSize.width / this.state.nativeSize.height) >= (this.state.maskSize.pixels.width / this.state.maskSize.pixels.height)) {
			scale = (this.state.nativeSize.width / this.state.nativeSize.height) / (this.state.maskSize.pixels.width / this.state.maskSize.pixels.height) * 100 - 100
			scale = Math.ceil(scale)
		}

		this.setState({scale: 0}, () => {
			this.props.onImageScale((scale + 100) + "%")
			var imageSize = this._scaleImageToThumbnail(this.originalImage).split(" ")
			this._setImagePosition({
				x: 20 + (this.state.maskSize.pixels.width - parseInt(imageSize[0])) / 2 + (this.maxMaskSize - this.state.maskSize.pixels.width) / 2,
				y: 20 + (this.state.maskSize.pixels.height - parseInt(imageSize[1])) / 2 + (this.maxMaskSize - this.state.maskSize.pixels.height) / 2
			})
		})
	}

	_scaleImageToThumbnail(image, nativeSize) {
		if(!get(this.props, 'activeRectangle') || !(this.state.nativeSize || nativeSize)) return this.maxMaskSize + "px " + this.maxMaskSize + "px"

		var imageSize = nativeSize || this.state.nativeSize
		// 0% scale will be equivalent to cover setting (100%)
		// find smaller of nativeSize.width and nativeSize.height
		var nativeScale = imageSize.width / imageSize.height
		var maskScale = this.state.maskSize.pixels.width / this.state.maskSize.pixels.height
		if( maskScale >= nativeScale) {
			var finalScale = this.maxMaskSize / imageSize.width
		} else if ( 1 / maskScale <= 1 / nativeScale ) {
			finalScale = this.maxMaskSize / imageSize.height
		} else {
			finalScale = this.state.maskSize.pixels.height / imageSize.height
		}
		return (imageSize.width * finalScale * (this.state.scale + 100) / 100) + "px " + (imageSize.height * finalScale * (this.state.scale + 100) / 100) + "px"
	}

	/**
	 * @method _dragStart
	 */
	_dragStart() {
		this.setState({initialX: this.state.imageX, initialY: this.state.imageY})
	}

	/**
	 * @method _reposition
	 */
	_reposition(ev, values) {
		this._setImagePosition({x: this.state.initialX + values.offsetX, y: this.state.initialY + values.offsetY})
	}

	_scaleChange(ev, values) {

		var scaleOffset = 0
		if((this.state.nativeSize.width / this.state.nativeSize.height) >= (this.state.maskSize.pixels.width / this.state.maskSize.pixels.height)) {
			scaleOffset = (this.state.nativeSize.width / this.state.nativeSize.height) / (this.state.maskSize.pixels.width / this.state.maskSize.pixels.height) * 100 - 100
			scaleOffset = Math.ceil(scaleOffset)
		}

		if(ev.target.className !== this.scale.props.className) return;
		var rect = DomElementUtils.GetElementRect(ev.target);

		var percent = (values.initialX - rect.left + values.offsetX) / rect.width;
		var scale = Math.ceil(percent * 200)
		this.setState({scale: scale - 100})
		this.props.onImageScale((scale + scaleOffset) + "%")
		this._setImagePosition({x: this.state.imageX, y: this.state.imageY})
	}

	_opacityChange(ev, values) {
		if(ev.target.className !== this.opacity.props.className) return;
		var rect = DomElementUtils.GetElementRect(ev.target);

		var percent = (values.initialX - rect.left + values.offsetX) / rect.width;
		this.setState({opacity: Math.ceil(percent * 100)})
		this.props.onOpacityChange(percent.toFixed(2))
	}

	_updateCanvas(originalImage) {
		var canvas = this.canvas
		const ctx = canvas.getContext('2d')
		canvas.width = this.state.nativeSize.width
		canvas.height = this.state.nativeSize.height
		ctx.clearRect(0,0, canvas.width, canvas.height)
		ctx.drawImage(originalImage, 0, 0)
	}

	/**
	 * @method _rotateCanvas
	 */
	_rotateCanvas(rotation){
		var canvas = this.canvas
		canvasUtils.applyRotateToCanvas(canvas, rotation)
		var nativeSize = { width: canvas.width, height: canvas.height }
		this.setState({ nativeSize: nativeSize })
		SignedUploadActionsCreator.signUpload()
	}

	/**
	 * @method _flipCanvas
	 */
	_flipCanvas(){
		var canvas = this.canvas
		canvasUtils.applyFlipToCanvas(canvas)
		SignedUploadActionsCreator.signUpload()
	}

	signedUploadEventHandler = (payload) => this.receiveSignedUpload(payload)
	receiveSignedUpload(payload) {
		var canvas = this.canvas
		this.xhr = canvasUtils.upload({
			contentType: 'image/png',
			fileName: Date.now() + '.png',
			signed: payload.result.data,
			canvas: canvas,
			previousXhr: this.xhr,
			onReady: (params) => this.onImageUploadSuccess(params),
			onError: (ev, params) => this.onImageUploadError(ev, params),
			onProgress: (ev) => this.onImageUploadProgress(ev)
		})
	}

	/**
	 * @method onImageUploadSuccess
	 */
	onImageUploadSuccess(params) {
		if(this.props.onImageChange) {
			this.props.onImageChange(params.url)
		}
		var sep = (this.props.imageUrl.indexOf('?') > -1) ? '&' : '?';
		this.originalImage.src = this.props.imageUrl + sep + Date.now();
	}

	/**
	 * @method onImageUploadProgress
	 */
	onImageUploadProgress(ev) {
		if (this.props.onUploadProgress) this.props.onUploadProgress(Math.ceil(ev.loaded / ev.total * 100))
	}

	/**
	 * @method onImageUploadError
	 */
	onImageUploadError(ev, params) {
		FlashMessageActionsCreator.addFlashMessage({ text: 'Oops, your image could not be updated.', level: 'error' });
	}

	render() {
		var imageSize = this._scaleImageToThumbnail(this.originalImage)
		return <div className="background-grid">
			<Draggable onDragStart={() => this._dragStart()} onDrag={(ev, values) => this._reposition(ev, values)} style={{width: '100%', height: '100%', overflow: 'hidden'}}>
				<div className="img-editor-image" id="drag-thumb"
					style={{display: 'none', cursor: 'move', width: '100%', height: '100%', backgroundImage: 'url('+ this.props.imageUrl + ')', backgroundSize: imageSize, backgroundRepeat: 'no-repeat', backgroundPosition: get(this.state, 'imageX') + 'px ' + get(this.state, 'imageY') + 'px', opacity: 1}} />
				{this.originalImage ? <canvas ref={(ref) => this.canvas = ref} style={{cursor: 'move', width: parseInt(imageSize.split(" ")[0]), height: parseInt(imageSize.split(" ")[1]), position: 'relative', top: get(this.state, 'imageY') + 'px', left: get(this.state, 'imageX') + 'px', opacity: get(this.state, 'opacity') / 100}} />
					:<div className='cropper-container-loading'></div>}
			</Draggable>
			<div style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, overflow: 'hidden', pointerEvents: 'none'}}>
				<table style={{width: '100%', height: '100%', background: 'transparent', position: 'relative'}}>
					<tbody style={{width: '100%', height: '100%', background: 'transparent', position: 'relative'}}>
						<tr style={{width: '100%', height: '100%', background: 'transparent', position: 'relative'}}>
							<td style={{width: '100%', height: '100%', background: 'transparent', position: 'relative', verticalAlign: 'middle', textAlign: 'center', padding: 20}}>
								<div className="img-editor-border-bounds" style={{width: this.state.maskSize.percent.width, height: 0, paddingBottom: this.state.maskSize.percent.height, border: '2px solid #3e95be', display: 'inline-block', boxShadow: '0 0 0pt 5000px rgba(255,255,255,0.6)'}} />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div style={{width: 320, marginLeft: '-5px', height: 30, marginTop: '-15px', position: 'relative', zIndex: 5, paddingTop: 0}}>
				<div style={{width: '50%', height: 30, position: 'absolute', bottom: 0, left: 0, paddingTop: 0, textAlign: 'left'}}>
					{/*button rotate*/}
					<div className="mod-button b-rotate" onClick={() => this._rotateCanvas(90)} style={{width: 30, height: 35, marginLeft: 10, marginRight: 1, display: 'inline-block', position: 'relative', borderBottom: '2px solid transparent'}}>
						<div style={{width: 30, height: 30, background: '#fff', border: '1px solid #9c9c9c', borderRadius: 5, display: 'inline-block', position: 'relative'}}>
							<i className="mdi mdi-rotate-right" style={{color: '#3e95be', fontSize: 20, position: 'absolute', top: 5, left: 4}} />
						</div>
					</div>
					{/*button flip*/}
					<div className="mod-button b-flip" onClick={() => this._flipCanvas()} style={{width: 30, height: 35, marginLeft: 5, marginRight: 1, display: 'inline-block', position: 'relative', borderBottom: '2px solid transparent'}}>
						<div style={{width: 30, height: 30, background: '#fff', border: '1px solid #9c9c9c', borderRadius: 5, display: 'inline-block', position: 'relative'}}>
							<i className="mdi mdi-repeat" style={{color: '#3e95be', fontSize: 20, position: 'absolute', top: 5, left: 4}} />
						</div>
					</div>
					{/*button center*/}
					<div className="mod-button b-center" onClick={() => this._centerImage()} style={{width: 30, height: 35, marginLeft: 5, marginRight: 1, display: 'inline-block', position: 'relative', borderBottom: '2px solid transparent'}}>
						<div style={{width: 30, height: 30, background: '#fff', border: '1px solid #9c9c9c', borderRadius: 5, display: 'inline-block', position: 'relative'}}>
							<i className="mdi mdi-image-filter-center-focus" style={{color: '#3e95be', fontSize: 20, position: 'absolute', top: 5, left: 4}} />
						</div>
					</div>
				</div>
			</div>
			<div style={{width: '100%', height: 45, position: 'relative', marginTop: 0, marginBottom: 0, paddingRight: 26, paddingLeft: 26}}>
				{/* sliders */}
				<div style={{width: '45%', height: 45, position: 'relative', marginLeft: 0, marginRight: 0, float: 'left'}}>
					{/* opacity */}
					<div style={{width: '100%', height: 30, position: 'relative', marginTop: 10, marginBottom: 10}}>
						<div style={{width: '100%', height: 30, position: 'absolute', bottom: 0, left: 0}}>
							<p className="tiny-heading" style={{textShadow: '0px 1px rgba(255,255,255,0.5)', fontSize: 10, textAlign: 'left', width: '13rem', lineHeight: '14px', padding: 0, margin: 0, fontWeight: 'bold'}}>Opacity</p>
							<input type="text" id="amount2" value={this.state.opacity + "%"} disabled="disabled" style={{position: 'absolute', top: 0, right: 0, textShadow: '0px 1px rgba(255,255,255,0.5)', pointerEvents: 'none', boxShadow: 'none', border: 'none', background: 'transparent', textAlign: 'right', padding: 0, height: 15, borderRadius: 5, width: 30, fontSize: 10}} />
							<Draggable ref={node => this.opacity = node} style={{height: 15, paddingTop: 10, paddingBottom: 10}} onDrag={(ev, values) => this._opacityChange(ev, values)} onDragStart={(ev, values) => this._opacityChange(ev, values)} className="opacity-slider">
								<div className="opacity-slider slider-background" style={{zIndex: -1, background: "#ccc", border: '0px solid #c5c5c5', height: 5, position: 'relative', top: 0}}>
									<div className="opacity-slider slider" style={{borderRadius: '50%', width: '1.0em', height: '1.0em', cursor: 'default', msTouchAction: 'none', touchAction: 'none', position: 'absolute', top: '-5px', left: (this.state.opacity) + '%', background: "#3e95be", border: '0px solid #c5c5c5', marginLeft: -8}}></div>
								</div>
							</Draggable>
						</div>
					</div>
				</div>
				<div style={{width: '45%', height: 45, position: 'relative', marginLeft: 0, marginRight: 0, float: 'right'}}>
					{/* scale */}
					<div style={{width: '100%', height: 30, position: 'relative', marginTop: 10, marginBottom: 10}}>
						<div style={{width: '100%', height: 30, position: 'absolute', bottom: 0, left: 0}}>
							<p className="tiny-heading" style={{textShadow: '0px 1px rgba(255,255,255,0.5)', fontSize: 10, textAlign: 'left', width: '13rem', lineHeight: '14px', padding: 0, margin: 0, fontWeight: 'bold'}}>Image Scale</p>
							<input type="text" id="amount" value={this.state.scale + "%"} disabled="disabled" style={{position: 'absolute', top: 0, right: 0, textShadow: '0px 1px rgba(255,255,255,0.5)', pointerEvents: 'none', boxShadow: 'none', border: 'none', background: 'transparent', textAlign: 'right', padding: 0, height: 15, borderRadius: 5, width: 30, fontSize: 10}} />
							<Draggable ref={node => this.scale = node} style={{height: 15, paddingTop: 10, paddingBottom: 10}} onDrag={(ev, values) => this._scaleChange(ev, values)} onDragStart={(ev, values) => this._scaleChange(ev, values)} className="scale-slider" >
								<div className="scale-slider slider-background" style={{zIndex: -1, background: "#ccc", border: '0px solid #c5c5c5', height: 5, position: 'relative', top: 0}}>
									<div className="scale-slider slider" style={{borderRadius: '50%', width: '1.0em', height: '1.0em', cursor: 'default', msTouchAction: 'none', touchAction: 'none', position: 'absolute', top: '-5px', left: ((this.state.scale + 100) / 2) + '%', background: "#3e95be", border: '0px solid #c5c5c5', marginLeft: -8}}></div>
								</div>
							</Draggable>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}
