'use strict';

import { get, set, cloneDeep, debounce, isEqual } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

var PropertyMetadata = require('../../models/PropertyMetadata');
var ComponentInstance = require('../../models/ComponentInstance');
var ColorPicker = require('../common/ColorPicker.react');

/** @module ColorInput
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentActionsCreator
 * @requires PropertyMetadata
 * @requires ComponentInstance
 * @requires ColorPicker
 * @requires lodash
	 */
var ColorInput = ComponentFactory.Create({
	displayName: "ColorInput",
	propTypes: {
		property: React.PropTypes.instanceOf(PropertyMetadata).isRequired,
		component: React.PropTypes.instanceOf(ComponentInstance).isRequired
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		var state = {value: ""}
		return state;
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function(props) {
		this._debouncedSave = debounce(this._save, 100, this);
		this._onColorChangeDebounced = debounce(this._onColorChange, 300);
		this.componentDidUpdate();

	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		var name = this.props.property.name,
			path = ['component', 'context', name];

		var isStateChanged = !this._equal(this.state, prevState, 'value');
		var isPropertyChanged = !this._equal(this.props, prevProps, path);
		var isSynced = this._equal(this.state, this.props, 'value', path);
		if (isSynced) return;

		if (isPropertyChanged) {
			this.setState({ value: get(this.props, path) });
		} else if(isStateChanged) {
			this._debouncedSave();
		}
	},

	/**
	 * @method _save
	 */
	_save: function() {
		var component = cloneDeep(this.props.component);
		set(component, ['context', this.props.property.name], this.state.value);

		ComponentActionsCreator.updateComponentInstanceContext(component);
	},

	/**
	 * @method _equal
	 */
	_equal: function(first, second, firstpath, secondpath) {
		secondpath = secondpath || firstpath;
		return isEqual(get(first, firstpath), get(second, secondpath));
	},

	/**
	 * @method _onTextChange
	 */
	_onTextChange: function(e) {
		this.setState({ value: e.target.value });
	},

	/**
	 * @method _onColorChange
	 */
	_onColorChange: function(colorStr) {
		this.setState({ value: colorStr });
	},

	/**
	 * @method render
	 */
	render: function() {
		return (<div className="color-input row">
			<div className="columns input-label">
				<label className="left inline">
					<span className="small-heading">{this.props.property.description}</span>
				</label>
			</div>
			<div className="columns">
				<div className="row">
					<div className="medium-3 large-3 columns input">
						<input
							className="small-input"
							type='text'
							value={this.state.value}
							placeholder={this.props.property.description}
							onChange={this._onTextChange} />
					</div>
					<div className="medium-9 large-9 columns">
						<ColorPicker color={get(this.state, 'value')}	onChange={this._onColorChangeDebounced} />
					</div>
				</div>
			</div>
		</div>);
	}
});

module.exports = ColorInput;
