'use strict';

var UrlUtils = require('./UrlUtils');

/** @module ThirdPartyWebAPI
 * @requires UrlUtils
 * @requires lodash
 */
module.exports = {
	/**
	 * @method buildInstagramAuthUrl
	 */
	buildInstagramAuthUrl: (clientid, redirectUri) => {
		const instagramOAuthApi = UrlUtils.parseUrl('https://api.instagram.com/oauth/authorize/');
		instagramOAuthApi.search = UrlUtils.buildQueryString({
			'client_id': clientid,
			'redirect_uri': redirectUri,
			'response_type': 'code'
		});

		return instagramOAuthApi.toString();
	}
};
