'use strict';

import { isFunction } from 'lodash';
var Validator = require('../utils/Validator');

function Proxy() {
	this.setup = function(options) {
		this.channel = options.channel;
	};

	this.destroy = function() {
		delete this.channel;
	};
}

/** @module Communication
 * @requires lodash
 * @requires Validator
 */

//marker interface need to check in it's Receiver
function Receiver() { }

function Channel(options) {
	Validator.isInstanceOf(options, Receiver, ['local']);
	Validator.isInstanceOf(options, Proxy, ['remote']);
	Validator.isTypeOf(options, 'object', ['window']);

	this.receiver = options.local;
	this.proxy = options.remote;
	this.window = options.window;

	this._receive = function(data) {
		var method = this.receiver[data.method];
		if(isFunction(method)) {
			method.call(this.receiver, data.parameters);
		}
	}.bind(this);

	this.setup = function() {
		this.proxy.setup({ channel: this });
	};

	this.destroy = function() {
		this.proxy.destroy();
	};
}

module.exports = {
	Channel: Channel,
	Proxy: Proxy,
	Receiver: Receiver
};
