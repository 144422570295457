'use strict';

var _ = require('lodash');

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var Generic = require('./Generic.react');
var SelectableItem = require('./SelectableItem.react');

/** @module SelectableGroup
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires Generic
 * @requires SelectableItem
 */
var SelectableGroup = ComponentFactory.Create({
	displayName: "SelectableGroup",
	propTypes: {
		selected: React.PropTypes.array,
		onChange: React.PropTypes.func,
		tag: React.PropTypes.any
	},
	/**
	 * @method getDefaultProps
	 */
	getDefaultProps: function() {
		return {
			selected: [],
			onChange: _.noop,
			tag: 'ul'
		};
	},
	/**
	 * @method _onChange
	 */
	_onChange: function(value) {
		var selected = _.clone(this.props.selected);
		if (_.includes(selected, value)) {
			_.remove(selected, value);
		} else {
			selected.push(value);
		}
		this.props.onChange(selected, value);
	},
	/**
	 * @method _bindSelectableItem
	 */
	_bindSelectableItem: function(element) {
		var value = element.props.value;

		var newProps = _(element.props)
			.pick(["selected", "onChange"])
			.defaults({
				selected: _.includes(this.props.selected, value),
				onChange: _.partial(this._onChange, value)
			})
			.value();

		return React.cloneElement(element, newProps);
	},
	/**
	 * @method render
	 */
	render: function() {
		var css = {
			"selectable-group": true
		};
		return (
			<Generic {...this.props} className={this.css(this.props.className, css)} unselectable='on'>
				{React.Children.map(this.props.children, (element) => {
					if (element && element.type === SelectableItem) {
						return this._bindSelectableItem(element);
					}
					return element;
				})}
			</Generic>
		);
	}
});

module.exports = SelectableGroup;
