'use strict';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

/** @module LinkIcon
 * @requires ComponentFactory
 * @requires react
 */
var LinkIcon = ComponentFactory.Create({
	displayName: "LinkIcon",
	propTypes: {
		name: React.PropTypes.string.isRequired
	},

	/**
	 * @method _onSelect
	 */
	_onSelect: function() {
		if (this.props.onSelect) {
			this.props.onSelect(this.props.name);
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		if (this.props.name === 'none') {
			return (<i onClick={this._onSelect} title="no icon" className="link-icon no-icon">no <span className="icon-wording">icon</span></i>);
		}
		return (<i onClick={this._onSelect} title={this.props.name} className={'link-icon mdi mdi-' + this.props.name}></i>);
	}
});

module.exports = LinkIcon;
