import React from 'react';
var ImagesUploader = require('../common/ImagesUploader.react');

export default class ImageImporter extends React.Component {
	static propTypes = {
		closeImageImporterModal: React.PropTypes.func
	}

	static defaultProps = {
	}

	constructor(props) {
		super(props);
	}


	render() {
		return <div className="importModal" onClick={this.props.closeImageImporterModal} style={{width: '100%', height: '100%', minWidth: '100%', minHeight: '100%', background: 'rgba(0, 0, 0, 0.5)', position: 'fixed', zIndex: 99999, top: 0, left: 0, display: 'block'}}>
			<div className="dropShad" onClick={(e) => {e.stopPropagation()}} style={{overflow: 'hidden', borderRadius: 3, width: '70%', height: '70%', position: 'absolute', top: '15%', left: '15%', background: '#fff', padding: 10, zIndex: '+1'}}>
				<div className="importFirst" style={{width: '100%', height: 'calc(100% - 50px)', position: 'relative', paddingTop: '5%', display: 'block'}}>
					<ImagesUploader style={{width: '90%', height: '90%', border: '1px dashed #898989', borderRadius: 5, position: 'relative', marginLeft: '5%', textAlign: 'center'}}>
						<table style={{width: '100%', height: '100%', maxHeight: '100%', border: 'none', background: 'none', padding: 0, margin: 0}} cellPadding="0px" cellSpacing="0px">
							<tbody><tr style={{width: '100%', height: '100%', border: 'none', background: 'none', padding: 0, margin: 0}}>
								<td style={{height: '100%', border: 'none', background: 'none', padding: 0, margin: 0, textAlign: 'center', paddingTop: 4}} valign="middle" align="center">
									<p style={{pointerEvents: 'none', fontFamily: '"Proxima Nova Bold", sans-serif', fontSize: 18, color: '#515151', padding: 0, margin: 0, textAlign: 'center', marginBottom: 3}}>Drag an Image Here</p>
									<p style={{pointerEvents: 'none', fontFamily: '"Proxima Nova Bold", sans-serif', fontSize: 12, color: '#515151', padding: 0, margin: 0, textAlign: 'center', marginBottom: 10}}>or click on the button</p>
									<div className="blueGrad" style={{cursor: 'pointer', width: 105, height: 30, borderRadius: 3, display: 'inline-block'}}>
										<table style={{width: '100%', height: '100%', maxHeight: '100%', border: 'none', background: 'none', padding: 0, margin: 0}} cellPadding="0px" cellSpacing="0px">
											<tbody><tr style={{width: '100%', height: '100%', border: 'none', background: 'none', padding: 0, margin: 0}}>
												<td style={{width: '100%', height: '100%', border: 'none', background: 'none', padding: 0, margin: 0, textAlign: 'center', paddingTop: 2}} valign="middle" align="center">
													<p style={{pointerEvents: 'none', fontFamily: '"Proxima Nova Bold", sans-serif', fontSize: 12, color: '#fff', display: 'inline-block', padding: 0, margin: 0, textAlign: 'right'}}>Choose Image</p>
												</td></tr></tbody></table>
									</div>
								</td></tr></tbody></table>
					</ImagesUploader>
				</div>
				<div style={{width: '100%', height: 50, position: 'absolute', left: 0, bottom: 0, background: '#eff2f6', borderTop: '1px solid #c7ccd4', borderBottomLeftRadius: 3, borderBottomRightRadius: 3}}>
					<table style={{width: '100%', height: 50, maxHeight: 50, border: 'none', background: 'none', padding: 0, margin: 0}} cellPadding="0px" cellSpacing="0px">
						<tbody><tr style={{width: '100%', height: 50, border: 'none', background: 'none', padding: 0, margin: 0}}>
							<td className="cancelImport" style={{height: 50, border: 'none', background: 'none', padding: 0, margin: 0, textAlign: 'left', paddingLeft: 26}} valign="middle" align="left">
								<p className="cancel" onClick={this.props.closeImageImporterModal} style={{cursor: 'pointer', fontFamily: '"Proxima Nova Bold", sans-serif', fontSize: 12, display: 'inline-block', padding: 0, margin: 0}}>Close</p>
							</td>
							<td style={{height: '100%', border: 'none', background: 'none', padding: 0, margin: 0, textAlign: 'center'}} valign="middle" align="center">
							</td>
							<td className="importButtonTotals" style={{width: 126, height: 50, border: 'none', background: 'none', padding: 0, margin: 0, textAlign: 'left', opacity: 0}} valign="middle" align="middle">
							</td></tr></tbody></table>
				</div>
			</div>
		</div>
	}
}
