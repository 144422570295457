'use strict';

var $ = require('jquery');
import { fromPairs, bind, map, forEach, debounce, create } from 'lodash';

var CommandUtils = require('./CommandUtils');
var Communication = require('./Communication');
var Proxy = Communication.Proxy;

/** @module Proxies
 * @requires jquery
 * @requires lodash
 * @requires CommandUtils
 * @requires Communication
 */

function CommandsExecutorProxy() {
	Proxy.call(this);

	this.remove = function(parameters) {
		this.channel.request('remove', parameters);
	};

	this.inject = function(parameters) {
		this.channel.request('inject', parameters);
	};

	this.text = function(parameters) {
		this.channel.request('text', parameters);
	};

	this.scroll = function(X, Y) {
		this.channel.request('scroll', {coordX: X, coordY: Y});
	};

	this.activate = function(parameters) {
		this.channel.request('activate', parameters);
	};

	this.expand = function(parameters) {
		this.channel.request('expand', parameters);
	};
}

function EventHandlingProxy() {
	Proxy.call(this);

	var _setup = bind(this.setup, this);
	this.setup = function(options) {
		_setup(options);

		$(document.body).on('input', this.debouncedSendCommand);
	};

	this.handle = function(data) {
		this.channel.request('handle', data);
	};

	this.sendCommands = bind(function(ev) {
		var commands = CommandUtils.GetCommands(ev.target, ev.type, ev);
		var command = map(commands, function (v, k) { return fromPairs([[k, v]]); });
		forEach(command, bind(this.handle, this));
	}, this);

	this.debouncedSendCommand = debounce(this.sendCommands, 500);
}

CommandsExecutorProxy.prototype = create(Proxy.prototype, {
	'constructor': CommandsExecutorProxy
});

EventHandlingProxy.prototype = create(Proxy.prototype, {
	'constructor': EventHandlingProxy
});

module.exports = {
	CommandsExecutorProxy: CommandsExecutorProxy,
	EventHandlingProxy: EventHandlingProxy
};
