'use strict';

import { map, partial, get, debounce, cloneDeep, set, unset, isEqual } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

var UserPreferenceActionsCreator = require('../../actions/UserPreferenceActionsCreator');

var UserPreferenceStore = require('../../stores/UserPreferenceStore');
var ComponentMetadataStore = require('../../stores/ComponentMetadataStore');
var PageStore = require('../../stores/PageStore');
var PageDomStateStore = require('../../stores/PageDomStateStore');
var ComponentContextStore = require('../../stores/ComponentContextStore');

var PropertyMetadata = require('../../models/PropertyMetadata');
var ComponentInstance = require('../../models/ComponentInstance');
var ColorPicker = require('../common/ColorPicker.react');
var Tabs = require('../common/Tabs.react');

/** @module BackgroundColorPicker
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentActionsCreator
 * @requires PropertyMetadata
 * @requires ComponentInstance
 * @requires ColorPicker
 */
var BackgroundColorPicker = ComponentFactory.Create(UserPreferenceStore, ComponentMetadataStore, PageStore, PageDomStateStore, ComponentContextStore, {
	displayName: "BackgroundColorPicker",
	propTypes: {
		property: React.PropTypes.instanceOf(PropertyMetadata).isRequired,
		component: React.PropTypes.instanceOf(ComponentInstance).isRequired
	},

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		var state = {backgroundImage: false}
		return state;
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function(props) {
		this.componentDidUpdate();
		var backgroundImage = this.state.backgroundImage;
		var backgroundImageUrl;
		var page = PageStore.getState(get(this.props.component, "pageId"));
		if (get(page, ['components', this.props.component.id])) {
			backgroundImage = PageDomStateStore.getState("phash_" + page.id, this.props.component.id, "backgroundImage.url")
			backgroundImageUrl = get(ComponentContextStore.getState('active').context, "backgroundImage.url");
		}

		this.setState({previousColors: UserPreferenceStore.getState('text_colors'), backgroundImage: backgroundImage, backgroundImageUrl: backgroundImageUrl})

		this._debouncedSave = debounce(this._save, 100, this);
		this._onColorChangeDebounced = debounce(this._onColorChange, 300);
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(prevProps, prevState) {
		var name = this.props.property.name,
			path = ['component', 'context', name];

		var isStateChanged = !this._equal(this.state, prevState, 'value');
		var isPropertyChanged = !this._equal(this.props, prevProps, path);
		var isSynced = this._equal(this.state, this.props, 'value', path);
		if (isSynced) return;

		if (isPropertyChanged) {
			this.setState({ value: get(this.props, path) });

		} else if(isStateChanged) {
			this._debouncedSave();
		}
	},

	/**
	 * @method _save
	 */
	_save: function() {
		var component = cloneDeep(this.props.component);
		set(component, ['context', this.props.property.name], this.state.value);

		ComponentActionsCreator.updateComponentInstanceContext(component);
	},

	/**
	 * @method _removeImage
	 */
	_removeImage: function() {
		var component = cloneDeep(this.props.component);
		unset(component, ['context', 'backgroundImage']);
		ComponentActionsCreator.updateComponentInstanceContext(component);
		this.setState({backgroundImageUrl: null})
	},

	/**
	 * @method _equal
	 */
	_equal: function(first, second, firstpath, secondpath) {
		secondpath = secondpath || firstpath;
		return isEqual(get(first, firstpath), get(second, secondpath));
	},

	/**
	 * @method _onTextChange
	 */
	_onTextChange: function(e) {
		this.setState({ value: e.target.value });
	},

	/**
	 * @method _onColorChange
	 */
	_onColorChange: function(colorStr) {
		UserPreferenceActionsCreator.changeTextColor(colorStr);
		this.setState({ value: colorStr, previousColors: UserPreferenceStore.getState('text_colors') });
	},

	/**
	 * @method _onImageClick
	 */
	_onImageClick: function() {
		ComponentActionsCreator.startImagePicker(this.state.backgroundImage);
	},

	/**
	 * @method render
	 */
	render: function() {
		var themeColors = ['#2f323b', '#f4f4f4', '#9c9c9c', '#515151', '#3e95be'];
		// TODO: use some clever logic once theme colors are done
		var userColors = get(this.state, 'previousColors') || themeColors;
		return (<div className="background-color-picker">
			<Tabs title={{headline: "Section Background", text: "Apply a custom color or image"}}>
				<div key='Palette' className="tabs-content-color" unselectable='on'>
					<div className="row">
						<p className="hint small-8 columns">
							<font className="content-title">Palette Colors</font>Colors used within this theme</p>
					</div>
					{map(themeColors, (color) => {
						return <div className="color-example"
							key={color}
							onClick={partial(this._onColorChange, color)}
							style={{backgroundColor: color}}></div>;
					})}
				</div>
				<div key='History' className="tabs-content-color" unselectable='on'>
					<div className="row">
						<p className="hint small-8 columns">
							<font className="content-title">History Colors</font>Previously selected custom colors</p>
					</div>
					{map(userColors, (color) => {
						return <div className="color-example"
							key={color}
							onClick={partial(this._onColorChange, color)}
							style={{backgroundColor: color}}></div>;
					})}
				</div>
				<div key='Custom' className="tabs-content-color custom" unselectable='on'>
					<div style={{display: "inline-block", verticalAlign: "middle", width: "100%"}}>
						<div className="row">
							<p className="hint small-7 columns">
								<font className="content-title">Custom Background</font>Use the color slider or manually enter a color value</p>
							<span className="color-input-wrapper small-5 columns">
								<input
									className="color-input"
									type="text"
									onChange={this._onTextChange}
									value={get(this.state, 'value', "#ffffff")} />
							</span>
						</div>
						<ColorPicker
							color={get(this.state, 'value')}
							onChange={this._onColorChangeDebounced} />
					</div>

					{this.state.backgroundImage ? <div style={{width: 96, height: 87, display: 'inline-block', paddingTop: 10, verticalAlign: "middle"}}>
						<div onClick={this._onImageClick} style={{backgroundSize: "cover", backgroundImage: "url(" + this.state.backgroundImageUrl + ")", width: 60, height: 60, borderRadius: 5, border: '1px dashed #9c9c9c', marginLeft: 18, textAlign: 'center', paddingTop: 15}}>
							{!this.state.backgroundImageUrl ? <p className="hint small-12 columns" style={{textAlign: 'center', fontSize: 8}}><font className="content-title" style={{fontWeight: 'bold', fontSize: 24, lineHeight: '10px', padding: 0, marginBottom: 5}}>+</font>Add Image</p> : null}
						</div>
						<div className="row" style={{marginLeft: 18, width: 60}}>
							<p className="hint small-12 columns" style={{textAlign: 'center', padding: 0, margin: 0}}>
								<font onClick={this._removeImage} className="content-title" style={{padding: 0, margin: 0, fontSize: 10, color: '#b74e4e'}}><font style={{fontSize: 14, fontWeight: 'bold'}}>x</font> Remove</font></p>
						</div>
					</div> : null }
				</div>
			</Tabs>
		</div>);
	}
});

module.exports = BackgroundColorPicker;
