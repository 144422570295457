'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module ComponentServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 */
var ComponentServerActionsCreator = {
	/**
	 * @method receiveInheritSkin
	 */
	receiveInheritSkin: function(pageId, basePageId, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_INHERIT_SKIN,
			component: response.data,
			pageId: pageId,
			basePageId: basePageId
		});
	},
	/**
	 * @method receiveInheritSkinFail
	 */
	receiveInheritSkinFail: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_INHERIT_SKIN_FAIL,
			message: 'could not inherit skin'
		});
	},
	/**
	 * @method receiveMovePageRevision
	 */
	receiveMovePageRevision: function(pageId, delta, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_MOVE_PAGE_REVISION,
			pageId: pageId,
			delta: delta
		});
	},
	/**
	 * @method receiveMovePageRevisionFail
	 */
	receiveMovePageRevisionFail: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_MOVE_PAGE_REVISION_FAIL,
			message: 'could not move page revision'
		});
	},
	/**
	 * @method receiveRemoveComponentInstance
	 */
	receiveRemoveComponentInstance: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_REMOVE_COMPONENT_INSTANCE,
			component: response.data
		});
	},
	/**
	 * @method receiveRemoveComponentInstanceFail
	 */
	receiveRemoveComponentInstanceFail: function(error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_REMOVE_COMPONENT_INSTANCE_FAIL,
			message: 'could not remove this component'
		});
	},
	/**
	 * @method receiveUpdateComponentInstance
	 */
	receiveUpdateComponentInstance: function(instance, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_COMPONENT_INSTANCE,
			component: instance
		});
	},
	/**
	 * @method receiveUpdateComponentInstanceFail
	 */
	receiveUpdateComponentInstanceFail: function(instance, error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_COMPONENT_INSTANCE_FAIL,
			message: "could not update component instance"
		});
	},
	/**
	 * @method receiveUpdateComponentInstanceContext
	 */
	receiveUpdateComponentInstanceContext: function(instance, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_COMPONENT_INSTANCE_CONTEXT,
			component: instance
		});
	},
	/**
	 * @method receiveUpdateComponentInstanceContextFail
	 */
	receiveUpdateComponentInstanceContextFail: function(instance, error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_UPDATE_COMPONENT_INSTANCE_CONTEXT_FAIL,
			message: "could not update component instance"
		});
	},
	/**
	 * @method receiveReplaceComponentInstance
	 */
	receiveReplaceComponentInstance: function(pageId, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_REPLACE_COMPONENT_INSTANCE,
			pageId: pageId
		});
	},
	/**
	 * @method receiveReplaceComponentInstanceFail
	 */
	receiveReplaceComponentInstanceFail: function(pageId) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_REPLACE_COMPONENT_INSTANCE_FAIL,
			pageId: pageId,
			message: "could not replace component instance"
		});
	},
	/**
	 * @method receiveCreateComponentInstances
	 */
	receiveCreateComponentInstances: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CREATE_COMPONENT_INSTANCE,
			component: response.data
		});
	},
	/**
	 * @method receiveCreateComponentInstancesFail
	 */
	receiveCreateComponentInstancesFail: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_CREATE_COMPONENT_INSTANCE_FAIL,
			message: "could not inject component"
		});
	},
	/**
	 * @method receiveLoadRelevantComponents
	 */
	receiveLoadRelevantComponents: function(pageId, componentContextId, componentCategory, searchText, pIndex, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_LOAD_RELEVANT_COMPONENTS,
			pageId: pageId,
			componentContextId: componentContextId,
			componentCategory: componentCategory,
			searchText: searchText,
			pIndex: pIndex,
			previews: response.data
		});
	},
	/**
	 * @method receiveLoadRelevantComponentsFail
	 */
	receiveLoadRelevantComponentsFail: function(pageId, componentContextId, componentCategory, searchText, pIndex) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_LOAD_RELEVANT_COMPONENTS_FAIL,
			pageId: pageId,
			componentContextId: componentContextId,
			componentCategory: componentCategory,
			searchText: searchText,
			pIndex: pIndex,
			message: "could not load relevant components"
		});
	},
	/**
	 * @method receiveLoadSimilarComponents
	 */
	receiveLoadSimilarComponents: function(pageId, componentContextId, componentCategory, searchText, pIndex, response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SIMILAR_COMPONENTS,
			pageId: pageId,
			componentContextId: componentContextId,
			componentCategory: componentCategory,
			searchText: searchText,
			pIndex: pIndex,
			previews: response.data
		});
	},
	/**
	 * @method receiveLoadSimilarComponentsFail
	 */
	receiveLoadSimilarComponentsFail: function(pageId, componentContextId, componentCategory, searchText, pIndex) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_SIMILAR_COMPONENTS_FAIL,
			pageId: pageId,
			componentContextId: componentContextId,
			componentCategory: componentCategory,
			searchText: searchText,
			pIndex: pIndex,
			message: "could not load relevant components"
		});
	},
	/**
	 * @method receiveComponentsMetadata
	 */
	receiveComponentsMetadata: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_COMPONENTS_METADATA,
			componentsMetadata: response.data
		});
	},
	/**
	 * @method receiveComponentsMetadataFail
	 */
	receiveComponentsMetadataFail: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_COMPONENTS_METADATA_FAIL,
			message: "could not get components metadata"
		});
	},
	/**
	 * @method receivePage
	 */
	receivePage: function(response) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_PAGE,
			page: response.data
		});
	},
	/**
	 * @method receivePageFail
	 */
	receivePageFail: function() {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_PAGE_FAIL,
			message: "could not get page metadata"
		});
	}
};

module.exports = ComponentServerActionsCreator;
