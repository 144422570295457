'use strict';

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

/** @module ImportStore
 * @requires StoreFactory
 * @requires RemixerConstants
 */
var ImportStore = StoreFactory.Create({
	/**
	 * @method _setImportStarting
	 */
	_setImportStarting: function (action) {
		this._filling(action.importType);
	},
	/**
	 * @method _setImportFinished
	 */
	_setImportFinished: function(action) {
		this._set(action.importType, true);
	},
	/**
	 * @method _setImportFailed
	 */
	_setImportFailed: function(action) {
		this._failed(action.importType);
	},
	/**
	 * @method _getHandlers
	 */
	_getHandlers: function() {
		var handlers = {};

		handlers[RemixerConstants.IMPORT_STARTING] = this._setImportStarting;
		handlers[RemixerConstants.RECEIVE_IMPORT_FINISHED] = this._setImportFinished;
		handlers[RemixerConstants.IMPORT_FAILS] = this._setImportFailed;
		handlers[RemixerConstants.RECEIVE_IMPORT_AUTH_EXPIRED] = this._setImportFailed;

		return handlers;
	}
});

module.exports = ImportStore;
