import React from 'react';

import RouteStore from '../stores/RouteStore';

export default class WebsitePageList extends React.Component {
	static propTypes = {
		activePageProject: React.PropTypes.object.isRequired,
		pageProjectList: React.PropTypes.object.isRequired,
		website: React.PropTypes.object.isRequired,
		onDelete: React.PropTypes.func.isRequired,
		onClone: React.PropTypes.func.isRequired
	};

	render() {
		return (
			<div>
				{this.props.pageProjectList.entrySeq().map(([key, pageProject]) => {
					return (
						<div key={key} title="Edit" className={"clearfix page-project-list" + (pageProject.id == this.props.activePageProject.id ? ' active-page' : '')} onClick={() => RouteStore.getState('route').transitionTo("layout", {pageProjectId: pageProject.id, websiteId: this.props.website.id})}>
							<div className="left" >
								<span className="page-title"><i className="mdi icon mdi-drag" /><span className="title">{pageProject.title}</span></span> {pageProject.id == this.props.activePageProject.id ? <span className='now-editing'>(now editing)</span> : ''}
							</div>
							<div className="right">
								<a onClick={(e) => {e.stopPropagation(); this.props.onClone(pageProject)}} title="Clone"><i className="mdi mdi-content-copy" /></a>
								{(this.props.website.mainPageProjectId != pageProject.id) ?
									<a onClick={(e) => {e.stopPropagation(); this.props.onDelete(pageProject)}} title="Delete"><i className="mdi icon mdi-delete" /></a>
									:
									<i className="mdi icon mdi-delete disabled" />
								}
							</div>
						</div>
					);
				}, this)}
			</div>
		);
	}
}
