import React from 'react';
import {Container} from 'flux/utils';

import WebsitesStore from '../../stores/WebsitesStore';
import SitePublisherStore from '../../stores/SitePublisherStore';
import TrialStatusStore from '../../stores/TrialStatusStore';

import PublishStatuses from '../../constants/PublishStatuses';

var classnames = require('classnames');
var RouteStore = require('../../stores/RouteStore');

var PublishActionsCreator = require('../../actions/PublishActionsCreator');

class Websites extends React.Component {
	static propTypes = {
		websiteId: React.PropTypes.number.isRequired
	}
	static getStores() {
		return [WebsitesStore, SitePublisherStore, TrialStatusStore];
	}
	static calculateState(prevState, props){
		let sitePublisher = SitePublisherStore.getState().get(props.websiteId)
		let publishingStage = prevState && prevState.publishingStage
		if (sitePublisher === PublishStatuses.PUBLISHING && prevState && prevState.sitePublisher !== PublishStatuses.PUBLISHING){
			publishingStage = 0
		}
		return {
			website: WebsitesStore.getState().get(props.websiteId),
			sitePublisher: sitePublisher,
			publishingStage: publishingStage,
			trialStatus: TrialStatusStore.getState()
		};
	}

	componentDidMount() {
		this.publishingStageUpdater = setInterval(() => {if(this.state.sitePublisher === PublishStatuses.PUBLISHING) {this.setState({publishingStage: this.state.publishingStage + 1})}}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.publishingStageUpdater);
	}

	handleStartPublish(websiteId) {
		PublishActionsCreator.startPublishSite(websiteId);
	}

	publishingText() {
		var publishingStages = [
			"Publishing Started",
			"Contacting Servers",
			"Exporting Images",
			"Joining Chords",
			"Bundling CSS",
			"Admiring Page",
			"Finishing Touches"
		];
		var i = this.state.publishingStage < publishingStages.length ? this.state.publishingStage : publishingStages.length - 1;
		var text = publishingStages[i];
		return (text);
	}

	render() {
		switch(this.state.sitePublisher) {
			case PublishStatuses.PUBLISHING:
				var publishStatusMessage = 'Publishing...';
				var publishStatus = ' publishing';
				var publishStatusIcon = ' mdi-autorenew rotate';
				var toolTip = '';
				break;
			case PublishStatuses.FAILED:
				publishStatusMessage = 'Publishing Failed';
				publishStatus = ' hidden';
				publishStatusIcon = ' mdi-alert error';
				toolTip = <span className="form-tool-tip-text" >Oops, there was a problem publishing this page. Please retry.</span>;
				break;
			case PublishStatuses.PUBLISHED:
			default:
				publishStatusMessage = 'Publish';
				publishStatus = ' hidden';
				publishStatusIcon = ' mdi-cloud-upload';
				toolTip = '';
				break;
		}

		return (
			<div className="website-block-button">
				<ul className={classnames({
					'small-block-grid-3': true,
					'button-padding': true })}>
					<li className="website-block-actions">
						<a href={RouteStore.getState().route.makeHref('layout', { websiteId: this.props.websiteId})} className="website-block-actions-button">
							<i className="mdi mdi-lead-pencil"></i>
							<p >Edit</p>
						</a>
					</li>

					<li className="website-block-actions">
						<a href={RouteStore.getState().route.makeHref("websiteSettings",  { websiteId: this.props.websiteId})} className="website-block-actions-button">
							<i className="mdi mdi-settings"></i>
							<p>Settings</p>
						</a>
					</li>
					<li className="website-block-actions form-tool-tip">
						{toolTip}
						<div className="website-block-actions-button" onClick={() => this.handleStartPublish(this.props.websiteId)}>
							<i className={"mdi" + publishStatusIcon}></i>
							<p>{publishStatusMessage}</p>
							<div className={"website-block-publish-flavor" + publishStatus}>
								<p>{this.publishingText()}</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		);
	}
}

export default Container.create(Websites, {withProps: true});
