'use strict';

import { noop, delay } from 'lodash';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');

var ComponentContextStore = require('../stores/ComponentContextStore');
var PageDomStateStore = require('../stores/PageDomStateStore');

var Popover = require('./common/Popover.react');
var ContentOverlay = require('./common/ContentOverlay.react');
var TextTools = require('./common/texttools/TextTools.react');
// var ImagePicker = require('./inputs/ImagePicker.react');
var MarkdownPicker = require('./inputs/MarkdownInput.react');
var VideoPicker = require('./inputs/VideoPicker.react').default;
var AudioPicker = require('./inputs/AudioPicker.react');
var MapPicker = require('./inputs/MapPicker.react');
var LinkPicker = require('./inputs/LinkPicker/LinkPicker.react');
var BackgroundColorPicker = require('./inputs/BackgroundColorPicker.react');

var DomElementUtils = require('../utils/DomElementUtils');

/** @module InlinePropertyEmulator
 * @requires lodash
 * @requires jquery
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentActionsCreator
 * @requires ComponentContextStore
 * @requires PageDomStateStore
 * @requires Popover
 * @requires ContentOverlay
 * @requires TextTools
 * @requires ImagePicker
 * @requires MarkdownInput
 * @requires VideoPicker
 * @requires AudioPicker
 * @requires MapPicker
 * @requires LinkPicker
 */
var InlinePropertyEmulator = ComponentFactory.Create(ComponentContextStore, PageDomStateStore, {
	displayName: "InlinePropertyEmulator",
	propTypes: {
		pageId: React.PropTypes.number.isRequired,
		onTextCommand: React.PropTypes.func
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var state = {
			component: ComponentContextStore.getState('active'),
			pageDom: PageDomStateStore.getState(this.props.pageId),
			activeTextManipulation: ComponentContextStore.getState('activeTextManipulation'),
			activeImagePicker: ComponentContextStore.getState('activeImagePicker'),
			activeMarkdownPicker: ComponentContextStore.getState('activeMarkdownPicker'),
			activeVideoPicker: ComponentContextStore.getState('activeVideoPicker'),
			activeAudioPicker: ComponentContextStore.getState('activeAudioPicker'),
			activeMapPicker: ComponentContextStore.getState('activeMapPicker'),
			activeLinkPicker: ComponentContextStore.getState('activeLinkPicker'),
			activeColorPicker: ComponentContextStore.getState('activeColorPicker'),
			hideContentOverlay: false
		};

		var activeProperty = state.activeTextManipulation || state.activeImagePicker ||
			state.activeMarkdownPicker || state.activeVideoPicker || state.activeAudioPicker || state.activeMapPicker
			|| state.activeLinkPicker;
		if (activeProperty) {
			state.componentRectangle = PageDomStateStore.getComponentRectangle(this.props.pageId, activeProperty.componentId);
			state.activeRectangle = PageDomStateStore.getPropertyRectangle(this.props.pageId, activeProperty.componentId, activeProperty.path);
		}

		if (state.pageDom) {
			state.boundaryRectangle = state.pageDom.page.clone();
			state.boundaryRectangle.size.width = window.innerWidth - 330;// 330px - sidebar width (with website/page settings)
			state.selection = state.pageDom.selection;
		}

		if (state.activeColorPicker) {
			state.componentRectangle = PageDomStateStore.getComponentRectangle(this.props.pageId, state.component.id);
			state.activeRectangle = state.componentRectangle.clone();
			state.activeRectangle.position.left = state.activeRectangle.right() - 150
			state.activeRectangle.size.height = 30;
			state.activeRectangle.size.width = 51;
		}

		return state;
	},

	/**
	 * @method _popupOpen
	 */
	_popupOpen: function(positionTop) {
		if (this.state.activeImageManipulation ||
			this.state.activeImagePicker ||
			this.state.activeVideoPicker ||
			this.state.activeAudioPicker ||
			this.state.activeMapPicker ||
			this.state.activeLinkPicker ||
			this.state.activeColorPicker) {
			delay(function() {
				DomElementUtils.scrollDocumentTop(positionTop - 50);
			}, 100);
		}
	},

	/**
	 * @method _popupClose
	 */
	_popupClose: function() {
		ComponentActionsCreator.stopMarkdownPicker();
		ComponentActionsCreator.stopImagePicker();
		ComponentActionsCreator.endTextManipulation();
		ComponentActionsCreator.stopVideoPicker();
		ComponentActionsCreator.stopAudioPicker();
		ComponentActionsCreator.stopMapPicker();
		ComponentActionsCreator.stopLinkPicker();
		ComponentActionsCreator.stopColorPicker();
	},

	/**
	 * @method _onImageChange
	 */
	// _onImageChange: function(newUrl, closePicker) {
	// 	if(!this.state.activeImagePicker) return;

	// 	var component = clone(this.state.component);
	// 	var path = this.state.activeImagePicker.path;
	// 	var stylePath = path.replace(new RegExp('url$'), 'style');
	// 	var url = get(component.context, path);
	// 	var style = get(component.context, stylePath, {});
	// 	if(!isEqual(newUrl, url)) {
	// 		set(component.context, path, newUrl);
	// 		set(component.context, stylePath, {'background-position': '0 0'})
	// 		var img = new Image();
	// 		img.onload = function() {
	// 			ComponentActionsCreator.updateComponentInstanceContext(component);
	// 		};
	// 		img.src = newUrl;
	// 		if (closePicker) ComponentActionsCreator.stopImagePicker();
	// 	}
	// },

	/**
	 * @method _showOverlay
	 */
	_showOverlay: function() {
		this.setState({ overlay: "overlay" });
	},

	/**
	 * @method _hideOverlay
	 */
	_hideOverlay: function() {
		this.setState({ overlay: null });
	},

	/**
	 * @method closeOverlay
	 */
	closeOverlay: function() {
		this.setState({ showContentOverlay: false });
	},

	openContentOverlay: noop,

	/**
	 * @method _closeContentOverlay
	 */
	_closeContentOverlay: function() {
		ComponentActionsCreator.stopImagePicker();
	},

	/**
	 * @method render
	 */
	render: function() {
		var popup;
		var contentOverlay;

		if (this.state.activeTextManipulation) {
			popup = (<TextTools
				key={this.state.activeTextManipulation.path}
				pageId={this.props.pageId}
				propertyInfo={this.state.activeTextManipulation}
				selection={this.state.selection}
				onCommand={this.props.onTextCommand}/>);
		}

		// if (this.state.activeImagePicker) {
		// 	var url = get(this.state.component.context, this.state.activeImagePicker.path);
		// 	contentOverlay = (<ImagePicker
		// 		mykey={this.state.activeImagePicker.path}
		// 		imageUrl={url}
		// 		onChangeUrl={this._onImageChange}
		// 		propertyName={this.state.activeImagePicker.name}
		// 		propertyPath={this.state.activeImagePicker.path}
		// 		propertyType={this.state.activeImagePicker.type}
		// 		componentRectangle={this.state.componentRectangle}
		// 		targetRectangle={this.state.activeRectangle}
		// 		boundaryRectangle={this.state.boundaryRectangle}
		// 		closeContentOverlay={this._closeContentOverlay} />);
		// }

		if (this.state.activeVideoPicker) {
			popup = (<VideoPicker
				key={this.state.activeVideoPicker.path}
				propertyName={this.state.activeVideoPicker.name}
				propertyType={this.state.activeVideoPicker.type} />);
		}

		if (this.state.activeAudioPicker) {
			popup = (<AudioPicker
				key={this.state.activeAudioPicker.name}
				propertyName={this.state.activeAudioPicker.name}
				propertyType={this.state.activeAudioPicker.type} />);
		}

		if (this.state.activeMapPicker) {
			popup = (<MapPicker
				key={this.state.activeMapPicker.name}
				propertyName={this.state.activeMapPicker.name}
				propertyType={this.state.activeMapPicker.type} />);
		}

		if (this.state.activeLinkPicker) {
			popup = (<LinkPicker
				key={this.state.activeLinkPicker.name}
				pageId = {this.props.pageId}
				propertyName={this.state.activeLinkPicker.name}
				propertyType={this.state.activeLinkPicker.type}
				selectedValuePath={this.state.activeLinkPicker.path} />);
		}

		if (this.state.activeMarkdownPicker) {
			contentOverlay = (
				<MarkdownPicker
					key={this.state.activeMarkdownPicker.path}
					propertyName={this.state.activeMarkdownPicker.name}
					propertyType={this.state.activeMarkdownPicker.type}
					componentRectangle={this.state.componentRectangle} />
			);
		}

		if (this.state.activeColorPicker) {
			popup = (
				<BackgroundColorPicker key={this.state.activeColorPicker.name} property={this.state.activeColorPicker} component={this.state.component}/>
			);
		}

		var showPopup = (popup && this.state.activeRectangle);
		if (showPopup) {
			return (
				<div>
					<div className={this.state.overlay}></div>
					<Popover key={popup.key} className={this.css("closable-popup",
						{
							"video-picker-popup": this.state.activeVideoPicker,
							"audio-picker-popup": this.state.activeAudioPicker,
							"map-picker-popup": this.state.activeMapPicker,
							"link-picker-popup": this.state.activeLinkPicker,
							"color-picker-popup": this.state.activeColorPicker
						})}
					onClose={this._popupClose}
					onOpen={this._popupOpen}
					targetRectangle={this.state.activeRectangle}
					boundaryRectangle={this.state.boundaryRectangle}>
						{popup}
					</Popover>
				</div>
			);
		}

		var showContentOverlay = (contentOverlay && this.state.activeRectangle);
		if (showContentOverlay) {
			return (
				<ContentOverlay
					onClose={this.closeContentOverlay}
					onOpen={this.openContentOverlay}
					show={!this.state.hideContentOverlay}
					componentRectangle={this.state.componentRectangle}>
					{contentOverlay}
				</ContentOverlay>
			);
		}

		return null;
	}
});

module.exports = InlinePropertyEmulator;
