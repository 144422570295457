import Immutable from 'immutable';

import PublishStatuses from '../../constants/PublishStatuses';

var Mapper = require('../../utils/Mapper');

const Record = Immutable.Record({
	id: null,
	name: "",
	basePageId: null,
	mainPageProjectId: null,
	ga: "",
	allowRobots: true,
	domain: null,
	publicUrl: null,
	edited: null,
	published: null,
	created: null,
	updated: null,
	exported: null,
	wpInstallId: null,
	status: PublishStatuses.NOT_PUBLISHED
});

const statusCheck = function(values) {
	// status should be whichever was done most recently (editing, publishing, or exporting)
	let actions = [{time: values.edited || "", action: PublishStatuses.NOT_PUBLISHED},
		{time: values.exported || "", action: PublishStatuses.WPEXPORTED},
		{time: values.published || "", action: PublishStatuses.PUBLISHED}
	]
	actions.sort((a, b) => {
		if (a.time < b.time) {
			return 1;
		}
		if (a.time > b.time) {
			return -1;
		}
		return 0;
	})
	return actions[0].action
}

class WebsiteRecord extends Record{
	static fromDTO(json) {
		return new WebsiteRecord(Mapper.toCamelCase(json));
	}
	constructor(values) {
		values.status = statusCheck(values)
		super(values);
	}
	toDTO() {
		return Mapper.toSnakeCase(this.toJS());
	}

}

export default WebsiteRecord;
