'use strict';

var React = require('react');
var ComponentFactory = require('../infrastructure/ComponentFactory');

var ComponentActionsCreator = require('../actions/ComponentActionsCreator');

/** @module UndoRedo
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentActionsCreator
 */
var UndoRedo = ComponentFactory.Create({
	displayName: "UndoRedo",
	propTypes: {
		pageId: React.PropTypes.number.isRequired
	},

	/**
	 * @method _undo
	 */
	_undo: function() {
		ComponentActionsCreator.movePageRevision(this.props.pageId, -1);
	},

	/**
	 * @method _redo
	 */
	_redo: function() {
		ComponentActionsCreator.movePageRevision(this.props.pageId, +1);
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="undo-redo-tools">
				<div className="undo-button" onClick={this._undo}>
					<i className="mdi mdi-reload mirrorX"></i>
					<p>undo</p>
				</div>
				<div className="redo-button" onClick={this._redo}>
					<i className="mdi mdi-reload"></i>
					<p>redo</p>
				</div>
			</div>
		);
	}
});

module.exports = UndoRedo;
