'use strict';

var AppDispatcher = require('../dispatcher/AppDispatcher');
var RemixerConstants = require('../constants/RemixerConstants');
var UrlUtils = require('../utils/UrlUtils');

/** @module FacebookServerActionsCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires UrlUtils
 */
var FacebookServerActionsCreator = {
	/**
	 * @method receiveFacebookAuthorizationUrl
	 */
	receiveFacebookAuthorizationUrl: function(result) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_FACEBOOK_AUTHORIZATION_URL,
			result: result.data
		});

		UrlUtils.navigateTo(result.data.authorization_url);
	},
	/**
	 * @method receiveFacebookAuthorizationUrlFails
	 */
	receiveFacebookAuthorizationUrlFails: function(error) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.RECEIVE_FACEBOOK_AUTHORIZATION_URL_FAILS,
			message: 'could not get Facebook authorization url'
		});
	}
};

module.exports = FacebookServerActionsCreator;
