'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var ComponentFactory = require('../../infrastructure/ComponentFactory');
var Markdown = require('react-remarkable');
var PageStore = require('../../stores/PageStore');
var ContentStore = require('../../stores/ContentStore');
var ContentActionsCreator = require('../../actions/ContentActionsCreator');
var ContentTypes = require('../../constants/ContentTypes');
var FlashMessageActionsCreator = require('../../actions/FlashMessageActionsCreator');
var $ = require('jquery');
import { get, find, debounce } from 'lodash';

var setScrollPositionDebounced;
var handleWindowResizeDebounced;

/** @module MarkdownPage
 * @requires react
 * @requires react-dom
 * @requires ComponentFactory
 * @requires react-remarkable
 * @requires PageStore
 * @requires ContentStore
 * @requires ContentActionsCreator
 * @requires ContentTypes
 * @requires FlashMessageActionsCreator
 * @requires jquery
 * @requires lodash
 */
var MarkdownPage = ComponentFactory.Create(ContentStore, PageStore, {
	displayName: "MarkdownPage",

	/**
	 * @method getState
	 */
	getState: function() {
		var content;
		var id = get(this, ['state', 'id']) || get(this.props, ['contentItem', 'id']);
		var contentState = ContentStore.getState(ContentTypes.MARKDOWN);

		if (id && contentState) content = find(contentState, { id: id });

		// use latest
		var name = get(this, ['refs', 'name']);
		var entry = get(this, ['refs', 'entry']);
		if (name) name = ReactDOM.findDOMNode(name).value;
		if (entry) entry = ReactDOM.findDOMNode(entry).value;

		return {
			id: id,
			name: name || get(content, 'name'),
			source: entry || get(content, 'markdown')
		};
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		setScrollPositionDebounced = debounce(this.setScrollPosition, 100, { leading: false, trailing: true });
		var tx = ReactDOM.findDOMNode(this.refs.entry);

		var that = this;
		tx.addEventListener('scroll', setScrollPositionDebounced);
		handleWindowResizeDebounced = debounce(function() {
			that.setVertical(ReactDOM.findDOMNode(that.refs.entry));
			that.setVertical(ReactDOM.findDOMNode(that.refs.markdownContainer));
		}, 100);
		window.addEventListener('resize', handleWindowResizeDebounced);
		handleWindowResizeDebounced();
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function() {
		ContentActionsCreator.loadContent(ContentTypes.MARKDOWN);
		if (this.state.id && this.state.id !== get(this.props, ['contentItem', 'id'])) {
			var that = this;
			setTimeout(function() {
				that.route().transitionTo('content', {tab: 'markdown', contentId: that.state.id});
			}, 1);
		}
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function() {
		var tx = ReactDOM.findDOMNode(this.refs.entry);
		tx.removeEventListener('scroll', setScrollPositionDebounced);
		window.removeEventListener('resize', handleWindowResizeDebounced);
	},

	/**
	 * @method componentWillReceiveProps
	 */
	componentWillReceiveProps: function(nextProps) {
		// this is how we figure out if the post action worked without an event listener
		if (nextProps.content.length > this.props.content.length) {
			this.setState({ id: nextProps.content[0].id });
		}
	},

	/**
	 * @method setVertical
	 */
	setVertical: function(el) {
		var ih = window.innerHeight;
		var rect = el.getBoundingClientRect();
		el.style.height = (ih - rect.top - 66) + 'px';
	},

	/**
	 * @method setScrollPosition
	 */
	setScrollPosition: function() {
		var mk = ReactDOM.findDOMNode(this.refs.markdownContainer);
		var tx = ReactDOM.findDOMNode(this.refs.entry);
		var scrollPercentage = tx.scrollTop / tx.getBoundingClientRect().height;
		$(mk).stop().animate({ scrollTop: mk.getBoundingClientRect().height * scrollPercentage }, 'swing');
	},

	/**
	 * @method onKeyUp
	 */
	onKeyUp: function() {
		this.setState({ source: ReactDOM.findDOMNode(this.refs.entry).value });
	},

	/**
	 * @method save
	 */
	save: function() {
		if (/^[\s]*$/.test(this.state.name)) {
			FlashMessageActionsCreator.addFlashMessage({ text: 'Please enter a document name.', level: 'error' });
			return;
		}
		var payload = {
			name: ReactDOM.findDOMNode(this.refs.name).value,
			markdown: ReactDOM.findDOMNode(this.refs.entry).value,
			content: get(ReactDOM.findDOMNode(this.refs.markdown), ['children', '0', 'innerHTML'])
		};
		if (this.state.id) {
			ContentActionsCreator.putContent(this.state.id, ContentTypes.MARKDOWN, payload);
		} else {
			ContentActionsCreator.postContent(ContentTypes.MARKDOWN, payload);
		}
	},

	/**
	 * @method nameChange
	 */
	nameChange: function(ev) {
		this.setState({ name: ev.target.value });
	},

	/**
	 * @method sourceChange
	 */
	sourceChange: function(ev) {
		this.setState({ source: ev.target.value });
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className="markdown-page content-edit">
				<div className="row">
					<div className="columns small-12">
						<h1>Markdown</h1>
					</div>
					<div className="columns small-3">
						<button onClick={this.save} className="right">Save</button>
					</div>
				</div>
				<div className="row">
					<div className="columns small-12">
						<input onChange={this.nameChange} ref="name" value={this.state.name} maxLength="255" type="text" placeholder="Enter Name of Text Document" />
					</div>
				</div>
				<div className="row">
					<div className="columns small-6">
						<h5>Entry</h5>
						<textarea onChange={this.sourceChange} className="markdown-entry entry" ref="entry" value={this.state.source} onKeyUp={this.onKeyUp}></textarea>
					</div>
					<div className="columns small-6">
						<h5>Preview</h5>
						<div ref="markdownContainer" className="markdown">
							<div className="markdown-inner">
								<Markdown options={{ html: false, typographer: true, linkify: true }} ref="markdown" container="div" source={this.state.source} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
});

module.exports = MarkdownPage;
