'use strict';

import { includes, map } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../../infrastructure/ComponentFactory');

var Thumbnail = require('../content-items/Thumbnail.react');
var InfiniteFlexbox = require('../../common/InfiniteFlexbox.react');

/** @module Mosaic
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires Thumbnail
 * @requires InfiniteFlexbox
 */
var Mosaic = ComponentFactory.Create({
	displayName: "Mosaic",
	propTypes: {
		content: React.PropTypes.array.isRequired,
		contentItem: React.PropTypes.object,
		picked: React.PropTypes.array, //array of IDs
		onPick: React.PropTypes.func,
		onDelete: React.PropTypes.func,
		itemWidth: React.PropTypes.number,
		itemHeight: React.PropTypes.number,
		useInfinite: React.PropTypes.bool,
		loading: React.PropTypes.bool
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			containerHeight: 0,
			containerWidth: 0,
			columnItemCount: 0,
			preloadHeight: 0
		};
	},

	/**
	 * @method _isPicked
	 */
	_isPicked: function(contentItem) {
		return includes(this.props.picked, contentItem.id);
	},

	/**
	 * @method render
	 */
	render: function() {
		var that = this;
		var css = {
			"image-content-list": true,
			"images-picker": true
		};
		var content = map(that.props.content, function(contentItem) {
			return (
				<Thumbnail
					key={contentItem.id}
					itemId={contentItem.id}
					contentItem={contentItem}
					picked={that._isPicked(contentItem)}
					onPick={that.props.onPick}
					onDelete={that.props.onDelete}
				/>
			);
		})
		var whatToRender = (this.props.useInfinite) ?
			<InfiniteFlexbox itemWidth={that.props.itemWidth} itemHeight={that.props.itemHeight}>{content}</InfiniteFlexbox> : content;
		return (
			<div className={that.css(that.props.className, css)}>
				<div className="images-picker-inner">
					{whatToRender}
				</div>
			</div>
		);
	}
});

module.exports = Mosaic;
