import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';

import WebAPI from '../utils/WebAPI';

export default {
	search(query, page=1) {
		WebAPI.searchVimeo(query, page);
		AppDispatcher.dispatch({
			actionType: RemixerConstants.SEARCH_VIMEO,
			query,
			page
		});
	}
};
