'use strict';

var _ = require('lodash');
var React = require('react');
var ReactDOM = require('react-dom');
var StickyPopover = require('../common/StickyPopover.react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ImportTypes = require('../../constants/ImportTypes');

var ImportProgressStore = require('../../stores/ImportProgressStore');

var ImportActionsCreator = require('../../actions/ImportActionsCreator');
var ContentStore = require('../../stores/ContentStore');
var ContentTypes = require('../../constants/ContentTypes');

/** @module PageImport
 * @requires lodash
 * @requires react
 * @requires react-dom
 * @requires StickyPopover
 * @requires ComponentFactory
 * @requires ImportTypes
 * @requires ImportProgressStore
 * @requires ImportActionsCreator
 * @requires ContentStore
 * @requires ContentTypes
 */
var PageImport = ComponentFactory.Create(ImportProgressStore, ContentStore, {
	displayName: "PageImport",

	/**
	 * @method getInitialState
	 */
	getInitialState: function() {
		var state = this.getState();
		state.textFieldValue = this.route().query.url;
		return state;
	},

	/**
	 * @method getState
	 */
	getState: function() {
		var state = {
			importProgress: ImportProgressStore.getState(ImportTypes.PAGE),

			importedFileCount: _(ContentStore.getState())
				.values()
				.flatten()
				.filter((x) => _.isEqual(x.import_type, ImportTypes.PAGE))
				.filter((x) => _.isEqual(x.content_type, ContentTypes.IMAGE))
				.size()
		};
		state.loading = state.importProgress && state.importProgress.progress;
		return state;
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		this.setState({element: ReactDOM.findDOMNode(this)});
	},

	/**
	 * @method _showToolbar
	 */
	_showToolbar: function(e) {
		var width = ReactDOM.findDOMNode(this).offsetWidth;
		this.setState({rel: {width: width}});
		this.setState({popup: true});
	},

	/**
	 * @method _onClick
	 */
	_onClick: function(e) {
		this._startImport();
	},

	/**
	 * @method _handleKeyDown
	 */
	_handleKeyDown: function(e) {
		var ENTER = 13;
		if(e.keyCode === ENTER || e.which === 13) {
			this._startImport();
		}
	},

	/**
	 * @method _startImport
	 */
	_startImport: function() {
		if(this.state.textFieldValue && !(this.state.importProgress && this.state.importProgress.progress)) {
			ImportActionsCreator.startImport(ImportTypes.PAGE, { url: this.state.textFieldValue });
			this._close();
		}
	},

	/**
	 * @method _close
	 */
	_close: function() {
		this.setState({popup: false});
	},

	/**
	 * @method _onChangeTextField
	 */
	_onChangeTextField: function (ev) {
		this.setState({ textFieldValue: ev.target.value });
	},

	/**
	 * @method _onMouseEnter
	 */
	_onMouseEnter: function() {
		this.setState({mouseOver: true});
	},

	/**
	 * @method _onMouseLeave
	 */
	_onMouseLeave: function() {
		this.setState({mouseOver: false});
	},

	/**
	 * @method render
	 */
	render: function () {
		var loading = this.state.loading;
		var popupForm = <div className="import-website">
			<div>
				<p className="import-website-description">Enter any site URL below to begin importing the site&#39;s images</p>
				<div className="import-website-body">
					<input
						className="website-url small-input"
						onKeyDown={this._handleKeyDown}
						onChange={this._onChangeTextField}
						value={this.state.textFieldValue}
						type="text"
						placeholder="www.yoursite.com"
						autoFocus />
				</div>
				<div className="row">
					<div className="large-6 columns">
						<p className="import-website-disclaimer">Images &amp; content may be subject to copyright.</p>
					</div>
					<div className="large-6 columns">
						<div
							className="button medium radius success"
							onClick={this._startImport}
							disabled={!this.state.textFieldValue}>
							<p style={{color: "#fff"}}>Import</p>
						</div>
					</div>
				</div>
			</div>
		</div>;

		var popup = this.state.popup ?
			<StickyPopover className={this.css("closable-popup", "import-popup", "page")}
				onClose={this._close}
				target={this.state.element}>
				<div className="title-bar row">
					<div className="large-6 columns">
						<p className="title">Import a website</p>
					</div>
				</div>
				{popupForm}
			</StickyPopover> :
			<div></div>;

		var iconCss = ['import-icon-img website', { pulse: loading }];
		var css = 'import-header-column import-header-column-button bg-overlay website';
		return (
			<div className={this.css(css)} >
				<div className="button-inner" onClick={this._showToolbar}>
					<div className="show-for-large-up import-website-label">
						<p>{this.props.title}</p>
						<h3>{this.props.tagline}</h3>
					</div>
					<div className="import-icon-holder">
						<div className={this.css(iconCss)}></div>
						<div className="import-icon-bubble-holder">
							<div className="bubbleNumber active">
								<div className="bubbleLeft dh"></div>
								<div className="bubbleRight dh"></div>
								<div className="bubbleText dh">
									<p>{this.state.importedFileCount}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{popup}
			</div>
		);
	}
});

module.exports = PageImport;
