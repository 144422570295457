import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';


const VimeoSearchParams = Immutable.Record({
	query: "",
	page: 1
});


class VimeoSearchParamsStore extends ReduceStore<VimeoSearchParams> {
	getInitialState(): VimeoSearchParams {
		return new VimeoSearchParams();
	}

	reduce(state: VimeoSearchParams, action: Object): VimeoSearchParams {
		const {actionType, query, page} = action;
		switch (actionType) {
			case RemixerConstants.SEARCH_VIMEO: return state.merge({query, page});
			default: return state;
		}
	}
}

export default new VimeoSearchParamsStore(AppDispatcher);
