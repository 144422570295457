'use strict';

var $ = require('jquery');
import { isError, attempt } from 'lodash';

var React = require('react');
var ComponentFactory = require('../../infrastructure/ComponentFactory');

var TagsInput = require('react-tagsinput');

/** @module Overlay
 * @requires jquery
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires react-tagsinput
 */
var Overlay = ComponentFactory.Create({
	displayName: "Overlay",
	propTypes: {
		title: React.PropTypes.string,
		onClose: React.PropTypes.func,
		onNext: React.PropTypes.func,
		onPrevious: React.PropTypes.func,
		onDelete: React.PropTypes.func,
		onChangeTags: React.PropTypes.func,
		tags: React.PropTypes.array
	},

	/**
	 * @method componentDidMount
	 */
	componentDidMount: function() {
		$(document.body).keydown(this._onKeyDown);
	},

	/**
	 * @method componentWillUnmount
	 */
	componentWillUnmount: function() {
		$(document.body).unbind('keydown', this._onKeyDown);
	},

	/**
	 * @method _onKeyDown
	 */
	_onKeyDown: function(ev) {
		var actions = {
			37: this.props.onPrevious,//ArrowLeft
			39: this.props.onNext,//ArrowRight
			27: this.props.onClose//Escape
		};
		if (!isError(attempt(actions[ev.keyCode]))) {
			ev.preventDefault();
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		return (
			<div className={this.css("remixer-overlay", this.props.className)}>
				<div className="row overlay-header">
					<div className="small-10 column">
						{this.props.title}
					</div>
					<div className="small-2 column">
						<a onClick={this.props.onClose} className="close right"></a>
					</div>
				</div>
				<div className="row overlay-container">
					<div className="small-12 column overlay-content">
						<div className="row content-panel">
							<div className="small-12 columns content-wrapper">
								{this.props.children}
								{this.props.onPrevious ?
									<a onClick={this.props.onPrevious} className="overlay-navigation previous"></a>
									:
									null
								}
								{this.props.onNext ?
									<a onClick={this.props.onNext} className="overlay-navigation next"></a>
									:
									null
								}
							</div>
						</div>
					</div>
				</div>
				<div className="row overlay-footer">
					<div className="small-12 column actions-panel">
						{this.props.onChangeTags ?
							<div><div className="tags-label left"><i className="mdi mdi-tag-multiple"></i> Tags:</div>
								<TagsInput value={this.props.tags || []} onChange={this.props.onChangeTags} />
							</div>
							:
							null
						}
						{this.props.onDelete ?
							<a onClick={this.props.onDelete} className="delete-button right"><i className="mdi mdi-delete"></i><span>Delete</span></a>
							:
							null
						}
					</div>
				</div>
			</div>
		);
	}
});

module.exports = Overlay;
