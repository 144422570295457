'use strict';

import { assign, forOwn, cloneDeepWith } from 'lodash';

/** @module Enum
 * @requires lodash
 */
function Enum() {
}

Enum.prototype.createEnumEntry = function (name, fields, constructorFunction) {

	var entry = constructorFunction ? new constructorFunction() : {};
	entry.name = name;

	entry.toString = function () {
		return entry.name;
	};
	if (fields) {
		assign(entry, fields);
	}
	return entry;
};

Enum.prototype.fromName = function fromName(name) {
	var enumValue = name;
	forOwn(this, function (value) {
		if (value && value.name && value.name === name) {
			enumValue = value;
			return false;
		}
	});
	return enumValue;
};

Enum.prototype.toName = function toName(value) {
	return value && value.name || value;
};

Enum.prototype.transformToEnum = function transformToEnum(obj, attributeName) {
	var self = this;
	return cloneDeepWith(obj, function (value, key) {
		if (key === attributeName) {
			return self.fromName(value);
		}
	});
};

Enum.prototype.transformToName = function transformToName(obj, attributeName) {
	var self = this;
	return cloneDeepWith(obj, function (value, key) {
		if (key === attributeName) {
			return self.toName(key);
		}
	});
};

module.exports = Enum;
