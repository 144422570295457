import AppDispatcher from '../dispatcher/AppDispatcher';
import RemixerConstants from '../constants/RemixerConstants';
import api2Client from '../utils/api2-client';

/** @module SignupActionCreator
 * @requires AppDispatcher
 * @requires RemixerConstants
 * @requires api2-client
 */
export default {

	createAccount: function(
		pageId,
		pageName,
		email,
		password,
		first_name,
		last_name,
		email_opt_in
	) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.REQUEST_NEW_ACCOUNT,
			pageId,
			pageName,
			email,
			password,
			first_name,
			last_name,
			email_opt_in
		},
		api2Client.createAccount, [
			pageId,
			pageName,
			email,
			password,
			first_name,
			last_name,
			email_opt_in
		]);
	},
	loginToAccount: function(
		pageId,
		pageName,
		webid,
		password
	) {
		AppDispatcher.dispatch({
			actionType: RemixerConstants.LOGIN_TO_ACCOUNT,
			pageId,
			pageName,
			webid,
			password
		},
		api2Client.loginToAccount, [
			pageId,
			pageName,
			webid,
			password
		]);
	}
};
