'use strict';

import { get, isEqual, omit, invokeMap, words } from 'lodash';

var React = require('react');

var ComponentFactory = require('../../infrastructure/ComponentFactory');

var ComponentContextStore = require('../../stores/ComponentContextStore');

var ComponentActionsCreator = require('../../actions/ComponentActionsCreator');

var PropertyInfo = require('../../models/PropertyInfo');

var PropertyTypes = require('../../constants/PropertyTypes');

var SystemLayerBreach = require('./SystemLayerBreach.react');

/** @module PropertySystemLayer
 * @requires lodash
 * @requires react
 * @requires ComponentFactory
 * @requires ComponentContextStore
 * @requires ComponentActionsCreator
 * @requires PropertyInfo
 * @requires PropertyTypes
 * @requires SystemLayerBreach
 */
var PropertySystemLayer = ComponentFactory.Create(ComponentContextStore, {
	displayName: "PropertySystemLayer",
	propTypes: {
		propertyInfo: React.PropTypes.instanceOf(PropertyInfo).isRequired,
		onFocusChange: React.PropTypes.func
	},

	/**
	 * @method getState
	 */
	getState: function() {
		return {
			isImagePickerActive: !!ComponentContextStore.getState('activeImagePicker'),
			active:
				ComponentContextStore.getState('activeTextManipulation') ||
				ComponentContextStore.getState('activeImagePicker') ||
				ComponentContextStore.getState('activeMarkdownPicker') ||
				ComponentContextStore.getState('activeVideoPicker') ||
				ComponentContextStore.getState('activeAudioPicker') ||
				ComponentContextStore.getState('activeMapPicker') ||
				ComponentContextStore.getState('activeLinkPicker')
		};
	},

	/**
	 * @method _isEqual
	 */
	_isEqual: function(first, second) {
		if (!(first instanceof PropertyInfo) || !(second instanceof PropertyInfo)) return false;

		return isEqual(
			omit(first, ["rectangle", "relativeRectangle"]),
			omit(second, ["rectangle", "relativeRectangle"])) &&
			isEqual(
				invokeMap([first], ["rectangle", "toObject"]),
				invokeMap([second], ["rectangle", "toObject"]));
	},

	/**
	 * @method _isInFocus
	 */
	_isInFocus: function() {
		if (!this.state.active || this.state.active.componentId !== this.props.propertyInfo.componentId) {
			return false;
		}

		//NOTE: we have to make all properties as in focus in case of images array picker
		var isSameImagesArrayActive =
			this.state.isImagePickerActive &&
			this.state.active.name === this.props.propertyInfo.name &&
			this.state.active.type === PropertyTypes.arrayOfImage;

		return isSameImagesArrayActive ? isSameImagesArrayActive :
			this.state.active.path === this.props.propertyInfo.path;
	},

	/**
	 * @method componentDidUpdate
	 */
	componentDidUpdate: function(props, state) {
		var isStateChanged = !isEqual(this.state.active, get(state, 'active'));
		var isPropsChanged = !this._isEqual(this.props.propertyInfo, get(props, 'propertyInfo'));

		if (isStateChanged || isPropsChanged) {
			this.setState({ focus: this._isInFocus() });
		}

		if (isPropsChanged) {
			this.setState({
				breach: PropertyTypes.isTextPropertyType(this.props.propertyInfo.type),
				tooltip: this._getTooltip(this.props.propertyInfo.type),
				empty: this.props.propertyInfo.relativeRectangle.size.height === 0
			});
		}
	},

	/**
	 * @method _getTooltip
	 */
	_getTooltip: function(type) {
		if (PropertyTypes.isTextPropertyType(type)) {
			return "text";
		}

		if (PropertyTypes.isImagePropertyType(type)) {
			return "image";
		}

		if (PropertyTypes.isVideoPropertyType(type)) {
			return "video";
		}

		if (PropertyTypes.isMarkdownPropertyType(type)) {
			return "markdown";
		}

		if (PropertyTypes.isAudioPropertyType(type)) {
			return "audio";
		}

		if (PropertyTypes.isMapPropertyType(type)) {
			return "map";
		}

		if (PropertyTypes.isLinkPropertyType(type)) {
			return "link";
		}

		return words(type).join(' ');
	},

	/**
	 * @method _onFocusChange
	 */
	_onFocusChange: function(focus) {
		if (this.props.onFocusChange) this.props.onFocusChange(focus);

		if (focus) {
			if (PropertyTypes.isTextPropertyType(this.props.propertyInfo.type)) {
				ComponentActionsCreator.startTextManipulation(this.props.propertyInfo);
			}

			if (PropertyTypes.isImagePropertyType(this.props.propertyInfo.type)) {
				ComponentActionsCreator.startImagePicker(this.props.propertyInfo);
			}

			if (PropertyTypes.isVideoPropertyType(this.props.propertyInfo.type)) {
				ComponentActionsCreator.startVideoPicker(this.props.propertyInfo);
			}

			if (PropertyTypes.isAudioPropertyType(this.props.propertyInfo.type)) {
				ComponentActionsCreator.startAudioPicker(this.props.propertyInfo);
			}

			if (PropertyTypes.isMapPropertyType(this.props.propertyInfo.type)) {
				ComponentActionsCreator.startMapPicker(this.props.propertyInfo);
			}

			if (PropertyTypes.isLinkPropertyType(this.props.propertyInfo.type)) {
				ComponentActionsCreator.startLinkPicker(this.props.propertyInfo);
			}

			if (PropertyTypes.isMarkdownPropertyType(this.props.propertyInfo.type)) {
				ComponentActionsCreator.startMarkdownPicker(this.props.propertyInfo);
			}
		} else {
			ComponentActionsCreator.deactivateProperty();
		}
	},

	/**
	 * @method render
	 */
	render: function() {
		return (<SystemLayerBreach
			onFocusChange={this._onFocusChange}
			className={this.css(this.state.breach ? "system-property-breach" : "system-property", { empty: this.state.empty })}
			focus={this.state.focus}
			rectangle={this.props.propertyInfo.relativeRectangle}>
			<span className="system-property-empty-indicator"><span>{"+ add " + this.state.tooltip}</span></span>
			<span className="system-property-tooltip">{this.state.tooltip}</span>
			<span className="system-property-indicator" />
			{this.props.marker ? <span className="marker"/> : null}
		</SystemLayerBreach>);
	}
});

module.exports = PropertySystemLayer;
