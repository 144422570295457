import { get, isEqual, set } from 'lodash';

import React from 'react';

import PropertyTypes from '../../constants/PropertyTypes';

import ComponentActionsCreator from '../../actions/ComponentActionsCreator';

import ComponentContextStore from '../../stores/ComponentContextStore';

import VideoPickerTabs from '../common/VideoPickerTabs.react';


export default class VideoPicker extends React.Component {
	static propTypes = {
		propertyName: React.PropTypes.string.isRequired,
		propertyType: React.PropTypes.oneOf([PropertyTypes.video, PropertyTypes.arrayOfVideo]).isRequired
	};

	_close(url) {
		let component = ComponentContextStore.getState('active');
		let oldValue = get(component.context, this.props.propertyName);
		let newValue = {url};

		if(!isEqual(oldValue, newValue)) {
			set(component.context, this.props.propertyName, newValue);
			ComponentActionsCreator.updateComponentInstanceContext(component);
		}
		ComponentActionsCreator.stopVideoPicker();
	}

	render() {
		return (
			<div className="video-picker">
				<span className="video-picker-title">Select a video:</span>
				<div className="video-picker-main">
					<VideoPickerTabs onSelect={(url) => this._close(url)} />
				</div>
			</div>
		);
	}
}
