'use strict';

var _ = require('lodash');

var StoreFactory = require('../infrastructure/StoreFactory');
var RemixerConstants = require('../constants/RemixerConstants');

var PageInstance = require('../models/PageInstance');
var _convert = function(page) { return new PageInstance(page); };

/** @module ThemePagesStore
 * @requires lodash
 * @requires StoreFactory
 * @requires RemixerConstants
 * @requires PageInstance
 */
var ThemePagesStore = StoreFactory.Create({

	/**
	 * @method _setThemePagesLoading
	 */
	_setThemePagesLoading: function(action) {
		this._filling(action.themeId);
	},

	/**
	 * @method _setThemePages
	 */
	_setThemePages: function(action) {
		var pages = _(action.pages).map(_convert).keyBy('id').value();

		this._set(action.themeId, pages);
	},

	/**
	 * @method _setThemePagesFailed
	 */
	_setThemePagesFailed: function(action) {
		this._failed(action.themeId);
	},

	/**
	 * @method _getHandlers
	 */
	_getHandlers: function () {
		var handlers = {};

		handlers[RemixerConstants.LOAD_THEME_PAGES] = this._setThemePagesLoading;
		handlers[RemixerConstants.RECEIVE_THEME_PAGES] = this._setThemePages;
		handlers[RemixerConstants.RECEIVE_THEME_PAGES_FAILS] = this._setThemePagesFailed;

		return handlers;
	}
});

module.exports = ThemePagesStore;
